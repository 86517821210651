import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.white,
    fontSize: 40,
  },
  video: {
    borderRadius: 6,
    aspectRatio: '9 / 16',
    objectFit: 'contain',
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    overflow: 'hidden',
  },
  tgvideo: {
    display: 'block',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    border: 'none',
    borderRadius: 'inherit',
  },
  videoWrapper: {
    position: 'relative',
    borderRadius: '16px',
    border: 'none',
    overflow: 'hidden',
    width: '100%',
    paddingTop: 'calc((27 / 32) * 100%)',
    backgroundColor: 'black',
  },
  root: {
    padding: '0 3px',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  mediaRoot: {
    width: '100%',
  },
  buttonControl: {
    padding: '10px 2px',

    '& svg': {
      height: '15px',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  constrolPlayContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeBox: {
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
  },
  prettoSlider: {
    '& div': {
      display: 'grid',
      placeItems: 'center',
    },
  },
}));
