export const PaymentIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="4" width="18.8235" height="14.1176" rx="2" stroke="#5242EA" strokeWidth="1.3" />
    <line x1="2" y1="8.99844" x2="20.8235" y2="8.99844" stroke="#5242EA" strokeWidth="1.3" />
    <line
      x1="5.47349"
      y1="14.643"
      x2="6.99702"
      y2="14.643"
      stroke="#5242EA"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <line
      x1="9.23813"
      y1="14.643"
      x2="12.644"
      y2="14.643"
      stroke="#5242EA"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <circle cx="18.8819" cy="16.8819" r="3.11471" fill="white" stroke="#5242EA" strokeWidth="1.3" />
    <path d="M18.8816 15.6758V18.0883" stroke="#5242EA" strokeLinecap="round" />
    <path d="M17.6758 16.8828L20.0883 16.8828" stroke="#5242EA" strokeLinecap="round" />
  </svg>
);
