import { useState, useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { createVenuesListTableContent } from '../../../../services/helpers/tableMappers';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  updateSort,
  getVenueList,
  goToSelectedPage,
  updateSize,
  updateSearch,
} from '../../../../store/slices/organizationVenueSlice';
import { getProperties, getVenue, setSelectedVenue } from '../../../../store/slices/venueSlice';
import { WebsiteRoutes } from '../../../../common/constants/routes';
import { UserRoles } from '../../../../common/constants/constants';
import { useVenueTablesStyles } from './VenuesTable.style';
import { reset as resetAnalytics } from '../../../../store/slices/analyticsSlice';
import { reset as resetHeroes } from '../../../../store/slices/heroesSlice';
import { reset as resetStoryVideos } from '../../../../store/slices/videosSlice';
import { reset as resetCampaignsStoryVideos } from '../../../../store/slices/campaignsStoryVideosSlice';
import {
  getPrimaryCampaign,
  reset as resetCampaigns,
} from '../../../../store/slices/campaignsSlice';
import { reset as resetRewardSMSTemplate } from '../../../../store/slices/rewardFulfillmentSlice';
import { reset as resetVenueManagers } from '../../../../store/slices/venueManagerSlice';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useVenueProperty } from '../../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../../api/models/venue';
import { reset as resetUI } from '../../../../store/slices/uiSlice';
import { useIsTBAccount } from '../../../../hooks/common';
import { CommonStrings } from '../../../../common/localization/en';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { AllAccountsEventNames } from '../../../../common/constants/events/allAccountsEvents';

interface IVenuesTableProps {
  children?: JSX.Element;
}

export const VenuesTable: FC<IVenuesTableProps> = ({ children }) => {
  const styles = useVenueTablesStyles();
  const dispatch = useAppDispatch();
  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
  } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { role } = useTypedSelector((state) => state.me);
  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();

  const [shouldRedirect, setShouldRedirect] = useState(false);
  const selectedVenuId = useTypedSelector((state) => state.venue.selectedVenueId);
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { distributors, activeDistributor } = useTypedSelector((state) => state.distributors);
  const { affiliates, activeAffiliate } = useTypedSelector((state) => state.affiliates);
  const { value: quickStartValues, isLoading: isVenuesPropertyLoading } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  const activeGroupId = activeDistributor?.groupId || activeAffiliate?.groupId || null;
  const isAllAccountsSelected = !activeAffiliate && !activeDistributor;

  const history = useHistory();
  const isSuperAdmin =
    ([UserRoles.admin_role] as string[]).includes(role) ||
    !!distributors.length ||
    !!affiliates.length;
  const searchParams = {
    size,
    sort: sort.toString(),
    search,
    page: page.toString(),
  };
  useEffect(() => {
    if (shouldRedirect && venueId === selectedVenuId && !isVenuesPropertyLoading) {
      if (quickStartValues?.isQuickStartInProgress) {
        history.push(quickStartValues?.currentRoute);
      } else {
        history.push(WebsiteRoutes.DashboardHeroes);
      }
    }
  }, [selectedVenuId, venueId, shouldRedirect, history, quickStartValues, isVenuesPropertyLoading]);
  useEffect(() => {
    dispatch(
      getVenueList({
        params: {
          ...searchParams,
        },
        withStats: true,
        groupId: activeGroupId,
        includeTestGroups: !isAllAccountsSelected,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, size, sort, search, activeGroupId]);

  const handleOpenDashboardClick = (venueId: string) => {
    dispatch(getVenue({ id: venueId }));
    dispatch(getProperties({ id: venueId }));
    dispatch(setSelectedVenue({ id: venueId }));
    dispatch(
      getPrimaryCampaign({
        venueId,
      }),
    );

    dispatch(resetUI());
    dispatch(resetAnalytics());
    dispatch(resetHeroes());
    dispatch(resetStoryVideos());
    dispatch(resetCampaignsStoryVideos());
    dispatch(resetCampaigns());
    dispatch(resetRewardSMSTemplate());
    dispatch(resetVenueManagers());

    setShouldRedirect(true);
  };

  const isTBAccount = useIsTBAccount();

  const contentDataTable = createVenuesListTableContent(
    handleOpenDashboardClick,
    { items },
    isSuperAdmin,
    isTBAccount ? CommonStrings.Patients : CommonStrings.Creators,
    isHealthCare,
  );

  const onGoToPage = (targetPage: number) => {
    trackEvent(AllAccountsEventNames.venuesTablePageChanged, {
      value: targetPage.toString(),
    });
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    trackEvent(AllAccountsEventNames.venuesTableSortClicked, {
      value: name,
    });
    dispatch(updateSort(name));
  };

  const changeSize = (size: number) => {
    trackEvent(AllAccountsEventNames.venuesTableSizeChanged);
    dispatch(updateSize(size));
  };

  const onClickRow = (venueId: string) => {
    trackEvent(AllAccountsEventNames.tableVenueSelected, { selectedVenueId: venueId });
    handleOpenDashboardClick(venueId);
  };

  return (
    <Box sx={{ pt: '0px' }}>
      <div className={styles.AccountsTable}>
        <CommonTable
          content={contentDataTable}
          page={convertApiPageToFrontEndPage(page)}
          sort={sort}
          totalItems={totalItems}
          totalPages={totalPages}
          isLoading={isLoading}
          noContent={error}
          tablePadding="0"
          goToPage={onGoToPage}
          onSortHeaderClick={onSort}
          size={size}
          onSizeChange={changeSize}
          withSearchBar={true}
          searchBarProps={{
            entity: 'OrganizationVenueSlice',
            adaptiveWidth: true,
            updateSearch,
            placeholderText: 'Search account',
          }}
          onClickRow={onClickRow}
          isSuperAdmin={isSuperAdmin}
        >
          {/* <Box sx={{ backgroundColor: '#f5f6f8', pt: '30px', pb: '30px' }}>
          <AnalyticsBlock />
        </Box> */}
          {children}
        </CommonTable>
      </div>
    </Box>
  );
};
