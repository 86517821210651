import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { TableDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { CampaignsEndpoints, getApiUrlForId } from '../../api/endpoints';
import { GetTableDataWithSearchRequest, GetTableDataResponse } from '../../api/models/common';
import {
  CampaignsApiModel,
  GetCampaignsAPIModel,
  CreateIncentiveCampaignApiModel,
  PatchIncentiveCampaignApiModel,
  PatchIncentiveCampaignOptions,
  SalesCalculationOptions,
  FundraisingCalculationOptions,
  MarketingCalculationOptions,
  GetCampaignsRequest,
  ETSCalculationOptions,
  Activity,
  GetRewardCardRequestOptions,
  RewardCard,
  GetCampaignUsersRequest,
  GetCampaignUsersRequestOptions,
  CampaignUser,
  GetExistedHeroesCampaignApiModel,
  EditUsersInCampaignOptions,
  GetCampaignVideosRequestOptions,
  GetCampaignVideosRequest,
  GetCampaignVideosApiModel,
  CampaignVideosApiModel,
  UserIncentiveCampaignsApiModel,
} from '../../api/models/campaigns';
import { RewardsCalculationApiModel } from '../../api/models/rewards';
import { getTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import axios from 'axios';
import {
  CampaignInvitationBatchesResponse,
  GetTransactionAPIModel,
  TransactionRecordRequest,
} from '../../api/models/transaction';

export interface CampaignCountData {
  users: number;
  fulfilledUsers: number;
  invitations: number;
  activeInvitations: number;
  pendingInvitations: number;
  usersWithVideos: number;
  unsentInvitations: number;
  invitationsWithVideos: number;
}

interface InitialState extends TableDTO<CampaignsApiModel> {
  activeCampaign: CampaignsApiModel | null;
  isCreationCampaignModal: boolean;
  isCalculationResultLoading: boolean;
  campaignCreationInProcessing: boolean;
  calculationResult: RewardsCalculationApiModel | null;
  recommendedValue: number[];
  title: string;
  subtitle: string;
  rewardTitle: string;
  rewardDescription: string;
  couponEnabled: boolean;
  rewardCard?: string;
  isRewardCardLoading?: boolean;
  isNullReward: boolean;
  currentCampaign: CampaignsApiModel | null;
  currentCampaignUsers: TableDTO<CampaignUser>;
  currUserActiveCampaign: CampaignsApiModel | null;
  isActiveCampaignLoading: boolean;
  countData: CampaignCountData;
  isCountDataLoading: boolean;
  campaignVideos: CampaignVideosApiModel[];
  userIncentiveCampaigns: UserIncentiveCampaignsApiModel[];
  invitationBatches: TableDTO<GetTransactionAPIModel>;
  primaryCampaign: CampaignsApiModel | null;
}

const campaignUsersInitState: TableDTO<CampaignUser> = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
};

const invitationBatchesInitialState: TableDTO<GetTransactionAPIModel> = {
  items: [],
  size: defaultPagination.size,
  sort: defaultPagination.sort,
  page: defaultPagination.page,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  isLoading: false,
  lastUpdated: new Date().toISOString(),
  error: false,
};

const initialState: InitialState = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
  activeCampaign: null,
  isCreationCampaignModal: false,
  calculationResult: null,
  recommendedValue: [],
  isCalculationResultLoading: false,
  campaignCreationInProcessing: false,
  title: '',
  subtitle: '',
  rewardTitle: '',
  rewardDescription: '',
  rewardCard: '',
  couponEnabled: true,
  isNullReward: false,
  currentCampaign: null,
  currentCampaignUsers: campaignUsersInitState,
  currUserActiveCampaign: null,
  isActiveCampaignLoading: false,
  countData: {
    users: 0,
    fulfilledUsers: 0,
    invitations: 0,
    activeInvitations: 0,
    pendingInvitations: 0,
    invitationsWithVideos: 0,
    usersWithVideos: 0,
  } as CampaignCountData,
  isCountDataLoading: false,
  campaignVideos: [],
  userIncentiveCampaigns: [],
  invitationBatches: invitationBatchesInitialState,
  primaryCampaign: null,
};

export const getCampaignVideos = createAsyncThunk(
  'campaigns/getCampaignVideos',
  async (options: GetCampaignVideosRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetCampaignVideosRequest, GetCampaignVideosApiModel>({
        url: CampaignsEndpoints.GetCampaignVideos.replace('{id}', options.id),
        requiresToken: true,
        params: {
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getCampaignById = createAsyncThunk(
  'campaigns/getRewardCampaignById',
  async (id: string, { rejectWithValue }) => {
    try {
      const campaign = await httpClient.get<undefined, CampaignsApiModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetRewardCampaignByIdWithStats, id),
        requiresToken: true,
      });

      campaign.activities = await httpClient.get<undefined, Activity[]>({
        url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaignActivities, campaign.id),
        requiresToken: true,
      });

      return campaign;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);
export const getCampaignUsers = createAsyncThunk(
  'campaigns/getCampaignUsers',
  async (options: GetCampaignUsersRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetCampaignUsersRequest, GetExistedHeroesCampaignApiModel>({
        url: CampaignsEndpoints.GetCampaignUsers.replace('{id}', options.id),
        requiresToken: true,
        params: {
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString() || '10000',
          sort: options.pageable.sort?.toString(),
          search: options.search || undefined,
          status: options.status,
          fulfilled: options.filter?.fulfilled?.toString(),
          hasVideos: options.hasVideos,
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getCampaigns = createAsyncThunk(
  'campaigns/getCampaigns',
  async (options: GetTableDataWithSearchRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetCampaignsRequest, GetTableDataResponse<GetCampaignsAPIModel>>({
        url: CampaignsEndpoints.GetIncentiveCampaigns,
        requiresToken: true,
        params: {
          venueId: options.venueId,
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
          search: options.search,
        },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getActiveIncentiveCampaign = createAsyncThunk(
  'campaigns/getActiveIncentiveCampaign',
  async (options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, GetCampaignsAPIModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaign, options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserActiveCampaign = createAsyncThunk(
  'campaigns/getUserActiveCampaign',
  async (options: { userId: string }, { rejectWithValue }) => {
    try {
      const campaign = await httpClient.get<undefined, GetCampaignsAPIModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetUserActiveCampaign, options.userId),
        requiresToken: true,
      });

      campaign.activities = await httpClient.get<undefined, Activity[]>({
        url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaignActivities, campaign.id),
        requiresToken: true,
      });

      return campaign;
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getActiveIncentiveCampaignActivities = createAsyncThunk(
  'campaigns/getActiveIncentiveCampaignActivities',
  async (options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, Activity[]>({
        url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaignActivities, options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getCurrentRewardCampaignActivities = createAsyncThunk(
  'campaigns/getCurrentRewardCampaignActivities',
  async (options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, Activity[]>({
        url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaignActivities, options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postSalesCalculation = createAsyncThunk(
  'campaigns/postSalesCalculation',
  async (options: SalesCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            numberOfSalesReps: number;
            incentiveBudget: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostSalesCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postFundraisingCalculation = createAsyncThunk(
  'campaigns/postFundraisingCalculation',
  async (options: FundraisingCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            numberOfHeroes: number;
            rewardBudget: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostFundraisingCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postETSCalculation = createAsyncThunk(
  'campaigns/postETSCalculation',
  async (options: ETSCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            numberOfHeroes: number;
            rewardBudget: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostETSCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postMarketingCalculation = createAsyncThunk(
  'campaigns/postMarketingCalculation',
  async (options: MarketingCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            monthlyDigitalMarketingBudget: number;
            averageCartCheckout: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostMarketingCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postEndIncentiveCampaign = createAsyncThunk(
  'campaigns/postEndIncentiveCampaign',
  async (options: string, { rejectWithValue }) => {
    try {
      return await httpClient.post<undefined, CampaignsApiModel>({
        url: getApiUrlForId(CampaignsEndpoints.PostEndIncentiveCampaign, options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const postIncentiveCampaign = createAsyncThunk(
  'campaigns/postIncentiveCampaign',
  async (options: CreateIncentiveCampaignApiModel) => {
    try {
      return await httpClient.post<CreateIncentiveCampaignApiModel, CampaignsApiModel>({
        url: CampaignsEndpoints.PostIncentiveCampaigns,
        requiresToken: true,
        payload: options,
      });
    } catch (err) {
      throw new Error();
    }
  },
);

export const patchIncentiveCampaign = createAsyncThunk(
  'campaigns/patchIncentiveCampaign',
  async (options: PatchIncentiveCampaignOptions) => {
    try {
      return await httpClient.put<PatchIncentiveCampaignApiModel, CampaignsApiModel>({
        url: getApiUrlForId(CampaignsEndpoints.PatchIncentiveCampaign, options.campaignId),
        requiresToken: true,
        payload: options.values,
      });
    } catch (err) {
      throw new Error();
    }
  },
);

export const getRewardCardPreview = createAsyncThunk(
  'campaigns/getRewardCardPreview',
  async (options: GetRewardCardRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<GetRewardCardRequestOptions, Blob>({
        url: CampaignsEndpoints.GenerateRewardCard,
        requiresToken: true,
        payload: options,
        isBlob: true,
      });
    } catch (error) {
      console.log('error', error);
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getRewardCardUrl = createAsyncThunk(
  'campaigns/getRewardCardUrl',
  async (options: GetRewardCardRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<GetRewardCardRequestOptions, RewardCard>({
        url: CampaignsEndpoints.GenerateRewardCardUrl,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      console.log('error', error);
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const addUsersToCampaign = createAsyncThunk(
  'campaigns/addUsersToCampaign',
  async (options: EditUsersInCampaignOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<EditUsersInCampaignOptions['payload'], { success: boolean }>({
        url: getApiUrlForId(CampaignsEndpoints.AddUsersToCampaign, options.campaignId),
        requiresToken: true,
        payload: options.payload,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const removeUsersFromCampaign = createAsyncThunk(
  'campaigns/removeUsersFromCampaign',
  async (options: EditUsersInCampaignOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<EditUsersInCampaignOptions['payload'], { success: boolean }>({
        url: getApiUrlForId(CampaignsEndpoints.RemoveUsersFromCampaign, options.campaignId),
        requiresToken: true,
        payload: options.payload,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getCampaignCountData = createAsyncThunk(
  'campaigns/getCampaignCountData',
  async ({ campaignId }: { campaignId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, CampaignCountData>({
        url: getApiUrlForId(CampaignsEndpoints.GetCampaignCountData, campaignId),
        requiresToken: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getUserIncentiveCampaigns = createAsyncThunk(
  'campaigns/getUserIncentiveCampaigns',
  async (options: { userId: string; withCampaignDetails?: boolean }, { rejectWithValue }) => {
    try {
      let userIncentiveCampaigns = await httpClient.get<
        undefined,
        UserIncentiveCampaignsApiModel[]
      >({
        url: getApiUrlForId(CampaignsEndpoints.GetUserIncentiveCampaigns, options.userId),
        requiresToken: true,
      });

      if (options.withCampaignDetails) {
        const campaignDetails = [];
        for await (const { campaignId } of userIncentiveCampaigns) {
          campaignDetails.push(
            httpClient.get<undefined, CampaignsApiModel>({
              url: getApiUrlForId(CampaignsEndpoints.GetRewardCampaignByIdWithStats, campaignId),
              requiresToken: true,
            }),
          );
        }

        await Promise.all(campaignDetails).then(
          (campaigns) =>
            (userIncentiveCampaigns = userIncentiveCampaigns.map((currentCampaign, index) => ({
              ...currentCampaign,
              campaign: campaigns[index],
            }))),
        );
      }

      return { userIncentiveCampaigns };
    } catch (error) {
      // @ts-ignore
      rejectWithValue(error.response.data.message);
    }
  },
);

export const getCampaignInvitationBatches = createAsyncThunk(
  'campaigns/getCampaignInvitationBatches',
  async (
    options: { id: string; page: number; size: number; sort: string[] },
    { rejectWithValue },
  ) => {
    try {
      return await httpClient.get<TransactionRecordRequest, CampaignInvitationBatchesResponse>({
        url: getApiUrlForId(CampaignsEndpoints.GetCampaignInvitationBatches, options.id),
        requiresToken: true,
        params: {
          page: options.page,
          size: options.size,
          sort: options.sort?.toString(),
        },
      });
    } catch (error) {
      // @ts-ignore
      rejectWithValue(error.response.data.message);
    }
  },
);

export const getPrimaryCampaign = createAsyncThunk(
  'campaigns/getPrimaryCampaign',
  async (_options: { venueId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ venueId: string }, GetCampaignsAPIModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetPrimaryCampaign, _options.venueId),
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCampaignsSorting(state, action: PayloadAction<string>) {
      const sorting = getTableSorting(current(state), action.payload);
      return {
        ...initialState,
        activeCampaign: state.activeCampaign,
        isCreationCampaignModal: state.isCreationCampaignModal,
        calculationResult: state.calculationResult,
        isCalculationResultLoading: state.isCalculationResultLoading,
        sort: sorting,
        size: state.size,
      };
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    updateRequestsSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = initialState.page;
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = initialState.page;
    },
    setIsCreationCampaignModal(state, action: PayloadAction<boolean>) {
      state.isCreationCampaignModal = action.payload;
    },
    reset: (state) => initialState,
    resetCurrentRewardCampaign(state) {
      state.currentCampaign = initialState.currentCampaign;
    },
    resetCurrentCampaignUsers(state) {
      state.currentCampaignUsers = initialState.currentCampaignUsers;
    },
    //existed users table reducers
    goToSelectedPageExistingCampaignUsersTable(state, action: PayloadAction<number>) {
      state.currentCampaignUsers.page = action.payload;
    },
    setExistingCampaignUsersTableSorting(state, action: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.currentCampaignUsers), action.payload);
      state.currentCampaignUsers.sort = sorting;
      state.currentCampaignUsers.page = initialState.currentCampaignUsers.page;
      // return {
      //   ...initialState,
      //   sort: sorting,
      //   search: state.search,
      //   size: state.size,
      // };
    },
    updateExistingCampaignUsersTableSize(state, action: PayloadAction<number>) {
      state.currentCampaignUsers.size = action.payload;
      state.currentCampaignUsers.page = initialState.currentCampaignUsers.page;
    },
    updateExistingCampaignUsersTableSearch(state, action: PayloadAction<string>) {
      state.currentCampaignUsers.search = action.payload;
      state.currentCampaignUsers.page = initialState.currentCampaignUsers.page;
    },
    resetUserIncentiveCampaigns(state) {
      state.userIncentiveCampaigns = [];
    },
    setInvitationBatchesSorting(state, { payload }: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.invitationBatches), payload);
      state.invitationBatches.sort = sorting;
      state.invitationBatches.page = initialState.invitationBatches.page;
    },
    updateInvitationBatchesSize(state, { payload }: PayloadAction<number>) {
      state.invitationBatches.size = payload;
      state.invitationBatches.page = initialState.invitationBatches.page;
    },
    updateInvitationBatchesSearch(state, { payload }: PayloadAction<string>) {
      state.invitationBatches.search = payload;
      state.invitationBatches.page = initialState.invitationBatches.page;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getCampaigns.rejected, (state) => {
      tableLoadRejected(state);
    });
    reducersBuilder.addCase(getCampaigns.pending, (state) => {
      tableLoadPending(state);
    });
    reducersBuilder.addCase(getCampaigns.fulfilled, (state, { payload }) => {
      tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(postIncentiveCampaign.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(postIncentiveCampaign.pending, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(postIncentiveCampaign.fulfilled, (state, { meta, payload }) => {
      state.activeCampaign = payload;
      state.items = [payload, ...state.items];
      state.title = meta.arg.title!;
      state.subtitle = meta.arg.subtitle!;
      state.isLoading = false;
      state.error = false;
    });
    reducersBuilder.addCase(patchIncentiveCampaign.fulfilled, (state, { meta, payload }) => {
      state.title = meta.arg.values.title!;
      state.subtitle = meta.arg.values.action!;
      if (state.currentCampaign) state.currentCampaign = payload;
    });
    reducersBuilder.addCase(getActiveIncentiveCampaign.fulfilled, (state, { payload }) => {
      state.activeCampaign =
        state.activeCampaign?.id && state.activeCampaign.id === payload.id
          ? { ...state.activeCampaign, ...payload }
          : payload;
    });

    reducersBuilder.addCase(getUserActiveCampaign.pending, (state, { payload }) => {
      state.isActiveCampaignLoading = true;
    });

    reducersBuilder.addCase(getUserActiveCampaign.rejected, (state, { payload }) => {
      state.currUserActiveCampaign = null;
      state.isActiveCampaignLoading = false;
    });

    reducersBuilder.addCase(getUserActiveCampaign.fulfilled, (state, { payload }) => {
      state.currUserActiveCampaign = payload;
      state.isActiveCampaignLoading = false;
    });
    reducersBuilder.addCase(getActiveIncentiveCampaign.rejected, (state, { payload }) => {
      state.activeCampaign = null;
    });
    reducersBuilder.addCase(getCampaignById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignById.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentCampaign = payload;
      }
      state.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignById.rejected, (state, { payload }) => {
      state.currentCampaign = null;
      state.isLoading = false;
    });

    reducersBuilder.addCase(getCampaignCountData.pending, (state, { payload }) => {
      state.isCountDataLoading = true;
    });
    reducersBuilder.addCase(getCampaignCountData.fulfilled, (state, { payload }) => {
      if (payload) {
        state.countData = payload;
      }
      state.isCountDataLoading = false;
    });
    reducersBuilder.addCase(getCampaignCountData.rejected, (state, { payload }) => {
      state.isCountDataLoading = false;
    });

    reducersBuilder.addCase(getCurrentRewardCampaignActivities.fulfilled, (state, { payload }) => {
      if (state.currentCampaign) state.currentCampaign.activities = payload;
    });

    reducersBuilder.addCase(getCampaignUsers.pending, (state, { payload }) => {
      state.currentCampaignUsers.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignUsers.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentCampaignUsers.items = payload.items;
        state.currentCampaignUsers.totalItems = payload.totalItems;
        state.currentCampaignUsers.totalPages = payload?.totalPages;
        state.currentCampaignUsers.page = payload?.page;
        state.currentCampaignUsers.size = payload?.size;
      }
      state.currentCampaignUsers.isLoading = false;
      state.currentCampaignUsers.isLoaded = true;
    });
    reducersBuilder.addCase(getCampaignUsers.rejected, (state, { payload }) => {
      state.currentCampaignUsers.isLoading = false;
      state.currentCampaignUsers.error = true;
    });
    reducersBuilder.addCase(getActiveIncentiveCampaignActivities.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(
      getActiveIncentiveCampaignActivities.fulfilled,
      (state, { payload }) => {
        if (state.activeCampaign) state.activeCampaign.activities = payload;
      },
    );
    reducersBuilder.addCase(postSalesCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postSalesCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.isCalculationResultLoading = false;
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const incentiveValuePerSalesRep = action.payload.incentiveValuePerSalesRep ?? 0;
        state.recommendedValue = [incentiveValuePerSalesRep];
      },
    );
    reducersBuilder.addCase(postFundraisingCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postFundraisingCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const rewardValue = action.payload.rewardValue ?? 0;
        state.recommendedValue = [rewardValue];
      },
    );
    reducersBuilder.addCase(postETSCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postETSCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const rewardValue = action.payload.rewardValue ?? 0;
        state.recommendedValue = [rewardValue];
      },
    );
    reducersBuilder.addCase(postMarketingCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postMarketingCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const projectedHeroes = action.payload.projectedHeroes ?? 0;
        const recommendedRewardBudget = action.payload.recommendedRewardBudget ?? 0;
        state.recommendedValue = [projectedHeroes, recommendedRewardBudget];
      },
    );
    reducersBuilder.addCase(postEndIncentiveCampaign.fulfilled, (state, { payload }) => {
      state.lastUpdated = new Date().toISOString();
      state.activeCampaign = null;
      state.calculationResult = null;
      state.campaignCreationInProcessing = false;
      state.recommendedValue = [];
      state.isLoading = false;
      state.error = false;
      if (state.currentCampaign?.id === payload.id) state.currentCampaign.endedAt = payload.endedAt;
    });
    reducersBuilder.addCase(postEndIncentiveCampaign.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(postEndIncentiveCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getRewardCardPreview.pending, (state) => {
      state.isRewardCardLoading = true;
    });
    reducersBuilder.addCase(getRewardCardPreview.fulfilled, (state, action) => {
      const objectURL = URL.createObjectURL(action.payload);
      state.isRewardCardLoading = false;
      const img = new Image();
      img.src = objectURL;
      state.rewardCard = objectURL;
    });

    reducersBuilder.addCase(addUsersToCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(addUsersToCampaign.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(addUsersToCampaign.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    reducersBuilder.addCase(getCampaignVideos.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignVideos.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignVideos.fulfilled, (state, action) => {
      state.isLoading = false;
      state.campaignVideos = action.payload?.items!;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.userIncentiveCampaigns = payload.userIncentiveCampaigns;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignInvitationBatches.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.invitationBatches.items = payload.items;
      state.invitationBatches.page = payload.page;
      state.invitationBatches.size = payload.size;
      state.invitationBatches.totalItems = payload.totalItems;
      state.invitationBatches.totalPages = payload.totalPages;

      state.invitationBatches.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignInvitationBatches.pending, (state) => {
      state.invitationBatches.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignInvitationBatches.rejected, (state) => {
      state.invitationBatches.isLoading = true;
    });
    reducersBuilder.addCase(getPrimaryCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getPrimaryCampaign.rejected, (state) => {
      state.isLoading = false;
      state.primaryCampaign = null;
    });
    reducersBuilder.addCase(getPrimaryCampaign.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.primaryCampaign = payload;
      }
    });
  },
});

export const {
  goToSelectedPage,
  updateTable,
  setCampaignsSorting,
  updateRequestsSize,
  reset,
  updateSearch,
  setIsCreationCampaignModal,
  resetCurrentRewardCampaign,
  resetCurrentCampaignUsers,
  goToSelectedPageExistingCampaignUsersTable,
  setExistingCampaignUsersTableSorting,
  updateExistingCampaignUsersTableSize,
  updateExistingCampaignUsersTableSearch,
  resetUserIncentiveCampaigns,
  setInvitationBatchesSorting,
  updateInvitationBatchesSize,
  updateInvitationBatchesSearch,
} = campaignsSlice.actions;
export default campaignsSlice.reducer;
