import React, { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useUploadFileToStorage } from '../../../../hooks/useUploadFileToStorage';
import { resizeImageForTwilioMMSCommunication } from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { setInviteMediaUrl } from '../../../../store/slices/createCampaignSlice';
import { PictureIconDnD } from '../../../assets/newDesign/PictureIconDnD';
import { CommonStrings, PersonalizationStrings } from '../../../localization/en';
import { ImageUploader, ImageUploaderProps } from '../ImageUploader';
import { Alignment } from '../ImageUploaderWithGallery';
import { useLocation } from 'react-router-dom';

interface InvitationSMSImageUploaderProps {
  onLogoChange?: (image: string | undefined) => void;
  title?: string;
  subtitle?: string;

  imagePreviewWidth?: ImageUploaderProps['imagePreviewWidth'];
  imagePreviewHeight?: ImageUploaderProps['imagePreviewHeight'];
  alignment?: Alignment;
  imagePadding?: number;
}

export const InvitationSMSImageUploader: React.FC<InvitationSMSImageUploaderProps> = ({
  title = 'Try to use a PNG',
  subtitle = 'PNGs have a transparent background which improves the aesthetic of the home screen',
  imagePreviewWidth = '160px',
  imagePreviewHeight = '160px',
  imagePadding,
  alignment,
}) => {
  const { inviteMediaUrl } = useTypedSelector((state) => state.createCampaign);
  const {
    venue: { logo },
  } = useTypedSelector((state) => state.venue);
  const dispatch = useAppDispatch();
  const { uploadFileToStorage, loading: isFileLoading } = useUploadFileToStorage();
  const location = useLocation();

  const { addToast } = useToasts();

  useEffect(() => {
    if (logo && !inviteMediaUrl && !location.pathname.includes('edit-campaign')) {
      dispatch(setInviteMediaUrl(logo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = async (file: File | undefined) => {
    if (!file) return;
    if (!['image/svg+xml', 'image/jpeg', 'image/png'].includes(file.type)) {
      addToast(CommonStrings.OnError, { appearance: 'error' });
      return;
    }
    const fileUrl = (await uploadFileToStorage(file))?.downloadUrl;
    if (fileUrl) {
      const resizedImageUrl = await resizeImageForTwilioMMSCommunication(fileUrl);
      dispatch(setInviteMediaUrl(resizedImageUrl));
    }
  };

  return (
    <ImageUploader
      file={inviteMediaUrl}
      onUpload={handleUpload}
      accept={['png', 'svg', 'jpeg']}
      title={title}
      subtitle={subtitle}
      dndDescription="JPG or PNG (recommended size 1:1 aspect ratio)"
      dndTitle="Drag or Drop"
      dndIcon={<PictureIconDnD />}
      buttonTitle={PersonalizationStrings.BrowseFiles}
      loading={isFileLoading}
      imagePreviewFit="contain"
      imagePreviewWidth={imagePreviewWidth}
      imagePreviewHeight={imagePreviewHeight}
      alignment={alignment}
      imagePadding={imagePadding}
      rightSideContainerProps={{
        maxWidth: '350px',
      }}
      warnBackgroundNotTransparent={false}
      displayRemoveButton={true}
    />
  );
};
