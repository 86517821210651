import { FC, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useStyles } from './InvitesBlockCopyUrl.styles';
import { SettingsPageStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import { CopyIcon } from '../../assets/newDesign/CopyIcon';
import { CopiedIcon } from '../../assets/newDesign/CopiedIcon';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';

interface Props {
  webAppLink: string;
}

export const InvitesBlockCopyUrl: FC<Props> = ({ webAppLink }) => {
  const classes = useStyles();
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (isLinkCopied) {
      trackEvent(VideosEventNames.copyInvitesBlockLinkClicked, {
        value: webAppLink,
      });
      navigator.clipboard.writeText(webAppLink);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 3000);
    }
  }, [isLinkCopied, webAppLink, trackEvent]);

  return (
    <Box className={classes.container}>
      <Box className={classes.fieldsWrapper}>
        <TextField className={classes.urlField} value={webAppLink} disabled={true} />
        <RoundedButton
          title={isLinkCopied ? SettingsPageStrings.VideoLinkCopied : ''}
          className={classes.btn}
          startIcon={isLinkCopied ? <CopiedIcon /> : <CopyIcon />}
          onClick={() => {
            setIsLinkCopied(true);
          }}
          style={{
            backgroundColor: isLinkCopied ? '#17B950' : '',
            width: isLinkCopied ? '100%' : '60px',
            boxShadow: isLinkCopied ? 'none' : '',
            borderRadius: isLinkCopied ? '8px' : '50px',
          }}
          sx={{
            '& span': {
              margin: isLinkCopied ? '' : '0',
              paddingBottom: isLinkCopied ? '3px' : '',
            },
          }}
        />
      </Box>
    </Box>
  );
};
