import { Box, Divider, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { FSModal } from '../../modal/Modal';
import { useStyles } from './ConfirmSyncModal.styles';
import { useTypedSelector } from '../../../../store';
import { RoundedButton } from '../../Button/RoundedButton';
import { DistributorsStrings } from '../../../localization/en';
import { CloseIcon } from '../../../assets/CloseIcon';
import { ArrowGreen } from '../../../assets/ArrowGreen';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  liveVideosCount: number;
}

export const ConfirmSyncModal: FC<Props> = ({ isOpen, onClose, onConfirm, liveVideosCount }) => {
  const styles = useStyles();
  const { name } = useTypedSelector((state) => state.me);
  const { name: businessName } = useTypedSelector((state) => state.venue.venue);

  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur={true}
      width="449px"
      padding="0 5px"
    >
      <Box className={styles.iconWrapper}>
        <IconButton onClick={onClose} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={styles.container}>
        <Typography className={styles.title}>{`Hello ${name.split(' ')[0]},`}</Typography>
        <Typography className={styles.title}>{`To add videos to ${businessName}'s`}</Typography>
        <Typography className={styles.title} width="317px">
          {DistributorsStrings.ConcentGoogle}
        </Typography>
        {liveVideosCount > 0 && (
          <Box>
            <Divider sx={{ marginTop: '15px !important' }} className={styles.divider} />
            <Typography className={styles.videosCountText}>
              {DistributorsStrings.VideosCount}
            </Typography>
            <Box className={styles.countWrapper}>
              <Typography className={styles.videosCount}>{liveVideosCount}</Typography>
              <ArrowGreen />
              <img
                src="/GoogleProfileLarge.png"
                className={styles.googleProfileIcon}
                alt="google icon"
              />
            </Box>
            <Divider sx={{ marginTop: '15px !important' }} className={styles.divider} />
          </Box>
        )}
        <Typography className={styles.description}>{DistributorsStrings.VideosSync}</Typography>
        <RoundedButton title="I agree" className={styles.btn} onClick={onConfirm} />
      </Box>
    </FSModal>
  );
};
