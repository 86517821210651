import { FC } from 'react';
import { theme } from '../../../theme';

interface VSharesIconAnalyticsProps {
  width?: string;
  height?: string;
  isActive?: boolean;
}

export const SharesIconAnalytics: FC<VSharesIconAnalyticsProps> = ({ width, height, isActive }) => (
  <svg
    width={width ?? 50}
    height={height ?? 50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={
      isActive
        ? {
            borderRadius: '50%',
            border: `1px solid ${theme.palette.primary.main}`,
          }
        : {}
    }
  >
    <mask
      id="mask0_704_20293"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={50}
      height={50}
    >
      <circle cx={25} cy={25} r={25} fill="#F1F1F1" />
    </mask>
    <g mask="url(#mask0_704_20293)">
      <circle cx={25} cy={25} r={25} fill="#EEECFD" />
      <path
        d="M9.76953 2.67432L47.3263 40.231"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.523 -5.08008L55.0797 32.4766"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.69849 9.74512L40.2552 47.3018"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-5.07971 17.5234L32.477 55.0802"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7378 31.6294L19.7892 35.9133L20.738 29.4664L22.0256 28.3628L25.7378 31.6294Z"
        fill="#1A1538"
        stroke="#1A1538"
        strokeWidth={0.948237}
        strokeLinejoin="round"
      />
      <path
        d="M10.52 22.607L38.5684 11.6343C39.4898 11.2739 40.4447 12.0763 40.2484 13.046L35.3084 37.4514C35.1273 38.346 34.0795 38.7509 33.3439 38.2107L19.7926 28.2578C19.6935 28.1849 19.5841 28.1271 19.468 28.0863L10.5602 24.9494C9.46759 24.5646 9.44124 23.029 10.52 22.607Z"
        fill="#D9D4FF"
        stroke="#5242EA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8719 27.1649L36.5184 15.0779L21.6093 29.5126L20.0709 35.5703C19.944 36.07 19.2355 36.0738 19.1033 35.5755L16.8719 27.1649Z"
        fill="white"
        stroke="#1A1538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
