import { Box, Button, Stack, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { LinearProgressWithLabel } from '../../../../common/components/LinearProgressBar/LinearProgress.helper';
import { RewardStrings } from '../../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  createUploadVideoUrl,
  reset,
  setProgress,
  uploadVideoAsFile,
} from '../../../../store/slices/uploadVideoSlice';
import { useStyles } from '../WelcomeVideoStyles.styles';

import { RecordVideoModal } from '../../RecordVideoModal/RecordVideoModal';
import { useModal } from '../../../../hooks/useModal';
import { useOnUploadProgress } from '../../../../services/hooks/useOnUploadProgress';
import { getUpdatedVideoUrl } from '../../../../common/components/VideoUploader/VideoUplader.helpers';
import { setWelcomeVideoUrl } from '../../../../store/slices/createCampaignSlice';
import { defaultVideos } from '../../WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import { UploadProcessStep } from '../../../../store/storeModels';

const AddVideoSection = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { uploadingProgress, currentStep } = useTypedSelector((state) => state.uploadVideo);
  const { welcomeVideoUrl } = useTypedSelector((state) => state.createCampaign);

  useEffect(() => {
    // clean errors on welcome-video url change
    setIsErrorAlert(false);
  }, [welcomeVideoUrl]);

  const onUploadProgress = useOnUploadProgress({ videoUpload: true });
  const isCancelUpload = useRef(false);

  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = (file: File) => {
    setIsUploading(true);
    setIsErrorAlert(false);
    createUploadVideoUrl().then((res) => {
      if (res) {
        dispatch(
          uploadVideoAsFile({
            options: res,
            data: file,
            onUploadProgress,
          }),
        ).then(async () => {
          const url = await getUpdatedVideoUrl(res.downloadUrl);
          dispatch(setWelcomeVideoUrl(url));
          setIsUploading(false);

          if (isCancelUpload.current) {
            isCancelUpload.current = false;
            return;
          }
        });
      }
    });
  };

  const resetWelcomeVideo = () => {
    const url = defaultVideos.default;
    dispatch(reset());
    dispatch(setWelcomeVideoUrl(url));
  };

  const clearInput = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const handleInputClick = (e: React.MouseEvent<HTMLElement>) => {
    if (inputRef?.current && e.detail === 1) {
      inputRef.current.click();
      /*  trackEvent({
        action: EventNames.Upload_Button_Click,
        location: window.location.href,
        venueId: venue.id,
        userId,
      }); */
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (!file) {
      clearInput();
      return;
    }

    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(file);

    videoElement.addEventListener('loadedmetadata', () => {
      const duration = videoElement.duration;

      if (duration > 30) {
        setIsErrorAlert(true);
        return;
      }
      // Upload file: file size checked successfully
      uploadFile(file);
    });

    /*     trackEvent({
      action: EventNames.Create_Video_Page_file_selected,
      location: window.location.href,
      venueId: venue.id,
      userId,
      fileSize: file.size,
      page: EventNames.Create_Video_Page,
    }); */
  };

  const resetVideo = () => {
    dispatch(setProgress({ value: 0 }));
    dispatch(setWelcomeVideoUrl(undefined));
    setIsErrorAlert(false);
    resetWelcomeVideo();
  };

  const {
    isOpen: isRecordVideoModalOpen,
    open: openRecordVideoModal,
    close: closeRecordVideoModal,
  } = useModal();

  const isCustomUploadedVideo = welcomeVideoUrl !== defaultVideos.default;

  return (
    <>
      <Typography className={styles.uploadTitle}>Upload Your Own Welcome Video</Typography>
      {isUploading && (
        <div className={styles.progressWrapper}>
          <LinearProgressWithLabel value={uploadingProgress} className={styles.progress} />
        </div>
      )}
      <Box
        style={{
          display: isCustomUploadedVideo ? 'flex' : 'block',
        }}
      >
        {isCustomUploadedVideo && (
          <img className={styles.thumbnail} src={welcomeVideoUrl?.replace('mp4', 'png')} alt="" />
        )}
        <Box
          sx={{
            display: 'block',
          }}
        >
          <Box sx={{ order: isCustomUploadedVideo ? '1' : '2' }}>
            <Typography className={styles.uploadSubTitle}>
              Record or select a <b>30 seconds maximum</b> video that tells your creators what type
              of videos you want them to create.
            </Typography>
          </Box>
          <Stack
            direction={'row'}
            spacing={'12px'}
            mt={'15px'}
            mr={'15px'}
            order={isCustomUploadedVideo ? '2' : '1'}
          >
            {currentStep === UploadProcessStep.UploadVideo &&
              !(welcomeVideoUrl && welcomeVideoUrl !== defaultVideos.default) && (
                <Box>
                  <RecordVideoModal show={isRecordVideoModalOpen} onClose={closeRecordVideoModal} />

                  <Button
                    style={{
                      borderRadius: '100px',
                      fontSize: '12px',
                      padding: '10px 14px',
                      minWidth: '113px',
                    }}
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={openRecordVideoModal}
                  >
                    {RewardStrings.RecordVideo}
                  </Button>
                </Box>
              )}

            <Box className={styles.uploadBtnControl}>
              <Button
                className={styles.uploadBtn}
                onClick={handleInputClick}
                variant="outlined"
                size="large"
                color="primary"
              >
                <input
                  className={['visually-hidden'].join(' ')}
                  type="file"
                  accept="video/mp4,video/webm,video/quicktime"
                  ref={inputRef}
                  id="upload-input"
                  name="upload-input"
                  onChange={onChangeHandler}
                />
                {RewardStrings.BrowseFiles}
              </Button>
              {(currentStep !== UploadProcessStep.UploadVideo ||
                (welcomeVideoUrl && welcomeVideoUrl !== defaultVideos.default)) && (
                <Button
                  onClick={resetVideo}
                  variant="text"
                  color="error"
                  size="large"
                  style={{ textAlign: 'center', fontSize: '12px', height: '44px' }}
                >
                  Remove
                </Button>
              )}
            </Box>
          </Stack>
          {isErrorAlert && (
            <Typography className={styles.error}>{RewardStrings.DurationError}</Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddVideoSection;
