import React, { FC } from 'react';
import { useStyles } from './CreateVideoPreview.styles';
import { IPhonePreview } from '../../IPhonePreview/IPhonePreview';
import { Box, Typography } from '@mui/material';
import { RewardStrings } from '../../../localization/en';
import { useTypedSelector } from '../../../../store';
import tinycolor from 'tinycolor2';
import { VideoAppTheme } from '../../../../api/models/venue';

interface Props {
  image: string;
  brandColor?: string;
  logo: string | undefined;
  theme?: VideoAppTheme;
  isDisplayWelcomeVideo?: boolean;
}

const CreateVideoPreview: FC<Props> = ({
  image,
  brandColor = '#42a5f5',
  logo,
  theme,
  isDisplayWelcomeVideo,
}) => {
  const styles = useStyles();
  const textColor = tinycolor(brandColor).isDark() ? 'white' : 'black';
  const primaryCampaign = useTypedSelector((state) => state.campaigns.primaryCampaign);
  const welcomeVideoUrl = primaryCampaign?.welcomeVideoUrl;
  const showWelcomeVideo = primaryCampaign?.showWelcomeVideo;

  const showWVideo =
    typeof isDisplayWelcomeVideo === 'boolean' ? isDisplayWelcomeVideo : showWelcomeVideo;

  const {
    venue: { name: companyName },
  } = useTypedSelector((state) => state.venue);
  return (
    <IPhonePreview>
      {welcomeVideoUrl && showWVideo ? (
        <video
          className={styles.image}
          style={{ background: image ? 'none' : theme && theme === 'dark' ? '#000' : '#fff' }}
          src={welcomeVideoUrl}
        />
      ) : (
        <img
          className={styles.image}
          style={{ background: image ? 'none' : theme && theme === 'dark' ? '#000' : '#fff' }}
          src={image}
          alt="card-background"
        />
      )}
      {logo && <img src={logo} alt="company logo" className={styles.logo} />}
      {!logo && <Typography className={styles.companyName}>{companyName}</Typography>}
      {(!welcomeVideoUrl || !showWVideo) && (
        <Box className={styles.glassContainer}>
          <Typography className={styles.uploadText}>{RewardStrings.RecordOrUpload}</Typography>
        </Box>
      )}
      <Box className={`${styles.buttonsWrapper} ${showWVideo || image ? styles.gradient : ''}`}>
        <Box
          className={`${styles.button} ${styles.recordButton}`}
          sx={{ background: brandColor, color: textColor }}
        >
          {RewardStrings.RecordVideo}
        </Box>
        <Box className={`${styles.button} ${styles.uploadButton}`}>{RewardStrings.UploadVideo}</Box>
      </Box>
    </IPhonePreview>
  );
};

export default CreateVideoPreview;
