import * as React from 'react';
import { SVGProps } from 'react';
export const WatchAndLearnIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={42}
    height={40}
    viewBox="0 0 42 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={41.0137}
      y={10}
      width={21.1429}
      height={40}
      rx={10.5714}
      transform="rotate(90 41.0137 10)"
      fill="#D9D4FF"
      stroke="#5242EA"
      strokeWidth={0.689974}
    />
    <path
      d="M22.1572 19.1431L33.0144 19.1431"
      stroke="#1A1538"
      strokeWidth={0.689974}
      strokeLinecap="round"
    />
    <path
      d="M22.1572 22.5713L36.4429 22.5713"
      stroke="#1A1538"
      strokeWidth={0.689974}
      strokeLinecap="round"
    />
    <path
      d="M11.738 14C15.4504 14 18.46 17.0104 18.46 20.7229C18.46 24.4353 15.4504 27.4457 11.738 27.4457C8.02552 27.4457 5.01599 24.4353 5.01599 20.7229C5.01599 17.0104 8.02553 14 11.738 14Z"
      fill="white"
    />
    <path
      d="M14.0159 20.0483C14.4801 20.3163 14.4801 20.9864 14.0159 21.2545L10.6315 23.2085C10.1672 23.4765 9.58691 23.1414 9.58691 22.6054L9.58691 18.6974C9.58691 18.1613 10.1672 17.8263 10.6315 18.0943L14.0159 20.0483Z"
      fill="#1A1538"
    />
  </svg>
);
