import React, { FC } from 'react';
import { useSocialVerseCardStyles } from './SocialVerseCard.style';
import {
  isCardsView,
  isEducationalView,
  isGridView,
  isPerspectiveView,
  isSpheresView,
} from '../../../services/utilities';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import { BlockList } from './BlockList';
import { SpheresView } from './SpheresView';
import { PerspectiveView } from './PerspectiveView';
import { EdicationalView } from './EdicationalView';
import { EmptyList } from './SocialVerseCard';
import { ButtonTooltip } from '../../../common/components/LearnMoreTooltip/LearnMoreTooltip';
import { SocialVerseStrings } from '../../../common/localization/en';
import { GridView } from './GridView';

interface Props {
  socialverse: SocialVerseAllApiModel;
  handleAddVideo: (id: string) => void;
}

const SocialVersePreview: FC<Props> = ({ socialverse, handleAddVideo }) => {
  const classes = useSocialVerseCardStyles();

  return (
    <Grid item className={classes.blockWrapper}>
      {(isCardsView(socialverse.viewMode) ||
        isPerspectiveView(socialverse.viewMode) ||
        isGridView(socialverse.viewMode)) && (
        <Typography className={classes.blockTitle}>{socialverse.title}</Typography>
      )}
      {socialverse.numVideos >= 0 ? (
        <div
          style={{
            zIndex: 1000,
            overflowX: isSpheresView(socialverse.viewMode) ? 'unset' : 'auto',
            overflowY: isGridView(socialverse.viewMode) ? 'hidden' : 'auto',
            height: '100%',
          }}
          className={classes.socialverse}
        >
          {isCardsView(socialverse.viewMode) && <BlockList socialverse={socialverse} />}
          {isSpheresView(socialverse.viewMode) && <SpheresView socialverse={socialverse} />}
          {isPerspectiveView(socialverse.viewMode) && (
            <PerspectiveView
              socialverse={socialverse}
              videos={
                socialverse.topVideos.length > 3
                  ? [
                      ...socialverse.topVideos.slice(
                        0,
                        (socialverse.details?.numberOfPerspectiveCards || 3) - 1, //get first two videos
                      ),
                      ...socialverse.topVideos.slice(-1), //get last video
                    ]
                  : socialverse.topVideos
              }
              glowColor={socialverse.details?.glowColor}
            />
          )}
          {isGridView(socialverse.viewMode) && <GridView socialverse={socialverse} />}
          {isEducationalView(socialverse.viewMode) && <EdicationalView socialverse={socialverse} />}
        </div>
      ) : (
        <>
          {isCardsView(socialverse.viewMode) ? (
            <Grid className={classes.EmptyBoxSection}>
              <EmptyList handleClick={() => handleAddVideo(socialverse.id)} />
            </Grid>
          ) : (
            <Box className={classes.spheresContainer}>
              <ButtonTooltip
                title={SocialVerseStrings.ClickToAddVideo}
                icon={
                  <IconButton
                    className={classes.emptySpheres}
                    onClick={() => handleAddVideo(socialverse.id)}
                    sx={{ fontSize: '40px' }}
                  >
                    <AddRoundedIcon fontSize="inherit" />
                  </IconButton>
                }
              />
            </Box>
          )}
        </>
      )}
    </Grid>
  );
};

export default SocialVersePreview;
