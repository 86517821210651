import { Location } from 'history';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';

import { useModal } from './useModal';

export interface UseRouteLeavingGuardOptions {
  // Modal will not be triggered if user are redirecting to subroute that includes one of these stings
  ignoreSubroutes?: string[];
}

export const useRouteLeavingGuard = (
  enableGuard: boolean,
  { ignoreSubroutes }: UseRouteLeavingGuardOptions = {},
) => {
  const history = useHistory();
  const { open: openGuard, close: closeGuard, isOpen: showGuard } = useModal();
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [destinationLocation, setDestinationLocation] = useState<Location>();

  const handleNavigationAttempt = (nextLocation: Location): boolean => {
    if (ignoreSubroutes?.some((subroute) => nextLocation.pathname.includes(subroute))) {
      // allow to redirect to supported subroute
      return true;
    }

    if (!confirmedNavigation) {
      setDestinationLocation(nextLocation);
      openGuard();
      return false;
    }
    return true;
  };

  const confirmNavigation = () => {
    if (destinationLocation) {
      closeGuard();
      setConfirmedNavigation(true);
      setTimeout(() => {
        history.push(destinationLocation.pathname);
      }, 500);
    }
  };

  const cancelNavigation = () => {
    closeGuard();
  };

  const renderGuardComponent = () => {
    return <Prompt when={enableGuard} message={handleNavigationAttempt} />;
  };

  return {
    showGuard,
    confirmNavigation,
    cancelNavigation,
    renderGuardComponent,
  };
};
