import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px 70px 40px 40px',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  table: {
    marginTop: '24px',
    '& td': {
      cursor: 'default !important',
    },
    '& th': {
      fontSize: '12px !important',
      '&:nth-child(1)': {
        paddingLeft: '15px !important',
      },
      '&:nth-child(2)': {
        paddingLeft: '0px !important',
        '& div': {
          margin: '0 auto',
        },
      },
      '&:nth-child(3)': {
        paddingLeft: '0px !important',
        '& div': {
          margin: '0 auto',
        },
      },
      '&:nth-child(4)': {
        paddingLeft: '0px !important',
        '& div': {
          margin: '0 auto',
        },
      },
      '&:nth-child(5)': {
        paddingLeft: '0px !important',
        '& div': {
          margin: '0 auto',
        },
      },
      '&:nth-child(6)': {
        paddingLeft: '0px !important',
        '& div': {
          margin: '0 auto',
        },
      },
      '&:nth-child(7)': {
        '& div': {
          display: 'block',
          padding: '0px !important',
          margin: '0 auto !important',
          textAlign: 'center !important',
        },
      },
    },
    '& tr': {
      '& td': {
        cursor: 'pointer !important',
      },
      '& td:nth-child(2)': {
        textAlign: 'center',
        paddingLeft: '0px !important',
      },
      '& td:nth-child(3)': {
        textAlign: 'center',
      },
      '& td:nth-child(4)': {
        paddingLeft: '0px !important',
        display: 'table-cell',
        '& p': {
          height: '100%',
          margin: '0 auto !important',
          paddingLeft: '0px !important',
        },
      },
      '& td:nth-child(5)': {
        paddingLeft: '0px !important',
        display: 'table-cell',
        '& p': {
          height: '100%',
          margin: '0 auto !important',
          paddingLeft: '0px !important',
          textAlign: 'center',
        },
      },
      '& td:nth-child(6)': {
        paddingLeft: '0px !important',
        display: 'table-cell',
        '& p': {
          height: '100%',
          margin: '0 auto !important',
          paddingLeft: '0px !important',
          textAlign: 'center',
        },
      },
      '& td:last-child': {
        paddingLeft: '0px !important',
        '& div': {
          paddingLeft: '0px !important',
        },
      },
    },
  },
  cell: {
    fontSize: 'inherit',
    paddingLeft: '10px',
    color: theme.palette.common.deepDark,
  },
  usersCell: {
    fontSize: 'inherit',
    display: 'flex !important',
    justifyContent: 'center !important',
    width: '100px',
  },
  btnSummary: {
    width: '128px',
    height: '18px',
    color: '#5D5FEF',
    borderRadius: '30px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    background: 'rgba(82, 66, 234, 0.1)',
  },
}));
