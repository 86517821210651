import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  sliderWrapper: {
    position: 'relative',
  },
  navigationWrapper: {
    position: 'absolute',
    top: '-50px',
    right: '22px',
    display: 'flex',
  },
  navigation: {
    minWidth: 34,
    maxWidth: 34,
    maxheight: 34,
    borderRadius: '50%',
    border: '1px solid #EAECF0',
    backgroundColor: '#F9FAFB',
    stroke: '#344054',
    fontSize: '20px',

    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      stroke: '#D0D5DD',
    },
  },
}));
