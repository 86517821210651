import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { EffectCoverflow, Navigation, Pagination } from 'swiper';
import 'swiper/modules/effect-coverflow/effect-coverflow.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { SocialVerseAllApiModel, SocialVerseVideos } from '../../../api/models/socialVerse';
import { ArrowLeftIcon } from '../../../common/assets/newDesign/ArrowLeftIcon';
import { ArrowRightIcon } from '../../../common/assets/newDesign/ArrowRightIcon';
import { useStyles } from './Perspective.style';
import {
  clamp,
  formatDuration,
  getDisplayNameAndInitial,
  getVideoPoster,
} from '../../../services/utilities';
import { VideoCardIcons } from '../../../common/assets/newDesign/VideoCardIcons';
import { PictureIconView } from './BlockList';

interface Props {
  socialverse: SocialVerseAllApiModel;
  videos: SocialVerseVideos[];
  glowColor?: string;
}

const MIN_VIDEOS_AMOUNT = 3;
const MAX_VIDEOS_AMOUNT = 5;

export const PerspectiveView: FC<Props> = ({ videos, glowColor }) => {
  const classes = useStyles({ glowColor });

  const cardsAmount = clamp(
    videos.length - 1 + (videos.length % 2),
    MIN_VIDEOS_AMOUNT,
    MAX_VIDEOS_AMOUNT,
  );

  const placeholdersAmount =
    videos.length < MIN_VIDEOS_AMOUNT ? MIN_VIDEOS_AMOUNT - videos.length : 0;

  const videosToShow = (
    placeholdersAmount === 2
      ? [...videos, null, null]
      : placeholdersAmount === 1
      ? [...videos, null]
      : placeholdersAmount === 3
      ? [null, null, null]
      : videos
  ).slice(0, cardsAmount);

  const offset = Math.ceil(videosToShow.length / 2);
  const slicedVideos = [...videosToShow.slice(offset), ...videosToShow.slice(0, offset)];

  return (
    <Box className={classes.container} sx={{ pointerEvents: 'none', userSelect: 'none' }}>
      <Swiper
        effect={'coverflow'}
        grabCursor={false}
        centeredSlides={true}
        loop={false}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className={classes.swiperContainer}
        initialSlide={Math.floor((videosToShow.length - 1) / 2)}
      >
        {slicedVideos.map((v, i) => (
          <SwiperSlide
            virtualIndex={i - 1}
            className={classes.swiperSlide}
            style={{ width: 'auto !important' }}
          >
            {v !== null ? (
              <>
                <Box className={classes.info}>
                  <Typography className={classes.author}>
                    {getDisplayNameAndInitial(v.userName)}
                  </Typography>
                  <Typography className={classes.duration}>
                    {formatDuration(v.duration || 0, { twoDigitsMinutes: true })}
                  </Typography>
                </Box>
                <Box className={classes.icons}>
                  <VideoCardIcons />
                </Box>
                <img
                  className={classes.img}
                  src={getVideoPoster(v.url) || v.thumbnailUrl}
                  alt="slide_image"
                />
              </>
            ) : (
              <div
                style={{
                  width: '93px',
                  height: '172px',
                  borderRadius: '6px',
                  background:
                    'linear-gradient(180deg, rgba(17, 17, 17, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(0deg, #ADADAD 0%, #ADADAD 100%)',
                }}
                className={classes.img}
              >
                {i === 1 && (
                  <>
                    <Box style={{ position: 'absolute', top: '38%', left: '50%' }}>
                      <PictureIconView />
                    </Box>
                    <Box className={classes.info}>
                      <Typography className={classes.author}>First L</Typography>
                      <Typography className={classes.duration}>00:23</Typography>
                    </Box>
                    <Box className={classes.icons}>
                      <VideoCardIcons />
                    </Box>
                  </>
                )}
              </div>
            )}
          </SwiperSlide>
        ))}

        <div className={classes.sliderControler}>
          <div className={`${classes.swiperButtonPrev} ${classes.sliderArrow}`}>
            <ArrowLeftIcon />
          </div>
          <Box className={classes.progressBar}>
            <Box
              style={{
                width: '16px',
                height: '6px',
                maxWidth: '80px',
              }}
              className={classes.progressIndicator}
            ></Box>
          </Box>
          <div className={`${classes.swiperButtonNext} ${classes.sliderArrow}`}>
            <ArrowRightIcon />
          </div>
        </div>
      </Swiper>
    </Box>
  );
};
