import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  backButton: {
    width: '235px',
    padding: '12px 16px',
    backgroundColor: 'white',
    borderRadius: '8px',
    margin: '12px 0 0 30px',
    display: 'flex',

    '& p': {
      color: '#475467',
      fontSize: '12px',
      fontWeight: '600',
      marginLeft: '14px',
    },
  },
}));
