import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '360px',
    height: '100%',
    border: '1px solid #D0D5DD',
    background: '#fff',
    borderRadius: '12px',
    padding: '20px 24px 0',
    position: 'relative',
  },
  titleText: {
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: 600,
    color: '#101828',
    marginBottom: '4px',
  },
  divider: {
    position: 'absolute',
    borderTop: '1px solid #EAECF0',
    top: '108px',
    left: '0',
    width: '100%',
  },
  downloadQrText: {
    marginTop: '30px',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
    color: '#475467',
  },
  scanCodeText: {
    marginTop: '8px',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
    color: '#475467',
  },
  qrWrapper: {
    display: 'flex',
    marginTop: '24px',
    marginLeft: '12px',
    alignItems: 'center',
  },
  qrCode: {
    transform: 'scale(1.25)',
    marginRight: '20px',
  },
  selectSection: {
    marginLeft: '15px',
  },
  selectStyleText: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: '#344054',
  },
  selectWrapper: {
    position: 'relative',
  },
  selectButton: {
    width: '144px',
    height: '27px',
    borderRadius: '6px',
    background: '#F9FAFB',
    border: '1px solid #D0D5DD',
    color: '#667085',
    marginTop: '10px',
    justifyContent: 'space-between',

    fontSize: '12px',
    '&:hover': {
      border: '1px solid #D0D5DD',
    },
  },
  selectItemsWrapper: {
    position: 'absolute',
    width: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    background: '#fff',
  },
  selectItem: {
    justifyContent: 'flex-start',
    color: '#101828',
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 500,
    background: '#fff',
    zIndex: 1,
    '&:hover': {
      zIndex: 10,
      background: '#F9FAFB',
    },
  },
  downloadBtnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '45px',
    alignItems: 'center',
  },
  btnDownload: {
    width: '105px',
    height: '40px',
    borderRadius: '8px',
    '& p': {
      fontWeight: 600,
      fontSize: '14px',
    },
  },
}));
