import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  iconBadge: {
    background: 'linear-gradient(180deg, #D9D5FB 0%, #5242EA 100%)',
    boxShadow: '4px 9px 14px rgba(155, 156, 142, 0.26)',
    borderRadius: '8px 0px 0px 8px',
    border: '1px solid #67CE67',
    width: '124px',
    height: '152px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
  },
  icon: {
    width: '86px',
    height: '86px',
    border: '1px solid #67CE67',
    borderRadius: '50%',
    boxShadow: '0px 9px 20px rgba(20, 21, 61, 0.15)',
    backdropFilter: 'blur(10px)',
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  stepTitle: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: '#525252',
    marginBottom: '10px',
  },
  stepDescription: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '21px',
    letterSpacing: '0.02em',
    color: '#484848',
  },
}));
