import { FC } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { useStyles } from './SettingsSidebar.style';
import { Tooltip } from '../../../common/components/Tooltip/Tooltip';
import { Toggle } from '../../../common/components/toggle/CustomToggle';
import { TextFieldComponent } from '../../../common/components/TextField/TextField';
import { CustomizationSocialVerse, SocialVerseStrings } from '../../../common/localization/en';
import { FormikProps } from 'formik';
import { FormikValues } from './SocialVerseSettingsPage';
import { TypeSocialVerse } from '../../../common/constants/constants';
import { useClientType } from '../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
import { useParams } from 'react-router';
import { CustomColorPickerWithInput } from '../../../common/components/CustomColorPicker/CustomColorPickerWithInput';
import Select from '../../../common/components/Select/Select';
import { useTypedSelector } from '../../../store';
import { MAX_SUPPORTING_TEXT_LENGTH, MAX_TITLE_LENGTH } from './SocialVerseSettingsPage.helpers';

interface Props {
  formik: FormikProps<FormikValues>;
  viewMode: TypeSocialVerse;
  setColorError: (value: boolean) => void;
}

export const SettingsSidebar: FC<Props> = ({ formik, viewMode, setColorError }) => {
  const classes = useStyles();
  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();
  const params = useParams<{ id: string }>();

  const { currentSocialVerseVideos } = useTypedSelector((state) => state.SocialVerseSlice);
  const { venue } = useTypedSelector((state) => state.venue);

  const numberOfPerspectiveCards = [
    { value: 3, name: 3 },
    { value: 5, name: 5 },
    { value: 7, name: 7 },
    { value: 9, name: 9 },
  ].filter((card) => card.value <= currentSocialVerseVideos.length || card.value === 3);

  const numberOfGridRows = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 4, name: 4 },
    { value: 6, name: 6 },
  ];

  return (
    <Grid className={classes.container}>
      <Typography className={classes.customise}>
        {CustomizationSocialVerse.CustomizeYourSocialVerse}
      </Typography>
      {viewMode === TypeSocialVerse.Perspective && (
        <Box className={classes.selectWrapper}>
          <Grid container alignItems="center">
            <Typography className={classes.sectionTitle}>
              {SocialVerseStrings.NumberOfCards}
            </Typography>
            <Tooltip
              title={SocialVerseStrings.NumberOfCardsTooltip}
              text={SocialVerseStrings.NumberOfCardsTooltipText}
            />
          </Grid>
          <Select
            width="115px"
            options={numberOfPerspectiveCards}
            value={formik.values.numberOfPerspectiveCards}
            onChange={(value: number) => {
              formik.setFieldValue('numberOfPerspectiveCards', value);
              trackEvent(SocialVerseEventNames.socialVerseNumberOfCards, {
                value: value.toString(),
              });
            }}
          />
        </Box>
      )}
      {viewMode === TypeSocialVerse.Grid && (
        <Box className={classes.selectWrapper}>
          <Grid container alignItems="center">
            <Typography className={classes.sectionTitle}>
              {SocialVerseStrings.NumberOfRows}
            </Typography>
            <Tooltip title={SocialVerseStrings.NumberOfRowsTooltip} />
          </Grid>
          <Select
            width="115px"
            options={numberOfGridRows}
            value={formik.values.numberOfGridRows}
            onChange={(value: number) => {
              formik.setFieldValue('numberOfGridRows', value);
              trackEvent(SocialVerseEventNames.socialVerseNumberOfRows, {
                value: value.toString(),
              });
            }}
          />
        </Box>
      )}
      <Divider className={classes.configDivider} color="#DCDBE4" />
      {viewMode !== TypeSocialVerse.Spheres && viewMode !== TypeSocialVerse.Circles && (
        <>
          <Box>
            <Grid container className={classes.sectionWrapper}>
              {viewMode !== TypeSocialVerse.Educational && (
                <Toggle
                  value={formik.values.showTitle}
                  onChange={() => {
                    formik.setFieldValue('showTitle', !formik.values.showTitle);
                    trackEvent(SocialVerseEventNames.toggleSocialVerseTitle);
                  }}
                />
              )}
              <Grid container alignItems="center">
                <Typography className={classes.sectionTitle}>{SocialVerseStrings.Title}</Typography>
                <Tooltip title={SocialVerseStrings.TitleTooltip} />
              </Grid>
            </Grid>
            <TextFieldComponent
              placeholder={
                isHealthCare
                  ? SocialVerseStrings.SeeWhatCustomersSay.replace('customers', 'patients')
                  : SocialVerseStrings.SeeWhatCustomersSay
              }
              name="title"
              value={formik.values.title ? formik.values.title : ''}
              onChange={(event) => {
                formik.handleChange(event);
                if ((event.target.value as string)?.length > MAX_TITLE_LENGTH + 1) {
                  formik.setFieldValue('title', formik.values.title);
                }
                trackEvent(SocialVerseEventNames.socialVerseTitleTyped, {
                  socialVerseId: params.id,
                  value: event.target.value,
                });
              }}
              onBlur={formik.handleBlur}
              setFieldTouched={formik.setFieldTouched}
              errorText={!!formik.errors.title ? (formik.errors.title as string) : undefined}
              fullWidth
              helperText
              className={classes.titleInput}
              disabled={!formik.values.showTitle}
            />
            <Typography className={classes.titleInfo}>
              <b>{SocialVerseStrings.Caution}</b> {SocialVerseStrings.TitleInfo}
            </Typography>
          </Box>
          {viewMode === TypeSocialVerse.Educational && (
            <Box>
              <Grid container className={classes.sectionWrapper}>
                <Toggle
                  value={formik.values.showSupportingText}
                  onChange={() => {
                    formik.setFieldValue('showSupportingText', !formik.values.showSupportingText);
                    trackEvent(SocialVerseEventNames.toggleSocialVerseSupportingText);
                  }}
                />
                <Grid container alignItems="center">
                  <Typography className={classes.sectionTitle}>
                    {SocialVerseStrings.SupportingText}
                  </Typography>
                  <Tooltip title={SocialVerseStrings.SupportingTextTooltip} />
                </Grid>
              </Grid>
              <TextFieldComponent
                placeholder={
                  isHealthCare
                    ? SocialVerseStrings.SeeWhatCustomersSay.replace('customers', 'patients')
                    : SocialVerseStrings.SeeWhatCustomersSay
                }
                name="supportingText"
                value={formik.values.supportingText ? formik.values.supportingText : ''}
                onChange={(event) => {
                  formik.handleChange(event);
                  if ((event.target.value as string)?.length > MAX_SUPPORTING_TEXT_LENGTH + 1) {
                    formik.setFieldValue('supportingText', formik.values.supportingText);
                  }
                  trackEvent(SocialVerseEventNames.socialVerseSupportingTextTyped, {
                    socialVerseId: params.id,
                    value: event.target.value,
                  });
                }}
                onBlur={formik.handleBlur}
                setFieldTouched={formik.setFieldTouched}
                errorText={
                  formik.values.supportingText && !!formik.errors.supportingText
                    ? (formik.errors.supportingText as string)
                    : undefined
                }
                fullWidth
                helperText
                className={classes.titleInput}
                disabled={!formik.values.showSupportingText}
              />
            </Box>
          )}
          <Divider className={classes.configDivider} color="#DCDBE4" />
        </>
      )}
      {(viewMode === TypeSocialVerse.Cards ||
        viewMode === TypeSocialVerse.Perspective ||
        viewMode === TypeSocialVerse.Grid) && (
        <Box>
          <Grid container className={classes.sectionWrapper}>
            <Toggle
              value={formik.values.showVideoCounter}
              onChange={() => {
                formik.setFieldValue('showVideoCounter', !formik.values.showVideoCounter);
                trackEvent(SocialVerseEventNames.toggleSocialVerseVideoCounter);
              }}
            />
            <Grid container alignItems="center">
              <Typography className={classes.sectionTitle}>
                {SocialVerseStrings.VideoCounter}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      {viewMode !== TypeSocialVerse.Educational && (
        <Box>
          <Grid container className={classes.sectionWrapper}>
            <Toggle
              value={formik.values.showUserInfo}
              onChange={() => {
                formik.setFieldValue('showUserInfo', !formik.values.showUserInfo);
                trackEvent(SocialVerseEventNames.toggleSocialVerseCreatorInfo);
              }}
            />
            <Grid container alignItems="center">
              <Typography className={classes.sectionTitle}>
                {isHealthCare
                  ? SocialVerseStrings.CreatorInfo_TruBlu
                  : SocialVerseStrings.CreatorInfo}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.configInfo}>
            {isHealthCare
              ? CustomizationSocialVerse.ToggleUserInformationOnOff_TruBlu
              : CustomizationSocialVerse.ToggleUserInformationOnOff}
          </Typography>
        </Box>
      )}
      {viewMode === TypeSocialVerse.Educational && (
        <Box>
          <Grid container className={classes.sectionWrapper}>
            <Toggle
              value={formik.values.withHoverPreview}
              onChange={() => {
                formik.setFieldValue('withHoverPreview', !formik.values.withHoverPreview);
                trackEvent(SocialVerseEventNames.toggleSocialVerseHoverPreview);
              }}
            />
            <Grid container alignItems="center">
              <Typography className={classes.sectionTitle}>
                {SocialVerseStrings.HoverPreview}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={classes.configInfo}>
            {SocialVerseStrings.HoverPreviewText}
          </Typography>
        </Box>
      )}
      {viewMode !== TypeSocialVerse.Spheres && viewMode !== TypeSocialVerse.Circles && (
        <>
          <Divider className={classes.configDivider} color="#DCDBE4" />
          <Box>
            <Grid container className={classes.sectionWrapper}>
              <Toggle
                value={formik.values.captionEnabled}
                onChange={() => {
                  formik.setFieldValue('captionEnabled', !formik.values.captionEnabled);
                  if (venue.distributorName !== 'TEST') {
                    trackEvent(SocialVerseEventNames.toggleSocialVerseCaptionEnabled, {
                      value: !formik.values.captionEnabled,
                    });
                  }
                }}
              />
              <Grid container alignItems="center">
                <Typography className={classes.sectionTitle}>
                  {SocialVerseStrings.ClosedCaptions}
                </Typography>
              </Grid>
            </Grid>
            <Typography className={classes.configInfo}>
              {CustomizationSocialVerse.ToggleCaptions}
            </Typography>
          </Box>
        </>
      )}
      <Divider className={classes.configDivider} color="#DCDBE4" />

      <Box>
        <Grid container className={classes.sectionWrapper}>
          <Toggle
            value={formik.values.withGlow}
            onChange={() => {
              formik.setFieldValue('withGlow', !formik.values.withGlow);
              trackEvent(SocialVerseEventNames.toggleSocialVerseColorPicker);
            }}
          />
          <Grid container alignItems="center">
            <Typography className={classes.sectionTitle}>
              {SocialVerseStrings.PickVideoGlowColor}
            </Typography>
            <Tooltip title={SocialVerseStrings.AccentColorTooltip} />
          </Grid>
        </Grid>
        <CustomColorPickerWithInput
          color={formik.values.glowColor}
          onColorChange={(color) => {
            formik.setFieldValue('glowColor', color);
            trackEvent(SocialVerseEventNames.socialVerseColorPick, {
              socialVerseId: params.id,
              value: color,
            });
          }}
          displayCustomInput
          title={SocialVerseStrings.ColorPalette}
          setFieldError={formik.setFieldError}
          errorText={formik.errors.glowColor}
          setColorError={setColorError}
          tooltipText={SocialVerseStrings.AccentColorTooltip}
          disabled={!formik.values.withGlow}
        />
      </Box>
    </Grid>
  );
};
