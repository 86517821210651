import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CampaignActivitiesTypes } from '../../../api/models/campaigns';
import { SearchTargets } from '../../../api/models/searchFilter';
import { ActionBar, ActiveModal } from '../../../common/components/ActionBar/ActionBar';
import { activeModalLiteral } from '../../../common/components/ActionBar/ActionBarModals';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getActiveIncentiveCampaignActivities } from '../../../store/slices/campaignsSlice';

import { getSearchFilters } from '../../../store/slices/searchFiltersSlice';

import { TabItem, TabsSelect } from '../../../common/components/TabsSelect/TabsSelect';
import { HeroesPageStrings } from '../../../common/localization/en';
import { useClientType } from '../../../services/hooks/useClientType';
import { HeroesTable } from './heroesTable/HeroesTable';
import { ContentContainer } from './TableSection.style';
import { useTabs } from '../../../common/components/Tabs/useTabs';

interface TableSectionProps {
  showActionBar: boolean;
}

export const TableSection: FC<TableSectionProps> = ({ showActionBar }) => {
  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { selectedRows } = useTypedSelector((state) => state.videos);
  const activities = useTypedSelector((state) => state.campaigns.activeCampaign?.activities);
  const { isTrubluClient } = useClientType();
  const { activeUsersCount, pendingUsersCount } = useTypedSelector((state) => state.heroes);

  const { activeTab, setActiveTab } = useTabs(2);

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  /* const clientType = useTypedSelector((state) => state.venue.venue.clientType); */
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  useEffect(() => {
    if (venueId) {
      dispatch(getSearchFilters({ venueId, target: SearchTargets.USERS }));
    }
  }, [venueId, dispatch]);

  useEffect(() => {
    if (activeCampaign?.id) {
      dispatch(getActiveIncentiveCampaignActivities(activeCampaign?.id));
    }
  }, [dispatch, activeCampaign?.id]);

  const handleBtnClick = (name: typeof activeModal) => {
    setActiveModal(name);
  };

  const handleClose = () => {
    setActiveModal(null);
  };

  const headers = [
    { label: 'User Name', key: 'name' },
    { label: 'Status', key: 'status' },
    { label: 'Last activity', key: 'lastVideoCreatedAt' },
    { label: 'Videos', key: 'shares' },
    { label: 'Clicks', key: 'videoCtaClicks' },
    { label: 'Total Points', key: 'totalPoints' },
    { label: 'Rewards owed', key: 'rewardsFulfilled' },
  ];

  const hasActiveCampaignBonusPoints =
    activities &&
    activities.find((activity) => activity.activityType === CampaignActivitiesTypes.BonusPoints);
  const isAwardsBonusPointsActive = activeCampaign && hasActiveCampaignBonusPoints ? true : false;

  const tabsList: TabItem[] = [
    {
      tabName: isTrubluClient ? HeroesPageStrings.ActivePatients : HeroesPageStrings.ActiveCreators,
      usersCount: activeUsersCount?.toString(),
      tabContent: (
        <HeroesTable
          showActionBar={!showActionBar}
          hasVideos
          activeTab={activeTab}
          totalItems={activeUsersCount || 0}
        />
      ),
    },
    {
      tabName: HeroesPageStrings.PendingCreators,
      usersCount: pendingUsersCount?.toString(),
      tabContent: (
        <HeroesTable
          showActionBar={!showActionBar}
          hasVideos={false}
          activeTab={activeTab}
          totalItems={pendingUsersCount || 0}
        />
      ),
    },
  ];

  return (
    <ContentContainer>
      <Box>
        {showActionBar && (
          <ActionBar
            setActiveModal={(name) => handleBtnClick(name)}
            selectedRowsLength={selectedRows?.length}
            isAwardsBonusPointsActive={isAwardsBonusPointsActive}
          />
        )}
        {/* could be refactored in the end ↓ */}
        {activeModal === 'DOWNLOAD_CSV' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, headers)}
        {activeModal === 'ASSIGN_BONUS_POINTS' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, selectedRows ?? [])}
        {activeModal === 'DOWNLOAD_CSV_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_TO_PLUGIN_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_BONUS_POINTS_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}

        {!showActionBar && (
          <TabsSelect onTrackEvent={() => {}} tabsList={tabsList} onTabChange={setActiveTab} />
        )}
      </Box>
    </ContentContainer>
  );
};
