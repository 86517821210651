import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FSModal } from '../../../common/components/modal/Modal';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { InviteModalStrings } from '../../../common/localization/en';
import { useClientType } from '../../../services/hooks/useClientType';
import { theme } from '../../../theme';
import CheckedStepperIcon from '../../../common/assets/QuickStartIcons/CheckedStepperIcon';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SettingsEvents } from '../../../common/constants/events/settingsEvents';

export const useClasses = makeStyles((theme) => ({
  title: {
    width: '100%',
    height: '120px',
    display: 'grid',
    placeItems: 'center',
    fontWeight: '500',
    fontSize: '26px',
    color: '#000000',
  },

  description: {
    marginTop: '25px',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '30px',
    letterSpacing: '0.03em',
    color: theme.palette.common.gray,
    whiteSpace: 'pre-line',
  },

  btn: {
    borderRadius: '10px',
    height: '45px',
    fontWeight: '400',
  },
  previewWrapper: {
    minHeight: '600px',
    background: '#F5F6F8',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  previewImage: {
    width: '450px',
    height: '512px',
    zIndex: 2,
    marginBottom: '30px',
  },
  info: {
    padding: '30px 50px 50px 50px',
  },
  btnsWrapper: {
    display: 'flex',
    gap: '15px',
    marginTop: '60px',
  },
  previewTitle: {
    marginTop: '30px',
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight: 500,
    color: '#1A1538',
  },
  stepperWrapper: {
    width: '450px',
    height: '46px',
    background: 'white',
    borderRadius: '40px',
    margin: '30px 0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
  },
  stepWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    borderRadius: '20px',
  },
  stepText: {
    color: '#5242EA',
    fontWeight: 500,
    fontSize: '13px',
  },
  keyImage: {
    width: '300px',
    height: '130px',
    margin: '0 auto',
  },
  descrStepperWrapper: {
    marginTop: '15px',
    display: 'flex',
    gap: '15px',
    flexDirection: 'column',
  },
  descrStep: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  stepIcon: {
    background: '#00BF2A',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'grid',
    placeItems: 'center',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: 400,
  },
  descrStepText: {
    color: '#8B89A0',
    letterSpacing: '0.02em',
    fontWeight: 500,
    fontSize: '15px',
  },
  btnSkip: {
    color: '#525252',
    textDecoration: 'underline',
    fontWeight: 400,
    fontSize: '14px',
  },
}));

interface WelcomeModalProps {
  isModalOpen: boolean;
  toggleIsWelcomeModal: () => void;
  withNotNowButton?: boolean;
}

export const WelcomeModal: FC<WelcomeModalProps> = ({
  isModalOpen,
  toggleIsWelcomeModal,
  withNotNowButton,
}) => {
  const classes = useClasses();
  const { isHealthCare, isTrubluClient } = useClientType();
  const history = useHistory();
  const { pathname } = useLocation();
  const isDashboard = pathname === WebsiteRoutes.DashboardHeroes;
  const { trackEvent } = useTrackEvent();

  const handleClick = () => {
    if (isDashboard) {
      toggleIsWelcomeModal();
      history.push(WebsiteRoutes.IntegrationsSmsInvite, {
        from: WebsiteRoutes.DashboardHeroes,
      });
    } else {
      toggleIsWelcomeModal();
      trackEvent(SettingsEvents.invites_welcome_modal_closed);
    }
  };

  return isModalOpen ? (
    <FSModal
      onClose={() => {
        toggleIsWelcomeModal();
        trackEvent(SettingsEvents.invites_welcome_modal_closed);
      }}
      modalIsOpen={isModalOpen}
      width="1013px"
      backgroundColor={theme.palette.common.white}
      padding="0"
      rounded="30px"
      closeBtn={false}
      blur
    >
      <Box className={classes.title}>
        {isHealthCare ? InviteModalStrings.TitleTrublu : InviteModalStrings.Title}
      </Box>

      <Grid container justifyContent="center">
        <Grid xs={6} className={classes.info}>
          <img src="/InvitesKeyImage.png" alt="" className={classes.keyImage} />
          <Typography className={classes.description}>
            {isHealthCare ? InviteModalStrings.Description_TruBlu : InviteModalStrings.Description}
          </Typography>
          <Box className={classes.descrStepperWrapper}>
            {(isHealthCare
              ? [
                  InviteModalStrings.UploadDescrStepTrublu,
                  InviteModalStrings.SendDescrStep,
                  InviteModalStrings.CreateDescrStepTrublu,
                ]
              : [
                  InviteModalStrings.UploadDescrStep,
                  InviteModalStrings.SendDescrStep,
                  InviteModalStrings.CreateDescrStep,
                ]
            ).map((stepText, index) => (
              <Box className={classes.descrStep}>
                <Box className={classes.stepIcon}>{index + 1}</Box>
                <Typography className={classes.descrStepText}>{stepText}</Typography>
              </Box>
            ))}
          </Box>

          <Box className={classes.btnsWrapper}>
            <Button
              size="large"
              onClick={handleClick}
              color="primary"
              variant="contained"
              className={classes.btn}
            >
              {isHealthCare ? InviteModalStrings.Button_Trublu : InviteModalStrings.Button}
            </Button>
            {withNotNowButton && (
              <Button
                onClick={() => {
                  toggleIsWelcomeModal();
                }}
                variant="text"
                className={classes.btnSkip}
              >
                {InviteModalStrings.NotNow}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid xs={6} className={classes.previewWrapper}>
          <Typography className={classes.previewTitle}>
            {isHealthCare ? InviteModalStrings.PreviewTitleTrublu : InviteModalStrings.PreviewTitle}
          </Typography>
          <Box className={classes.stepperWrapper}>
            {[
              InviteModalStrings.ImportStep,
              InviteModalStrings.MatchStep,
              InviteModalStrings.SendStep,
            ].map((stepText, index) => (
              <Box
                className={classes.stepWrapper}
                sx={{
                  background: index === 0 ? '#E6E3FF' : 'white',
                  padding: index === 0 ? '2px 15px 2px 5px' : '0',
                }}
              >
                <CheckedStepperIcon color="#00BF29" scale={0.8} />
                <Typography className={classes.stepText}>{stepText}</Typography>
              </Box>
            ))}
          </Box>
          <img
            className={classes.previewImage}
            src={isTrubluClient ? '/InvitesTrubluImage.png' : '/InvitesSvImage.png'}
            alt="preview"
          />
        </Grid>
      </Grid>
    </FSModal>
  ) : null;
};
