import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { isCreateNewCampaign?: boolean }>((theme: Theme) => ({
  btn: {
    height: '40px !important',
    padding: '8px 20px !important',
  },
  btnWrapper: ({ isCreateNewCampaign }) => ({
    display: 'flex',
    height: '40px',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: isCreateNewCampaign ? '10px' : '30px',
  }),
  subtitle: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#1A1538',
  },
  messageBuilderWrapper: {
    '& .charsLimit': {
      fontSize: '12px !important',
      top: '-32px !important',
    },
  },
}));
