import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './InviteOption.styles';
import { CheckmarkIcon } from '../../assets/newDesign/CheckmarkIcon';

interface Props {
  title: string;
  text: string;
  isActive: boolean;
  onClick: () => void;
}

const InviteOption: FC<Props> = ({ title, text, isActive, onClick }) => {
  const styles = useStyles();

  return (
    <Box className={`${styles.container} ${isActive && styles.containerActive}`} onClick={onClick}>
      <CheckmarkIcon className={`${styles.checkmark} ${isActive && styles.checkmarkActive}`} />
      <Typography className={`${styles.title} ${isActive && styles.titleActive}`}>
        {title}
      </Typography>
      <Typography className={styles.text}>{text}</Typography>
    </Box>
  );
};

export default InviteOption;
