import React, { FC } from 'react';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { CommonStrings } from '../../../common/localization/en';
import { VideoStyleList } from './VideoStyleList';
import { useBusinessVideoStylePreviewStyles } from './BusinessVideoStylePreview.style';
import {
  activityTypesContent,
  diagramData,
  videoStyleContent,
} from './BusinessVideoStylePreview.helper';
import { useTypedSelector } from '../../../store';
import DiagramBackground from '../../../common/assets/QuickStartIcons/DiagramBackground';
import { CustomerActivitiesType } from '../../../api/models/campaigns';
import { useClientType } from '../../../services/hooks/useClientType';

interface PreviewProps {
  definedPreviewType?: CustomerActivitiesType;
  isVideoStylePreview?: boolean;
}

export const BusinessVideoStylePreview: FC<PreviewProps> = ({
  definedPreviewType,
  isVideoStylePreview,
}) => {
  const styles = useBusinessVideoStylePreviewStyles();
  const { isHealthCare } = useClientType();
  const { campaignObjective } = useTypedSelector((state) => state.createCampaign);
  const previewType = definedPreviewType ? definedPreviewType : campaignObjective;
  const previewContent = isVideoStylePreview
    ? videoStyleContent
    : activityTypesContent[campaignObjective];

  const { title, subTitle, diagramLegendTitle, description, descriptionList, diagramIcon } =
    previewContent;

  return (
    <Box className={styles.Container}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', height: '42px' }}>
        {false && (
          <IconButton className={styles.QuestionButton}>
            <QuestionMarkRoundedIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
      <Typography className={styles.Title}>{title}</Typography>
      <Typography className={styles.SubTitle}>
        {isHealthCare ? subTitle.replace(`creator`, `patient`) : subTitle}
      </Typography>

      {isVideoStylePreview ? (
        <VideoStyleList descriptionList={descriptionList} isHealthCare={isHealthCare} />
      ) : (
        <>
          <Box width={'488px'} marginTop={'20px'} marginBottom={'30px'}>
            <Divider />
          </Box>
          <Box className={styles.Diagram}>
            <VideoTypeLegend previewType={previewType} diagramLegendTitle={diagramLegendTitle} />
            <Box sx={{ position: 'relative' }} className="something">
              {diagramIcon}
              <DiagramBackground />
            </Box>
          </Box>
          <Typography className={styles.Description}>
            {isHealthCare ? description.replace(`creator`, `patient`) : description}
          </Typography>
        </>
      )}
    </Box>
  );
};

interface VideoTypeLegendProps {
  previewType: string;
  isHorizontal?: boolean;
  diagramLegendTitle?: string;
}

export const VideoTypeLegend: FC<VideoTypeLegendProps> = ({
  previewType,
  isHorizontal,
  diagramLegendTitle,
}) => {
  const styles = useBusinessVideoStylePreviewStyles();

  return (
    <>
      <Box
        className={styles.DiagramLegend}
        sx={{
          position: isHorizontal ? 'unset !important' : '',
          transform: isHorizontal ? 'none !important' : '',
          flexDirection: isHorizontal ? 'row  !important' : '',
        }}
      >
        {isHorizontal && (
          <Box sx={{ marginRight: '35px' }}>{diagramData[previewType].staticDiagram}</Box>
        )}
        {!isHorizontal && (
          <Box sx={{ transform: 'scale(0.66667)', marginBottom: '-12px' }}>
            {diagramData[previewType].icon}
          </Box>
        )}
        {!isHorizontal && (
          <Typography className={styles.DiagramLegendTitle}>{diagramLegendTitle}</Typography>
        )}
        <Box>
          <Typography className={styles.DiagramLegendSubTitle}>
            {CommonStrings.RewardEligibility}
          </Typography>
          <Box>
            <Box display={'flex'} mb="4px">
              <Typography className={[styles.DiagramLegendIndicator, styles.Videos].join(' ')}>
                {diagramData[previewType].videos}
              </Typography>
              <Typography className={styles.DiagramLegendIndicatorText}>
                {CommonStrings.Videos}
              </Typography>
            </Box>
            <Box display={'flex'} mb="4px">
              <Typography className={[styles.DiagramLegendIndicator, styles.Views].join(' ')}>
                {diagramData[previewType].views}
              </Typography>
              <Typography className={styles.DiagramLegendIndicatorText}>
                {CommonStrings.Views}
              </Typography>
            </Box>
            <Box display={'flex'} mb="4px">
              <Typography
                className={[styles.DiagramLegendIndicator, styles.Clickthroughs].join(' ')}
              >
                {diagramData[previewType].clickthrouhs}
              </Typography>
              <Typography className={styles.DiagramLegendIndicatorText}>
                {CommonStrings.Clickthroughs}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
