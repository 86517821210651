import styled from 'styled-components';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin: 24px 24px 0 16px;
  padding: 32px 24px;
  box-sizing: border-box;
  border-radius: 16px;
`;

export const StyledConfirmText = styled.p`
  color: var(--darkBlue);
  font-size: 24px;
  font-weight: 400;
  text-align: center;
`;

export const SectionLayout = styled.div`
  width: 100%;
  padding: 16px 24px;
  display: grid;
`;

export const useVideosTableStyles = makeStyles((theme: Theme) => ({
  SVVideosTableStyles: {
    '& th': {
      '&:nth-child(1)': {
        // backgroundColor: 'blue',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8)': {
        textAlign: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(10) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      '&:nth-child(1)': {
        // backgroundColor: 'red',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7) div': {
        margin: '0 auto',
      },
      '&:nth-child(8)': {
        paddingTop: '10px',
        paddingLeft: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },

  SVTopGolfVideosTableStyles: {
    '& th': {
      '&:nth-child(1)': {
        // backgroundColor: 'blue',
      },
      '&:nth-child(2) div': {
        transform: 'translateX(-30%)',
      },
      '&:nth-child(3) div': {
        margin: '0 auto',
      },
      '&:nth-child(4) div': {
        margin: '0 auto',
      },
      '&:nth-child(5)': {
        margin: '0 auto',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6)': {
        margin: '0 auto',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7)': {
        margin: '0 auto',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8)': {
        textAlign: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      '&:nth-child(1)': {
        // backgroundColor: 'red',
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6) div': {
        margin: '0 auto',
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7) div': {
        margin: '0 auto',
      },
      '&:nth-child(8)': {
        padding: '0 10px 0 10px',
        textAlign: 'center',
      },
      '&:nth-child(8)>div': {
        width: '110px',
        minWidth: '110px !important',
      },
      '&:nth-child(8)>div>div': {
        width: '110px',
        minWidth: '110px !important',
      },
      '&:nth-child(8) span': {
        fontSize: '12px !important',
      },
    },
  },
}));
