import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { VenueAPIModel } from '../../../../api/models/venue';
import { VenuesListDTO } from '../../../../store/storeModels';
import { UserRoles } from '../../../constants/constants';
import { SearchObject } from '../../SearchAccordion/SearchAccordion.helper';

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    boxShadow: 'unset',
  },
  webappLink: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '8px 20px',
    backgroundColor: '#EAF4FF',
    borderRadius: 50,
    color: theme.palette.primary.main,
    lineHeight: 1.4,
  },
  disabledWebappLink: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    padding: '8px 20px',
    backgroundColor: '#EAF4FF',
    borderRadius: 50,
    color: theme.palette.common.Text.secondary,
    lineHeight: 1.4,
    marginRight: 30,
    pointerEvents: 'none',
    cursor: 'default',
  },
  menuIcon: {
    color: theme.palette.common.black,

    [theme.breakpoints.down('lg')]: {
      display: 'block',
      marginRight: 20,
    },

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
    color: theme.palette.common.black,
    zIndex: 3,
    borderBottom: '1px solid #DCDBE4',
    // height: 80,
    backgroundColor: theme.palette.common.white,
    '& .MuiAccordionSummary-content': {
      transition: 'none',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
    minHeight: 'auto',
  },
  toolBarTablet: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 4px 18px -2px rgba(10, 25, 107, 0.1)',
    height: '72px',
  },
  grow: {
    flexGrow: 1,
    // marginTop: '8px',
    position: 'relative',
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: ' 0.02em',
    paddingTop: '8px',
    color: theme.palette.common.black,
  },
  Multiline: {
    flexDirection: 'column',
  },
  Singleline: {
    flexDirection: 'row',
  },
}));

export const getVenues = (
  role: string,
  venueList: VenuesListDTO[],
  orgManagersVenueList: VenueAPIModel[],
) => {
  const isSocialVenueAdmin = ([UserRoles.admin_role] as string[]).includes(role);

  let items = [] as SearchObject[];

  if (isSocialVenueAdmin) {
    items = orgManagersVenueList.map((venue) => ({
      id: venue.id,
      name: venue.name,
    }));
  } else {
    items = venueList.map((venue) => ({
      id: venue.venueId,
      name: venue.venueName,
    }));
  }

  return items;
};
