import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AddToast } from 'react-toast-notifications';
import { GetTransactionAPIModel } from '../../../../api/models/transaction';
import ImportDetailsTable from '../../../../common/components/DataSync/ImportDetailsTable/ImportDetailsTable';
import { FSModal } from '../../../../common/components/modal/Modal';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { WebsiteRoutes } from '../../../../common/constants/routes';
import { DataSyncStrings } from '../../../../common/localization/en';
import { importHistoryDataSyncTableContent } from '../../../../services/helpers/tableMappers';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  getTransactionBatches,
  getTransactionBatchRecordsById,
  goToSelectedPage,
  putTransactionBatch,
  setDataSyncSorting,
  updateSize,
} from '../../../../store/slices/dataSyncSlice';
import ApproveModal from './ApproveModal';
import { useStyles } from './ImportHistoryTableSection.helper';
import { updateMessagingConfig } from '../../../../store/slices/smsInvitesSlice';
import { useVenue } from '../../../../hooks/useVenue';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { DataSyncEventNames } from '../../../../common/constants/events/dataSyncEvents';

interface ImportHistoryTableSectionProps {
  items: GetTransactionAPIModel[];
  size: number;
  page: number;
  totalItems: number;
  totalPages: number;
  sort: string[];
  error: boolean;
  isLoading: boolean;
  btnIsLoading: boolean;
  addToast: AddToast;
}

export const ImportHistoryTableSection: FC<ImportHistoryTableSectionProps> = ({
  items,
  size,
  page,
  totalItems,
  totalPages,
  sort,
  isLoading,
  error,
  btnIsLoading,
  addToast,
}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { push } = useHistory();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const { trackEvent } = useTrackEvent();

  const [approveBtnId, setApproveBtnId] = useState<string | null>(null);
  const { id } = useTypedSelector((state) => state.venue.venue);
  const { messageConfig } = useTypedSelector((store) => store.smsInvitesSlice);

  const [daysShift, setDaysShift] = useState(messageConfig.postMessage.daysShift);
  const { venueId } = useVenue();
  const { isMarketingClient } = useClientType();

  const handleApprove = () => {
    if (approveBtnId) {
      dispatch(putTransactionBatch({ id: approveBtnId })).then(
        () => {
          setApproveBtnId(null);
          addToast(DataSyncStrings.SuccessfullyApproved, {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(
            getTransactionBatches({
              venueId: id,
              page,
              size,
              sort: sort.toString(),
            }),
          );
        },
        () => {
          addToast(DataSyncStrings.FailedToApproved, {
            appearance: 'error',
            autoDismiss: true,
          });
        },
      );

      if (isMarketingClient) {
        dispatch(
          updateMessagingConfig({
            venueId,
            ...messageConfig,
            postMessage: {
              ...messageConfig.postMessage,
              daysShift,
            },
          }),
        );
      }

      trackEvent(DataSyncEventNames.approveTransactionConfirm, { transactionId: approveBtnId });
    }
  };

  const handleOpenApproveModal = (id: string) => {
    setApproveBtnId(id);
    trackEvent(DataSyncEventNames.approveTransactionOpen, { transactionId: id });
  };

  const dataSyncTableContent = importHistoryDataSyncTableContent({
    items: items.slice(page * size, (page + 1) * size),
    handleClick: handleOpenApproveModal,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const changeTableSize = (size: number) => {
    dispatch(updateSize(size));
  };

  const onSort = (name: string) => {
    dispatch(setDataSyncSorting(name));
  };

  const selectedImportRow = items.find((row) => row.id === location.search.split('id=')[1]);

  const selectedRowId = location.search.split('id=')[1];

  useEffect(() => {
    if (selectedRowId) dispatch(getTransactionBatchRecordsById({ id: selectedRowId }));
  }, [dispatch, selectedRowId]);

  return (
    <Box className={styles.root}>
      <FSModal
        modalIsOpen={!!location.search}
        onClose={() => push(`${WebsiteRoutes.SyncData}`)}
        width="90%"
        padding="13px 0 0 0"
      >
        {selectedImportRow ? <ImportDetailsTable /> : <></>}
      </FSModal>

      <FSModal
        modalIsOpen={!!approveBtnId}
        onClose={() => setApproveBtnId(null)}
        width="500px"
        padding="13px 0 0 0"
        rounded="16px"
      >
        <ApproveModal
          handleApprove={handleApprove}
          handleClose={() => {
            trackEvent(DataSyncEventNames.approveTransactionCancel, {
              transactionId: approveBtnId!,
            });
            setApproveBtnId(null);
          }}
          loading={btnIsLoading}
          setDaysShift={(value: number) => {
            setDaysShift(value);
            trackEvent(DataSyncEventNames.approveTransactionMessageDeliverySchedule, {
              transactionId: approveBtnId!,
              value: `${value} days after`,
            });
          }}
          daysShift={daysShift}
        />
      </FSModal>
      <Box className={styles.ImportTable}>
        <CommonTable
          selectedRowId={params?.id || ''}
          content={dataSyncTableContent}
          page={convertApiPageToFrontEndPage(page)}
          sort={sort}
          totalItems={totalItems}
          totalPages={totalPages}
          isLoading={isLoading}
          noContent={error}
          tablePadding="0"
          goToPage={onGoToPage}
          onSortHeaderClick={onSort}
          onClickRow={(id) => push(`${WebsiteRoutes.SyncData}?id=${id}`)}
          size={size}
          onSizeChange={(size) => {
            changeTableSize(size);
          }}
          disableFullHeight
          onTableRowClick={() =>
            trackEvent(DataSyncEventNames.importDetailsOpen, { transactionId: params.id })
          }
        />
      </Box>
    </Box>
  );
};
