import { Box, Button, Typography } from '@mui/material';
import { BoxProps } from '@material-ui/core';
import { FC } from 'react';
import { RoundedButton } from '../Button/RoundedButton';

import { ImageUploaderBase, ImageUploaderBaseProps } from './components/ImageUploaderBase';
import { CommonStrings } from '../../localization/en';
import { Alignment } from './ImageUploaderWithGallery';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../constants/events/common';
import { setInviteMediaUrl } from '../../../store/slices/createCampaignSlice';
import { useAppDispatch } from '../../../store';

export const ImageUploaderInfo: React.FC<{
  title?: string;
  subtitle?: string;
  warnBackgroundNotTransparent?: boolean;
}> = ({ title, subtitle, warnBackgroundNotTransparent }) => {
  return (
    <Box>
      {title && (
        <Typography fontWeight="500" fontSize="17px" mb="6px" color="#344054" textAlign="start">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography fontSize="12px" color="#8B89A0" textAlign="start" width="220px">
          {subtitle}
        </Typography>
      )}
      {warnBackgroundNotTransparent && (
        <Typography fontSize="12px" color="red" textAlign="start" width="220px">
          {CommonStrings.PreferTransperentBackground}
        </Typography>
      )}
    </Box>
  );
};

export interface ImageUploaderProps extends ImageUploaderBaseProps {
  title?: string;
  subtitle?: string;
  buttonTitle?: string;
  rightSideContainerProps?: BoxProps;
  warnBackgroundNotTransparent?: boolean;
  alignment?: Alignment;
  imagePadding?: number;
  displayInfo?: boolean;
  displayButtons?: boolean;
  displayRemoveButton?: boolean;
  dndIcon?: JSX.Element;
}

export const ImageUploader: FC<ImageUploaderProps> = ({
  file,
  onUpload,
  accept,
  title,
  subtitle,
  buttonTitle = 'Upload Image',
  loading,
  children,
  rightSideContainerProps,
  warnBackgroundNotTransparent,
  alignment,
  imagePadding,
  displayButtons = true,
  displayRemoveButton = false,
  displayInfo = true,
  ...restProps
}) => {
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const handleRemoveImage = () => {
    dispatch(setInviteMediaUrl(''));
  };
  return (
    <ImageUploaderBase
      file={file}
      onUpload={onUpload}
      accept={accept}
      loading={loading}
      alignment={alignment}
      imagePadding={imagePadding}
      renderRightSide={({ openFileSelector }) => (
        <Box display="flex" flexDirection="column" alignItems="start" {...rightSideContainerProps}>
          {displayInfo && (
            <ImageUploaderInfo
              title={title}
              subtitle={subtitle}
              warnBackgroundNotTransparent={warnBackgroundNotTransparent}
            />
          )}
          {displayButtons && (
            <Box>
              <RoundedButton
                title={buttonTitle}
                onClick={() => {
                  openFileSelector();
                  trackEvent(CommonEventNames.company_logo_upload_button_click);
                }}
                fontSize={12}
                sx={{
                  mt: warnBackgroundNotTransparent ? '5px' : '15px',
                  height: '34px',
                  // width: '120px',
                  padding: '5px 20px',
                  '& p': {
                    fontSize: '12px',
                  },
                }}
                loading={loading}
              />
              {displayRemoveButton && file && (
                <Button
                  variant="text"
                  color="error"
                  onClick={handleRemoveImage}
                  sx={{ marginTop: '15px', marginLeft: '30px' }}
                >
                  Remove
                </Button>
              )}
            </Box>
          )}
        </Box>
      )}
      {...restProps}
    />
  );
};
