import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FC, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { ArrowIcon } from '../../../../common/assets/newDesign/ArrowIcon';
import { useStyles } from './ActionMenu.styles';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../common/constants/events/rewardCampaignsEvents';
import { useClientType } from '../../../../services/hooks/useClientType';

interface IMenuItem {
  icon?: JSX.Element;
  label: string;
  onClick: (value?: any) => void;
  value?: any;
  disabled?: boolean;
  subItems?: IMenuItem[];
  showForEndedCampaigns?: boolean;
}

interface ActionMenuProps {
  title: string;
  items: IMenuItem[];
  isActiveCampaign?: boolean;
  campaignId: string;
  isCampaignsTable?: boolean;
}

const ActionMenu: FC<ActionMenuProps> = ({
  title,
  items,
  isActiveCampaign,
  campaignId,
  isCampaignsTable,
}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [openSub, setOpenSub] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const anchorRefSub = useRef<any>(null);
  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();

  const handleClose = (event: Event | SyntheticEvent, isSubItem: boolean) => {
    if (!isSubItem) {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
      }
    }
    setOpen(false);
    isMenuClicked.current = false;
    trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageActionsMenuClose, {
      campaignId,
    });
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  const prevOpenSub = useRef(openSub);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
    if (open) {
      trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageActionsMenuOpen, {
        campaignId,
      });
    }
  }, [open, campaignId, trackEvent]);

  useEffect(() => {
    if (prevOpenSub.current === true && openSub === false) {
      anchorRefSub.current!.focus();
    }

    prevOpenSub.current = openSub;
  }, [openSub]);

  const isMenuClicked = useRef(false);

  const toggleMenu = () => {
    if (isMenuClicked.current) {
      isMenuClicked.current = false;
      setOpen(false);
      return;
    }

    isMenuClicked.current = true;
    setOpen(true);
  };

  const openOnHover = () => {
    if (open) return;

    setOpen(true);
  };

  const closeOnHover = () => {
    if (isMenuClicked.current) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box
      sx={{
        width: isCampaignsTable ? '10px !important' : 'fit-content',
        marginLeft: isCampaignsTable ? '20px !important' : '',
      }}
    >
      <Button
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        className={styles.button}
        startIcon={isCampaignsTable ? <MoreVertIcon /> : <></>}
        sx={{
          border: isCampaignsTable ? 'none !important' : '',
          boxShadow: isCampaignsTable ? 'none !important' : '',
          paddingLeft: isCampaignsTable ? '2px !important' : '',
          paddingRight: isCampaignsTable ? '2px !important' : '',
        }}
        onMouseOver={openOnHover}
        onMouseLeave={closeOnHover}
      >
        <>{title}</>
        {!isCampaignsTable && (
          <Box sx={{ marginLeft: '14px', transform: open ? 'rotateZ(-90deg)' : 'rotateZ(90deg)' }}>
            <ArrowIcon />
          </Box>
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: '10', marginTop: '8px', width: isCampaignsTable ? '240px' : '261px' }}
        onMouseOver={openOnHover}
        onMouseLeave={closeOnHover}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={(e) => handleClose(e, false)}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {items
                    .filter((item) => {
                      return (isActiveCampaign || item.showForEndedCampaigns) && !item.disabled;
                    })
                    .map((item, i) => {
                      if (item.subItems?.length) {
                        return (
                          <MenuItem
                            key={i}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              item.onClick();
                              setOpenSub((v) => !v);
                            }}
                            onMouseOver={() => {
                              setOpenSub(true);
                            }}
                            onMouseLeave={() => {
                              setOpenSub(false);
                            }}
                            ref={anchorRefSub}
                            className={styles.item}
                            style={{
                              borderTop: '1px solid #EAECF0',
                              borderTopRightRadius: i === 0 ? '8px' : 'none',
                              borderTopLeftRadius: i === 0 ? '8px' : 'none',
                            }}
                            disabled={!isActiveCampaign || item.disabled}
                            id={`${item.label.split(' ').join('-')}-button`}
                          >
                            <Box className={styles.icon} mr={'8px'}>
                              {item.icon}
                            </Box>
                            <Typography className={styles.label}>
                              {isHealthCare
                                ? item.label.replace('Customer', 'Patient')
                                : item.label}
                            </Typography>
                            <Box ml={'auto'}>
                              <ArrowIcon />
                            </Box>
                            <Popper
                              open={openSub}
                              anchorEl={anchorRefSub.current}
                              role={undefined}
                              placement="right-start"
                              transition
                              disablePortal
                              style={{ width: isCampaignsTable ? '180px' : '' }}
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener onClickAway={(e) => handleClose(e, true)}>
                                      <MenuList
                                        autoFocusItem={openSub}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                      >
                                        {item.subItems!.map((sub, i) => (
                                          <MenuItem
                                            key={i}
                                            onClick={(e) => {
                                              sub.onClick();
                                              handleClose(e, true);
                                              trackEvent(
                                                RewardCampaignsEvents.RewardCampaignSummaryPageActionsMenuItemClick,
                                                {
                                                  campaignId,
                                                  value: sub.label,
                                                },
                                              );
                                            }}
                                            className={styles.item}
                                            style={{
                                              borderBottomRightRadius:
                                                item.subItems?.length &&
                                                i === item.subItems.length - 1
                                                  ? '8px'
                                                  : 'none',
                                              borderBottomLeftRadius:
                                                item.subItems?.length &&
                                                i === item.subItems.length - 1
                                                  ? '8px'
                                                  : 'none',
                                              borderTopRightRadius: i === 0 ? '8px' : 'none',
                                              borderTopLeftRadius: i === 0 ? '8px' : 'none',
                                            }}
                                            id={`${sub.label.split(' ').join('-')}-button`}
                                          >
                                            <Typography className={styles.label}>
                                              {sub.label}
                                            </Typography>
                                          </MenuItem>
                                        ))}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </MenuItem>
                        );
                      }
                      return (
                        <MenuItem
                          disabled={!isActiveCampaign || item.disabled}
                          key={i}
                          onClick={(e) => {
                            item.onClick();
                            handleClose(e, false);
                            trackEvent(
                              RewardCampaignsEvents.RewardCampaignSummaryPageActionsMenuItemClick,
                              {
                                campaignId,
                                value: item.label,
                              },
                            );
                          }}
                          className={styles.item}
                          style={{
                            borderTop: i === items.length - 1 ? '1px solid #EAECF0' : 'none',
                            borderBottomRightRadius: i === items.length - 1 ? '8px' : 'none',
                            borderBottomLeftRadius: i === items.length - 1 ? '8px' : 'none',
                            borderTopRightRadius: i === 0 ? '8px' : 'none',
                            borderTopLeftRadius: i === 0 ? '8px' : 'none',
                          }}
                          id={`${item.label.split(' ').join('-')}-button`}
                        >
                          {item.icon && (
                            <Box className={styles.icon} mr={'8px'}>
                              {item.icon}
                            </Box>
                          )}
                          <Typography className={styles.label}>{item.label}</Typography>
                          {item.value && (
                            <Typography className={styles.value}>{item.value}</Typography>
                          )}
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default ActionMenu;
