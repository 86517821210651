import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApiUrlForId, SocialVerseEndpoints } from '../../api/endpoints';
import { httpClient } from '../../services/httpClient/httpClient';
import {
  CreateSocialVerseApiResponse,
  SocialVerseApiModel,
  SocialVerseAllApiModel,
  SocialVerseByIdApiModel,
  SocialVerseVideos,
} from './../../api/models/socialVerse';

interface SocialVerseInitialState {
  socialVerse: SocialVerseByIdApiModel | null;
  socialVerseAll: Array<SocialVerseAllApiModel> | null;
  currentSocialVerseVideos: SocialVerseVideos[];
  isLoading: boolean;
  isError: boolean;
  addVideo: boolean;
}

const initialState: SocialVerseInitialState = {
  socialVerse: null,
  socialVerseAll: null,
  isLoading: false,
  isError: false,
  currentSocialVerseVideos: [],
  addVideo: false,
};

interface GetSocialVerseByIdOptions {
  id: string;
}

interface GetAllSocialVerseOptions {
  venueId: string;
  withVideos?: boolean;
}

interface DeleteSocialVerseResponce {
  id: string;
}

interface EditSocialVerseData {
  name?: string;
  viewMode?: string;
  showTitle?: boolean;
  showUserInfo?: boolean;
  title?: string;
  keywordFilterEnabled?: boolean;
  gbpSyncEnabled?: boolean;
  details?: {
    glowColor?: string;
    withGlow?: boolean;
    captionEnabled?: boolean;
    showSupportingText?: boolean;
    supportingText?: string;
    numberOfPerspectiveCards?: number;
    numberOfGridRows?: number;
    withHoverPreview?: boolean;
    showVideoCounter?: boolean;
  };
}

export interface DeleteSocialVerseRequest {
  id: string;
}

export interface EditSocialVerseOptions {
  id: string;
  data: EditSocialVerseData;
}

interface CreateSocialVerseOptions extends EditSocialVerseData {
  venueId: string;
}

export const AddVideoToSocialVerse = createAsyncThunk(
  'socialVerse/addVideoToSocialVerse',
  async (options: { videoId: string; id: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<{ videoId: string }, { success: boolean }>({
        url: getApiUrlForId(SocialVerseEndpoints.AddVideoToSocialVerse, options.id),
        payload: {
          videoId: options.videoId,
        },
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const BatchVideosToSocialVerse = createAsyncThunk(
  'socialVerse/batchVideosToSocialVerse',
  async (options: { videoIds: string[]; id: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<{ videoIds: string[] }, { success: boolean }>({
        url: getApiUrlForId(SocialVerseEndpoints.BatchVideosToSocialVerse, options.id),
        payload: {
          videoIds: options.videoIds,
        },
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const GetVideosBySocialVerseId = createAsyncThunk(
  'socialVerse/GetVideosBySocialVerseId',
  async (options: { id: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<{}, SocialVerseVideos[]>({
        url: getApiUrlForId(SocialVerseEndpoints.GetVideosBySocialVerse, options.id),
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const DeleteVideoFromSocialVerse = createAsyncThunk(
  'socialVerse/DeleteVideoFromSocialVerse',
  async (options: { id: string; videoId: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.delete<{}, { success: boolean }>({
        url: getApiUrlForId(SocialVerseEndpoints.RemoveVideoFromSocialVerse, options.id).replace(
          ':videoId',
          options.videoId,
        ),
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const UpdateVideoPositionInSocialVerse = createAsyncThunk(
  'socialVerse/UpdateVideoPositionInSocialVerse',
  async (
    options: { id: string; videoId: string; position: number },
    { rejectWithValue },
  ): Promise<any> => {
    try {
      return await httpClient.put<{ position: number }, { success: boolean }>({
        url: getApiUrlForId(
          SocialVerseEndpoints.MoveVideoPositionInSocialVerse,
          options.id,
        ).replace(':videoId', options.videoId),
        requiresToken: true,
        payload: {
          position: options.position,
        },
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getSocialVerseById = createAsyncThunk(
  'socialVerse/getSocialVerseById',
  async (options: GetSocialVerseByIdOptions, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<{}, SocialVerseByIdApiModel>({
        url: SocialVerseEndpoints.GetSocialVerseById + options.id,
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAllSocialVerse = createAsyncThunk(
  'socialVerse/getAllSocialVerse',
  async (options: GetAllSocialVerseOptions, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.get<
        { venueId: string; withVideos: boolean },
        Array<SocialVerseAllApiModel>
      >({
        url: SocialVerseEndpoints.GetAllSocialVerses,
        params: {
          venueId: options.venueId,
          withVideos: options.withVideos ? options.withVideos : false,
        },
        payload: {
          venueId: options.venueId,
          withVideos: options.withVideos ? options.withVideos : false,
        },
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postCreateSocialVerse = createAsyncThunk(
  'socialVerse/postCreateSocialVerse',
  async (options: CreateSocialVerseOptions, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.post<CreateSocialVerseOptions, CreateSocialVerseApiResponse>({
        url: SocialVerseEndpoints.PostCreateSocialVerse,
        payload: {
          venueId: options.venueId,
          name: options.name,
          viewMode: options.viewMode,
          showTitle: options.showTitle,
          showUserInfo: options.showUserInfo,
          title: options.title,
          keywordFilterEnabled: options.keywordFilterEnabled,
          details: options.details,
        },
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const putEditSocialVerse = createAsyncThunk(
  'socialVerse/putEditSocialVerse',
  async (options: EditSocialVerseOptions, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.put<EditSocialVerseData, SocialVerseApiModel>({
        url: SocialVerseEndpoints.PutUpdateSocialVerse + options.id,
        payload: options.data,
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const deleteSocialVerseById = createAsyncThunk(
  'videos/deleteSocialVerse',
  async (options: DeleteSocialVerseRequest, { rejectWithValue }) => {
    try {
      return await httpClient.delete<DeleteSocialVerseRequest, DeleteSocialVerseResponce>({
        url: SocialVerseEndpoints.PutUpdateSocialVerse + options.id,
        requiresToken: true,
        params: options,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
const SocialVerseSlice = createSlice({
  name: 'socialVerse',
  initialState,
  reducers: {
    setNewVideo: (state, action) => {
      state.addVideo = action.payload;
    },
    deleteVideoFromSocialVerseAll: (
      state,
      action: PayloadAction<{ id: string; videoId: string }>,
    ) => {
      let currentSv = state.socialVerseAll
        ? state.socialVerseAll.find((sv: SocialVerseAllApiModel) => sv.id === action.payload.id)
        : null;

      let filterVideos = currentSv?.topVideos.filter(
        (video) => video.videoId !== action.payload.videoId,
      );

      const updatedSocialVerseAll = state.socialVerseAll
        ? state.socialVerseAll.map((el) =>
            currentSv && el.id === currentSv.id
              ? { ...currentSv, topVideos: filterVideos || [] }
              : el,
          )
        : null;

      state.socialVerseAll = updatedSocialVerseAll;
    },
    updateVideoThumbnail: (state, action) => {
      state.currentSocialVerseVideos = state.currentSocialVerseVideos.map((video) => {
        if (video.videoId !== action.payload.id) {
          return video;
        }

        return { ...video, thumbnailUrl: action.payload.thumbnailUrl };
      });
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getSocialVerseById.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getSocialVerseById.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getSocialVerseById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.socialVerse = payload;
      state.isError = false;
    });
    reducersBuilder.addCase(getAllSocialVerse.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getAllSocialVerse.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getAllSocialVerse.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.socialVerseAll = payload;
      state.isError = false;
    });
    reducersBuilder.addCase(postCreateSocialVerse.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(postCreateSocialVerse.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(postCreateSocialVerse.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.socialVerse = payload;
      state.isError = false;
    });
    reducersBuilder.addCase(putEditSocialVerse.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(putEditSocialVerse.fulfilled, (state, { payload }) => {
      state.isError = false;
      state.socialVerse = { ...state.socialVerse, ...payload };

      const svIndex = state.socialVerseAll?.findIndex((sv) => sv.id === payload.id);
      if (state.socialVerseAll && svIndex !== undefined && svIndex >= 0) {
        const socialVerseAll = state.socialVerseAll;
        socialVerseAll[svIndex] = { ...socialVerseAll[svIndex], ...payload };
        state.socialVerseAll = socialVerseAll;
      }
    });
    reducersBuilder.addCase(deleteSocialVerseById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(deleteSocialVerseById.fulfilled, (state, { payload, meta }) => {
      state.socialVerseAll = state.socialVerseAll
        ? state.socialVerseAll.filter((item) => item.id !== meta.arg.id)
        : state.socialVerseAll;
      state.isLoading = false;
      state.socialVerse = null;
    });
    reducersBuilder.addCase(deleteSocialVerseById.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(GetVideosBySocialVerseId.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(GetVideosBySocialVerseId.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isError = false;
    });
    reducersBuilder.addCase(GetVideosBySocialVerseId.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.currentSocialVerseVideos = payload;
    });
    //AddVideoToSocialVerse

    reducersBuilder.addCase(UpdateVideoPositionInSocialVerse.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isError = false;
    });
    reducersBuilder.addCase(UpdateVideoPositionInSocialVerse.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(UpdateVideoPositionInSocialVerse.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
    });

    reducersBuilder.addCase(AddVideoToSocialVerse.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isError = false;
    });
    reducersBuilder.addCase(AddVideoToSocialVerse.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(AddVideoToSocialVerse.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.addVideo = false;
    });
    reducersBuilder.addCase(BatchVideosToSocialVerse.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isError = false;
    });
    reducersBuilder.addCase(BatchVideosToSocialVerse.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.addVideo = false;
    });

    reducersBuilder.addCase(DeleteVideoFromSocialVerse.pending, (state, { payload }) => {
      state.isLoading = true;
      state.isError = false;
    });
    reducersBuilder.addCase(DeleteVideoFromSocialVerse.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(DeleteVideoFromSocialVerse.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.currentSocialVerseVideos = state.currentSocialVerseVideos.filter(
        (item) => item.videoId !== action.meta.arg.videoId,
      );
    });
  },
});

export const { setNewVideo, deleteVideoFromSocialVerseAll, updateVideoThumbnail } =
  SocialVerseSlice.actions;
export default SocialVerseSlice.reducer;
