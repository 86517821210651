import React, { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VideosDetailHeaderToolbarStrings } from '../../localization/en';
import { useVideosDetailHeaderToolbarStyles } from './VideosDetailHeaderToolbar.style';
import { VideoStatus } from './VideoStatus';
import { VideoApiModel } from '../../../api/models/videos';
import { TopGolfVideosApiModel } from '../../../api/models/topgolfVideos';
import { VideoStatusDropdown } from '../VideoStatusDropdown/VideoStatusDropdown';

interface IContentModalHeaderProps {
  isTopGolf: boolean;
  onClose: () => void;
  video: VideoApiModel | TopGolfVideosApiModel;
}

export const VideosDetailHeaderToolbar: FC<IContentModalHeaderProps> = ({
  onClose,
  isTopGolf,
  video,
}) => {
  const classes = useVideosDetailHeaderToolbarStyles();

  return (
    <>
      <Box className={classes.container}>
        <IconButton onClick={onClose} size="large" className={classes.containerCloseIcon}>
          <CloseIcon />
        </IconButton>
        <Box className={classes.Title}>{VideosDetailHeaderToolbarStrings.VideoDetail}</Box>
        <Box width={'220px'} display="flex" justifyContent={'end'}>
          {isTopGolf ? (
            <VideoStatus status={video.status} />
          ) : (
            <VideoStatusDropdown video={video} />
          )}
        </Box>
      </Box>
    </>
  );
};
