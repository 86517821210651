import { FC, useEffect } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Button, Grid, IconButton, SxProps } from '@mui/material';
import clsx from 'clsx';
import { useSocialVerseStyles } from '../SocialVerses.style';
import { SocialVerseStrings } from '../../../common/localization/en';
import { Add } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useTypedSelector } from '../../../store';

import AccountMenu from '../../../common/components/AccountMenu/AccountMenu';
import { useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { useIsFteFinished } from '../../../hooks/useIsFteFinished';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../../common/constants/events/videosEvents';
import { useWebAppLink } from '../../../hooks/useWebAppLink';
import { getPrimaryCampaign } from '../../../store/slices/campaignsSlice';

//// SVButton
interface ISVButtonProps {
  disabled?: boolean;
  startIcon?: React.ReactNode;
  sx?: SxProps;
  variant: 'contained' | 'outlined';
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  children?: string | React.ReactNode | ReactJSXElement;
  isDisabled?: (id: string) => boolean;
  id?: string;
}

export const SVButton: FC<ISVButtonProps> = (props) => {
  const { sx, variant, startIcon, className, handleClick, children, disabled = false, id } = props;
  const classes = useSocialVerseStyles();

  return (
    <Button
      disabled={disabled}
      sx={sx}
      variant={variant}
      startIcon={startIcon}
      classes={{ disabled: classes.disabldedButton, root: className }}
      className={clsx(classes.svButton, {
        [classes.containedBtn]: variant === 'contained',
        [classes.outlinedBtn]: variant === 'outlined',
      })}
      onClick={handleClick}
      id={id}
    >
      {children}
    </Button>
  );
};

////// Empty card Add video
interface AddNewVideoProps {
  handleAddVideo: () => void;
  viewMode: string;
}

export const AddNewVideoCard: FC<AddNewVideoProps> = ({ viewMode, handleAddVideo }) => {
  const classes = useSocialVerseStyles();
  const renderCards = () => (
    <Grid container item md={12} gap={1} alignItems="flex-end">
      <Grid item md={2}>
        <Box
          sx={{ maxHeight: '170px !important', height: '170px !important' }}
          className={classes.previewEmptyWrapper}
        >
          <IconButton
            onClick={() => {
              handleAddVideo();
            }}
            className={classes.addButton}
          >
            <>+</>
          </IconButton>
        </Box>
      </Grid>
      <Grid item md={2}>
        <Box className={classes.previewEmptyWrapper} sx={{ opacity: '0.5' }}></Box>
      </Grid>
      <Grid item md={2}>
        <Box className={classes.previewEmptyWrapper} sx={{ opacity: '0.3' }}></Box>
      </Grid>
      <Grid item md={2}>
        <Box className={classes.previewEmptyWrapper} sx={{ opacity: '0.1' }}></Box>
      </Grid>
    </Grid>
  );
  const renderBubbles = () => (
    <Grid container item md={12} sx={{ width: '100%' }}>
      <Grid item className={classes.previewEmptyBubbles}>
        <IconButton
          onClick={() => {
            handleAddVideo();
          }}
          className={classes.addButton}
        >
          <Add />
        </IconButton>
      </Grid>
      <Grid item className={classes.previewEmptyBubbles} sx={{ opacity: '0.5' }}></Grid>
      <Grid item className={classes.previewEmptyBubbles} sx={{ opacity: '0.3' }}></Grid>
      <Grid item className={classes.previewEmptyBubbles} sx={{ opacity: '0.1' }}></Grid>
    </Grid>
  );
  const renderContent = () => {
    if (viewMode === 'blocks') {
      return renderCards();
    } else if (viewMode === 'circles') {
      return renderBubbles();
    } else {
      <></>;
    }
  };
  return <>{renderContent()}</>;
};

///// Open webapp component

interface Props {
  blur?: boolean;
}

export const useOpenWebAppStyles = makeStyles<Theme, Props>((theme) => ({
  Container: (props) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexWrap: 'nowrap',
    filter: props.blur ? 'blur(4px)' : 'none',
    pointerEvent: props.blur ? 'none' : 'auto',
    marginRight: '-50px',
  }),
  Badge: {
    '& .MuiBadge-badge': {
      backgroundColor: '#EA3475',
      color: theme.palette.common.white,
      fontSize: '10px',
      fontWeight: 900,
    },
  },
  Link: {
    color: theme.palette.primary.main,
    '& :hover': {
      // outline: '1px solid',
    },
  },
}));

interface IOpenWebAppProps {
  messages?: number;
}

export const OpenWebApp: FC<IOpenWebAppProps> = ({ messages = 0 }) => {
  // const handleClickBell = () => {};
  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const { items } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { primaryCampaign, isLoading } = useTypedSelector((state) => state.campaigns);
  const FTEIsFinished = useIsFteFinished();
  const { trackEvent } = useTrackEvent();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isOnProfilePage =
    pathname === WebsiteRoutes.ProfileAccounts || pathname === WebsiteRoutes.ProfileProfile;
  const styles = useOpenWebAppStyles({ blur: !FTEIsFinished && !isOnProfilePage });

  useEffect(() => {
    if (venueId) {
      dispatch(
        getPrimaryCampaign({
          venueId,
        }),
      );
    }
  }, [dispatch, venueId]);

  const { webAppLink } = useWebAppLink({
    queryParams: {
      cid: primaryCampaign ? primaryCampaign.shortcode! : '',
    },
  });
  const singleAccount = items.length === 1;
  return (
    <Grid container className={styles.Container}>
      {!isOnProfilePage || singleAccount ? (
        <>
          <Grid item sx={{ flexShrink: 0 }}>
            <Button
              sx={{
                padding: '10px 30px',
                borderRadius: '100px',
                pointerEvents: FTEIsFinished ? 'auto' : 'none',
              }}
              variant="outlined"
              onClick={() => {
                trackEvent(VideosEventNames.openVideoAppLinkClicked, {
                  value: webAppLink,
                });
              }}
              disabled={isLoading}
            >
              <a href={webAppLink} target="_blank" rel="noreferrer" className={styles.Link}>
                {SocialVerseStrings.OpenWebApp}
              </a>
            </Button>
          </Grid>
        </>
      ) : null}
      <Grid
        item
        sx={{
          paddingLeft: '2px',
          pointerEvents: isOnProfilePage ? 'auto' : FTEIsFinished ? 'auto' : 'none',
        }}
      >
        <AccountMenu />
      </Grid>
      {/* <Grid item sx={{ paddingLeft: '2px' }}>
        <LogoutSection isTopbarPlaced />
      </Grid> */}
      {/* <Grid item sx={{ paddingLeft: '2px' }}>
        <IconButton onClick={handleClickBell} disabled>
          <Badge badgeContent={messages} className={styles.Badge}>
            <BellIcon />
          </Badge>
        </IconButton>
      </Grid> */}
    </Grid>
  );
};
