import { FC, useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { SearchFilterAttributes } from '../../../../api/models/searchFilter';
import { SocialVerseAllApiModel } from '../../../../api/models/socialVerse';
import { VideoApiModel } from '../../../../api/models/videos';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import {
  defaultPagination,
  LayoutMode,
  TypeSocialVerse,
} from '../../../../common/constants/constants';
import { usePrevious } from '../../../../hooks/common';
import {
  createContentDataTableContent,
  createMobileContentDataTableContentFirstSlide,
  createMobileContentDataTableContentSecondSlide,
} from '../../../../services/helpers/tableMappers';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
  getItemsFromPageAndSize,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { setActiveFilter } from '../../../../store/slices/searchFiltersSlice';
import {
  getVideosBySearchFilter,
  getVideos,
  goToSelectedPage,
  reset,
  setVideosSorting,
  updateSearch,
  updateSize,
} from '../../../../store/slices/videosSlice';
import { updateSearch as updateTopgolfSearch } from '../../../../store/slices/videosSlice';
import { useVideoTableIdContext } from '../../../SocialVerse/SocialVerse.helper';
import { ClientTypes } from '../../../../api/models/common';
import { VideoStatusStrings } from '../../../../common/localization/en';
import { getKeywordsByVenueId } from '../../../../store/slices/keywordsSlice';
import { useClientType } from '../../../../services/hooks/useClientType';
import { getGeneralKPIs } from '../../../../store/slices/analyticsSlice';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../../../common/constants/events/videosEvents';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { isFirefox } from 'react-device-detect';
import { useRef } from 'react';
import { setScrollY } from '../../../../store/slices/uiSlice';

export const useStyles = makeStyles(() => ({
  table: {
    '& td > *': {
      fontSize: '13px !important',
    },
    '& td': {
      '&:nth-child(9)': {
        paddingLeft: '0px !important',
      },
      '&:nth-child(10)': {
        paddingLeft: '0px !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        paddingRight: '12px',
      },
    },
  },
}));

interface ContentTableProps {
  handleOpenDeleteModal: (id: string, deleted: boolean) => void;
  statusFilter?: string;
  handleOpenConfirmationModal: (id: string) => void;
  handleOpenAddNewTagModal: (id: string) => void;
  archivedFilter: boolean;
  isSocialVersePage?: boolean;
  handleAddVideoToCard: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  socialVerseCards?: SocialVerseAllApiModel[];
  onRowClick?: (id: string) => void;
  handleVideoReplace?: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
}

export const ContentTable: FC<ContentTableProps> = ({
  handleOpenDeleteModal,
  statusFilter,
  handleOpenConfirmationModal,
  handleOpenAddNewTagModal,
  handleAddVideoToCard,
  archivedFilter,
  isSocialVersePage = false,
  onRowClick,
  handleVideoReplace,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const scrollbarRefProp = useRef<HTMLDivElement | null>(null);

  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
  } = useTypedSelector((state) => state.videos);
  const { trackEvent } = useTrackEvent();

  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);
  const { scrollY } = useTypedSelector((state) => state.ui);

  const { id: venueId, clientType } = useTypedSelector((state) => state.venue.venue);
  const params = useParams<{ id: string }>();
  const device = useWindowResize();
  const match = useRouteMatch();
  const visibleItems = getItemsFromPageAndSize<VideoApiModel>(items, page, size).filter((item) =>
    isSocialVersePage ? item.status !== VideoStatusStrings.Moderated.toUpperCase() : true,
  );
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);
  const { newFilterAttributes } = useTypedSelector((state) => state.SearchFilterSlice);
  const prevStatus = usePrevious<string | undefined>(statusFilter);
  const prevArchived = usePrevious<boolean>(archivedFilter);

  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
  const entity = isNotTopgolf ? 'videos' : 'TopgGolfVideos';
  const { isHealthCare } = useClientType();

  const { kpis } = useTypedSelector((state) => state.analytics);
  const isConversionsHidden = kpis.convertedReferrals === 0;

  const { currentSocialVerseVideos, socialVerse } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );

  const shouldResetPagination = useMemo(() => {
    if (prevArchived !== archivedFilter || prevStatus !== statusFilter) {
      return defaultPagination.page;
    }
    return page;
  }, [prevArchived, archivedFilter, prevStatus, statusFilter, page]);

  useEffect(() => {
    dispatch(
      getGeneralKPIs({
        venueId,
      }),
    );

    return () => {
      dispatch(setActiveFilter(null));
      dispatch(reset());
    };
  }, [dispatch, venueId]);

  useEffect(() => {
    if (venueId) {
      dispatch(getKeywordsByVenueId({ venueId: venueId }));
    }
  }, [dispatch, venueId]);

  useEffect(() => {
    if (venueId) {
      if (activeFilter || (isSocialVersePage && newFilterAttributes.length)) {
        let filter: SearchFilterAttributes[] = [];
        if (isSocialVersePage && newFilterAttributes.length) {
          filter = newFilterAttributes;
        } else if (isSocialVersePage && activeFilter) {
          filter = activeFilter?.filter.attributes;
        } else if (activeFilter) {
          filter = activeFilter?.filter.attributes;
        }
        dispatch(
          getVideosBySearchFilter({
            venueId,
            filter: filter,
            pageable: {
              page: shouldResetPagination,
              size,
              sort,
            },
            search,
          }),
        );
      } else {
        dispatch(
          getVideos({
            venueId,
            pageable: {
              page: shouldResetPagination,
              size,
              sort,
              status: statusFilter,
              deleted: archivedFilter,
            },
            search,
          }),
        );
      }
    }

    // dispatch(getHeroes({ search, venueId, pageable: { page, size, sort } }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    size,
    sort,
    search,
    venueId,
    statusFilter,
    archivedFilter,
    activeFilter,
    newFilterAttributes,
    isSocialVersePage,
    keywords,
  ]);

  useEffect(() => {
    if (scrollbarRefProp.current && scrollY) {
      scrollbarRefProp.current.scrollTo(0, scrollY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRowClick]);

  const tableConfig = {
    items: visibleItems,
    handleOpenDeleteModal,
    handleOpenConfirmationModal,
    handleOpenAddNewTagModal,
    handleAddVideoToCard,
    handleVideoReplace,
    renderCheckbox: activeFilter ? true : false,
    isSocialVerse: isSocialVersePage,
    isHealthCare,
    isConversionsHidden,
    totalKeywords: keywords.length,
    isFirefox,
    replace:
      currentSocialVerseVideos.length > 0 && socialVerse?.viewMode === TypeSocialVerse.Educational,
  };

  const contentDataTableContents = createContentDataTableContent(tableConfig);

  const mobileContentDataTableSecondSlide = createMobileContentDataTableContentSecondSlide({
    items: visibleItems,
    handleOpenDeleteModal,
    handleOpenConfirmationModal,
    handleOpenAddNewTagModal,
  });

  const mobileContentDataTableFirstSlide = createMobileContentDataTableContentFirstSlide({
    items: visibleItems,
  });

  const onGoToPage = (targetPage: number) => {
    trackEvent(VideosEventNames.videosTablePageChanged, {
      value: targetPage.toString(),
    });

    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }
    dispatch(setScrollY(undefined));
    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    trackEvent(VideosEventNames.videosTableSortClicked, {
      value: name,
    });
    dispatch(setScrollY(undefined));
    dispatch(setVideosSorting(name));
  };

  const { storyVideoId, setStoryVideoId, setWithEditThumbnailOpen } = useVideoTableIdContext();

  const onClickRow = (id: string) => {
    if (scrollbarRefProp?.current) {
      dispatch(setScrollY(scrollbarRefProp.current.scrollTop));
    }

    onRowClick?.(id);
    if (isSocialVersePage) {
      setStoryVideoId(id);
      setWithEditThumbnailOpen?.(false);
    } else {
      history.push(match.path.replace(':id', id));
    }
  };

  const changeSize = (size: number) => {
    trackEvent(VideosEventNames.videosTableSizeChanged, {
      value: size.toString(),
    });
    dispatch(setScrollY(undefined));
    dispatch(updateSize(size));
  };

  if (device === LayoutMode.Mobile) {
    const sliderSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      pauseOnFocus: true,
      cssEase: 'linear',
    };

    return (
      <Slider {...sliderSettings}>
        <div key={1}>
          <CommonTable
            content={mobileContentDataTableFirstSlide}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
          />
        </div>
        <div key={2}>
          <CommonTable
            content={mobileContentDataTableSecondSlide}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
          />
        </div>
      </Slider>
    );
  }

  return (
    <Box className={classes.table}>
      <CommonTable
        selectedRowId={isSocialVersePage ? storyVideoId || '' : params?.id || ''}
        content={contentDataTableContents}
        page={convertApiPageToFrontEndPage(page)}
        sort={sort}
        totalItems={totalItems}
        totalPages={totalPages}
        isLoading={isLoading}
        noContent={error}
        tablePadding="0"
        goToPage={onGoToPage}
        onSortHeaderClick={onSort}
        onClickRow={onClickRow}
        size={size}
        onSizeChange={changeSize}
        activeFilter={Boolean(activeFilter)}
        withSearchBar={isNotTopgolf ? false : true}
        searchBarProps={{
          adaptiveWidth: true,
          entity,
          updateSearch: isNotTopgolf ? updateSearch : updateTopgolfSearch,
        }}
        scrollbarRefProp={scrollbarRefProp}
      />
    </Box>
  );
};
