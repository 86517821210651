import React from 'react';

const BackArrow = () => {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16272 8.16797L0.739671 4.75536L4.16272 1.33231"
        stroke="#5D5FEF"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.739797 4.75L11.2603 4.75"
        stroke="#5D5FEF"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BackArrow;
