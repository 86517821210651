import React, { FC } from 'react';
import { useStyles } from './SharePreview.styles';
import { IPhonePreview } from '../../IPhonePreview/IPhonePreview';
import { Box, Button, Typography } from '@mui/material';
import { WebAppPageStrings } from '../../../localization/en';
import tinycolor from 'tinycolor2';
import { VideoAppTheme } from '../../../../api/models/venue';
import { MenuIcon } from '../../../assets/newDesign/MenuIcon';
import { CrispChatIcon } from '../../../assets/newDesign/CrispChatIcon';
import { DownloadIcon } from '../../../assets/shareAssets/DownloadIcon';
import FacebookIcon from '../../IPhonePreview/IPhoneSharePreview/icons/FacebookIcon';
import LinkedInIcon from '../../IPhonePreview/IPhoneSharePreview/icons/LinkedInIcon';
import InstagramIcon from '../../IPhonePreview/IPhoneSharePreview/icons/InstagramIcon';
import TwitterIcon from '../../IPhonePreview/IPhoneSharePreview/icons/TwitterIconNew';
import LinkIcon from '../../IPhonePreview/IPhoneSharePreview/icons/LinkIcon';
import SMSIcon from '../../IPhonePreview/IPhoneSharePreview/icons/SMSIcon';

interface Props {
  image: string;
  brandColor?: string;
  logo: string | undefined;
  theme: VideoAppTheme;
}

const SharePreview: FC<Props> = ({ brandColor = '#42a5f5', theme }) => {
  const styles = useStyles({ brandColor, ltheme: theme });
  const textColor = tinycolor(brandColor).isDark() ? 'white' : 'black';

  return (
    <IPhonePreview>
      <Box
        style={{
          background: theme === 'light' ? '#fff' : '#000',
          height: '100%',
          width: '100%',
        }}
      >
        <Box className={styles.menuIcon}>
          <MenuIcon color={brandColor} />
        </Box>
        <Box className={styles.crispChatIcon}>
          <CrispChatIcon color={theme === VideoAppTheme.Dark ? 'white' : '#C1C1C1'} />
        </Box>
        <Typography
          className={styles.title}
          style={{
            color: theme === VideoAppTheme.Dark ? '#fff' : '#000',
            zIndex: '100',
            position: 'absolute',
            top: '4px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          Share To{' '}
          <Typography className={styles.title} style={{ color: brandColor, marginLeft: '4px' }}>
            {' '}
            The World
          </Typography>
        </Typography>
        <Box className={styles.imgWrapper}>
          <img className={styles.img} src="/newShareVideoPreview-2.png" alt="" />
        </Box>
        <Typography
          className={styles.shareDesc}
          style={{ color: theme === VideoAppTheme.Dark ? '#fff' : '#000' }}
        >
          Share via Link
        </Typography>
        <Box className={styles.shareBtnWrapper}>
          <Box className={styles.shareBtn}>
            <SMSIcon />
            &nbsp; Open SMS
          </Box>
          <Box className={styles.shareBtn}>
            <LinkIcon />
            &nbsp; Copy Link
          </Box>
        </Box>
        <Typography
          className={styles.shareDesc}
          style={{ color: theme === VideoAppTheme.Dark ? '#fff' : '#000' }}
        >
          Share via Twitter
        </Typography>
        <Box className={styles.shareBtnWrapper}>
          <Box className={`${styles.shareBtn} ${styles.shareBtnSmall}`}>
            <FacebookIcon />
          </Box>
          <Box className={`${styles.shareBtn} ${styles.shareBtnSmall}`}>
            <LinkedInIcon />
          </Box>
          <Box
            style={{ border: '2px solid white' }}
            className={`${styles.shareBtn} ${styles.shareBtnSmall}`}
          >
            <TwitterIcon />
          </Box>
          <Box className={`${styles.shareBtn} ${styles.shareBtnSmall}`}>
            <InstagramIcon />
          </Box>
        </Box>
        <Box className={styles.buttonsWrapper}>
          <Button className={styles.outlined} variant="outlined">
            Open Twitter
          </Button>
          <Box
            className={`${styles.button} ${styles.recordButton}`}
            sx={{
              background: brandColor,
              color: textColor,
              display: 'flex !important',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <DownloadIcon color={textColor} />
            <Typography
              style={{ color: textColor, marginLeft: '4px', fontSize: '11px', fontWeight: '600' }}
            >
              {WebAppPageStrings.DownloadedVideo}
            </Typography>
          </Box>
        </Box>
      </Box>
    </IPhonePreview>
  );
};

export default SharePreview;
