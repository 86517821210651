import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useModal } from '../../../hooks/useModal';
import { handleChangeWithPattern } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getMe, patchMe } from '../../../store/slices/meSlice';
import { AccountProfileStrings, CommonStrings, WorkspaceStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import { CustomTextField } from '../customTextField/CustomTextField';
import { TextFieldComponent } from '../TextField/TextField';
import { useStyles, validationSchema } from './AccountProfile.helper';
import { PasswordModal } from './PasswordModal/PasswordModal';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../constants/events/accountEvents';

export const AccountProfile = () => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const {
    isOpen: isPasswordModalOpen,
    open: openPasswordModal,
    close: closePasswordModal,
  } = useModal(false);

  const { name, email, isLoading, id, photoUrl, phoneNumber } = useTypedSelector(
    (state) => state.me,
  );

  useEffect(() => {
    dispatch(getMe({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const initialValues = {
    name: name || '',
    cell: phoneNumber || '',
    email: email || '',
  };

  const {
    values,
    submitForm,
    handleChange,
    handleBlur,
    setFieldTouched,
    dirty,
    errors,
    isSubmitting,
    setFieldValue,
    resetForm,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (values) => {
      await dispatch(
        patchMe({ id, name: values.name, email: values.email, photoUrl, phoneNumber: values.cell }),
      )
        .then(() => {
          addToast(WorkspaceStrings.SuccessfullyUpdatedProfile, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        })
        .catch(() => {
          addToast(WorkspaceStrings.FailedProfileUpdate, {
            appearance: 'error',
            autoDismissTimeout: 2500,
          });
        });
    },
  });
  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? (errors[field] as string) : undefined;
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeWithPattern({
      event,
      prevVal: values.cell,
      pattern: '(###) ###-####',
      setVal: (val: string) => {
        setFieldValue('cell', val);
      },
    });
  };

  return (
    <Box className={styles.root}>
      <PasswordModal
        openModal={isPasswordModalOpen}
        toggleModal={closePasswordModal}
        isLoading={isLoading}
        id={id}
      />

      <Box className={styles.profileSection}>
        <Grid className={styles.topbap}>
          <Box>
            <Typography className={styles.pageTitle}>{WorkspaceStrings.Profile}</Typography>
          </Box>
          <Box className={styles.toolbar}>
            <RoundedButton
              title={CommonStrings.Cancel}
              onClick={() => {
                resetForm();
                trackEvent(AccountEventNames.profileButtonCancelClicked);
              }}
              outlined
              sx={{ mr: '15px' }}
              disabled={isSubmitting}
              id="cancel-changes-button"
            />
            <RoundedButton
              title={WorkspaceStrings.SaveChange}
              onClick={() => {
                submitForm();
                trackEvent(AccountEventNames.profileButtonSaveClicked);
              }}
              disabled={!dirty || !!Object.keys(errors).length}
              loading={isSubmitting}
              id="save-changes-button"
            />
          </Box>
        </Grid>
        <Divider color="#DCDBE4" className={styles.top__divider} />
        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>
              {' '}
              {AccountProfileStrings.Name}
            </Typography>
            <Typography className={styles.profileRowSubTitle}>
              {AccountProfileStrings.UpdateTheName}
            </Typography>
          </Grid>
          <Box>
            <TextFieldComponent
              placeholder="Your Name"
              name="name"
              value={values.name}
              onChange={(event) => {
                handleChange(event);
                trackEvent(AccountEventNames.profileNameTyped, {
                  value: event.target.value,
                });
              }}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              errorText={errors.name}
              helperText
              className={styles.textfield}
            />
          </Box>
        </Grid>
        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>
              {' '}
              {AccountProfileStrings.CellPhone}
            </Typography>
            <Typography className={styles.profileRowSubTitle}>
              {AccountProfileStrings.UpdateThePhone}
            </Typography>
          </Grid>
          <Box>
            <CustomTextField
              label={''}
              value={values.cell}
              name="cell"
              handleChange={(event) => {
                handlePhoneNumberChange(event);
                trackEvent(AccountEventNames.profileCellPhoneTyped, {
                  value: event.target.value,
                });
              }}
              error={getFieldError('cell')}
              labelTextClassName={''}
              // helperText={errors.cell || undefined}
              inputClassName={styles.textfield}
              placeholder={'(123) 456-7890'}
              onBlur={handleBlur}
            />
          </Box>
        </Grid>
        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>
              {' '}
              {AccountProfileStrings.Email}
            </Typography>
            <Typography className={styles.profileRowSubTitle}>
              {AccountProfileStrings.UpdateTheEmail}
            </Typography>
          </Grid>
          <Box>
            <TextFieldComponent
              placeholder="Your Email"
              name="email"
              value={values.email || ''}
              onChange={(event) => {
                handleChange(event);
                trackEvent(AccountEventNames.profileEmailTyped, {
                  value: event.target.value,
                });
              }}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              errorText={errors.email || undefined}
              helperText
              className={styles.textfield}
              disabled
            />
          </Box>
        </Grid>

        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>
              {' '}
              {AccountProfileStrings.Password}
            </Typography>
            <Typography className={styles.profileRowSubTitle}>
              {AccountProfileStrings.UpdateThePassword}
            </Typography>
          </Grid>
          <Button
            onClick={() => {
              openPasswordModal();
              trackEvent(AccountEventNames.profileButtonChangePasswordClicked);
            }}
            variant="outlined"
            size="large"
            color="primary"
            sx={{
              borderRadius: '10px',
              border: '1px solid #E1E5EE',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '26px',
              minWidth: '420px',
            }}
          >
            {CommonStrings.Change}
          </Button>
          {/* <Box>
            <Typography>Last changed: Oct 9, 2022</Typography>
          </Box> */}
        </Grid>
      </Box>
    </Box>
  );
};
