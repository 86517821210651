import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '303px',
    height: '303px',
    position: 'relative',
  },
  contentWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  companyLogo: {
    margin: '29px auto 0 auto',
    height: '60px',
    width: 'auto',
  },
  headerText: {
    whiteSpace: 'nowrap',
    width: 'fit-content',
    margin: '0 auto',
    fontWeight: 700,
    color: theme.palette.common.white,
  },
  customerName: {
    fontSize: '25px',
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  rewardTitle: {
    color: theme.palette.common.white,
    fontWeight: 700,
    textShadow: '0px 2px 1px rgba(0, 0, 0, 0.25)',
    marginBottom: '9px',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    margin: '0 auto',
  },
  companyName: {
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  infoText: {
    fontSize: '12px',
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  infoWrapper: {
    position: 'absolute',
    bottom: '18px',
    width: '100%',
  },
  customBackground: {
    borderRadius: '15px',
  },
  contactWrapper: {
    marginTop: '4px',
    marginBottom: '6px',
  },
  contactHeader: {
    fontSize: '13px',
    color: theme.palette.common.white,
  },
  contact: {
    fontSize: '13px',
    fontWeight: 600,
    color: theme.palette.common.white,
    height: '19px',
  },
}));
