import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 0',
  },
  title: {
    color: '#101828',
    fontSize: '18px',
    fontWeight: 600,
    marginBottom: '10px',
  },
  subTitle: {
    color: '#475467',
    fontSize: '14px',
    maxWidth: '440px',
    textAlign: 'center',
  },
  divider: {
    margin: '20px 0',
    borderColor: '#EAECF0',
    height: '1px',
    width: '100%',
  },
  noVideoWrapper: {
    width: '568px',
    height: '514px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noVideoTitle: {
    color: '#101828',
    fontSize: '38px',
    fontWeight: 600,
    marginBottom: '20px',
  },
  noVideoSubTitle: {
    color: '#475467',
    textAlign: 'center',
    fontSize: '20px',
    width: '85%',
  },
  videoWrapper: {
    position: 'relative',
    width: '289px',
    height: '514px',
    backgroundColor: '#000',
    border: '3px solid #EAECF0',
    borderRadius: '14px',
    overflow: 'hidden',
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffffff80',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  closeButton: {
    padding: '10px 23px',
    height: '44px',
    fontWeight: 600,
    borderRadius: '8px',
    color: '#344054',
    border: '1px solid #D0D5DD',
    marginTop: '15px',

    '&:hover': {
      backgroundColor: 'white',
      borderColor: '#344454',
    },
  },
}));
