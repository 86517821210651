import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.common.white,
    borderRadius: '0 10px 10px 10px',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 20px 11px 28px',
  },
  table: {
    '& #common-table-wrapper': {
      maxHeight: '630px',
      height: '630px',
    },

    '& table': {
      borderSpacing: '0 !important',
    },
    '& th': {
      '& > div': {
        padding: '5px !important',
        fontSize: '12px',
      },

      '&:nth-child(1) div': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '13px !important',
        minWidth: '200px',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '15px',
      },
    },
    '& tr': {
      height: '48px !important',
      fontSize: '2px !important',
      borderSpacing: 'none !important',
    },
    '& td': {
      fontSize: '13px !important',
      cursor: 'default !important',
      '& span': { fontSize: '13px !important' },
      '& p': { fontSize: '13px !important' },
      '& > div': {
        padding: '5px !important',
        fontSize: '13px !important',
      },
      '&:nth-child(1)': {
        paddingLeft: '10px !important',
        minWidth: '220px',
      },
      '&:nth-child(2)': { paddingLeft: 0, textAlign: 'center' },
      '&:nth-child(2) div': {
        margin: '0 auto',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '20px',
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '20px',
      },
      '&:nth-child(5)': {
        paddingLeft: '15px',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        paddingLeft: '0',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
      },
      '&:nth-child(8) span': {
        paddingLeft: '0',
        display: 'block',
        margin: '0 auto',
      },
    },
  },
}));
