import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTooltipStyles = makeStyles((theme: Theme) => ({
  Icon: {
    width: '14px',
    height: '14px',
    color: '#8B89A0',
    marginLeft: '12px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));
