import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useRightSideWelcomeLayoutStyles = makeStyles((theme: Theme) => ({
  description: {
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: '#475467',
    marginBottom: '25px',
  },
  iconBadge: {
    background: 'linear-gradient(180deg, #D9D5FB 0%, #5242EA 100%);',
    border: '1px solid #F7B409',
    boxShadow: '4px 9px 14px rgba(155, 156, 142, 0.26)',
    borderRadius: '8px 0px 0px 8px',
    width: '124px',
    height: '152px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
  },
  icon: {
    width: '86px',
    height: '86px',
    border: '1px solid #5242EA',
    borderRadius: '50%',
    boxShadow: '0px 9px 20px rgba(20, 21, 61, 0.15)',
    backdropFilter: 'blur(5px)',
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFF',
  },
  stepTitle: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: '#101828',
    marginBottom: '10px',
  },
  stepDescription: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '21px',
    letterSpacing: '0.02em',
    color: '#484848',
  },
  previewContainer: {
    height: '500px',
    width: '480px',
    borderRadius: '16px',
    backgroundColor: '#FFF',
    padding: '40px 0px 40px 20px',
    position: 'relative',
    margin: '0 auto',
  },
  previewImg: {
    position: 'absolute',
    top: '40px',
    height: '430px',
    width: '510px',
    objectPosition: 'left',
  },
}));
