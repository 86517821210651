import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FSModal } from '../../../../common/components/modal/Modal';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  getCampaignById,
  getPrimaryCampaign,
  patchIncentiveCampaign,
} from '../../../../store/slices/campaignsSlice';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    textAlign: 'center',
  },
  subtitle: {
    marginTop: '4px',
    marginBottom: '30px',
    padding: '0px 100px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'center',
  },
  btn: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    width: '100%',
  },
}));

interface SetPrimaryCampaignModalProps {
  campaignId: string;
  isOpen: boolean;
  onClose: () => void;
}

const SetPrimaryCampaignModal: FC<SetPrimaryCampaignModalProps> = ({
  campaignId,
  isOpen,
  onClose,
}) => {
  const styles = useStyles();
  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const dispatch = useAppDispatch();
  const onSubmit = () => {
    dispatch(patchIncentiveCampaign({ campaignId, values: { isPreferred: true } })).then(() => {
      dispatch(getCampaignById(campaignId));
      dispatch(
        getPrimaryCampaign({
          venueId,
        }),
      );
    });
    onClose();
  };
  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur
      width="640px"
      padding="16px"
    >
      <Typography className={styles.title}>Make this your Primary Campaign?</Typography>
      <Typography className={styles.subtitle}>
        All videos created by campaigns that are no longer active will be added to this campaign.
      </Typography>
      <Stack display={'flex'} justifyContent="center" direction="row" spacing={'12px'}>
        <Button className={styles.btn} onClick={onClose} variant="outlined">
          Not At This Time
        </Button>
        <Button className={styles.btn} onClick={onSubmit} variant="contained" color="primary">
          Set As Primary Campaign
        </Button>
      </Stack>
    </FSModal>
  );
};

export default SetPrimaryCampaignModal;
