import * as React from 'react';
import { SVGProps } from 'react';

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={30}
    viewBox="0 0 19 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.667 2.093c-3.26 0-5.903 2.655-5.903 5.93v4.242c0 .577-.466 1.046-1.042 1.046H2.208v3.433H6.24c.575 0 1.042.469 1.042 1.047v10.116h3.714V17.791c0-.578.466-1.047 1.041-1.047h3.543l.906-3.488h-4.207a1.044 1.044 0 0 1-1.042-1.047V9.42c0-2.12 1.71-3.838 3.82-3.838h1.736V2.093h-3.125ZM5.68 8.023C5.68 3.593 9.256 0 13.667 0h4.166c.576 0 1.042.469 1.042 1.047v5.58c0 .579-.466 1.047-1.042 1.047h-2.777a1.74 1.74 0 0 0-1.737 1.745v1.744h4.514c.323 0 .627.15.825.406.197.257.265.59.183.904l-1.449 5.582c-.12.46-.534.782-1.008.782h-3.306v10.116c0 .579-.466 1.047-1.042 1.047H6.24a1.044 1.044 0 0 1-1.042-1.047V18.837h-4.03a1.044 1.044 0 0 1-1.042-1.046v-5.526c0-.578.466-1.047 1.042-1.047H5.68V8.023Z"
      fill={props.color || '#fff'}
    />
  </svg>
);

export default FacebookIcon;
