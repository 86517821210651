import {
  CreateIncentiveCampaignApiModel,
  CustomerActivitiesType,
  GetCampaignsAPIModel,
} from '../../api/models/campaigns';
import { replaceMessageBodyWithApiVariables } from '../../common/components/SMS/SMS.helper';
import { useSetDefaultSMSTemplates } from '../NewCampaign/CustomizeSMS/DefaultSMSTemplates/useSetDefaultSMSTemplates';
import { RewardFulfillmentMethod } from '../../api/models/rewardFulfillment';
import { RewardStrings } from '../../common/localization/en';
import { DEFAULT_POINTS_GOAL } from '../../common/constants/constants';
import { activitiesForMarketing } from '../../common/constants/defaults';
import { VideoStyleTypes } from '../NewCampaign/VideoStyleTypesSwitch/VideoStyleTypesSwitch';
import { useAppDispatch, useTypedSelector } from '../../store';
import { getCampaigns, postIncentiveCampaign } from '../../store/slices/campaignsSlice';
import { GetTableDataResponse } from '../../api/models/common';

export const useCreateDefaultCampaign = () => {
  const { defaultInviteMessage, defaultRewardMessage } = useSetDefaultSMSTemplates(true);
  const dispatch = useAppDispatch();

  const { page, size, sort, search = '' } = useTypedSelector((state) => state.campaigns);

  const getDefaultCampaignValues = (venueId: string): CreateIncentiveCampaignApiModel => {
    return {
      venueId,
      name: RewardStrings.DefaultAwarenessCampaignName,
      imageUrl: '',
      points: DEFAULT_POINTS_GOAL,
      isLocked: false,
      rewardTitle: '',
      rewardDescription: RewardStrings.IsNullRewardDefaultDescription,
      campaignObjective: CustomerActivitiesType.videoContent,
      activities: activitiesForMarketing,
      couponEnabled: true,
      isNullReward: true,
      isPreferred: true,
      fulfillmentConfig: {
        method: RewardFulfillmentMethod.AUTOMATIC_SMS,
        sms: {
          mediaUrl: '',
          body: replaceMessageBodyWithApiVariables(defaultRewardMessage, true),
        },
      },
      invitationMsgConfig: {
        mediaUrl: '',
        body: replaceMessageBodyWithApiVariables(defaultInviteMessage, true),
      },
      recommendedVideoStyle: VideoStyleTypes.Inspirational,
    };
  };

  const createDefaultCampaign = async (venueId: string) => {
    return dispatch(getCampaigns({ search, venueId, pageable: { page, size, sort } })).then(
      (data) => {
        if (data.meta.requestStatus === 'rejected') return;

        const campaignsTotal = (data.payload as GetTableDataResponse<GetCampaignsAPIModel>)
          .totalItems;

        if (campaignsTotal > 0) return;

        return dispatch(postIncentiveCampaign(getDefaultCampaignValues(venueId)));
      },
    );
  };

  return { getDefaultCampaignValues, createDefaultCampaign };
};
