import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
    <mask
      id="mask0_2080_94431"
      width={60}
      height={60}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <circle cx={30} cy={30} r={30} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask0_2080_94431)">
      <circle
        cx={30}
        cy={30}
        r={29.2}
        fill="#D9D4FF"
        stroke="#5242EA"
        strokeWidth={1.6}
        opacity={0.8}
      />
      <circle
        cx={29.765}
        cy={29.929}
        r={20.354}
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeWidth={1.6}
        transform="rotate(-.122 29.765 29.93)"
      />
      <path
        fill="#5242EA"
        d="M30.083 41.738c6.674 0 12.084-5.41 12.084-12.083 0-6.674-5.41-12.084-12.084-12.084C23.41 17.571 18 22.981 18 29.655c0 6.673 5.41 12.083 12.083 12.083Z"
      />
      <path
        fill="#5242EA"
        d="M34.313 26.03a.604.604 0 1 1-1.209 0 .604.604 0 0 1 1.209 0ZM27.063 26.03a.604.604 0 1 1-1.209 0 .604.604 0 0 1 1.209 0ZM30.083 36.3c3.022 0 5.438-2.214 5.438-4.229H24.646c0 2.015 2.416 4.23 5.437 4.23Z"
      />
      <path
        stroke="#A69DF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M33.708 26.03h.012m-7.262 0h.012m15.697 3.625c0 6.673-5.41 12.083-12.084 12.083C23.41 41.738 18 36.328 18 29.655c0-6.674 5.41-12.084 12.083-12.084 6.674 0 12.084 5.41 12.084 12.084Zm-7.855-3.625a.604.604 0 1 1-1.208 0 .604.604 0 0 1 1.209 0Zm-7.25 0a.604.604 0 1 1-1.208 0 .604.604 0 0 1 1.209 0Zm3.021 10.27c3.022 0 5.438-2.214 5.438-4.229H24.646c0 2.015 2.416 4.23 5.437 4.23Z"
      />
    </g>
  </svg>
);
const VideoStyleFunnyIconActive = memo(SvgComponent);
export default VideoStyleFunnyIconActive;
