import { FC } from 'react';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { CampaignsApiModel, CampaignUser } from '../../../api/models/campaigns';
import { useCampaignControlsStyles } from './CampaignControls.style';
import { LearnMoreStrings, RewardsPageStrings } from '../../../common/localization/en';
import { downloadUrl } from '../../../services/utilities';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { VideoTypeLegend } from '../../NewCampaign/BusinessVideoStylePreview/BusinessVideoStylePreview';
import { activityTypesContent } from '../../NewCampaign/BusinessVideoStylePreview/BusinessVideoStylePreview.helper';
import { LearnMoreTooltip } from '../../../common/components/LearnMoreTooltip/LearnMoreTooltip';

interface CampaignControlsProps {
  currentRewardCampaign: CampaignsApiModel;
  currentRewardCampaignExistedUsers: CampaignUser[];
  onTrackEvent: IEventTracker;
}

export const CampaignControls: FC<CampaignControlsProps> = ({
  currentRewardCampaign,
  onTrackEvent,
}) => {
  const { campaignObjective: previewType } = currentRewardCampaign;
  const campaignSMSMethod = currentRewardCampaign.fulfillmentConfig?.method;
  const styles = useCampaignControlsStyles({ campaignSMSMethod });
  const { addToast } = useToasts();
  const { videos: totalVideosCount } = currentRewardCampaign;
  const isCampaignEnded = !!currentRewardCampaign.endedAt;
  const handleDownloadReport = () => {
    onTrackEvent(RewardCampaignsEvents.RewardCampaignsDetailsDownloadReportClick);
    const id = currentRewardCampaign.id;
    const name = currentRewardCampaign.name;
    const downloadTemplateUrl = `${process.env.REACT_APP_NEW_API_URL}/api/v1/incentive-campaigns/${id}/report`;
    downloadUrl(downloadTemplateUrl, name);
    addToast(RewardsPageStrings.downloadSummaryMessage, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  // if (!previewType) {
  //   return null;
  // }

  return (
    <Paper elevation={0} className={styles.container}>
      <Box
        sx={{
          padding: '20px 24px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '10px',
        }}
      >
        <Box>
          <Typography className={styles.CampaignObjective}>
            {RewardsPageStrings.CampaignObjective}
          </Typography>
          <Typography className={styles.CampaignObjectiveProperty}>
            {previewType
              ? activityTypesContent[previewType].diagramLegendTitle
              : currentRewardCampaign.rewardTitle || ''}
          </Typography>
        </Box>
        <Box>
          <Typography className={styles.SummaryInfo}>{RewardsPageStrings.TotalVideos}</Typography>
          <Typography className={styles.Videos}>{totalVideosCount}</Typography>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: '10px' }} />
      <Box className={styles.DiagramContainer}>
        {previewType && (
          <>
            <VideoTypeLegend previewType={previewType} isHorizontal />
            <Typography className={styles.EligibilityDescription}>
              {activityTypesContent[previewType].description}
            </Typography>
          </>
        )}
      </Box>
      <Divider sx={{ marginBottom: '10px', marginTop: '50px' }} />
      <Box
        sx={{
          display: 'flex',
          padding: '0px 24px 12px',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box className={styles.Tooltip}>
          <LearnMoreTooltip title={LearnMoreStrings.RewardString} />
        </Box>
        <Button
          variant="outlined"
          className={styles.button}
          onClick={handleDownloadReport}
          disabled={!isCampaignEnded}
          id="view-full-report-button"
        >
          {RewardsPageStrings.ViewFullReport}
        </Button>
      </Box>
    </Paper>
  );
};
