import { FC } from 'react';
import { Grid } from '@mui/material';

import { KPIApiModel, KPIApiNames } from '../../../api/models/analytics';
import { KPIDTOActiveAnalyticsBlock } from '../../../store/storeModels';
import { ClientTypes } from '../../../api/models/common';
import {
  widgetInitialAnalyticsData,
  webInitialAnalyticsData,
  widgetInitialAnalyticsTBDData,
  webInitialAnalyticsTBDData,
  getAnalyticsData,
  webInitialAnalyticsTopGolfData,
  AnalyticsBlockModel,
} from './AnalyticsSection.helper';
import { useTypedSelector } from '../../../store';
import { AnalyticsBlocks } from './AnalyticsBlocks/AnalyticsBlocks';
import { Container } from './AnalyticsSection.style';
import { NumberWithOneDecimal, stringToNumber } from '../../../services/utilities';
import { useClientType } from '../../../services/hooks/useClientType';

interface AnalyticsSectionProps {
  isWidget: boolean;
  isHeroes: boolean;
  onClick: (kpiName: KPIApiNames, name: string) => void;
  kpis: KPIApiModel;
  activeAnalyticsBlock: KPIDTOActiveAnalyticsBlock;
}

export const AnalyticsSection: FC<AnalyticsSectionProps> = ({
  isWidget,
  isHeroes,
  kpis,
  onClick,
  activeAnalyticsBlock,
}) => {
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const widgetAnalyticsData = getAnalyticsData(
    kpis,
    widgetInitialAnalyticsData,
  ) as AnalyticsBlockModel[];
  const webAnalyticsData = getAnalyticsData(
    kpis,
    clientType === ClientTypes.TOPGOLF ? webInitialAnalyticsTopGolfData : webInitialAnalyticsData,
  ) as AnalyticsBlockModel[];

  const { isHealthCare, isTopGolfClient } = useClientType();

  const divided = (a: number, b: number, percent?: number) => {
    return percent
      ? b
        ? `${NumberWithOneDecimal((a / b) * percent)}%`
        : '0%'
      : b
      ? `${NumberWithOneDecimal(a / b)}`
      : '0';
  };
  const getConversionAnalytics = (items: AnalyticsBlockModel[]) => {
    const visitors = stringToNumber(
      items.find((item) => item.kpiName === KPIApiNames.visitors)?.value,
    );
    const users = stringToNumber(items.find((item) => item.kpiName === KPIApiNames.users)?.value);
    const videos = stringToNumber(items.find((item) => item.kpiName === KPIApiNames.Videos)?.value);
    const shares = stringToNumber(items.find((item) => item.kpiName === KPIApiNames.shares)?.value);
    const topgolfviews = stringToNumber(
      items.find((item) => item.kpiName === KPIApiNames.videoViews)?.value,
    );

    const views = stringToNumber(
      items.find((item) => item.kpiName === KPIApiNames.VideoViews)?.value,
    );
    const CTA = stringToNumber(items.find((item) => item.kpiName === KPIApiNames.CTAClicks)?.value);
    const convertedReferrals = stringToNumber(
      items.find((item) => item.kpiName === KPIApiNames.ConvertedReferrals)?.value,
    );
    return {
      videosRatioValue: divided(users, videos),
      sharesRatioValue: divided(shares, videos),
      viewsRatioValue: divided(topgolfviews, shares),
      CTARatioValue: divided(CTA, topgolfviews, 100),
      userRationValue: divided(users, visitors, 100),
      sharesPerUsersRationValue: divided(shares, users, 100),
      NotgvideosRatioValue: divided(videos, users),
      NotgCTARatioValue: divided(CTA, views, 100),
      NotgviewsRatioValue: divided(views, shares),
      convertedReferralsRatioValue: divided(convertedReferrals, CTA, 100),
    };
  };
  const {
    sharesRatioValue,
    viewsRatioValue,
    CTARatioValue,
    userRationValue,
    sharesPerUsersRationValue,
    NotgvideosRatioValue,
    NotgviewsRatioValue,
    NotgCTARatioValue,
    convertedReferralsRatioValue,
  } = getConversionAnalytics(webAnalyticsData);

  const webAnalyticsDataWithRate = (items: AnalyticsBlockModel[]) => {
    if (clientType === ClientTypes.TOPGOLF) {
      return items.map((item) => {
        switch (item.kpiName) {
          case KPIApiNames.users:
            item.ratioValue = userRationValue;
            break;
          case KPIApiNames.shares:
            item.ratioValue = sharesPerUsersRationValue;
            break;
          case KPIApiNames.videoViews:
            item.ratioValue = viewsRatioValue;
            break;
          case KPIApiNames.CTAClicks:
            item!.ratioValue = CTARatioValue;
            break;
          case KPIApiNames.ConvertedReferrals:
            item.ratioValue = convertedReferralsRatioValue;
            break;
        }
        return item;
      });
    } else {
      return items.map((item) => {
        switch (item.kpiName) {
          case KPIApiNames.Videos:
            item.ratioValue = NotgvideosRatioValue;
            break;
          case KPIApiNames.shares:
            item.ratioValue = sharesRatioValue;
            break;
          case KPIApiNames.VideoViews:
            item.ratioValue = NotgviewsRatioValue;
            break;
          case KPIApiNames.CTAClicks:
            item.ratioValue = NotgCTARatioValue;
            break;
          case KPIApiNames.ConvertedReferrals:
            item.ratioValue = convertedReferralsRatioValue;
            break;
        }
        return item;
      });
    }
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {isWidget && (
          <Grid item xs={12}>
            <AnalyticsBlocks
              onClick={onClick}
              items={widgetAnalyticsData}
              TBDItem={widgetInitialAnalyticsTBDData}
              activeAnalyticsBlock={activeAnalyticsBlock}
              isTopgolf={isTopGolfClient}
              isHealthCare={isHealthCare}
            />
          </Grid>
        )}
        {isHeroes && (
          <Grid item xs={12}>
            <AnalyticsBlocks
              onClick={onClick}
              items={webAnalyticsDataWithRate(webAnalyticsData)}
              TBDItem={webInitialAnalyticsTBDData}
              activeAnalyticsBlock={activeAnalyticsBlock}
              isTopgolf={isTopGolfClient}
              isHealthCare={isHealthCare}
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
