import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    background: '#F5F6F8',
    borderRadius: '8px 50px 50px 8px',
    overflow: 'hidden',
  },
  urlField: {
    height: '35px',
    width: 'calc(100% - 60px)',
    '& .MuiOutlinedInput-root': {
      height: '35px',
      border: 'none',
      borderRadius: '50px',
      '& fieldset': {
        border: 'none',
      },
    },
    '& input': {
      height: '35px',
      borderRadius: '50px',
      fontSize: '11px',
      background: '#F5F6F8',
    },
  },
  fieldsWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '50px',
  },
  btn: {
    height: '80%',
    position: 'absolute',
    right: '0',
    transition: 'all 0.2s linear',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '&:hover': {
      boxShadow: 'none',
    },

    '& p': {
      fontSize: '12px',
    },
  },
  copiedIndicator: {
    width: '100%',
    height: '35px',
    background: '#17B950',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },
  copiedText: {
    color: '#fff',
    fontSize: '11px',
    marginLeft: '9px',
  },
}));
