import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { useInviteTeamMemberStyles } from './InviteTeamMember.style';
import { CommonStrings, TeamSettingsStrings } from '../../localization/en';

// import { Invitations } from '../Invitations/Invitations';

// import { UserLabel } from './UserLabel';
import { WebsiteRoutes } from '../../constants/routes';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  createVenueManager,
  deleteVenueManager,
  getVenueManagerList,
} from '../../../store/slices/venueManagerSlice';
import { DeleteModal } from './DeleteModal';
import { CancelModal } from './CancelModal';
import { VenueMappingRoles } from '../../constants/constants';
import {
  DEFAULT_PHONE_VALIDATION_STATE,
  PhoneField,
  PhoneValidationState,
} from '../PhoneField/PhoneField';
import { getValidationSchemaInviteTeamMember } from './InviteTeamMember.helper';
import { RoundedButton } from '../Button/RoundedButton';
import { PageWithHeaderLayout } from '../../layout/PageWithHeaderLayout/PageWithHeaderLayout';
import { useModal } from '../../../hooks/useModal';
import { useVenue } from '../../../hooks/useVenue';
import { isObjectEmpty } from '../../../services/utilities';
import { CustomTextField } from '../customTextField/CustomTextField';
import { MemberInformation } from '../MemberInformation/MemberInformation';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../constants/events/accountEvents';
// import { Alert } from '../Alert/Alert';

interface InviteTeamMemberProps {}

export const InviteTeamMember: FC<InviteTeamMemberProps> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const style = useInviteTeamMemberStyles();
  const { addToast } = useToasts();
  const theme = useTheme();
  const params = useParams<{ id?: string }>();
  const memberId = params.id;

  const {
    isOpen: showDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal(false);

  const {
    isOpen: showCancelModalOpen,
    open: openCancelModal,
    close: closeCancelModal,
  } = useModal(false);

  const { venueId } = useVenue({
    onVenueLoad: () => {
      dispatch(getVenueManagerList({ venueId }));
    },
  });

  const {
    items: venueMembers,
    sort,
    // loggedInManager: loggedInMember,
    // isLoading: isVenueMembersLoading,
  } = useTypedSelector((state) => state.VenueManagerSlice);

  const me = useTypedSelector((state) => state.me);

  const phoneValidationRef = useRef<PhoneValidationState>(DEFAULT_PHONE_VALIDATION_STATE);

  const currentMember = useMemo(() => {
    return venueMembers.find((item) => item.id === memberId);
  }, [memberId, venueMembers]);

  // const canIChangeRoles = useMemo(
  //   () =>
  //     myRole === UserRoles.admin || loggedInMember?.venueMappingRole === VenueMappingRoles.owner,
  //   [loggedInMember?.venueMappingRole, myRole],
  // );

  const isActiveCurrentMember = currentMember && currentMember.status !== 'PENDING';

  const isPending = currentMember?.status === 'PENDING';

  const canEditForm = !currentMember;

  const initialValues = useMemo(() => {
    return {
      name: currentMember?.name || '',
      lastName: currentMember?.lastName || '',
      email: currentMember?.email || '',
      phone: currentMember?.phoneNumber || '',
      role: currentMember?.venueMappingRole || VenueMappingRoles.member,
    };
  }, [currentMember]);

  const {
    values,
    handleChange,
    errors,
    dirty,
    submitForm,
    validateField,
    setFieldValue,
    touched,
    handleBlur,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: getValidationSchemaInviteTeamMember({
      phoneValidationRef,
    }),
    onSubmit: async (values) => {
      if (isAlreadyInvited) {
        addToast(TeamSettingsStrings.UserAlreadyInvited, {
          appearance: 'warning',
          autoDismissTimeout: 2500,
        });
        return;
      }

      await dispatch(
        createVenueManager({
          venueId,
          item: {
            email: values.email,
            name: `${values.name} ${values.lastName}`,
            role: values.role as VenueMappingRoles,
            phoneNumber: values.phone,
          },
        }),
      );
      addToast(TeamSettingsStrings.addManagerMessage.replace('<email>', values.email), {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
      redirectToAccountMembers();
    },
  });

  const isEmailInvited = useMemo(() => {
    return (
      venueMembers.map((member) => member.email).includes(values.email) || me.email === values.email
    );
  }, [values.email, venueMembers, me.email]);

  const isPhoneInvited = useMemo(() => {
    if (!values.phone) return false;
    return (
      venueMembers.map((member) => member.phoneNumber).includes(values.phone) ||
      me.phoneNumber === values.phone
    );
  }, [values.phone, venueMembers, me.phoneNumber]);

  const isAlreadyInvited = isEmailInvited || isPhoneInvited;

  const redirectToAccountMembers = () => {
    trackEvent(AccountEventNames.buttonBackClicked);
    history.push(WebsiteRoutes.AccountsMembers);
  };

  const handleDelete = async () => {
    trackEvent(AccountEventNames.buttonDeleteClicked, {
      memberId: memberId,
    });
    if (currentMember) {
      addToast(TeamSettingsStrings.deleteManagerMessage.replace('<email>', currentMember.email), {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
      await dispatch(deleteVenueManager({ venueId, managerId: currentMember.id }));
      dispatch(getVenueManagerList({ venueId, sort }));
      redirectToAccountMembers();
    }
  };

  const handleExitInviting = () => {
    redirectToAccountMembers();
  };

  // const handleChangeStatus = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const newRole = event.target.value;

  //   if (!canIChangeRoles) {
  //     return;
  //   }

  //   setFieldValue('role', newRole);

  //   if (currentMember && currentMember.role && !isVenueMembersLoading) {
  //     await dispatch(
  //       updateVenueMemberRole({
  //         venueId,
  //         role: newRole,
  //         memberId: currentMember.id,
  //       }),
  //     );
  //     addToast('Role have been updated', {
  //       appearance: 'success',
  //       autoDismissTimeout: 2500,
  //     });
  //   }
  // };

  // const isEditMemberPage =

  const canSave = useMemo(() => {
    return dirty && isObjectEmpty(errors) && values.name && values.email;
  }, [dirty, errors, values.email, values.name]);

  const canDeleteManager = currentMember ? me.id === currentMember.id : true;

  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? errors[field] : undefined;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <PageWithHeaderLayout
        header={{
          title:
            params && params.id
              ? `${TeamSettingsStrings.MemberProfile}`
              : `${TeamSettingsStrings.InviteTitle}`,
          leftButton: {
            title: CommonStrings.Back,
            outlined: true,
            onClick: dirty ? openCancelModal : redirectToAccountMembers,
            textProps: { padding: '14px 36px' },
          },
          rightButton: {
            disabled: !canSave,
            onClick: submitForm,
            title: isActiveCurrentMember
              ? TeamSettingsStrings.Save
              : TeamSettingsStrings.InviteMember,
            textProps: { padding: '14px 36px' },
            backgroundColor: !canSave ? '#5242EA66' : '',
            hide: !!currentMember,
          },
        }}
        bgcolor={theme.palette.common.white}
        isFixed
      >
        <Grid
          container
          component="main"
          pt="81px"
          sx={{
            backgroundColor: '#EAECF0',
            flexGrow: 1,
            // height: 'calc(100% - 81px - 81px)', overflowY: 'auto'
          }}
        >
          <Grid item xs={8} display="flex" justifyItems="center" width="100%">
            <Box display="flex" flexDirection="row" justifyItems="center" width="100%">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="610px"
                ml="auto"
                marginTop="40px"
                // gap="20px"
              >
                {/* {currentMember && (
                  <UserLabel
                    email={currentMember.email}
                    phoneNumber={currentMember.phoneNumber}
                    isPending={isPending}
                  />
                )} */}

                {!isPending && !currentMember ? (
                  <>
                    <Box width="100%" sx={{ mt: '10%' }}>
                      <Typography component="h1" className={style.MainTitle}>
                        {TeamSettingsStrings.MainTittle}
                      </Typography>
                    </Box>
                    <Box width="100%">
                      <Typography className={style.MainDescription}>
                        {TeamSettingsStrings.MainDescription}
                      </Typography>
                    </Box>
                    <Box
                      mt="30px"
                      // borderRadius="12px"
                      // padding="14px 20px"
                      // bgcolor={theme.palette.common.pageBackground}
                      width="100%"
                      sx={{
                        opacity: canEditForm ? 'auto' : 0.5,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Box className={style.CustomTextField}>
                            <CustomTextField
                              label={TeamSettingsStrings.FirstName + '*'}
                              error={getFieldError('name')}
                              name="name"
                              value={values.name}
                              placeholder={TeamSettingsStrings.FirstNamePlaceholder}
                              handleChange={(event) => {
                                handleChange(event);
                                trackEvent(AccountEventNames.memberNameTyped, {
                                  value: event.target.value,
                                  memberId,
                                });
                              }}
                              onBlur={(e) => {
                                validateField('name');
                                handleBlur(e);
                              }}
                              disabled={!canEditForm}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={style.CustomTextField}>
                            <CustomTextField
                              label={TeamSettingsStrings.LastName + '*'}
                              error={getFieldError('lastName')}
                              name="lastName"
                              value={values.lastName}
                              placeholder={TeamSettingsStrings.LastNamePlaceholder}
                              handleChange={(event) => {
                                handleChange(event);
                                trackEvent(AccountEventNames.memberLastNameTyped, {
                                  value: event.target.value,
                                });
                              }}
                              onBlur={(e) => {
                                validateField('lastName');
                                handleBlur(e);
                              }}
                              disabled={!canEditForm}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className={style.CustomTextField}>
                        <CustomTextField
                          label={CommonStrings.Email + '*'}
                          error={getFieldError('email')}
                          name="email"
                          value={values.email}
                          placeholder={TeamSettingsStrings.EmailPlaceholder}
                          handleChange={(event) => {
                            handleChange(event);
                            trackEvent(AccountEventNames.memberEmailTyped, {
                              value: event.target.value,
                            });
                          }}
                          onBlur={(e) => {
                            validateField('email');
                            handleBlur(e);
                          }}
                          disabled={!canEditForm}
                        />
                      </Box>
                      <Typography className={style.LabelEmail}>
                        {CommonStrings.CellPhone + '*'}
                      </Typography>
                      <PhoneField
                        name="phone"
                        value={values.phone}
                        onChange={(event) => {
                          handleChange(event);
                          trackEvent(AccountEventNames.memberPhoneTyped, {
                            value: event.target.value,
                          });
                        }}
                        className={style.InputField}
                        error={getFieldError('phone')}
                        onValidationChange={(value) => {
                          phoneValidationRef.current = value;
                        }}
                        onBlur={(e) => {
                          validateField('phone');
                          handleBlur(e);
                        }}
                        placeholder="Enter Cell Number"
                        disabled={!canEditForm}
                        setFieldValue={(value: string) => {
                          return setFieldValue('phone', value);
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  currentMember && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <MemberInformation member={currentMember} />
                      <RoundedButton
                        style={{ alignSelf: 'center', justifySelf: 'center', marginTop: '10px' }}
                        sx={{ mb: '24px', '& p': { color: canDeleteManager ? 'grey' : '' } }}
                        error
                        onClick={openDeleteModal}
                        title={
                          isPending
                            ? TeamSettingsStrings.RevokeInvite
                            : TeamSettingsStrings.RemoveMember
                        }
                        disabled={canDeleteManager || venueMembers.length === 1}
                      />
                    </div>
                  )
                )}

                {/* removed manager's permissions by https://socialvenu.atlassian.net/browse/MYF-3254 */}
                {/* [Account Details] Members - Remove all reference to the permissions component */}
                {/* <MemberRoleSwitch
                  role={values.role}
                  onChange={handleChangeStatus}
                  isPending={isPending}
                /> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} display="flex" justifyItems="flex-start">
            <Box display="flex" flexDirection="column" alignItems="center">
              {/* {currentMember && (
                <RoundedButton
                  sx={{ mb: '24px', '& p': { color: canDeleteManager ? 'grey' : '' } }}
                  error
                  onClick={openDeleteModal}
                  title={
                    isPending ? TeamSettingsStrings.RevokeInvite : TeamSettingsStrings.RemoveMember
                  }
                  disabled={canDeleteManager}
                />
              )} */}
              {/* {currentMember && (
                <Invitations
                  manager={currentMember}
                  managerRole={values.role}
                  isPending={isPending}
                />
              )} */}
            </Box>
          </Grid>
        </Grid>
        {!currentMember && (
          <Box
            component="footer"
            position="fixed"
            bottom="0"
            display="flex"
            width="100%"
            borderTop={`1px solid ${theme.palette.divider}`}
            bgcolor={theme.palette.common.white}
          >
            <Grid container alignItems="baseline" p="0px" height="81px">
              <Grid item xs={3} width="100%" />
              {/* <Grid item xs={6} width="100%" display="flex">
                <RoundedButton
                  disabled={!canSave}
                  onClick={submitForm}
                  title={TeamSettingsStrings.InviteMember}
                />
              </Grid> */}
              <Grid item xs={3} />
            </Grid>
          </Box>
        )}
      </PageWithHeaderLayout>
      <DeleteModal
        openDeleteModal={showDeleteModalOpen}
        handleClose={() => {
          closeDeleteModal();
          trackEvent(AccountEventNames.buttonCancelDeleteClicked, {
            memberId,
          });
        }}
        handleDelete={handleDelete}
      />
      <CancelModal
        openDeleteModal={showCancelModalOpen}
        handleClose={closeCancelModal}
        handleExitInviting={handleExitInviting}
      />
    </Box>
  );
};
