import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '18px',
    fontWeight: '500',
    color: '#1A1538',
    marginBottom: '14px',
  },
  subTitle: {
    fontSize: '14px',
    color: '#8B89A0',
    lineHeight: '28px',
    marginBottom: '24px',
  },
  addBelow: {
    fontSize: '12px',
    color: '#8B89A0',
    marginBottom: '5px',
  },
  modalBtn: {
    width: '140px',
    height: '42px',
    fontSize: '12px',
    fontWeight: '400',
    background: '#5242EA',
    borderRadius: '10px',
    color: 'white',
    '& .MuiButton-root:hover': {
      backgroundColor: 'rgba(82, 66, 234, 0.8) !important',
    },
    '&:disabled': {
      background: 'rgba(82, 66, 234, 0.2)',
      color: 'rgba(82, 66, 234, 0.3)',
    },
    '&:hover': {
      backgroundColor: '#3423d1 !important',
    },
  },
  categories: {
    margin: '24px 0 5px',
    fontWeight: '500',
    color: '#1A1538',
  },
  btnWrapper: {
    marginTop: '24px',
    width: 270,
    display: 'flex',
    justifyContent: 'space-between',
  },
  btnSave: {
    width: '150px',
    height: '45px',
    borderRadius: '100px',
  },
}));
