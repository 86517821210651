import { Box, Typography, Button, Stack } from '@mui/material';
import React, { FC, useRef } from 'react';
import { useLatestVideosStyles } from './LatestVideos.styles';
import { CampaignInvitesStrings, LearnMoreStrings } from '../../../common/localization/en';
import { useHistory } from 'react-router-dom';
import { NewCampaignSubroutes, WebsiteRoutes } from '../../../common/constants/routes';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import RecentVideosSlider, { SLIDE_WIDTH } from './RecentVideosSlider/RecentVideosSlider';
import { useModal } from '../../../hooks/useModal';
import { AddIndividualCustomerModal } from './Modals/AddIndividualCustomerModal';
import { ImportFileModal } from './Modals/ImportFileModal';
import { useElementDimensions } from '../../../services/hooks/useElementDimensions';
import { useClientType } from '../../../services/hooks/useClientType';

interface LatestVideosProps {
  items: any[];
  campaignId: string;
  isCampaignDisabled: boolean;
  openWelcomeVideoModal: () => void;
}

export const LatestVideos: FC<LatestVideosProps> = ({
  items,
  campaignId,
  isCampaignDisabled,
  openWelcomeVideoModal,
}) => {
  const styles = useLatestVideosStyles();
  const history = useHistory();
  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();

  const sliderContainer = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useElementDimensions(sliderContainer);

  const CONTAINER_PADDING = 18;
  const slidesPerView = Math.floor(containerWidth / SLIDE_WIDTH);
  const sliderWidth =
    slidesPerView > items.length
      ? containerWidth - CONTAINER_PADDING * 2
      : SLIDE_WIDTH * slidesPerView;

  const {
    isOpen: isImportFileModalOpen,
    open: openImportFileModal,
    close: closeImportFileModal,
  } = useModal(false);

  const {
    isOpen: isAddIndividualModalOpen,
    open: openIndividualModal,
    close: closeIndividualModal,
  } = useModal(false);

  return (
    <Box className={styles.container} ref={sliderContainer}>
      <AddIndividualCustomerModal
        campaignId={campaignId}
        isOpen={isAddIndividualModalOpen}
        onClose={() => {
          closeIndividualModal();
          trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageImportManuallyModalClose, {
            campaignId: campaignId,
          });
        }}
      />

      <ImportFileModal
        campaignId={campaignId}
        isOpen={isImportFileModalOpen}
        onClose={() => {
          closeImportFileModal();
          trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageImportFileModalClose, {
            campaignId: campaignId,
          });
        }}
      />

      {items.length > 0 && (
        <Box width={sliderWidth}>
          <Typography
            className={`${items.length > 0 ? styles.recentTitle : styles.noVideosStyle} ${
              styles.Title
            }`}
          >
            {CampaignInvitesStrings.ListTitle}
          </Typography>
        </Box>
      )}
      {items.length > 0 ? (
        <>
          <RecentVideosSlider
            videos={items}
            campaignId={campaignId}
            sliderWidth={sliderWidth}
            slidesPerView={slidesPerView}
          />
        </>
      ) : (
        <Box display={'flex'} flexDirection="column" alignItems={'center'}>
          <Typography className={styles.Promote}>
            {isHealthCare ? LearnMoreStrings.InviteUsersTrublu : LearnMoreStrings.InviteUsers}
          </Typography>
          <Typography className={styles.Description}>
            {isHealthCare
              ? CampaignInvitesStrings.PromoteInfoTrublu
              : CampaignInvitesStrings.PromoteInfo}
          </Typography>

          <Box className={styles.buttonsWrapper}>
            <Stack direction={'row'} spacing="8px">
              <Button
                variant="outlined"
                disabled={isCampaignDisabled}
                className={styles.Button}
                style={{
                  width: '170px',
                }}
                onClick={() => {
                  history.push(
                    `${WebsiteRoutes.EditCampaign}/${campaignId}/${NewCampaignSubroutes.InviteSms}`,
                  );
                  trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageEditButtonClick, {
                    campaignId: campaignId,
                  });
                }}
                id="edit-sms-invitation-button"
              >
                {CampaignInvitesStrings.EditSMSInvitation}
              </Button>
              <Button
                variant="outlined"
                className={styles.Button}
                disabled={isCampaignDisabled}
                style={{
                  width: '170px',
                }}
                onClick={() => {
                  openWelcomeVideoModal();
                  trackEvent(RewardCampaignsEvents.RewardCampaignSummaryImportManuallyButtonClick, {
                    campaignId: campaignId,
                  });
                }}
                id="edit-welcome-video-button"
              >
                {CampaignInvitesStrings.WelcomeVideo}
              </Button>

              <Button
                variant="outlined"
                className={styles.Button}
                disabled={isCampaignDisabled}
                style={{
                  width: '170px',
                }}
                onClick={() => {
                  openImportFileModal();
                  trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageImportDataButtonClick, {
                    campaignId: campaignId,
                  });
                }}
                id="import-data-button"
              >
                {CampaignInvitesStrings.ImportFile}
              </Button>
            </Stack>
            <Button
              variant="contained"
              className={styles.Button}
              disabled={isCampaignDisabled}
              style={{ color: 'white', width: '215px', marginTop: '8px' }}
              onClick={() => {
                openIndividualModal();
                trackEvent(RewardCampaignsEvents.RewardCampaignSummaryImportManuallyButtonClick, {
                  campaignId: campaignId,
                });
              }}
              id="import-data-manually-button"
            >
              {isHealthCare
                ? CampaignInvitesStrings.ImportDataManuallyTrublu
                : CampaignInvitesStrings.ImportDataManually}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
