import { FC } from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { PurpleMain, useBottomToolbarStyles } from './BottomToolbar.style';
import { DownloadIconDv5 } from './DownloadIconDv5';
import { LinkIconDv5 } from './LinkIconDv5';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

interface IBottomTollbarProps {
  handleOpenDownloadVideoDialog: () => void;
  storyPageUrl: string;
  handleDeleteModalOpen: () => void;
  isTopGolf: boolean;
}

export const BottomToolbar: FC<IBottomTollbarProps> = ({
  handleOpenDownloadVideoDialog,
  storyPageUrl,
  handleDeleteModalOpen,
  isTopGolf,
}) => {
  const classes = useBottomToolbarStyles();
  const { trackEvent } = useTrackEvent();

  const theme = useTheme();
  return (
    <Box className={classes.ButtonsContainer}>
      <Box display="flex">
        <Button
          color="primary"
          onClick={() => {
            handleOpenDownloadVideoDialog();
            trackEvent(SocialVerseEventNames.downloadVideo, { value: storyPageUrl });
          }}
          startIcon={<DownloadIconDv5 color={theme.palette.common.white} />}
          className={[classes.Buttons, classes.ButtonsFilled].join(' ')}
        >
          {VideoDetailsModalStringsDv5.Download}
        </Button>
        <Box mr={2} />
        <Button
          variant="contained"
          className={[classes.Buttons].join(' ')}
          startIcon={<LinkIconDv5 color={PurpleMain} />}
          onClick={() => {
            trackEvent(SocialVerseEventNames.goToVideoLink, { value: storyPageUrl });
          }}
        >
          <a
            href={storyPageUrl}
            target="_blank"
            rel="noreferrer"
            className={classes.ButtonVideoLink}
          >
            {VideoDetailsModalStringsDv5.VideoLink}
          </a>
        </Button>
      </Box>
      {!isTopGolf ? (
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            handleDeleteModalOpen();
            trackEvent(SocialVerseEventNames.deleteUserVideo);
          }}
          className={[classes.DeleteButton].join(' ')}
          style={{ marginRight: '60px' }}
        >
          {VideoDetailsModalStringsDv5.DeleteVideo}
        </Button>
      ) : (
        <div></div>
      )}
    </Box>
  );
};
