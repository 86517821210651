import { Box } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  setWelcomeVideoToggle,
  setWelcomeVideoUrl,
} from '../../../store/slices/createCampaignSlice';
import { defaultVideos } from '../WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import AddVideoSection from './components/AddVideoSection';
import DisplayWelcomeVideoToggle from './components/DisplayWelcomeVideoToggle';
import { useStyles } from './WelcomeVideoStyles.styles';

interface WelcomeVideoStylesProps {}

const WelcomeVideoStyles: FC<WelcomeVideoStylesProps> = () => {
  const styles = useStyles();
  const { showWelcomeVideo } = useTypedSelector((state) => state.createCampaign);
  const dispatch = useAppDispatch();

  const handleChange = () => {
    const url = defaultVideos.default;
    dispatch(setWelcomeVideoToggle(!showWelcomeVideo));
    //if enable, need set default video
    dispatch(setWelcomeVideoUrl(showWelcomeVideo ? undefined : url));
  };

  return (
    <Box className={styles.wrapper}>
      <DisplayWelcomeVideoToggle onChange={handleChange} showWelcomeVideo={showWelcomeVideo} />
      {showWelcomeVideo && (
        <Box style={{ marginLeft: '52px', marginTop: '20px' }}>
          <AddVideoSection />
        </Box>
      )}
    </Box>
  );
};

export default WelcomeVideoStyles;
