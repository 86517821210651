import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { alpha } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: '200px',
    minWidth: '300px',
  },
  sphereWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
  emptySpheres: {
    width: '132px',
    height: '132px',
    borderRadius: '50%',
    border: '1px dashed rgba(82, 66, 234, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '40px',
    color: '#5242EA',
    fontWeight: '400',
    cursor: 'pointer',
  },
  spherePreview: {
    width: 132,
    height: 132,
    borderRadius: '100%',
    position: 'relative',
    background: '#ccc',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop: '35px',
    boxShadow: '0px 4px 10px 0px #00000080',
  },
  spheresPlayIcon: {
    position: 'absolute',
    top: '10px',
    left: '40px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 52,
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: alpha('#fff', 0.5),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
    boxShadow: '0px 0px 4px 4px rgb(0 0 0 / 10%)',
  },
  spheresPictureIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -45%)',
  },
}));
