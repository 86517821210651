import { FC, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { SearchFilterApiModel } from '../../api/models/searchFilter';
import { DeleteBasketIcon } from '../../common/assets/DeleteBasketIcon';
import { SettingsIcon } from '../../common/assets/SettingsIcon';
import { SavedFiltersHeaderStrings, SavedFiltersPageStrings } from '../../common/localization/en';
import { useFiltersStyles } from './Filters.style';
import { SortableIcon } from '../../common/components/table/CommonTable.style';
import { OrderDirection } from '../../common/constants/constants';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { setActiveFilter, setTarget } from '../../store/slices/searchFiltersSlice';
import { filtersResultRoutes } from '../../common/components/CreateUserFilterModal/CreateUserFilterModal.helper';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { VideosEventNames } from '../../common/constants/events/videosEvents';

type IFiltersTableProps = {
  items: SearchFilterApiModel[];
  handleOpen: (filter: SearchFilterApiModel) => void;
  openPreviewModal: (filter: SearchFilterApiModel) => void;
  isSocailVerse: boolean;
};

export const FiltersTable: FC<IFiltersTableProps> = ({
  items,
  handleOpen,
  openPreviewModal,
  isSocailVerse,
}) => {
  const styles = useFiltersStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const [sortOrder, setsortOrder] = useState<Order>(OrderDirection.DESC);
  const [sortIcon, setsortIcon] = useState('fas fa-sort');

  const onSortHeaderClick = () => {
    let orderVal = OrderDirection.DESC;

    if (sortIcon === 'fas fa-sort') {
      setsortOrder(OrderDirection.DESC);
      setsortIcon('fas fa-sort-down');
    } else {
      if (sortIcon === 'fas fa-sort-down') {
        orderVal = OrderDirection.ASC;
        setsortOrder(orderVal);
        setsortIcon('fas fa-sort-up');
      } else {
        orderVal = OrderDirection.DESC;
        setsortOrder(orderVal);
        setsortIcon('fas fa-sort');
      }
    }
    trackEvent(VideosEventNames.filterOrderChangeButtonClicked, {
      value: orderVal,
    });
  };

  const handleApplyFilter = (selectedFilter: SearchFilterApiModel) => {
    trackEvent(VideosEventNames.filterApplyButtonClicked, {
      filterId: selectedFilter.id,
    });

    dispatch(setActiveFilter(selectedFilter));
    dispatch(setTarget(selectedFilter.target));
    dispatch(setActiveFilter(selectedFilter));
    if (isSocailVerse) dispatch(setActiveFilter(selectedFilter));
    isSocailVerse ? history.goBack() : history.push(filtersResultRoutes[selectedFilter.target]);
  };

  return (
    <TableContainer
      style={{
        maxHeight: 'calc(100vh - 80px - 105px)',
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              className={styles.HeaderCell}
              sx={{ minWidth: '300px', paddingLeft: '25px' }}
            >
              {SavedFiltersHeaderStrings.FilterName}
            </TableCell>
            <TableCell className={styles.HeaderCell}>
              <Box display="flex" alignItems="center">
                {SavedFiltersHeaderStrings.DateCreated}
                <Box
                  onClick={() => onSortHeaderClick()}
                  display="flex"
                  alignItems="center"
                  sx={{ paddingLeft: '8px', color: '#8B89A0', cursor: 'pointer' }}
                >
                  <SortableIcon className={sortIcon} /> &nbsp;
                </Box>
              </Box>
            </TableCell>
            <TableCell className={styles.HeaderCell} sx={{ textAlign: 'center' }}>
              {SavedFiltersHeaderStrings.Actions}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(items, getComparator(sortOrder, 'createdAt')).map((item) => {
            return (
              <TableRow key={item.id}>
                <TableCell className={styles.TableCell} sx={{ paddingLeft: '25px' }}>
                  {item.name}
                </TableCell>
                {item.createdAt ? (
                  <TableCell className={styles.TableCell}>
                    <Box display="flex" alignItems="center">
                      {format(new Date(item.createdAt), 'PP')}
                    </Box>
                  </TableCell>
                ) : null}
                <TableCell>
                  <Box className={styles.ActionCell}>
                    <Button
                      variant="contained"
                      className={styles.ApplyBtn}
                      onClick={() => handleApplyFilter(item)}
                    >
                      {SavedFiltersPageStrings.ApplyFilter}
                    </Button>
                    <Button
                      variant="outlined"
                      className={styles.ApplyBtn}
                      onClick={() => {
                        openPreviewModal(item);
                        trackEvent(VideosEventNames.filterSettingsButtonClicked, {
                          filterId: item.id,
                        });
                      }}
                    >
                      <SettingsIcon /> {SavedFiltersPageStrings.FilterSettings}
                    </Button>
                    <IconButton
                      className={styles.DeleteBtn}
                      onClick={() => {
                        handleOpen(item);

                        trackEvent(VideosEventNames.filterDeleteButtonClicked, {
                          filterId: item.id,
                        });
                      }}
                    >
                      <DeleteBasketIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(
  order: Order,
  orderBy: keyof SearchFilterApiModel,
): (a: SearchFilterApiModel, b: SearchFilterApiModel) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type Order = OrderDirection.ASC | OrderDirection.DESC;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
