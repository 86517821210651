import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useState } from 'react';
import { useClientType } from '../../../../services/hooks/useClientType';
import { SwapIcon } from '../../../assets/newDesign/SwapIcon';
import { DataSyncStrings, SmsInviteStrings } from '../../../localization/en';
import { TextFieldComponent } from '../../TextField/TextField';
import { useStyles } from './MatchProperties.helper';
import { CheckBox } from '../../CheckBox/CheckBox';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../../constants/events/common';
import { IconBack } from '../../../assets/IconBack';
import { RoundedButton } from '../../Button/RoundedButton';
import { SMSInvitationTypeSelector } from '../../SMSInvitationTypeSelector/SMSInvitationTypeSelector';

enum matchProperties {
  firstName = 'First Name',
  lastName = 'Last Name',
  cell = 'Cell Phone',
  email = 'Email',
  transactionDate = 'Transaction Date',
  optIn = 'Opt In',
  optOut = 'Opt Out',
}

export const matchPropertiesLiteral: { [key: string]: string } = {
  firstName: matchProperties.firstName,
  lastName: matchProperties.lastName,
  cell: matchProperties.cell,
  email: matchProperties.email,
  transactionDate: matchProperties.transactionDate,
  optIn: matchProperties.optIn,
  optOut: matchProperties.optOut,
};

interface MatchPropertiesProps {
  formik: any;
  uploadedCSV: string[];
  getFields: string[][];
  onImportClick: () => void;
  smsInvitesLayout?: boolean;
  quickStartLayout?: boolean;
  newCampaignLayout?: boolean;
  moveToPrevStep: () => void;
  newCampaignLayoutMenuImport?: boolean;
  isLoading: boolean;
}

export const MatchProperties: FC<MatchPropertiesProps> = ({
  formik,
  uploadedCSV,
  getFields,
  onImportClick,
  smsInvitesLayout,
  quickStartLayout,
  newCampaignLayout,
  moveToPrevStep,
  newCampaignLayoutMenuImport: actionMenuImport,
  isLoading,
}) => {
  const styles = useStyles({
    smsInvitesLayout,
    quickStartLayout: quickStartLayout,
  });
  const theme = useTheme();
  const { isHealthCare } = useClientType();
  const [isOptedIn, setIsOptedIn] = useState(false);
  const { trackEvent } = useTrackEvent();
  const getDisabled = (fieldName: string) => {
    const csvEntries = Object.entries(formik.values);

    return !!csvEntries.some((entry) => entry[1] === fieldName);
  };

  const isImportEventsBtnDisabled = () => {
    const allPropertiesAreSelected =
      (formik.values.firstName || formik.values.lastName) && formik.values.cell;

    return !allPropertiesAreSelected || (quickStartLayout && !isOptedIn);
  };

  const formikKeys = Object.keys(formik.values);

  const showLabels = !smsInvitesLayout && !quickStartLayout;
  const showOptionalFields = !smsInvitesLayout && !quickStartLayout;

  const renderOptionalFields = () => {
    return getFields[1].map((field, i) => (
      <Grid item>
        <Typography className={styles.label}>Optional</Typography>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid xs={5}>
            <TextFieldComponent
              className={styles.input}
              placeholder={`Additional Property ${i}`}
              name={field}
              label=""
              value={formik.values[field]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              setFieldTouched={formik.setFieldTouched}
              errorText={formik.errors[field]}
              fullWidth
              helperText
              disabled
            />
          </Grid>
          <SwapIcon />
          <Grid xs={5}>
            <FormControl
              className={styles.formControl}
              sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
            >
              <Select
                className={styles.input}
                value={formik.values[field]}
                onChange={formik.handleChange}
                displayEmpty
                name={field}
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                {uploadedCSV.map((header, i) => (
                  <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                    {header}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    ));
  };

  const renderImportButton = () => {
    return (
      <RoundedButton
        disabled={isImportEventsBtnDisabled()}
        loading={isLoading}
        variant="contained"
        color="primary"
        size="large"
        sx={{
          borderRadius: '25px',
          fontWeight: '400',
          '&:disabled': {
            background: 'rgba(82, 66, 234, 0.3)',
          },
          width: newCampaignLayout ? '200px' : actionMenuImport ? '100%' : 'auto',
        }}
        onClick={onImportClick}
        title={
          smsInvitesLayout || quickStartLayout
            ? SmsInviteStrings.ImportExcelMatchPropertiesButton
            : DataSyncStrings.ImportBtn
        }
        id="import-data-button"
      />
    );
  };

  return (
    <Box className={styles.root}>
      <Box className={styles.matchSection}>
        <Grid container justifyContent={'space-between'} mb={quickStartLayout ? '0' : '30px'}>
          <Typography className={styles.title}>{DataSyncStrings.UploadCustomerData}</Typography>
          <Typography className={styles.matchProperties}>
            {DataSyncStrings.MatchProperties}
          </Typography>
          {!smsInvitesLayout && !quickStartLayout ? renderImportButton() : undefined}
        </Grid>
        <Box>
          {!quickStartLayout && (
            <Box className={styles.inputSection}>
              {smsInvitesLayout ? (
                <Typography color={theme.palette.common.gray}>
                  {isHealthCare
                    ? SmsInviteStrings.ImportExcelMatchPropertiesDescription_TruBlu
                    : SmsInviteStrings.ImportExcelMatchPropertiesDescription}
                </Typography>
              ) : (
                <>
                  <Typography className={`${styles.matchTitle} ${styles.mt40}`}>
                    {DataSyncStrings.MatchTitle}
                  </Typography>
                  <Typography className={styles.matchSubTitle}>
                    {DataSyncStrings.MatchSubTitle}
                  </Typography>
                </>
              )}
            </Box>
          )}
          <Grid
            className={styles.matchForm}
            style={{
              maxWidth: newCampaignLayout ? '480px' : 'auto',
            }}
          >
            <Grid xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Grid xs={5}>
                  <Typography className={styles.colTitle}>
                    {DataSyncStrings.DataProperties}
                  </Typography>
                </Grid>
                <Grid xs={5}>
                  <Typography className={styles.colTitle} mx="8px">
                    {DataSyncStrings.ImportedProperties}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item marginBottom={1}>
                {showLabels && <Typography className={styles.label}>*Required</Typography>}
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid xs={5}>
                    <TextFieldComponent
                      className={styles.input}
                      placeholder="First Name"
                      name="firstName"
                      label=""
                      value="First Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      setFieldTouched={formik.setFieldTouched}
                      fullWidth
                      helperText
                      disabled
                    />
                  </Grid>
                  <SwapIcon />
                  <Grid xs={5}>
                    <FormControl
                      className={styles.formControl}
                      sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
                    >
                      <Select
                        className={styles.input}
                        value={formik.values.firstName ? formik.values.firstName : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          trackEvent(
                            CommonEventNames.data_sync_imported_property_first_name_changed,
                            { value: e.target.value },
                          );
                        }}
                        displayEmpty
                        name="firstName"
                        fullWidth
                        error={!!formik.errors.firstName}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>---</em>
                        </MenuItem>
                        {uploadedCSV.map((header, i) => (
                          <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.firstName && (
                        <FormHelperText className={styles.error}>
                          <ReportProblemRoundedIcon
                            color="error"
                            style={{ height: '16px', width: '16px', marginRight: '5px' }}
                          />
                          {formik.errors.firstName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item marginBottom={1}>
                {showLabels && <Typography className={styles.label}>*Required</Typography>}
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid xs={5}>
                    <TextFieldComponent
                      className={styles.input}
                      placeholder="Last Name"
                      name="lastName"
                      label=""
                      value="Last Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      setFieldTouched={formik.setFieldTouched}
                      fullWidth
                      helperText
                      disabled
                    />
                  </Grid>
                  <SwapIcon />
                  <Grid xs={5}>
                    <FormControl
                      className={styles.formControl}
                      sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
                    >
                      <Select
                        className={styles.input}
                        value={formik.values.lastName ? formik.values.lastName : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          trackEvent(
                            CommonEventNames.data_sync_imported_property_last_name_changed,
                            { value: e.target.value },
                          );
                        }}
                        displayEmpty
                        name="lastName"
                        fullWidth
                        error={!!formik.errors.lastName}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>---</em>
                        </MenuItem>
                        {uploadedCSV.map((header, i) => (
                          <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.lastName && (
                        <FormHelperText className={styles.error}>
                          <ReportProblemRoundedIcon
                            color="error"
                            style={{ height: '16px', width: '16px', marginRight: '5px' }}
                          />
                          {formik.errors.lastName}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item marginBottom={1}>
                {showLabels && <Typography className={styles.label}>*Required</Typography>}
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid xs={5}>
                    <TextFieldComponent
                      className={styles.input}
                      placeholder="Cell Phone"
                      name="cell"
                      label=""
                      value="Cell Phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      setFieldTouched={formik.setFieldTouched}
                      fullWidth
                      helperText
                      disabled
                    />
                  </Grid>
                  <SwapIcon />
                  <Grid xs={5}>
                    <FormControl
                      className={styles.formControl}
                      sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
                    >
                      <Select
                        className={styles.input}
                        value={formik.values.cell ? formik.values.cell : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          trackEvent(
                            CommonEventNames.data_sync_imported_property_cell_phone_changed,
                            { value: e.target.value },
                          );
                        }}
                        displayEmpty
                        name="cell"
                        error={!!formik.errors.cell}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>---</em>
                        </MenuItem>
                        {uploadedCSV.map((header, i) => (
                          <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.cell && (
                        <FormHelperText className={styles.error}>
                          <ReportProblemRoundedIcon
                            color="error"
                            style={{ height: '16px', width: '16px', marginRight: '5px' }}
                          />
                          {formik.errors.cell}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item marginBottom={1}>
                {showLabels && <Typography className={styles.label}>*Required</Typography>}
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid xs={5}>
                    <TextFieldComponent
                      className={styles.input}
                      placeholder="Email"
                      name="email"
                      label=""
                      value="Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      setFieldTouched={formik.setFieldTouched}
                      fullWidth
                      helperText
                      disabled
                    />
                  </Grid>
                  <SwapIcon />
                  <Grid xs={5}>
                    <FormControl
                      className={styles.formControl}
                      sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
                    >
                      <Select
                        className={styles.input}
                        value={formik.values.email ? formik.values.email : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          trackEvent(CommonEventNames.data_sync_imported_property_email_changed, {
                            value: e.target.value,
                          });
                        }}
                        displayEmpty
                        name="email"
                        error={!!formik.errors.email}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>---</em>
                        </MenuItem>
                        {uploadedCSV.map((header, i) => (
                          <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.email && (
                        <FormHelperText className={styles.error}>
                          <ReportProblemRoundedIcon
                            color="error"
                            style={{ height: '16px', width: '16px', marginRight: '5px' }}
                          />
                          {formik.errors.email}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item marginBottom={1}>
                {showLabels && <Typography className={styles.label}>*Required</Typography>}
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid xs={5}>
                    <TextFieldComponent
                      className={styles.input}
                      placeholder="Transaction Date"
                      name=""
                      label=""
                      value="Transaction Date"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      setFieldTouched={formik.setFieldTouched}
                      fullWidth
                      helperText
                      disabled
                    />
                  </Grid>
                  <SwapIcon />
                  <Grid xs={5}>
                    <FormControl
                      className={styles.formControl}
                      sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
                    >
                      <Select
                        className={styles.input}
                        value={formik.values.transactionDate ? formik.values.transactionDate : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          trackEvent(
                            CommonEventNames.data_sync_imported_property_transaction_date_changed,
                            { value: e.target.value },
                          );
                        }}
                        displayEmpty
                        name="transactionDate"
                        error={!!formik.errors.transactionDate}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>---</em>
                        </MenuItem>
                        {uploadedCSV.map((header, i) => (
                          <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.transactionDate && (
                        <FormHelperText className={styles.error}>
                          <ReportProblemRoundedIcon
                            color="error"
                            style={{ height: '16px', width: '16px', marginRight: '5px' }}
                          />
                          {formik.errors.transactionDate}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {showLabels && <Typography className={styles.label}>Optional</Typography>}
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid xs={5}>
                    <TextFieldComponent
                      className={styles.input}
                      placeholder="Opt in / out status"
                      name="optInOut"
                      label=""
                      value={'Opt in / out status'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      setFieldTouched={formik.setFieldTouched}
                      errorText={formik.errors.optInOut}
                      fullWidth
                      helperText
                      disabled
                    />
                  </Grid>
                  <SwapIcon />
                  <Grid xs={5}>
                    <FormControl
                      className={styles.formControl}
                      sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
                    >
                      <Select
                        className={styles.input}
                        value={formik.values.optInOut ? formik.values.optInOut : ''}
                        onChange={(e) => {
                          formik.handleChange(e);
                          trackEvent(
                            CommonEventNames.data_sync_imported_property_opt_in_status_changed,
                            { value: e.target.value },
                          );
                        }}
                        displayEmpty
                        name="optInOut"
                        error={!!formik.errors.optInOut}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>---</em>
                        </MenuItem>
                        {uploadedCSV.map((header, i) => (
                          <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {!smsInvitesLayout && !quickStartLayout && (
                <Grid item>
                  {showLabels && <Typography className={styles.label}>Optional</Typography>}
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid xs={5}>
                      <TextFieldComponent
                        className={styles.input}
                        placeholder="Id"
                        name="refId"
                        label=""
                        value={'Id'}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        setFieldTouched={formik.setFieldTouched}
                        errorText={formik.errors.refId}
                        fullWidth
                        helperText
                        disabled
                      />
                    </Grid>
                    <SwapIcon />
                    <Grid xs={5}>
                      <FormControl
                        className={styles.formControl}
                        sx={{ m: 1, minWidth: 120, width: '100%', position: 'relative' }}
                      >
                        <Select
                          className={styles.input}
                          value={formik.values.refId ? formik.values.refId : ''}
                          onChange={(e) => {
                            formik.handleChange(e);
                            trackEvent(CommonEventNames.data_sync_imported_property_id_changed, {
                              value: e.target.value,
                            });
                          }}
                          displayEmpty
                          name="refId"
                        >
                          <MenuItem value="">
                            <em>---</em>
                          </MenuItem>
                          {uploadedCSV.map((header, i) => (
                            <MenuItem disabled={getDisabled(header)} key={i} value={header}>
                              {header}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* OPTIONAL */}
              {showOptionalFields &&
                formikKeys.length > getFields[1].length &&
                renderOptionalFields()}
            </Grid>
          </Grid>

          <Box pl="16px">
            <SMSInvitationTypeSelector
              value={formik.values.smsInvitationType}
              onChange={(value) => formik.setFieldValue('smsInvitationType', value)}
            />
          </Box>

          {newCampaignLayout && (
            <>
              <AgreementCheckBox
                isOptedIn={isOptedIn}
                setIsOptedIn={setIsOptedIn}
                margin="0 0 0 12px"
              />
              <Box display="flex" justifyContent="flex-end" mt="25px" pr="20px">
                <Button
                  className={styles.btnBack}
                  startIcon={<IconBack />}
                  onClick={() => {
                    moveToPrevStep();
                    trackEvent(CommonEventNames.data_sync_prev_step_button_click);
                  }}
                  id="go-step-back-button"
                />

                {renderImportButton()}
              </Box>
            </>
          )}

          {smsInvitesLayout && !quickStartLayout ? (
            <Box display="flex" justifyContent="flex-end" mt="10px">
              {renderImportButton()}
            </Box>
          ) : undefined}
        </Box>
      </Box>
      {quickStartLayout && !newCampaignLayout && (
        <Box display={'flex'} flexDirection={actionMenuImport ? 'column-reverse' : 'column'}>
          <Box display="flex" justifyContent="flex-end" mt="28px">
            {renderImportButton()}
          </Box>
          <AgreementCheckBox isOptedIn={isOptedIn} setIsOptedIn={setIsOptedIn} />
        </Box>
      )}
    </Box>
  );
};

interface AgreementProps {
  isOptedIn: boolean;
  setIsOptedIn: (isOptedIn: boolean) => void;
  margin?: string;
}

const AgreementCheckBox: FC<AgreementProps> = ({ isOptedIn, setIsOptedIn, margin }) => {
  const styles = useStyles({});
  const { trackEvent } = useTrackEvent();
  return (
    <Box className={styles.agreementWrapper} style={{ margin: margin || '28px auto 0' }}>
      <CheckBox
        isChecked={isOptedIn}
        onChange={(_, isChecked) => {
          setIsOptedIn(isChecked);
          trackEvent(CommonEventNames.data_sync_agreement_checkbox_click, {
            value: isChecked ? 'checked' : 'unchecked',
          });
        }}
        outlineColor={'gray'}
        id="agreement-checkbox"
      />
      <Typography className={styles.agreementText}>
        {DataSyncStrings.ImportModalAgreement}
      </Typography>
    </Box>
  );
};
