import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useCircularTimer } from './useCircularTimer';

export const AnimatedDiagramFamilyFriends = () => {
  const progress = useCircularTimer(71);
  const progress1 = useCircularTimer(8);
  const progress2 = useCircularTimer(8);
  return (
    <Box
      sx={{
        position: 'absolute',
        width: '336px',
        height: '336px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <CircularProgress
        variant="determinate"
        value={progress}
        size={336}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'rotate(0.75turn) scale(1, -1) !important',
          circle: {
            stroke: '#5242EA ',
          },
          svg: {
            strokeLinecap: 'round',
          },
        }}
        thickness={4}
      />
      <CircularProgress
        variant="determinate"
        value={progress1}
        size={336}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'rotate(-1deg) scale(1, -1) !important',
          circle: {
            stroke: '#BAB3FE',
          },
          svg: {
            strokeLinecap: 'round',
          },
        }}
        thickness={4}
      />
      <CircularProgress
        variant="determinate"
        value={progress2}
        size={336}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'rotate(-45deg) scale(1, -1) !important',
          circle: {
            stroke: '#372F86',
          },
          svg: {
            strokeLinecap: 'round',
          },
        }}
        thickness={4}
      />
    </Box>
  );
};
