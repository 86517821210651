import { FC } from 'react';

import clsx from 'clsx';

import { useStoryStatusStyles } from './StoryStatus.style';

export type StatusValues = 'published' | 'unpublished' | 'moderated' | 'pending_moderation';

interface IStoryStatusprops {
  status: StatusValues | string;
}

export const VideoStatus: FC<IStoryStatusprops> = ({ status }) => {
  const classes = useStoryStatusStyles();
  return (
    <div
      className={clsx(classes.statusContainer, [
        classes[status.toLocaleLowerCase() as StatusValues],
      ])}
    >
      <span className={classes.statusText}>{status.toLocaleLowerCase().split('_').join(' ')}</span>
    </div>
  );
};
