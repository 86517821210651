import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useNoUsersLayoutStyles = makeStyles((theme: Theme) => ({
  Container: {
    borderRadius: '0px 20px 20px 20px',
    backgroundColor: theme.palette.common.white,
  },
  Title: {
    margin: '0 auto',
    marginBottom: '24px',
    textAlign: 'center',
    fontSize: '38px',
    fontWeight: '600',
    lineHeight: '50px',
    letterSpacing: '-0.02em',
    padding: '5px 6px',
    width: 'fit-content',
    color: '#5242EA',
    backgroundColor: '#EBE9FF',
    borderRadius: '8px',
  },
  Description: {
    margin: '0 auto',
    marginBottom: '40px',
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '30px',
    color: '#475467',
    textAlign: 'center',
    maxWidth: '640px',
  },
  Button: {
    padding: '12px 20px',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    color: theme.palette.common.black,
    borderColor: '#D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  },
}));
