import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: '0 10px 10px 10px',
    backgroundColor: theme.palette.common.white,
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 20px 11px 28px',
  },
  table: {
    '& #common-table-wrapper': {
      maxHeight: '630px',
      height: '630px',
    },

    '& table': {
      borderSpacing: '0 !important',
    },
    '& th': {
      '& > div': {
        padding: '5px !important',
        fontSize: '12px',
      },

      '&:nth-child(1) div': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px !important',
        minWidth: '200px',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '20px',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '20px',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '8px',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },
    },
    '& tr': {
      height: '48px !important',
      fontSize: '2px !important',
      borderSpacing: 'none !important',
    },
    '& td': {
      fontSize: '13px !important',
      '& span': { fontSize: '13px !important' },
      '& p': { fontSize: '13px !important' },
      '& > div': {
        padding: '5px !important',
        fontSize: '13px !important',
      },
      '&:nth-child(1)': {
        paddingLeft: '10px !important',
        minWidth: '220px',
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
      },
      '&:nth-child(2) div': {
        margin: '0 auto',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '20px',
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '20px',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '20px',
      },
      '&:nth-child(6)': {
        textAlign: 'start',
        paddingLeft: '0px',
      },
    },
  },
}));
