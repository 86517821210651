import { FC } from 'react';
import { Box } from '@mui/material';
import { BoxProps } from '@material-ui/core';
import { CSSProperties } from 'styled-components';

const toPx = (pixels: number) => `${pixels}px`;

const IPHONE_HEIGHT_PX = 605;
const IPHONE_WIDTH_PX = 300;
const HORIZONTAL_IPHONE_BORDER_WIDTH_PX = 17;
const VERTICAL_IPHONE_BORDER_WIDTH_PX = 16;

const getIphoneParams = (height?: number, width?: number) => {
  const iphoneHeight = height || IPHONE_HEIGHT_PX;
  const iphoneWidth = width || IPHONE_WIDTH_PX;
  const iphoneScreenHeight = iphoneHeight - 2 * VERTICAL_IPHONE_BORDER_WIDTH_PX;
  const iphoneScreenWidth = iphoneWidth - 2 * HORIZONTAL_IPHONE_BORDER_WIDTH_PX;

  return {
    iphoneHeight,
    iphoneScreenHeight,
    iphoneWidth,
    iphoneScreenWidth,
  };
};

interface Props {
  height?: number;
  width?: number;
}

const IPhoneFrame: React.FC<Props> = ({ height, width, children }) => {
  const { iphoneHeight, iphoneScreenHeight, iphoneWidth, iphoneScreenWidth } = getIphoneParams(
    height,
    width,
  );

  return (
    <Box
      position="relative"
      height={toPx(iphoneHeight)}
      width={toPx(iphoneWidth)}
      style={{ pointerEvents: 'none', userSelect: 'none' }}
    >
      <img
        src="/iphone-frame.png"
        alt="iphone-preview"
        height={toPx(iphoneHeight)}
        width={toPx(iphoneWidth)}
      />

      <Box
        position="absolute"
        top={toPx(VERTICAL_IPHONE_BORDER_WIDTH_PX)}
        left={toPx(HORIZONTAL_IPHONE_BORDER_WIDTH_PX)}
        height={toPx(iphoneScreenHeight)}
        width={toPx(iphoneScreenWidth)}
        overflow="hidden"
      >
        {children}
      </Box>
    </Box>
  );
};

interface WithBackgroundProps {
  src?: string;
  topOffset?: number;
  bottomOffset?: number;
  height?: number;
  width?: number;
  style?: CSSProperties;
}

const BROWSER_TOP_OFFSET_PX = 48;
const BROWSER_BOTTOM_OFFSET_PX = 55;

export const WithBackground: React.FC<WithBackgroundProps> = ({
  src = '/iphone-webapp-background.png',
  topOffset = BROWSER_TOP_OFFSET_PX,
  bottomOffset = BROWSER_BOTTOM_OFFSET_PX,
  children,
  height,
  width,
  style,
}) => {
  if (!src) {
    return <>{children}</>;
  }

  const { iphoneHeight, iphoneScreenHeight, iphoneScreenWidth } = getIphoneParams(height, width);
  const CONTENT_WIDTH_PX = iphoneScreenWidth;
  const CONTENT_HEIGHT_PX = iphoneScreenHeight - topOffset - bottomOffset;

  return (
    <Box display="flex" justifyContent="center" height={height ? '100%' : 'auto'}>
      <img
        src={src}
        alt="iphone-preview-background"
        height={toPx(iphoneHeight)}
        width={toPx(iphoneScreenWidth)}
      />

      <Box
        position="absolute"
        overflow="hidden"
        height={toPx(CONTENT_HEIGHT_PX)}
        width={toPx(CONTENT_WIDTH_PX)}
        top={topOffset}
        style={style}
      >
        {children}
      </Box>
    </Box>
  );
};

export interface IPhonePreviewProps extends BoxProps {
  hideBackground?: boolean;
  background?: WithBackgroundProps;
  height?: number;
  width?: number;
}

export const IPhonePreview: FC<IPhonePreviewProps> = ({
  hideBackground,
  background,
  children,
  height,
  width,
  style,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <IPhoneFrame height={height} width={width}>
        {hideBackground ? (
          <>{children}</>
        ) : (
          <WithBackground {...background} height={height} width={width} style={{ ...style }}>
            {children}
          </WithBackground>
        )}
      </IPhoneFrame>
    </Box>
  );
};
