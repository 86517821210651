import { Box, Grid } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { CommonTable } from '../../../common/components/table/CommonTable';
import TableFilterSelect, {
  TabItem,
} from '../../../common/components/TableFilterSelect/TableFilterSelect';
import { InvitedCustomersStrings } from '../../../common/localization/en';
import { createInvitedCustomersDataTableContent } from '../../../services/helpers/tableMappers';
import { useClientType } from '../../../services/hooks/useClientType';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getTransactionRecordsByCampaignId,
  InvitedUserStatus,
  setCampaignRecordsPage,
  setCampaignRecordsSorting,
  updateCampaignRecordsSearch,
  updateCampaignRecordsSize,
  updateDetailsSearch,
} from '../../../store/slices/dataSyncSlice';
import { useStyles } from './InvitedCustomers.helper';
import { CampaignCountData } from '../../../store/slices/campaignsSlice';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { isFirefox } from 'react-device-detect';

interface Props {
  countData: CampaignCountData;
  handleTrackEvent: IEventTracker;
}

export const InvitedCustomersTable: FC<Props> = ({ countData, handleTrackEvent }) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();
  const { isTrubluClient } = useClientType();
  const { id: campaignId } = useParams<{ id: string }>();
  const { isCountDataLoading } = useTypedSelector((state) => state.campaigns);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { error, isLoading, items, page, size, sort, totalItems, totalPages, search } =
    useTypedSelector((state) => state.dataSyncSlice.campaignRecords);

  const tabsList: TabItem[] = useMemo(
    () => [
      {
        tabName: InvitedCustomersStrings.ViewAllTab,
        usersCount: (activeTabIndex === 0 && isCountDataLoading
          ? 0
          : countData.invitations
        ).toString(),
        filter: undefined,
      },
      {
        tabName: InvitedCustomersStrings.Active,
        usersCount: (activeTabIndex === 1 && isCountDataLoading
          ? 0
          : countData.invitationsWithVideos
        ).toString(),
        filter: {
          status: InvitedUserStatus.Active,
          hasVideos: true,
        },
      },
      {
        tabName: InvitedCustomersStrings.Pending,
        usersCount: (activeTabIndex === 2 && isCountDataLoading
          ? 0
          : countData.activeInvitations - countData.invitationsWithVideos
        ).toString(),
        filter: {
          status: InvitedUserStatus.Active,
          hasVideos: false,
        },
      },
      {
        tabName: InvitedCustomersStrings.InvitesSent,
        usersCount: (activeTabIndex === 3 && isCountDataLoading
          ? 0
          : countData.pendingInvitations
        ).toString(),
        filter: {
          status: InvitedUserStatus.Pending,
        },
      },
    ],
    [activeTabIndex, countData, isCountDataLoading],
  );

  useEffect(() => {
    dispatch(
      getTransactionRecordsByCampaignId({
        campaignId,
        pageable: { page, size, sort },
        search,
        filter: {
          status: tabsList[activeTabIndex].filter?.status as InvitedUserStatus,
          hasVideos: tabsList[activeTabIndex].filter?.hasVideos,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, page, size, sort, search, dispatch, activeTabIndex, countData]);

  const content = createInvitedCustomersDataTableContent({
    items,
    isTrubluClient,
    handleTrackEvent,
    isFirefox,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);
    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(setCampaignRecordsPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setCampaignRecordsSorting(name));
    handleTrackEvent(
      RewardCampaignsEvents.RewardCampaignsInvitedCustomersTableSortClick.replace(
        'sort_by_column',
        `${tabsList[activeTabIndex].tabName}_tab_sort_by_${name}`,
      ).toLowerCase(),
    );
  };

  const changeSize = (size: number) => {
    dispatch(updateCampaignRecordsSize(size));
  };

  return (
    <Box className={styles.root}>
      <Grid container className={styles.top}>
        <TableFilterSelect
          tabsList={tabsList}
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          handleTrackEvent={handleTrackEvent}
          type="invited"
        />
        <Box>
          <SearchBar
            entity={'campaigns'}
            updateSearch={updateCampaignRecordsSearch}
            adaptiveWidth={true}
            disabled={isLoading}
            placeholderText={`Search ${isTrubluClient ? 'Patient' : 'Creator'}`}
            borderColor={'#F2F4F7'}
            clearSearch={activeTabIndex}
          />
        </Box>
      </Grid>
      <Grid className={styles.table}>
        <CommonTable
          selectedRowId={campaignId}
          content={content}
          page={convertApiPageToFrontEndPage(page)}
          sort={sort}
          totalItems={totalItems}
          totalPages={totalPages}
          isLoading={isLoading}
          noContent={error}
          tablePadding="0"
          goToPage={onGoToPage}
          onSortHeaderClick={onSort}
          size={size}
          onSizeChange={changeSize}
          withSearchBar={false}
          roundedNavButtons={false}
          disableTopNav={true}
          searchBarProps={{
            adaptiveWidth: true,
            entity: 'campaigns',
            updateSearch: updateDetailsSearch,
          }}
        />
      </Grid>
    </Box>
  );
};
