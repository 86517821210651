import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

import { SubnavigationItemValues } from './WebappSubnavigation.helper';
import { SubNavigationItem } from '../SubNavigationItem/SubNavigationItem';
import { WebsiteRoutes } from '../../constants/routes';
import { UserRoles } from '../../constants/constants';
import { useTypedSelector } from '../../../store';
import { ClientTypes } from '../../../api/models/common';
import { SettingsSubnavigationStrings } from '../../localization/en';
import { useThemeLayout } from '../../../hooks/useThemeLayout';

interface WebappSubnavigationProps {
  items: SubnavigationItemValues[];
  onRouteClick?: (route: string) => void;
}

export const WebappSubnavigation: FC<WebappSubnavigationProps> = ({ items, onRouteClick }) => {
  const location = useLocation();
  const { role } = useTypedSelector((state) => state.me);
  const {
    venue: { clientType },
  } = useTypedSelector((state) => state.venue);
  const { isThemeLayoutEnabled } = useThemeLayout();
  const { pricingPlan } = useTypedSelector((state) => state.BillingSlice);

  const socialVerseId = new URLSearchParams(location.search).get('id');
  const isTopgolfVenue = clientType === ClientTypes.TOPGOLF;

  const isBillingEnabled = useMemo(() => {
    return pricingPlan != null;
  }, [pricingPlan]);

  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);

  const FTEIsFinished =
    Object.keys(venueProperties?.['webapp.firstTimeOnboardingFinished.config'] || {})?.length >= 5;

  const itemsWithProps = items
    ?.filter((item) => isThemeLayoutEnabled || item.route !== WebsiteRoutes.SignUpTab)
    .map((item) => {
      if (item.route === WebsiteRoutes.TeamSettings && isTopgolfVenue) {
        return { ...item, disabled: true };
      }
      if (item.route === WebsiteRoutes.WebAppCards && isTopgolfVenue) {
        item.text = SettingsSubnavigationStrings.BrandingStyle;
        return { ...item };
      }
      if (item.route === WebsiteRoutes.IntegrationsAddToWebsite && !socialVerseId) {
        item.disabled = true;
        return { ...item };
      }
      return item;
    });

  if (location?.pathname === WebsiteRoutes.IntegrationsCreate) {
    return null;
  }

  return items?.length ? (
    <Grid
      flexWrap="nowrap"
      container
      paddingY={2}
      paddingX={
        ![WebsiteRoutes.SyncData as string, WebsiteRoutes.HistorySync as string].includes(
          location.pathname,
        )
          ? '40px'
          : '0px'
      }
    >
      {itemsWithProps
        .filter((item) =>
          item.route === WebsiteRoutes.SettingsManagers ? role === UserRoles.admin_role : true,
        )
        .filter((item) =>
          item.route === WebsiteRoutes.WebAppSignUpConfig && isTopgolfVenue ? false : true,
        )
        .map((navItem) => {
          const isDisabled =
            navItem.disabled ||
            (!isBillingEnabled && navItem.route === WebsiteRoutes.AccountsBilling);
          return (
            <SubNavigationItem
              onClick={onRouteClick}
              key={navItem.route}
              route={
                location.search === '?type=circles'
                  ? navItem.route + location.search
                  : navItem.route
              }
              text={navItem.text}
              disabled={isDisabled}
              icon={navItem.icon}
              blur={
                FTEIsFinished || isTopgolfVenue
                  ? false
                  : [WebsiteRoutes.WorkspaceProfile, WebsiteRoutes.WorkspaceMembers].some(
                      (el) => el === navItem.route,
                    )
              }
            />
          );
        })}
    </Grid>
  ) : null;
};
