import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultPagination } from '../../common/constants/constants';
import { getUsersBySearchFilter } from './heroesSlice';
import { getVideosBySearchFilter as getSvVideosBySearchFilter } from './videosSlice';
import { getVideosBySearchFilter } from './topGolfVideosSlice';

export interface Alert {
  title: string | null;
  message: string | null;
  status: string | null;
}

interface AlertInitialState {
  items: any[];
  isLoading: boolean;
  selectedRows: any[];
  page: number;
  size: number;
  previousPage: number;
  previousSize: number;
}

const initialState: AlertInitialState = {
  items: [],
  isLoading: false,
  selectedRows: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  previousPage: defaultPagination.page,
  previousSize: defaultPagination.size,
};

export const SelectedRowsSlice = createSlice({
  name: 'selectedRowsSlice',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.previousPage = state.page;
      state.page = action.payload;
    },
    resetSelectedRows(state) {
      state.selectedRows = [];
      state.items = [];
    },
    resetPage(state) {
      state.previousPage = 0;
      state.page = 0;
      state.previousSize = defaultPagination.size;
      state.size = defaultPagination.size;
    },
    updateSelectedPageSize(state, action: PayloadAction<number>) {
      state.previousPage = state.page;
      state.previousSize = state.size;

      state.size = action.payload;
      state.page = 0;
    },
    filterSelectedRows(
      state,
      action: PayloadAction<{ video: any /* StoryApiModel */; checked: boolean }>,
    ) {
      const { video, checked } = action.payload;

      if (checked) {
        state.selectedRows = state.selectedRows
          ? state.selectedRows.filter((item) => item.id !== video.id)
          : [];
      } else {
        state.selectedRows = state.selectedRows ? [...state.selectedRows, video] : [];
      }
    },

    toggleSelectAll(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        //selected all => unselect
        if (state.page === 0 && state.previousPage === 0) {
          state.selectedRows = [];
        }

        if (state.page > state.previousPage) {
          if (state.selectedRows) {
            state.selectedRows = state.selectedRows.filter(
              (_el, i) => i < state.selectedRows.length - state.size,
            );
          }
        } else if (state.page < state.previousPage) {
          const findSelectedOnOtherPages = state.selectedRows.filter(
            (selectedRow) => !state.items.some((item) => item.id === selectedRow.id),
          );

          state.selectedRows = findSelectedOnOtherPages;
        }
      } else {
        //selected not all => select all
        if (state.page === 0 && state.previousPage === 0) {
          //case for the first page
          state.selectedRows = state.items;
        }

        if (state.selectedRows) {
          if (state.page <= state.previousPage && state.previousSize < state.size) {
            state.selectedRows = state.items;
          }

          if (state.page > state.previousPage || state.page < state.previousPage) {
            const findSelectedOnOtherPages = state.selectedRows.filter(
              (selectedRow) => !state.items.some((item) => item.id === selectedRow.id),
            );

            state.selectedRows = [...findSelectedOnOtherPages, ...state.items];
          }
        }
      }
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getSvVideosBySearchFilter.fulfilled, (state, { payload, meta }) => {
      //do not add items from 1st page to the selectedRows if go from 2nd to 1st page
      const skipAddingToSelectedRows = state.selectedRows?.some((row) =>
        payload.items
          /* .slice(payload.page * payload.size, (payload.page + 1) * payload.size) */
          .some((item) => item.id === row.id),
      );

      if (
        (meta.arg.filter as any).length &&
        !skipAddingToSelectedRows &&
        state.previousPage !== undefined &&
        state.previousPage <= payload.page
      ) {
        state.selectedRows = state.selectedRows?.length
          ? [...state.selectedRows, ...payload.items]
          : [...payload.items];
      }

      state.items = payload.items;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getSvVideosBySearchFilter.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getVideosBySearchFilter.fulfilled, (state, { payload, meta }) => {
      //do not add items from 1st page to the selectedRows if go from 2nd to 1st page
      const skipAddingToSelectedRows = state.selectedRows?.some((row) =>
        payload.items
          /* .slice(payload.page * payload.size, (payload.page + 1) * payload.size) */
          .some((item) => item.id === row.id),
      );

      if (
        (meta.arg.filter as any).length &&
        !skipAddingToSelectedRows &&
        state.previousPage !== undefined &&
        state.previousPage <= payload.page
      ) {
        state.selectedRows = state.selectedRows?.length
          ? [...state.selectedRows, ...payload.items]
          : [...payload.items];
      }

      state.items = payload.items;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getVideosBySearchFilter.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getUsersBySearchFilter.fulfilled, (state, { payload, meta }) => {
      //do not add items from 1st page to the selectedRows if go from 2nd to 1st page
      const skipAddingToSelectedRows = state.selectedRows?.some((row) =>
        payload.items
          /* .slice(payload.page * payload.size, (payload.page + 1) * payload.size) */
          .some((item) => item.id === row.id),
      );

      if (
        (meta.arg.filter as any).length &&
        !skipAddingToSelectedRows &&
        state.previousPage !== undefined &&
        state.previousPage <= payload.page
      ) {
        state.selectedRows = state.selectedRows?.length
          ? [...state.selectedRows, ...payload.items]
          : [...payload.items];
      }
      state.items = payload.items;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getUsersBySearchFilter.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const {
  filterSelectedRows,
  resetSelectedRows,
  goToSelectedPage,
  toggleSelectAll,
  resetPage,
  updateSelectedPageSize,
} = SelectedRowsSlice.actions;

export default SelectedRowsSlice.reducer;
