import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import { DNDIcon } from '../../assets/newDesign/DNDIcon';
import { DataSyncStrings } from '../../localization/en';

export type DropzoneSupportedFileTypes = 'csv' | 'excel';

const acceptOptions: Record<DropzoneSupportedFileTypes, Accept> = {
  csv: {
    'text/csv': ['.csv'],
  },
  excel: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsm'],
    'application/x-msi': ['.xls'],
  },
};

export interface DropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  title?: string;
  description?: string;
  accept?: DropzoneSupportedFileTypes[];
  whiteBackground?: boolean;
  withBorder?: boolean;
  padding?: string;
  height?: string;
  borderRadius?: string;
  newCampaignLayoutMenuImport?: boolean;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  onDrop,
  title = DataSyncStrings.DNDInputTitle,
  description = DataSyncStrings.DNDInputSubTitle,
  accept,
  whiteBackground,
  withBorder = true,
  padding,
  height,
  borderRadius,
  newCampaignLayoutMenuImport,
}) => {
  const theme = useTheme();

  const acceptSettings = useMemo<Accept>(() => {
    return (accept || []).reduce((resultAccept: Accept, fileType) => {
      return { ...resultAccept, ...acceptOptions[fileType] };
    }, {});
  }, [accept]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptSettings,
  });

  const backgroundColor = whiteBackground
    ? theme.palette.common.white
    : theme.palette.common.pageBackground;

  const border =
    whiteBackground && withBorder
      ? '2px dashed #D6D9DE'
      : newCampaignLayoutMenuImport
      ? '1 px solid D6D9DE'
      : undefined;

  return (
    <div
      style={{ display: 'block' }}
      {...getRootProps({ className: 'dropzone' })}
      id="file-dropzone"
    >
      <input type="file" className="input-zone" {...getInputProps()} />
      <Grid
        container
        sx={{
          alignItems: 'center',
          marginTop: '20px',
          padding: padding || '35px 38px',
          borderRadius: borderRadius || '5px',
          background: isDragActive ? theme.palette.primary.light : backgroundColor,
          cursor: isDragActive ? 'url(/fiber-smart-record.svg) !important' : 'pointer',
          border: border,
          height: height || 'auto',
        }}
      >
        <Box mr="20px">
          <DNDIcon color={isDragActive ? '#80A4DC' : '#D9D9D9'} />
        </Box>
        <Box>
          <Typography fontWeight="600" fontSize="17px" lineHeight="26px">
            {title}
          </Typography>
          <Typography color="#085DFF" fontWeight="400" fontSize="12px" lineHeight="26px">
            {description}
          </Typography>
        </Box>
      </Grid>
    </div>
  );
};
