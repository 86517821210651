import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { Box, Button } from '@mui/material';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { VenueManagerApiModel } from './../../../api/models/venueManagers';
import {
  AdminOrgManagementStrings,
  AdminMarketManagementStrings,
  AdminVenueManagementStrings,
} from '../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  addEmptyManager,
  getVenueManagerList,
  resetError,
  setCurrentLoggedInManager,
} from '../../../store/slices/venueManagerSlice';
import { useStyles } from './ManagerSwitch.style';
import { useMembersStyles } from './Members.style';
import { sortItems } from './WorkspaceManagers.helper';
import { WorkspaceMembersList } from '../WorkspaceMembersList/WorkspaceMembersList';
import { SearchOnClient } from '../searchOnClient/searchOnClient';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../constants/routes';
import { SpinnerContainer } from '../table/CommonTable.style';
import { Spinner } from '../../assets/Spinner';
import { UserRoles } from '../../constants/constants';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../constants/events/accountEvents';

export const Members = () => {
  const [sortedItems, setSortedItems] = useState<Array<VenueManagerApiModel>>([]);
  const id = useTypedSelector((state) => state.venue.venue.id);
  const { id: userId, role } = useTypedSelector((state) => state.me);
  const classes = useStyles();
  const styles = useMembersStyles();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { sort, items, error, isLoading, loggedInManager } = useTypedSelector(
    (state) => state.VenueManagerSlice,
  );
  const history = useHistory();

  const canEdit = loggedInManager?.venueMappingRole === 'OWNER' || role === UserRoles.admin;

  useEffect(() => {
    if (items && items.length) {
      const foundManager = items.find((item) => item.id === userId);
      dispatch(setCurrentLoggedInManager(foundManager || null));
    }
  }, [items, dispatch, userId]);

  useEffect(() => {
    if (error) {
      addToast('Something went wrong', { appearance: 'error' });
      dispatch(resetError());
    }
  }, [dispatch, error, addToast]);

  useEffect(() => {
    if (id) {
      dispatch(getVenueManagerList({ venueId: id, sort }));
    }
  }, [dispatch, id, sort]);

  useEffect(() => {
    setSortedItems(sortItems(items));
  }, [items]);

  const handleAddOrgManager = () => {
    trackEvent(AccountEventNames.buttonAddMemberClicked);

    dispatch(
      addEmptyManager({
        id: '',
        email: '',
        role: '',
        name: '',
        photoUrl: '',
        refId: '',
        status: '',
        createdAt: '',
        updatedAt: '',
        venueMappingRole: '',
      }),
    );
    history.push(WebsiteRoutes.WorkspaceMembersInvite);
  };
  const showBottomNav = () => {
    // check if there is a manager with a null id
    return items.filter((item) => !item.id).length ? true : false;
  };

  const onSearch = (e: BaseSyntheticEvent) => {
    const val = e?.target?.value.trim();
    trackEvent(AccountEventNames.memberSearchTyped, {
      value: val,
    });

    if (!val) {
      onReset();
    }
    const filtered = sortItems(items).filter((item) => {
      const newVal = val.toLocaleLowerCase();
      const name = item.name?.toLocaleLowerCase();
      const email = item.email?.toLocaleLowerCase();
      return name?.indexOf(newVal) !== -1 || email?.indexOf(newVal) !== -1;
    });
    setSortedItems(filtered);
  };

  const onReset = () => {
    setSortedItems(sortItems(items));
  };

  return (
    <>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner color="var(--spinnerColor)" />
        </SpinnerContainer>
      ) : (
        <Box className={classes.box}>
          <h2 className={classes.subheading}>{AdminMarketManagementStrings.Title}</h2>
          <Box paddingTop={2.5} marginBottom={4} display="flex" justifyContent="space-between">
            <SearchOnClient onReset={onReset} onChange={onSearch} />
            <Button
              color="primary"
              onClick={handleAddOrgManager}
              startIcon={<AddRoundedIcon />}
              type="button"
              disabled={showBottomNav()}
              className={styles.AddManagerButton}
            >
              {AdminOrgManagementStrings.InviteAdmin}
            </Button>
          </Box>
          <WorkspaceMembersList
            headCellsNames={
              canEdit
                ? [
                    AdminVenueManagementStrings.Name,
                    AdminVenueManagementStrings.CellPhone,
                    AdminVenueManagementStrings.Role,
                    AdminVenueManagementStrings.UserAlerts,
                    AdminVenueManagementStrings.Action,
                  ]
                : [
                    AdminVenueManagementStrings.Name,
                    AdminVenueManagementStrings.CellPhone,
                    AdminVenueManagementStrings.Role,
                    AdminVenueManagementStrings.UserAlerts,
                  ]
            }
            items={sortedItems}
          />
        </Box>
      )}
    </>
  );
};
