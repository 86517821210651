import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useColorInputStyles = makeStyles<Theme>((theme: Theme) => ({
  colorSelectInput: {
    // position: 'absolute',
    // right: 0,
    '&>div>.ColorPicker-MuiButtonBase-root, &>div>.ColorPicker-MuiButtonBase-root>.ColorPicker-MuiButton-label>div, &>div>.ColorPicker-MuiButtonBase-root>.ColorPicker-MuiTouchRipple-root':
      {
        outline: 'none',
        borderRadius: '8px',
        width: '58px',
        height: '24px',
        cursor: 'pointer !important',
      },
  },
  colorInputWrapper: {
    width: '100%',
    display: 'flex',

    alignItems: 'flex-start',
    position: 'relative',
  },
  select: {
    border: 'none !important',
    '&>.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  inputText: {
    padding: '8px 20px',
    fontWeight: 400,
    fontSize: '12px',
    color: theme.palette.common.deepDark,
    border: '1px solid #E1E5EE',
    borderRadius: '8px',
    height: '25px',
    width: '180px',
  },
}));
