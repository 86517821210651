import { Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { VenueProperties } from '../../../api/models/venue';
import { VideosIconAnalytics } from '../../../common/assets/newDesign/VideosIconAnalytics';
import { QuickStartEventNames } from '../../../common/constants/events/quickstartEvents';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { PageWithHeaderLayout } from '../../../common/layout/PageWithHeaderLayout/PageWithHeaderLayout';
import { TwoSidePageLayout } from '../../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { OnboardingWelcomeStrings } from '../../../common/localization/en';
import { useFTE } from '../../../hooks/useFTE';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { useTypedSelector } from '../../../store';
import { theme } from '../../../theme';
import { useStyles } from './Complete.styles';
import LeftSideCompleteLayout from './LeftSideCompleteLayout/LeftSideCompleteLayout';
import RightSideCompleteLayout from './RightSideCompleteLayout/RightSideCompleteLayout';

const Complete = () => {
  const styles = useStyles();
  const history = useHistory();
  const { trackEvent } = useTrackEvent();
  const { onFinishFte } = useFTE();

  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const { items } = useTypedSelector((state) => state.campaigns);

  const { value: quickStartValues, update: updateQuickStartConfig } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  const { value: FTEValues, update: updateFTEValues } = useVenueProperty<
    VenueProperties['webapp.firstTimeOnboardingFinished.config']
  >({
    property: 'webapp.firstTimeOnboardingFinished.config',
  });

  useEffect(() => {
    if (venueId) {
      updateQuickStartConfig({
        ...quickStartValues,
        currentRoute: history.location.pathname,
        isQuickStartInProgress: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, venueId]);

  const finishQuickStart = async () => {
    await updateQuickStartConfig({
      ...quickStartValues,
      isQuickStartInProgress: false,
    });
    await updateFTEValues({
      ...FTEValues,
      webapp_modal: {
        modal: true,
        actionBar: true,
      },
    });
    await onFinishFte();
  };

  const handleConfigureVideoAppClick = async () => {
    await finishQuickStart();

    trackEvent(QuickStartEventNames.QuickStartConfigureVideoApp);
    history.push(WebsiteRoutes.BrandingStyleTab);
  };

  const handleReviewDefaultCampaignClick = async () => {
    await finishQuickStart();

    trackEvent(QuickStartEventNames.QuickStartReviewDefaultCampaignFlow);
    history.push(WebsiteRoutes.CampaignDetails.replace(':id', items[0]?.id || ''));
  };

  return (
    <Box className={styles.container}>
      <PageWithHeaderLayout
        header={{
          title: `${OnboardingWelcomeStrings.SetUp}`,
          icon: <VideosIconAnalytics width="30px" height="30px" />,
          filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))',
          height: '80px',
          iconHeight: '30px',
          titleSmall: true,
        }}
        bgcolor={theme.palette.common.white}
        isFixed
      >
        <Grid component="main" className={styles.pageWrapper}>
          <TwoSidePageLayout
            containerWidth={'100%'}
            containerHeight={'100%'}
            loading={false}
            pxLeftSide={0}
            leftSideColumnWidths={7}
            leftSideNode={
              <LeftSideCompleteLayout
                onConfigureVideoAppClick={handleConfigureVideoAppClick}
                onReviewDefaultCampaignClick={handleReviewDefaultCampaignClick}
              />
            }
            rightSideNode={<RightSideCompleteLayout />}
          />
        </Grid>
      </PageWithHeaderLayout>
    </Box>
  );
};

export default Complete;
