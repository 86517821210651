import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
  },
  iconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
  },
  closeIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    margin: '0 auto',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.36px',
    color: '#1A1538',
  },
  description: {
    margin: '25px auto 0',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.24px',
    color: '#8B89A0',
    width: '304px',
  },
  btn: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '26px',
    borderRadius: '100px',
    height: '45px',
    width: '111px',
    margin: '15px auto 35px',
  },
  videosCountText: {
    width: '360px',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.28px',
    color: '#000',
    marginTop: '15px',
  },
  divider: {
    width: '300px',
    margin: '0 auto',
  },
  countWrapper: {
    margin: '15px auto 0',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    minWidth: '210px',
    width: 'max-content',
  },
  videosCount: {
    fontSize: '48px',
    fontWeight: '600',
    lineHeight: '38px',
    color: '#54AD32',
  },
  googleProfileIcon: {
    width: '55px',
    height: '48px',
  },
}));
