import { Box, Button, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getCampaignById,
  resetCurrentRewardCampaign,
  resetCurrentCampaignUsers,
  getCampaignCountData,
  getCampaignVideos,
  reset,
} from '../../../store/slices/campaignsSlice';
import { CampaignControls } from '../../rewards/CampaignControls/CampaignControls';
import { CampaignSummary } from '../../rewards/CampaignSummary/CampaignSummary';
import { CampaignUsersTable } from '../ExistingCampaignUsersTable/CampaignUsersTable';
import { TabItem, TabsSelect } from '../../../common/components/TabsSelect/TabsSelect';
import { CampaignInvitesStrings, RewardsPageStrings } from '../../../common/localization/en';
import { InvitedCustomersTable } from '../../NewCampaign/InvitedCustomers/InvitedCustomers';
import { useStyles } from './CampaignDetails.styles';
import { WebsiteRoutes } from '../../../common/constants/routes';
import BackArrow from '../../../common/assets/BackArrow';
import { IEventTracker, useTrackEvent } from '../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { InvitesBlock } from '../../../common/components/InvitesBlock/InvitesBlock';
import { PendingUsersTable } from '../PendingUsersTable/PendingUserTable';
import { NoUsersLayout } from '../../NewCampaign/InvitedCustomers/NoUsersLayout';
import { UnsentInvitesTable } from '../UnsentInvitesTable/UnsentInvitesTable';
import { useClientType } from '../../../services/hooks/useClientType';

export const CampaignDetails = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { items: currentRewardCampaignExistedUsers } = useTypedSelector(
    (state) => state.campaigns.currentCampaignUsers,
  );
  const { currentCampaign, countData, isCountDataLoading, campaignVideos } = useTypedSelector(
    (state) => state.campaigns,
  );
  const { id: currentCampaignId } = useParams<{ id: string }>();

  const history = useHistory();

  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();

  useEffect(() => {
    handleTrackEvent(RewardCampaignsEvents.RewardCampaignsDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentCampaignId) {
      dispatch(getCampaignCountData({ campaignId: currentCampaignId }));
      dispatch(getCampaignById(currentCampaignId));
      dispatch(
        getCampaignVideos({
          id: currentCampaignId,
          pageable: {
            page: 0,
            size: 100000,
            sort: ['asc'],
          },
        }),
      );
    }
  }, [currentCampaignId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCurrentRewardCampaign());
      dispatch(resetCurrentCampaignUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTrackEvent: IEventTracker = (event, props) => {
    trackEvent(event, {
      campaignId: currentCampaign?.id,
      ...props,
    });
  };

  const tabsList: TabItem[] = [
    {
      tabName: isHealthCare
        ? CampaignInvitesStrings.ActivePatients
        : CampaignInvitesStrings.ActiveCreators,
      usersCount: isCountDataLoading ? '' : countData?.usersWithVideos?.toString(),
      tabContent: <CampaignUsersTable countData={countData} handleTrackEvent={handleTrackEvent} />,
    },
    {
      tabName: isHealthCare
        ? CampaignInvitesStrings.PendingPatients
        : CampaignInvitesStrings.PendingCustomers,
      usersCount: isCountDataLoading
        ? ''
        : (countData.users - countData?.usersWithVideos).toString(),
      tabContent: <PendingUsersTable countData={countData} handleTrackEvent={handleTrackEvent} />,
    },
    {
      tabName: CampaignInvitesStrings.SmsInviteHistory,
      usersCount: isCountDataLoading ? '' : countData?.invitations.toString(),
      tabContent: currentCampaign ? (
        <InvitedCustomersTable countData={countData} handleTrackEvent={handleTrackEvent} />
      ) : (
        <></>
      ),
    },
    {
      tabName: CampaignInvitesStrings.UnsentInvites,
      usersCount: isCountDataLoading ? '' : countData?.unsentInvitations?.toString(),
      tabContent: countData.unsentInvitations ? (
        <UnsentInvitesTable handleTrackEvent={handleTrackEvent} />
      ) : currentCampaign ? (
        <NoUsersLayout currentCampaign={currentCampaign} showSmsInvitationButton={false} />
      ) : (
        <></>
      ),
    },
  ];

  const handleBack = () => {
    history.push(WebsiteRoutes.RewardCampaigns);
    handleTrackEvent(RewardCampaignsEvents.RewardCampaignsDetailsBackwardClick);
    dispatch(reset());
  };

  return (
    <>
      <Button className={styles.backButton} onClick={handleBack} id="return-to-campaigns-button">
        <BackArrow />
        <Typography>{RewardsPageStrings.BackButton}</Typography>
      </Button>
      {currentCampaign && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '11px 30px 0 30px',
            minHeight: '190px',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              marginRight: '24px',
              maxWidth: 'calc(100% - 360px - 360px)',
              minWidth: '600px',
            }}
          >
            <CampaignSummary
              currentRewardCampaign={currentCampaign}
              campaignVideos={campaignVideos}
            />
          </Box>
          <Box>
            <CampaignControls
              currentRewardCampaign={currentCampaign}
              currentRewardCampaignExistedUsers={currentRewardCampaignExistedUsers}
              onTrackEvent={handleTrackEvent}
            />
          </Box>
          <Box sx={{ marginLeft: '24px' }}>
            <InvitesBlock campaignId={currentCampaign?.shortcode!} />
          </Box>
        </Box>
      )}
      <Box margin="20px 30px 60px 30px">
        <TabsSelect
          tabsList={tabsList}
          onTrackEvent={handleTrackEvent}
          campaignVideos={currentCampaign?.videos}
        />
      </Box>
    </>
  );
};
