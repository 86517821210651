import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UnprotectedRoutes, WebsiteRoutes } from '../../common/constants/routes';
import { useTypedSelector } from '../../store';

import { useClientType } from '../hooks/useClientType';
import { useCrispChat } from './useCrispChat';

const SV_CRISP_WEBSITE_ID = '2611e74f-8b94-4159-957f-0f407aa2e3a4';
// const TRUBLU_CRISP_WEBSITE_ID = 'b4f2d8e6-43d2-4c04-8758-a5714b6a301b';

const ROUTES_WITH_HIDDEN_CHAT = [
  WebsiteRoutes.ProfileAccounts,
  ...Object.values(UnprotectedRoutes),
];

export const CrispChatProvider: React.FC = ({ children }) => {
  const { isTrubluClient } = useClientType();
  const { hideCrispChatButton, showCrispChatButton } = useCrispChat();
  const { pathname } = useLocation();
  const { name, email } = useTypedSelector((state) => state.me);
  const { venue } = useTypedSelector((state) => state.venue);

  const initCrispChat = () => {
    if (window.$crisp) return;

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = SV_CRISP_WEBSITE_ID;
    const injectScriptToHead = () => {
      const d = document;
      const script = d.createElement('script');
      script.src = 'https://client.crisp.chat/l.js';
      script.async = true;
      d.getElementsByTagName('head')[0].appendChild(script);
    };

    injectScriptToHead();
  };

  // const updateCrispId = (newCrispId: string) => {
  //   if (!window.$crisp || window.CRISP_WEBSITE_ID === newCrispId) return;

  //   window.CRISP_WEBSITE_ID = newCrispId;
  //   window.$crisp.push(['do', 'session:reset']);
  // };

  useEffect(() => {
    initCrispChat();
  }, []);

  useEffect(() => {
    const shouldHideCrispChat =
      (ROUTES_WITH_HIDDEN_CHAT as string[]).includes(pathname) || isTrubluClient;

    if (shouldHideCrispChat) {
      hideCrispChatButton();
    } else {
      showCrispChatButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTrubluClient, pathname]);

  useEffect(() => {
    if (window.$crisp && email && venue.name && name) {
      //@ts-ignore
      if (window?.$crisp?.is && window?.$crisp?.is('session:ongoing')) {
        //@ts-ignore
        const crispVenue = window.$crisp.get('user:nickname').match(/\[.+]/)?.[0];
        if (crispVenue === `[${venue.name}]`) {
          return;
        }

        window.$crisp.push(['do', 'session:reset']);
      }

      window.$crisp.push([
        'on',
        'chat:opened',
        () => {
          // Handle the "chat:opened" event
          console.log('Crisp chat opened', email, name, venue.name);
          email && window.$crisp!.push(['set', 'user:email', [email]]);

          venue.name &&
            name &&
            window.$crisp!.push([
              'set',
              'user:nickname',
              [`(DASHBOARD) ${name}, from: [${venue.name}]`],
            ]);
        },
      ]);
    }

    return () => {
      // Cleanup: remove the event listener when the component unmounts
      if (window.$crisp) {
        window.$crisp && window.$crisp.push(['off', 'chat:opened']);
      }
    };
  }, [email, name, venue.name]);

  return <>{children}</>;
};
