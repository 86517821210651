import { Box } from '@mui/material';
import { TopGolfVideosApiModel } from '../../api/models/topgolfVideos';
import { TableContent, TableRow } from '../../common/commonTypes';
import { StatusDropdown } from '../../common/components/StatusDropdown/StatusDropdown';

import { DefaultImage, TextSpan } from '../../common/components/table/CommonTable.style';
import { ImageComponent } from '../../common/components/table/TableImage';
import { VideoStatus } from '../../common/components/VideosDetailHeaderToolbar/VideoStatus';
import { OrderDirection } from '../../common/constants/constants';
import { ContentListView } from '../../common/localization/en';
import { CustomCheckBox } from '../../pages/content/tableSection/contentTable/UserNameCell';
import { AddVideoToSocialVerse } from '../../pages/SocialVerse/AddVideos/AddVideosButton/AddVideoToSocialVerse';
import { getAMPMTime, getPSTDateString, getStringValue, getVideoPoster } from '../utilities';

interface ContentMapperProps<T> {
  renderCheckbox?: boolean;
  items: T[];
  handleOpenConfirmationModal: (id: string) => void;
  isSocialVersePage?: boolean;
  handleAddVideoToCard: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  replace?: boolean;
  handleVideoReplace?: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
}

export interface TopGolfContentDataContent extends TableRow {
  id: string;
  video?: JSX.Element;
  shotDate: JSX.Element;
  shotTime: JSX.Element;
  views: JSX.Element;
}

export const createContentDataTableContent = ({
  items,
  renderCheckbox,
  handleOpenConfirmationModal,
  isSocialVersePage,
  handleAddVideoToCard,
  handleVideoReplace,
  replace,
}: ContentMapperProps<TopGolfVideosApiModel>): TableContent<TopGolfContentDataContent> => {
  const tableContent: TableContent<TopGolfContentDataContent> = {
    header: {
      video: {
        name: ContentListView.Column1,
        sortable: false,
      },
      shotDate: {
        name: 'Shot Date',
        sortable: false,
        sortOrder: OrderDirection.DESC,
      },
      shotTime: {
        name: 'Shot Time',
        sortable: true,
        sortOrder: OrderDirection.DESC,
      },
      shortcode: {
        name: 'Short Code',
        sortable: true,
        sortOrder: OrderDirection.DESC,
      },
      views: {
        name: 'Video Views',
        sortable: true,
      },
      ctaClicks: {
        name: 'Conversions',
        sortable: true,
        tooltip: {
          title: ContentListView.Column6,
          text: ContentListView.Column6Tooltip,
        },
      },
      status: {
        name: 'Video Status',
        sortable: true,
        tooltip: {
          title: ContentListView.Column8,
          text: ContentListView.Column8Tooltip,
        },
      },
    },
    rows: [],
  };

  if (tableContent.header) {
    if (isSocialVersePage) {
      tableContent.header.featured = {
        name: 'SocialVerse',
        sortable: false,
        tooltip: undefined,
      };
    } else {
      tableContent.header.videoStatus = {
        name: 'WebApp Status',
        sortable: true,
        tooltip: {
          title: ContentListView.Column8,
          text: ContentListView.Column8Tooltip,
        },
      };
    }
  }

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => ({
    id: item.id,
    video: (
      <>
        <Box component="span" display="flex" alignItems="center">
          {renderCheckbox && <CustomCheckBox video={item} />}
          {item.url ? (
            <ImageComponent imageRef={getVideoPoster(item.posterUrl)} />
          ) : (
            <DefaultImage />
          )}
        </Box>
      </>
    ),
    shotDate: <TextSpan>{getPSTDateString(item.shotTime)} </TextSpan>,
    shotTime: <TextSpan>{getAMPMTime(item.shotTime)} </TextSpan>,
    shortCode: <TextSpan>{item.shortcode} </TextSpan>,
    views: <TextSpan>{getStringValue(item.views)} </TextSpan>,
    ctaClicks: <TextSpan>{item.ctaClicks}</TextSpan>,
    status: <VideoStatus status={item.status} />,
    featured: isSocialVersePage ? (
      <>
        <AddVideoToSocialVerse
          id={item.id}
          handleClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            return replace
              ? handleVideoReplace
                ? handleVideoReplace({ event, id: item.id, addUsingRowBtn: true })
                : null
              : handleAddVideoToCard
              ? handleAddVideoToCard(event, item.id, true)
              : null;
          }}
        />
      </>
    ) : (
      <StatusDropdown
        id={item.id}
        publishedToWebapp={item.publishedToWebapp ? 'published' : 'unpublished'}
        TG
        webAppStatus
      />
    ),
  }));

  return tableContent;
};

export const createDownloadCSVTopGolfTableContent = ({
  items,
  renderCheckbox,
  handleOpenConfirmationModal,
  isSocialVersePage,
  handleAddVideoToCard,
}: ContentMapperProps<TopGolfVideosApiModel>): TableContent<TopGolfContentDataContent> => {
  const tableContent: TableContent<TopGolfContentDataContent> = {
    header: {
      shotDate: {
        name: 'Shot Date',
        sortable: false,
        sortOrder: OrderDirection.DESC,
      },
      shotTime: {
        name: 'Shot Time',
        sortable: true,
        sortOrder: OrderDirection.DESC,
      },
      shortcode: {
        name: 'Short Code',
        sortable: true,
        sortOrder: OrderDirection.DESC,
      },
      views: {
        name: 'Video Views',
        sortable: true,
      },
      ctaClicks: {
        name: 'Conversions',
        sortable: true,
        tooltip: {
          title: ContentListView.Column6,
          text: ContentListView.Column6Tooltip,
        },
      },
      status: {
        name: 'Video Status',
        sortable: true,
        tooltip: {
          title: ContentListView.Column8,
          text: ContentListView.Column8Tooltip,
        },
      },
    },
    rows: [],
  };

  if (tableContent.header) {
    if (isSocialVersePage) {
      tableContent.header.featured = {
        name: 'SocialVerse',
        sortable: false,
        tooltip: undefined,
      };
    } else {
      tableContent.header.videoStatus = {
        name: 'WebApp Status',
        sortable: true,
        tooltip: {
          title: ContentListView.Column8,
          text: ContentListView.Column8Tooltip,
        },
      };
    }
  }

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => ({
    id: item.id,
    shotDate: (
      <Box display={'flex'} alignItems="center">
        <Box component="span" display="flex" alignItems="center">
          {item.url ? (
            <ImageComponent imageRef={getVideoPoster(item.posterUrl)} />
          ) : (
            <DefaultImage />
          )}
        </Box>
        <TextSpan>{getPSTDateString(item.shotTime)} </TextSpan>
      </Box>
    ),
    shotTime: <TextSpan>{getAMPMTime(item.shotTime)} </TextSpan>,
    shortCode: <TextSpan>{item.shortcode} </TextSpan>,
    views: <TextSpan>{getStringValue(item.views)} </TextSpan>,
    ctaClicks: <TextSpan>{item.ctaClicks}</TextSpan>,
    status: <VideoStatus status={item.status} />,
    featured: isSocialVersePage ? (
      <>
        <AddVideoToSocialVerse
          id={item.id}
          handleClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            return handleAddVideoToCard ? handleAddVideoToCard(event, item.id, true) : null;
          }}
        />
      </>
    ) : (
      <StatusDropdown
        id={item.id}
        publishedToWebapp={item.publishedToWebapp ? 'published' : 'unpublished'}
        TG
        webAppStatus
      />
    ),
  }));

  return tableContent;
};
