import { EmailIcon } from '../../assets/shareAssets/EmailIcon';
import { FBReelsIcon } from '../../assets/shareAssets/FBReelsIcon';
import { RedditIcon } from '../../assets/shareAssets/RedditIcon';
import { SMSIcon } from '../../assets/shareAssets/SMSIcon';
import { TwitterIcon } from '../../assets/shareAssets/TwitterIcon';
import { DownloadIconDv5 } from './DownloadIconDv5';
import { FacebookIconDv5 } from './FacebookIconDv5';
import { InstagramIconDv5 } from './InstagramIconDv5';
import { LinkIconDv5 } from './LinkIconDv5';
import { SharesAnalyticsData } from './SharedVideo';
import { TikTokIcon } from './TikTokIcon';
import { YouTubeShortsIcon } from './YouTubeShortsIcon';

export interface SharesAnalyticsBlockModel {
  icon: React.ReactNode;
  label: string;
  value: number | string;
  kpiName: string;
}

export const createSharesAnalyticsBlockData = (
  videoAnalyticsData: SharesAnalyticsData,
): SharesAnalyticsBlockModel[] => {
  return [
    {
      icon: <SMSIcon />,
      label: 'SMS',
      value: videoAnalyticsData['SMS'] | 0,
      kpiName: 'SMS',
    },
    {
      icon: <EmailIcon />,
      label: 'Email',
      value: videoAnalyticsData['EMAIL'] | 0,
      kpiName: 'EMAIL',
    },
    {
      icon: <FacebookIconDv5 color="#8B89A0" />,
      label: 'Facebook',
      value: videoAnalyticsData['FACEBOOK'] | 0,
      kpiName: 'FACEBOOK',
    },
    {
      icon: <TwitterIcon />,
      label: 'Twitter',
      value: videoAnalyticsData['TWITTER'] | 0,
      kpiName: 'TWITTER',
    },
    {
      icon: <LinkIconDv5 />,
      label: 'Link',
      value: videoAnalyticsData['LINK'] | 0,
      kpiName: 'LINK',
    },
    {
      icon: <DownloadIconDv5 />,
      label: 'Downloads',
      value: videoAnalyticsData['DOWNLOAD'] | 0,
      kpiName: 'DOWNLOAD',
    },
    {
      icon: <InstagramIconDv5 />,
      label: 'Instagram',
      value: videoAnalyticsData['INSTAGRAM'] | 0,
      kpiName: 'INSTAGRAM',
    },
    {
      icon: <TikTokIcon />,
      label: 'TikTok',
      value: videoAnalyticsData['TIKTOK'] | 0,
      kpiName: 'TIKTOK',
    },
    {
      icon: <FBReelsIcon />,
      label: 'Facebook Reels',
      value: videoAnalyticsData['REELS'] | 0,
      kpiName: 'REELS',
    },
    {
      icon: <RedditIcon />,
      label: 'Reddit',
      value: videoAnalyticsData['REDDIT'] | 0,
      kpiName: 'REDDIT',
    },
    {
      icon: <YouTubeShortsIcon />,
      label: 'YouTube Shorts',
      value: videoAnalyticsData['YOUTUBESHORTS'] | 0,
      kpiName: 'YOUTUBESHORTS',
    },
  ];
};
