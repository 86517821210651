import { VideoDetails } from '../../../api/models/videos';
import {
  asyncGoogleStorageToCloudinary,
  getVideoInMp4,
  VideoResolution,
} from '../../../services/utilities';
import { googleStorageVideoUrlToCloudinaryUrl } from '../EditThumbnail/EditThumbnail.helper';

export const getUrlOriginalResolutionWithFilter = async (url: string) => {
  let result = url.replace(/,w_\d+,h_\d+/, '');

  const video = document.createElement('video');
  video.src = result;

  const videoResolution = await new Promise<VideoResolution>((resolve) => {
    video.addEventListener(
      'loadedmetadata',
      () => {
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      },
      false,
    );
  });

  result = result
    .replace(/w_\d+/, `w_${videoResolution.width}`)
    .replace(/h_\d+/, `h_${videoResolution.height}`);

  if (!result.includes('fl_attachment')) {
    result = result.replace('/upload/', '/upload/fl_attachment/');
  }
  return result;
};

const prefetchCloudinaryVideos = async (url: string, transformedUrl: string) => {
  const [first, second] = url.split('/upload/');

  await fetch(transformedUrl);
  fetch(`${first}/upload/fl_attachment/ar_9:16,c_fill/c_scale,fl_relative/v1/${second}`);
  getUrlOriginalResolutionWithFilter(transformedUrl).then((resultUrl) => {
    fetch(resultUrl);
  });
};

export const CloudinaryURL = async (url: string, filterPublicId: string, width: number) => {
  try {
    let l = url.split('?')[0] || '';

    if (l.includes('https://storage.googleapis.com/')) {
      l = l.replace(
        'https://storage.googleapis.com/',
        'https://res.cloudinary.com/socialvenu/video/upload/',
      );
    }
    if (l.includes('http://storage.googleapis.com/')) {
      l = l.replace(
        'http://storage.googleapis.com/',
        'https://res.cloudinary.com/socialvenu/video/upload/',
      );
    }

    const W = width < 375 ? 400 : width; // temporary value

    const [first, second] = l.split('/upload/');
    // add a check to see if second is defined
    const transformed = `${first}/upload/ar_9:16,c_fill,w_${
      540 || W
    },h_960/c_scale,fl_relative,${filterPublicId},w_${540 || W},h_${960},y_0/v1/${second}`;

    await prefetchCloudinaryVideos(l, transformed);

    return getVideoInMp4(transformed);
  } catch (error) {
    console.log(error);
    console.log(width);
    return '';
  }
};

export function getFilterPublicId(url: string) {
  if (url && url.includes('sv-dev-assets/')) {
    let [, path] = url.split('sv-dev-assets/');
    let withoutExt = path.replace('.png', '');
    let rightPath = withoutExt.replace('/', ':');
    return `l_sv-dev-assets:${rightPath}`;
  }

  if (url && url.includes('sv-prod-assets/')) {
    let [, path] = url.split('sv-prod-assets/');
    let withoutExt = path.replace('.png', '');
    let rightPath = withoutExt.replace('/', ':');
    return `l_sv-prod-assets:${rightPath}`;
  }

  return '';
}

export const getVideoURLWithAspectRatio = async (url: string, width: number) => {
  try {
    let l = url.split('?')[0] || '';

    if (l.includes('https://storage.googleapis.com/')) {
      l = l.replace(
        'https://storage.googleapis.com/',
        'https://res.cloudinary.com/socialvenu/video/upload/',
      );
    }
    if (l.includes('http://storage.googleapis.com/')) {
      l = l.replace(
        'http://storage.googleapis.com/',
        'https://res.cloudinary.com/socialvenu/video/upload/',
      );
    }

    const W = width < 375 ? 400 : width; // temporary value

    const [first, second] = l.split('/upload/');
    // add a check see if second is defined
    const transformed = `${first}/upload/ar_9:16,c_fill,w_${540 || W},h_960/v1/${second}`;

    await prefetchCloudinaryVideos(l, transformed);
    return transformed;
  } catch (error) {
    console.log(error);
    console.log(width);
    return '';
  }
};

export async function getUpdatedVideoUrl(
  downloadUrl: string | undefined,
  attributesFilterUrl?: string | undefined,
  videoPosWidth?: number,
) {
  let url = '';

  if (!downloadUrl) {
    return url;
  }

  if (attributesFilterUrl) {
    const filterId = await asyncGoogleStorageToCloudinary(attributesFilterUrl);
    url = await CloudinaryURL(downloadUrl, getFilterPublicId(filterId), 400);
  } else {
    url = await getVideoURLWithAspectRatio(downloadUrl, videoPosWidth || 400);
  }
  return url;
}

export const getVideoPosterWithCloudinary = (URL: string, isCloudinaryUrl: boolean) => {
  if (isCloudinaryUrl) {
  }
  let url = googleStorageVideoUrlToCloudinaryUrl(URL);
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.jpg';
};

export const getDefaultStoryDetails = (
  userDetails: VideoDetails | null,
  venueDetails: VideoDetails | undefined,
  mockDetails: VideoDetails,
  returnUrl?: string,
) => {
  return {
    metaTitle: venueDetails?.webAppSettings?.metaTitle
      ? userDetails?.metaTitle || venueDetails?.metaTitle || mockDetails.metaTitle
      : venueDetails?.metaTitle || mockDetails.metaTitle,

    metaDescription:
      userDetails?.metaDescription || venueDetails?.metaDescription || mockDetails.metaDescription,
    videoText: '',

    ctaBtnText: venueDetails?.webAppSettings?.ctaBtnText
      ? userDetails?.ctaBtnText || venueDetails?.ctaBtnText || mockDetails.ctaBtnText
      : venueDetails?.ctaBtnText || mockDetails.ctaBtnText,

    ctaBtnLinkUrl: venueDetails?.webAppSettings?.ctaBtnLinkUrl
      ? userDetails?.ctaBtnLinkUrl ||
        returnUrl ||
        venueDetails?.ctaBtnLinkUrl ||
        mockDetails.ctaBtnLinkUrl
      : venueDetails?.ctaBtnLinkUrl || returnUrl || mockDetails.ctaBtnLinkUrl,

    editable: venueDetails?.editable ?? mockDetails.editable,

    videoMessage: venueDetails?.webAppSettings?.videoMessage
      ? userDetails?.videoMessage || venueDetails?.videoMessage || mockDetails.videoMessage
      : venueDetails?.videoMessage || mockDetails.videoMessage,
  };
};

export const getUserStoryDetails = (
  defaults: VideoDetails,
  name: string,
  org: string,
): VideoDetails => {
  return {
    ...defaults,
    metaTitle: defaults.metaTitle.replace('{Display Name}', name).replace('{Org Name}', org),
    videoText: '',
  };
};

export function getSupportedMimeTypes(): string[] {
  const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska'];
  const VIDEO_CODECS = [
    'vp9',
    'vp9.0',
    'vp8',
    'vp8.0',
    'avc1',
    'av1',
    'h265',
    'h.265',
    'h264',
    'h.264',
    'opus',
  ];

  const supportedTypes: string[] = [];
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`;
    VIDEO_CODECS.forEach((codec) => {
      const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`,
      ];
      variations.forEach((variation) => {
        // @ts-ignore
        if (MediaRecorder && MediaRecorder.isTypeSupported(variation))
          supportedTypes.push(variation);
      });
    });
  });
  return supportedTypes;
}

export const getFileSizeInMb = (size: number) => {
  return Math.floor(size / 1000000);
};
