import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  select: {
    '& .MuiSelect-select': {
      fontWeight: '500 !important',
      color: '#101828',
    },
  },
  iconWrapper: {
    position: 'absolute',
    right: '10px',
    top: '12px',
  },
  selectItem: {
    color: '#101828 !important',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
}));
