import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    minHeight: 'calc(100vh - 150px)',
  },
  descriptionWrapper: {
    width: 'fit-content',
    paddingTop: '40px',
    margin: '0 auto 95px',
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    fontSize: '20px',
    lineHeight: '30px',
    width: '428px',
  },
  btnSendSample: {
    marginLeft: '25px',
    height: '56px',
    border: 'none',
    boxShadow: ' 0px 1px 2px rgba(54, 37, 175, 0.05), 0px 0px 0px 4px rgba(54, 37, 175, 0.2)',
    background: '#E6E3FF',
    color: '#5242EA',
    fontSize: '14px',
    width: '200px',
    '& p': {
      lineHeight: '18px',
    },
    '&:hover': {
      border: 'none',
    },
  },

  divider: {
    borderBottom: '1px solid #DCDBE4',
    marginBottom: '25px',
  },
  sectionNumber: {
    display: 'grid',
    placeItems: 'center',
    marginTop: '5px',
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: '#5242EA',
  },
  addDataWrapper: {
    margin: '125px 0 153px 103px',
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    display: 'inline',
    color: '#1A1538',
  },
  titleOption: {
    color: '#5242EA',
    all: 'inherit',
  },
  importDescription: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#101828',
    width: '600px',
    margin: '10px 0 0 10px',
  },
  headersList: {
    fontSize: '16px',
    lineHeight: '24px',
    '& .MuiListItem-root': {
      paddingTop: 0,
      paddingBottom: '2px',
    },
  },

  templateLink: {
    fontSize: '12px',
    lineHeight: '26px',
    textDecoration: 'underline',
    fontWeight: '400',
    color: '#085DFF',
  },
  selectText: {
    fontSize: '12px',
    color: '#8B89A0',
    marginTop: '15px',
    marginLeft: '35px',
  },
  btnsWrapper: {
    marginTop: '30px',
    display: 'flex',
  },
  btn: {
    width: '224px',
  },
  listWrapper: {
    display: 'flex',
    gap: '25px',
    margin: '5px 15px 35px',
  },
}));
