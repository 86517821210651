import { Button, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { RewardFulfillmentMethod } from '../../../../api/models/rewardFulfillment';
import { FSModal } from '../../../../common/components/modal/Modal';
import { CampaignSummary } from '../../../../common/localization/en';
import { theme } from '../../../../theme';
import { useClientType } from '../../../../services/hooks/useClientType';

interface NotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  value: RewardFulfillmentMethod;
  onChange: () => void;
}

export const NotificationModal: FC<NotificationModalProps> = ({
  onClose,
  isOpen,
  onChange,
  value,
}) => {
  const { isHealthCare } = useClientType();
  return (
    <FSModal onClose={onClose} modalIsOpen={isOpen} width="400px" rounded="15px" closeBtn>
      <Typography
        style={{
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '150%',
          textAlign: 'center',
          letterSpacing: '0.02em',
        }}
      >
        {value === RewardFulfillmentMethod.AUTOMATIC_SMS
          ? CampaignSummary.Manual
          : CampaignSummary.Automatic}
      </Typography>
      <Typography
        style={{
          fontWeight: '400',
          fontSize: '13px',
          lineHeight: '160%',
          textAlign: 'center',
          letterSpacing: '0.02em',
          color: theme.palette.common.gray,
          marginTop: '12px',
        }}
      >
        {value === RewardFulfillmentMethod.AUTOMATIC_SMS
          ? CampaignSummary.ManualDescription.replace(
              'customer',
              isHealthCare ? 'patient' : 'customer',
            )
          : CampaignSummary.AutomaticDescription.replace(
              'customer',
              isHealthCare ? 'patient' : 'customer',
            )}
      </Typography>
      <Stack mt={'23px'} justifyContent="center" direction="row" spacing={2}>
        <Button
          variant="outlined"
          size="large"
          style={{ borderRadius: '100px', padding: '8px 36px' }}
          onClick={onClose}
          id="change-goal-notification-cancel-buttom"
        >
          No thanks
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{ borderRadius: '100px', padding: '8px 36px' }}
          onClick={onChange}
          id="change-goal-notification-confirm-buttom"
        >
          Confirm
        </Button>
      </Stack>
    </FSModal>
  );
};
