import React from 'react';
import { AccordionItem } from '../../common/components/CustomizationAccordion/CustomizationAccordion';
import { NewCampaignSubroutes } from '../../common/constants/routes';
import { NewCampaignStrings } from '../../common/localization/en';
import { AcknowledgementSMSContent } from './AcknowledgementSMS/AcknowledgementSMSContent';
import { AcknowledgementSMSPreview } from './AcknowledgementSMS/AcknowledgementSMSPreview';
import { BusinessVideoStylePreview } from './BusinessVideoStylePreview/BusinessVideoStylePreview';
import { CustomizeSMSPreview } from './CustomizeSMS/CustomizeSMSPreview';
import { CustomizeSMSSectionContent } from './CustomizeSMS/CustomizeSMSSectionContent';
import { ImproveBusinessTypesSwitch } from './ImproveBusinessTypesSwitch/ImproveBusinessTypesSwitch';
/* import { VideoStyleTypesSwitch } from './VideoStyleTypesSwitch/VideoStyleTypesSwitch'; */
import WelcomeVideoStyles from './WelcomeVideoStyles/WelcomeVideoStyles';
import WelcomeVideoStylesPreview from './WelcomeVideoStylesPreview/WelcomeVideoStylesPreview';

interface NewCampaignAccordionItem extends AccordionItem {
  preview?: React.ReactNode;
}

export const accordionItems: NewCampaignAccordionItem[] = [
  {
    sectionContent: <ImproveBusinessTypesSwitch />,
    preview: <BusinessVideoStylePreview />,
    title: NewCampaignStrings.CampaignObjectives,
    subroute: NewCampaignSubroutes.CampaignObjectives,
    tooltipText: '',
    withToolTip: true,
  },
  {
    sectionContent: <WelcomeVideoStyles />,
    preview: <WelcomeVideoStylesPreview />,
    title: NewCampaignStrings.VideoStyle,
    subroute: NewCampaignSubroutes.VideoStyle,
    tooltipText: '',
    withToolTip: true,
  },
  {
    sectionContent: <CustomizeSMSSectionContent />,
    preview: <CustomizeSMSPreview />,
    title: NewCampaignStrings.InviteSms,
    subroute: NewCampaignSubroutes.InviteSms,
    tooltipText: '',
    withToolTip: true,
  },
  {
    sectionContent: <AcknowledgementSMSContent />,
    preview: <AcknowledgementSMSPreview />,
    title: NewCampaignStrings.CustomizeAcknowledgementSMS,
    subroute: NewCampaignSubroutes.AcknowledgementSms,
    tooltipText: '',
    withToolTip: true,
  },
  /*  {
    sectionContent: <FulfillRewards />,
    preview: <FulfillRewardsPreview />,
    title: NewCampaignStrings.FulfillRewards,
    subroute: NewCampaignSubroutes.FulfillRewards,
    tooltipText: '',
    withToolTip: true,
  }, */
];

export interface VisitedRoutes {
  [key: string]: {
    visited: boolean;
    revisited?: boolean;
    valid: boolean;
  };
}

export const rewardRoutes = {
  'campaign-objectives': {
    visited: false,
    valid: true,
  },
  'acknowledgement-sms': {
    visited: false,
    valid: false,
  },
  'invite-sms': {
    visited: false,
    valid: false,
  },
  'video-style': {
    visited: false,
    valid: true,
  },
};
export const isEditRewardRoutes = {
  'invite-sms': {
    visited: false,
    valid: false,
  },
  'acknowledgement-sms': {
    visited: false,
    valid: false,
  },
};
