import { alpha } from '@material-ui/core';
import { Box, CardMedia, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { SocialVerseAllApiModel, SocialVerseVideos } from '../../../api/models/socialVerse';
import { ArrowLeftIcon } from '../../../common/assets/newDesign/ArrowLeftIcon';
import { ArrowRightIcon } from '../../../common/assets/newDesign/ArrowRightIcon';
import { PictureIcon } from '../../../common/assets/newDesign/PictureIcon';
import { VideoCardIcons } from '../../../common/assets/newDesign/VideoCardIcons';
import { PlayIcon } from '../../../common/assets/newDesign/VideoControlsIcons/PlayIcon';
import { formatDuration, getVideoPoster } from '../../../services/utilities';

interface BlockListProps {
  socialverse: SocialVerseAllApiModel;
}

const useStyles = makeStyles<Theme, { showNavigation?: boolean }>(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: 'fit-content',
    position: 'relative',
    height: '206px',
    justifyContent: 'center',
    '& .swiper-slide': {
      width: '106.5px !important',
    },
  },
  videoCardWrapper: {
    position: 'relative',
    borderRadius: 8,
  },
  videoCard: {
    width: 104,
    height: 168,
    borderRadius: 8,
    border: '1px solid transparent',
    background: '#ccc',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
  },
  videoCardShadow: {
    width: '100%',
    height: 74,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    background: 'linear-gradient(0deg, #00000000 0%, #000000A8 100%)',
    position: 'absolute',
    top: '0',
    zIndex: '2',
    display: 'flex',
    justifyContent: 'space-between',
  },
  videoCardInfo: {
    paddingTop: '12px',
    paddingLeft: '14px',
  },
  videoCardAuthor: {
    fontFamily: 'HelveticaNeue',
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '9px',
    letterSpacing: ' 0.02em',
    textAlign: 'left',
    color: '#fff',
  },
  videoCardDuration: {
    fontFamily: 'Poppins',
    fontSize: '8px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: ' 0.02em',
    color: '#fff',
  },
  videoCardControl: {
    paddingTop: '24px',
    paddingRight: '10px',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: alpha('#fff', 0.5),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  navigationWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
    cursor: 'default',
  },
  navButton: {
    /* position: 'absolute', */
    /* top: '77px', */
    zIndex: 100,
    width: '25px',
    height: '25px',
    paddingTop: '3px',
    background: '#72C0F9',
    borderRadius: '50%',
    '&:svg': {
      fill: 'white',
    },
    '&:disabled': {
      background: '#DCDBE4',
      '&:svg': {
        fill: '#47546780',
      },
    },
  },
  navLabel: {
    fontSize: '10px',
    color: '#475467',
    fontWeight: '500',
    lineHeight: '20px',
    margin: '0px 34px 0px',
  },
  leftBtn: {},
  rightBtn: {},
  swiper: () => ({
    position: 'relative',
    marginLeft: '0 !important',
    width: '450px !important',
    '& > div': {
      justifyContent: 'left',
    },
  }),
  navBarWrapper: {
    marginTop: '6px',
  },
  swiperNavBar: {
    width: 'fit-content',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    transform: 'translateX(2.5px)',
  },
  swiperNavItem: {
    height: '8px',
    width: '8px',
    borderRadius: '50%',
    marginRight: '5px',
    marginBottom: '5px',
  },
}));

export const PlayIconView = () => {
  const classes = useStyles({});
  return (
    <Box fontSize={45} className={classes.icon} sx={{ width: '56px', height: '56px' }}>
      <PlayIcon />
    </Box>
  );
};
export const PictureIconView = () => {
  const classes = useStyles({});
  return (
    <Box
      fontSize={45}
      className={[classes.icon, classes.transparent].join(' ')}
      sx={{ width: '56px', height: '56px', zIndex: '1' }}
    >
      <PictureIcon />
    </Box>
  );
};
export const BlockList: FC<BlockListProps> = ({ socialverse }) => {
  const classes = useStyles({ showNavigation: socialverse.topVideos.length > 4 });
  const [swiper, setSwiper] = useState<any>();
  const [navBarUpdate, setNavBarUpdate] = useState(1);

  const renderBlock = (item: SocialVerseVideos) => {
    return (
      <SwiperSlide
        style={{
          width: '106.5px !important',
        }}
      >
        <Box key={item.videoId} className={classes.videoCardWrapper}>
          <PlayIconView />
          <Box className={classes.videoCardShadow}>
            <Box className={classes.videoCardInfo}>
              <Box className={classes.videoCardAuthor}>{item.userName}</Box>
              <Box className={classes.videoCardDuration}>
                {formatDuration(item.duration || 0, { twoDigitsMinutes: true })}
              </Box>
            </Box>
            <Box className={classes.videoCardControl}>
              <VideoCardIcons />
            </Box>
          </Box>
          <Box id={item.videoId} draggable={true}>
            {item.thumbnailUrl ? (
              <CardMedia
                className={classes.videoCard}
                image={getVideoPoster(item.url) || item.thumbnailUrl || item.url}
              />
            ) : (
              <CardMedia
                className={classes.videoCard}
                component="video"
                image={item.thumbnailUrl ? item.thumbnailUrl : item.url}
              />
            )}
          </Box>
        </Box>
      </SwiperSlide>
    );
  };

  const renderEmptyBlock = (i: number) => {
    return (
      <SwiperSlide
        style={{
          width: '106.5px !important',
        }}
      >
        <Box key={i} className={classes.videoCardWrapper}>
          <PictureIconView />
          <Box className={classes.videoCardShadow}>
            <Box className={classes.videoCardInfo}>
              <Box className={classes.videoCardAuthor}>First L</Box>
              <Box className={classes.videoCardDuration}>00:23</Box>
            </Box>
            <Box className={classes.videoCardControl}>
              <VideoCardIcons />
            </Box>
          </Box>
          <Box id={`${i}`} draggable={true}>
            {<CardMedia className={classes.videoCard} component="video" image={''} />}
          </Box>
        </Box>
      </SwiperSlide>
    );
  };
  const renderBlocks = () => {
    return socialverse.topVideos.map((item) => renderBlock(item));
  };
  const renderEmptyBlocks = () => {
    const amountTorender =
      socialverse.topVideos && socialverse.topVideos?.length < 4
        ? 4 - socialverse.topVideos.length
        : 0;
    return [...Array(amountTorender)].map((_, i) => renderEmptyBlock(i));
  };

  interface NavButtonProps {
    swiper: any;
  }

  const SwiperNavButtons: FC<NavButtonProps> = ({ swiper }) => {
    const viewsCount = Math.ceil(swiper?.slides?.length / swiper?.params?.slidesPerView);
    return (
      <Box className={classes.navigationWrapper}>
        <button
          onClick={() => {
            [...Array(swiper.params.slidesPerView)].forEach(() => swiper.slidePrev());
            setNavBarUpdate((prev) => prev - 1);
          }}
          className={[classes.navButton, classes.leftBtn].join(' ')}
          disabled
        >
          <ArrowLeftIcon />
        </button>
        <Typography className={classes.navLabel}>
          Group {navBarUpdate} of {viewsCount}
        </Typography>
        <button
          onClick={() => {
            [...Array(swiper.params.slidesPerView)].forEach(() => swiper.slideNext());
            setNavBarUpdate((prev) => prev + 1);
          }}
          className={[classes.navButton, classes.rightBtn].join(' ')}
          disabled
        >
          <ArrowRightIcon />
        </button>
      </Box>
    );
  };

  return (
    <div className={classes.container}>
      {socialverse.topVideos.length > 4 && (
        <>
          <SwiperNavButtons swiper={swiper} />
        </>
      )}
      <Swiper
        draggable={false}
        slidesPerView={4}
        spaceBetween={8}
        width={450}
        className={classes.swiper}
        onSwiper={(swiper) => {
          setSwiper(swiper);
        }}
        allowTouchMove={false}
      >
        {renderBlocks()}
        {4 - (socialverse.topVideos?.length || 0) && renderEmptyBlocks()}
      </Swiper>
    </div>
  );
};
