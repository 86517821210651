import React, { FC, useCallback, useRef, useState } from 'react';
import { useStyles } from './RecentVideosSlider.styles';
import { CampaignVideosApiModel } from '../../../../api/models/campaigns';
import { VideoComponent } from '../../../../common/components/VideoComponent/VideoComponent';
import { VideoPlaybackModal } from '../../../../common/components/NewDetailsModal/DetailsModalContent/VideoPlaybackModal';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper/types';

import 'swiper/swiper.min.css';
import { Box, Button } from '@mui/material';
import ArrowLeft from '../../../../common/assets/ArrowLeft';
import ArrowRight from '../../../../common/assets/ArrowRight';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../common/constants/events/rewardCampaignsEvents';

interface Props {
  videos: CampaignVideosApiModel[];
  campaignId: string;
  sliderWidth: number;
  slidesPerView: number;
}

export const SLIDE_WIDTH = 128;

const RecentVideosSlider: FC<Props> = ({ videos, campaignId, sliderWidth, slidesPerView }) => {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoToPlay = useRef<string | null>(null);
  const { trackEvent } = useTrackEvent();

  const handleOpenPlayVideoModal = (url: string) => {
    setIsOpen(!isOpen);
    setIsPlaying(true);
    videoToPlay.current = url;
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsPlaying(false);
  };

  const sliderRef = useRef<SwiperRef | null>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageVideosSliderPrevButtonClick, {
      campaignId,
    });
  }, [campaignId, trackEvent]);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageVideosSliderNextButtonClick, {
      campaignId,
    });
  }, [campaignId, trackEvent]);

  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  function checkIfButtonDisabled(swiper: SwiperType) {
    if (swiper.realIndex === 0) {
      setIsPrevButtonDisabled(true);
    } else {
      setIsPrevButtonDisabled(false);
    }

    if (swiper.isEnd && swiper.realIndex !== 0) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  }

  return (
    <>
      {videoToPlay.current && (
        <VideoPlaybackModal
          isOpen={isOpen}
          isTopGolf={false}
          videoUrl={videoToPlay.current}
          onClose={handleClose}
        />
      )}
      <Box className={styles.sliderWrapper} width={sliderWidth}>
        <Swiper slidesPerView={slidesPerView} ref={sliderRef} onSlideChange={checkIfButtonDisabled}>
          {videos.map((video) => (
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
              <VideoComponent
                thumbnail={video.thumbnailUrl}
                url={video.url}
                handleOpenModal={() => {
                  handleOpenPlayVideoModal(video.url);
                  trackEvent(
                    RewardCampaignsEvents.RewardCampaignSummaryPageVideosSliderVideoClick,
                    {
                      campaignId,
                      videoId: video.id,
                    },
                  );
                }}
                isTopGolf={false}
                isPlaying={isPlaying}
                withoutThumbnailEdit
                width={110}
                height={192}
                withoutShadow
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {sliderWidth < videos.length * SLIDE_WIDTH && (
          <Box className={styles.navigationWrapper}>
            <Button
              onClick={handlePrev}
              disabled={isPrevButtonDisabled}
              className={styles.navigation}
              sx={{ marginRight: '16px' }}
            >
              <ArrowLeft />
            </Button>
            <Button
              onClick={handleNext}
              disabled={isNextButtonDisabled}
              className={styles.navigation}
            >
              <ArrowRight />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default RecentVideosSlider;
