import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { brandColor: string; ltheme?: string }>(
  (theme: Theme) => ({
    image: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    logo: {
      position: 'relative',
      height: '40px',
      width: 'auto',
      objectFit: 'contain',
      marginBottom: '50px',
      marginTop: '35px',
      padding: '0 16px',
    },
    menuIcon: {
      position: 'absolute',
      top: '5px',
      left: '10px',
      zIndex: '100',
    },
    crispChatIcon: {
      position: 'absolute',
      top: '10px',
      right: '12px',
      zIndex: '100',
    },
    title: {
      fontSize: '15px',
      fontWeight: '600',
      lineHeight: '24px',
    },
    imgWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: '36px',
      marginBottom: '18px',
    },
    shareDesc: {
      marginBottom: '8px',
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '20px',
      textAlign: 'center',
    },
    img: ({ ltheme }) => ({
      width: '74px',
      height: '130px',
      zIndex: '100',
      ...(ltheme === 'light' ? { borderRadius: '12px', border: '4px solid black' } : {}),
    }),
    buttonsWrapper: {
      position: 'absolute',
      bottom: '10px',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    outlined: ({ brandColor, ltheme }) => ({
      borderRadius: '10px',
      border: `2px solid ${brandColor}`,
      color: ltheme === 'light' ? '#475467' : '#fff',
      fontSize: '600',
      lineHeight: '12px',
      padding: '12px 20px 12px 20px',
      width: '100%',
      marginBottom: '17px',
    }),
    button: {
      height: '42px',
      width: '211px',
      display: 'grid',
      placeItems: 'center',
      borderRadius: '50px',
      fontSize: '12px',
      fontWeight: 600,
    },
    recordButton: {
      boxShadow: '0px 4px 7px -2px rgba(44, 44, 44, 0.15)',
    },
    shareBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '6px',
      marginBottom: '8px',
    },
    shareBtn: ({ brandColor }) => ({
      background: brandColor,
      color: 'white',
      minHeight: '44px',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '12px',
      lineHeight: '23px',
      fontWeight: '500',
      padding: '10px 20px 10px 14px',
    }),
    shareBtnSmall: {
      padding: '4px 12px 4px 12px !important',
      '& svg': {
        height: '30px',
      },
    },
  }),
);
