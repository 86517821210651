import React, { FC } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useNoUsersLayoutStyles } from './NoUsersLayout.style';
import { CampaignInvitesStrings } from '../../../common/localization/en';
import { useHistory } from 'react-router-dom';
import { NewCampaignSubroutes, WebsiteRoutes } from '../../../common/constants/routes';
import { CampaignsApiModel } from '../../../api/models/campaigns';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { useModal } from '../../../hooks/useModal';
import { AddIndividualCustomerModal } from '../../rewards/CampaignSummary/Modals/AddIndividualCustomerModal';
import { ImportFileModal } from '../../rewards/CampaignSummary/Modals/ImportFileModal';
import { useClientType } from '../../../services/hooks/useClientType';

interface NoInvitesLayoutProps {
  isCampaignDisabled?: boolean;
  currentCampaign: CampaignsApiModel;
  showSmsInvitationButton?: boolean;
}

export const NoUsersLayout: FC<NoInvitesLayoutProps> = ({
  currentCampaign,
  showSmsInvitationButton = true,
}) => {
  const styles = useNoUsersLayoutStyles();
  const history = useHistory();
  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();

  const {
    isOpen: isImportFileModalOpen,
    open: openImportFileModal,
    close: closeImportFileModal,
  } = useModal(false);

  const {
    isOpen: isAddIndividualModalOpen,
    open: openIndividualModal,
    close: closeIndividualModal,
  } = useModal(false);

  const isDisabled = !!currentCampaign.endedAt;

  return (
    <Box className={styles.Container}>
      <Box sx={{ paddingTop: '56px', paddingBottom: '70px' }}>
        <Divider />
      </Box>
      <Typography className={styles.Title}>
        {isHealthCare
          ? CampaignInvitesStrings.InviteYourUsers_Trublu
          : CampaignInvitesStrings.InviteYourUsers}
      </Typography>
      <Typography className={styles.Description}>
        {isHealthCare
          ? CampaignInvitesStrings.PromoteDescription_Trublu
          : CampaignInvitesStrings.PromoteDescription}
      </Typography>
      <Stack direction={'row'} spacing="12px" justifyContent="center">
        <Button
          variant="contained"
          className={styles.Button}
          disabled={isDisabled}
          sx={{ color: '#fff !important', borderColor: '#7F56D9 !important' }}
          onClick={() => {
            openIndividualModal();
            trackEvent(
              RewardCampaignsEvents.reward_campaign_summary_page_import_manually_no_invites_button_click,
              {
                campaignId: currentCampaign.id,
              },
            );
          }}
          id="import-data-manually-no-invites-button"
        >
          {isHealthCare
            ? CampaignInvitesStrings.ImportDataManuallyTrublu
            : CampaignInvitesStrings.ImportDataManually}
        </Button>
        <Button
          variant="outlined"
          className={styles.Button}
          disabled={isDisabled}
          onClick={() => {
            openImportFileModal();
            trackEvent(
              RewardCampaignsEvents.reward_campaign_summary_page_import_data_no_invites_button_click,
              {
                campaignId: currentCampaign.id,
              },
            );
          }}
          id="import-data-no-invites-button"
        >
          {isHealthCare
            ? CampaignInvitesStrings.UploadCustomerListTrublu
            : CampaignInvitesStrings.UploadCustomerList}
        </Button>
        {showSmsInvitationButton && (
          <Button
            variant="outlined"
            disabled={isDisabled}
            className={styles.Button}
            onClick={() => {
              history.push(
                `${WebsiteRoutes.EditCampaign}/${currentCampaign.id}/${NewCampaignSubroutes.InviteSms}`,
              );
              trackEvent(
                RewardCampaignsEvents.reward_campaign_summary_page_no_invites_button_click,
                {
                  campaignId: currentCampaign.id,
                },
              );
            }}
            id="edit-sms-invitation-no-invites-button"
          >
            {CampaignInvitesStrings.EditSMSInvitation}
          </Button>
        )}
      </Stack>
      <Box sx={{ paddingBottom: '56px', paddingTop: '70px' }}>
        <Divider />
      </Box>

      <AddIndividualCustomerModal
        campaignId={currentCampaign.id}
        isOpen={isAddIndividualModalOpen}
        onClose={() => {
          closeIndividualModal();
          trackEvent(
            RewardCampaignsEvents.reward_campaign_summary_page_import_manually_no_invites_modal_close,
            {
              campaignId: currentCampaign.id,
            },
          );
        }}
      />

      <ImportFileModal
        campaignId={currentCampaign.id}
        isOpen={isImportFileModalOpen}
        onClose={() => {
          closeImportFileModal();
          trackEvent(
            RewardCampaignsEvents.reward_campaign_summary_page_import_file_no_invites_modal_close,
            {
              campaignId: currentCampaign.id,
            },
          );
        }}
      />
    </Box>
  );
};
