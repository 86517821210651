import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useClasses } from './VideosBlock.helper';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getVideos, updateVideoById } from '../../../../store/slices/videosSlice';
import { UserApiModel } from '../../../../api/models/users';
import { CommonStrings } from '../../../localization/en';
import { ConfirmationModal } from '../../../../pages/content/ConfirmationModel/confirmationModel';
import { VideoCardNew } from '../../NewDetailsModal/DetailsModalContent/components/VideoCardNew';

interface ActivityLogBlockProps {
  userData: UserApiModel;
}
export const VideosBlock: React.FC<ActivityLogBlockProps> = ({ userData }) => {
  const classes = useClasses();
  const dispatch = useAppDispatch();
  const [storyId, setStoryId] = useState('');
  const [storyUpdate] = useTypedSelector((state) =>
    state.videos.items.filter((item) => item.id === storyId),
  );
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { id, venueId } = userData;
  const { items, size, page, sort, search, lastUpdated, isLoading } = useTypedSelector(
    (state) => state.videos,
  );
  useEffect(() => {
    dispatch(
      getVideos({
        venueId: venueId,
        userId: id,
        pageable: { page: page, size: size, sort: sort },
        search: search,
      }),
    );
  }, [dispatch, page, size, sort, id, venueId, search, lastUpdated]);

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setStoryId('');
  };
  const handleOpenConfirmationModal = (id: string) => {
    setIsConfirmationModalOpen(true);
    setStoryId(id);
  };
  const handleConfirm = () => {
    if (storyId) {
      dispatch(updateVideoById({ id: storyId, update: { featured: !storyUpdate.featured } }));
      handleCloseConfirmationModal();
    }
  };

  if (isLoading) {
    return (
      <Box className={classes.root}>
        <Box mb={2} width="100%" />
        <Typography variant="subtitle1">Loading...</Typography>
      </Box>
    );
  }

  if (!isLoading && !items.length) {
    return (
      <Box className={classes.root}>
        <Box mb={2} width="100%" />
        <Typography variant="subtitle1">No videos</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.videosContainer}>
        {items.map((story) => (
          <Box display="flex" flexDirection="column" key={story.id}>
            <VideoCardNew video={story} handleOpenConfirmationModal={handleOpenConfirmationModal} />
            <Box mb={1} />
          </Box>
        ))}
      </Box>
      <ConfirmationModal
        handleCloseModal={handleCloseConfirmationModal}
        onConfirm={handleConfirm}
        isModalOpen={isConfirmationModalOpen}
        message={
          storyUpdate && storyUpdate.featured
            ? CommonStrings.RemoveFromWidgetConfirmation
            : CommonStrings.AddToWidgetConfirmation
        }
      />
    </Box>
  );
};
