import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_231_2)">
      <mask
        id="mask0_231_2"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="70"
        height="70"
      >
        <path
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill="#EEECFC"
        />
      </mask>
      <g mask="url(#mask0_231_2)">
        <path
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill="#EEECFC"
        />
        <path d="M0 42.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0 57.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0 27.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0 12.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g clip-path="url(#clip1_231_2)">
        <path
          d="M35.5 55C46.8218 55 56 45.8218 56 34.5C56 23.1782 46.8218 14 35.5 14C24.1782 14 15 23.1782 15 34.5C15 45.8218 24.1782 55 35.5 55Z"
          fill="white"
          stroke="#D9D4FF"
          strokeWidth="4"
        />
        <path
          d="M26 34C26 39.5228 30.4772 44 36 44C37.6569 44 39 42.6569 39 41V40.5C39 40.0356 39 39.8034 39.0257 39.6084C39.2029 38.2622 40.2622 37.2029 41.6084 37.0257C41.8034 37 42.0356 37 42.5 37H43C44.6569 37 46 35.6569 46 34C46 28.4772 41.5228 24 36 24C30.4772 24 26 28.4772 26 34Z"
          stroke="#5242EA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31 35C31.5523 35 32 34.5523 32 34C32 33.4477 31.5523 33 31 33C30.4477 33 30 33.4477 30 34C30 34.5523 30.4477 35 31 35Z"
          stroke="#5242EA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40 31C40.5523 31 41 30.5523 41 30C41 29.4477 40.5523 29 40 29C39.4477 29 39 29.4477 39 30C39 30.5523 39.4477 31 40 31Z"
          stroke="#5242EA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34 30C34.5523 30 35 29.5523 35 29C35 28.4477 34.5523 28 34 28C33.4477 28 33 28.4477 33 29C33 29.5523 33.4477 30 34 30Z"
          stroke="#5242EA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

const BrandColorIcon = memo(SvgComponent);
export default BrandColorIcon;
