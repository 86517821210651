import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.5954 14.8713L26.7863 5.55487H24.8456L17.7303 13.6426L12.0514 5.55487H5.5L14.0894 17.786L5.5 27.5549H7.44071L14.9501 19.012L20.9486 27.5549H27.5L18.5954 14.8713ZM15.9365 17.8933L15.0649 16.6746L8.14057 6.98651H11.1219L16.7117 14.8081L17.5796 16.0268L24.8447 26.1931H21.8633L15.9365 17.8933Z"
      fill="white"
    />
  </svg>
);

export default SvgComponent;
