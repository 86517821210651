import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  select: {
    width: '295px',
    height: '44px',
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& svg': {
      marginRight: '10px',
      width: '20px',
    },
    '& .MuiSelect-select': {
      color: '#1A1538',
      fontSize: '16px',
      fontWeight: '500',
    },
  },
  menuWrapper: {
    boxShadow: 'none',
    border: '1px solid #DCDBE4',
  },
  selectItem: {
    width: '290px',
    background: 'white !important',
    padding: '10px 30px',
    color: theme.palette.common.gray,
    '&:hover': {
      background: '#F5F6F8 !important',
    },
  },
  itemAdd: {
    color: '#1A1538',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center',
    padding: '0 30px',
  },
  plusIcon: {
    fontWeight: '500',
    fontSize: '30px',
    color: '#1A1538',
    padding: '0 10px 5px 0',
  },
  keywordItem: {
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover svg': {
      display: 'block',
    },
  },
  iconDelete: {
    display: 'none',
    color: '#292D32',
  },
}));
