import { OnboardingWelcomeStrings } from '../../../common/localization/en';
import BrandColorIcon from '../../../common/assets/QuickStartIcons/BrandColorIcon';
import CollectVideosIcon from '../../../common/assets/QuickStartIcons/CollectVideosIcon';

export const getStepsMethod1 = () => [
  {
    icon: <BrandColorIcon />,
    title: OnboardingWelcomeStrings.Method1Steps1Title,
    description: OnboardingWelcomeStrings.Method1Steps1Description,
  },
  {
    icon: <CollectVideosIcon />,
    title: OnboardingWelcomeStrings.Method1Steps2Title,
    description: OnboardingWelcomeStrings.Method1Steps2Description,
  },
];
