import { Box, Button, Typography, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { IPhonePreview } from '../../../common/components/IPhonePreview/IPhonePreview';
// import { CheckmarkIcon } from '../../assets/newDesign/CheckmarkIcon';
import { CheckBoxIconChecked } from '../CheckBox/CheckBox';
import { FakeTextField } from './previewComponents/FakeTextField';
import { useTypedSelector } from '../../../store';
import tinycolor from 'tinycolor2';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
import { LogoPresentation, LogoPresentationLayout } from '../../../api/models/venue';

const useStyles = makeStyles(() => ({
  tos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      fill: 'white',
    },
  },
  brush: {
    transform: 'scale(0.65)',
    position: 'absolute',
    top: '-28px',
    left: '-96px',
  },
  btnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'end',
    height: '120px',
  },
}));

interface IPhoneSignUpPreviewProps {
  phone?: string;
  phoneRequired?: boolean;
  name?: string;
  nameRequired?: boolean;
  email?: string;
  emailRequired?: boolean;
  brandColor?: string;
  logoImage?: string;
  backgroundImage?: string | null;
  bgShape?: 'straight' | 'curved' | 'fade';
  showCompanyName?: boolean;
  logoPresentation?: any;
  layout?: string;
}

export const IPhoneSignUpPreview: React.FC<IPhoneSignUpPreviewProps> = ({
  phone,
  phoneRequired,
  name,
  nameRequired,
  email,
  emailRequired,
  logoImage,
  backgroundImage,
  brandColor = '#42a5f5',
  bgShape,
  showCompanyName,
  logoPresentation,
  layout,
}) => {
  const textColor = tinycolor(brandColor).isDark() ? 'white' : 'black';
  const styles = useStyles();
  const textRef = React.useRef<HTMLDivElement>(null);
  const { isThemeLayoutEnabled, theme: layoutTheme } = useThemeLayout();
  const {
    venue: { name: companyName },
  } = useTypedSelector((state) => state.venue);

  React.useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      let fontSize = 24; // Change 24 to the default font size you want to use
      let lineHeight = 26; // Change 1.2 to the default line height you want to use
      // let textWidth = textElement.offsetWidth;
      let textHeight = 130;
      // console.log('textHeight', textHeight, textWidth);
      while (textHeight > lineHeight * 2) {
        // console.log('textHeight ', textHeight, lineHeight * 2);
        fontSize--;
        textElement.style.fontSize = `${fontSize}px`;
        // textWidth = textElement.offsetWidth;
        textHeight = textElement.offsetHeight;
      }
      textElement.style.whiteSpace = 'normal'; // Allow text to wrap
      textElement.style.textAlign = 'center';
      textElement.style.alignItems = 'center';
    }
  }, [textRef]);

  return (
    <Box>
      <IPhonePreview>
        {!isThemeLayoutEnabled && backgroundImage && (
          <img style={{ position: 'absolute' }} src={backgroundImage} alt="background" />
        )}
        <Box
          style={{
            background: isThemeLayoutEnabled ? (layoutTheme === 'light' ? '#fff' : '#000') : '#000',
            height: '100%',
            width: '100%',
          }}
        >
          {isThemeLayoutEnabled && (
            <Box style={{ position: 'absolute', height: '160px', width: '100%' }}>
              <Box
                style={
                  layout === LogoPresentationLayout.Curved
                    ? {
                        width: '120%',
                        height: ' 100%',
                        borderBottomLeftRadius: '45%',
                        borderBottomRightRadius: '45%',
                        top: '-10%',
                        left: '-10%',
                        right: '0',
                        position: 'relative',
                        overflow: 'hidden',
                      }
                    : {
                        width: '100%',
                        height: ' 100%',
                        background: isThemeLayoutEnabled
                          ? layoutTheme === 'dark'
                            ? '#000'
                            : '#fff'
                          : brandColor,
                        zIndex: '1',
                      }
                }
              >
                {layout === LogoPresentationLayout.Fade && (
                  <Box
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      height: '40px',
                      width: '100%',
                      background: `linear-gradient(0deg, ${
                        layoutTheme === 'dark' ? '#000' : '#fff'
                      }, transparent)`,
                      zIndex: '100',
                    }}
                  />
                )}
                {logoPresentation === LogoPresentation.SplashImage ? (
                  backgroundImage && (
                    <img
                      style={{ height: '100%', width: '100%' }}
                      src={backgroundImage}
                      alt="background"
                    />
                  )
                ) : (
                  <Box
                    style={{
                      position: 'absolute',
                      height: '160px',
                      width: '100%',
                      background:
                        logoPresentation === LogoPresentation.BrandColor
                          ? brandColor
                          : isThemeLayoutEnabled && layoutTheme === 'dark'
                          ? '#000'
                          : '#fff',
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
          <Box height="100%" display="flex" flexDirection="column" alignItems="center" p="8px">
            <Box
              sx={{
                width: '100%',
                height: '100%',
                zIndex: 1,
                position: 'relative',
                ...(!isThemeLayoutEnabled
                  ? {
                      padding: '25px 12px 12px',
                      background:
                        'linear-gradient(rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
                      borderRadius: '12px',
                      backdropFilter: 'blur(3.5px)',
                      overflowX: 'hidden',
                    }
                  : {}),
              }}
            >
              {/* <Box className={styles.brush}>
              <BrushShape color={brandColor} />
            </Box> */}

              <Box style={{ minHeight: '70px', marginTop: '30px', marginBottom: '68px' }}>
                {logoImage && (
                  <img
                    src={logoImage}
                    alt="logo"
                    style={{
                      height: '70px',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                )}
              </Box>

              {(!logoImage || showCompanyName) && (
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: brandColor,
                    marginBottom: '8px',
                  }}
                >
                  {companyName}
                </Typography>
              )}

              {isThemeLayoutEnabled && (
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff',
                    lineHeight: '14px',
                    textAlign: 'center',
                    marginBottom: '18px',
                  }}
                >
                  Welcome! Please enter your details
                </Typography>
              )}

              {/* <Box
              position="relative"
              height="52px"
              marginTop="10px"
              marginBottom={'80px'}
              width="100%"
              zIndex={200}
              display="flex"
              alignItems={'center'}
              justifyContent="center"
            >
              <div ref={textRef} style={{ color: 'white' }}>
                {venueName}
              </div>
            </Box> */}
              <FakeTextField
                title="Name"
                color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                value={name}
                mb="17px"
                required={true}
              />
              {emailRequired && (
                <FakeTextField
                  title="Email Address"
                  color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                  value={email}
                  mb="17px"
                />
              )}
              <Box className={styles.btnWrapper}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: brandColor,
                    fontSize: '14px',
                    color: textColor,
                    height: '42px',
                    fontWeight: 600,
                  }}
                  disabled
                >
                  Create Your Video
                </Button>
                <Box className={styles.tos} marginTop={'8px'}>
                  <Checkbox checked icon={<CheckBoxIconChecked />} sx={{ padding: '4px' }} />
                  <Typography
                    fontSize="8px"
                    color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                  >
                    You agree to our{' '}
                    <Typography
                      sx={{ textDecoration: 'underline' }}
                      component="span"
                      fontSize="8px"
                      color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                    >
                      User Terms
                    </Typography>{' '}
                    &amp;{' '}
                    <Typography
                      sx={{ textDecoration: 'underline' }}
                      component="span"
                      fontSize="8px"
                      color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                    >
                      Privacy Policy
                    </Typography>{' '}
                  </Typography>
                </Box>
              </Box>
              {/* <Typography fontSize="8px" color="white" sx={{ textAlign: 'center' }}>
              By proceeding, you agree to our{' '}
              <Typography component="span" fontSize="8px" color="white">
                Terms of Service
              </Typography>{' '}
              &amp;{' '}
              <Typography component="span" fontSize="8px" color="white">
                Privacy Policy
              </Typography>{' '}
            </Typography>
            <Typography
              fontSize="14px"
              color="white"
              fontWeight="500"
              mt="8px"
              sx={{ textAlign: 'center' }}
            >
              Already a user?{' '}
              <Typography
                component="span"
                fontSize="14px"
                color={brandColor}
                fontWeight="500"
                sx={{ textDecoration: 'underline' }}
              >
                Login
              </Typography>
            </Typography> */}
            </Box>
          </Box>
        </Box>
      </IPhonePreview>
    </Box>
  );
};
