import { FC, ReactNode, useEffect } from 'react';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../api/models/venue';
import { useHistory, useLocation } from 'react-router-dom';
import { httpClient } from '../../../services/httpClient/httpClient';
import { UnprotectedRoutes, WebsiteRoutes } from '../../constants/routes';

interface Props {
  children?: ReactNode;
}

export const QuickStartHandler: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const isLoggedIn = httpClient.isLoggedIn();

  const { value: quickStartValues, isLoading } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  useEffect(() => {
    if (
      quickStartValues?.isQuickStartInProgress &&
      quickStartValues?.currentRoute &&
      !pathname.includes(WebsiteRoutes.QuickStart) &&
      !pathname.includes(WebsiteRoutes.Profile) &&
      !pathname.includes(WebsiteRoutes.PricingPlans) &&
      !pathname.includes(UnprotectedRoutes.SignUp) &&
      isLoggedIn &&
      !isLoading
    ) {
      history.push(quickStartValues.currentRoute);
    }
  }, [history, pathname, quickStartValues, isLoggedIn, isLoading]);
  return <>{children}</>;
};
