import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const ContentContainer = styled.div<{ disablePadding?: boolean }>`
  position: relative;
  width: 100%;
  height: 110vh;
  overflow: auto;
  background-color: var(--pageBackgroundColor);
  display: flex;
  flex-direction: column;
`;
