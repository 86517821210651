import { FC, forwardRef } from 'react';
import { Dialog, DialogContent, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './Modal.helper';

const Transition = forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export interface FSModalProps {
  modalIsOpen: boolean;
  backgroundColor?: string;
  width?: string;
  onClose: () => void;
  padding?: string;
  rounded?: string;
  closeBtn?: boolean;
  blur?: boolean;
  offsetTop?: string;
  slideDirection?: string;
  styles?: any;
}

export const FSModal: FC<FSModalProps> = ({
  children,
  modalIsOpen,
  onClose,
  backgroundColor,
  width,
  padding,
  rounded,
  closeBtn = false,
  blur = false,
  offsetTop,
  slideDirection,
  styles,
}) => {
  const classes = useStyles({
    backgroundColor,
    width,
    padding,
    rounded,
    overflowY: styles?.overflowY,
  });

  const handleModalClose = () => onClose();
  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Dialog
      sx={{
        // backdropFilter: 'blur(20px)',
        paddingTop: offsetTop || '0px',
        //other styles here
        ...styles,
        zIndex: '1200',
      }}
      className={blur ? classes.blur : undefined}
      classes={{ paper: classes.paper }}
      onClose={onCloseHandler}
      open={modalIsOpen}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      TransitionComponent={slideDirection ? Transition : undefined}
      transitionDuration={slideDirection ? 600 : undefined}
    >
      {closeBtn && (
        <IconButton
          className={classes.close}
          type="button"
          aria-label="Close modal"
          onClick={handleModalClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent
        classes={{ root: classes.dialogText }}
        style={padding ? { padding } : {}}
        sx={styles}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};
