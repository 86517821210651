import { Box } from '@mui/material';
import { useStyles } from './QuickStart.styles';
import { WebsiteRoutes } from '../../common/constants/routes';
import { Route } from 'react-router-dom';
import { Personalize } from './Personalize/Personalize';
import { useAppDispatch, useTypedSelector } from '../../store';
import { useEffect } from 'react';
import { getProperties, getVenue } from '../../store/slices/venueSlice';
import Complete from './Complete/Complete';

export const QuickStart = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { selectedVenueId } = useTypedSelector((state) => state.venue);
  const { items } = useTypedSelector((state) => state.OrganizationVenueSlice);

  useEffect(() => {
    const id = selectedVenueId || items[0]?.id;
    if (id) {
      dispatch(getVenue({ id }));
      dispatch(getProperties({ id }));
    }
  }, [dispatch, items, selectedVenueId]);

  return (
    <Box className={classes.container}>
      <Route
        path={[WebsiteRoutes.QuickStartPersonalize, `${WebsiteRoutes.QuickStartPersonalize}/*`]}
      >
        <Personalize />
      </Route>
      <Route path={[WebsiteRoutes.QuickStartComplete, `${WebsiteRoutes.QuickStartComplete}/*`]}>
        <Complete />
      </Route>
    </Box>
  );
};
