import React, { FC, useCallback, useEffect } from 'react';
import { FSModal } from '../modal/Modal';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useAddToWebsiteModalStyles } from './AddToWebsiteModal.style';
import { CustomizationSocialVerse } from '../../localization/en';
import { CustomButton } from '../customButton/customButton';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useToasts } from 'react-toast-notifications';
import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import {
  getHeaderColoredText,
  getBodyColoredText,
  getHtmlColoredText,
} from './AddToWebsiteModal.helper';
import { AccordionWithoutState } from '../AccordionWithoutState/AccordionWithoutState';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ViewMode } from '../ViewMode/ViewMode';

interface AddToWebsiteModalProps {
  isOpen: boolean;
  socialverse: SocialVerseAllApiModel;
  onClose: (event?: React.SyntheticEvent) => void;
  actualSocialverseName: string;
  onCopyHeaderClick?: () => void;
  onCopyBodyClick?: () => void;
}

export const AddToWebsiteModal: FC<AddToWebsiteModalProps> = ({
  isOpen,
  onClose,
  socialverse,
  actualSocialverseName,
  onCopyHeaderClick,
  onCopyBodyClick,
}) => {
  const classes = useAddToWebsiteModalStyles();
  const { addToast } = useToasts();

  const headerCodeScript = `<script type='module' src='https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.esm.js'></script>
  <script nomodule src='https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.js'></script>`;

  const bodyCodeScript = `<gidget-component widget-type='${socialverse.viewMode.replace(
    'blocks',
    'block',
  )}' venue-id='${socialverse.venueId}' heading='${
    socialverse.showTitle ? socialverse.title : ''
  }' socialverse-id='${socialverse.id}' show-block-hero-name='${socialverse.showUserInfo}'/>`;

  const handleClick = useCallback(
    () => (event: MouseEvent) => {
      event.stopPropagation();
      const className = (event.target as HTMLElement)?.className;
      if (typeof className === 'string' && className.includes('MuiDialog-container')) {
        onClose();
      }
    },
    [onClose],
  );

  const handleCopyToClipboard = (data: string) => {
    navigator.clipboard.writeText(data);
    addToast('Copied successfully', { appearance: 'success' });
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      width="1265px"
      rounded="20px"
      padding="0"
      backgroundColor="#F5F6F8"
    >
      <Box className={classes.titleWrapper}>
        <Box className={classes.nameWrapper}>
          <Typography variant="body1" sx={{ color: '#8B89A0' }} className={classes.socialvName}>
            Name:{' '}
            <span
              style={{
                fontWeight: 500,
                color: 'black',
              }}
            >
              {actualSocialverseName}
            </span>
          </Typography>
          <Divider
            orientation={'vertical'}
            sx={{
              height: '25px',
              borderColor: '#DCDBE4',
              marginRight: '10px',
            }}
          />
          <ViewMode viewMode={socialverse.viewMode} />
        </Box>
        <IconButton onClick={onClose} style={{}}>
          <CloseRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>

      <Grid container className={classes.sectionsWrapper}>
        <Grid xs={5} className={classes.leftSide}>
          <Typography className={classes.subtitle}>
            {CustomizationSocialVerse.CopyAndPaste}
          </Typography>
          <Divider
            sx={{
              width: '400px',
              borderColor: '#DCDBE4',
            }}
          />

          <Box className={classes.header}>
            <Typography className={classes.title}>{CustomizationSocialVerse.HeaderCode}</Typography>
          </Box>
          <>
            <Typography className={classes.code}>{getHeaderColoredText()}</Typography>
            <Box className={classes.btnWrap}>
              <CustomButton
                onClick={() => {
                  handleCopyToClipboard(headerCodeScript);
                  onCopyHeaderClick?.();
                }}
                title={CustomizationSocialVerse.CopyHeaderCode}
                colorType="primary"
                startIcon={<ContentCopyRoundedIcon />}
                className={classes.btn}
              />
            </Box>
          </>

          <Divider
            sx={{
              width: '400px',
              borderColor: '#DCDBE4',
              marginTop: '15px',
            }}
          />
          <Box className={classes.header}>
            <Typography className={classes.title}>{CustomizationSocialVerse.BodyCode}</Typography>
          </Box>
          <>
            <Typography className={classes.code}>{getBodyColoredText(socialverse)}</Typography>
            <Box className={classes.btnWrap}>
              <CustomButton
                onClick={() => {
                  handleCopyToClipboard(bodyCodeScript);
                  onCopyBodyClick?.();
                }}
                title={CustomizationSocialVerse.CopyBodyCode}
                colorType="primary"
                startIcon={<ContentCopyRoundedIcon />}
                className={classes.btn}
              />
            </Box>
          </>
        </Grid>
        <Grid xs={7} className={classes.rightSide}>
          <AccordionWithoutState
            title={CustomizationSocialVerse.BodyCode2}
            defaultOpen
            divider
            marginTop="10px"
            marginBottom="15px"
          >
            <>
              <Box className={classes.accordionItemWrap} mt="20px">
                <Typography className={classes.numbers}>{1}</Typography>
                <Typography className={classes.itemTitle}>
                  {CustomizationSocialVerse.GoAndInsert1}
                  <strong>{CustomizationSocialVerse.GoAndInsert2}</strong>
                  {CustomizationSocialVerse.GoAndInsert3}
                </Typography>
              </Box>
              <Box className={classes.accordionItemWrap}>
                <Typography className={classes.numbers}>{2}</Typography>
                <Typography className={classes.itemTitle}>
                  {CustomizationSocialVerse.PasteCodeThenSave1}
                  <strong>{CustomizationSocialVerse.PasteCodeThenSave2}</strong>
                  {CustomizationSocialVerse.PasteCodeThenSave3}
                </Typography>
              </Box>
              <Box className={classes.html}>{getHtmlColoredText(socialverse)}</Box>
            </>
          </AccordionWithoutState>

          {/*          <AccordionWithoutState
            title={CustomizationSocialVerse.Webflow}
            defaultOpen={false}
            divider
            marginBottom="15px"
            marginTop="10px"
          ></AccordionWithoutState>
          <AccordionWithoutState
            title={CustomizationSocialVerse.Wordpress}
            defaultOpen={false}
            divider
            marginBottom="15px"
            marginTop="10px"
          ></AccordionWithoutState>*/}
        </Grid>
      </Grid>
    </FSModal>
  );
};
