import { defaultPagination } from './../../common/constants/constants';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { getApiUrlForId, searchFilterEndpoints } from '../../api/endpoints';
import {
  creationDate,
  GetSearchFiltersRequest,
  PostSearchFiltersRequest,
  SearchFilterApiModel,
  SearchFilterAttributes,
  SearchOperations,
  SearchTargetsType,
} from '../../api/models/searchFilter';
import { RangeFilterDTO } from '../storeModels';
import { getUTCDateString } from '../../services/utilities';

interface SearchFilterInitalState {
  items: SearchFilterApiModel[];
  isLoading: Boolean;
  isError: Boolean;
  activeFilter: SearchFilterApiModel | null;
  rangeFilter: RangeFilterDTO | null;
  newFilterAttributes: SearchFilterAttributes[];
  target: SearchTargetsType | null;
  page: number;
  size: number;
  previousPage: number;
  previousSize: number;
}

const initialState: SearchFilterInitalState = {
  items: [],
  isLoading: false,
  isError: false,
  activeFilter: null,
  rangeFilter: null,
  newFilterAttributes: [],
  target: null,
  page: defaultPagination.page,
  size: defaultPagination.size,
  previousPage: defaultPagination.page,
  previousSize: defaultPagination.size,
};

export const getSearchFilters = createAsyncThunk(
  'searchFilter/getsearchFilters',
  async (options: GetSearchFiltersRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetSearchFiltersRequest, SearchFilterApiModel[]>({
        url: searchFilterEndpoints.GetSearchFilters,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteSearchFilterById = createAsyncThunk(
  'searchFilter/deleteSearchFilterById',
  async (id: string, { rejectWithValue }) => {
    try {
      return await httpClient.delete<string, { id: string }>({
        url: getApiUrlForId(searchFilterEndpoints.DeleteSearchFilters, id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postSearchFilters = createAsyncThunk(
  'searchFilter/postsearchFilters',
  async (options: PostSearchFiltersRequest, { rejectWithValue }) => {
    try {
      return await httpClient.post<PostSearchFiltersRequest, SearchFilterApiModel>({
        url: searchFilterEndpoints.GetSearchFilters,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const searchFilterSlice = createSlice({
  name: 'searchFilter',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.previousPage = state.page;
      state.page = action.payload;
    },
    resetError(state, action: PayloadAction<boolean>) {
      state.isError = action.payload;
    },
    setActiveFilter(state, action: PayloadAction<SearchFilterApiModel | null>) {
      state.activeFilter = action.payload;
    },
    resetActiveFilter(state) {
      state.activeFilter = initialState.activeFilter;
    },
    updateFilter(state, action: PayloadAction<RangeFilterDTO | null>) {
      state.rangeFilter = action.payload;
      if (!action.payload) {
        state.newFilterAttributes = [
          ...state.newFilterAttributes.filter((item) => item.name !== creationDate),
        ];
      }
      if (action.payload?.from) {
        const currentAttribute = state.newFilterAttributes.findIndex(
          (item) => item.name === creationDate && item.operation === SearchOperations.GREATER_THAN,
        );
        if (currentAttribute === -1) {
          state.newFilterAttributes = [
            ...state.newFilterAttributes,
            {
              name: creationDate,
              operation: SearchOperations.GREATER_THAN,
              value: getUTCDateString(action.payload?.from),
            },
          ];
        } else {
          state.newFilterAttributes[currentAttribute] = {
            name: creationDate,
            operation: SearchOperations.GREATER_THAN,
            value: getUTCDateString(action.payload?.from),
          };
        }
      }
      if (action.payload?.to) {
        const currentAttribute = state.newFilterAttributes.findIndex(
          (item) => item.name === creationDate && item.operation === SearchOperations.LESS_THAN,
        );
        if (currentAttribute === -1) {
          state.newFilterAttributes = [
            ...state.newFilterAttributes,
            {
              name: creationDate,
              operation: SearchOperations.LESS_THAN,
              value: getUTCDateString(action.payload?.to),
            },
          ];
        } else {
          state.newFilterAttributes[currentAttribute] = {
            name: creationDate,
            operation: SearchOperations.LESS_THAN,
            value: getUTCDateString(action.payload?.to),
          };
        }
      }
    },
    resetNewFilter(state) {
      state.newFilterAttributes = [];
      state.activeFilter = null;
    },
    updateNewSearchFilterAttributes(state, action: PayloadAction<SearchFilterAttributes>) {
      const currentAttribute = state.newFilterAttributes.findIndex(
        (item) => item.name === action.payload.name,
      );
      if (currentAttribute === -1) {
        state.newFilterAttributes = [...state.newFilterAttributes, action.payload];
      } else {
        state.newFilterAttributes[currentAttribute] = action.payload;
      }
    },
    removeSearchFilterAttribute(state, action: PayloadAction<string>) {
      const update = state.newFilterAttributes.filter((item) => item.name !== action.payload);
      state.newFilterAttributes = [...update];
    },
    setTarget(state, action: PayloadAction<SearchTargetsType>) {
      state.target = action.payload;
    },
    addSeveralFilterAttributes(state, action: PayloadAction<SearchFilterAttributes[]>) {
      state.newFilterAttributes = [...action.payload];
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(postSearchFilters.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(postSearchFilters.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(postSearchFilters.fulfilled, (state, { payload }) => {
      state.isError = false;
      state.isLoading = false;
      state.items = [...state.items, payload];
      state.activeFilter = payload;
    });
    reducersBuilder.addCase(getSearchFilters.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getSearchFilters.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getSearchFilters.fulfilled, (state, { payload }) => {
      state.isError = false;
      state.isLoading = false;
      state.items = payload;
    });
    reducersBuilder.addCase(deleteSearchFilterById.fulfilled, (state, { payload }) => {
      state.items = state.items.filter((item) => item.id !== payload.id);
    });
  },
});

export const {
  goToSelectedPage,
  resetError,
  setActiveFilter,
  resetActiveFilter,
  updateFilter,
  resetNewFilter,
  updateNewSearchFilterAttributes,
  removeSearchFilterAttribute,
  setTarget,
  addSeveralFilterAttributes,
} = searchFilterSlice.actions;
export default searchFilterSlice.reducer;
