import { Box, Divider } from '@mui/material';
import { useState, FC } from 'react';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import { ThumbnailFrame } from './EditThumbnail';
import { useThumbnailCustomizationStyles } from './ThumbnailCustomization.style';

interface ThumbnailCustomizationProps {
  onFrameSelect: (frame: ThumbnailFrame) => void;
  frames: ThumbnailFrame[];
}
export const ThumbnailCustomization: FC<ThumbnailCustomizationProps> = ({
  onFrameSelect,
  frames,
}) => {
  const [selectedFrame, setSelectedFrame] = useState<ThumbnailFrame>();
  const classes = useThumbnailCustomizationStyles();
  const selectFrame = (frame: ThumbnailFrame) => {
    setSelectedFrame(frame);
    onFrameSelect(frame);
  };
  const dummyArray = [1, 2, 3, 4, 5];
  return (
    <div>
      <Divider style={{ borderColor: '#DDDCE4' }} />
      <Box className={classes.framesContainer}>
        {frames && frames.length
          ? frames.map((frame, key) => (
              <div
                onClick={() => selectFrame(frame!)}
                key={key}
                className={[
                  frame.thumbnail === selectedFrame?.thumbnail
                    ? classes.framesContainerItemActive
                    : '',
                  classes.framesContainerItem,
                ].join(' ')}
              >
                <Box
                  component="img"
                  src={frame.thumbnail.src || ''}
                  width={104}
                  style={{ aspectRatio: '9 / 16' }}
                  alt="thumbnail"
                />
              </div>
            ))
          : dummyArray.map((key) => {
              return (
                <div key={key} className={classes.framesContainerItem}>
                  <Skeleton
                    className={classes.skeleton}
                    variant="rectangular"
                    width={104}
                    height={184}
                    animation="wave"
                  />
                  <CircularProgress disableShrink className={classes.circularProgress} />
                </div>
              );
            })}
      </Box>
    </div>
  );
};
