import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { FC } from 'react';

import { Tooltip as TableTooltip, TooltipProps } from './../table/Tooltip';

import { useTooltipStyles } from './Tooltip.style';

export const Tooltip: FC<TooltipProps> = ({ title, ...rest }) => {
  const styles = useTooltipStyles();

  return (
    <TableTooltip
      padding="23px 25px"
      rounded="15px"
      title={title}
      icon={<QuestionMarkRoundedIcon className={styles.Icon} />}
      {...rest}
    />
  );
};
