import { Box } from '@mui/material';
import { CSSProperties, FC, useState } from 'react';
import { PlayIcon } from '../../assets/PlayIcon';
import { HtmlTooltip, TooltipProps } from '../table/Tooltip';
import { useClientType } from '../../../services/hooks/useClientType';
import { CampaignUser } from '../../../api/models/campaigns';
import { VideoApiModel } from '../../../api/models/videos';
import { TooltipVideoPlaybackModal } from '../NewDetailsModal/DetailsModalContent/TooltipVideoPlaybackModal';
import { getVideoPoster } from '../../../services/utilities';

interface CustomColorTooltipProps extends TooltipProps {
  onClick?: () => void;
  creator?: CampaignUser;
  video: VideoApiModel;
  style?: CSSProperties;
}

export const PlayVideoTooltip: FC<CustomColorTooltipProps> = ({
  video,
  title,
  text,
  onClick,
  style,
  ...rest
}) => {
  const { isTopGolfClient } = useClientType();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [, setIsPlaying] = useState(false);

  const handleOpenPlayVideoModal = () => {
    setIsOpen(!isOpen);
    setIsPlaying(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsPlaying(false);
  };
  return (
    <Box onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <HtmlTooltip
        interactive={false}
        followCursor={false}
        pointerEvents={true}
        style={style}
        title={
          <Box
            sx={{
              padding: '8px 12px',
              cursor: 'pointer',
            }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              handleOpenPlayVideoModal();
              setOpen(false);
            }}
          >
            <Box
              sx={{
                borderRadius: '5px',
                width: '40px',
                height: '60px',
              }}
              component={'img'}
              src={getVideoPoster(video.url)}
            />
          </Box>
        }
        rounded={'8px'}
        padding="0px"
        light={true}
        isOpen={open}
      >
        <Box mr="10px">
          <PlayIcon />
        </Box>
      </HtmlTooltip>
      <TooltipVideoPlaybackModal
        isOpen={isOpen}
        isTopGolf={isTopGolfClient}
        storyUrl={video.url}
        onClose={handleClose}
      />
    </Box>
  );
};
