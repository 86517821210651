import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
  },
  closeIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    margin: '15px auto 45px',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: '0.36px',
  },
}));
