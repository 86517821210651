import { makeStyles } from '@mui/styles';

const drawerWidth = 260;

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.deepDark,
  },
  docked: {
    width: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 80,
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexShrink: 0,
    flexGrow: 0,
    marginBottom: '20px',
    marginTop: '20px',
    '&>svg': {
      width: 150,
      height: 50,
      flexShrink: 0,
      flexGrow: 0,
    },
  },
}));
