import { useStyles } from './Campaigns.styles';
import { Box } from '@mui/material';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import { CommonStrings } from '../../../common/localization/en';
import { WebsiteRoutes } from '../../../common/constants/routes';
import React, { FC, useEffect, useState } from 'react';
import { LeafIcon } from '../../../common/assets/LeafIcon';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../../common/constants/constants';
import { CreateCampaignsTableContent } from './Campaigns.helper';
import {
  getCampaigns,
  getPrimaryCampaign,
  goToSelectedPage,
  patchIncentiveCampaign,
  setCampaignsSorting,
  updateRequestsSize,
} from '../../../store/slices/campaignsSlice';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { CommonTable } from '../../../common/components/table/CommonTable';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { ConfirmPrimaryModal } from '../../../common/components/ConfirmPrimaryModal/ConfirmPrimaryModal';
import { getApiUrlForId } from '../../../api/endpoints';

export const Campaigns: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;
  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
    lastUpdated,
  } = useTypedSelector((state) => state.campaigns);

  const venueId = useTypedSelector((state) => state.venue.venue.id);
  const [isConfirmPrimaryModalOpen, setIsConfirmPrimaryModalOpen] = useState(false);
  const [primaryCampaignId, setPrimaryCampaignId] = useState('');

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (venueId) {
      dispatch(getCampaigns({ search, venueId, pageable: { page, size, sort } }));
    }
  }, [dispatch, page, size, sort, search, venueId, lastUpdated]);

  const tableContents = CreateCampaignsTableContent({
    items,
    onOpenCampaign: () => {},
    isEditButton: true,
    isMobile,
    onSetPrimaryCampaign: (campaignId: string) => {
      setIsConfirmPrimaryModalOpen(true);
      setPrimaryCampaignId(campaignId);
    },
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setCampaignsSorting(name));
    trackEvent(RewardCampaignsEvents.RewardCampaignsTableSort.replace('name', name));
  };

  const changeSize = (size: number) => {
    dispatch(updateRequestsSize(size));
  };

  const handleConfirmPrimary = async () => {
    setIsConfirmPrimaryModalOpen(false);
    await dispatch(
      patchIncentiveCampaign({
        campaignId: primaryCampaignId,
        values: {
          isPreferred: true,
        },
      }),
    );
    await dispatch(getCampaigns({ search, venueId, pageable: { page, size, sort } }));
    dispatch(getPrimaryCampaign({ venueId }));
  };

  return (
    <Box className={classes.container}>
      <ConfirmPrimaryModal
        isOpen={isConfirmPrimaryModalOpen}
        onClose={() => {
          setIsConfirmPrimaryModalOpen(false);
        }}
        onCancel={() => {
          setIsConfirmPrimaryModalOpen(false);
        }}
        onConfirm={handleConfirmPrimary}
      />
      <Box className={classes.btnWrapper}>
        <RoundedButton
          title={CommonStrings.CreateNewCampaign}
          startIcon={<LeafIcon />}
          sx={{
            padding: '10px 35px',
          }}
          onClick={() => {
            history.push(WebsiteRoutes.NewCampaign);
            trackEvent(RewardCampaignsEvents.RewardCampaignsNewRewardClick);
          }}
          id="create-new-campaign-button"
        />
      </Box>
      <Box className={classes.table}>
        <CommonTable
          content={tableContents}
          page={convertApiPageToFrontEndPage(page)}
          sort={sort}
          totalItems={totalItems}
          totalPages={totalPages}
          isLoading={isLoading}
          noContent={error}
          goToPage={onGoToPage}
          onSortHeaderClick={onSort}
          size={size}
          onSizeChange={changeSize}
          handleCellClick={(rowId, cellIndex) => {
            if (cellIndex !== 7) {
              history.push(getApiUrlForId(WebsiteRoutes.CampaignDetails, rowId));
            }
          }}
        />
      </Box>
    </Box>
  );
};
