import { LoadingButton } from '@mui/lab';
import { Box, Divider, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import * as xlsx from 'xlsx';
import { CreateTransactionBatchResponse, Records } from '../../../../api/models/transaction';
import { MatchProperties } from '../../../../common/components/DataSync/MatchProperties/MatchProperties';
import { Dropzone } from '../../../../common/components/Dropzone/Dropzone';
import { FSModal } from '../../../../common/components/modal/Modal';
import { CommonEventNames } from '../../../../common/constants/events/common';
import { DataSyncStrings, ImportFileModalStrings } from '../../../../common/localization/en';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { getFileExtension } from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { postTransactionBatch, putTransactionBatch } from '../../../../store/slices/dataSyncSlice';
import { useStyles } from './ImportFileModal.styles';

import ImportModalExceedLimit from '../../../../common/components/DataSync/MatchProperties/ImportModalExceedLimit';
import { useModal } from '../../../../hooks/useModal';
import { findMatch, validationSchema } from '../../../webappSettings/DataSync/DataSync.helper';
import { getCampaignCountData } from '../../../../store/slices/campaignsSlice';
import { ExelInviteImport } from '../../../../common/components/ExelInviteImport/ExelInviteImport';
import { SMSInvitationType } from '../../../../common/components/SMSInvitationTypeSelector/SMSInvitationTypeSelector';

interface ImportFileModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignId: string;
}

export const ImportFileModal: FC<ImportFileModalProps> = ({ isOpen, onClose, campaignId }) => {
  const classes = useStyles();
  const { id } = useTypedSelector((state) => state.venue.venue);
  const { isLoading } = useTypedSelector((state) => state.dataSyncSlice.data);

  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const {
    isOpen: isImportEventsModalExceedLimit,
    close: closeImportEventsModalExceedLimit,
    open: openImportEventsModalExceedLimit,
  } = useModal();

  const [uploadedCSVHeaders, setUploadedCSVHeaders] = useState<string[] | null>(null);
  const [uploadedCSVData, setUploadedCSVData] = useState<string[][] | null>(null);
  const [step, setStep] = useState(0);
  const [fileName, setFileName] = useState<string>();
  const [createdTransactionalBatch, setCreatedTransactionalBatch] =
    useState<CreateTransactionBatchResponse | null>(null);

  const formik = useFormik({
    initialValues: {
      firstName: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'firstName') : '',
      lastName: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'lastName') : '',
      cell: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'cell') : '',
      email: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'email') : '',
      transactionDate: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'transactionDate') : '',
      optInOut: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'optInOut') : '',
      refId: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'refId') : undefined,
      additionalProp1: uploadedCSVHeaders
        ? findMatch(uploadedCSVHeaders, 'additionalProp1')
        : undefined,
      additionalProp2: uploadedCSVHeaders
        ? findMatch(uploadedCSVHeaders, 'additionalProp2')
        : undefined,
      additionalProp3: uploadedCSVHeaders
        ? findMatch(uploadedCSVHeaders, 'additionalProp3')
        : undefined,
      smsInvitationType: SMSInvitationType.SAVE_FOR_LATER,
    },
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: validationSchema,
    validateOnChange: true,
  });

  const getFields = useMemo(() => {
    const fieldsEntries = Object.entries(validationSchema().describe().fields);

    return [
      fieldsEntries
        .filter(
          ([key, _]) =>
            (validationSchema().describe().fields[key] as any).tests.findIndex(
              ({ name }: { name: string }) => name === 'required',
            ) >= 0,
        )
        .map((el) => el[0]),
      fieldsEntries
        .filter(([key, _]) => (validationSchema().describe().fields[key] as any).tests.length === 0)
        .map((el) => el[0]),
    ];
  }, []);
  const getRecords = useCallback(() => {
    if (!uploadedCSVData || !uploadedCSVHeaders) {
      return;
    }
    const findCorrespondingHeaderIndex = (name: string) => uploadedCSVHeaders.indexOf(name);
    return (
      uploadedCSVData[uploadedCSVData.length - 1].length === 1
        ? uploadedCSVData.slice(0, -1)
        : uploadedCSVData
    )
      .map((row: string[]) => {
        const newRow = {
          phoneNumber: formik.values.cell
            ? row[findCorrespondingHeaderIndex(formik.values.cell)]
            : '',
          firstName: Boolean(formik.values.firstName)
            ? row[findCorrespondingHeaderIndex(formik.values.firstName as string)]
            : '',
          lastName: formik.values.lastName
            ? row[findCorrespondingHeaderIndex(formik.values.lastName)]
            : '',
          transactionDate: formik.values.transactionDate
            ? row[findCorrespondingHeaderIndex(formik.values.transactionDate)]
            : format(new Date(), 'MM/dd/yyyy'),
          email: formik.values.email
            ? row[findCorrespondingHeaderIndex(formik.values.email)]
            : undefined,
          refId: formik.values.refId ? row[findCorrespondingHeaderIndex(formik.values.refId)] : '',
          optedIn: formik.values.optInOut
            ? row[findCorrespondingHeaderIndex(formik.values.optInOut)] === 'false' ||
              row[findCorrespondingHeaderIndex(formik.values.optInOut)] === 'No'
              ? false
              : true
            : true,

          attributes: {
            additionalProp1: formik.values.additionalProp1
              ? row[findCorrespondingHeaderIndex(formik.values.additionalProp1)]
              : '',
            additionalProp2: formik.values.additionalProp2
              ? row[findCorrespondingHeaderIndex(formik.values.additionalProp2)]
              : '',
            additionalProp3: formik.values.additionalProp3
              ? row[findCorrespondingHeaderIndex(formik.values.additionalProp3)]
              : '',
          },
        };
        const validRow =
          Boolean(newRow.firstName) &&
          Boolean(newRow.lastName) &&
          Boolean(newRow.phoneNumber) &&
          newRow.optedIn;
        if (!validRow) return null;
        return newRow;
      })
      .filter((r) => r);
  }, [uploadedCSVData, uploadedCSVHeaders, formik.values]);

  const handleImportClick = () => {
    moveToSubmitModal(formik.values.smsInvitationType === SMSInvitationType.SEND_IMMEDIATELY);
    trackEvent(CommonEventNames.data_sync_import_data_click);
    onClose();
  };

  const createTransactionalBatch = async (name: string) => {
    const records = getRecords();
    if (!records) {
      return;
    }

    const { payload } = await dispatch(
      postTransactionBatch({
        name,
        records: records as Records,
        venueId: id,
        campaignId,
      }),
    );
    return payload as CreateTransactionBatchResponse;
  };

  const moveToSubmitModal = async (autoApprove?: boolean) => {
    // if autoApprove is set -> should create transactional batch on import modal open
    let response;

    const batchName = `${new Date().toDateString()} import`;

    const batch = await createTransactionalBatch(batchName);
    response = batch;

    if (!batch) return;

    if (autoApprove) {
      handleSendImportedReport(batchName, batch);
    }

    setCreatedTransactionalBatch(batch);

    if (response && response.numValidRecords > 750) {
      openImportEventsModalExceedLimit();
      return;
    }
  };

  const handleCloseImportEventsModalExceedLimit = () => {
    closeImportEventsModalExceedLimit();
  };

  const handleSendImportedReport = async (
    name: string,
    batch?: CreateTransactionBatchResponse | null,
  ) => {
    if (createdTransactionalBatch || batch) {
      const id = (createdTransactionalBatch?.id || batch?.id)!;
      // update created batch name and approve
      /* await dispatch(updateTransactionBatch({ id, name, campaignId })); */
      await dispatch(putTransactionBatch({ id }));
    } else {
      // default data-sync -> create new transactional batch
      await createTransactionalBatch(name);
    }

    setUploadedCSVHeaders(null);
    setUploadedCSVData(null);

    addToast(DataSyncStrings.ToastSuccess, { appearance: 'success' });

    onClose();
    handleClose();

    dispatch(getCampaignCountData({ campaignId }));
  };

  const parseCsv = (csvData: string) => {
    const processedData = csvData.replace(/"/g, ''); // remove quotes from strings (for example, from links)
    const lines = processedData.split(/\r?\n|\r|\n/g);

    const [headers, ...tableRows] = lines.map((line) => line.split(','));
    setUploadedCSVHeaders(headers);
    setUploadedCSVData(tableRows);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      trackEvent(CommonEventNames.data_sync_file_uploaded, { value: acceptedFiles });
      const file = acceptedFiles[0];
      const fileExtension = getFileExtension(file.name);
      setFileName(file.name);

      const isExcelFile = fileExtension ? ['xlsx', 'xls'].includes(fileExtension) : false;
      const isCsv = file.type === 'text/csv';

      if (!isExcelFile && !isCsv) {
        addToast(DataSyncStrings.SelectedWrongFileType, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (e: any) {
        const data = e.target.result;

        let csvData = data;

        // convert excel file to csv
        if (isExcelFile) {
          const wb = xlsx.read(data, { type: 'binary' });
          const ws = wb.Sheets[wb.SheetNames[0]]; // Get first worksheet
          csvData = xlsx.utils.sheet_to_csv(ws);
        }

        parseCsv(csvData);
      };

      return file;
    },
    [addToast, trackEvent],
  );

  const handleClose = () => {
    setStep(0);
    setFileName(undefined);
    setUploadedCSVData(null);
    setUploadedCSVHeaders(null);
    setCreatedTransactionalBatch(null);
  };

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  return (
    <FSModal
      onClose={() => {
        onClose();
        handleClose();
      }}
      modalIsOpen={isOpen}
      width="640"
      rounded="15px"
      closeBtn
      padding="0"
    >
      <FSModal
        onClose={handleCloseImportEventsModalExceedLimit}
        modalIsOpen={isImportEventsModalExceedLimit}
        rounded="16px"
        blur
        width="500px"
        padding="16px"
      >
        <ImportModalExceedLimit close={handleCloseImportEventsModalExceedLimit} />
      </FSModal>
      <Box className={classes.inputsWrapper}>
        <Typography className={classes.title}>{ImportFileModalStrings.Title}</Typography>
        <ExelInviteImport linkOnly />
        <Divider color="#DCDBE4" className={classes.divider} />
        <Box className={classes.content}>
          {step === 0 && (
            <>
              <Dropzone
                onDrop={onDrop}
                accept={['excel', 'csv']}
                whiteBackground
                withBorder={true}
                padding={'200px 82px'}
                height={'auto'}
                borderRadius={'10px'}
                newCampaignLayoutMenuImport
                title={ImportFileModalStrings.DropzoneTitle}
                description={ImportFileModalStrings.DropzoneDesc}
              />
              <Box className={classes.importTable}>
                <Box className={classes.headers}>
                  <Typography
                    style={{ width: '200%' }}
                    className={`${classes.subTitle} ${classes.labelsHeader}`}
                  >
                    {ImportFileModalStrings.ImportName}
                  </Typography>
                  <Typography className={`${classes.subTitle} ${classes.labelsHeader}`}>
                    {ImportFileModalStrings.ValidRecords}
                  </Typography>
                  <Typography className={`${classes.subTitle} ${classes.labelsHeader}`}>
                    {ImportFileModalStrings.Action}
                  </Typography>
                </Box>
                <Box className={classes.row}>
                  <Typography
                    style={{
                      width: '200%',
                      paddingRight: '12px',
                      border: '1px solid DCDBE4',
                    }}
                    className={`${classes.subTitle} ${classes.values}`}
                  >
                    {fileName}
                  </Typography>
                  <Typography
                    style={{ borderRight: '1px solid DCDBE4' }}
                    className={`${classes.subTitle} ${classes.values}`}
                  >
                    {getRecords()?.length}
                  </Typography>
                  <Typography className={`${classes.subTitle} ${classes.values}`}></Typography>
                </Box>
              </Box>
              <LoadingButton
                disabled={!uploadedCSVData}
                variant="contained"
                color="primary"
                size="large"
                style={{ borderRadius: '8px', marginTop: '32px' }}
                fullWidth
                onClick={() => {
                  trackEvent(CommonEventNames.add_users_manually_import_data_button_click);
                  setStep(1);
                }}
                id="match-properties-button"
              >
                {ImportFileModalStrings.NextMatchPropertiesBtn}
              </LoadingButton>
            </>
          )}
          {step === 1 && (
            <MatchProperties
              isLoading={isLoading}
              onImportClick={handleImportClick}
              formik={formik}
              uploadedCSV={uploadedCSVHeaders ?? []}
              getFields={getFields}
              quickStartLayout
              moveToPrevStep={() => {}}
              newCampaignLayoutMenuImport
            />
          )}
        </Box>
      </Box>
    </FSModal>
  );
};
