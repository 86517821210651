import { FC } from 'react';

export const NextIcon: FC = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 13L7.00013 6.99991L1 1"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 13L7.00013 6.99991L1 1"
      stroke="#5242EA"
      strokeOpacity="0.3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
