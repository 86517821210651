import * as React from 'react';
import { SVGProps } from 'react';
const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={126}
    height={126}
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M63 10C69.9601 10 76.852 11.3709 83.2822 14.0344C89.7125 16.6979 95.5552 20.6018 100.477 25.5233C105.398 30.4449 109.302 36.2875 111.966 42.7178C114.629 49.148 116 56.0399 116 63C116 69.9601 114.629 76.852 111.966 83.2822C109.302 89.7125 105.398 95.5552 100.477 100.477C95.5551 105.398 89.7125 109.302 83.2822 111.966C76.8519 114.629 69.96 116 63 116C56.0399 116 49.148 114.629 42.7178 111.966C36.2875 109.302 30.4448 105.398 25.5233 100.477C20.6018 95.5551 16.6979 89.7125 14.0344 83.2822C11.3709 76.8519 10 69.96 10 63C10 56.0399 11.3709 49.148 14.0344 42.7178C16.6979 36.2875 20.6019 30.4448 25.5234 25.5233C30.4449 20.6018 36.2876 16.6979 42.7178 14.0344C49.1481 11.3709 56.04 10 63 10L63 10Z"
      stroke="#F2F4F7"
      strokeWidth={20}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M113.424 45.1095C117.169 55.5583 117.54 66.9186 114.487 77.5898C111.433 88.2609 105.108 97.705 96.4036 104.592C87.6988 111.478 77.0526 115.46 65.9652 115.976C54.8778 116.492 43.9079 113.516 34.6012 107.467C25.2944 101.419 18.12 92.6032 14.0886 82.2618C10.0573 71.9203 9.37223 60.5746 12.13 49.8233C14.8878 39.0719 20.9494 29.4568 29.4608 22.3326C37.9722 15.2085 48.5044 10.9344 59.5733 10.1126"
      stroke="#CFB8EE"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M113.424 45.1095C117.169 55.5583 117.54 66.9186 114.487 77.5898C111.433 88.2609 105.108 97.705 96.4036 104.592C87.6988 111.478 77.0526 115.46 65.9652 115.976C54.8778 116.492 43.9079 113.516 34.6012 107.467C25.2944 101.419 18.12 92.6032 14.0886 82.2618C10.0573 71.9203 9.37223 60.5746 12.13 49.8233C14.8878 39.0719 20.9494 29.4568 29.4608 22.3326C37.9722 15.2085 48.5044 10.9344 59.5733 10.1126"
      stroke="#5242EA"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.5442 17.9886C96.7269 21.2177 101.313 25.3176 105.1 30.1076"
      stroke="#BAB3FE"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.4571 11.3325C75.7817 11.4076 76.1056 11.4858 76.4288 11.5671"
      stroke="#7F56D9"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.4571 11.3325C75.7817 11.4076 76.1056 11.4858 76.4288 11.5671"
      stroke="#372F86"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_0_1"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={36}
      width={55}
      height={55}
    >
      <circle cx={63.5} cy={63.5} r={27.5} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask0_0_1)">
      <circle cx={63.5} cy={63.5} r={26.8} fill="#CFB8EE" stroke="#A69DF7" strokeWidth={1.4} />
      <ellipse
        cx={63.2832}
        cy={63.4355}
        rx={18.6575}
        ry={18.6575}
        transform="rotate(-0.12231 63.2832 63.4355)"
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeOpacity={0.5}
        strokeWidth={1.4}
      />
      <path
        d="M70.8848 62.8866L60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866Z"
        fill="#5242EA"
        stroke="#A69DF7"
        strokeWidth={1.4}
      />
    </g>
    <mask
      id="mask1_0_1"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={36}
      width={55}
      height={55}
    >
      <circle cx={63.5} cy={63.5} r={27.5} fill="#CFB8EE" />
    </mask>
    <g mask="url(#mask1_0_1)">
      <circle cx={63.5} cy={63.5} r={27.5} fill="#CFB8EE" />
      <ellipse
        cx={63.2832}
        cy={63.4355}
        rx={18.6575}
        ry={18.6575}
        transform="rotate(-0.12231 63.2832 63.4355)"
        fill="#EEECFD"
      />
      <path
        d="M70.8848 62.8866L60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866Z"
        fill="#5242EA"
        stroke="#5242EA"
        strokeWidth={1.4}
      />
    </g>
    <circle cx={63.5} cy={63.5} r={26.8} fill="#D9D4FF" stroke="#5242EA" strokeWidth={1.4} />
    <ellipse
      cx={63.2832}
      cy={63.4355}
      rx={18.6575}
      ry={18.6575}
      transform="rotate(-0.12231 63.2832 63.4355)"
      fill="#EEECFD"
      stroke="#A69DF7"
      strokeWidth={1.4}
    />
    <path
      d="M70.8848 62.8866L60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866Z"
      fill="#5242EA"
      stroke="#A69DF7"
      strokeWidth={1.4}
    />
    <mask
      id="mask2_0_1"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={36}
      width={55}
      height={55}
    >
      <circle cx={63.5} cy={63.5} r={27.5} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask2_0_1)">
      <circle cx={63.5} cy={63.5} r={27.5} fill="#D9D4FF" />
      <circle cx={63.5} cy={63.5} r={26.7} stroke="#5242EA" strokeOpacity={0.8} strokeWidth={1.6} />
      <circle
        cx={63.2832}
        cy={63.4355}
        r={18.6575}
        transform="rotate(-0.12231 63.2832 63.4355)"
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeWidth={1.4}
      />
      <path
        d="M57.2233 63.3353L59.9274 65.2362L59.9274 54.3604C59.9274 53.502 60.6232 52.8062 61.4816 52.8062C62.3399 52.8062 63.0358 53.502 63.0358 54.3604L63.0358 59.2461C63.0358 58.4065 63.7164 57.7259 64.556 57.7259C65.3956 57.7259 66.0762 58.4065 66.0762 59.2461L66.0762 59.6835C66.0762 58.8655 66.7392 58.2025 67.5572 58.2025C68.3751 58.2025 69.0382 58.8655 69.0382 59.6835L69.0382 60.3676C69.0382 59.6071 69.6547 58.9905 70.4153 58.9905C71.1758 58.9905 71.7924 59.6071 71.7924 60.3676L71.7924 67.4115C71.7924 71.399 68.5598 74.6315 64.5723 74.6315C62.0251 74.6315 59.6664 73.2894 58.3653 71.0997L54.984 65.4093C54.6047 64.771 54.7277 63.9537 55.2781 63.4552C55.8188 62.9656 56.6266 62.9158 57.2233 63.3353Z"
        fill="#5242EA"
      />
      <path
        d="M59.9274 65.2362L57.2233 63.3353C56.6266 62.9158 55.8188 62.9656 55.2781 63.4552V63.4552C54.7277 63.9537 54.6047 64.771 54.984 65.4093L58.3653 71.0997C59.6664 73.2894 62.0251 74.6315 64.5723 74.6315V74.6315C68.5598 74.6315 71.7924 71.399 71.7924 67.4115L71.7924 60.3676C71.7924 59.6071 71.1758 58.9905 70.4153 58.9905V58.9905C69.6547 58.9905 69.0382 59.6071 69.0382 60.3676L69.0382 62.493L69.0382 59.6835C69.0382 58.8655 68.3751 58.2025 67.5572 58.2025V58.2025C66.7392 58.2025 66.0762 58.8655 66.0762 59.6835L66.0762 62.493L66.0762 59.2461C66.0762 58.4065 65.3956 57.7259 64.556 57.7259V57.7259C63.7164 57.7259 63.0358 58.4065 63.0358 59.2461L63.0358 62.493L63.0358 54.3604C63.0358 53.502 62.3399 52.8062 61.4816 52.8062V52.8062C60.6232 52.8062 59.9274 53.502 59.9274 54.3604L59.9274 65.2362Z"
        stroke="#DCDBE4"
        strokeWidth={1.3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default SVGComponent;
