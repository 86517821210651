import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useBusinessVideoStylePreviewStyles = makeStyles((theme: Theme) => ({
  Container: {
    margin: '0 auto',
    marginTop: '70px',
    width: '644px',
    height: '615px',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '20px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(82,66,234,0.05) 100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  QuestionButton: {
    marginTop: '23px',
    marginRight: '23px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    width: '19px',
    height: '19px',
    border: '1px solid',
    borderColor: theme.palette.common.gray,
    borderRadius: '50%',
    fontSize: '10px',
    filter: 'none',
  },
  Title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '4px',
  },
  SubTitle: {
    width: '480px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  Diagram: {
    position: 'relative',
    marginBottom: '30px',
  },
  DiagramLegend: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  DiagramLegendTitle: {
    width: '200px',
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '44px',
    color: theme.palette.common.black,
    textAlign: 'center',
    letterSpacing: '-0.02em',
  },
  DiagramLegendSubTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.common.gray,
    marginBottom: '12px',
  },
  DiagramLegendIndicator: {
    width: '30px',
    height: '22px',
    borderRadius: '16px',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  Videos: {
    backgroundColor: theme.palette.primary.main,
  },
  Views: {
    backgroundColor: theme.palette.primary.dark,
  },
  Clickthroughs: {
    backgroundColor: theme.palette.primary.light,
  },
  DiagramLegendIndicatorText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#475467',
    marginLeft: '5px',
  },
  Description: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.common.black,
    textAlign: 'center',
    width: '475px',
  },
}));
