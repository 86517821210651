import { FC } from 'react';
interface IConversionsIconDv5Props {
  color?: string;
}
export const ConversionsIconDv5: FC<IConversionsIconDv5Props> = ({ color }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.917" cy="11" r="10" stroke={color || '#8B89A0'} strokeWidth="1.3" />
      <path
        d="M8.30176 8.00895C8.30176 7.21722 9.17812 6.73949 9.84357 7.16845L14.4833 10.1593C15.0942 10.5531 15.0942 11.4465 14.4833 11.8403L9.84357 14.8312C9.17812 15.2602 8.30176 14.7824 8.30176 13.9907L8.30176 8.00895Z"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
      />
    </svg>
  );
};
