import { useToasts } from 'react-toast-notifications';
import { useSMSInviteApiCalls } from './useSMSInviteApiCalls';

export function useSendTestSms() {
  const { addToast } = useToasts();

  const { sendTestMessage } = useSMSInviteApiCalls();

  return ({ message, phone }: { message: string; phone: string }) => {
    sendTestMessage({ body: message, to: phone }).then((data: any) => {
      addToast(`A test message ${data.error ? "wasn't" : 'was'} sent to ${phone}`, {
        appearance: data.error ? 'error' : 'success',
        autoDismissTimeout: 2500,
      });
    });
  };
}
