import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_5_787"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="70"
      height="71"
    >
      <circle cx="35" cy="35.0312" r="35" fill="#EEECFC" />
    </mask>
    <g mask="url(#mask0_5_787)">
      <circle cx="35" cy="35.0312" r="35" fill="#EEECFC" />
      <path
        d="M0 42.5312L69.9999 42.5313"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 57.5312L69.9999 57.5313"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 27.5312L69.9999 27.5313"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 12.5312L69.9999 12.5313"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <circle cx="34.5" cy="34.5312" r="20.5" fill="white" stroke="#D9D4FF" strokeWidth="4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.007 26.5308H40.9922V29.5431V32.5066C40.9922 35.8057 38.3025 38.4801 34.9996 38.4801C31.69 38.4801 29.007 35.814 29.007 32.499V29.5131V26.5308Z"
      stroke="#5242EA"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M34.9996 40.1507V44.1458" stroke="#5242EA" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M31.0045 44.1458H38.9947" stroke="#5242EA" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M40.9922 28.5223C40.9922 28.5223 43.4891 28.5223 43.9885 28.5223C44.4879 28.5223 44.9872 29.0202 44.9872 29.5181C44.9872 30.016 45.4696 34.497 40.4757 34.497"
      stroke="#5242EA"
      strokeWidth="1.5"
    />
    <path
      d="M29.007 28.5223C29.007 28.5223 26.51 28.5223 26.0107 28.5223C25.5113 28.5223 25.0119 29.0202 25.0119 29.5181C25.0119 30.016 24.5386 34.497 29.5325 34.497"
      stroke="#5242EA"
      strokeWidth="1.5"
    />
  </svg>
);

const CollectVideosIcon = memo(SvgComponent);
export default CollectVideosIcon;
