import { ClientTypes } from '../../api/models/common';
import { useTypedSelector } from '../../store';

export function useClientType() {
  const { clientType } = useTypedSelector((state) => state.venue.venue);

  const isTrubluClient = clientType === ClientTypes.TRUBLU;
  const isEtsClient = clientType === ClientTypes.ETS;
  const isFundraisingClient = clientType === ClientTypes.FUNDRAISING;
  const isSalesClient = clientType === ClientTypes.SALES;
  const isMarketingClient = clientType === ClientTypes.MARKETING;
  const isTopGolfClient = clientType === ClientTypes.TOPGOLF;
  const isAthleticSolutionsClient = clientType === ClientTypes.ATHSOL;
  const isSvHealth = clientType === ClientTypes.SV_HEALTH;
  const isHealthCare = isTrubluClient || isSvHealth;

  return {
    isTrubluClient,
    isEtsClient,
    isFundraisingClient,
    isSalesClient,
    isMarketingClient,
    isTopGolfClient,
    isAthleticSolutionsClient,
    isSvHealth,
    isHealthCare,
  };
}
