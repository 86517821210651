import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RewardFulfillmentMethod } from '../../../api/models/rewardFulfillment';

interface IStyleProps {
  campaignSMSMethod?: RewardFulfillmentMethod;
}

export const useCampaignControlsStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => ({
  DiagramContainer: {
    position: 'relative',
    marginBottom: '10px',
    padding: '0px 24px 0px',
    height: '200px',
  },
  Tooltip: {
    '& div': {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.common.gray,
      backgroundColor: 'transparent',
      '&>div': {},
    },
  },
  LearnMore: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.common.gray,
  },
  SummaryInfo: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.common.gray,
    textAlign: 'right',
    marginTop: '4px',
  },
  Videos: {
    fontWeight: '600',
    fontSize: '27px',
    lineHeight: '44px',
    color: theme.palette.common.black,
    textAlign: 'right',
  },
  EligibilityDescription: {
    fontSize: '14px',
    color: theme.palette.common.gray,
    marginTop: '10px',
    minHeight: '70px',
  },
  CampaignObjective: {
    fontWeight: '600',
    fontSize: '18px',
    color: theme.palette.common.black,
  },
  CampaignObjectiveProperty: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '32px',
    color: theme.palette.primary.main,
  },
  container: {
    width: '360px',
    minHeight: '440px',
    border: '1px solid #D0D5DD',
  },
  button: {
    flexShrink: 0,
    flexGrow: 0,
    height: 'fit-content',
    marginLeft: '12px',
    background: theme.palette.common.white,
    fontWeight: '600',
    fontSize: '14px',
    color: '#344054',
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    padding: '10px 16px',
  },
}));
