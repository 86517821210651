import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useLatestVideosStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '16px 0px',
    minHeight: '240px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  Title: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '28px',
    color: theme.palette.common.black,
  },
  Promote: {
    margin: '0 auto',
    marginTop: '16px',
    marginBottom: '8px',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '30px',
    color: theme.palette.common.black,
    maxWidth: '430px',
    textAlign: 'center',
  },
  Description: {
    margin: '0 auto',
    marginBottom: '32px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#475467',
    textAlign: 'center',
    width: '320px',
  },
  Button: {
    padding: '10px 18px',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    color: theme.palette.common.black,
    borderColor: '#D0D5DD',
  },
  recentTitle: {
    fontSize: 18,
    marginBottom: 20,
    width: '100%',
    paddingLeft: '8px',
  },
  noVideosStyle: {
    color: '#D0D5DD',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '28px',
  },
  buttonsWrapper: {
    display: 'flex',
    width: 'fit-content',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
