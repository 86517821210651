import React, { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { descriptionListType } from './BusinessVideoStylePreview.helper';
import { useTypedSelector } from '../../../store';

interface VideoStyleListProps {
  descriptionList: descriptionListType;
  isHealthCare: boolean;
}

export const VideoStyleList: FC<VideoStyleListProps> = ({ descriptionList, isHealthCare }) => {
  const theme = useTheme();
  const { recommendedVideoStyle, showWelcomeVideo } = useTypedSelector(
    (state) => state.createCampaign,
  );

  return (
    <Box sx={{ width: '524px', marginTop: '30px' }}>
      {descriptionList.map(({ title, description, icon }) => {
        return (
          <Box
            key={title}
            sx={{
              display: 'flex',
              padding: '18px',
              background: '#fff',
              borderRadius: '12px',
              marginBottom: '12px',
              height: '120px',
              border:
                showWelcomeVideo && title?.toLowerCase().includes(recommendedVideoStyle)
                  ? '1px solid #5242EA'
                  : 'none',
            }}
          >
            <Box sx={{ marginRight: '18px' }}>{icon}</Box>
            <Box>
              <Typography
                sx={{
                  fontSize: '17px',
                  lineHeight: '18px',
                  marginBottom: '4px',
                  color: theme.palette.common.black,
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{ fontSize: '15px', lineHeight: '21px', color: theme.palette.common.gray }}
              >
                {isHealthCare ? description.replace(`creator`, `patient`) : description}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
