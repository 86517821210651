import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import tinycolor from 'tinycolor2';
import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import { EducationalPlayIcon } from '../../../common/assets/newDesign/SocialVerse/EducationalPlayIcon';
import { getTextWidth } from '../../../services/utilities';

import { useStyles } from './EdicationalView.styles';

interface Props {
  socialverse: SocialVerseAllApiModel;
}

export const EdicationalView: FC<Props> = ({ socialverse }) => {
  const classes = useStyles({});

  return (
    <Box className={classes.container}>
      <EducationCard
        size="md"
        title={socialverse.title}
        description={socialverse?.details?.supportingText}
        thumbnailUrl={socialverse.topVideos[0] ? socialverse.topVideos[0].thumbnailUrl : ''}
        color={socialverse.details?.glowColor}
      />
    </Box>
  );
};

interface EducationCardProps {
  size: 'sm' | 'md';
  title: string;
  description?: string;
  thumbnailUrl?: string;
  color?: string;
}

const EducationCard: FC<EducationCardProps> = ({
  size,
  title,
  description,
  thumbnailUrl,
  color,
}) => {
  const classes = useStyles({ size, color });
  const titleWidth = getTextWidth(title, '16px');
  const descriptionWidth = getTextWidth(description || '', '14px');

  return (
    <Box className={classes.card}>
      <EducationalPlayIcon color={tinycolor(color).isDark() ? 'white' : 'black'} />
      <Box style={{ marginLeft: '8px' }}>
        <Typography
          style={{ color: tinycolor(color).isDark() ? 'white' : 'black' }}
          className={classes.title}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            style={{
              color: tinycolor(color).isDark() ? 'white' : 'black',
              width: descriptionWidth / titleWidth > 2 ? '150px' : 'auto',
            }}
            className={classes.description}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
