import { AccountsIcon } from '../../../../common/assets/newDesign/AccountsIcon';
import { CTAIcon } from '../../../../common/assets/newDesign/CTAIcon';
import { VideosIconAnalytics } from '../../../../common/assets/newDesign/VideosIconAnalytics';

import { GlobalAnalyticsNames } from '../../../../api/models/analytics';
import { FolderIcon } from '../../../../common/assets/newDesign/FolderIcon';
import { PlayStropesIcon } from '../../../../common/assets/newDesign/PlayStropesIcon';
import { SEOIcon } from '../../../../common/assets/newDesign/SEOIcon';
import { SuperAdminAnalyticsBlock } from '../../../../common/localization/en';

export interface AnalyticsBlockModel {
  icon: React.ReactNode;
  kpiName: string;
  name: string;
  value: number | string;
  tooltipText: string;
  ratioValue?: string;
  ratioLable?: string;
}

export const venuesInitialAnalyticsData: AnalyticsBlockModel[] = [
  {
    icon: <AccountsIcon />,
    kpiName: GlobalAnalyticsNames.venues,
    name: SuperAdminAnalyticsBlock.Block1,
    value: 0,
    tooltipText: SuperAdminAnalyticsBlock.Block1Tooltip,
  },
  {
    icon: <FolderIcon />,
    kpiName: GlobalAnalyticsNames.liveCampaigns,
    name: SuperAdminAnalyticsBlock.Block6,
    value: 0,
    tooltipText: SuperAdminAnalyticsBlock.Block6Tooltip,
  },

  {
    icon: <VideosIconAnalytics />,
    kpiName: GlobalAnalyticsNames.videos,
    name: SuperAdminAnalyticsBlock.Block3,
    value: 0,
    tooltipText: SuperAdminAnalyticsBlock.Block3Tooltip,
  },
  {
    //
    icon: <PlayStropesIcon />,
    kpiName: GlobalAnalyticsNames.socialVerses,
    name: SuperAdminAnalyticsBlock.Block2,
    value: 0,
    tooltipText: SuperAdminAnalyticsBlock.Block2Tooltip,
  },
  {
    //
    icon: <SEOIcon />,
    kpiName: GlobalAnalyticsNames.gbpAccounts,
    name: SuperAdminAnalyticsBlock.Block4,
    value: 0,
    tooltipText: SuperAdminAnalyticsBlock.Block4Tooltip,
  },
  {
    icon: <CTAIcon />,
    kpiName: GlobalAnalyticsNames.ctaClicks,
    name: SuperAdminAnalyticsBlock.Block5,
    value: 0,
    tooltipText: SuperAdminAnalyticsBlock.Block5Tooltip,
  },
];
