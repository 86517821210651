import * as React from 'react';
import { SVGProps } from 'react';
export const GridIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={41}
    height={40}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1179_33215)">
      <path
        d="M32.3867 17.8149H34.5815"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M32.3867 29.8862H34.5815"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M32.3867 5.74365H34.5815"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M16.4746 17.2661H18.6694"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M17.0234 29.8862H19.2182"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M17.0234 5.74365H19.2182"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M2.75684 17.8149H4.95162"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M2.75684 29.8862H4.95162"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M2.75684 5.74365H4.95162"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <rect x={0.513672} y={3.5} width={9.27027} height={9.42524} rx={1.68} stroke="black" />
      <rect x={15.1084} y={3.5} width={9.81081} height={9.42524} rx={1.68} stroke="black" />
      <rect x={30.2432} y={3.5} width={9.27027} height={9.42524} rx={1.68} stroke="black" />
      <rect x={0.513672} y={15.5713} width={9.27027} height={9.42524} rx={1.68} stroke="black" />
      <rect
        x={15.1084}
        y={15.5713}
        width={9.81081}
        height={9.42524}
        rx={1.68}
        fill="#D9D4FF"
        stroke="#5242EA"
      />
      <path
        d="M16.4746 17.2661H20.8642"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <path
        d="M16.4746 18.3633H18.6694"
        stroke="#1A1538"
        strokeWidth={0.689974}
        strokeLinecap="round"
      />
      <rect x={30.2432} y={15.5713} width={9.27027} height={9.42524} rx={1.68} stroke="black" />
      <rect x={0.513672} y={27.6426} width={9.27027} height={9.42524} rx={1.68} stroke="black" />
      <rect x={15.1084} y={27.6426} width={9.81081} height={9.42524} rx={1.68} stroke="black" />
      <rect x={30.2432} y={27.6426} width={9.27027} height={9.42524} rx={1.68} stroke="black" />
    </g>
    <defs>
      <clipPath id="clip0_1179_33215">
        <rect width={40.6513} height={40} fill="white" transform="translate(0.0136719)" />
      </clipPath>
    </defs>
  </svg>
);
