import { ReactElement } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// import { SocialvenueManagerRoutes, WebsiteRoutes } from '../../../constants/routes';
import { WebsiteRoutes } from '../../../constants/routes';

import { SidebarStrings } from '../../../localization/en';
// import { IndicatorIcon } from '../../../assets/IndicatorIcon';
// import { QRCodeIcon } from '../../../assets/newDesign/QRCodeIcon';
// import { PeopleIcon } from '../../../assets/PeopleIcon';
import { PerformanceIcon } from '../../../assets/sideBarAssets/PerformanceIcon';
import { VideosIcon } from '../../../assets/sideBarAssets/VideosIcon';
import { UsersIcon } from '../../../assets/sideBarAssets/UsersIcon';
import { IncentiveIcon } from '../../../assets/sideBarAssets/IncentivesIcon';
import { ConfigurationsIcon } from '../../../assets/sideBarAssets/ConfigurationsIcon';
import { SocialVerseIcon } from '../../../assets/sideBarAssets/SocialVerseIcon';
import { WebAppicon } from '../../../assets/sideBarAssets/WebAppIcon';
import { APIIcon } from '../../../assets/sideBarAssets/APIIcon';

import { Incentiveicon } from '../../../assets/sideBarAssets/IncentiveIcon';
import { InvitesIcon } from '../../../assets/sideBarAssets/InvitesIcon';
import { DataSyncIcon } from '../../../assets/sideBarAssets/DataSyncIcon';
import WebAppIcon from '../../../assets/WebAppIcon';

export const checkIsActiveItem = (pathname: string, route: string): boolean => {
  return pathname.indexOf(route) === 0;
};
export const checkIsActiveSubItem = (pathname: string, route: string, nested: boolean): boolean => {
  let tempRoutes;
  if (nested) {
    tempRoutes = route.split('/');
    tempRoutes.pop();
    tempRoutes = tempRoutes.join('/');
  } else {
    tempRoutes = route;
  }
  return pathname.indexOf(tempRoutes) === 0;
};

export enum ContentColors {
  published = 'published',
  unpublished = 'unpublished',
  moderated = 'moderated',
}

export enum CommonMenuColors {
  active = 'active',
  inactive = 'inactive',
}

export interface ISubMenuItem {
  icon: ReactElement;
  text: string;
  notification?: number;
  route: string;
  color?: string;
  isNestedRoute?: boolean;
  disabled?: boolean;
}

export interface IMenuItem {
  icon: ReactElement;
  text: string;
  notification?: number;
  route: string;
  isSubMenu: boolean;
  isSuperAdmin?: boolean;
  subMenuItems?: ISubMenuItem[];
  isNotTopGolfItem?: boolean;
  isTopGolfItem?: boolean;
  disabled?: boolean;
  nested?: boolean;
}

export const useStylesList = makeStyles((theme: Theme) => ({
  root: {
    display: 'inherit',
    flexDirection: 'inherit',
    flexGrow: 'inherit',
    marginTop: '40px',
    marginBottom: '72px',
    width: '100%',
    '@media (max-height: 900px)': {
      marginBottom: 25,
    },
  },

  sectionDivider: {
    marginTop: 45,
    '@media (max-height: 900px)': {
      marginTop: 25,
    },
  },
  sectionTitle: {
    color: theme.palette.text.secondary,
    paddingLeft: '30px',
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '10px',
  },
  progressWrapper: {
    padding: '7px 15px 10px',
    margin: '12px 20px 0',
    background: '#2b2743',
    borderRadius: '10px',
  },

  progressText: {
    color: theme.palette.common.white,
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: 500,
  },

  progress: {
    marginTop: '3px',
    background: '#403c55',
    borderRadius: '10px',
    '& > span': {
      background: '#24b947',
    },
  },
}));

export const useStyles = makeStyles<
  Theme,
  {
    active: boolean;
    isSuperAdmin: boolean;
    subItems: number;
    isSubMenuOpened: boolean;
    blur: boolean;
    isAccountsButton?: boolean;
  }
>((theme) => ({
  menueItemWrapper: (props) => ({
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    filter: props.blur ? 'blur(16px)' : 'none',
    pointerEvent: props.blur ? 'none' : 'auto',
  }),
  root: (props) => ({
    paddingRight: 7,
    minHeight: '55px',
    borderRadius: '10px',
    color: theme.palette.common.white,
    stroke: theme.palette.common.gray,
    background: props.isAccountsButton ? 'rgba(255, 255, 255, 0.1)' : '',
    '&:hover': {
      background: props.isAccountsButton ? theme.palette.primary.main : '',
      cursor: 'pointer',
    },
    // ...(props.isSuperAdmin && {
    //   borderTop: '1px solid #464c71',
    //   marginTop: 'auto',
    // }),
  }),
  selected: (props) => ({
    color: props.isAccountsButton
      ? `${theme.palette.common.white} !important`
      : `${theme.palette.secondary.main} !important`,
    backgroundColor: props.isAccountsButton
      ? `${theme.palette.primary.main} !important`
      : `${theme.palette.secondary.light} !important`,
    stroke: props.isAccountsButton ? '' : `${theme.palette.secondary.main} !important`,
    // opacity: 0.1,
  }),
  icon: {
    marginLeft: 12,
    marginRight: 8,
    color: 'inherit !important',
  },
  accountItem: {
    position: 'absolute',
    bottom: '-70px',

    '@media (max-height: 900px)': {
      bottom: '-20px',
    },
  },
}));

export const menuItems = (isHealthCare?: boolean) => [
  {
    icon: <PerformanceIcon />,
    text: SidebarStrings.Performance,
    route: WebsiteRoutes.DashboardHeroes,
    isSubMenu: false,
    nested: true,
    // subMenuItems: [
    //   {
    //     icon: <IndicatorIcon />,
    //     text: SidebarStrings.Heroes,
    //     route: WebsiteRoutes.DashboardHeroes,
    //   },
    //   {
    //     icon: <IndicatorIcon />,
    //     text: SidebarStrings.Widget,
    //     route: WebsiteRoutes.DashboardWidget,
    //   },
    // ],
  },
  {
    icon: <VideosIcon />,
    text: SidebarStrings.Content,
    route: WebsiteRoutes.Content,
    isSubMenu: false,
  },
  {
    icon: <UsersIcon />,
    text: isHealthCare ? SidebarStrings.Patients : SidebarStrings.Heroes,
    route: WebsiteRoutes.Heroes,
    isSubMenu: false,
  },
  {
    icon: <SocialVerseIcon />,
    text: SidebarStrings.SocialVerse,
    route: WebsiteRoutes.SocialVerse,
    isSubMenu: false,
    disabled: false,
  },
  {
    icon: <IncentiveIcon />,
    text: SidebarStrings.RewardAd,
    route: WebsiteRoutes.IncentiveAd,
    isSubMenu: false,
    isTopGolfItem: true,
  },
  {
    icon: <WebAppIcon />,
    text: SidebarStrings.WebApp,
    route: WebsiteRoutes.WebAppSettings,
    isSubMenu: false,
    nested: false,
  },
  {
    icon: <IncentiveIcon />,
    text: SidebarStrings.Campaigns,
    route: WebsiteRoutes.RewardCampaigns,
    isSubMenu: false,
    isNotTopGolfItem: true,
    // subMenuItems: [
    //   {
    //     icon: <IndicatorIcon />,
    //     text: SidebarStrings.Campaigns,
    //     route: WebsiteRoutes.RewardsCampaigns,
    //   },
    //   {
    //     icon: <IndicatorIcon />,
    //     text: SidebarStrings.Leaderboard,
    //     route: WebsiteRoutes.RewardsLeaderboard,
    //   },
    // ],
  },
  {
    icon: <DataSyncIcon />,
    text: SidebarStrings.DataSync,
    route: WebsiteRoutes.DataSync,
    isSubMenu: false,
    nested: true,
  },

  // {
  //   // icon: <EqualizerIcon />,
  //   icon: <></>,
  //   text: SidebarStrings.Accounts,
  //   // route: SocialvenueManagerRoutes.Performance,
  //   route: WebsiteRoutes.ProfileAccounts,
  //   isSubMenu: false,
  //   isSuperAdmin: true,
  // },
];

export const managerItems: IMenuItem[] = [
  {
    icon: <ConfigurationsIcon />,
    text: SidebarStrings.Configurations,
    route: WebsiteRoutes.ConfirgurationIncentive,
    isSubMenu: true,
    subMenuItems: [
      {
        icon: <Incentiveicon />,
        text: SidebarStrings.Rewards,
        route: WebsiteRoutes.ConfirgurationIncentive,
        disabled: false,
      },
      {
        icon: <WebAppicon />,
        text: SidebarStrings.WebApp,
        route: WebsiteRoutes.WebApp,
        isNestedRoute: true,
      },

      // {
      //   icon: <></>,
      //   text: SidebarStrings.Heroes,
      //   route: WebsiteRoutes.TeamSettings,
      // },
      {
        icon: <InvitesIcon />,
        text: SidebarStrings.Invites,
        route: WebsiteRoutes.IntegrationsSmsInvite,
        isNestedRoute: true,
      },
      // {
      //   icon: <BeaconsIcon />,
      //   text: SidebarStrings.Beacons,
      //   route: WebsiteRoutes.Beacons,
      //   disabled: false,
      // },
      {
        icon: <DataSyncIcon />,
        text: SidebarStrings.DataSync,
        route: WebsiteRoutes.DataSync,
        disabled: false,
      },
      {
        icon: <APIIcon />,
        text: SidebarStrings.API,
        route: WebsiteRoutes.API,
        disabled: true,
      },
    ],
    //   {
    //     icon: <IndicatorIcon />,
    //     text: SidebarStrings.BrandingStyle,
    //     route: WebsiteRoutes.BrandingStyle,
    //   },
    //   {
    //     icon: <IndicatorIcon />,
    //     text: SidebarStrings.VideoEditing,
    //     route: WebsiteRoutes.VideoEditing,
    //   },
    //   {
    //     icon: <IndicatorIcon />,
    //     text: SidebarStrings.VideoSharing,
    //     route: WebsiteRoutes.VideoSharing,
    //   },
    // ],
  },
  // {
  //   icon: <QRCodeIcon />,
  //   text: SidebarStrings.Integrations,
  //   route: WebsiteRoutes.Integrations,
  //   isSubMenu: false,
  // subMenuItems: [
  //   {
  //     icon: <IndicatorIcon />,
  //     text: SidebarStrings.WebWidget,
  //     route: WebsiteRoutes.IntegrationsWebWidget,
  //   },
  //   {
  //     icon: <IndicatorIcon />,
  //     text: SidebarStrings.IntegrationsHistory,
  //     route: WebsiteRoutes.IntegrationsHistory,
  //   },
  // ],
  // },
  // {
  //   icon: <PeopleIcon />,
  //   text: SidebarStrings.Organizations,
  //   route: SocialvenueManagerRoutes.AdminOrgManagement,
  //   isSubMenu: false,
  //   isSuperAdmin: true,
  // },
  // {
  //   // icon: <EqualizerIcon />,
  //   icon: <></>,
  //   text: SidebarStrings.Accounts,
  //   route: SocialvenueManagerRoutes.Performance,
  //   isSubMenu: false,
  //   isSuperAdmin: true,
  // },
];

export const checkIsActiveSubMenuItem = (pathname: string, item: IMenuItem) => {
  if (!item.subMenuItems) {
    return false;
  }

  const activeItem = item.subMenuItems.find((subMenuItem: ISubMenuItem) => {
    const parentPath = subMenuItem.route.split('/');
    parentPath.pop();
    return pathname.includes(parentPath.join('/'));
  });

  return Boolean(activeItem);
};
