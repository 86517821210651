import { FC } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useTypedSelector, useAppDispatch } from '../../../../store';
import {
  postIncentiveCampaign,
  updateTable,
  patchIncentiveCampaign,
  getActiveIncentiveCampaign,
} from '../../../../store/slices/campaignsSlice';

import { CommonStrings, RewardsStrings } from '../../../../common/localization/en';

import { RewardInitialValues } from './AddRewardForm/AddRewardForm.helper';
import { Activity } from '../../../../api/models/campaigns';
// import { ClientTypes } from '../../../../api/models/common';
import { СreateIncentiveModal } from '../../createIncentive/createIncentiveModal';
import { patchVenueProperties } from '../../../../store/slices/venueSlice';

interface AddRewardSectionProps {
  isActive: boolean;
  isMobile: boolean;
  onCloseCreationModal: () => void;
  onOpenEndCampaignModal?: () => void;
}
export const AddRewardSection: FC<AddRewardSectionProps> = ({
  isActive,
  isMobile,
  onCloseCreationModal,
  onOpenEndCampaignModal,
}) => {
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const {
    venue: { id: venueId },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const incentiveConfig = venueProperties?.['webapp.incentive.config'];
  // const welcomeCard = venueProperties?.['webapp.cards-welcome'];

  const imageUrl = activeCampaign?.couponEnabled ? '' : activeCampaign?.imageUrl || '';
  const initParams = {
    imageUrl: imageUrl,
    name: activeCampaign?.name || '',
    action: incentiveConfig?.action || '',
    title: incentiveConfig?.title || '',
    activities: activeCampaign?.activities || null,
    points: activeCampaign?.points,
  };

  // const isTopgolf = clientType === ClientTypes.TOPGOLF;
  const onSaveHandler = (values: RewardInitialValues, activities?: Activity[]) => {
    const data = {
      action: values.action || incentiveConfig?.action,
      imageUrl: values.imageUrl || incentiveConfig?.imageUrl,
      title: values.title || incentiveConfig?.title,
      rewardTitle: values.title || incentiveConfig?.rewardTitle || incentiveConfig?.title,
      rewardDescription:
        values.action || incentiveConfig?.rewardDescription || incentiveConfig?.action,
    };
    if (activeCampaign) {
      dispatch(
        patchVenueProperties({
          id: venueId,
          update: {
            name: 'webapp.incentive.config',
            value: { ...incentiveConfig, ...data },
          },
          alert: false,
        }),
      );
      values.rewardTitle = values.title || incentiveConfig?.rewardTitle || incentiveConfig?.title;
      values.rewardDescription =
        values.action || incentiveConfig?.rewardDescription || incentiveConfig?.action;
      dispatch(
        patchIncentiveCampaign({
          campaignId: activeCampaign.id,
          values,
        }),
      )
        .then(() => {
          dispatch(updateTable());
          dispatch(getActiveIncentiveCampaign(venueId));
          onCloseCreationModal();
          addToast(RewardsStrings.RewardUpdateMessage, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        })
        .catch(() => {
          addToast(CommonStrings.OnError, { appearance: 'error' });
        });
      return;
    } else if (activities)
      dispatch(
        postIncentiveCampaign({
          venueId,
          activities,
          isLocked: false,
          ...values,
        }),
      );
  };

  return (
    <>
      {/* (
        <AddRewardForm
          isMobile={isMobile}
          onSave={onSaveHandler}
          venueId={venueId}
          editedCampaign={activeCampaign}
          onCloseCreationModal={onCloseCreationModal}
          isTopgolf={isTopgolf}
        />
      ) : ( */}
      <СreateIncentiveModal
        initialParams={initParams}
        isEdit={!!activeCampaign?.name}
        isOpen={true}
        onSave={onSaveHandler}
        onCloseCreationModal={onCloseCreationModal}
        onOpenEndCampaignModal={onOpenEndCampaignModal}
      />
    </>
  );
};
