import * as React from 'react';
import { SVGProps } from 'react';
const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={126}
    height={126}
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M63 10C69.9601 10 76.852 11.3709 83.2822 14.0344C89.7125 16.6979 95.5552 20.6018 100.477 25.5233C105.398 30.4449 109.302 36.2875 111.966 42.7178C114.629 49.148 116 56.0399 116 63C116 69.9601 114.629 76.852 111.966 83.2822C109.302 89.7125 105.398 95.5552 100.477 100.477C95.5551 105.398 89.7125 109.302 83.2822 111.966C76.8519 114.629 69.96 116 63 116C56.0399 116 49.148 114.629 42.7178 111.966C36.2875 109.302 30.4448 105.398 25.5233 100.477C20.6018 95.5551 16.6979 89.7125 14.0344 83.2822C11.3709 76.8519 10 69.96 10 63C10 56.0399 11.3709 49.148 14.0344 42.7178C16.6979 36.2875 20.6019 30.4448 25.5234 25.5233C30.4449 20.6018 36.2876 16.6979 42.7178 14.0344C49.1481 11.3709 56.04 10 63 10L63 10Z"
      stroke="#F2F4F7"
      strokeWidth={20}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.683 29.5871C112.147 38.7869 116.316 50.221 116.525 62.066C116.733 73.911 112.97 85.4849 105.835 94.9418C98.6992 104.399 88.6028 111.194 77.1554 114.245C65.708 117.295 53.5688 116.425 42.6737 111.773C31.7785 107.12 22.7548 98.9539 17.0416 88.5757C11.3283 78.1975 9.25449 66.2051 11.1508 54.511C13.0471 42.8169 18.8044 32.0945 27.5045 24.0536C36.2046 16.0127 47.3466 11.1163 59.1536 10.1454"
      stroke="#CFB8EE"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M104.683 29.5871C112.147 38.7869 116.316 50.221 116.525 62.066C116.733 73.911 112.97 85.4849 105.835 94.9418C98.6992 104.399 88.6028 111.194 77.1554 114.245C65.708 117.295 53.5688 116.425 42.6737 111.773C31.7785 107.12 22.7548 98.9539 17.0416 88.5757C11.3283 78.1975 9.25449 66.2051 11.1508 54.511C13.0471 42.8169 18.8044 32.0945 27.5045 24.0536C36.2046 16.0127 47.3466 11.1163 59.1536 10.1454"
      stroke="#5242EA"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.5442 17.9886C91.827 18.1648 92.1082 18.3437 92.3876 18.5252"
      stroke="#BAB3FE"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.4571 11.3325C75.7817 11.4076 76.1056 11.4858 76.4288 11.5671"
      stroke="#7F56D9"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M75.4571 11.3325C75.7817 11.4076 76.1056 11.4858 76.4288 11.5671"
      stroke="#372F86"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_0_1"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={36}
      width={55}
      height={55}
    >
      <circle cx={63.5} cy={63.5} r={27.5} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask0_0_1)">
      <circle cx={63.5} cy={63.5} r={26.8} fill="#CFB8EE" stroke="#A69DF7" strokeWidth={1.4} />
      <ellipse
        cx={63.2832}
        cy={63.4355}
        rx={18.6575}
        ry={18.6575}
        transform="rotate(-0.12231 63.2832 63.4355)"
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeOpacity={0.5}
        strokeWidth={1.4}
      />
      <path
        d="M60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866L60.6212 56.3013Z"
        fill="#5242EA"
        stroke="#A69DF7"
        strokeWidth={1.4}
      />
    </g>
    <mask
      id="mask1_0_1"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={36}
      width={55}
      height={55}
    >
      <circle cx={63.5} cy={63.5} r={27.5} fill="#CFB8EE" />
    </mask>
    <g mask="url(#mask1_0_1)">
      <circle cx={63.5} cy={63.5} r={27.5} fill="#CFB8EE" />
      <ellipse
        cx={63.2832}
        cy={63.4355}
        rx={18.6575}
        ry={18.6575}
        transform="rotate(-0.12231 63.2832 63.4355)"
        fill="#EEECFD"
      />
      <path
        d="M60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866L60.6212 56.3013Z"
        fill="#5242EA"
        stroke="#5242EA"
        strokeWidth={1.4}
      />
    </g>
    <circle cx={63.5} cy={63.5} r={26.8} fill="#D9D4FF" stroke="#5242EA" strokeWidth={1.4} />
    <ellipse
      cx={63.2832}
      cy={63.4355}
      rx={18.6575}
      ry={18.6575}
      transform="rotate(-0.12231 63.2832 63.4355)"
      fill="#EEECFD"
      stroke="#A69DF7"
      strokeWidth={1.4}
    />
    <path
      d="M60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866L60.6212 56.3013Z"
      fill="#5242EA"
      stroke="#A69DF7"
      strokeWidth={1.4}
    />
  </svg>
);
export default SVGComponent;
