import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    border: '1px solid #DCDBE4',
    borderRadius: '15px',
    padding: '17px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .css-mpxmd2-MuiTypography-root': {
      fontSize: '14px',
      fontWeight: '500',
      color: '#344054',
      lineHeight: '20px',
    },
  },
  title: {
    fontSize: '14px',
    fontWeight: '600',
    color: theme.palette.common.deepDark,
  },
  subtitle: {
    fontSize: '12px',
    color: '#5242EA',
  },
  loadingWrapper: {
    width: '100%',
    padding: '0 16px',
    position: 'relative',
  },
  fileName: {
    fontSize: '14px',
    color: '#344054',
  },
  fileSize: {
    fontSize: '14px',
    color: '#475467',
    fontWeight: '500',
  },
  progressWrapper: {
    marginTop: '10px',
  },
  progress: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#EAECF0',
  },
  completedIcon: {
    position: 'absolute',
    top: '0',
    right: '16px',
  },
  deleteIcon: {
    position: 'absolute',
    top: '-8px',
    right: '8px',
  },
  tryAgainButton: {
    fontSize: '14px',
    fontWeight: '600',
    padding: '0',
    marginTop: '4px',
  },
  dragOver: {
    width: '48px',
    height: '48px',
    borderRadius: '6px',
    boxShadow: '0px 24px 48px -12px #1018282E',
    position: 'absolute',
    bottom: '13px',
    right: '13px',
    display: 'grid',
    placeContent: 'center',
  },
}));
