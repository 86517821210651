import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useFormik } from 'formik';
// import ReCAPTCHA from 'react-google-recaptcha';

import {
  PricingScreenStrings,
  SettingsPageStrings,
  SingUpPage,
} from '../../../common/localization/en';
import {
  castURLToHttps,
  getValidationSchemaSignUpForm,
  getValidationSchemaSignUpFormWithoutPractice,
} from './SignUpForm.helper';
import { useSignUpFormStyles } from './SignUpForm.style';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { createCheckoutSession, getBillingConfig } from '../../../store/slices/BillingSlice';
// import { convertStripePriceToDollars } from '../../../services/utilities';
import {
  states,
  SV_HEALTH_SERVICE_AGREEMENT,
  SV_SERVICE_AGREEMENT,
  TRUBLU_SERVICE_AGREEMENT,
  UserRoles,
} from '../../../common/constants/constants';
import {
  DEFAULT_PHONE_VALIDATION_STATE,
  PhoneField,
  PhoneValidationState,
} from '../../../common/components/PhoneField/PhoneField';
import { CustomTextField } from '../../../common/components/customTextField/CustomTextField';
import { CustomSelectWithTextField } from '../../../common/components/customSelect/CustomSelectWithTextField';
import { CustomButton } from '../../../common/components/customButton/customButton';
import { Signature } from '../../../common/components/Signature/Signature';
import { useQuery } from '../../../hooks/common';
import { PricingPlanModel } from '../../../api/models/pricingPlans';
import { PricingPlanPreview } from './components/PricingPlanPreview';
import {
  getPricingPlanByReferralCode,
  resetPlanDetail,
} from '../../../store/slices/pricingPlansSlice';
import { ClientTypes } from '../../../api/models/common';
import { URLTextField } from '../../../common/components/TextField/URLTextField';
import { base64ToString } from '../../../services/utilities';
import { SuccessIcon } from '../../../common/assets/newDesign/SuccessIcon';
import { PlanBackgroundTopIcon } from '../../../common/assets/newDesign/PlanBackgroundTopIcon';
import { PlanBackgroundSideIcon } from '../../../common/assets/newDesign/PlanBackgroundSideIcon';
import { LayoutLoader } from '../../../common/layout/LayoutLoader';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SignUpEventNames } from '../../../common/constants/events/signUpEventNames';
import { useHandleChangeWithTracking } from '../../../services/hooks/useHandleChangeWithTracking';

interface Props {}

// const recaptchaKey = process.env.REACT_APP_RECAPTCHA;
// const DEFAULT_PLAN_CODE = 'PSV01';

export const SignUpForm: FC<Props> = () => {
  const query = useQuery();
  const planCode = base64ToString(query.get('promo') ?? '');
  const distCode = base64ToString(query.get('dist') ?? '');
  const managerId = base64ToString(query.get('muid') ?? '');
  const { trackEvent } = useTrackEvent();
  const {
    plans,
    redirectUrl,
    isLoading: isRedirectUrlLoading,
  } = useTypedSelector((state) => state.BillingSlice);
  // const currentPlan = planId && plans ? plans[planId as PricingPlansTypes] : null;
  const classes = useSignUpFormStyles();
  // const planName = currentPlan?.name || '';
  // const planPrice = convertStripePriceToDollars(currentPlan?.priceAmount || 0);
  // const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  // const currentPlanId = currentPlan ? backendPricingPlans[planId as PricingPlansTypes] : '';
  const origin = window.location.origin;

  const { role } = useTypedSelector((state) => state.me);
  const isSVAdmin = role === UserRoles.admin;
  const isEnterPrisePlan = planCode.toLocaleLowerCase().includes('enterprise');
  const [isUserRequest, setIsUserRequest] = useState(false);

  useEffect(() => {
    if (!plans) {
      dispatch(getBillingConfig());
    }
  }, [plans, dispatch]);

  const [pricingPlan, setPricingPlan] = useState<PricingPlanModel>();
  const [defaultPlan, setDefaultPlan] = useState<PricingPlanModel>();

  const [appliedReferral, setAppliedReferral] = useState<{
    plan: PricingPlanModel;
    code: string;
  }>();

  useEffect(() => {
    if (redirectUrl) {
      const a = document.createElement('a');
      a.href = redirectUrl;
      a.click();
    }
  }, [redirectUrl]);

  // useEffect(() => {
  //   if (isSVAdmin && isEnterPrisePlan) {
  //     const a = document.createElement('a');
  //     a.href = `${origin}/signup/success`;
  //     a.click();
  //   }
  // }, [isSVAdmin, origin, isEnterPrisePlan]);

  const phoneValidationRef = useRef<PhoneValidationState>(DEFAULT_PHONE_VALIDATION_STATE);

  const isTruBluPlan =
    planCode.startsWith('TB') || (appliedReferral ?? pricingPlan)?.code.startsWith('TB');
  const isSVHealthPlan =
    planCode.includes('SVH') || (appliedReferral ?? pricingPlan)?.code.includes('SVH');
  const clientType = isTruBluPlan
    ? ClientTypes.TRUBLU
    : isSVHealthPlan
    ? ClientTypes.SV_HEALTH
    : undefined;

  const [countryCode, setCountryCode] = useState('');

  // const resetCaptcha = () => {
  //   window?.grecaptcha?.reset?.();
  //   setCaptchaResponse(null);
  // };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmedPassword: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    signature: '',
    companyName: '',
    practiceName: isTruBluPlan ? '' : undefined,
    companyWebsite: '',
    terms: true,
    planCode: '',
  };

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    setFieldError,
    isSubmitting,
    handleSubmit,
    touched,
    handleBlur,
    isValid: isFormValid,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: isTruBluPlan
      ? getValidationSchemaSignUpForm({ phoneValidationRef })
      : getValidationSchemaSignUpFormWithoutPractice({ phoneValidationRef }),
    onSubmit: async (values) => {
      trackEvent(SignUpEventNames.submitButtonClicked, {
        //@ts-ignore
        value: values,
      });
      const transformedUrl = castURLToHttps(values.companyWebsite);
      const planCode = appliedReferral?.plan.code ?? pricingPlan?.code ?? distCode ?? '';

      localStorage.setItem(
        'signupForm',
        JSON.stringify({
          ...values,
          phone: `${countryCode}${values.phone}`,
          soldBy: managerId || null,
          companyWebsite: transformedUrl,
          planCode: planCode,
          clientType: clientType,
          name: `${values.firstName} ${values.lastName}`,
        }),
      );

      if (!redirectUrl && !(isEnterPrisePlan && isSVAdmin)) {
        await dispatch(
          createCheckoutSession({
            payload: {
              planCode: planCode,
              successUrl: `${origin}/signup/success?session_id={CHECKOUT_SESSION_ID}`,
              cancelUrl: `${origin}/signup/plans`,
            },
            // captchaResponse: captchaResponse || '',
          }),
        );
      } else if (isSVAdmin && isEnterPrisePlan) {
        const a = document.createElement('a');
        a.href = `${origin}/signup/success`;
        a.click();
      }
      // resetCaptcha();
    },
  });

  const { handleChangeWithTracking } = useHandleChangeWithTracking(handleChange);

  const {
    planDetails: { value: planDetails, isError: isPlanDetailsError },
    isLoading: isPlansLoading,
  } = useTypedSelector((state) => state.pricingPlans);
  const fetchPricingDetailsByReferralCode = async (code: string) => {
    if (code) {
      setIsUserRequest(true);
      dispatch(getPricingPlanByReferralCode({ code }));
    }
  };

  useEffect(() => {
    if (planDetails) {
      setPricingPlan(planDetails);
    }
  }, [planDetails]);

  // Fetch pricingPlan
  useEffect(() => {
    dispatch(resetPlanDetail());
    dispatch(getPricingPlanByReferralCode({ code: planCode || '_' })).then((result) => {
      setDefaultPlan(result.payload as PricingPlanModel);
      fetchPricingDetailsByReferralCode(planCode ?? '');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // update planCode on referral-code apply
    if (isPlanDetailsError) {
      setFieldError('planCode', PricingScreenStrings.PromoCodeError);
    }
  }, [isPlanDetailsError, setFieldError]);

  const promoCodeApplied = useMemo(() => {
    return !errors.planCode && planDetails && defaultPlan && planDetails.name !== defaultPlan.name
      ? values.planCode
      : '';
    // eslint-disable-next-line
  }, [defaultPlan, planDetails, errors.planCode]);

  const handlePlanCodeChange = (event: ChangeEvent<any>) => {
    setAppliedReferral(undefined);
    setFieldError('planCode', undefined);
    setFieldValue('planCode', event.target.value);
    handleChangeWithTracking(event);
  };

  useEffect(() => {
    if (planDetails) {
      setAppliedReferral({ plan: planDetails as PricingPlanModel, code: planDetails.code });
    }
  }, [planDetails]);

  // const onCaptchChange = (value: string | null) => {
  //   trackEvent(SignUpEventNames.captchaResponseReceived, { value: value || '' });
  //   setCaptchaResponse(value);
  // };

  useEffect(() => {
    const el: HTMLInputElement | null = document.querySelector('.Mui-error');
    (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth' });
  }, [isSubmitting]);

  const handleCodeApply = async () => {
    trackEvent(SignUpEventNames.applyPromoCodeButtonClicked, {
      value: values.planCode,
    });
    fetchPricingDetailsByReferralCode(values.planCode);
  };

  const agreementDocument = isTruBluPlan
    ? {
        title: PricingScreenStrings.ServiceAgreement,
        link: TRUBLU_SERVICE_AGREEMENT,
      }
    : isSVHealthPlan
    ? {
        title: 'Customer Terms of Service and Pricing Plan',
        link: SV_HEALTH_SERVICE_AGREEMENT,
      }
    : {
        title: 'Customer Terms of Service and Pricing Plan',
        link: SV_SERVICE_AGREEMENT,
      };

  const handleNameChange = (event: ChangeEvent<any>) => {
    event.target.value = event.target.value.trim();
    handleChangeWithTracking(event);
  };

  if (isPlansLoading && !isUserRequest) {
    return <LayoutLoader />;
  }

  const getLabelWithDescription = (title: string, description: string) => {
    return (
      <Typography display="inline">
        {title}
        <Typography display="inline" color="#8B89A0 !important">
          {description}
        </Typography>
      </Typography>
    );
  };

  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? errors[field] : undefined;
  };

  return (
    <Grid container sx={{ margin: '0 auto' }}>
      <Grid
        item
        xs={6.5}
        style={{
          backgroundColor: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} className={classes.mainSide}>
          <Grid xs={12} className={classes.content}>
            {/* {isSVAdmin ? null : (
              <Typography className={classes.plan}>
                {planName + ' ' + SingUpPage.Plan + ': ' + planPrice}
                <Typography className={classes.month} component="span">
                  / Mo
                </Typography>
              </Typography>
            )} */}
            <Typography className={classes.title} component="h1">
              {SingUpPage.Title}
            </Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
              <CustomTextField
                label={SingUpPage.CompanyName}
                value={values.companyName}
                name="companyName"
                handleChange={handleChangeWithTracking}
                error={getFieldError('companyName')}
                labelClassName={classes.marginTop}
                placeholder={`${SingUpPage.Enter} ${SingUpPage.CompanyName}`}
                onBlur={handleBlur}
              />

              {isTruBluPlan && (
                <CustomTextField
                  label={SingUpPage.PracticeName}
                  value={values.practiceName!}
                  name="practiceName"
                  handleChange={handleChangeWithTracking}
                  error={getFieldError('practiceName')}
                  labelClassName={classes.marginTop}
                  placeholder={`${SingUpPage.Enter} ${SingUpPage.PracticeName}`}
                  onBlur={handleBlur}
                />
              )}

              <Box mt="15px">
                <Typography fontSize="12px" color="black" mb="10px">
                  {isTruBluPlan ? SingUpPage.PracticeWebsite : SingUpPage.CompanyWebsite}
                </Typography>
                <URLTextField
                  value={values.companyWebsite}
                  name="companyWebsite"
                  onChange={handleChangeWithTracking}
                  errorText={getFieldError('companyWebsite')}
                  placeholder={
                    isTruBluPlan
                      ? SingUpPage.TrubluWebsitePlaceholder
                      : SingUpPage.SvWebsitePlaceholder
                  }
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>

              <CustomTextField
                label={isTruBluPlan ? SingUpPage.PracticeAddress : SingUpPage.CompanyAddress}
                value={values.address}
                name="address"
                handleChange={handleChangeWithTracking}
                error={getFieldError('address')}
                labelClassName={classes.marginTop}
                placeholder={SingUpPage.FirstLineOfAddress}
                onBlur={handleBlur}
              />
              <Grid container justifyContent="space-between" alignItems={'flex-start'}>
                <Grid item xs={4.7}>
                  <CustomTextField
                    label={''}
                    value={values.city}
                    name="city"
                    handleChange={handleChangeWithTracking}
                    error={getFieldError('city')}
                    placeholder={SingUpPage.City}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={4.1}>
                  <CustomSelectWithTextField
                    className={classes.full}
                    name={'state'}
                    value={values.state}
                    label={''}
                    onChange={handleChange}
                    options={states}
                    setFieldValue={setFieldValue}
                    optional={false}
                    state
                    onBlur={handleBlur}
                    height="43px"
                    margin="0"
                    error={getFieldError('state')}
                  />
                </Grid>
                <Grid item xs={2.9}>
                  <CustomTextField
                    label={''}
                    value={values.zip}
                    name="zip"
                    handleChange={(event) => {
                      const val = event.target.value;
                      if ((/^\d+$/.test(val) || val === '') && val.length < 6) {
                        handleChangeWithTracking(event);
                      }
                    }}
                    error={getFieldError('zip')}
                    placeholder={SingUpPage.ZipCode}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>

              <CustomTextField
                label={SingUpPage.FirstName}
                value={values.firstName}
                name="firstName"
                handleChange={handleNameChange}
                error={getFieldError('firstName')}
                labelClassName={classes.marginTop}
                placeholder={SingUpPage.Enter + ' ' + SingUpPage.FirstName}
                onBlur={handleBlur}
              />
              <CustomTextField
                label={SingUpPage.LastName}
                value={values.lastName}
                name="lastName"
                handleChange={handleNameChange}
                error={getFieldError('lastName')}
                labelClassName={classes.marginTop}
                placeholder={SingUpPage.Enter + ' ' + SingUpPage.LastName}
                onBlur={handleBlur}
              />
              <Box className={classes.marginTop} sx={{ mt: '0px !important' }}>
                <PhoneField
                  name="phone"
                  label={SingUpPage.Phone}
                  value={values.phone}
                  onChange={handleChangeWithTracking}
                  className={classes.marginTop}
                  placeholder={'(555) 555-5555'}
                  error={getFieldError('phone')}
                  validateOnMount={false}
                  setCountryCode={setCountryCode}
                  countryCodeEditable={true}
                  onValidationChange={(v) => {
                    phoneValidationRef.current = v;
                  }}
                  onlyCountries={['us']}
                  onBlur={handleBlur}
                  isCountryCodeDisabled={true}
                  autoComplete="on"
                />
              </Box>

              <CustomTextField
                label={getLabelWithDescription(SingUpPage.Email, SingUpPage.LoginUserName)}
                value={values.email}
                name="email"
                handleChange={handleChangeWithTracking}
                error={getFieldError('email')}
                labelClassName={classes.marginTop}
                placeholder={SingUpPage.Enter + ' ' + SingUpPage.Email}
                onBlur={handleBlur}
                autoComplete="none"
              />
              <CustomTextField
                label={getLabelWithDescription(SingUpPage.Password, SingUpPage.LoginPassword)}
                value={values.password}
                name="password"
                handleChange={(event) => {
                  handleChangeWithTracking(event, true);
                }}
                error={getFieldError('password')}
                labelClassName={classes.marginTop}
                placeholder={SingUpPage.Enter + ' ' + SingUpPage.Password}
                isPasswordField
                onBlur={handleBlur}
                autoComplete="new-password"
              />
              <CustomTextField
                label={SettingsPageStrings.ConfirmPassword}
                name="confirmedPassword"
                isPasswordField={true}
                value={values.confirmedPassword}
                handleChange={(event) => {
                  handleChangeWithTracking(event, true);
                }}
                error={getFieldError('confirmedPassword')}
                labelClassName={classes.marginTop}
                placeholder={SettingsPageStrings.EnterPassword}
                onBlur={handleBlur}
                autoComplete="new-password"
              />

              {!isTruBluPlan && !planCode && (
                <Box display="flex" position="relative">
                  <Box width="100%">
                    <CustomTextField
                      label={SingUpPage.PromoCode}
                      name="planCode"
                      value={values.planCode}
                      handleChange={handlePlanCodeChange}
                      error={(isPlanDetailsError && PricingScreenStrings.PromoCodeError) || ''}
                      labelClassName={classes.marginTop}
                      placeholder={SingUpPage.EnterYourPromoCode}
                      inputClassName={
                        promoCodeApplied !== '' && values.planCode === promoCodeApplied
                          ? classes.inputSuccess
                          : ''
                      }
                    />
                  </Box>
                  <Button
                    variant="contained"
                    sx={{ height: '42px', minWidth: '120px', mt: '49px', fontWeight: 400 }}
                    onClick={handleCodeApply}
                    disabled={values.planCode.length === 0 || values.planCode === promoCodeApplied}
                    classes={{
                      disabled: classes.btnDisabled,
                    }}
                  >
                    {SingUpPage.ApplyCode}
                  </Button>
                  {promoCodeApplied !== '' && (
                    <Box className={classes.planSuccessWrapper}>
                      <SuccessIcon />
                      <Typography className={classes.planSuccess}>
                        {PricingScreenStrings.PromoCodeSuccess}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}

              <Divider sx={{ mt: promoCodeApplied !== '' ? '45px' : '25px', mb: '10px' }} />
              <Signature
                signature={values.signature}
                onChange={handleChangeWithTracking}
                error={getFieldError('signature')}
                clientName={`${values.firstName} ${values.lastName}`}
                onBlur={handleBlur}
              />
              <Box className={classes.termsBlock}>
                <FormControlLabel
                  label={
                    <Typography>
                      {PricingScreenStrings.ByContinuing}
                      <a
                        href={agreementDocument.link}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.agreementLink}
                      >
                        {agreementDocument.title}
                      </a>
                      {isTruBluPlan && (
                        <>
                          <br></br>
                          {' and '}
                          <a
                            href="./TRUBLU End User License Agreement.pdf"
                            target="_blank"
                            rel="noreferrer"
                            className={classes.agreementLink}
                          >
                            {PricingScreenStrings.EndUserLicensingAgreement}
                          </a>
                          <span>
                            {' and '}{' '}
                            <span style={{ fontWeight: 'bold', color: 'black' }}>
                              {' '}
                              Pricing Plan{' '}
                            </span>
                          </span>
                        </>
                      )}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={values.terms}
                      name="terms"
                      onChange={(event) => {
                        handleChange(event);
                        trackEvent(SignUpEventNames.termsAcceptCheckboxClicked, {
                          value: event.target.checked.toString(),
                        });
                      }}
                      size="medium"
                    />
                  }
                />
              </Box>
              {/* <div className={classes.captchaBlock}>
                <ReCAPTCHA sitekey={recaptchaKey || ''} onChange={onCaptchChange} />
              </div> */}
              <Box className={classes.SubmitBtn}>
                <CustomButton
                  title="Continue"
                  type="submit"
                  colorType="primary"
                  fullWidth
                  disabled={isRedirectUrlLoading || !values.terms || !isFormValid}
                  endIcon={<ArrowForwardIosRoundedIcon />}
                />
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5.5}>
        <Box
          sx={{
            height: '100%',
            background: '#1E0035',
            position: 'relative',
          }}
        >
          <PlanBackgroundTopIcon />
          <Box className={classes.BackgroundSideIcon}>
            <PlanBackgroundSideIcon />
          </Box>
          <Box position="sticky" top="50px">
            {pricingPlan && defaultPlan && (planDetails !== null || isPlanDetailsError) && (
              <>
                <PricingPlanPreview
                  plan={isTruBluPlan ? pricingPlan : defaultPlan}
                  referralPlan={promoCodeApplied !== '' && !isTruBluPlan ? pricingPlan : null}
                  isTrubluPlan={isTruBluPlan || false}
                  isEnterPrisePlan={isEnterPrisePlan}
                />
              </>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
