import { Grid, Box } from '@mui/material';
import { PageWithHeaderLayout } from '../../common/layout/PageWithHeaderLayout/PageWithHeaderLayout';
import { TwoSidePageLayout } from '../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { OnboardingWelcomeStrings } from '../../common/localization/en';
import { theme } from '../../theme';
import { useAppDispatch, useTypedSelector } from '../../store';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LeftSideWelcomeLayout } from './LeftSideWelcomeLayout/LeftSideWelcomeLayout';
import { RightSideWelcomeLayout } from './RightSideWelcomeLayout/RightSideWelcomeLayout';
import { VideosIconAnalytics } from '../../common/assets/newDesign/VideosIconAnalytics';
import { WebsiteRoutes } from '../../common/constants/routes';
// import { useFTE } from '../../hooks/useFTE';
import { useVenueProperty } from '../../hooks/useVenueProperty';
import { VenueProperties } from '../../api/models/venue';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../common/constants/events/quickstartEvents';
import { useFTE } from '../../hooks/useFTE';

import { getCampaigns } from '../../store/slices/campaignsSlice';

export type OnboardingMethodType = 'quick_start' | 'manual';

export const QuickStartWelcome = () => {
  const me = useTypedSelector((state) => state.me);
  const { isLoading } = useTypedSelector((state) => state.campaigns);
  const name = me?.firstName || me.name.split(' ')[0] || me.email;
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { onFinishFte } = useFTE();

  const [requestCount, setRequestCount] = useState<null | number>(null);

  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const { trackEvent } = useTrackEvent();

  const { value: quickStartValues, update: updateQuickStartConfig } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  useEffect(() => {
    if (venueId) {
      updateQuickStartConfig({
        ...quickStartValues,
        currentRoute: history.location.pathname,
        isQuickStartInProgress: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, venueId]);

  const handleNext = async () => {
    trackEvent(QuickStartEventNames.QuickStartGetStarted);

    history.push(WebsiteRoutes.QuickStartPersonalize);
  };

  const handleQuitQuickstart = async () => {
    await updateQuickStartConfig({
      ...quickStartValues,
      isQuickStartInProgress: false,
    });

    await onFinishFte();
    trackEvent(QuickStartEventNames.QuickStartDoThisLater);
    history.push(WebsiteRoutes.BrandingStyleTab);
  };

  useEffect(() => {
    if (venueId && !isLoading && requestCount === null) {
      dispatch(getCampaigns({ venueId, pageable: { page: 0, size: 10 } })).then(() => {
        setRequestCount(1);
      });
    }
  }, [dispatch, venueId, isLoading, requestCount]);

  return (
    <Box width="100%">
      <PageWithHeaderLayout
        header={{
          title: `${OnboardingWelcomeStrings.SetUp}`,
          icon: <VideosIconAnalytics width="30px" height="30px" />,
          filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25))',
          iconHeight: '30px',
          titleSmall: true,
        }}
        bgcolor={theme.palette.common.white}
        isFixed
      >
        <Grid container component="main" sx={{ backgroundColor: '#EAECF0', flexGrow: 1 }}>
          <TwoSidePageLayout
            containerWidth={'100%'}
            containerHeight={'100%'}
            loading={false}
            pxLeftSide={0}
            leftSideColumnWidths={7}
            leftSideNode={
              <LeftSideWelcomeLayout
                name={name}
                onGetStartedClick={handleNext}
                onDoThisLaterClick={handleQuitQuickstart}
              />
            }
            rightSideNode={<RightSideWelcomeLayout />}
          />
        </Grid>
      </PageWithHeaderLayout>
    </Box>
  );
};
