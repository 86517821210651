import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    margin: '0 auto',
  },
  layout: {
    width: '100%',
    overflowY: 'auto',
    height: 'calc(110vh - 80px)',
    marginBottom: '50px',
    display: 'flex',
    background: '#F5F6F8',
  },
  leftSide: {
    background: 'white',
    minHeight: 'calc(110vh - 80px)',
    height: 'fit-content',
    padding: '18px 0 50px',
    maxWidth: '520px',
  },
  rightSide: {
    background: '#F5F6F8',
    minHeight: 'calc(110vh - 80px)',
    height: 'fit-content',
  },
  description: {
    marginLeft: '30px',
    color: '#8B89A0',
    letterSpacing: '0.02em',
    lineHeight: '28px',
    fontSize: '12px',
    width: '350px',
  },
  topWrapper: {
    marginLeft: '20px',
    marginRight: '14px',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  nameTitle: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '30px',
    color: '#1A1538',
    letterSpacing: '0.02em',
    marginRight: '20px',
  },
  nameInput: {
    width: '264px',
  },
  descr: {
    marginTop: '12px',
    fontSize: '12px',
    fontWeight: '600',
    letterSpacing: '0.02em',
    lineHeight: '18px',
    color: '#252C32',
  },
  hint: {
    marginTop: '3px',
    fontSize: '12px',
    fontWeight: '400',
    letterSpacing: '0.02em',
    lineHeight: '18px',
    color: '#475467',
    paddingRight: '36px',
    maxWidth: '415px',
  },
  divider: {
    margin: '24px  40px 0 30px',
    borderTop: '1px solid #DCDBE4',
  },
  accordionWrapper: {
    margin: '0  20px 0 22px',
    paddingTop: '16px',
  },
  subTitle: {
    fontSize: '12px',
    fontWeight: 500,
    marginRight: '14px',
  },
  rewardInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '17px',
  },
  rewardInput: {
    width: '334px',
  },
  toggle: {
    width: '44px',
    height: '26px',
    '& .MuiSwitch-switchBase': {
      color: '#fff',
    },
    '& .MuiButtonBase-root': {
      padding: '3px 5px',
    },
    '& .MuiButtonBase-root.Mui-checked': {
      padding: '3px 9px',
    },
  },
  textWrapper: {
    width: '100%',
  },
  limitCampaignText: {
    color: '#344054',
    lineHeight: '24px',
    fontWeight: 500,
    fontSize: '14px',
  },
  primaryLimitCampaignText: {
    lineHeight: '24px',
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
  futureText: {
    color: '#475467',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '14px',
    width: '340px',
  },
  primaryFutureText: {
    color: theme.palette.primary.main,
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '14px',
    width: '340px',
  },
  togglesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '14px 0 30px 20px',
  },
  toggleWrapper: {
    display: 'flex',
    padding: '8px',
  },
  primaryToggleWrapper: {
    display: 'flex',
    backgroundColor: '#F9F5FF',
    borderRadius: '10px',
    marginRight: '10px',
    padding: '8px',
    marginBottom: '-16px',
  },
  editTextWrapper: {
    padding: '16px 24px 0 24px',
    width: '420px',
    fontSize: '14px',
  },
  editHeader: {
    fontWeight: 700,
    color: '#999',
    marginBottom: '20px',
  },
  editSupport: {
    fontWeight: 500,
    color: '#999',
  },
}));
