import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Toggle } from '../../../../common/components/toggle/CustomToggle';
import { useStyles } from '../WelcomeVideoStyles.styles';

interface DisplayWelcomeVideoToggleProps {
  onChange: () => void;
  showWelcomeVideo: boolean;
}

const DisplayWelcomeVideoToggle: FC<DisplayWelcomeVideoToggleProps> = ({
  onChange,
  showWelcomeVideo,
}) => {
  const styles = useStyles();
  return (
    <Box className={styles.toggleWrapper}>
      <Box>
        <Toggle pending={false} value={showWelcomeVideo} onChange={onChange} />
      </Box>
      <Box>
        <Typography className={styles.toggleWrapperTitle}>Display Welcome video</Typography>
        <Typography className={styles.toggleWrapperSubTitle}>
          Use our welcome video to tell creators what type of videos you want them to create.
        </Typography>
      </Box>
    </Box>
  );
};

export default DisplayWelcomeVideoToggle;
