import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { TypeSocialVerse } from '../../constants/constants';
export const useAddedVideoConfigStyle = makeStyles<Theme, { isScroll: number; viewMode?: string }>(
  (theme) => ({
    container: ({ viewMode }) => ({
      padding: '34px 54px 34px 46px;',
      display: viewMode === TypeSocialVerse.Educational ? 'flex' : 'inherit',
      justifyContent: viewMode === TypeSocialVerse.Educational ? 'center' : 'inherit',
      position: 'relative',
    }),
    wrapper: ({ viewMode }) => ({
      background: '#fff',
      padding: '30px 25px',
      borderRadius: 15,
      width: viewMode === TypeSocialVerse.Educational ? 'fit-content' : '100%',
    }),
    title: ({ viewMode }) => ({
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '30px',
      color: '#000',
      paddingBottom: 5,
      textAlign: viewMode === TypeSocialVerse.Educational ? 'center' : 'left',
    }),
    subTitle: ({ viewMode }) => ({
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '26px',
      color: '#8B89A0',
      textAlign: viewMode === TypeSocialVerse.Educational ? 'center' : 'left',
    }),
    circleBox: {
      width: 60,
      height: 60,
      borderRadius: '50%',
      backgroundColor: theme.palette.primary.main,
      opacity: '0.1',
      margin: '0 auto',
    },
    videoWrapper: {
      width: (props) =>
        props.viewMode === TypeSocialVerse.Educational
          ? 'fit-content'
          : props.isScroll
          ? `${props.isScroll}px`
          : '100%',
      padding: '25px 0',
      display: 'flex',
      flexWrap: 'nowrap',
    },
    videoNumberWrapper: {
      width: 'max-content',
      position: 'absolute',
      left: '0',
      bottom: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '1',
    },
    videoNumber: {
      zIndex: '1',
      width: '120px',
      fontFamily: 'Poppins',
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '26px',
      letterSpacing: '0.02em',
      textAlign: 'center',
      color: '#8B89A0',
    },
    videoScroll: (props) => ({
      overflowX: Boolean(props.isScroll) ? 'scroll' : 'initial',
      display: props.viewMode === TypeSocialVerse.Educational ? 'flex' : 'inherit',
      justifyContent: props.viewMode === TypeSocialVerse.Educational ? 'center' : 'inherit',
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-track': {
        marginTop: '75px',
        marginBottom: Boolean(props.isScroll) ? '4px' : '15px',
        marginLeft: '4px',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.light,
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.main,
      },
    }),
    cardHolderWrapper: (props) => ({
      height: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
        props.viewMode as TypeSocialVerse,
      )
        ? '150px'
        : '177px',
      width: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
        props.viewMode as TypeSocialVerse,
      )
        ? '150px'
        : '100px',
      aspectRatio: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
        props.viewMode as TypeSocialVerse,
      )
        ? '16:16'
        : '9/16',
      backgroundColor: '#F5F6F8',
      borderRadius: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
        props.viewMode as TypeSocialVerse,
      )
        ? '50%'
        : 15,
      margin: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
        props.viewMode as TypeSocialVerse,
      )
        ? 7
        : 15,
      marginBottom: '5px',
    }),
  }),
);
