import { Add } from '@mui/icons-material';
import { Box, Button, Divider, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import { DeleteIcon } from '../../../common/assets/newDesign/SocialVerse/DeletedIcons';
import { EditIcon } from '../../../common/assets/newDesign/WebAppIcons/EditIcon';
import { LeftIcon } from '../../../common/assets/newDesign/WebAppIcons/LeftIcon';
import { RightIcon } from '../../../common/assets/newDesign/WebAppIcons/RightIcon';
import { AddToWebsiteModal } from '../../../common/components/AddToWebsiteModal/AddToWebsiteModal';
import { ViewMode } from '../../../common/components/ViewMode/ViewMode';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
import { SocialVerseStrings } from '../../../common/localization/en';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { useAppDispatch } from '../../../store';
import { putEditSocialVerse } from '../../../store/slices/socialVerseSlice';
import { theme } from '../../../theme';
import { useSocialVerseCardStyles } from './SocialVerseCard.style';
import { GoogleSyncStatus } from '../../../common/components/GoogleBusinessProfile/GoogleSyncStatus/GoogleSyncStatus';
import { PreviewIcon } from '../../../common/assets/newDesign/SocialVerse/PreviewIcon';
import { LivePreviewIcon } from '../../../common/assets/newDesign/SocialVerse/LivePreviewIcon';
import SocialVersePreview from './SocialVersePreview';

interface EmptyListProps {
  handleClick: () => void;
}

export const EmptyList: FC<EmptyListProps> = ({ handleClick }) => {
  const classes = useSocialVerseCardStyles();
  return (
    <Grid
      container
      alignItems="end"
      sx={{
        position: 'relative',
        width: '516px',
      }}
    >
      <LeftIcon className={[classes.navigIcon, classes.leftIcon].join(' ')} />
      <RightIcon className={[classes.navigIcon, classes.rightIcon].join(' ')} />
      {[...Array(5)].map((_el, i) => (
        <Box
          key={i}
          sx={{
            cursor: i === 0 ? 'pointer' : 'default',
            opacity: i === 0 ? 1 : 0.8 - i * 0.15,
            width: '99px',
            height: '176px',
          }}
          className={classes.EmptyBox}
          onClick={() => (i === 0 ? handleClick() : {})}
        >
          {'+'}
        </Box>
      ))}
    </Grid>
  );
};

interface SocialVerseCardProps {
  socialverse: SocialVerseAllApiModel;
  handleAddVideo: (id: string) => void;
  handleDeleteModalToggle: (id: string) => void;
  cardIndex: number;
  isGridLayout: boolean;
}

export const SocialVerseCard: FC<SocialVerseCardProps> = ({
  socialverse,
  handleAddVideo,
  handleDeleteModalToggle,
  cardIndex,
  isGridLayout,
}) => {
  const classes = useSocialVerseCardStyles();
  const [isSvNameEdit, setIsSvNameEdit] = useState(false);

  const [nameEditVal, setNameEditVal] = useState(socialverse.name);
  const [isAddToWebsiteModalOpen, setIsAddToWebsiteModalOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { trackEvent } = useTrackEvent();

  const saveSocialverseName = async () => {
    setIsSvNameEdit(false);

    await dispatch(
      putEditSocialVerse({
        id: socialverse.id,
        data: {
          name: nameEditVal,
          title: socialverse.title,
          viewMode: socialverse.viewMode,
          keywordFilterEnabled: socialverse.keywordFilterEnabled,
          showTitle: socialverse.showTitle,
          showUserInfo: socialverse.showUserInfo,
        },
      }),
    );
  };

  const ref = useDetectClickOutside({
    onTriggered: async () => {
      if (isSvNameEdit) {
        await saveSocialverseName();
      }
    },
  });

  const handleNameEditChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNameEditVal(event.target.value);
    trackEvent(SocialVerseEventNames.changeSocialVerseNameTyped, {
      socialVerseId: socialverse.id,
      value: event.target.value,
    });
  };

  const handleSvNameEdit = () => {
    setTimeout(() => {
      setIsSvNameEdit(true);
    }, 0);

    trackEvent(SocialVerseEventNames.changeSocialVerseName, { socialVerseId: socialverse.id });
  };

  const handleOpenModal = () => {
    setIsAddToWebsiteModalOpen(true);
  };

  const onInputNameEditEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await saveSocialverseName();
      trackEvent(SocialVerseEventNames.saveSocialVerseName, { socialVerseId: socialverse.id });
    } else if (event.key === 'Escape') {
      setIsSvNameEdit(false);

      setNameEditVal(socialverse.name);
    }
  };

  const parseWebsiteUrl = (url: string) => {
    const parsedUrl = url.split('/').filter((part) => part !== '');

    if (parsedUrl.length >= 3) {
      return `${parsedUrl[0]}//${parsedUrl[1]}/...`;
    }

    return url;
  };

  return (
    <>
      <AddToWebsiteModal
        isOpen={isAddToWebsiteModalOpen}
        socialverse={socialverse}
        actualSocialverseName={nameEditVal}
        onClose={() => {
          setIsAddToWebsiteModalOpen(false);
          trackEvent(SocialVerseEventNames.closeAddToWebsite);
        }}
        onCopyHeaderClick={() =>
          trackEvent(SocialVerseEventNames.copyHeaderCode, { socialVerseId: socialverse.id })
        }
        onCopyBodyClick={() =>
          trackEvent(SocialVerseEventNames.copyBodyCode, { socialVerseId: socialverse.id })
        }
      />
      <Grid
        container
        className={classes.containerSV}
        style={{ height: isGridLayout ? '240px' : 'auto' }}
      >
        <Grid
          item
          xs={isGridLayout ? 0 : 6}
          sx={{ paddingRight: isGridLayout ? 0 : '50px', width: '100%' }}
        >
          <Grid className={classes.innerContainerSV}>
            <Grid className={classes.topWrapper}>
              <ViewMode viewMode={socialverse.viewMode} />
              <IconButton
                onClick={() => {
                  handleDeleteModalToggle(socialverse.id);
                  trackEvent(SocialVerseEventNames.deleteSocialVerse, {
                    socialVerseId: socialverse.id,
                  });
                }}
                id={`delete-card-button-${cardIndex}`}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            {!isSvNameEdit ? (
              <Grid
                className={classes.titleWrapper}
                style={{ marginTop: isGridLayout ? 0 : '15px' }}
              >
                <Typography className={classes.titleSV}>{nameEditVal}</Typography>
                <IconButton
                  className={classes.editButton}
                  onClick={handleSvNameEdit}
                  id={`edit-card-button-${cardIndex}`}
                >
                  <EditIcon
                    stroke={theme.palette.common.white}
                    fill={theme.palette.primary.main}
                    fillOpacity={1}
                    width={50}
                    height={50}
                  />
                </IconButton>
              </Grid>
            ) : (
              <input
                type="text"
                value={nameEditVal}
                onChange={handleNameEditChange}
                className={classes.nameEditInput}
                onKeyDown={onInputNameEditEnter}
                maxLength={36}
                ref={ref}
                style={{ margin: isGridLayout ? '0 0 10px 0' : '35px 0 15px 0' }}
                id={`socialverse-name-input-${cardIndex}`}
              />
            )}

            <Box
              sx={{
                marginBottom: isSvNameEdit ? '41px' : '70px',
                marginTop: '-10px',
              }}
            >
              {socialverse.lastSeenAt && socialverse.lastSeenLocationUrl && (
                <Typography display="inline" className={classes.lastSeenText}>
                  {`Added to: `}
                  <a
                    href={socialverse.lastSeenLocationUrl}
                    className={classes.lastSeenUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {parseWebsiteUrl(socialverse.lastSeenLocationUrl)}
                  </a>
                </Typography>
              )}
            </Box>

            <Box
              className={classes.btnsWrapper}
              style={{
                gap: isGridLayout ? '10px' : '16px',
                borderTop: isGridLayout ? '1px solid #EAECF0' : 'none',
                padding: isGridLayout ? '16px 24px' : 0,
                position: isGridLayout ? 'absolute' : 'relative',
              }}
            >
              <Tooltip
                title="Videos/Display"
                arrow
                followCursor
                placement="top"
                classes={classes}
                disableHoverListener={!isGridLayout}
              >
                <Button
                  startIcon={isGridLayout ? <></> : <Add />}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleAddVideo(socialverse.id);
                    trackEvent(SocialVerseEventNames.addVideos, { socialVerseId: socialverse.id });
                  }}
                  className={classes.cardBtn}
                  style={{ padding: isGridLayout ? '0 16px' : '0 20px' }}
                  id={`add-videos-button-${cardIndex}`}
                >
                  {isGridLayout ? SocialVerseStrings.Manage : SocialVerseStrings.AddEditVideos}
                </Button>
              </Tooltip>
              <Button
                variant="outlined"
                onClick={() => {
                  handleOpenModal();
                  trackEvent(SocialVerseEventNames.addToWebsite, { socialVerseId: socialverse.id });
                }}
                className={classes.cardBtn}
                style={{ padding: isGridLayout ? '0 16px' : '0 20px' }}
                sx={{ border: '1px solid #5242EA' }}
              >
                {isGridLayout ? SocialVerseStrings.GetCode : SocialVerseStrings.AddToYourWebsite}
              </Button>
              {socialverse && <GoogleSyncStatus socialVerse={socialverse} disableClick />}
              {isGridLayout && (
                <Box sx={{ marginLeft: 'auto' }}>
                  {socialverse.lastSeenAt && socialverse.lastSeenLocationUrl ? (
                    <Tooltip
                      title={SocialVerseStrings.ViewItLive}
                      arrow
                      followCursor
                      placement="top"
                      classes={classes}
                    >
                      <Link href={socialverse.lastSeenLocationUrl} target="_blank" rel="noreferrer">
                        <LivePreviewIcon />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      arrow
                      followCursor
                      placement="top"
                      classes={classes}
                      title={
                        <SocialVersePreview
                          socialverse={socialverse}
                          handleAddVideo={handleAddVideo}
                        />
                      }
                    >
                      <Box>
                        <PreviewIcon />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {!isGridLayout && (
          <Grid item xs={6} sx={{ display: 'flex' }}>
            <Divider className={classes.divider} />
            <SocialVersePreview socialverse={socialverse} handleAddVideo={handleAddVideo} />
          </Grid>
        )}
      </Grid>
    </>
  );
};
