import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const PurpleMain = '#5242EA';

export const useBottomToolbarStyles = makeStyles((theme: Theme) => ({
  ButtonsContainer: {
    position: 'sticky',
    zIndex: 1205,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -10px 34px rgba(23, 25, 78, 0.05)',
    padding: 28,
    width: '100%',
    left: 0,
  },
  Buttons: {
    boxShadow: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    padding: '10px 25px',
    borderRadius: '100px',
    border: '1px solid',
    borderColor: PurpleMain,
    color: PurpleMain,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: PurpleMain,
      boxShadow: 'none',
    },
  },
  ButtonsFilled: {
    backgroundColor: PurpleMain,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: PurpleMain,
      color: theme.palette.common.white,
    },
  },
  ButtonVideoLink: {
    color: PurpleMain,
  },
  DeleteButton: {
    color: theme.palette.error.main,
  },
}));
