import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={33}
    height={32}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.25 31h9c7.5 0 10.5-3 10.5-10.5v-9c0-7.5-3-10.5-10.5-10.5h-9c-7.5 0-10.5 3-10.5 10.5v9c0 7.5 3 10.5 10.5 10.5Z"
      stroke={props.color || '#fff'}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.75 21.25a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5Z"
      stroke={props.color || '#fff'}
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.203 8.5h.018"
      stroke={props.color || '#fff'}
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
