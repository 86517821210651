import { CSSProperties, FC, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import TooltipMUI from '@mui/material/Tooltip';

import { TooltipCircle } from './CommonTable.style';
interface Props {
  padding: string;
  rounded: string;
  light: boolean;
  pointerEvents?: boolean;
  marginBottom: number;
  style?: CSSProperties;
}
const useStyles = makeStyles<any, Props>((theme) => ({
  arrow: ({ light }) => ({
    color: light ? theme.palette.common.white : theme.palette.common.deepDark,
    '&:before': {
      border: light ? `1px solid #e1e5ee` : 'none',
    },
  }),
  tooltip: ({ light, padding, rounded, pointerEvents }) => ({
    backgroundColor: light ? theme.palette.common.white : theme.palette.common.deepDark,
    color: light ? '#667085' : theme.palette.primary.contrastText,
    maxWidth: light ? 235 : 220,
    fontSize: light ? 12 : 14,
    border: '1px solid #dadde9',
    padding: padding || '0',
    borderRadius: rounded || '0',
    textAlign: light ? 'center' : 'left',
    pointerEvents: pointerEvents ? 'auto' : 'none',
  }),
  icon: {
    verticalAlign: 'middle',

    '&:hover': {
      fill: theme.palette.info.light,
    },
  },
  popper: ({ marginBottom, style }) =>
    style
      ? {
          marginBottom: `${marginBottom - 10}px !important`,
          ...style,
        }
      : {},
}));

export function HtmlTooltip({
  followCursor = true,
  style,
  ...props
}: {
  children: JSX.Element;
  title: JSX.Element | string;
  rounded: string;
  padding: string;
  light: boolean;
  isOpen?: boolean;
  interactive?: boolean;
  followCursor?: boolean;
  style?: CSSProperties;
  pointerEvents?: boolean;
}) {
  const ref = useRef();
  const classes = useStyles({
    rounded: props.rounded,
    padding: props.padding,
    light: props.light,
    pointerEvents: props.pointerEvents,
    marginBottom: Number((ref?.current as any)?.getBoundingClientRect().top || 0) * 0.1,
    style: style,
  });

  return (
    <TooltipMUI
      ref={ref}
      arrow
      placement="top"
      classes={classes}
      {...props}
      followCursor={followCursor}
      disableInteractive={!props.interactive}
      open={props.isOpen}
    />
  );
}

export interface TooltipProps {
  title: string | JSX.Element;
  text?: string;
  rounded?: string;
  padding?: string;
  icon?: JSX.Element;
  light?: boolean;
  isOpen?: boolean;
  followCursor?: boolean;
}

// TODO: Move to shared components -> /common/components/Tooltip
export const Tooltip: FC<TooltipProps> = ({
  title,
  text,
  icon,
  rounded = '0',
  padding = '0',
  light = false,
  isOpen,
  followCursor,
}) => {
  return (
    <HtmlTooltip
      rounded={rounded}
      padding={padding}
      title={
        typeof title === 'string' ? (
          <>
            <Typography variant="body2" color="inherit">
              {title}
            </Typography>
            {text && (
              <>
                <br />
                <Typography variant="caption" color="inherit">
                  {text}
                </Typography>
              </>
            )}
          </>
        ) : (
          title
        )
      }
      light={light}
      isOpen={isOpen}
      followCursor={followCursor}
    >
      {icon ? (
        icon
      ) : (
        <TooltipCircle>
          <span>?</span>
        </TooltipCircle>
      )}
    </HtmlTooltip>
  );
};
