export const ConfirmIcon = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 0C11.276 0 0.5 10.776 0.5 24C0.5 37.224 11.276 48 24.5 48C37.724 48 48.5 37.224 48.5 24C48.5 10.776 37.724 0 24.5 0ZM35.972 18.48L22.364 32.088C22.028 32.424 21.572 32.616 21.092 32.616C20.612 32.616 20.156 32.424 19.82 32.088L13.028 25.296C12.332 24.6 12.332 23.448 13.028 22.752C13.724 22.056 14.876 22.056 15.572 22.752L21.092 28.272L33.428 15.936C34.124 15.24 35.276 15.24 35.972 15.936C36.668 16.632 36.668 17.76 35.972 18.48Z"
        fill="#67CE67"
      />
    </svg>
  );
};
