import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import {
  CommonStrings,
  NotFoundPageStrings,
  SavedFiltersPageStrings,
  VenueCreationWizardStrings,
} from '../../../common/localization/en';
import { useFiltersStyles } from '../Filters.style';

import { useAppDispatch, useTypedSelector } from '../../../store';

import { useCallback, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ClientTypes } from '../../../api/models/common';
import { DownloadIconSVG } from '../../../common/assets/DownloadIcon';
import { MedalIcon } from '../../../common/assets/newDesign/MedalIcon';
import { UsersIcon } from '../../../common/assets/sideBarAssets/UsersIcon';
import { ActiveModal } from '../../../common/components/ActionBar/ActionBar';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { CommonTable } from '../../../common/components/table/CommonTable';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import {
  getHeroes,
  getUsersBySearchFilter,
  goToSelectedPage as goToSelectedUserPage,
  reset as resetUser,
  setUserDataSorting,
  updateSize as updateUserTableSize,
} from '../../../store/slices/heroesSlice';
import {
  postSearchFilters,
  resetActiveFilter,
  resetNewFilter,
  setActiveFilter,
} from '../../../store/slices/searchFiltersSlice';
import {
  goToSelectedPage as goToSelectedRowsPage,
  resetPage,
  resetSelectedRows,
  updateSelectedPageSize,
} from '../../../store/slices/selectedRowsSlice';

import { approveReward } from '../../../store/slices/userDetailsSlice';
import { theme } from '../../../theme';
import {
  backButtonRoutes,
  generateEntity,
  getDownloadCSVModalUserContents,
  getHeaders,
  getUserDataTableContents,
  Header,
  Modals,
  updateSearch,
} from '../CreatedFilter.helper';
import { CreatedFilterModal } from '../CreatedFilterMdal';
import { SearchFilterApiModel } from '../../../api/models/searchFilter';
import { SalesDetailsModal } from '../../../common/components/NewDetailsModal/SalesDetailsModal';
import { useFilterTablesStyles } from './TablesStyles';
import { useClientType } from '../../../services/hooks/useClientType';
import { LAYOUT_CONTAINER_CLASSNAME } from '../../../common/constants/constants';

export const UsersFilter = () => {
  const styles = useFiltersStyles();
  const classes = useFilterTablesStyles();
  const history: any = useHistory();
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const match = useRouteMatch();
  const params = useParams<{ id: string }>();
  const { clientType, id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { selectedRows: selected } = useTypedSelector((state) => state.selectedRows);
  const [selectedRows, setSelectedRows] = useState<any[] | null>(null);
  const {
    isLoading,
    items: userItems,
    page: userPage,
    totalItems: userTotalItems,
    totalPages: userTotalPages,
    size: userSize,
    sort: userSort,
    search: userSearch = '',
  } = useTypedSelector((state) => state.heroes);

  const [open, setOpen] = useState(false);
  const { activeFilter, target, newFilterAttributes } = useTypedSelector(
    (state) => state.SearchFilterSlice,
  );
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const [isRedeemPointsModal, setIsRedeemPointsModal] = useState(false);
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const { isHealthCare } = useClientType();

  const handleClose = () => {
    setActiveModal(null);
  };

  useEffect(() => {
    if (target === 'USERS' || target === 'INCENTIVE_CAMPAIGNS') {
      dispatch(
        getUsersBySearchFilter({
          venueId,
          filter:
            newFilterAttributes.length > 0
              ? newFilterAttributes
              : activeFilter?.filter.attributes ?? [],
          pageable: {
            page: userPage,
            size: userSize,
            sort: userSort,
          },
          search: userSearch,
        }),
      );
    }
  }, [
    activeFilter,
    dispatch,
    target,
    userPage,
    userSearch,
    userSize,
    userSort,
    venueId,
    newFilterAttributes,
  ]);

  const toggleRedeemPointsModal = useCallback(async () => {
    setIsRedeemPointsModal(!isRedeemPointsModal);
  }, [isRedeemPointsModal]);

  const approveRewardHandler = useCallback(
    async (userId) => {
      const response = await dispatch(approveReward({ id: userId }));
      if (response.payload) {
        addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        dispatch(
          getHeroes({
            search: userSearch,
            venueId,
            pageable: { page: userPage, size: userSize, sort: userSort },
          }),
        );
        toggleRedeemPointsModal();
        return;
      }
      addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
      dispatch(
        getHeroes({
          search: userSearch,
          venueId,
          pageable: { page: userPage, size: userSize, sort: userSort },
        }),
      );
      toggleRedeemPointsModal();
    },
    [
      addToast,
      dispatch,
      toggleRedeemPointsModal,
      userPage,
      userSize,
      userSort,
      venueId,
      userSearch,
    ],
  );

  const userDataTableContents = getUserDataTableContents(
    selectedRows ? selectedRows : userItems || [],
    activeCampaign,
    clientType || ClientTypes.SALES,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
    isHealthCare,
  );

  const downloadCSVModalContent = getDownloadCSVModalUserContents(
    selectedRows ? selectedRows : userItems || [],
    activeCampaign,
    clientType || ClientTypes.SALES,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
    isHealthCare,
  );

  const handleGoBack = () => {
    dispatch(resetNewFilter());
    dispatch(resetActiveFilter());
    dispatch(resetSelectedRows());
    dispatch(resetPage());
    dispatch(resetUser());
    history.push(backButtonRoutes[target ?? 'VIDEOS']);
  };

  const handleSaveBtn = () => {
    setOpen(!open);
  };

  const handleConfirm = (name: string) => {
    if (newFilterAttributes.length && target) {
      dispatch(
        postSearchFilters({
          venueId,
          name,
          target,
          filter: {
            attributes: newFilterAttributes,
          },
          searchText: '',
        }),
      ).then(() =>
        addToast(CommonStrings.NewFilterSuccesfullySaved, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        }),
      );
      setOpen(false);
      dispatch(
        setActiveFilter({
          name: name,
          target,
          filter: {
            attributes: newFilterAttributes,
          },
        } as SearchFilterApiModel),
      );
    }
  };

  const onGoToUserPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= userTotalPages) {
      return;
    }

    dispatch(goToSelectedUserPage(convertedTargetPage));
    dispatch(goToSelectedRowsPage(convertedTargetPage));
  };

  const changeUserTableSize = (size: number) => {
    dispatch(updateUserTableSize(size));
    dispatch(updateSelectedPageSize(size));
  };

  const updateSearchHandler = (search: string) => {
    return updateSearch(
      search,
      getUsersBySearchFilter,
      activeFilter?.filter?.attributes || [],
      venueId,
      userPage,
      userSize,
      userSort,
    );
  };

  const handleRowClick = (id: string) => {
    history.push(match.path.replace(':id', id));
  };

  const close = () => {
    history.push(match.path.replace(':id', ''));
  };

  const onSort = (name: string) => {
    dispatch(setUserDataSorting(name));
  };

  useEffect(() => {
    return () => {
      dispatch(resetNewFilter());
      dispatch(resetSelectedRows());
      dispatch(resetPage());
      dispatch(resetUser());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={[styles.Container, LAYOUT_CONTAINER_CLASSNAME].join(' ')}>
      {activeModal && target && (
        <Modals
          activeModal={activeModal}
          handleClose={handleClose}
          headers={getHeaders[target]}
          content={downloadCSVModalContent}
          setSelectedRows={(selected) => setSelectedRows(selected)}
        />
      )}
      <Header
        handleGoBack={handleGoBack}
        handleSaveBtn={handleSaveBtn}
        target={target ?? 'VIDEOS'}
        showSaveBtn={activeFilter ? false : true}
        filterName={activeFilter?.name}
      />
      <main>
        <Grid container flexWrap={'nowrap'}>
          <Grid item className={styles.Sidebar} minWidth={280}>
            <Box width="90%" marginLeft="auto" marginRight="auto">
              <Divider />
            </Box>
            <Typography className={styles.PageDescription}>
              {SavedFiltersPageStrings.BulkDescription}
            </Typography>
            <Grid spacing={3} container direction="column" className={styles.BulkList}>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    if (selected && selected.length <= 100) {
                      setActiveModal('DOWNLOAD_CSV');
                    } else {
                      setActiveModal('DOWNLOAD_CSV_LIMIT');
                    }
                  }}
                  variant="contained"
                  size="large"
                  className={styles.Btn}
                  disabled={!selected || !selected.length}
                >
                  <span
                    style={{
                      color:
                        !selected || !selected.length
                          ? theme.palette.text.secondary
                          : theme.palette.common.black,
                      display: 'flex',
                    }}
                  >
                    <div className={styles.Icon} style={{ stroke: theme.palette.common.white }}>
                      <DownloadIconSVG />
                    </div>
                  </span>
                  {SavedFiltersPageStrings.DownloadCSV}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    if (selected && selected.length <= 10) {
                      setActiveModal('ASSIGN_BONUS_POINTS');
                    } else {
                      setActiveModal('ASSIGN_BONUS_POINTS_LIMIT');
                    }
                  }}
                  variant="contained"
                  size="large"
                  className={styles.Btn}
                  disabled={!selected?.length || !activeCampaign}
                >
                  <span style={{ display: 'flex' }}>
                    <div className={styles.Icon} style={{ stroke: theme.palette.common.white }}>
                      <MedalIcon />
                    </div>
                  </span>
                  {SavedFiltersPageStrings.RewardNow}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={styles.Content}>
            <Grid container alignItems="center" justifyContent="space-between">
              {target && (
                <Box className={styles.Search}>
                  <SearchBar entity={generateEntity[target]} updateSearch={updateSearchHandler} />
                </Box>
              )}
              <Box className={styles.TotalNumberSection}>
                <span style={{ stroke: theme.palette.common.gray, paddingTop: '6px' }}>
                  <UsersIcon />
                </span>
                <Typography className={styles.TotalNumberSectionText}>
                  {`${userTotalItems} ${isHealthCare ? CommonStrings.Patients : 'Creators'}`}
                </Typography>
              </Box>
            </Grid>

            {target === 'USERS' && (
              <Grid item className={styles.Table}>
                <div className={classes.UsersFilterTableStyles}>
                  <CommonTable
                    selectedRowId={params?.id || ''}
                    content={userDataTableContents}
                    page={convertApiPageToFrontEndPage(userPage)}
                    sort={userSort}
                    totalItems={userTotalItems}
                    totalPages={userTotalPages}
                    isLoading={isLoading}
                    tablePadding="0"
                    goToPage={onGoToUserPage}
                    size={userSize}
                    onSizeChange={changeUserTableSize}
                    noContent={!Boolean(userItems.length)}
                    activeFilter
                    onClickRow={handleRowClick}
                    onSortHeaderClick={onSort}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </main>
      <CreatedFilterModal
        modalIsOpen={open}
        handleClose={handleSaveBtn}
        handleConfirm={(name) => {
          handleConfirm(name);
          // handleGoBack();
        }}
        target={target}
      />
      <SalesDetailsModal isOpen={!!params.id} userId={params.id} handleClose={close} />
    </div>
  );
};
