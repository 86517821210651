import React, { useRef } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import { useImproveBusinessTypesSwitchStyles } from './ImproveBusinessTypesSwitch.styles';
import RewardTypeButton from '../../../common/components/RewardTypeButton/RewardTypeButton';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

import { NewCampaignEvents } from '../../../common/constants/events/newCampaignEvents';

import { RewardStrings } from '../../../common/localization/en';
import VideoTypeMarketingIcon from '../../../common/assets/QuickStartIcons/VideoTypeMarketingIcon';
import VideoTypeMarketingIconActive from '../../../common/assets/QuickStartIcons/VideoTypeMarketingIconActive';
import VideoTypeWebsiteSEOIconActive from '../../../common/assets/QuickStartIcons/VideoTypeWebsiteSEOIconActive';
import VideoTypeWebsiteSEOIcon from '../../../common/assets/QuickStartIcons/VideoTypeWebsiteSEOIcon';
import VideoTypeFriendsAndFamilyIconActive from '../../../common/assets/QuickStartIcons/VideoTypeFriendsAndFamilyIconActive';
import VideoTypeFriendsAndFamilyIcon from '../../../common/assets/QuickStartIcons/VideoTypeFriendsAndFamilyIcon';
import { Activity, CustomerActivitiesType } from '../../../api/models/campaigns';
import {
  activitiesForFriendsAndFamily,
  activitiesForMarketing,
  activitiesForWebsiteSEO,
} from '../../../common/constants/defaults';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  setActivities,
  setCampaignObjective,
  setCustomerActivityType,
} from '../../../store/slices/createCampaignSlice';

export const rewardButtons = [
  {
    title: RewardStrings.Marketing,
    type: CustomerActivitiesType.videoContent,
    activeIcon: <VideoTypeMarketingIconActive />,
    icon: <VideoTypeMarketingIcon />,
  },
  {
    title: RewardStrings.WebsiteSEO,
    type: CustomerActivitiesType.showcase,
    activeIcon: <VideoTypeWebsiteSEOIconActive />,
    icon: <VideoTypeWebsiteSEOIcon />,
  },
  {
    title: RewardStrings.FriendsAndFamily,
    type: CustomerActivitiesType.sharing,
    activeIcon: <VideoTypeFriendsAndFamilyIconActive />,
    icon: <VideoTypeFriendsAndFamilyIcon />,
  },
];

export const ImproveBusinessTypesSwitch = () => {
  const inputRef = useRef<HTMLInputElement>();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { campaignObjective } = useTypedSelector((state) => state.createCampaign);

  const handleButtonClick = (type: CustomerActivitiesType) => {
    trackEvent(NewCampaignEvents.NewRewardCampaignImproveBusinessTypesSwitch, { value: type });
    if (inputRef?.current) {
      inputRef.current.focus();
    }
    const defaultActivities = getDefaultActivities(type);
    dispatch(setActivities(defaultActivities as unknown as Activity[]));
    dispatch(setCustomerActivityType(type));
    dispatch(setCampaignObjective(type));
  };

  const getDefaultActivities = (activityType: CustomerActivitiesType) => {
    const defaultActivitiesMap: Record<CustomerActivitiesType, Activity[]> = {
      [CustomerActivitiesType.videoContent]: activitiesForMarketing,
      [CustomerActivitiesType.showcase]: activitiesForWebsiteSEO,
      [CustomerActivitiesType.sharing]: activitiesForFriendsAndFamily,
    };
    return defaultActivitiesMap[activityType];
  };
  const styles = useImproveBusinessTypesSwitchStyles();

  return (
    <Box className={styles.rewardTypes}>
      {rewardButtons.map((button) => {
        const isActive = button.type === campaignObjective;
        return (
          <RewardTypeButton
            icon={isActive ? button.activeIcon : button.icon}
            title={button.title}
            onClick={() => {
              handleButtonClick(button.type);
            }}
            isActive={isActive}
            key={button.title}
            className={clsx(styles.button, {
              [styles.active]: button.type === campaignObjective,
            })}
          />
        );
      })}
    </Box>
  );
};
