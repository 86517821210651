import { FC, ReactNode } from 'react';

import { SideBar } from './sideBar/SideBar';
import { Container, ContentContainer } from './Layout.style';
import { LAYOUT_CONTAINER_CLASSNAME } from '../constants/constants';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <Container>
      <SideBar />
      <ContentContainer className={LAYOUT_CONTAINER_CLASSNAME}>{children}</ContentContainer>
    </Container>
  );
};
