import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={83} height={83} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M83 4.016V52.21a4.01 4.01 0 0 1-4.016 4.016 4.016 4.016 0 0 1-3.815-2.771 3.792 3.792 0 0 1-.201-1.245V4.016c0-.442.067-.87.214-1.258A3.988 3.988 0 0 1 78.984 0c1.111 0 2.115.455 2.838 1.178A4.007 4.007 0 0 1 83 4.016Z"
        fill="#9A9A9A"
      />
      <path
        d="M74.968 4.016c0-.441.067-.87.214-1.258l-.214-.08S61.366 12.57 18.715 15.717l.027.348v24.097l-.027.348c7.028.522 13.253 1.218 18.769 2.035 27.443 4.016 37.109 10.736 37.47 10.99l.215-.08a3.795 3.795 0 0 1-.201-1.245V4.016Z"
        fill="#9E96ED"
      />
      <path d="M37.484 42.839v12.048h-5.355l-1.258-8.768 6.613-3.28Z" fill="#393D48" />
      <path
        d="m30.87 46.118-8.3 4.124c-.749.415-1.606.629-2.463.629h-.026a5.181 5.181 0 0 1-4.994-3.923l-1.7-6.787c1.82.107 3.601.214 5.328.348 7.028.522 13.253 1.218 18.769 2.035v.295l-6.613 3.28Z"
        fill="#292D32"
      />
      <path
        d="M35.703 79.947c.241 1.607-1.017 3.053-2.65 3.053h-6.587a2.687 2.687 0 0 1-2.664-2.343L20.081 50.87h.026c.857 0 1.714-.215 2.464-.63l8.3-4.123 1.258 8.769 3.574 25.06Z"
        fill="#9E96ED"
      />
      <path
        d="M18.742 16.065v24.097l-.027.348a289.225 289.225 0 0 0-5.328-.348V16.065c1.82-.107 3.601-.214 5.328-.348l.027.348Z"
        fill="#9A9A9A"
      />
      <path
        d="M13.387 33.468v6.693H5.355A5.353 5.353 0 0 1 0 34.806V21.42a5.353 5.353 0 0 1 5.355-5.355h8.032v17.404Z"
        fill="#393D48"
      />
      <path
        d="M80.323 45.516a2.685 2.685 0 0 1-2.678-2.677V.228a3.992 3.992 0 0 0-2.463 2.53 3.553 3.553 0 0 0-.214 1.258V52.21c0 .441.067.856.2 1.245a4.016 4.016 0 0 0 3.816 2.77A4.01 4.01 0 0 0 83 52.21v-9.371a2.685 2.685 0 0 1-2.677 2.677Z"
        fill="#4C5764"
      />
      <path
        d="M74.968 44.177c-5.61-5.609-27.497-9.25-40.229-10.95a10.712 10.712 0 0 1-9.303-10.616v-7.47c-2.156.214-4.392.401-6.72.575l.026.348v24.097l-.027.348c7.028.522 13.253 1.218 18.769 2.035 27.443 4.016 37.109 10.737 37.47 10.99l.215-.08a3.795 3.795 0 0 1-.201-1.245v-8.032Z"
        fill="#5242EA"
      />
      <path
        d="M18.742 30.79h-5.355v9.371c1.82.107 3.601.214 5.328.348l.027-.348V30.79Z"
        fill="#4C5764"
      />
      <path
        d="M13.387 30.79H10.71a5.353 5.353 0 0 1-5.355-5.355v-9.37A5.353 5.353 0 0 0 0 21.418v13.387a5.353 5.353 0 0 0 5.355 5.355h8.032v-9.37Z"
        fill="#2B2B37"
      />
      <path
        d="M6.694 21.419h6.693v2.677H6.694a1.343 1.343 0 0 1-1.34-1.338c0-.737.603-1.34 1.34-1.34ZM6.694 26.773h6.693v2.678H6.694a1.343 1.343 0 0 1-1.34-1.339c0-.736.603-1.339 1.34-1.339Z"
        fill="#FBFBFB"
      />
      <path
        d="M6.694 32.129h6.693v2.677H6.694a1.343 1.343 0 0 1-1.34-1.338c0-.737.603-1.34 1.34-1.34Z"
        fill="#D6E0EB"
      />
      <path
        d="M49.532 37.484c-.348 0-.682-.134-.95-.388a1.333 1.333 0 0 1 0-1.888l16.064-16.064a1.333 1.333 0 0 1 1.888 0 1.333 1.333 0 0 1 0 1.887L50.469 37.096a1.314 1.314 0 0 1-.95.388h.013ZM61.58 38.822a4.028 4.028 0 0 1-4.015-4.016 4.028 4.028 0 0 1 4.016-4.016 4.028 4.028 0 0 1 4.016 4.016 4.028 4.028 0 0 1-4.016 4.016Zm0-5.355c-.736 0-1.338.603-1.338 1.34 0 .735.602 1.338 1.339 1.338.736 0 1.338-.603 1.338-1.339s-.602-1.338-1.338-1.338ZM53.548 25.436a4.028 4.028 0 0 1-4.016-4.017 4.028 4.028 0 0 1 4.016-4.016 4.028 4.028 0 0 1 4.017 4.016 4.028 4.028 0 0 1-4.017 4.017Zm0-5.355c-.736 0-1.338.602-1.338 1.338 0 .737.602 1.34 1.338 1.34.737 0 1.34-.603 1.34-1.34 0-.736-.603-1.338-1.34-1.338Z"
        fill="#FBFBFB"
      />
      <path
        d="m27.805 80.67-3.454-26.453a2.673 2.673 0 0 1 1.607-2.81l5.341-2.29-.428-2.999-8.3 4.123c-.75.415-1.607.63-2.464.63h-.026l3.721 29.786A2.687 2.687 0 0 0 26.466 83h4.003a2.683 2.683 0 0 1-2.65-2.33h-.014Z"
        fill="#5242EA"
      />
      <path
        d="m35.141 47.23 2.343-1.34v-3.065l-6.613 3.28 1.258 8.769h2.677l-.963-4.806a2.68 2.68 0 0 1 1.298-2.852v.014Z"
        fill="#2B2B37"
      />
      <path
        d="M37.484 42.544c-5.516-.817-11.74-1.513-18.769-2.035a289.225 289.225 0 0 0-5.328-.348l1.7 6.787a5.181 5.181 0 0 0 4.994 3.923h.026c.282 0 .563-.027.844-.08l-1.62-4.86a1.342 1.342 0 0 1 1.272-1.767h14.19l2.704-1.339v-.294l-.013.013Z"
        fill="#4C5764"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h83v83H0z" />
      </clipPath>
    </defs>
  </svg>
);

const CustomRewardIcon = memo(SvgComponent);
export default CustomRewardIcon;
