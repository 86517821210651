import { GetMeResponse } from './../../api/models/auth';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MeApiModel,
  PatchMe,
  UpdatePassword,
  PatchmeOptions,
  ResetPasswordOptions,
} from '../../api/models/auth';
import { httpClient } from '../../services/httpClient/httpClient';
import { AuthEndpoints, getApiUrlForId, VenueEndpoints } from '../../api/endpoints';
import { MeDTO } from '../storeModels';
import { GetMeRequestOptions } from '../../api/models/auth';
import { SettingsPageStrings } from '../../common/localization/en';
import { GetTableDataResponse } from '../../api/models/common';
import { VenueApiModelWithStats } from '../../api/models/venue';

const authDataFromLocalStorage = () => {
  let response = localStorage.getItem('authResponse')
    ? JSON.parse(localStorage.getItem('authResponse') || '')
    : null;
  if (!response?.manager) {
    httpClient.logout();
    return null;
  }
  return response;
};

interface KnownLoginError {
  error: string;
  status: number;
}

const mapAuthResponseToMeDTO = (authresponse: MeApiModel): MeDTO => {
  return {
    email: authresponse.manager.email,
    createdAt: authresponse.manager.createdAt,
    photoUrl: authresponse.manager.photoUrl,
    updatedAt: authresponse.manager.updatedAt,
    name: authresponse.manager.name,
    enabled: authresponse.manager.enabled,
    isLoading: authresponse.manager.isLoading,
    id: authresponse.manager.id,
    role: authresponse.manager.role,
    refId: authresponse.manager.refId,
    auth: false,
    authError: false,
    resetPasswordError: '',
    venueId: authresponse.manager.venueId,
    accounts: authresponse.accounts || 0,
  };
};
const emptyState: MeDTO = {
  email: '',
  createdAt: '',
  photoUrl: '',
  updatedAt: '',
  name: '',
  refId: '',
  role: '',
  enabled: false,
  isLoading: false,
  id: '',
  auth: false,
  authError: false,
  resetPasswordError: '',
  venueId: '',
  authStatusCode: undefined,
  accounts: 0,
};

const initialState: MeDTO =
  (authDataFromLocalStorage() && mapAuthResponseToMeDTO(authDataFromLocalStorage())) || emptyState;

export const validatePasswordResetToken = async (token: string) => {
  try {
    return await httpClient.post<{ token: string }, { isValid: boolean }>({
      url: AuthEndpoints.ValidateToken,
      payload: { token },
      requiresToken: false,
    });
  } catch (error) {
    return { isValid: false };
  }
};
export const getUserAccounts = createAsyncThunk(
  'me/getVenueList',
  async (options: {}, { rejectWithValue }) => {
    try {
      return await httpClient.get<{}, GetTableDataResponse<VenueApiModelWithStats>>({
        url: VenueEndpoints.CreateVenue,
        requiresToken: true,
        params: {
          includeTestGroups: true,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const authRequest = createAsyncThunk<
  MeApiModel | KnownLoginError,
  { username: string; password: string },
  {}
>('me/auth', async (values: { username: string; password: string }, thunkApi) => {
  try {
    const response = await httpClient.login({
      url: AuthEndpoints.NewLogin,
      payload: { email: values.username, password: values.password },

      requiresToken: false,
    });

    // dispatch(setSelectedVenue({ id: response.customer.venueId }));
    return response as MeApiModel;
  } catch ({ status, error }) {
    return thunkApi.rejectWithValue({
      error: error.response.data.message,
      status,
    } as KnownLoginError);
  }
});

export const PartnerLogin = createAsyncThunk<
  MeApiModel | KnownLoginError,
  { venueId: string; token: string },
  {}
>('me/PartnerLogin', async (values: { venueId: string; token: string }, thunkApi) => {
  try {
    const response = await httpClient.partnerLogin({
      url: AuthEndpoints.PartnerLogin,
      payload: values,
      requiresToken: false,
    });

    // dispatch(setSelectedVenue({ id: response.customer.venueId }));
    return response as MeApiModel;
  } catch ({ status, error }) {
    return thunkApi.rejectWithValue({
      error: error.response.data.message,
      status,
    } as KnownLoginError);
  }
});

//PartnerLogin

export const getMe = createAsyncThunk(
  'me/getMe',
  async (_options: GetMeRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetMeRequestOptions, GetMeResponse>({
        url: getApiUrlForId(AuthEndpoints.GetMe, _options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const patchMe = createAsyncThunk(
  'venue/patchMe',
  async (_options: PatchmeOptions, { rejectWithValue }) => {
    return await httpClient.put<PatchMe, MeDTO>({
      url: getApiUrlForId(AuthEndpoints.PatchMe, _options.id),
      payload: {
        name: _options.name,
        photoUrl: _options.photoUrl,
        email: _options.email,
        phoneNumber: _options.phoneNumber,
      },
      requiresToken: true,
    });
  },
);

export const resetPasswordMe = createAsyncThunk(
  'venue/resetPasswordMe',
  async (_options: ResetPasswordOptions, { rejectWithValue }) => {
    try {
      return await httpClient.put<UpdatePassword, MeApiModel>({
        url: getApiUrlForId(AuthEndpoints.PatchMe, _options.id),
        payload: { currentPassword: _options.currentPassword, newPassword: _options.newPassword },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const forgotPassword = async (_options: { email: string }) => {
  try {
    return await httpClient.post<{ email: string }, { success: boolean }>({
      url: AuthEndpoints.ForgotPassword,
      payload: _options,
      requiresToken: false,
    });
  } catch (error) {
    throw error;
  }
};
export const ResetPassword = async (_options: {
  token: string;
  newPassword: string;
  name?: string;
}) => {
  try {
    return await httpClient.post<
      { token: string; newPassword: string; name?: string },
      { success: boolean }
    >({
      url: AuthEndpoints.ResetPassword,
      payload: _options,
      requiresToken: false,
    });
  } catch (error) {
    throw error;
  }
};

const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    updateMe(state, action: PayloadAction<MeApiModel>) {
      state.email = action.payload.manager.email;
      state.name = action.payload.manager.name;
      state.enabled = action.payload.manager.enabled;
      state.id = action.payload.manager.id;
      state.photoUrl = action.payload.manager.photoUrl;
      state.updatedAt = action.payload.manager.updatedAt;
    },
    reset: () => emptyState,
    ResetAuthError(state) {
      state.authError = false;
    },
    ClearResetPasswordError(state) {
      state.resetPasswordError = '';
    },
  },
  extraReducers: (reducerBuilder) => {
    reducerBuilder.addCase(patchMe.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(patchMe.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.email;
      state.name = payload.name;
      state.phoneNumber = payload.phoneNumber;

      const tempAuthResponse = localStorage.getItem('authResponse')
        ? JSON.parse(localStorage.getItem('authResponse') || '')
        : null;

      localStorage.setItem(
        'authResponse',
        JSON.stringify({
          ...tempAuthResponse,
          manager: {
            ...tempAuthResponse.manager,
            name: state.name,
            email: state.email,
          },
        }),
      );
    });
    reducerBuilder.addCase(resetPasswordMe.rejected, (state) => {
      state.resetPasswordError = SettingsPageStrings.ResetPasswordError;
      state.isLoading = false;
    });
    reducerBuilder.addCase(resetPasswordMe.pending, (state) => {
      state.isLoading = true;
      state.resetPasswordError = '';
    });
    reducerBuilder.addCase(resetPasswordMe.fulfilled, (state) => {
      state.resetPasswordError = '';
      state.isLoading = false;
    });
    reducerBuilder.addCase(getMe.pending, (state) => {
      state.isLoading = true;
    });
    reducerBuilder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.email = payload.email;
      state.name = payload.name;
      state.phoneNumber = payload.phoneNumber;
      state.id = payload.id;
      state.role = payload.role;
      state.refId = payload.refId || state.refId;
      state.photoUrl = payload.photoUrl || state.photoUrl;
      state.updatedAt = payload.updatedAt;
    });

    reducerBuilder.addCase(PartnerLogin.fulfilled, (state, { payload }) => {
      const meApiModelPayload = payload as MeApiModel;
      state.auth = true;
      state.authError = false;
      state.email = meApiModelPayload.manager.email;
      state.name = meApiModelPayload.manager.name;
      state.enabled = meApiModelPayload.manager.enabled;
      state.id = meApiModelPayload.manager.id;
      state.refId = meApiModelPayload.manager.refId;
      state.role = meApiModelPayload.manager.role;
      state.photoUrl = meApiModelPayload.manager.photoUrl;
      state.updatedAt = meApiModelPayload.manager.updatedAt;
    });
    reducerBuilder.addCase(PartnerLogin.rejected, (state, { payload }) => {
      const loginErrorPayload = payload as KnownLoginError;
      state.authError = true;
      state.authStatusCode = loginErrorPayload.status;
    });
    reducerBuilder.addCase(authRequest.fulfilled, (state, { payload }) => {
      const meApiModelPayload = payload as MeApiModel;

      state.auth = true;
      state.authError = false;
      state.email = meApiModelPayload.manager.email;
      state.name = meApiModelPayload.manager.name;
      state.enabled = meApiModelPayload.manager.enabled;
      state.id = meApiModelPayload.manager.id;
      state.refId = meApiModelPayload.manager.refId;
      state.role = meApiModelPayload.manager.role;
      state.photoUrl = meApiModelPayload.manager.photoUrl;
      state.updatedAt = meApiModelPayload.manager.updatedAt;
    });
    reducerBuilder.addCase(authRequest.rejected, (state, { payload }) => {
      const loginErrorPayload = payload as KnownLoginError;
      state.authError = true;
      state.authStatusCode = loginErrorPayload.status;
    });
    reducerBuilder.addCase(getUserAccounts.fulfilled, (state, { payload }) => {
      state.accounts = payload.items.length;
      let response = localStorage.getItem('authResponse')
        ? JSON.parse(localStorage.getItem('authResponse') || '')
        : null;
      if (response) {
        localStorage.setItem(
          'authResponse',
          JSON.stringify({ ...response, accounts: payload.items.length }),
        );
      }
    });
    //getUserAccounts
  },
});

export const { updateMe, reset, ResetAuthError, ClearResetPasswordError } = meSlice.actions;
export default meSlice.reducer;
