import { ChangeEvent, FC, useState } from 'react';
import { Box, Divider, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { useCampaignSummaryStyles } from './CampaignSummary.style';
import {
  CommonStrings,
  RewardFulfillmentStrings,
  RewardsStrings,
} from '../../../common/localization/en';
import {
  CampaignVideosApiModel,
  CampaignsApiModel,
  PatchIncentiveCampaignApiModel,
} from '../../../api/models/campaigns';
import { format } from 'date-fns';
import { LatestVideos } from './LatestVideos';
import ActionMenu from './ActionMenu/ActionMenu';
import { UserIconPlush } from '../../../common/assets/newDesign/UserIconPlus';
import { SettingsIcon } from '../../../common/assets/newDesign/SettingsIcon';
import { LogoutIcon } from '../../../common/assets/newDesign/LogoutIcon';
import { useModal } from '../../../hooks/useModal';
import { AddIndividualCustomerModal } from './Modals/AddIndividualCustomerModal';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getCampaignById,
  patchIncentiveCampaign,
  postEndIncentiveCampaign,
} from '../../../store/slices/campaignsSlice';
import { useToasts } from 'react-toast-notifications';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { NotificationModal } from './Modals/NotificationModal';
import { RewardFulfillmentMethod } from '../../../api/models/rewardFulfillment';
import { ImportFileModal } from './Modals/ImportFileModal';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { UnlockLastCampaignModal } from './Modals/UnlockLastCampaignModal';
import { CustomTooltip } from './CampaignSummary.helper';
import SetPrimaryCampaignModal from './Modals/SetPrimaryCampaignModal';
import { EditIcon } from '../../../common/assets/newDesign/WebAppIcons/EditIcon';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { HomeIcon } from '../../../common/assets/newDesign/HomeIcon';
import { WelcomeVideoIcon } from '../../../common/assets/newDesign/WelcomeVideoIcon';
import { UsersIcon } from '../../../common/assets/newDesign/UsersIcon';
import WelcomeVideoModal from './Modals/WelcomeVideoModal/WelcomeVideoModal';

interface CampaignSummaryProps {
  currentRewardCampaign: CampaignsApiModel;
  campaignVideos: CampaignVideosApiModel[];
}

export const CampaignSummary: FC<CampaignSummaryProps> = ({
  currentRewardCampaign,
  campaignVideos,
}) => {
  const styles = useCampaignSummaryStyles();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();
  const history = useHistory();
  const theme = useTheme();

  const [isEditNameOpen, setIsEditNameOpen] = useState(false);
  const [newCampaignName, setNewCampaignName] = useState(currentRewardCampaign.name);

  const campaigns = useTypedSelector((state) => state.campaigns.items);
  const activeCampaigns = campaigns.filter((campaign) => campaign.endedAt === null);
  const isLastCampaign = activeCampaigns.length === 1;

  const handleEditNameOpen = () => {
    setTimeout(() => {
      setIsEditNameOpen(true);
    }, 0);
  };

  const handleNameEditChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewCampaignName(event.target.value);
    trackEvent(RewardCampaignsEvents.RewardCampaignSummaryNameChange, {
      campaignId: currentRewardCampaign.id,
      value: event.target.value,
    });
  };

  const saveCampaignName = async () => {
    setIsEditNameOpen(false);

    if (!newCampaignName) {
      setNewCampaignName(currentRewardCampaign.name);
      addToast(`Campaign name can't be empty`, { appearance: 'error' });
      return;
    }

    if (newCampaignName === currentRewardCampaign.name) {
      return;
    }

    await dispatch(
      patchIncentiveCampaign({
        campaignId: currentRewardCampaign.id,
        values: { name: newCampaignName },
      }),
    ).then(() => dispatch(getCampaignById(id)));
  };

  const onInputNameEditEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await saveCampaignName();
      trackEvent(RewardCampaignsEvents.RewardCampaignSummaryNameChangeByKeyboard, {
        campaignId: currentRewardCampaign.id,
        value: newCampaignName,
      });
    } else if (event.key === 'Escape') {
      setIsEditNameOpen(false);
      trackEvent(RewardCampaignsEvents.RewardCampaignSummaryCancelNameChangeByKeyboard, {
        campaignId: currentRewardCampaign.id,
      });

      setNewCampaignName(currentRewardCampaign.name);
    }
  };

  const ref = useDetectClickOutside({
    onTriggered: async () => {
      if (isEditNameOpen) {
        await saveCampaignName();
      }
    },
  });

  const {
    isOpen: isAddIndividualModalOpen,
    open: openIndividualModal,
    close: closeIndividualModal,
  } = useModal(false);

  const {
    isOpen: isImportFileModalOpen,
    open: openImportFileModal,
    close: closeImportFileModal,
  } = useModal(false);

  const {
    isOpen: isNotificationModalOpen,
    open: openNotificationModal,
    close: closeNotificationModal,
  } = useModal(false);

  const {
    isOpen: isPrimaryModalOpen,
    open: openPrimaryModal,
    close: closePrimaryModal,
  } = useModal(false);

  const {
    isOpen: isUnlockCampaignModalOpen,
    open: openUnlockCampaignModal,
    close: closeUnlockCampaignModal,
    modalValue: unlockCampaign,
    setModalValue: setUnlockCampaign,
  } = useModal(false);

  const {
    isOpen: isWelcomeVideoModalOpen,
    open: openWelcomeVideoModal,
    close: closeWelcomeVideoModal,
  } = useModal(false);

  const { name: campaignName, startedAt, createdByName, id, isPreferred } = currentRewardCampaign;

  const displayedData = startedAt ? format(new Date(startedAt), 'PP') : '';

  const onNotificationMethodChange = (method: RewardFulfillmentMethod) => {
    dispatch(
      patchIncentiveCampaign({
        campaignId: currentRewardCampaign.id,
        values: {
          fulfillmentConfig: { ...currentRewardCampaign.fulfillmentConfig, method },
        } as PatchIncentiveCampaignApiModel,
      }),
    ).then(() => {
      dispatch(getCampaignById(id));
      closeNotificationModal();
      addToast(CommonStrings.OnSuccess, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
    });
  };

  const onEndCampaign = () => {
    const lastCampaign =
      activeCampaigns.length === 2 // the only one campaign will remain after request
        ? activeCampaigns.filter((campaign) => campaign.id !== id)[0]
        : null;

    if (lastCampaign?.isLocked) {
      setUnlockCampaign(lastCampaign);
      openUnlockCampaignModal();
      // campaign will be ended inside the modal
      return;
    }

    dispatch(postEndIncentiveCampaign(id)).then((res: any) => {
      if (res.error)
        return addToast(RewardsStrings.endCampaignErrorMessage, {
          appearance: 'error',
          autoDismissTimeout: 2500,
        });
      addToast(RewardsStrings.endCampaignMessage, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
    });

    trackEvent(RewardCampaignsEvents.RewardCampaignsDetailsEndCampaignConfirmClick, {
      campaignId: id,
    });
  };

  const onCaptureEmail = () => {
    if (currentRewardCampaign.emailRequired) {
      dispatch(patchIncentiveCampaign({ campaignId: id, values: { emailRequired: false } })).then(
        () => {
          dispatch(getCampaignById(id));
          addToast(CommonStrings.OnSuccess, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        },
      );
    } else {
      dispatch(patchIncentiveCampaign({ campaignId: id, values: { emailRequired: true } })).then(
        () => {
          dispatch(getCampaignById(id));
          addToast(CommonStrings.OnSuccess, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        },
      );
    }
  };

  const actionMenu = [
    {
      icon: <HomeIcon />,
      label: 'Set as Primary Campaign',
      onClick: openPrimaryModal,
      disabled: currentRewardCampaign.isPreferred,
    },
    {
      icon: <WelcomeVideoIcon />,
      label: 'Welcome Video',
      onClick: openWelcomeVideoModal,
    },
    {
      icon: <SettingsIcon />,
      label: 'Capture Email',
      onClick: onCaptureEmail,
      value: currentRewardCampaign.emailRequired ? 'ON' : 'OFF',
    },
    {
      icon: <UserIconPlush />,
      label: 'Invite Customers',
      onClick: () => {},
      subItems: [
        {
          label: 'Add Individual',
          onClick: openIndividualModal,
        },
        {
          label: 'Upload List',
          onClick: openImportFileModal,
        },
      ],
    },
    {
      icon: <UsersIcon />,
      label: 'SMS Text Messaging',
      onClick: () => {
        history.push(`${WebsiteRoutes.EditCampaign}/${currentRewardCampaign.id}`);
      },
    },
    {
      label: currentRewardCampaign.isNullReward ? 'Acknowledge:' : 'Reward:',
      onClick: openNotificationModal,
      value:
        currentRewardCampaign.fulfillmentConfig?.method &&
        currentRewardCampaign.fulfillmentConfig?.method === RewardFulfillmentMethod.MANUAL_SMS
          ? RewardFulfillmentStrings.Manual
          : RewardFulfillmentStrings.Automatic,
      showForEndedCampaigns: true,
    },
    {
      icon: <LogoutIcon />,
      label: 'End Campaign',
      onClick: onEndCampaign,
      disabled: isLastCampaign || isPreferred,
    },
  ];
  return (
    <>
      <AddIndividualCustomerModal
        campaignId={id}
        isOpen={isAddIndividualModalOpen}
        onClose={closeIndividualModal}
      />
      <ImportFileModal
        campaignId={id}
        isOpen={isImportFileModalOpen}
        onClose={closeImportFileModal}
      />
      <SetPrimaryCampaignModal
        campaignId={id}
        isOpen={isPrimaryModalOpen}
        onClose={closePrimaryModal}
      />
      <WelcomeVideoModal
        currentCampaign={currentRewardCampaign}
        isOpen={isWelcomeVideoModalOpen}
        onClose={() => {
          closeWelcomeVideoModal();
        }}
        isEdit={true}
      />
      <UnlockLastCampaignModal
        isOpen={isUnlockCampaignModalOpen}
        campaign={unlockCampaign as CampaignsApiModel}
        onConfirm={async () => {
          await dispatch(postEndIncentiveCampaign(id));

          const lastCampaignId = (unlockCampaign as CampaignsApiModel)?.id;
          await dispatch(
            patchIncentiveCampaign({
              campaignId: lastCampaignId,
              values: {
                isLocked: false,
              } as PatchIncentiveCampaignApiModel,
            }),
          ).then(() => dispatch(getCampaignById(lastCampaignId)));

          closeUnlockCampaignModal();
          addToast(CommonStrings.OnSuccess, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });

          trackEvent(
            RewardCampaignsEvents.RewardCampaignSummaryPageUnlockLastCampaignModalConfirmButtonClick,
            {
              campaignId: lastCampaignId,
            },
          );
        }}
        onClose={() => {
          closeUnlockCampaignModal();
          trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageUnlockLastCampaignModalClose, {
            campaignId: (unlockCampaign as CampaignsApiModel)?.id,
          });
        }}
      />
      <NotificationModal
        value={
          currentRewardCampaign.fulfillmentConfig?.method || RewardFulfillmentMethod.AUTOMATIC_SMS
        }
        onChange={() => {
          const notificationMethod =
            currentRewardCampaign.fulfillmentConfig?.method &&
            currentRewardCampaign.fulfillmentConfig?.method === RewardFulfillmentMethod.MANUAL_SMS
              ? RewardFulfillmentMethod.AUTOMATIC_SMS
              : RewardFulfillmentMethod.MANUAL_SMS;
          onNotificationMethodChange(notificationMethod);
          trackEvent(
            RewardCampaignsEvents.RewardCampaignSummaryPageNotificationModalChangeMethodClick,
            {
              campaignId: currentRewardCampaign.id,
              value: notificationMethod,
            },
          );
        }}
        isOpen={isNotificationModalOpen}
        onClose={() => {
          closeNotificationModal();
          trackEvent(RewardCampaignsEvents.RewardCampaignSummaryPageNotificationModalClose, {
            campaignId: currentRewardCampaign.id,
          });
        }}
      />
      {currentRewardCampaign && (
        <Paper
          elevation={0}
          className={styles.Container}
          style={{
            border: currentRewardCampaign.isPreferred ? '1px solid #F3B03E' : '1px solid #D0D5DD',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '22px 24px' }}>
            <Box>
              <Box className={styles.campaignNameWrapper}>
                {!isEditNameOpen ? (
                  <>
                    <Typography className={styles.CampaignName}>{campaignName}</Typography>
                    <IconButton
                      className={styles.editButton}
                      onClick={handleEditNameOpen}
                      id="edit-name-button"
                    >
                      <EditIcon
                        stroke={theme.palette.common.white}
                        fill={theme.palette.primary.main}
                        fillOpacity={1}
                        width={50}
                        height={50}
                      />
                    </IconButton>
                  </>
                ) : (
                  <input
                    type="text"
                    value={newCampaignName}
                    onChange={handleNameEditChange}
                    className={styles.nameEditInput}
                    onKeyDown={onInputNameEditEnter}
                    maxLength={36}
                    ref={ref}
                    id="campaign-name-input"
                  />
                )}
              </Box>
              <Typography className={styles.SummaryInfo}>
                Created by {createdByName || 'Campaign Creator'} on {displayedData}
              </Typography>
              <Box display="flex" alignItems={'baseline'}>
                {currentRewardCampaign.isPreferred && (
                  <CustomTooltip
                    title={
                      <Box padding={'13px 15px'}>
                        <Typography
                          color="inherit"
                          style={{ fontSize: '11px', fontWeight: '700', textAlign: 'left' }}
                        >
                          {RewardsStrings.PrimaryCampaignToolTipTitle}
                        </Typography>
                        <Typography
                          color="inherit"
                          style={{
                            textAlign: 'left',
                            fontSize: '11px',
                            fontWeight: '500',
                            marginTop: '8px',
                          }}
                        >
                          {RewardsStrings.PrimaryCampaignTooltip}
                        </Typography>
                      </Box>
                    }
                    icon={
                      <Typography className={styles.PrimaryCampaign}>
                        {RewardsStrings.PrimaryCampaign}
                      </Typography>
                    }
                  />
                )}

                <Typography className={styles.RewardName}>
                  {currentRewardCampaign.isNullReward
                    ? 'Awareness'
                    : `Incentive: ${currentRewardCampaign.rewardTitle}`}
                </Typography>
                <Typography
                  className={[
                    styles.RewardName,
                    currentRewardCampaign.endedAt
                      ? styles.CampaignStatusInactive
                      : styles.CampaignStatusActive,
                  ].join(' ')}
                >
                  {currentRewardCampaign.endedAt ? 'Inactive' : 'Active'}
                </Typography>
              </Box>
            </Box>
            <Box>
              <ActionMenu
                title={RewardsStrings.ActionMenu}
                items={actionMenu}
                isActiveCampaign={!currentRewardCampaign.endedAt}
                campaignId={currentRewardCampaign.id}
              />
            </Box>
          </Box>
          <Divider />
          <LatestVideos
            items={campaignVideos}
            campaignId={currentRewardCampaign.id}
            isCampaignDisabled={!!currentRewardCampaign.endedAt}
            openWelcomeVideoModal={() => {
              openWelcomeVideoModal();
            }}
          />
        </Paper>
      )}
    </>
  );
};
