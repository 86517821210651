import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { UserApiModel, UserMetricsAPIModel } from '../../../../../api/models/users';
import { HeroesPageStrings, SalesHeroInfoRewardsStrings } from '../../../../localization/en';
import { getLocaleNumberValue } from '../../../../../services/utilities';
import { makeStyles } from '@mui/styles';
import { VideoIcon } from '../../../../assets/VideosIcon';
import { AchievementIcon } from '../../../../assets/AchievementIcon';
import { PointsStarIcon } from '../../../../assets/PointsStarIcon';
import { SharesIcon } from '../../../../assets/SharesIcon';
import {
  TochAppIcon,
  ConversionsIcon,
  EyeIcon,
} from '../../../../assets/newDesign/UserDetails/index';
import { useTypedSelector } from '../../../../../store';

interface SalesHeroInfoRewardsProps {
  userData: UserApiModel;
  metrics: UserMetricsAPIModel | null | undefined;
  isCampaignUser?: boolean;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    '&:before': {
      content: '',
    },
  },
  avatar: {
    display: 'flex',
    height: '70px',
    width: '70px',
    borderRadius: '100%',
    marginRight: '20px',
  },
  infoBlockRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gridTemplateRows: 'repeat(2,1fr)',
    gridRowGap: '20px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: '25px',
    borderRadius: '10px',
    padding: '15px 14px',
    border: `1px solid #DCDBE4`,
  },
  infoBlockContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 10px',
  },
  iconContainer: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    padding: '8px',
    borderRadius: '30px',
    backgroundColor: '#F5F6F8',
    margin: 'auto 13px auto 0',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems:'center',
  },
  iconSvg: {
    display: 'block',
    width: '20px',
    height: '20px',
    fill: '#8B89A0',
  },
  arrowOutlined: {
    border: '1px solid #8B89A0',
    borderRadius: '50%',
  },
  infoBlockContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  infoBlockName: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: '6px',
  },
  infoBlockValue: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#1A1538',
    marginBottom: '6px',
    letterSpacing: '0.02em',
  },
}));
export const SalesHeroInfoRewards: FC<SalesHeroInfoRewardsProps> = ({
  userData,
  metrics,
  isCampaignUser,
}) => {
  const styles = useStyles();
  const { campaignUser } = useTypedSelector((state) => state.userDetails);

  const renderInfoBlock = (block: string, value: number | string) => {
    let icon;
    const date = typeof value === 'string' && new Date(value).toLocaleDateString('en-US');
    const dateValue = date === 'Invalid Date' ? '---' : date;

    switch (block) {
      case HeroesPageStrings.NumberOfStories: {
        icon = <VideoIcon />;
        break;
      }
      case HeroesPageStrings.Views: {
        icon = (
          <div style={{ margin: '0 -2px' }}>
            <EyeIcon />
          </div>
        );
        break;
      }
      case HeroesPageStrings.CTA: {
        icon = (
          <div style={{ margin: '-2px' }}>
            <TochAppIcon />
          </div>
        );
        break;
      }
      case HeroesPageStrings.Shares: {
        icon = <SharesIcon color="#8B89A0" />;
        break;
      }
      case HeroesPageStrings.TotalPoints: {
        icon = <PointsStarIcon color="#8B89A0" />;
        break;
      }
      case HeroesPageStrings.RewardsNumber: {
        icon = <AchievementIcon color="#8B89A0" />;
        break;
      }
      case HeroesPageStrings.Conversions: {
        icon = <ConversionsIcon />;
        break;
      }
    }
    return (
      <Box className={styles.infoBlockContainer}>
        <Box className={styles.iconContainer}>{icon}</Box>
        <Box className={styles.infoBlockContent}>
          <Typography className={styles.infoBlockName}>{block}</Typography>
          <Typography className={styles.infoBlockValue}>
            {typeof value !== 'string' ? getLocaleNumberValue(value) : dateValue}
          </Typography>
        </Box>
      </Box>
    );
  };

  const showCampaignUser = isCampaignUser && campaignUser != null;

  return (
    <>
      <Grid container direction="column" className={styles.root}>
        <Box className={styles.infoBlockRow}>
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Videos,
            showCampaignUser ? campaignUser?.videos! : userData.videos,
          )}
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Views,
            showCampaignUser ? campaignUser?.videoViews! : userData.videoViews,
          )}
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.CTA,
            showCampaignUser ? campaignUser?.videoCtaClicks! : userData.videoCtaClicks,
          )}
          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Shares,
            showCampaignUser ? campaignUser?.shares! : userData.shares,
          )}

          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Conversions,
            showCampaignUser ? campaignUser?.convertedReferrals! : userData.convertedReferrals,
          )}
          {/* {renderInfoBlock('Latest Story', metrics?.storyViews || 0)} */}

          {renderInfoBlock(
            SalesHeroInfoRewardsStrings.Rewards,
            showCampaignUser ? (campaignUser?.fulfilled! ? 1 : 0) : userData.rewardsFulfilled,
          )}
        </Box>
      </Grid>
    </>
  );
};
