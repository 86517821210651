import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={59} height={60} fill="none" {...props}>
    <mask
      id="mask0_2083_97090"
      width={59}
      height={60}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <ellipse cx={29.5} cy={30} fill="#D9D4FF" rx={29.5} ry={30} />
    </mask>
    <g mask="url(#mask0_2083_97090)">
      <ellipse cx={29.5} cy={30} fill="#D9D4FF" rx={29.5} ry={30} />
      <path
        stroke="#5242EA"
        strokeOpacity={0.8}
        strokeWidth={1.6}
        d="M58.2 30c0 16.14-12.862 29.2-28.7 29.2S.8 46.14.8 30 13.662.8 29.5.8 58.2 13.86 58.2 30Z"
      />
      <ellipse
        cx={20.014}
        cy={20.354}
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeWidth={1.4}
        rx={20.014}
        ry={20.354}
        transform="matrix(1 -.00217 .0021 1 9.212 9.62)"
      />
      <path
        fill="#5242EA"
        stroke="#DEDEDE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M40.033 29.222c.49.778.49 1.767 0 2.545a13.164 13.164 0 0 1-4.109 4.127 12.894 12.894 0 0 1-6.892 2c-2.435 0-4.823-.692-6.891-2a13.165 13.165 0 0 1-4.109-4.127 2.391 2.391 0 0 1 0-2.545 13.163 13.163 0 0 1 4.109-4.127c2.069-1.307 4.456-2 6.892-2 2.435 0 4.823.693 6.891 2a13.162 13.162 0 0 1 4.109 4.127Z"
      />
      <path
        stroke="#DEDEDE"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M29.033 34.066c1.94 0 3.512-1.6 3.512-3.572s-1.572-3.571-3.512-3.571-3.512 1.599-3.512 3.571c0 1.973 1.573 3.572 3.512 3.572Z"
      />
    </g>
  </svg>
);
const VideoTypeWebsiteSEOIconActive = memo(SvgComponent);
export default VideoTypeWebsiteSEOIconActive;
