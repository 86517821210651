import { Box, Grid, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { KeywordItem } from '../../../../api/models/venue';
import { KeywordsPreview } from '../../../../pages/Keywords/KeywordsPreview';
import { theme } from '../../../../theme';
import { useContentModalInfoStyles } from './ContentModalInfo.style';

interface IKeywordProps {
  keywordsTitle: string;
  keywords: KeywordItem[];
  keywordScoreTitle?: string;
  keywordScoreValue?: string;
  transcriptText?: string;
}

export const VideoKeywords: FC<IKeywordProps> = ({
  keywordsTitle,
  keywords = [],
  keywordScoreTitle,
  keywordScoreValue,
  transcriptText,
}) => {
  const classes = useContentModalInfoStyles();

  const disabledKeywords = useMemo(() => {
    return keywords.map((keywordItem) => ({
      ...keywordItem,
      disabled: !transcriptText?.toLowerCase()?.includes(keywordItem.keyword.toLowerCase()),
    }));
  }, [keywords, transcriptText]);

  return (
    <Grid className={classes.KeywordsContainer}>
      <Box width="100%">
        <Typography className={classes.KeywordsTitle}>{keywordsTitle}</Typography>
        <Grid className={classes.KeywordsBubblesContainer}>
          {keywords && <KeywordsPreview keywords={disabledKeywords} />}
          {/* {keywords.map((keyword, index) => {
            return (
              <Box className={classes.KeywordsBubble} key={keyword + index}>
                <Typography className={classes.Keyword}>{keyword}</Typography>
              </Box>
            );
          })} */}
        </Grid>
      </Box>
      {keywordScoreTitle && keywordScoreValue && (
        <Box className={classes.KeywordScoreContainer}>
          <Typography className={classes.KeywordScore}>{keywordScoreTitle}</Typography>
          <Typography
            className={classes.KeywordScoreValue}
            component="span"
            style={{ color: Number(keywordScoreValue) === 0 ? theme.palette.grey[400] : '' }}
          >
            {keywordScoreValue}%
          </Typography>
        </Box>
      )}
    </Grid>
  );
};
