import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: '0 10px 10px 10px',
    backgroundColor: theme.palette.common.white,
  },
  top: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 20px 11px 23px',
  },
  table: {
    position: 'relative',

    '& #common-table-wrapper': {
      maxHeight: '630px',
      height: '630px',
    },

    '& table': {
      borderSpacing: '0 !important',
    },
    '& th': {
      '& > div': {
        padding: '5px !important',
        fontSize: '12px',
      },
      '&:nth-child(1) div': {
        paddingLeft: '30px !important',
      },
      '&:nth-child(2) div': {
        justifyContent: 'center',
      },
      '&:nth-child(3) div': {
        justifyContent: 'center',
      },
      '&:nth-child(4) div': {
        justifyContent: 'center',
      },
    },
    '& tr': {
      height: '48px !important',
      fontSize: '2px !important',
      borderSpacing: 'none !important',
    },
    '& td': {
      fontSize: '13px !important',

      '& span': { fontSize: '13px !important' },
      '& p': {
        fontSize: '13px !important',
        color: theme.palette.common.deepDark,
      },
      '& > div': {
        padding: '5px !important',
        fontSize: '13px !important',
      },
      '&:nth-child(1)': {
        paddingLeft: '18px !important',
        minWidth: '220px',
      },
      '&:nth-child(2)': {
        padding: 0,
        textAlign: 'center',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
    },
  },
  inviteButton: {
    padding: '8px 31px',
    fontSize: '15x',
  },
  checkbox: {
    position: 'absolute',
    top: '18px',
    left: '13px',
    zIndex: 10,
  },
}));
