import { Check } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import tinycolor from 'tinycolor2';

interface ColorPaletteProps {
  palette: string[][];
  activeColor: string;
  onSelect?: (color: string) => void;
}

export const ColorPalette: React.FC<ColorPaletteProps> = ({ palette, activeColor, onSelect }) => {
  return (
    <Grid
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {palette.map((column, i) => {
        const isLastColumn = i === palette.length - 1;

        return (
          <Grid
            container
            xs={2}
            key={i}
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              mr: isLastColumn ? undefined : '5px',
              width: '100%',
              height: '100%',
              flexWrap: 'nowrap',
            }}
          >
            {column.map((color) => {
              const checkmarkColor = tinycolor(color).isDark() ? 'white' : 'black';

              return (
                <Grid
                  item
                  onClick={() => onSelect?.(color)}
                  key={color}
                  sx={(theme) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: '67px',
                    height: '24px',
                    cursor: 'pointer',

                    marginBottom: '5px !important',

                    backgroundColor: color,

                    borderRadius: '8px',
                    border: '2px solid transparent',
                    '&:hover': {
                      border: `2px solid ${theme.palette.primary.main}`,
                    },
                  })}
                  id={`${color}-color-pick`}
                >
                  {activeColor === color && (
                    <Check
                      style={{
                        fill: checkmarkColor,
                      }}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};
