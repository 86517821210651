import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={20}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.5 10a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm4.166 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm-8.333 0a1 1 0 1 0 2 0 1 1 0 0 0-2 0Zm13.775-3.616a9.337 9.337 0 0 0-4.992-4.992A9.289 9.289 0 0 0 9.499.667h-.041a9.311 9.311 0 0 0-9.292 9.377 9.363 9.363 0 0 0 1 4.165v3.166a.958.958 0 0 0 .958.959h3.17a9.365 9.365 0 0 0 4.164 1H9.5a9.339 9.339 0 0 0 3.598-.715 9.26 9.26 0 0 0 2.975-1.983 9.312 9.312 0 0 0 2.758-6.594 9.297 9.297 0 0 0-.724-3.658Zm-3.148 9.125A7.71 7.71 0 0 1 9.5 17.75h-.036a7.778 7.778 0 0 1-3.606-.906l-.175-.094H2.749v-2.933l-.093-.175a7.778 7.778 0 0 1-.907-3.606A7.702 7.702 0 0 1 3.991 4.54a7.691 7.691 0 0 1 5.475-2.29h.035a7.713 7.713 0 0 1 5.484 2.27 7.719 7.719 0 0 1 2.269 5.517 7.72 7.72 0 0 1-2.294 5.472Z"
      fill={props.color || '#fff'}
    />
  </svg>
);

export default SvgComponent;
