import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';

interface Props {
  isMms?: boolean;
}

export const MessageTypeTag: React.FC<Props> = ({ isMms }) => {
  const color = useMemo(() => {
    return isMms ? '#FFB515' : '#5242EA';
  }, [isMms]);

  return (
    <Box
      sx={{
        width: '36px',
        height: '34px',
        backgroundColor: `${color}33`, // set opacity
        borderRadius: '8px',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography fontWeight={700} fontSize={12} color={color}>
        {isMms ? 'MMS' : 'SMS'}
      </Typography>
    </Box>
  );
};
