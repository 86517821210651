import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns';
import _ from 'lodash';
import { FC, ReactNode, useEffect, useState } from 'react';
import {
  Attribute,
  SearchFilterAttributes,
  SearchOperations,
  SearchOperationsType,
} from '../../../api/models/searchFilter';
import { getUTCDateString } from '../../../services/utilities';
/* import { CrownIcon } from '../../assets/CrownIcon'; */
import {
  DataTreatmentsStrings,
  DateRangeFilterStrings,
  SearchFilterStrings,
  VideoFiltersStrings,
  VideoStatusStrings,
} from '../../localization/en';
import { OneParamStatsItem } from '../CreateUserFilterModal/OneParamStatsItem';
import { TwoParamsStatsItem } from '../CreateUserFilterModal/TwoParamsStatsItem';
import { DateRangeFilter } from '../datePicker/DateRangeFilter';
import { shareOptions } from './ShareOptionsList';
import { isToday } from 'date-fns';
import { isYesterday } from 'date-fns/esm';
import { frontEndDateFormat } from '../../constants/constants';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';

export interface IFilterOption {
  option: string | string[];
  from: number;
  to: number;
  GREATER_THAN?: number | string;
  LESS_THAN?: number | string;
  operation?: SearchOperationsType;
  value?: string;
}

export const headCellsNames = [
  '',
  SearchFilterStrings.Filterby,
  SearchFilterStrings.DataTreatments,
];

export const tableNumberRows = createTableNumberRows();

function createTableNumberRows() {
  const userFilers = Object.values(VideoFiltersStrings);
  const tableRows = [];
  for (let index = 0; index < userFilers.length; index++) {
    tableRows.push(index);
  }
  return tableRows;
}

export const tableVideoFilterRows = createVideoTableFilterrRows();

function createVideoTableFilterrRows() {
  const filters = Object.values(VideoFiltersStrings);
  const tableRows = [];
  for (let index = 0; index < filters.length; index++) {
    /*   const isPremiumFilterOption =
      filters[index] === VideoFiltersStrings.conversions ||
      filters[index] === VideoFiltersStrings.keywordScore; */
    tableRows.push(
      <>
        <Typography
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            opacity: /* isPremiumFilterOption ? '50%' : */ 'initial',
          }}
        >
          {/*     {isPremiumFilterOption ? (
            <Box paddingRight="12px">
              <CrownIcon />
            </Box>
          ) : null}{' '} */}
          {filters[index]}
        </Typography>
      </>,
    );
  }
  return tableRows;
}

interface IcreateVideoFilterInputRows {
  filter: { [key: string]: null | IFilterOption | { option: string | string[] } | string };
  handleDateUpdate: (filterName: string, value: any) => void;
  handleRangeFiltersUpdate: (filterName: string, option: string, from: number, to: number) => void;
  handleVideoOptionUpdate: (filterName: string, value: any) => void;
  trackEvent: IEventTracker;
}

export function createVideoFilterInputRows({
  filter,
  handleDateUpdate,
  handleRangeFiltersUpdate,
  handleVideoOptionUpdate,
  trackEvent,
}: IcreateVideoFilterInputRows) {
  const filers = Object.values(VideoFiltersStrings);
  return filers.map((value) => {
    const valueToSetState =
      Object.keys(VideoFiltersStrings)[Object.values(VideoFiltersStrings).indexOf(value)];

    const isPremiumFilterOption = false;
    if (value === VideoFiltersStrings.createdAt) {
      return (
        <Box style={{ cursor: 'pointer' }}>
          <DateRangeFilter
            updateRange={(dataRange) => {
              handleDateUpdate(valueToSetState, dataRange);
              trackEvent(VideosEventNames.filterDateRangeApplied, {
                value: dataRange ? `${dataRange.from} - ${dataRange.to}` : '',
              });
            }}
            initialValue={filter[valueToSetState]}
          />
        </Box>
      );
    }
    if (value === VideoFiltersStrings.sharePlatform) {
      return (
        <SelectShareDestination
          handleStatusChange={handleVideoOptionUpdate}
          status={(filter[valueToSetState] as IFilterOption).option as string}
          valueToSetState={valueToSetState}
        />
      );
    }
    if (value === VideoFiltersStrings.status) {
      return (
        <SelectVideoStatus
          handleStatusChange={handleVideoOptionUpdate}
          status={(filter[valueToSetState] as IFilterOption).option as string}
          valueToSetState={valueToSetState}
        />
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <SelectDataTreatmentsStatus
          handleChange={handleVideoOptionUpdate}
          dataStatus={(filter[valueToSetState] as IFilterOption).option as string}
          valueToSetState={valueToSetState}
          isPremiumFilterOption={isPremiumFilterOption}
        />

        {(filter[valueToSetState] as IFilterOption).option === DataTreatmentsStrings.IN_RANGE ? (
          <TwoParamsStatsItem
            item={{
              id: '',
              title: '',
              icon: <></>,
            }}
            handleChangeValues={function (
              id: string,
              newValue: { min: number; max: number },
            ): void {
              handleRangeFiltersUpdate(
                valueToSetState,
                (filter[valueToSetState] as IFilterOption).option as string,
                newValue.min,
                newValue.max,
              );
            }}
            value={{
              min: (filter[valueToSetState] as IFilterOption).from,
              max: (filter[valueToSetState] as IFilterOption).to,
            }}
            isPremiumFilterOption={isPremiumFilterOption}
            isPercent={value === VideoFiltersStrings.keywordScore}
          />
        ) : (
          <OneParamStatsItem
            item={{
              id: valueToSetState,
              title: '',
              icon: <></>,
            }}
            handleChangeValues={function (
              id: string,
              newValue: { min: number; max: number },
            ): void {
              handleRangeFiltersUpdate(
                valueToSetState,
                (filter[valueToSetState] as IFilterOption).option as string,
                !(filter[valueToSetState] as IFilterOption).option ? 0 : newValue.min,
                newValue.max,
              );
            }}
            value={{
              min: !(filter[valueToSetState] as IFilterOption).option
                ? 0
                : (filter[valueToSetState] as IFilterOption).from,
              max: (filter[valueToSetState] as IFilterOption).to,
            }}
            option={(filter[valueToSetState] as IFilterOption).option as string}
            isPremiumFilterOption={isPremiumFilterOption}
            isPercent={value === VideoFiltersStrings.keywordScore}
          />
        )}
      </Box>
    );
  });
}

interface ISelectShareDestinationProps {
  status: string;
  handleStatusChange: (filterName: string, value: any) => void;
  valueToSetState: string;
}

const SelectShareDestination: FC<ISelectShareDestinationProps> = ({
  status,
  handleStatusChange,
  valueToSetState,
}) => {
  const [sharesList, setSharesList] = useState<string[]>([]);
  useEffect(() => {
    if (!status) setSharesList([]);
  }, [status]);

  useEffect(() => {
    handleStatusChange(valueToSetState, sharesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharesList]);

  const handleChange = (event: SelectChangeEvent<typeof sharesList>) => {
    const {
      target: { value },
    } = event;

    setSharesList(typeof value === 'string' ? value.split(',') : value);
  };
  return (
    <FormControl sx={{ width: '280px' }}>
      <InputLabel style={{ backgroundColor: '#FFF', top: '-4px', fontSize: '14px' }}>
        {SearchFilterStrings.SelectOneOrMore}
      </InputLabel>
      <Select
        multiple
        value={sharesList}
        onChange={handleChange}
        input={<OutlinedInput label={SearchFilterStrings.SelectOneOrMore} />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={{
          PaperProps: {
            style: {
              borderRadius: '12px',
            },
          },
        }}
        style={{ border: !status.length ? '' : '1px solid blue' }}
      >
        {shareOptions.map((item) => (
          <MenuItem key={item.id} value={item.title}>
            <Checkbox checked={sharesList.indexOf(item.title) > -1} />
            <Box width="24px" height="24px" marginRight="16px" textAlign="center">
              {item.icon}
            </Box>
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

interface ISelectVideoStatusProps {
  status: string;
  handleStatusChange: (filterName: string, value: any) => void;
  valueToSetState: string;
}

const SelectVideoStatus: FC<ISelectVideoStatusProps> = ({
  status,
  handleStatusChange,
  valueToSetState,
}) => {
  return (
    <Box sx={{ minWidth: 165 }}>
      <FormControl fullWidth>
        <Select
          value={status}
          style={{ border: !status ? '' : '1px solid blue' }}
          onChange={(event: SelectChangeEvent<string>) => {
            const value = event.target.value;
            handleStatusChange(valueToSetState, value);
          }}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={() => {
            if (status === '') {
              return SearchFilterStrings.SelectStatus;
            }
            return status;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '12px',
              },
            },
          }}
        >
          <MenuItem value="">-</MenuItem>
          <MenuItem value={VideoStatusStrings.Published}>{VideoStatusStrings.Published}</MenuItem>
          <MenuItem value={VideoStatusStrings.Unpublished}>
            {VideoStatusStrings.Unpublished}
          </MenuItem>
          <MenuItem value={VideoStatusStrings.Moderated}>{VideoStatusStrings.Moderated}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

interface ISelectDataTreatmentsStatusProps {
  handleChange: (filterName: string, value: any) => void;
  dataStatus: string;
  valueToSetState: string;
  isPremiumFilterOption: boolean;
}

const SelectDataTreatmentsStatus: FC<ISelectDataTreatmentsStatusProps> = ({
  handleChange,
  dataStatus,
  valueToSetState,
  isPremiumFilterOption,
}) => {
  return (
    <Box sx={{ minWidth: 165 }}>
      <FormControl fullWidth>
        <Select
          style={{ border: !dataStatus ? '' : '1px solid blue' }}
          disabled={isPremiumFilterOption}
          value={dataStatus}
          onChange={(event: SelectChangeEvent<string>) => {
            const value = event.target.value;
            handleChange(valueToSetState, value);
          }}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={() => {
            if (dataStatus === '') {
              return '-';
            }
            return dataStatus;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '12px',
              },
            },
          }}
        >
          <MenuItem value="">-</MenuItem>
          {Object.values(DataTreatmentsStrings).map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export const initialVideoFilterState: {
  [key: string]: null | IFilterOption;
} = {
  createdAt: null,

  sharePlatform: {
    option: '',
    from: 0,
    to: 0,
  },
  views: {
    option: '',
    from: 0,
    to: 10,
  },
  ctaClicks: {
    option: '',
    from: 0,
    to: 10,
  },
  convertedReferrals: {
    option: '',
    from: 0,
    to: 10,
  },
  keywordScore: {
    option: '',
    from: 0,
    to: 10,
  },
  status: {
    operation: 'EQUALS',
    option: '',
    from: 0,
    to: 0,
  },
};

export const stateToAtrributes = (
  filter: { [x: string]: IFilterOption | null },
  venueCreatedAt: string,
) => {
  const filterOption = Object.keys(initialVideoFilterState);
  const attributes: SearchFilterAttributes[] = [];
  const shareArray: string[] = [];
  filterOption.forEach((filterOption) => {
    if (_.isEqual(initialVideoFilterState[filterOption], filter[filterOption])) return;
    switch (filterOption) {
      case 'createdAt':
        const dateFrom = new Date(
          filter[filterOption] && filter[filterOption]?.GREATER_THAN
            ? getUTCDateString(filter[filterOption]?.GREATER_THAN as string)
            : getUTCDateString(venueCreatedAt),
        );
        const dateTo = new Date(
          filter[filterOption] && filter[filterOption]?.LESS_THAN
            ? getUTCDateString(filter[filterOption]?.LESS_THAN as string)
            : getUTCDateString(new Date().toString()),
        );
        const timezoneOffset = new Date().getTimezoneOffset() / 60;

        //for dateFrom add timezoneOffset; for dateTo add timezoneOffset + 24hours to point at the end of the day
        dateFrom.setHours(dateFrom.getHours() + timezoneOffset);
        dateTo.setHours(dateTo.getHours() + timezoneOffset + 24);

        attributes.push({
          name: filterOption,
          operation: SearchOperations.GREATER_THAN,
          value: dateFrom.toISOString(),
        });
        attributes.push({
          name: filterOption,
          operation: SearchOperations.LESS_THAN,
          value: dateTo.toISOString(),
        });
        break;
      case 'status':
        attributes.push({
          name: filterOption,
          operation: SearchOperations.EQUALS,
          value: (filter[filterOption]?.option as string).toUpperCase(),
        });
        break;
      case 'sharePlatform':
        (filter[filterOption]?.option as string[]).forEach((option) => {
          shareArray.push(option === 'Facebook Reels' ? 'REELS' : option.toLocaleUpperCase());
        });
        break;
      default:
        switch (filter[filterOption]?.option) {
          case DataTreatmentsStrings.IN_RANGE:
            attributes.push({
              name: filterOption,
              operation: SearchOperations.IN_RANGE,
              value: { min: filter[filterOption]?.from, max: filter[filterOption]?.to },
            });
            break;
          default:
            const operation = Object.keys(DataTreatmentsStrings)[
              Object.values(DataTreatmentsStrings).indexOf(
                filter[filterOption]?.option as DataTreatmentsStrings,
              )
            ] as SearchOperationsType;

            if (operation === undefined) return;
            attributes.push({
              name: filterOption,
              operation: (operation === undefined ? '' : operation) as SearchOperationsType,
              value: operation ? filter[filterOption]?.from : 0,
            });
            break;
        }
        break;
    }
  });

  if (shareArray.length) {
    attributes.push({
      name: 'sharePlatform',
      operation: SearchOperations.IN,
      value: shareArray,
    });
  }

  return attributes;
};

export const attributesToState = (
  attributes: {
    name: string;
    operation: SearchOperationsType;
    value: any;
  }[],
) => {
  const state: { [key: string]: null | IFilterOption } = {};
  attributes.forEach((attribute) => {
    let name = attribute.name;
    let option;

    switch (attribute.name) {
      case Object.keys(VideoFiltersStrings)[
        Object.values(VideoFiltersStrings).indexOf(VideoFiltersStrings.status)
      ] as SearchOperationsType:
        option = attribute.value.charAt(0).toUpperCase() + attribute.value.slice(1).toLowerCase();
        state[name] = { option: option, from: 0, to: 10 };
        break;

      case Object.keys(VideoFiltersStrings)[
        Object.values(VideoFiltersStrings).indexOf(VideoFiltersStrings.createdAt)
      ] as SearchOperationsType:
        let from;
        let to;
        if (attribute.operation === SearchOperations.GREATER_THAN) from = attribute.value;
        if (attribute.operation === SearchOperations.LESS_THAN) to = attribute.value;
        state[name] = {
          option: '',
          from: state[name]?.from ? state[name]?.from : from,
          to: state[name]?.to ? state[name]?.to : to,
        };
        break;

      case Object.keys(VideoFiltersStrings)[
        Object.values(VideoFiltersStrings).indexOf(VideoFiltersStrings.sharePlatform)
      ] as SearchOperationsType:
        let options: string[] = [];
        options = Array.isArray(state[name]?.option)
          ? [...(state[name]?.option as string[])]
          : options;
        options.push(attribute.value);
        state[name] = {
          option: options,
          from: 0,
          to: 10,
        };
        break;

      default:
        if (name === 'views') name = 'views';
        if (
          attribute.operation === 'EQUALS' ||
          attribute.operation === 'GREATER_THAN' ||
          attribute.operation === 'LESS_THAN' ||
          attribute.operation === 'IN_RANGE'
        ) {
          option = DataTreatmentsStrings[attribute.operation];
          state[name] = {
            option: option as string,
            from:
              attribute.value.min !== null || attribute.value.min !== undefined
                ? attribute.value.min
                : attribute.value,
            to: attribute.value.max,
          };
        }
    }
  });
  return state;
};

const transformDate = (from: number | undefined, to: number | undefined) => {
  if (from && to) {
    if (from === to && isToday(new Date(from?.toString()))) {
      return DateRangeFilterStrings.Today;
    } else if (from === to && isYesterday(new Date(from?.toString()))) {
      return DateRangeFilterStrings.Yesterday;
    } else {
      const endOfLastWeek = endOfWeek(subWeeks(new Date(), 1));
      const startOfLastWeek = startOfWeek(endOfLastWeek);

      const equalToEndOfLastWeek =
        format(new Date(endOfLastWeek), frontEndDateFormat) ===
        format(new Date(to), frontEndDateFormat);
      const equalToStartOfLastWeek =
        format(new Date(startOfLastWeek), frontEndDateFormat) ===
        format(new Date(from), frontEndDateFormat);

      if (equalToStartOfLastWeek && equalToEndOfLastWeek) {
        return DateRangeFilterStrings.LastWeek;
      }

      const endOfLastMonth = endOfMonth(subMonths(new Date(), 1));
      const startOfLastMonth = startOfMonth(endOfLastMonth);

      const equalToEndOfLastMonth =
        format(new Date(endOfLastMonth), frontEndDateFormat) ===
        format(new Date(to), frontEndDateFormat);
      const equalToStartOfLastMonth =
        format(new Date(startOfLastMonth), frontEndDateFormat) ===
        format(new Date(from), frontEndDateFormat);

      if (equalToEndOfLastMonth && equalToStartOfLastMonth) {
        return DateRangeFilterStrings.LastMonth;
      }

      const endOfThisMonth = endOfMonth(new Date());
      const startOfThisMonth = startOfMonth(endOfThisMonth);

      const equalToEndOfThisMonth =
        format(new Date(endOfThisMonth), frontEndDateFormat) ===
        format(new Date(to), frontEndDateFormat);
      const equalToStartOfThisMonth =
        format(new Date(startOfThisMonth), frontEndDateFormat) ===
        format(new Date(from), frontEndDateFormat);

      if (equalToEndOfThisMonth && equalToStartOfThisMonth) {
        return DateRangeFilterStrings.ThisMonth;
      }

      return from && to && `${format(new Date(from), 'PP')}-${format(new Date(to), 'PP')}`;
    }
  }
};

export const createVideoFilterPreview = (attributes: Attribute[]): ReactNode[] => {
  const attributesPreview = attributesToState(attributes);
  const updatedInintailState = { ...initialVideoFilterState, ...attributesPreview };
  const filters = Object.values(VideoFiltersStrings);
  return filters.map((value) => {
    const valueToSetState =
      Object.keys(VideoFiltersStrings)[Object.values(VideoFiltersStrings).indexOf(value)];

    if (value === VideoFiltersStrings.createdAt) {
      const displayedValue = updatedInintailState[valueToSetState]
        ? transformDate(
            updatedInintailState[valueToSetState]?.from,
            updatedInintailState[valueToSetState]?.to,
          )
        : DateRangeFilterStrings.SinceBeginning;
      return (
        <Box>
          <TextField
            sx={{ width: '368px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
            disabled={true}
            variant="outlined"
            value={displayedValue}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      );
    }
    if (value === VideoFiltersStrings.sharePlatform) {
      return (
        <TextField
          disabled={true}
          sx={{ width: '368px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
          variant="outlined"
          value={updatedInintailState[valueToSetState]?.option}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    }
    if (value === VideoFiltersStrings.status) {
      return (
        <TextField
          disabled={true}
          sx={{ width: '160px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
          variant="outlined"
          value={updatedInintailState[valueToSetState]?.option}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <TextField
          disabled={true}
          sx={{
            width: '160px',
            backgroundColor: '#F5F6F8',
            marginRight: '15px',
            borderRadius: '12px',
          }}
          variant="outlined"
          value={updatedInintailState[valueToSetState]?.option}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {(updatedInintailState[valueToSetState] as IFilterOption).option ===
        DataTreatmentsStrings.IN_RANGE ? (
          <Box display="flex" alignItems="center">
            <TextField
              disabled={true}
              sx={{
                width: '55px',
                backgroundColor: '#F5F6F8',
                marginRight: '10px',
                borderRadius: '12px',
              }}
              variant="outlined"
              value={
                updatedInintailState[valueToSetState]?.from
                  ? updatedInintailState[valueToSetState]?.from
                  : updatedInintailState[valueToSetState]?.option
                  ? '0'
                  : '-'
              }
              InputLabelProps={{
                shrink: true,
              }}
            />{' '}
            <Typography sx={{ marginRight: '10px' }}>to</Typography>
            <TextField
              disabled={true}
              sx={{ width: '100px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
              variant="outlined"
              value={
                updatedInintailState[valueToSetState]?.to
                  ? updatedInintailState[valueToSetState]?.to
                  : updatedInintailState[valueToSetState]?.option
                  ? '0'
                  : '-'
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ) : (
          <TextField
            disabled={true}
            sx={{ width: '55px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
            variant="outlined"
            value={
              updatedInintailState[valueToSetState]?.from
                ? updatedInintailState[valueToSetState]?.from
                : updatedInintailState[valueToSetState]?.option
                ? '0'
                : '-'
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </Box>
    );
  });
};
