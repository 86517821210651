import { FC, useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import { SearchFilterAttributes } from '../../../../api/models/searchFilter';
import { TopGolfVideosApiModel } from '../../../../api/models/topgolfVideos';
import { ReturnedDate } from '../../../../common/components/datePicker/DateRangeFilter';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { TypeSocialVerse, defaultPagination } from '../../../../common/constants/constants';
import { VideosEventNames } from '../../../../common/constants/events/videosEvents';
import { usePrevious } from '../../../../hooks/common';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { createContentDataTableContent } from '../../../../services/helpers/topgolfTableMappers';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
  getItemsFromPageAndSize,
  isEqualsJson,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { setActiveFilter } from '../../../../store/slices/searchFiltersSlice';
import {
  getTopGolfVideos,
  getVideosBySearchFilter,
  goToSelectedPage,
  reset,
  resetPagination,
  setVideosSorting,
  updateSearch,
  updateSize,
} from '../../../../store/slices/topGolfVideosSlice';

interface ContentTableProps {
  handleOpenDeleteModal: (id: string, deleted: boolean) => void;
  statusFilter?: string;
  handleOpenConfirmationModal: (id: string) => void;
  handleOpenAddNewTagModal: (id: string) => void;
  archivedFilter: boolean;
  date: ReturnedDate | null;
  isSocialVersePage?: boolean;
  handleAddVideoToCard: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  handleVideoReplace?: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
}

export const TopgolfContentTable: FC<ContentTableProps> = ({
  handleOpenDeleteModal,
  statusFilter,
  handleOpenConfirmationModal,
  archivedFilter,
  date,
  isSocialVersePage,
  handleAddVideoToCard,
  handleVideoReplace,
}) => {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const history = useHistory();
  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
  } = useTypedSelector((state) => state.TopgGolfVideos);
  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);

  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const params = useParams<{ id: string }>();
  const match = useRouteMatch();
  const visibleItems = getItemsFromPageAndSize<TopGolfVideosApiModel>(items, page, size);
  const { activeFilter, newFilterAttributes } = useTypedSelector(
    (state) => state.SearchFilterSlice,
  );

  const { currentSocialVerseVideos, socialVerse } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );

  const prevStatus = usePrevious<string | undefined>(statusFilter);
  const prevArchived = usePrevious<boolean>(archivedFilter);
  const prevDate = usePrevious<ReturnedDate | null>(date);

  const shouldResetPagination = useMemo(() => {
    if (prevArchived !== archivedFilter || prevStatus !== statusFilter) {
      return defaultPagination.page;
    }

    return page;
  }, [prevArchived, archivedFilter, prevStatus, statusFilter, page]);

  const shotTimeFrom = date?.from ? new Date(date?.from).toISOString() : '';
  const tempdate = date?.to ? new Date(date?.to) : new Date();
  tempdate.setHours(0);
  tempdate.setMinutes(0);
  const tempToDate = date?.to ? new Date(date?.to) : new Date();
  tempToDate.setHours(23);
  tempToDate.setMinutes(59);
  tempdate.setDate(tempdate.getDate() + 1);
  const shotTimeTo = date?.to
    ? date.to.toString() === date?.from.toString()
      ? tempdate.toISOString()
      : tempToDate.toISOString()
    : '';

  useEffect(() => {
    return () => {
      dispatch(setActiveFilter(null));
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (venueId) {
      if (activeFilter || (isSocialVersePage && newFilterAttributes.length)) {
        let filter: SearchFilterAttributes[] = [];
        if (isSocialVersePage && newFilterAttributes.length) {
          filter = newFilterAttributes;
        } else if (isSocialVersePage && activeFilter) {
          filter = activeFilter?.filter.attributes;
        } else if (activeFilter) {
          filter = activeFilter?.filter.attributes;
        }
        dispatch(
          getVideosBySearchFilter({
            venueId,
            filter: filter,
            pageable: {
              page: shouldResetPagination,
              size,
              sort,
            },
            search,
          }),
        );
      } else if (
        (page && !!date && !prevDate) ||
        (!!date && !!prevDate && !isEqualsJson(date, prevDate))
      ) {
        dispatch(resetPagination());
        dispatch(
          getTopGolfVideos({
            venueId,
            shotTimeFrom: shotTimeFrom,
            shotTimeTo: shotTimeTo,
            pageable: {
              page: 0,
              size,
              sort,
              status: statusFilter,
              deleted: archivedFilter,
            },
            search,
          }),
        );
      } else {
        dispatch(
          getTopGolfVideos({
            venueId,
            shotTimeFrom: shotTimeFrom,
            shotTimeTo: shotTimeTo,
            pageable: {
              page: shouldResetPagination,
              size,
              sort,
              status: statusFilter,
              deleted: archivedFilter,
            },
            search,
          }),
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    size,
    sort,
    search,
    venueId,
    statusFilter,
    archivedFilter,
    date,
    // this causes unnecessary request
    // prevDate,
    activeFilter,
    newFilterAttributes,
  ]);

  const tableConfig = {
    items: visibleItems,
    handleOpenConfirmationModal,
    isSocialVersePage,
    handleAddVideoToCard,
    totalKeywords: keywords.length,
    replace:
      currentSocialVerseVideos.length > 0 && socialVerse?.viewMode === TypeSocialVerse.Educational,
    handleVideoReplace,
  };

  const contentDataTableContents = createContentDataTableContent(tableConfig);

  const onGoToPage = (targetPage: number) => {
    trackEvent(VideosEventNames.videosTablePageChanged, {
      value: targetPage.toString(),
    });
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    trackEvent(VideosEventNames.videosTableSortClicked, {
      value: name,
    });
    dispatch(setVideosSorting(name));
  };

  const onClickRow = (id: string) => {
    trackEvent(VideosEventNames.videosTableRowClicked, {
      videoId: id,
    });

    if (isSocialVersePage) return;
    history.push(match.path.replace(':id', id));
  };

  const changeSize = (size: number) => {
    trackEvent(VideosEventNames.videosTableSizeChanged, {
      value: size.toString(),
    });
    dispatch(updateSize(size));
  };

  return (
    <CommonTable
      selectedRowId={params?.id || ''}
      content={contentDataTableContents}
      page={convertApiPageToFrontEndPage(page)}
      sort={sort}
      totalItems={totalItems}
      totalPages={totalPages}
      isLoading={isLoading}
      noContent={error}
      tablePadding="0"
      goToPage={onGoToPage}
      onSortHeaderClick={onSort}
      onClickRow={onClickRow}
      size={size}
      onSizeChange={changeSize}
      withSearchBar
      searchBarProps={{
        adaptiveWidth: true,
        entity: 'TopgGolfVideos',
        updateSearch: updateSearch,
      }}
    />
  );
};
