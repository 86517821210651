import React, { FC } from 'react';

interface Props {
  fill: string;
}

const RewardCardPreviewBackground: FC<Props> = ({ fill }) => {
  return (
    <svg width="303" height="303" viewBox="0 0 303 303" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_29_179" maskUnits="userSpaceOnUse" x="0" y="0" width="303" height="303">
        <path
          d="M288 0H15C6.71573 0 0 6.71573 0 15V288C0 296.284 6.71573 303 15 303H288C296.284 303 303 296.284 303 288V15C303 6.71573 296.284 0 288 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_29_179)">
        <mask id="mask1_29_179" maskUnits="userSpaceOnUse" x="0" y="0" width="303" height="303">
          <path d="M303 0H0V303H303V0Z" fill="white" />
        </mask>
        <g mask="url(#mask1_29_179)">
          <mask id="mask2_29_179" maskUnits="userSpaceOnUse" x="0" y="0" width="303" height="327">
            <path
              d="M283 0H20C8.9543 0 0 10.8303 0 24.1901V302.377C0 315.736 8.9543 326.567 20 326.567H283C294.046 326.567 303 315.736 303 302.377V24.1901C303 10.8303 294.046 0 283 0Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_29_179)">
            <path
              d="M283 0H20C8.9543 0 0 10.8303 0 24.1901V302.377C0 315.736 8.9543 326.567 20 326.567H283C294.046 326.567 303 315.736 303 302.377V24.1901C303 10.8303 294.046 0 283 0Z"
              fill={fill}
            />
            <mask id="mask3_29_179" maskUnits="userSpaceOnUse" x="0" y="0" width="101" height="164">
              <path d="M0 0H100.008V163.283H0V0Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask3_29_179)">
              <path d="M0 163.284V87.6162L47.9204 163.284H0Z" fill="black" fill-opacity="0.32" />
              <path
                d="M99.712 163.283H47.6246L-11.3086 69.694V-12.9434L99.712 163.283Z"
                fill="black"
                fill-opacity="0.22"
              />
              <path
                d="M100.008 163.782V82.1395L48.2181 0H0V4.9781L100.008 163.782Z"
                fill="black"
                fill-opacity="0.1"
              />
              <path
                d="M100.008 82.1395L48.5156 0H100.008V82.1395Z"
                fill="black"
                fill-opacity="0.4"
              />
            </g>
            <mask
              id="mask4_29_179"
              maskUnits="userSpaceOnUse"
              x="100"
              y="0"
              width="103"
              height="164"
            >
              <path d="M100.008 0H202.992V163.283H100.008V0Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask4_29_179)">
              <path
                d="M100.008 82.6377H202.992V164.279H100.008V82.6377Z"
                fill="black"
                fill-opacity="0.18"
              />
              <path d="M100.008 0H202.992V82.6373H100.008V0Z" fill="black" fill-opacity="0.33" />
              <path
                d="M100.009 163.78C100.009 163.78 100.006 91.5971 100.009 82.1387C104.353 156.087 143.15 163.237 154.032 163.78H156.859C156.859 163.78 155.823 163.87 154.032 163.78H100.009Z"
                fill="black"
                fill-opacity="0.3"
              />
              <path
                d="M100.009 0.0397733C100.009 0.0397733 100.006 72.1878 100.009 81.6417C104.375 7.73 143.375 0.583323 154.314 0.0397733H157.155C157.155 0.0397733 156.114 -0.0497167 154.314 0.0397733H100.009Z"
                fill="black"
                fill-opacity="0.07"
              />
            </g>
            <mask
              id="mask5_29_179"
              maskUnits="userSpaceOnUse"
              x="202"
              y="0"
              width="101"
              height="164"
            >
              <path d="M202.992 0H303V163.283H202.992V0Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask5_29_179)">
              <path
                d="M202.992 163.285V82.1406L254.484 163.285H202.992Z"
                fill="black"
                fill-opacity="0.35"
              />
              <path
                d="M303.003 163.284V82.6377L254.785 163.284H303.003Z"
                fill="black"
                fill-opacity="0.35"
              />
              <path
                d="M254.486 82.1402L202.398 -0.49707H305.978L254.486 82.1402Z"
                fill="black"
                fill-opacity="0.35"
              />
              <path
                d="M202.992 82.1407V0.499023L254.484 82.1407L305.381 0.499023V78.656L254.484 163.782L202.992 82.1407Z"
                fill="black"
                fill-opacity="0.18"
              />
            </g>
            <mask
              id="mask6_29_179"
              maskUnits="userSpaceOnUse"
              x="0"
              y="163"
              width="101"
              height="164"
            >
              <path d="M0 163.283H100.008V326.567H0V163.283Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask6_29_179)">
              <path d="M0 163.283H100.008V326.567H0V163.283Z" fill="black" fill-opacity="0.21" />
              <path
                d="M48.2286 244.926C67.2781 244.926 100.019 244.926 100.019 244.926V326.567H-5.65625C-5.65625 326.567 -0.00104904 246.915 48.2286 244.926Z"
                fill="black"
                fill-opacity="0.23"
              />
              <path
                d="M51.8388 244.925C9.2261 247.414 -1.19141 188.672 -1.19141 188.672V163.283C-1.19141 163.283 33.6328 163.283 52.8864 163.283C100.603 174.732 100.007 244.925 100.007 244.925H51.8388Z"
                fill="black"
                fill-opacity="0.19"
              />
            </g>
            <mask
              id="mask7_29_179"
              maskUnits="userSpaceOnUse"
              x="100"
              y="163"
              width="103"
              height="164"
            >
              <path d="M100.008 163.283H202.992V326.567H100.008V163.283Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask7_29_179)">
              <path
                d="M100.008 163.283H202.992V326.567H100.008V163.283Z"
                fill="black"
                fill-opacity="0.21"
              />
              <path
                d="M100.008 163.283H202.992C202.992 163.283 204.48 240.942 151.5 244.925C132.748 244.925 100.008 244.925 100.008 244.925V163.283Z"
                fill="black"
                fill-opacity="0.17"
              />
              <path
                d="M202.996 326.566H100.012C100.012 326.566 98.524 248.908 151.504 244.925C170.256 244.925 202.996 244.925 202.996 244.925V326.566Z"
                fill="black"
                fill-opacity="0.15"
              />
            </g>
            <mask
              id="mask8_29_179"
              maskUnits="userSpaceOnUse"
              x="202"
              y="163"
              width="101"
              height="164"
            >
              <path d="M202.992 163.283H303V326.567H202.992V163.283Z" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask8_29_179)">
              <path
                d="M202.992 163.283H303V326.567H202.992V163.283Z"
                fill="black"
                fill-opacity="0.31"
              />
              <path
                d="M254.783 154.323V326.567C254.783 326.567 205.076 329.056 202.695 240.445C202.695 209.082 202.695 154.323 202.695 154.323H254.783Z"
                fill="black"
                fill-opacity="0.08"
              />
              <path
                d="M254.781 335.534V163.29C254.781 163.29 303.892 160.801 306.273 249.412C306.273 280.774 306.273 335.534 306.273 335.534H254.781Z"
                fill="black"
                fill-opacity="0.18"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RewardCardPreviewBackground;
