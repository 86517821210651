import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { CheckmarkIcon } from '../../../../../assets/newDesign/CheckmarkIcon';
import { useStyles } from './ToggleWIthLabels.styles';

interface ToggleWIthLabelsProps {
  labels: { title: string; label: string }[];
  active: number;
  setActive: (i: number) => void;
}

const ToggleWIthLabels: FC<ToggleWIthLabelsProps> = ({ labels, active, setActive }) => {
  const classes = useStyles();
  const handleClick = (i: number) => {
    if (i === active) return;
    setActive(i);
  };

  return (
    <Grid className={classes.root}>
      {labels.map((l, i) => (
        <Box
          className={`${classes.option} ${i === 0 && classes.leftOption} ${
            i === labels.length - 1 && classes.rightOption
          } ${active === i && classes.activeOption}`}
          onClick={() => handleClick(i)}
          id={`campaign-type-change-button-${l.title.split(' ').join('-')}`}
        >
          <Box>
            <CheckmarkIcon
              className={`${classes.checkmark}  ${active === i && classes.checkmarkActive}`}
            />
          </Box>
          <Typography className={classes.title}>{l.title}</Typography>
          <Typography className={classes.subtitle}>{l.label}</Typography>
        </Box>
      ))}
    </Grid>
  );
};

export default ToggleWIthLabels;
