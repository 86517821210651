import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
    <mask
      id="mask0_2083_97127"
      width={60}
      height={60}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <circle cx={30} cy={30} r={30} fill="#D9D4FF" />
    </mask>
    <g strokeWidth={1.4} mask="url(#mask0_2083_97127)">
      <circle cx={30} cy={30} r={29.3} fill="#F3F2FD" fillOpacity={0.2} stroke="#D9D4FF" />
      <circle
        cx={29.765}
        cy={29.929}
        r={20.354}
        fill="#8F81FF"
        fillOpacity={0.3}
        stroke="#A69DF7"
        opacity={0.7}
        transform="rotate(-.122 29.765 29.93)"
      />
      <path
        fill="#fff"
        stroke="#8B89A0"
        d="M28.229 23.101c-1.8-1.154-4.163.14-4.159 2.278l.024 11.08c.005 2.138 2.374 3.423 4.168 2.261l8.582-5.559c1.648-1.066 1.642-3.478-.01-4.538L28.23 23.1Z"
      />
    </g>
  </svg>
);
const VideoTypeMarketingIcon = memo(SvgComponent);
export default VideoTypeMarketingIcon;
