import { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { OnboardingWelcomeStrings } from '../../../common/localization/en';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import ArrowRightSmall from '../../../common/assets/ArrowRightSmall';

interface LeftSideLayoutProps {
  name: string;
  onGetStartedClick: () => void;
  onDoThisLaterClick: () => void;
}

export const LeftSideWelcomeLayout: FC<LeftSideLayoutProps> = ({
  name,
  onGetStartedClick,
  onDoThisLaterClick,
}) => {
  return (
    <Box marginTop="210px">
      <Box
        sx={{
          padding: '142px 90px 0 90px',
          borderTop: '1px solid #DCDBE4',
        }}
      >
        <Typography
          sx={{
            padding: '5px 6px',
            fontSize: '38px',
            color: '#5242EA',
            fontWeight: 600,
            backgroundColor: '#EBE9FF',
            width: 'fit-content',
            borderRadius: '8px',
          }}
        >
          {OnboardingWelcomeStrings.Branding}
        </Typography>
        <Typography
          sx={{
            fontSize: '40px',
            fontWeight: 600,
            lineHeight: '48px',
            color: '#101828',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          {OnboardingWelcomeStrings.PageTitle} <b>{name}</b>!
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            fontWeight: 400,
            fontFamily: 'Inter',
            lineHeight: '24px',
            color: '#475467',
            mb: '30px',
            width: '555px',
          }}
        >
          {OnboardingWelcomeStrings.PageDescription}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', padding: '0 90px' }}>
        <RoundedButton
          title="Get Started"
          endIcon={<ArrowRightSmall />}
          fontWeight={400}
          height="50px"
          sx={{ padding: '0 70px', width: '259px' }}
          onClick={onGetStartedClick}
        />
      </Box>
      <Divider sx={{ mt: '126px', borderColor: '#DCDBE4' }} />
    </Box>
  );
};
