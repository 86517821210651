import { RewardFulfillmentMethod } from './rewardFulfillment';
import { DiscountType } from '../../pages/QuickStart/Rewards/RewardTypeCustomizationSection/RewardTypeCustomizationSection.helper';

export interface CampaignsApiModel {
  id: string;
  name: string;
  code: string;
  venueId: string;
  filterUrl: string;
  imageUrl: string;
  published: boolean;
  createdAt: string;
  startedAt: string;
  endedAt: string;
  numAchievements: number;
  points: number;
  downloadLink: string;
  value: string;
  activities?: Activity[];
  description?: string;
  rewardTitle: string | null;
  rewardDescription: string | null;
  couponEnabled?: boolean;
  cardEnabled?: boolean;
  isNullReward: boolean;
  createdByName: string;
  rewardType: string;
  fulfillmentConfig?: CampaignFulfillmentConfig;
  content?: CampaignUser[];
  videos?: number;
  isLocked?: boolean;
  recommendedVideoStyle?: string;
  campaignObjective?: string;
  rewardHeader: string;
  cardStyle: string;
  invitationMsgConfig?: InvitationSMSConfig;
  contact: string;
  contactMethod: string;
  shortcode?: string;
  isPreferred?: boolean;
  emailRequired?: boolean;
  welcomeVideoUrl: string | null;
  showWelcomeVideo: boolean;
}

export interface CampaignUser {
  userId: string;
  campaignId: string;
  points: number;
  active: boolean;
  fulfilled: boolean;
  userName: string;
  userStatus: string;
  phoneNumber: string;
  email: string;
  photoUrl: string;
  videos: number;
  videoViews: number;
  videoCtaClicks: number;
  lastVideoCreatedAt: string;
  phoneNumberVerified: boolean;
  shares: number;
  createdAt: string;
}

export interface CampaignVideosApiModel {
  id: string;
  venueId: string;
  userId: string;
  url: string;
  thumbnailUrl: string;
  shortcode: string;
  details: {
    title: string;
    metaTitle: string;
    metaDescription: string;
    videoText: string;
    videoMessage: string;
    ctaBtnText: string;
    ctaBtnLinkUrl: string;
    approvalReason: string;
    smsShareList: [
      {
        name: string;
      },
    ];
  };
  shares: number;
  copiedFrom: string;
  campaignId: string;
  createdAt: string;
  userName: string;
  userDisplayName: string;
  userPhotoUrl: string;
  userScaleoAffiliateId: number;
  returnUrl: string;
  isPublic: true;
  views: number;
  ctaClicks: number;
  campaignViews: number;
  campaignCtaClicks: number;
  videoPageUrl: string;
  points: number;
  status: string;
  widgetTag: string;
  convertedReferrals: number;
  creationIp: string;
  featured: true;
  updatedAt: string;
  deleted: true;
  transcript: string;
  keywordScore: number;
  keywordsCount: number;
}

export interface GetExistedHeroesCampaignApiModel {
  items: CampaignUser[];
  page: number;
  size: number;
  totalItems: number;
  totalPages: number;
}

export interface GetCampaignVideosApiModel {
  items: CampaignVideosApiModel[];
  page: number;
  size: number;
  totalItems: number;
  totalPages: number;
}

export interface CreateCampaignsApiModel {
  title: string;
  code: string;
  description: string;
  sponsorCardId?: string;
  rewardTitle: string;
  rewardDescription: string;
}

export enum CampaignActivitiesTypes {
  CreateVideo = 'CREATE_VIDEO',
  Conversion = 'CONVERSION',
  Clicks = 'SHARE',
  CallToAction = 'CALL_TO_ACTION',
  BonusPoints = 'BONUS_POINTS',
}
export interface Activity {
  activityType: CampaignActivitiesTypes;
  points: number;
  status?: boolean;
}

export interface CreateIncentiveCampaignApiModel {
  name: string;
  imageUrl: string;
  value?: string;
  points: number;
  venueId: string;
  activities: Activity[];
  title?: string;
  subtitle?: string;
  rewardTitle?: string;
  rewardDescription?: string;
  campaignObjective?: CustomerActivitiesType;
  rewardType?: DiscountType;
  couponEnabled?: boolean;
  isNullReward?: boolean;
  isLocked: boolean;
  invitationMsgConfig?: InvitationSMSConfig;
  fulfillmentConfig?: CampaignFulfillmentConfig;
  recommendedVideoStyle?: string;
  rewardHeader?: string;
  cardStyle?: string;
  cardEnabled?: boolean;
  contact?: string;
  contactMethod?: string;
  emailRequired?: boolean;
  isPreferred?: boolean;
  showWelcomeVideo?: boolean;
  welcomeVideoUrl?: string;
}

export interface PatchIncentiveCampaignApiModel {
  name?: string;
  imageUrl?: string;
  action?: string;
  title?: string;
  points?: number;
  activities?: Activity[];
  rewardTitle?: string;
  rewardDescription?: string;
  couponEnabled?: boolean;
  isNullReward?: boolean;
  fulfillmentConfig?: Pick<CampaignFulfillmentConfig, 'method'>;
  isLocked?: boolean;
  invitationMsgConfig?: InvitationSMSConfig;
  emailRequired?: boolean;
  isPreferred?: boolean;
  showWelcomeVideo?: boolean;
  welcomeVideoUrl?: string;
  recommendedVideoStyle?: string;
}

export interface PatchIncentiveCampaignOptions {
  values: PatchIncentiveCampaignApiModel;
  campaignId: string;
}

export interface GetCampaignsAPIModel {
  id: string;
  name: string;
  code: string;
  venueId: string;
  filterUrl: string;
  imageUrl: string;
  published: boolean;
  createdAt: string;
  startedAt: string;
  endedAt: string;
  numAchievements: number;
  points: number;
  downloadLink: string;
  value: string;
  rewardTitle: string | null;
  rewardDescription: string | null;
  couponEnabled: boolean;
  isNullReward: boolean;
  createdByName: string;
  rewardType: string;
  activities: Activity[];
  campaignObjective: string;
  rewardHeader: string;
  cardStyle: string;
  contact: string;
  contactMethod: string;
  isPreferred: boolean;
  welcomeVideoUrl: string | null;
  showWelcomeVideo: boolean;
}

export interface GetIncentiveUsersAPIModel {
  totalPages: number;
  totalElements: number;
  size: number;
  content: IncentiveUserAPIModel[];
  number: number;
  sort: {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
  };
}
export interface IncentiveUserAPIModel {
  userId: string;
  campaignId: string;
  points: number;
  active: boolean;
  fulfilled: boolean;
  userName: string;
  userStatus: string;
  photoUrl: string;
  videos: number;
  videoViews: number;
  videoCtaClicks: number;
  lastVideoCreatedAt: string;
  phoneNumberVerified: boolean;
}

export interface SalesCalculationOptions {
  venueId: string;
  payload: {
    numberOfSalesReps: number;
    incentiveBudget: number;
    videosCreated: number;
    engagements: number;
  };
}

export interface FundraisingCalculationOptions {
  venueId: string;
  payload: {
    numberOfHeroes: number;
    rewardBudget: number;
  };
}

export interface ETSCalculationOptions {
  venueId: string;
  payload: {
    numberOfHeroes: number;
    rewardBudget: number;
  };
}

export interface MarketingCalculationOptions {
  venueId: string;
  payload: {
    monthlyDigitalMarketingBudget: number;
    averageCartCheckout: number;
  };
}

export interface GetCampaignsRequest {
  venueId: string;
  page?: string;
  size?: string;
  sort?: string;
  search?: string;
}

export interface GetCampaignUsersRequestOptions {
  id: string;
  pageable: {
    page?: number;
    size?: number;
    sort?: string[];
  };
  filter?: {
    fulfilled: boolean | null;
  };
  search?: string;
  status?: string;
  hasVideos?: boolean;
}
export interface GetCampaignVideosRequestOptions {
  id: string;
  pageable: {
    page?: number;
    size?: number;
    sort?: string[];
  };
}

export interface GetCampaignUsersRequest {
  page?: string;
  size?: string;
  sort?: string;
  search?: string;
  status?: string;
  fulfilled?: string;
  hasVideos?: boolean;
}
export interface GetCampaignVideosRequest {
  page?: string;
  size?: string;
  sort?: string;
}

export enum ImageTemplateCode {
  CouponImageTemplate = 'CIT001',
  NoRewardTemplate = 'CIT002',
}

export interface GetRewardCardRequestOptions {
  logoUrl: string;
  userName: string;
  rewardHeader: string;
  rewardTitle: string;
  cardStyle: string;
  contactPhone: string;
  companyName: string;
  date: string;
}

export interface RewardCard {
  imageUrl: string;
}

export interface EditUsersInCampaignOptions {
  campaignId: string;
  payload: {
    userIds?: Array<string>;
    phoneNumbers?: Array<string>;
  };
}

export enum CustomerActivitiesType {
  videoContent = 'VIDEO_CONTENT',
  showcase = 'SHOWCASE',
  sharing = 'SHARING',
}

export interface CampaignFulfillmentConfig {
  method: RewardFulfillmentMethod;
  sms: {
    mediaUrl: string;
    body: string;
    parameters?: {
      additionalProp1: string;
      additionalProp2: string;
      additionalProp3: string;
    };
  };
  webhook?: {
    webhookUrl: string;
    validationKey: string;
  };
}

export interface InvitationSMSConfig {
  mediaUrl: string;
  body: string;
}

export interface UserIncentiveCampaignsApiModel {
  campaignId: string;
  userId: string;
  points: number;
  active: boolean;
  fulfilledAt: string;
  rewardCardUrl: string;
  campaignPoints: number;
  videosCount: number;
  createdAt: string;
  fulfilled: boolean;
  campaign: CampaignsApiModel;
}
