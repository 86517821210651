import React from 'react';
import { useStyles } from './RightSideCompleteLayout.styles';
import { Box, Divider, Stack, Typography } from '@mui/material';
import CompletedIcon from '../../../../common/assets/QuickStartIcons/CompletedIcon';
import MobileIcon from './icons/MobileIcon';

const steps: Array<{
  icon: JSX.Element;
  status?: 'success' | 'pending';
  title: string;
  description: string;
}> = [
  {
    status: 'success',
    icon: <CompletedIcon />,
    title: 'Account Branding',
    description: 'Key branding elements applied to both your account and video app',
  },
  {
    status: 'pending',
    icon: <MobileIcon />,
    title: 'Collect Videos',
    description: 'Use Campaigns to invite users to create the videos you want.',
  },
];

const RightSideCompleteLayout = () => {
  const styles = useStyles();

  return (
    <Box sx={{ p: '56px' }}>
      <Typography fontSize="16px" maxWidth="410px">
        Now that your branding is set, it’s time to create awareness and get customer to create
        videos.
      </Typography>

      <Divider sx={{ margin: '20px 0 100px 0', borderColor: '#DCDBE4', maxWidth: '489px' }} />

      <Stack spacing={3}>
        {steps.map((step) => {
          const outlineColor = step.status === 'success' ? '#67CE67' : '#F7B409';

          return (
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: '#fff',
                display: 'flex',
                maxWidth: '489px',
              }}
            >
              <Box
                className={styles.iconBadge}
                sx={{
                  borderColor: `${outlineColor} !important`,
                }}
              >
                <Box
                  className={styles.icon}
                  sx={{
                    borderColor: `${outlineColor} !important`,
                  }}
                >
                  {step.icon}
                </Box>
              </Box>
              <Box sx={{ pl: '40px', pt: '16px', pr: '20px' }}>
                <Typography className={styles.stepTitle}>{step.title}</Typography>
                <Box sx={{ mb: '14px', width: '40px' }}>
                  <Divider sx={{ borderColor: outlineColor }} />
                </Box>
                <Typography className={styles.stepDescription}>{step.description}</Typography>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default RightSideCompleteLayout;
