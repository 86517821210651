import { FC } from 'react';

interface IconProps {
  color?: string;
}

export const AccountsIcon: FC<IconProps> = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_6269_106916"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="50"
        height="50"
      >
        <circle cx="25" cy="25" r="25" fill="#F1F1F1" />
      </mask>
      <g mask="url(#mask0_6269_106916)">
        <circle cx="25" cy="25" r="25" fill="#EEECFD" />
        <path
          d="M30.0173 51.5566L30.0173 -1.55658"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.9827 51.5566L40.9827 -1.55658"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0173 51.5566L20.0173 -1.55658"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.01733 51.5566L9.01733 -1.55658"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M41.7727 28.0292L41.7727 33.4065C41.7727 33.9588 41.325 34.4065 40.7727 34.4065H31.7497C31.7497 34.4065 31.7495 29.1371 31.7495 28.0292C31.7495 25.2613 33.9933 23.0176 36.7611 23.0176C39.5289 23.0176 41.7727 25.2613 41.7727 28.0292Z"
        fill="white"
        stroke="#5242EA"
        strokeLinejoin="round"
      />
      <circle cx="36.7614" cy="21.1843" r="3.75945" fill="white" stroke="#5242EA" />
      <path
        d="M7.77222 28.0292L7.77222 33.4065C7.77222 33.9588 8.21993 34.4065 8.77222 34.4065H17.7952C17.7952 34.4065 17.7954 29.1371 17.7954 28.0292C17.7954 25.2613 15.5516 23.0176 12.7838 23.0176C10.016 23.0176 7.77222 25.2613 7.77222 28.0292Z"
        fill="white"
        stroke="#5242EA"
        strokeLinejoin="round"
      />
      <circle
        r="3.75945"
        transform="matrix(-1 0 0 1 12.7835 21.1843)"
        fill="white"
        stroke="#5242EA"
      />
      <path
        d="M32.855 30.033V34.5126C32.855 35.0649 32.4073 35.5126 31.855 35.5126H17.6857C17.1334 35.5126 16.6857 35.0663 16.6857 34.5141C16.6856 33.2905 16.6854 31.2327 16.6854 30.033C16.6854 25.5679 20.3051 21.9482 24.7702 21.9482C29.2353 21.9482 32.855 25.5679 32.855 30.033Z"
        fill="#D9D4FF"
      />
      <path d="M24.7704 26.8232L24.7704 35.513" stroke="#5242EA" strokeLinecap="round" />
      <path
        d="M32.855 30.033V34.5126C32.855 35.0649 32.4073 35.5126 31.855 35.5126H17.6857C17.1334 35.5126 16.6857 35.0663 16.6857 34.5141C16.6856 33.2905 16.6854 31.2327 16.6854 30.033C16.6854 25.5679 20.3051 21.9482 24.7702 21.9482C29.2353 21.9482 32.855 25.5679 32.855 30.033Z"
        stroke="#1A1538"
        strokeLinejoin="round"
      />
      <circle cx="24.7705" cy="18.2547" r="5.77028" fill="white" stroke="#1A1538" />
      <path
        d="M26.3735 19.6104C25.9886 20.0645 25.4139 20.3528 24.772 20.3528C24.13 20.3528 23.5553 20.0645 23.1704 19.6104"
        stroke="#5242EA"
        strokeLinecap="round"
      />
      <rect x="27.251" y="28.7305" width="3" height="1" rx="0.5" fill="#5242EA" />
    </svg>
  );
};
