import { FC } from 'react';
import { Box, List } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../../../store';
import { segmentTrackCall } from '../../../../utils/segment';
import { segmentActions, UserRoles } from '../../../constants/constants';
import { WebsiteRoutes, SuperAdminRoutes } from '../../../constants/routes';
import { IMenuItem, menuItems, useStylesList } from './Menu.helper';
import { MenuItem } from './menuItem/MenuItem';
import { MenuItemCollapsed } from './menuItemCollapsed/MenuItemCollapsed';
import { SidebarStrings } from '../../../localization/en';
import { useIsFteFinished } from '../../../../hooks/useIsFteFinished';
import { useClientType } from '../../../../services/hooks/useClientType';
// import { useSidebarFTE } from '../../../../hooks/useSidebarFTE';

interface MenuProps {
  collapsed: boolean;
  isSuperAdmin: boolean;
  showVenuMenu: boolean;
  itemClickHandler?: () => void;
}

export const Menu: FC<MenuProps> = ({
  collapsed,
  isSuperAdmin,
  showVenuMenu,
  itemClickHandler,
}) => {
  const { pathname } = useLocation();
  const classes = useStylesList();

  // const { unvisitedRoutes } = useSidebarFTE();

  const { venue } = useTypedSelector((state) => state.venue);

  const { role } = useTypedSelector((state) => state.me);
  const isSuperAdminRoute = Object.values(SuperAdminRoutes).filter(
    (item) => item === pathname,
  ).length;
  const clickHandler = (name: string) => {
    segmentTrackCall(`${segmentActions.SideBar}_${name}`, venue.id);
    if (typeof itemClickHandler !== 'undefined') {
      itemClickHandler();
    }
  };

  const { isTopGolfClient, isHealthCare } = useClientType();

  const FTEIsFinished = useIsFteFinished();
  const renderItems = (items: IMenuItem[]) =>
    items
      .filter((item) =>
        item.route === WebsiteRoutes.Integrations ? role !== UserRoles.venue_manager : true,
      )
      .filter((item) => (item.isSuperAdmin ? isSuperAdmin : showVenuMenu))
      .map((item) => ({
        ...item,
        subMenuItems: item.subMenuItems?.filter((subMenuItem) =>
          subMenuItem.route === WebsiteRoutes.SettingsManagers
            ? role === UserRoles.admin_role
            : true,
        ),
      }))
      // Below map function does:
      // Remove signup config menu item for topgolf clientType,
      // Not default side bar menu item to web app signup config
      .map((item) => {
        let temp = item.subMenuItems || [];
        if (item.text === SidebarStrings.Configurations && isTopGolfClient && item.subMenuItems) {
          temp.splice(1, 1, {
            ...item.subMenuItems[1],
            route: WebsiteRoutes.BrandingStyle,
          });
        }
        return item.text === SidebarStrings.Configurations && isTopGolfClient
          ? { ...item, subMenuItems: temp }
          : item;
      })
      .filter((item) => (isTopGolfClient ? !item.isNotTopGolfItem : !item.isTopGolfItem))
      .map((item) =>
        collapsed ? (
          <MenuItemCollapsed item={item} key={item.route} />
        ) : (
          <MenuItem
            item={item}
            key={item.route}
            itemClickHandler={() => clickHandler(item.text.toLowerCase())}
            blur={
              FTEIsFinished || isTopGolfClient || isSuperAdminRoute
                ? false
                : [
                    '/dashboard/heroes',
                    '/content',
                    '/creators',
                    '/socialverse',
                    '/rewards/campaigns',
                  ].some((el) => el === item.route)
            }
            FTEIsFinished={FTEIsFinished}
            showPulseNotification={false}
          />
        ),
      );

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <List className={classes.root} component="nav">
        {!pathname.includes('admin') && <div>{renderItems(menuItems(isHealthCare))}</div>}
        {/* removed customization section SV-341 Update the dashboard left navigation column */}
        {/* <div className={classes.sectionDivider}>{renderItems(managerItems)}</div> */}
      </List>
    </Box>
  );
};
