import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIState } from '../storeModels';

const initialState: UIState = {
  sidebarOpen: false,
  isCummulativeGraph: true,
  emoji: {
    loading: false,
    initialized: false,
  },
  wasInvitingModalDisplayed: false,
  scrollY: 0,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    triggerSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    setIsCummulativeGraph(state, action: PayloadAction<boolean>) {
      state.isCummulativeGraph = action.payload;
    },
    setEmojiState(state, action: PayloadAction<UIState['emoji']>) {
      state.emoji = action.payload;
    },
    updateInvitingModalStatus(state) {
      state.wasInvitingModalDisplayed = true;
    },
    setScrollY(state, action: PayloadAction<number | undefined>) {
      state.scrollY = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  triggerSidebar,
  reset,
  setIsCummulativeGraph,
  setEmojiState,
  updateInvitingModalStatus,
  setScrollY,
} = uiSlice.actions;
export default uiSlice.reducer;
