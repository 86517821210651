import { FC } from 'react';

export const MenuIconPlayActive: FC = () => (
  <svg width="56" height="47" viewBox="0 0 56 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50 0.5H6C2.96243 0.5 0.5 2.96243 0.5 6V41C0.5 44.0376 2.96243 46.5 6 46.5H50C53.0376 46.5 55.5 44.0376 55.5 41V6C55.5 2.96243 53.0376 0.5 50 0.5Z"
      fill="#5242EA"
      stroke="#5242EA"
    />
    <rect
      x="16"
      y="12.707"
      width="24"
      height="19.5"
      rx="3"
      stroke="white"
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.822 26.345C26.1554 26.7189 25.3328 26.2371 25.3328 25.4728V19.9144C25.3328 19.1501 26.1554 18.6683 26.822 19.0422L31.7776 21.8214C32.4587 22.2034 32.4587 23.1838 31.7776 23.5658L26.822 26.345Z"
      stroke="white"
      strokeWidth="1.3"
      strokeLinejoin="round"
    />
  </svg>
);
