import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={24} fill="none" {...props}>
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M8.332 10.833a2.333 2.333 0 1 0 0-4.666 2.333 2.333 0 0 0 0 4.666Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M1.332 7.1c0-1.96 0-2.94.382-3.689a3.5 3.5 0 0 1 1.53-1.53C3.991 1.5 4.971 1.5 6.931 1.5h12.133c1.96 0 2.94 0 3.69.381a3.5 3.5 0 0 1 1.529 1.53c.381.749.381 1.729.381 3.689v9.8c0 1.96 0 2.94-.381 3.689a3.5 3.5 0 0 1-1.53 1.53c-.748.381-1.729.381-3.689.381H6.932c-1.96 0-2.94 0-3.689-.381a3.5 3.5 0 0 1-1.53-1.53c-.381-.749-.381-1.729-.381-3.689V7.1Z"
    />
    <path
      stroke={props.color || '#8B89A0'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m3.983 22.183 7.696-7.696c.462-.462.693-.693.96-.78.234-.076.486-.076.72 0 .267.087.498.318.96.78l7.644 7.644M15.332 15.5l3.347-3.347c.462-.462.693-.693.96-.78.233-.075.486-.075.72 0 .267.087.498.318.96.78l3.346 3.347m-14-7A2.333 2.333 0 1 1 6 8.5a2.333 2.333 0 0 1 4.666 0Zm-3.733 14h12.133c1.96 0 2.94 0 3.69-.381a3.5 3.5 0 0 0 1.529-1.53c.381-.749.381-1.729.381-3.689V7.1c0-1.96 0-2.94-.381-3.689a3.5 3.5 0 0 0-1.53-1.53c-.748-.381-1.729-.381-3.689-.381H6.932c-1.96 0-2.94 0-3.689.381a3.5 3.5 0 0 0-1.53 1.53c-.381.749-.381 1.729-.381 3.689v9.8c0 1.96 0 2.94.382 3.689a3.5 3.5 0 0 0 1.53 1.53c.748.381 1.728.381 3.688.381Z"
    />
  </svg>
);
const VideoStyleListExperientialIcon = memo(SvgComponent);
export default VideoStyleListExperientialIcon;
