import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    padding: '24px 0',
  },
  closeIcon: {
    position: 'absolute',
    top: '25px',
    right: '22px',
    width: '24px',
    color: '#667085',
    cursor: 'pointer',
  },
  title: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828',
    marginBottom: '21px',
  },
  optionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    border: '1px solid #EAECF0',
    borderRight: 0,
    borderLeft: 0,
    padding: '15px 0',
  },
  button: {
    width: 'calc(100% - 48px)',
    height: '44px',
    margin: '0 24px',
    marginTop: '25px',
  },
  campaignsWrapper: {
    padding: '15px 24px 25px 24px',
    borderBottom: '1px solid #EAECF0',
  },
  campaignsTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '6px',
  },
  select: {
    width: '300px',
    height: '44px',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    color: '#667085',
    fontSize: '15px',
    fontWeight: 400,
    padding: '0 20px 0 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '6px',
    cursor: 'pointer',
  },
  selectName: {
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  campaignsSelectWrapper: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    zIndex: 10,
    width: '300px',
    maxHeight: '140px',
    overflow: 'hidden',
    overflowY: 'scroll',
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    borderRadius: '15px',
    marginTop: '4px',

    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#F5F6F8',
      borderRadius: '8px',
    },
  },
  campaign: {
    cursor: 'pointer',
    width: '168px',
    height: '44px',
    borderRadius: '6px',
    backgroundColor: '#F9FAFB',
    padding: '0 8px',
    margin: '2px 6px',
    transition: '0.11s',

    '&:hover': {
      width: '100%',
      backgroundColor: '#EEECFF',
      margin: '2px 0',
    },
  },
  campaignName: {
    width: '300px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#101828',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '44px',
  },
}));
