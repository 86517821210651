import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useUsersTablesStyles = makeStyles((theme: Theme) => ({
  UsersTableStyles: {
    '& #common-table-wrapper': {
      maxHeight: '630px',
      height: '630px',
    },

    '& table': {
      borderSpacing: '0 !important',
    },
    '& th': {
      '& > div': {
        padding: '5px !important',
        fontSize: '12px',
      },

      '&:nth-child(1) div': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '15px',
        minWidth: '220px',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '15px',
      },
    },
    '& tr': {
      height: '48px !important',
      fontSize: '2px !important',
      borderSpacing: 'none !important',
    },
    '& td': {
      fontSize: '12px !important',
      '& span': { fontSize: '12px !important' },
      '& p': { fontSize: '12px !important' },
      '& > div': {
        padding: '5px !important',
        fontSize: '12px !important',
      },
      '&:nth-child(1)': {
        paddingLeft: '18px !important',
        minWidth: '220px',
      },
      '&:nth-child(2)': { paddingLeft: 0 },
      '&:nth-child(2) div': {
        margin: '0 auto',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
      },
      '&:nth-child(8) span': {
        paddingLeft: '0',
        display: 'block',
        margin: '0 auto',
      },
    },
  },
  contentWrapper: {
    background: '#fff',
  },
  line: {
    borderTop: '1px solid #E1E5EE',
    marginTop: '1px',
  },
  searchWrapper: {
    paddingLeft: '19px',
    paddingTop: '10px',
  },
  topBarWrapper: {
    height: '68px',
  },
  buttonsWrapper: {
    display: 'flex',
    marginRight: '15px',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    color: '#1A1538',
    fontWeight: 400,
    fontSize: '14px',
    border: '0',
    height: '44px',

    '& svg': {
      color: theme.palette.grey[500],
    },
  },
}));
