import { FC } from 'react';
export const UserIconAnalytics: FC = () => (
  <svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_704_20270"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={50}
      height={50}
    >
      <circle cx={25} cy={25} r={25} fill="#F1F1F1" />
    </mask>
    <g mask="url(#mask0_704_20270)">
      <circle cx={25} cy={25} r={25} fill="#EEECFD" />
      <path
        d="M-0.970703 28.4619H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-0.970703 39.4272H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-0.970703 18.4619H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-0.970703 7.46191H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0397 14.5313C15.7362 16.2904 16.4167 20.0458 17.0449 21.7037H32.1223C35.787 15.2121 30.133 9.71486 24.0078 9.76722C19.1076 9.8091 17.9873 12.9607 18.0397 14.5313Z"
        fill="#1A1538"
      />
      <rect x={22} y={24.8438} width={6} height={9.21935} rx={3} fill="white" />
      <ellipse cx={18.9816} cy={22.626} rx={1.83038} ry={2.35359} fill="white" stroke="#1A1538" />
      <ellipse
        rx={1.83038}
        ry={2.35359}
        transform="matrix(-1 0 0 1 31.0201 22.626)"
        fill="white"
        stroke="#1A1538"
      />
      <path
        d="M18.9934 16.689C18.9934 15.7435 19.7599 14.9771 20.7054 14.9771H29.2653C30.2108 14.9771 30.9773 15.7435 30.9773 16.689V23.2127C30.9773 26.3269 28.4527 28.8514 25.3385 28.8514H24.6322C21.518 28.8514 18.9934 26.3269 18.9934 23.2127V16.689Z"
        fill="white"
        stroke="#1A1538"
      />
      <path
        d="M23.0741 15.7737C22.3244 18.1994 20.3454 19.2956 18.4923 19.2705V14.9197L21.5975 13.9197H28.7695L31.4786 14.9197V18.0647C27.8028 19.8216 24.186 18.1994 23.0741 15.7737Z"
        fill="#1A1538"
      />
      <path
        d="M8.30396 51.2319H41.696L39.3544 38.0577C38.9518 35.7923 37.2891 33.9567 35.0745 33.3326L28.0506 31.3532H22.0099L14.9329 33.3363C12.7143 33.958 11.0476 35.7952 10.6444 38.0637L8.30396 51.2319Z"
        fill="#D9D5FF"
        stroke="#5242EA"
      />
      <path
        d="M8.30396 51.2319H41.696L39.3544 38.0577C38.9518 35.7923 37.2891 33.9567 35.0745 33.3326L28.0506 31.3532H22.0099L14.9329 33.3363C12.7143 33.958 11.0476 35.7952 10.6444 38.0637L8.30396 51.2319Z"
        stroke="#5242EA"
      />
      <path
        d="M24.9958 33.8574V50.1064"
        stroke="#5242EA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16 43.4087V52.4614" stroke="#5242EA" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M34 43.4087V52.4614" stroke="#5242EA" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.5 40.4272L17.5 40.4272"
        stroke="#5242EA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 40.4272L28.5 40.4272"
        stroke="#5242EA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 29.7566H28V31.063C28 32.7199 26.6569 34.063 25 34.063C23.3431 34.063 22 32.7199 22 31.063V29.7566Z"
        fill="white"
      />
      <path
        d="M22 28.4094V31.0632C22 32.7201 23.3431 34.0632 25 34.0632V34.0632C26.6569 34.0632 28 32.7201 28 31.0632V28.4094"
        stroke="#1A1538"
      />
    </g>
  </svg>
);
