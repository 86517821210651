import { FC, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ViewsIcon } from '../../assets/searchFilterIcons/ViewsIcon';
import { SettingsIcon } from '../../assets/SettingsIcon';

import { VideoSquareIcon } from '../../assets/VideoSquareIcon';
import { useSocialVerseFilterAppBarStyles } from './SocialVerseFilterAppBar.style';
import {
  SavedFiltersHeaderStrings,
  SavedFiltersPageStrings,
  SocialVerseStrings,
} from '../../localization/en';
import { CreatedFilterModal } from '../../../pages/filters/CreatedFilterMdal';
import { SearchFilterApiModel, SearchTargets } from '../../../api/models/searchFilter';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  postSearchFilters,
  resetActiveFilter,
  resetNewFilter,
} from '../../../store/slices/searchFiltersSlice';
import { useToasts } from 'react-toast-notifications';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { useParams } from 'react-router';

interface ISocialVerseFilterAppBarProps {
  videosAmount?: number;
  filter?: string;
  toggleCreationModal: () => void;
  isCreatedNewFilter: (arg0: string) => void;
}

export const SocialVerseFilterAppBar: FC<ISocialVerseFilterAppBarProps> = ({
  videosAmount = 0,
  filter,
  toggleCreationModal,
  isCreatedNewFilter,
}) => {
  const styles = useSocialVerseFilterAppBarStyles();
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const { trackEvent } = useTrackEvent();
  const { newFilterAttributes, target, activeFilter } = useTypedSelector(
    (state) => state.SearchFilterSlice,
  );
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const canSave = () =>
    !(newFilterName || (activeFilter && (activeFilter as SearchFilterApiModel)?.name));

  const [newFilterName, setnewFilterName] = useState('');
  useEffect(() => {
    setnewFilterName('');
  }, []);

  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const handleClose = () => {
    setmodalIsOpen(false);
  };

  const handleConfirm = (name: string) => {
    if (newFilterAttributes.length && target) {
      dispatch(
        postSearchFilters({
          venueId,
          name,
          target,
          filter: {
            attributes: newFilterAttributes,
          },
          searchText: '',
        }),
      );
      setmodalIsOpen(false);
      setnewFilterName(name);
      isCreatedNewFilter(name);
      addToast(SavedFiltersHeaderStrings.Message, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
    }
  };

  const handleReset = () => {
    dispatch(resetNewFilter());
    dispatch(resetActiveFilter());
    setnewFilterName('');
    isCreatedNewFilter('');
    trackEvent(SocialVerseEventNames.resetResults, { socialVerseId: params.id });
  };
  const isDisabled = !(!!newFilterName || !!newFilterAttributes.length || !!activeFilter);
  return (
    <div className={styles.ToolBar}>
      <Box style={{}} className={styles.VideosAmount}>
        <VideoSquareIcon />
        <Typography style={{ marginLeft: '10px' }}>
          {videosAmount} {SocialVerseStrings.Videos}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        className={styles.ApplyBtn}
        startIcon={<ViewsIcon />}
        disabled={isDisabled}
        sx={{ color: isDisabled ? '#1A1538 !important' : '' }}
        onClick={() => {
          toggleCreationModal();
          trackEvent(SocialVerseEventNames.activeFilter, { socialVerseId: params.id });
        }}
      >
        {newFilterName || activeFilter?.name ? (
          <Box style={{ flex: 1, textAlign: 'left' }}> {newFilterName || activeFilter?.name}</Box>
        ) : (
          <Box style={{ flex: 1, textAlign: 'left' }}> {SavedFiltersPageStrings.UnsavedFilter}</Box>
        )}
        <Box marginLeft="20px" display="flex" alignItems="center">
          <SettingsIcon color="#8B89A0" />
        </Box>
      </Button>
      {!activeFilter && (
        <Button
          variant="contained"
          className={styles.Btn}
          onClick={() => setmodalIsOpen(true)}
          disabled={!canSave()}
          style={{ display: !canSave() ? 'none' : '' }}
        >
          {SavedFiltersPageStrings.SaveThisFilterButton}
        </Button>
      )}
      <Button variant="outlined" className={styles.Btn} onClick={handleReset}>
        {SavedFiltersPageStrings.ResetResults}
      </Button>
      <CreatedFilterModal
        modalIsOpen={modalIsOpen}
        handleClose={handleClose}
        handleConfirm={(name) => {
          handleConfirm(name);
        }}
        target={SearchTargets.VIDEOS}
      />
    </div>
  );
};
