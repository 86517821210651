export const InvitesDirectionArrow = () => {
  return (
    <svg
      width="130"
      height="341"
      viewBox="0 0 130 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ zIndex: '1' }}
    >
      <path
        d="M2.50024 314.48H0.500244H2.50024ZM0.500244 2C0.500244 0.89543 1.39568 0 2.50024 0C3.60481 0 4.50024 0.89543 4.50024 2H0.500244ZM16.1517 328C17.2563 328 18.1517 328.895 18.1517 330C18.1517 331.105 17.2563 332 16.1517 332V328ZM124.5 328C125.605 328 126.5 328.895 126.5 330C126.5 331.105 125.605 332 124.5 332V328ZM14.8516 332C13.747 332 12.8516 331.105 12.8516 330C12.8516 328.895 13.747 328 14.8516 328V332ZM0.500244 314.48V2H4.50024V314.48H0.500244ZM16.1517 332C7.27423 332 0.500244 323.907 0.500244 314.48H4.50024C4.50024 322.196 9.95018 328 16.1517 328V332ZM124.5 332H14.8516V328H124.5V332Z"
        fill="#BDBDBD"
      />
      <path
        d="M120 322.163L128 330.163L120 338.163"
        stroke="#BDBDBD"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
