import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.837a3.837 3.837 0 1 1 7.674 0 3.837 3.837 0 0 1-7.674 0Zm3.837-1.744a1.744 1.744 0 1 0 0 3.488 1.744 1.744 0 0 0 0-3.488ZM0 10.814c0-.578.469-1.047 1.047-1.047h5.58c.579 0 1.047.469 1.047 1.047v18.14c0 .578-.468 1.046-1.046 1.046H1.047A1.046 1.046 0 0 1 0 28.953V10.814Zm2.093 1.046v16.047h3.488V11.861H2.093ZM20.581 9.767a9.419 9.419 0 0 0-9.418 9.419v9.767c0 .579.468 1.047 1.046 1.047h5.582c.578 0 1.046-.468 1.046-1.047v-9.767a1.744 1.744 0 0 1 3.489 0v9.767c0 .579.468 1.047 1.046 1.047h5.581c.579 0 1.047-.468 1.047-1.047v-9.767a9.419 9.419 0 0 0-9.419-9.419Zm-7.325 9.419a7.326 7.326 0 0 1 14.651 0v8.721h-3.488v-8.721a3.837 3.837 0 1 0-7.675 0v8.721h-3.488v-8.721Z"
      fill={props.color || '#fff'}
    />
  </svg>
);

export default SvgComponent;
