import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './TableFilterSelect.styles';
import { RewardCampaignsEvents } from '../../constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';

export interface TabItem {
  tabName: string;
  usersCount?: string;
  filter?: {status?:string, hasVideos?:boolean};
}

interface Props {
  tabsList: TabItem[];
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  handleTrackEvent: IEventTracker;
  type: 'active' | 'invited';
}

const TableFilterSelect: FC<Props> = ({
  tabsList,
  activeTabIndex,
  setActiveTabIndex,
  handleTrackEvent,
  type,
}) => {
  const styles = useStyles();

  const replaceEventNameByTableType =
    RewardCampaignsEvents.RewardCampaginActiveCustomersTableViewAllTabClick.replace(
      'tab',
      `${type}_tab`,
    );

  return (
    <Box className={styles.container}>
      {tabsList.map((tabItem, index) => {
        const isActiveTab = activeTabIndex === index;
        return (
          <Box
            className={styles.tab}
            style={{
              background: isActiveTab ? '#FFFFFF' : 'none',
              borderRadius: isActiveTab ? '6px' : 'none',
              boxShadow: isActiveTab
                ? '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
                : 'none',
            }}
            key={index}
            onClick={() => {
              setActiveTabIndex(index);
              handleTrackEvent(
                replaceEventNameByTableType
                  .replace('subtab', `${tabItem.tabName}_tab`)
                  .toLowerCase(),
              );
            }}
            id={`${tabItem.tabName.split(' ').join('-')}-table-filter`}
          >
            <Typography
              className={styles.tabName}
              style={{
                color: isActiveTab ? '#5242EA' : '#667085',
              }}
            >
              {tabItem.tabName}
            </Typography>
            <Typography className={styles.amount}>{tabItem.usersCount}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default TableFilterSelect;
