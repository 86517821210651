import { FC } from 'react';
import { useStyles } from './GoalProgress.styles';
import { Box, Typography } from '@mui/material';

interface Props {
  points: number;
  goal: number;
}

export const GoalProgress: FC<Props> = ({ goal, points }) => {
  const classes = useStyles();
  const progress = Math.min((points / goal) * 100, 100);
  return (
    <Box className={classes.container}>
      <Box className={classes.contentWrapper}>
        <Box className={classes.progresWrapper}>
          <Box
            className={classes.progresBar}
            style={{
              width: `${progress}%`,
            }}
          />
        </Box>
        <Typography className={classes.progressVal}>{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
};
