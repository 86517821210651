import React, { FC } from 'react';
import { CommonTable } from '../table/CommonTable';
import { Box } from '@mui/material';
import { useStyles } from './InvitesTable.styles';
import { TableContent, TableRow } from '../../commonTypes';

interface Props {
  content: TableContent<TableRow>;
  totalItems: number;
  handleRowDelete?: (id: string) => void;
  withRowDelete?: boolean;
}

export const InvitesTable: FC<Props> = ({
  content,
  totalItems,
  handleRowDelete,
  withRowDelete = true,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.tableWrapper}>
      <CommonTable
        content={content}
        page={0}
        totalItems={totalItems}
        totalPages={1}
        isLoading={false}
        tablePadding="0"
        size={totalItems}
        onSizeChange={() => {}}
        disableFullHeight
        containerClassName={classes.tableContainer}
        topSectionClassName={classes.topSectionClassName}
        headerCellClassName={classes.headerCellClassName}
        rowClassName={classes.rowClassName}
        withRowDelete={withRowDelete}
        withCellDivider={true}
        handleRowDelete={handleRowDelete}
        withAutoScroll={false}
      />
    </Box>
  );
};
