import { Dispatch, FC, SetStateAction } from 'react';

import { Card } from '../../../pages/webappSettings/WebAppCards/WebAppCards.helper';
import { Typography, Box, Grid, Divider } from '@mui/material';
import { WebAppPageStrings } from '../../localization/en';

import { useStylesCardBlock } from './CarBlock.style';
import { ConfigStateCard } from '../../../pages/webappSettings/WebAppCards/WebAppCards';
import { BackgroundImageUploader } from '../ImageUploader/uploaders/BackgroundImageUploader';
import { LogoUploader } from '../ImageUploader/uploaders/LogoUploader';
import { CustomColorPickerWithInput } from '../CustomColorPicker/CustomColorPickerWithInput';
import { FormikProps } from 'formik';
import { ThemeSelect } from '../ThemeSelect/ThemeSelect';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
import { Toggle } from '../toggle/CustomToggle';
import { patchVenueProperties } from '../../../store/slices/venueSlice';
import { useAppDispatch, useTypedSelector } from '../../../store';

interface CardBlockProps {
  setCard: (card: Card) => void;
  sectionSubtitle?: string;
  color: string;
  setColor: (color: string) => void;
  setConfigCard: Dispatch<SetStateAction<ConfigStateCard>>;

  logo: string;
  onLogoChange: (logoUrl: string) => void;
  backgroundImage: string;
  onBackgroundImageChange: (backgroundUrl: string) => void;
  formik: FormikProps<any>;
  setColorError: (error: boolean) => void;
}

export const CardBlock: FC<CardBlockProps> = ({
  setConfigCard,
  logo,
  onLogoChange,
  backgroundImage,
  onBackgroundImageChange,
  formik,
  setColorError,
  setColor,
}) => {
  const classes = useStylesCardBlock();
  const { setFieldError, errors, values, setFieldValue } = formik;
  const { isThemeLayoutEnabled } = useThemeLayout();
  const dispatch = useAppDispatch();
  const {
    venue: { id: venueId },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);

  return (
    <Grid container flexDirection={'column'}>
      <Box mt="20px">
        <Typography className={classes.uploadLogoTitle}>{WebAppPageStrings.WebAppLogo}</Typography>
        <LogoUploader
          logo={logo}
          onLogoChange={(newLogo) => {
            if (!newLogo) return;
            onLogoChange(newLogo);
          }}
          title={''}
          subtitle={WebAppPageStrings.WebAppLogoSubtitle}
          imagePreviewWidth="190px"
          imagePreviewHeight="162px"
          alignment="center"
          imagePadding={5}
        />
      </Box>
      <Box margin="35px 0 10px 10px">
        <Typography className={classes.SectionTitle}>
          {WebAppPageStrings.ColorPickerTitle}
        </Typography>
      </Box>
      <Box maxWidth="460px" mb="16px">
        <CustomColorPickerWithInput
          setFieldError={setFieldError}
          errorText={errors.brandColor as string}
          displayCustomInput
          color={values.brandColor}
          setColorError={setColorError}
          onColorChange={(color) => {
            setColor(color);
            setFieldValue('brandColor', color);
          }}
          title={WebAppPageStrings.ColorPickerSubTitle}
        />
      </Box>
      <Divider />
      {isThemeLayoutEnabled && (
        <Box margin="17px 0 20px 0" maxWidth="404px">
          <Typography className={classes.themeText}>{WebAppPageStrings.Theme}</Typography>
          <ThemeSelect
            onChange={(val) => {
              setFieldValue('theme', val);
            }}
            value={values.theme}
          />
        </Box>
      )}
      <Divider />
      <Box margin="25px 0 0 10px">
        <Typography className={classes.SectionTitle}>{WebAppPageStrings.UploadMedia}</Typography>
        <BackgroundImageUploader
          backgroundImage={backgroundImage}
          onImageChange={(newImage) => {
            if (!newImage) return;
            onBackgroundImageChange(newImage);
            setConfigCard((prev) => ({ ...prev, isUploadPhoto: true }));
          }}
          imagePreviewWidth="140px"
          imagePreviewHeight="240px"
        />
      </Box>
      {window.location.host !== 'dashboard.socialvenu.com' && (
        <Box mt="40px" display="flex">
          <Typography fontWeight="600" mr="10px" ml="10px" color="#1A1538">
            Enable theme
          </Typography>
          <Toggle
            value={venueProperties?.['webapp.config'].isThemeLayoutEnabled}
            onChange={(status) => {
              dispatch(
                patchVenueProperties({
                  id: venueId,
                  update: {
                    name: 'webapp.config',
                    value: {
                      ...(venueProperties?.['webapp.config'] || {}),
                      isThemeLayoutEnabled: status,
                    },
                  },
                }),
              );
            }}
          />
        </Box>
      )}
    </Grid>
  );
};
