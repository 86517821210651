export const InvitationArrow = () => {
  return (
    <svg
      width="130"
      height="20"
      viewBox="0 0 130 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120 15L120 2"
        stroke="#5242EA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112 10L120 18L128 10"
        stroke="#5242EA"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
