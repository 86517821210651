import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" {...props}>
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M13.083 25.167c6.674 0 12.084-5.41 12.084-12.084C25.167 6.41 19.757 1 13.083 1 6.41 1 1 6.41 1 13.083c0 6.674 5.41 12.084 12.083 12.084Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.4}
      d="M17.313 9.458a.604.604 0 1 1-1.209 0 .604.604 0 0 1 1.209 0ZM10.063 9.458a.604.604 0 1 1-1.209 0 .604.604 0 0 1 1.209 0ZM13.083 19.73c3.022 0 5.438-2.216 5.438-4.23H7.646c0 2.014 2.416 4.23 5.437 4.23Z"
    />
    <path
      stroke={props.color || '#8B89A0'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16.708 9.458h.012m-7.262 0h.012m15.697 3.625c0 6.674-5.41 12.084-12.084 12.084C6.41 25.167 1 19.757 1 13.083 1 6.41 6.41 1 13.083 1c6.674 0 12.084 5.41 12.084 12.083Zm-7.855-3.625a.604.604 0 1 1-1.208 0 .604.604 0 0 1 1.209 0Zm-7.25 0a.604.604 0 1 1-1.208 0 .604.604 0 0 1 1.209 0Zm3.021 10.271c3.022 0 5.438-2.215 5.438-4.229H7.646c0 2.014 2.416 4.23 5.437 4.23Z"
    />
  </svg>
);
const VideoStyleListFunnyIcon = memo(SvgComponent);
export default VideoStyleListFunnyIcon;
