import { Button, Grid, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useState } from 'react';
import { SearchTargetsType } from '../../../../api/models/searchFilter';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { postSearchFilters } from '../../../../store/slices/searchFiltersSlice';
import { FSModal } from '../../modal/Modal';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: '100px',
    width: '100px',
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    textAlign: 'center',
    width: '100%',
  },
  text: {
    color: theme.palette.common.gray,
    marginTop: '30px',
    marginBottom: '14px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.02em',
  },
  endButton: {
    marginTop: '34px',
    borderRadius: '50px',
  },
  cancelButton: {
    marginTop: '10px',
    borderRadius: '50px',
  },
  input: {
    '& label': {
      top: '-5px',
    },
  },
}));

interface ConfirmFilterCreationModalProps {
  open: boolean;
  onClose: () => void;
  target: SearchTargetsType;
}

export const ConfirmFilterCreationModal: FC<ConfirmFilterCreationModalProps> = ({
  open,
  onClose,
  target,
}) => {
  const { newFilterAttributes } = useTypedSelector((state) => state.SearchFilterSlice);
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const classes = useStyles();
  const [name, setName] = useState('');
  const dispatch = useAppDispatch();
  const handleChange = (e: { target: { value: string } }) => {
    setName(e.target.value);
  };

  const handleConfirm = () => {
    dispatch(
      postSearchFilters({
        venueId,
        name,
        target,
        filter: {
          attributes: newFilterAttributes,
        },
        searchText: '',
      }),
    );
    onClose();
  };

  return (
    <FSModal onClose={onClose} modalIsOpen={open} width="500px">
      <Grid container direction="column" alignItems="start" justifyContent="center">
        <Typography className={classes.title}>{'Save Filter'}</Typography>
        <Typography className={classes.text}>{'FIlter’s name'}</Typography>
        <TextField
          id="filter name"
          variant="outlined"
          label="Name Your Filter"
          value={name}
          onChange={handleChange}
          fullWidth
          className={classes.input}
        />
        <Button
          className={classes.endButton}
          size="large"
          onClick={handleConfirm}
          variant="contained"
          color="primary"
          disabled={!name}
        >
          {'Save Filter'}
        </Button>
      </Grid>
    </FSModal>
  );
};
