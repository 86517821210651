import React from 'react';
import { Typography } from '@mui/material';
import { SmsEditor } from '../../integrations/SmsInvite/Sections/AccordionSection/SmsEditor';
import { ErrorsStrings, SmsInviteStrings } from '../../../common/localization/en';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useModal } from '../../../hooks/useModal';
import { SendTestMessageModal } from '../../../common/components/SMS/SendTestMessageModal';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
} from '../../../common/components/SMS/SMS.helper';
import { useSendTestSms } from '../../../services/hooks/useSendTestSms';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { NewCampaignEvents } from '../../../common/constants/events/newCampaignEvents';
import { setSmsRewardMessage } from '../../../store/slices/createCampaignSlice';
import HowToRewardCreators from '../HowToRewardCreators/HowToRewardCreators';

export const AcknowledgementSMSContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const me = useTypedSelector((store) => store.me);
  const message = useTypedSelector((store) => store.createCampaign.smsRewardMessage);
  const isNullReward = useTypedSelector((store) => store.createCampaign.isNullReward);

  const { rewardName } = useTypedSelector((state) => state.createCampaign.howToRewardCreators);

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: rewardName,
  });

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const constructedTestPostMessage = constructMessageFromTemplate(message, templateVariables, true);
  const sendTestSMS = useSendTestSms();

  return (
    <>
      {!isNullReward && <HowToRewardCreators />}

      <Typography sx={{ my: '10px', color: '#999999' }}>
        {isNullReward ? (
          SmsInviteStrings.AwarenessCampaignAcknowledgmentSubtitle
        ) : (
          <>
            <b>This message will be sent when creators are manually rewarded via the dashboard.</b>
            <br />
            <br />
            You can choose to send these messages <i>automatically</i> instead by using the
            ‘Actions’ menu on the Campaign Summary after creating this campaign.
          </>
        )}
      </Typography>

      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageModalCloseClick);
          closeTestMessageModal();
        }}
        testMessage={constructedTestPostMessage}
        onTestMessageSendClick={(data) => {
          trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageModalSendClick);
          sendTestSMS(data);
        }}
        phoneNumber={me.phoneNumber}
        name={me.name}
      />
      <SmsEditor
        mt="16px"
        title="Edit Reward SMS"
        messageLabel={SmsInviteStrings.MessageTemplateLabel}
        message={message}
        onMessageChange={async (message: string) => {
          dispatch(setSmsRewardMessage(message));
        }}
        templateVariables={templateVariables}
        disabled={false}
        onSendTestClick={() => {
          openTestMessageModal();
          trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageSendSampleTestClick);
        }}
        errorText={!message.trim() ? ErrorsStrings.FieldIsRequired : ''}
      />
    </>
  );
};
