import { FC, useRef, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import clsx from 'clsx';

import { VideoApiModel } from '../../../../../../api/models/videos';
// import { FavoriteButton } from '../../../../favoriteButton/FavoriteButton';
import { getLocalDateString } from '../../../../../../services/utilities';
import { useStyles } from './SalesHeroVideoCard.helper';
import { VideoStatistics } from '../../../../videoStatistics/videoStatistics';
import { PlayArrow } from '@mui/icons-material';
import { VideoPlaybackModal } from '../../VideoPlaybackModal';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../../../../constants/events/creatorsTableEvents';
import { RewardCampaignsEvents } from '../../../../../constants/events/rewardCampaignsEvents';
// import { VideCardStrings } from '../../../../../localization/en';

interface SalesHeroVideoCardProps {
  video: VideoApiModel;
  handleOpenConfirmationModal?: (id: string) => void;
  entity?: 'heroes' | 'campaigns';
}

export const SalesHeroVideoCard: FC<SalesHeroVideoCardProps> = ({
  video,
  handleOpenConfirmationModal,
  entity,
}) => {
  const classes = useStyles();
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { trackEvent } = useTrackEvent();

  const handleOpenPlayVideoModal = () => {
    setIsOpen(!isOpen);
    setIsPlaying(true);
    trackEvent(
      entity === 'campaigns'
        ? RewardCampaignsEvents.RewardCampaignsDetailsModalStoryClick
        : CreatorsTableEventNames.CreatorDetailsModalStoryClick,
      {
        selectedUserId: video.userId,
        videoId: video.id,
      },
    );
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsPlaying(false);
    trackEvent(
      entity === 'campaigns'
        ? RewardCampaignsEvents.RewardCampaignsDetailsModalStoryCloseClick
        : CreatorsTableEventNames.CreatorDetailsModalStoryCloseClick,
      {
        selectedUserId: video.userId,
        videoId: video.id,
      },
    );
  };
  return (
    <Card className={classes.root}>
      <VideoPlaybackModal
        isOpen={isOpen}
        isTopGolf={false}
        videoUrl={video.url}
        onClose={handleClose}
        videoId={video.id}
        entity={entity}
        thumbnailUrl={video.thumbnailUrl}
      />

      {/*There is a lot of functionality that will be lost if we delete this. */}
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          component="video"
          ref={refVideo}
          className={classes.video}
          src={video.url}
          onClick={handleOpenPlayVideoModal}
        />
        {!isPlaying && (
          <Box fontSize={40} className={classes.icon}>
            <PlayArrow fontSize="inherit" color="inherit" />
          </Box>
        )}
        {/*commented not deleted for the future review*/}
        {/*<Box className={classes.header}>*/}
        {/*  <div*/}
        {/*    className={clsx({*/}
        {/*      [classes.public]: true,*/}
        {/*      [classes.private]: !story.isPublic,*/}
        {/*    })}*/}
        {/*  >*/}
        {/*    {story.isPublic ? VideCardStrings.Public : VideCardStrings.Private}*/}
        {/*  </div>*/}
        {/*  {handleOpenConfirmationModal && (*/}
        {/*    <Box className={classes.favouriteButton}>*/}
        {/*      <FavoriteButton*/}
        {/*        checked={story.featured}*/}
        {/*        id={story.id}*/}
        {/*        handleOpenConfirmationModal={handleOpenConfirmationModal}*/}
        {/*      />*/}
        {/*    </Box>*/}
        {/*  )}*/}
        {/*</Box>*/}
      </CardActionArea>
      <CardContent className={classes.contentCard}>
        <VideoStatistics video={video} />
        <Box className={classes.box} lineHeight="1.4">
          <Typography title={video.id} className={classes.boxText} fontSize={14} color="#1A1538">
            VID: {video.shortcode}
          </Typography>
          <Typography className={clsx([classes.smallText, classes.smallTextPrimary])}>
            {getLocalDateString(video.createdAt)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
