import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
    <mask
      id="mask0_1816_71529"
      width={60}
      height={60}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <circle cx={30} cy={30} r={30} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask0_1816_71529)">
      <circle cx={30} cy={30} r={30} fill="#D9D4FF" />
      <circle cx={30} cy={30} r={29.3} stroke="#5242EA" strokeWidth={1.4} />
      <circle
        cx={29.765}
        cy={29.929}
        r={20.354}
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeWidth={1.4}
        transform="rotate(-.122 29.765 29.93)"
      />
      <path
        fill="#5242EA"
        stroke="#A69DF7"
        strokeWidth={1.4}
        d="M28.229 23.1c-1.8-1.154-4.163.141-4.159 2.279l.024 11.08c.005 2.138 2.374 3.423 4.168 2.26l8.582-5.558c1.648-1.067 1.642-3.479-.01-4.539l-8.605-5.521Z"
      />
    </g>
  </svg>
);
const VideoTypeMarketingIconActive = memo(SvgComponent);
export default VideoTypeMarketingIconActive;
