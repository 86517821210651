import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';

import { format } from 'date-fns';
import _ from 'lodash';
import { FC, ReactNode } from 'react';
import {
  Attribute,
  SearchFilterAttributes,
  SearchOperations,
  SearchOperationsType,
} from '../../../api/models/searchFilter';
import { getUTCDateString } from '../../../services/utilities';
import { CrownIcon } from '../../assets/CrownIcon';
import { WebsiteRoutes } from '../../constants/routes';
import {
  DataTreatmentsStrings,
  SearchFilterStrings,
  UserFiltersStrings,
  UserStatusStrings,
} from '../../localization/en';
import { DateRangeFilter } from '../datePicker/DateRangeFilter';

import { OneParamStatsItem } from './OneParamStatsItem';
import { TwoParamsStatsItem } from './TwoParamsStatsItem';

export const headCellsNames = [
  '',
  SearchFilterStrings.Filterby,
  SearchFilterStrings.DataTreatments,
];

export const filtersResultRoutes = {
  USERS: WebsiteRoutes.UsersFilter,
  VIDEOS: WebsiteRoutes.VideosFilter,
  VENUES: WebsiteRoutes.VideosFilter,
  INCENTIVE_CAMPAIGNS: WebsiteRoutes.IncentivesFilter,
};

export interface IFilterOption {
  option: string | string[];
  from: number;
  to: number;
  GREATER_THAN?: number | string;
  LESS_THAN?: number | string;
  operation?: SearchOperationsType;
  value?: string;
}

export const tableNumberRows = createTableNumberRows();

function createTableNumberRows() {
  const userFilers = Object.values(UserFiltersStrings);
  const tableRows = [];
  for (let index = 0; index < userFilers.length; index++) {
    tableRows.push(index);
  }
  return tableRows;
}

export const tableUserFilterRows = (isHealthCare?: boolean) =>
  createUserTableFilterrRows(isHealthCare);

function createUserTableFilterrRows(isHealthCare?: boolean) {
  const userFilers = Object.values(UserFiltersStrings);
  const tableRows = [];
  for (let index = 0; index < userFilers.length; index++) {
    const isPremiumFilterOption =
      userFilers[index] === UserFiltersStrings.conversions ||
      userFilers[index] === UserFiltersStrings.totalPoints;
    tableRows.push(
      <>
        <Typography
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            opacity: isPremiumFilterOption ? '50%' : 'initial',
          }}
        >
          {isPremiumFilterOption ? (
            <Box paddingRight="12px">
              <CrownIcon />
            </Box>
          ) : null}{' '}
          {isHealthCare && userFilers[index] === 'Creator Status'
            ? 'Patient Status'
            : userFilers[index]}
        </Typography>
      </>,
    );
  }
  return tableRows;
}

interface IcreateUserFilterInputRows {
  filter: { [key: string]: null | IFilterOption | { option: string } | string };
  handleDateUpdate: (filterName: string, value: any) => void;
  handleOptionUpdate: (filterName: string, value: any) => void;
  handleRangeFiltersUpdate: (filterName: string, option: string, from: number, to: number) => void;
}

export function createUserFilterInputsRows({
  filter,
  handleRangeFiltersUpdate,
  handleDateUpdate,
  handleOptionUpdate,
}: IcreateUserFilterInputRows) {
  const userFilers = Object.values(UserFiltersStrings);

  return userFilers.map((value) => {
    const valueToSetState =
      Object.keys(UserFiltersStrings)[Object.values(UserFiltersStrings).indexOf(value)];

    const isPremiumFilterOption =
      value === UserFiltersStrings.conversions || value === UserFiltersStrings.totalPoints;
    if (value === UserFiltersStrings.lastVideoCreatedAt) {
      return (
        <Box style={{ cursor: 'pointer' }}>
          <DateRangeFilter
            updateRange={(dataRange) => {
              handleDateUpdate(valueToSetState, dataRange);
            }}
            initialValue={filter[valueToSetState]}
          />
        </Box>
      );
    }
    if (value === UserFiltersStrings.status) {
      return (
        <SelectUserStatus
          handleUserStatusChange={handleOptionUpdate}
          userStatus={(filter[valueToSetState] as IFilterOption).option as string}
          valueToSetState={valueToSetState}
        />
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <SelectDataTreatmentsStatus
          handleChange={handleOptionUpdate}
          dataStatus={(filter[valueToSetState] as IFilterOption).option as string}
          valueToSetState={valueToSetState}
          isPremiumFilterOption={isPremiumFilterOption}
        />
        {(filter[valueToSetState] as IFilterOption).option === DataTreatmentsStrings.IN_RANGE ? (
          <TwoParamsStatsItem
            item={{
              id: '',
              title: '',
              icon: <></>,
            }}
            handleChangeValues={function (
              id: string,
              newValue: { min: number; max: number },
            ): void {
              handleRangeFiltersUpdate(
                valueToSetState,
                (filter[valueToSetState] as IFilterOption).option as string,
                newValue.min,
                newValue.max,
              );
            }}
            value={{
              min: (filter[valueToSetState] as IFilterOption).from,
              max: (filter[valueToSetState] as IFilterOption).to,
            }}
            isPremiumFilterOption={isPremiumFilterOption}
          />
        ) : (
          <OneParamStatsItem
            item={{
              id: valueToSetState,
              title: '',
              icon: <></>,
            }}
            handleChangeValues={function (
              id: string,
              newValue: { min: number; max: number },
            ): void {
              handleRangeFiltersUpdate(
                valueToSetState,
                (filter[valueToSetState] as IFilterOption).option as string,
                !(filter[valueToSetState] as IFilterOption).option ? 0 : newValue.min,
                newValue.max,
              );
            }}
            value={{
              min: !(filter[valueToSetState] as IFilterOption).option
                ? 0
                : (filter[valueToSetState] as IFilterOption).from,
              max: (filter[valueToSetState] as IFilterOption).to,
            }}
            option={(filter[valueToSetState] as IFilterOption).option as string}
            isPremiumFilterOption={isPremiumFilterOption}
          />
        )}
      </Box>
    );
  });
}

interface ISelectDataTreatmentsStatusProps {
  handleChange: (filterName: string, value: any) => void;
  dataStatus: string;
  valueToSetState: string;
  isPremiumFilterOption: boolean;
}

const SelectDataTreatmentsStatus: FC<ISelectDataTreatmentsStatusProps> = ({
  handleChange,
  dataStatus,
  valueToSetState,
  isPremiumFilterOption,
}) => {
  return (
    <Box sx={{ minWidth: 165 }}>
      <FormControl fullWidth>
        <Select
          style={{ border: !dataStatus ? '' : '1px solid blue' }}
          disabled={isPremiumFilterOption}
          value={dataStatus}
          onChange={(event: SelectChangeEvent<string>) => {
            const value = event.target.value;
            handleChange(valueToSetState, value);
          }}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={() => {
            if (dataStatus === '') {
              return '-';
            }
            return dataStatus;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '12px',
              },
            },
          }}
        >
          <MenuItem value="">-</MenuItem>
          {Object.values(DataTreatmentsStrings).map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

interface ISelectUserStatusProps {
  userStatus: string;
  handleUserStatusChange: (filterName: string, value: any) => void;
  valueToSetState: string;
}

const SelectUserStatus: FC<ISelectUserStatusProps> = ({
  userStatus,
  handleUserStatusChange,
  valueToSetState,
}) => {
  return (
    <Box sx={{ minWidth: 165 }}>
      <FormControl fullWidth>
        <Select
          value={userStatus}
          style={{ border: !userStatus ? '' : '1px solid blue' }}
          onChange={(event: SelectChangeEvent<string>) => {
            const value = event.target.value;
            handleUserStatusChange(valueToSetState, value);
          }}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={() => {
            if (userStatus === '') {
              return SearchFilterStrings.SelectStatus;
            }
            return userStatus;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                borderRadius: '12px',
              },
            },
          }}
        >
          <MenuItem value="">{'-'}</MenuItem>
          <MenuItem value={UserStatusStrings.active}>{UserStatusStrings.active}</MenuItem>
          <MenuItem value={UserStatusStrings.deactivated}>{UserStatusStrings.deactivated}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const initialUserFilterState: {
  [key: string]: null | IFilterOption;
} = {
  lastVideoCreatedAt: null,
  videos: {
    option: '',
    from: 0,
    to: 10,
  },
  shares: {
    option: '',
    from: 0,
    to: 10,
  },
  videoViews: {
    option: '',
    from: 0,
    to: 10,
  },
  videoCtaClicks: {
    option: '',
    from: 0,
    to: 10,
  },
  conversions: {
    option: '',
    from: 0,
    to: 10,
  },
  totalPoints: {
    option: '',
    from: 0,
    to: 10,
  },
  status: {
    option: '',
    from: 0,
    to: 0,
  },
};

export const stateToAtrributes = (
  filter: { [x: string]: IFilterOption | null },
  venueCreatedAt: string,
) => {
  const filterOption = Object.keys(initialUserFilterState);
  const attributes: SearchFilterAttributes[] = [];
  filterOption.forEach((filterOption) => {
    if (_.isEqual(initialUserFilterState[filterOption], filter[filterOption])) return;
    switch (filterOption) {
      case 'lastVideoCreatedAt':
        const dateFrom = new Date(
          filter[filterOption] && filter[filterOption]?.GREATER_THAN
            ? getUTCDateString(filter[filterOption]?.GREATER_THAN as string)
            : getUTCDateString(venueCreatedAt),
        );
        const dateTo = new Date(
          filter[filterOption] && filter[filterOption]?.LESS_THAN
            ? getUTCDateString(filter[filterOption]?.LESS_THAN as string)
            : getUTCDateString(new Date().toString()),
        );
        const timezoneOffset = new Date().getTimezoneOffset() / 60;

        //for dateFrom add timezoneOffset; for dateTo add timezoneOffset + 24hours to point at the end of the day
        dateFrom.setHours(dateFrom.getHours() + timezoneOffset);
        dateTo.setHours(dateTo.getHours() + timezoneOffset + 24);

        attributes.push({
          name: filterOption,
          operation: SearchOperations.GREATER_THAN,
          value: dateFrom.toISOString(),
        });
        attributes.push({
          name: filterOption,
          operation: SearchOperations.LESS_THAN,
          value: dateTo.toISOString(),
        });
        break;
      case 'status':
        const status =
          filter[filterOption]?.option === UserStatusStrings.active ? 'ACTIVE' : 'INACTIVE';
        attributes.push({
          name: filterOption,
          operation: SearchOperations.EQUALS,
          value: status,
        });
        break;
      case 'sharePlatform':
        (filter[filterOption]?.option as string[]).forEach((option) => {
          attributes.push({
            name: filterOption,
            operation: SearchOperations.IN,
            value: option.toLocaleUpperCase(),
          });
        });
        break;
      default:
        switch (filter[filterOption]?.option) {
          case DataTreatmentsStrings.IN_RANGE:
            attributes.push({
              name: filterOption,
              operation: SearchOperations.IN_RANGE,
              value: { min: filter[filterOption]?.from, max: filter[filterOption]?.to },
            });
            break;
          default:
            const operation = Object.keys(DataTreatmentsStrings)[
              Object.values(DataTreatmentsStrings).indexOf(
                filter[filterOption]?.option as DataTreatmentsStrings,
              )
            ] as SearchOperationsType;

            if (operation === undefined) return;
            attributes.push({
              name: filterOption,
              operation: (operation === undefined ? '' : operation) as SearchOperationsType,
              value: operation ? filter[filterOption]?.from : 0,
            });
            break;
        }
        break;
    }
  });
  return attributes;
};

export const attributesToState = (
  attributes: {
    name: string;
    operation: SearchOperationsType;
    value: any;
  }[],
) => {
  const state: { [key: string]: null | IFilterOption } = {};
  attributes.forEach((attribute) => {
    let name = attribute.name;
    let option;
    switch (attribute.name) {
      case Object.keys(UserFiltersStrings)[
        Object.values(UserFiltersStrings).indexOf(UserFiltersStrings.status)
      ] as SearchOperationsType:
        option = attribute.value.charAt(0).toUpperCase() + attribute.value.slice(1).toLowerCase();
        state[name] = { option: option, from: 0, to: 10 };
        break;
      case Object.keys(UserFiltersStrings)[
        Object.values(UserFiltersStrings).indexOf(UserFiltersStrings.lastVideoCreatedAt)
      ] as SearchOperationsType:
        let from;
        let to;
        if (attribute.operation === SearchOperations.GREATER_THAN) from = attribute.value;
        if (attribute.operation === SearchOperations.LESS_THAN) to = attribute.value;
        state[name] = {
          option: '',
          from: state[name]?.from ? state[name]?.from : from,
          to: state[name]?.to ? state[name]?.to : to,
        };
        break;

      default:
        if (
          attribute.operation === 'EQUALS' ||
          attribute.operation === 'GREATER_THAN' ||
          attribute.operation === 'LESS_THAN' ||
          attribute.operation === 'IN_RANGE'
        ) {
          option = DataTreatmentsStrings[attribute.operation];
          state[name] = {
            option: option as string,
            from:
              attribute.value.min !== null || attribute.value.min !== undefined
                ? attribute.value.min
                : attribute.value,
            to: attribute.value.max,
          };
        }
    }
  });
  return state;
};

export const createUserFilterPreview = ({
  attributes,
}: {
  attributes: Attribute[];
}): ReactNode[] => {
  const attributesPreview = attributesToState(attributes);
  const updatedInintailState = { ...initialUserFilterState, ...attributesPreview };
  const filters = Object.values(UserFiltersStrings);
  return filters.map((value) => {
    const valueToSetState =
      Object.keys(UserFiltersStrings)[Object.values(UserFiltersStrings).indexOf(value)];

    if (value === UserFiltersStrings.lastVideoCreatedAt) {
      const displayedValue = updatedInintailState[valueToSetState]
        ? `${format(
            new Date(updatedInintailState[valueToSetState]?.from as number),
            'PP',
          )}-${format(new Date(updatedInintailState[valueToSetState]?.to as number), 'PP')}`
        : '';
      return (
        <Box>
          <TextField
            sx={{ width: '368px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
            disabled={true}
            variant="outlined"
            value={displayedValue}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      );
    }
    if (value === UserFiltersStrings.status) {
      const displayedStatus =
        updatedInintailState[valueToSetState]?.option === 'Active'
          ? updatedInintailState[valueToSetState]?.option
          : UserStatusStrings.deactivated;
      return (
        <TextField
          disabled={true}
          sx={{
            width: '160px',
            backgroundColor: '#F5F6F8',
            borderRadius: '12px',
          }}
          variant="outlined"
          value={displayedStatus}
          InputLabelProps={{
            shrink: true,
          }}
        />
      );
    }

    return (
      <Box display="flex" alignItems="center">
        <TextField
          disabled={true}
          sx={{
            width: '160px',
            backgroundColor: '#F5F6F8',
            marginRight: '15px',
            borderRadius: '12px',
          }}
          variant="outlined"
          value={updatedInintailState[valueToSetState]?.option}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {(updatedInintailState[valueToSetState] as IFilterOption).option ===
        DataTreatmentsStrings.IN_RANGE ? (
          <Box display="flex" alignItems="center">
            <TextField
              disabled={true}
              sx={{
                width: '55px',
                backgroundColor: '#F5F6F8',
                marginRight: '10px',
                borderRadius: '12px',
              }}
              variant="outlined"
              value={
                updatedInintailState[valueToSetState]?.from
                  ? updatedInintailState[valueToSetState]?.from
                  : updatedInintailState[valueToSetState]?.option
                  ? '0'
                  : '-'
              }
              InputLabelProps={{
                shrink: true,
              }}
            />{' '}
            <Typography sx={{ marginRight: '10px' }}>to</Typography>
            <TextField
              disabled={true}
              sx={{ width: '100px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
              variant="outlined"
              value={
                updatedInintailState[valueToSetState]?.to
                  ? updatedInintailState[valueToSetState]?.to
                  : updatedInintailState[valueToSetState]?.option
                  ? '0'
                  : '-'
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        ) : (
          <TextField
            disabled={true}
            sx={{ width: '55px', backgroundColor: '#F5F6F8', borderRadius: '12px' }}
            variant="outlined"
            value={
              updatedInintailState[valueToSetState]?.from
                ? updatedInintailState[valueToSetState]?.from
                : updatedInintailState[valueToSetState]?.option
                ? '0'
                : '-'
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </Box>
    );
  });
};
