import { FC, useState } from 'react';
import { Box, Drawer } from '@mui/material';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { UserLogoSection } from './userLogoSection/UserLogoSection';
// import { CollapseSection } from './collapseSection/CollapseSection';
import { Menu } from './menu/Menu';
import { Container, FlexWrapper } from './SideBar.style';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import {
  LayoutMode,
  segmentActions,
  TRUBLU_SIDEBAR_LOGO,
  UserRoles,
} from '../../constants/constants';
import { triggerSidebar } from '../../../store/slices/uiSlice';
import { useStyles } from './Sidebar.helper';
import { segmentTrackCall } from '../../../utils/segment';

import { WebsiteRoutes } from '../../constants/routes';
import { SocialVenuLogo } from '../../assets/newDesign/Sidebar/SocialVenuLogo';
import { WorkspaceSection } from './menu/workspaceSection/WorkspaceSection';
import { AccountsButton, BackButton } from './menu/menuItem/MenuItem';
import { DistributorModel } from '../../../api/models/distributors';
import { setActiveDistributor } from '../../../store/slices/distributorsSlice';
import { setActiveAffiliate } from '../../../store/slices/affiliatesSlice';
import { AffiliateModel } from '../../../api/models/affiliates';
import { resetPage } from '../../../store/slices/organizationVenueSlice';
import { useIsTBAccount } from '../../../hooks/common';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AllAccountsEventNames } from '../../constants/events/allAccountsEvents';
import { useClientType } from '../../../services/hooks/useClientType';

export const SideBar: FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {
    selectedVenueId: selectedVenu,
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const history = useHistory();
  const sidebarMode = useWindowResize();
  const { sidebarOpen } = useTypedSelector((state) => state.ui);
  const { name, email, role, accounts } = useTypedSelector((state) => state.me);
  const { distributors, activeDistributor } = useTypedSelector((state) => state.distributors);
  const { affiliates, activeAffiliate } = useTypedSelector((state) => state.affiliates);
  const [open, setOpen] = useState(true);
  const isSuperAdmin = ([UserRoles.admin_role] as string[]).includes(role);
  const { pathname } = useLocation();
  const singleAccount = accounts === 1;
  const isMultiAccount = accounts > 1;
  const isAdmin = isSuperAdmin || isMultiAccount;
  const isAccountsPage = history.location.pathname === WebsiteRoutes.ProfileAccounts;
  const { trackEvent } = useTrackEvent();
  const { isTrubluClient } = useClientType();

  const isSVAdminAccountButtonActive = isAccountsPage && !activeAffiliate && !activeDistributor;

  const isDistributorAccountButtonActive = (id: string) => {
    return !!(isAccountsPage && activeDistributor && activeDistributor.id === id);
  };

  const isAffiliateAccountButtonActive = (id: string) => {
    return !!(isAccountsPage && activeAffiliate && activeAffiliate.id === id);
  };

  const handleDistrubtorsAccountsClick = (distributor: DistributorModel | undefined) => {
    trackEvent(AllAccountsEventNames.distributorAccountsButtonClicked, {
      distributorId: distributor?.id,
    });
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(distributor));
    history.push(WebsiteRoutes.ProfileAccounts);
  };

  const handleAffiliatesAccountsClick = (affiliate: AffiliateModel | undefined) => {
    trackEvent(AllAccountsEventNames.affiliateAccountsButtonClicked, {
      affiliateId: affiliate?.id,
    });
    dispatch(setActiveAffiliate(affiliate));
    dispatch(setActiveDistributor(undefined));
    history.push(WebsiteRoutes.ProfileAccounts);
  };

  const handleSVadminAccountsClick = () => {
    // history.push(SocialvenueManagerRoutes.Performance);
    trackEvent(AllAccountsEventNames.allAccountsButtonClicked);
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(undefined));
    history.push(WebsiteRoutes.ProfileAccounts);
    // setIsActiveItem(true);
  };
  // const isAdminSection = pathname.includes('/admin');
  const showVenuMenu = () => {
    if (selectedVenu) {
      if (
        singleAccount
          ? false
          : (isAdmin && pathname === WebsiteRoutes.ProfileAccounts) ||
            pathname === WebsiteRoutes.ProfileProfile
      ) {
        return false;
      }
      const isWebsiteRoute = Boolean(
        Object.values(WebsiteRoutes).filter((path) => matchPath(pathname, { path, exact: true }))
          .length,
      );

      return isWebsiteRoute;
    }

    return false;
  };
  // eslint-disable-next-line
  const onCollapseHandler = () => {
    setOpen(!open);
    segmentTrackCall(segmentActions.SideBarCollapse, venueId);
  };

  const closeSideBar = () => {
    dispatch(triggerSidebar());
  };
  const isBackButton = pathname === WebsiteRoutes.ProfileProfile && isAdmin;

  const isTBAccount = useIsTBAccount();

  const renderDesktopSidebar = () => (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        docked: classes.docked,
        paper: clsx(classes.root, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <Container collapsed={!open}>
        {isBackButton && <BackButton />}
        <FlexWrapper>
          {/* <LogoSection logoUrl={logo || defaultUserAvatar} collapsed={!open} /> */}
          <WorkspaceSection />
          <Menu isSuperAdmin={isSuperAdmin} showVenuMenu={showVenuMenu()} collapsed={!open} />
        </FlexWrapper>
        {/* {isSuperAdmin && isAdminSection && <NavAlt isOpen={open} />} */}
        {isAccountsPage &&
          affiliates.map((item: AffiliateModel) => (
            <div key={item.id}>
              <AccountsButton
                handleClick={() => {
                  dispatch(resetPage());
                  handleAffiliatesAccountsClick(item);
                }}
                title={item.name}
                label="Accounts"
                isActive={isAffiliateAccountButtonActive(item.id)}
              />
            </div>
          ))}
        {isAccountsPage &&
          distributors.map((item: DistributorModel, index) => {
            // need to render space between SV and TruBlu buttons
            const firstSvIndex = distributors.findIndex((d) => d.code.startsWith('SV'));
            const isFirstSvItem = firstSvIndex === index;

            return (
              <Box key={item.id} mt={isFirstSvItem ? '32px' : undefined}>
                <AccountsButton
                  handleClick={() => {
                    dispatch(resetPage());
                    handleDistrubtorsAccountsClick(item);
                  }}
                  title={item.name}
                  label="Accounts"
                  isActive={isDistributorAccountButtonActive(item.id)}
                />
              </Box>
            );
          })}

        {isAdmin && (
          <AccountsButton
            handleClick={() => {
              dispatch(resetPage());
              handleSVadminAccountsClick();
            }}
            title="All Accounts"
            label=""
            isActive={isSVAdminAccountButtonActive}
          />
        )}

        {/* <UserLogoSection
          isSuperAdmin={isSuperAdmin}
          collapsed={!open}
          imageUrl=""
          firstName={name}
          email={email}
        /> */}
        {selectedVenu && (
          <div className={classes.logoWrapper}>
            {isAccountsPage ? (
              isTBAccount ? (
                <Box mx="40px">
                  <img src={TRUBLU_SIDEBAR_LOGO} alt="distributor-logo" />
                </Box>
              ) : (
                <SocialVenuLogo />
              )
            ) : isTrubluClient ? (
              <Box mx="40px">
                <img src={TRUBLU_SIDEBAR_LOGO} alt="distributor-logo" />
              </Box>
            ) : (
              <SocialVenuLogo />
            )}
          </div>
        )}
        {/* <CollapseSection collapsed={!open} onCollapse={onCollapseHandler} /> */}
      </Container>
    </Drawer>
  );

  const renderTabletSidebar = () => {
    return (
      <Drawer open={sidebarOpen} onClose={closeSideBar} classes={{ paper: classes.root }}>
        <FlexWrapper>
          <Menu isSuperAdmin={isSuperAdmin} showVenuMenu={showVenuMenu()} collapsed={!open} />
        </FlexWrapper>
        <UserLogoSection
          isSuperAdmin={isSuperAdmin}
          collapsed={!open}
          imageUrl=""
          firstName={name}
          email={email}
        />
        <WorkspaceSection />

        {/* <CollapseSection collapsed={!open} onCollapse={onCollapseHandler} /> */}
      </Drawer>
    );
  };

  const renderMobileSidebar = () => {
    return (
      <Drawer open={sidebarOpen} onClose={closeSideBar} classes={{ paper: classes.root }}>
        <FlexWrapper>
          <Menu isSuperAdmin={isSuperAdmin} showVenuMenu={showVenuMenu()} collapsed={!open} />
        </FlexWrapper>
        <UserLogoSection
          isSuperAdmin={isSuperAdmin}
          collapsed={!open}
          imageUrl=""
          firstName={name}
          email={email}
        />
        <WorkspaceSection />

        {/* <CollapseSection collapsed={!open} onCollapse={onCollapseHandler} /> */}
      </Drawer>
    );
  };

  if (pathname.includes(WebsiteRoutes.WorkspaceMembersInvite)) return null;

  if (!sidebarMode) return null;

  switch (sidebarMode) {
    case LayoutMode.Desktop: {
      return renderDesktopSidebar();
    }
    case LayoutMode.Tablet: {
      return renderTabletSidebar();
    }
    case LayoutMode.Mobile: {
      return renderMobileSidebar();
    }
    default: {
      return renderDesktopSidebar();
    }
  }
};
