import React, { MouseEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SalesDetailsModal } from '../../common/components/NewDetailsModal/SalesDetailsModal';
import { TopbarSelectedFilter } from '../../common/components/TopbarSelectedFilter';
import { defaultPagination } from '../../common/constants/constants';
import { CreatorsTableEventNames } from '../../common/constants/events/creatorsTableEvents';
import { WebsiteRoutes } from '../../common/constants/routes';
import { HeroesPageStrings } from '../../common/localization/en';
import { useSegmentPageCall } from '../../hooks/useSegment';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { useClientType } from '../../services/hooks/useClientType';
import { useAppDispatch, useTypedSelector } from '../../store';
import { getActiveIncentiveCampaign } from '../../store/slices/campaignsSlice';
import { setUserDataSorting } from '../../store/slices/heroesSlice';
import { setActiveFilter } from '../../store/slices/searchFiltersSlice';
import { HeroesContainer } from './Heroes.style';
import { TableSection } from './tableSection/TableSection';

export const Heroes: React.FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);
  const dispatch = useAppDispatch();

  const [id, setId] = useState('');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isHealthCare } = useClientType();

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    dispatch(setUserDataSorting(defaultPagination.sortByLastVideosCount[0]));

    trackEvent(CreatorsTableEventNames.PageLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (venueId) {
      dispatch(getActiveIncentiveCampaign(venueId));
    }
  }, [dispatch, venueId]);

  useSegmentPageCall();
  const close = () => {
    history.push(WebsiteRoutes.Heroes);
  };

  const handleClearFilter = () => {
    dispatch(setActiveFilter(null));
  };

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setId(id !== event.currentTarget?.id ? event.currentTarget.id : '');
  };

  return (
    <HeroesContainer>
      <TopbarSelectedFilter
        anchorEl={anchorEl}
        handleSetAnchorEl={() => setAnchorEl(null)}
        handleSetId={() => setId('')}
        handleClick={handleClick}
        handleClearFilter={handleClearFilter}
        sectionTitle={isHealthCare ? 'Patients' : HeroesPageStrings.Heroes}
        multiline={false}
      />
      <TableSection showActionBar={activeFilter ? true : false} />

      <SalesDetailsModal
        isOpen={!!params.id}
        userId={params.id}
        handleClose={close}
        entity="heroes"
      />
    </HeroesContainer>
  );
};
