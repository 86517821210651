import { Box, Button, Typography } from '@mui/material';
import { FC } from 'react';

import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { UserApiModel } from '../../../../api/models/users';
import { CreatorsTableEventNames } from '../../../../common/constants/events/creatorsTableEvents';
import { LearnMoreStrings, UsersTable } from '../../../../common/localization/en';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { canApplyReward } from './RewardsOwed.helper';
import { LearnMoreTooltip } from '../../../../common/components/LearnMoreTooltip/LearnMoreTooltip';

interface IRewardsOwedProps {
  activeCampaign: null | CampaignsApiModel;
  user: UserApiModel;
  approveRewardHandler: (userId: string) => void;
  toggleRedeemPointsModal: () => void;
  isRedeemPointsModal: boolean;
}

export const RewardsOwed: FC<IRewardsOwedProps> = ({
  activeCampaign,
  user,
  approveRewardHandler,
  toggleRedeemPointsModal,
  isRedeemPointsModal,
}) => {
  const { trackEvent } = useTrackEvent();

  if (!activeCampaign || !user) return <></>;

  const canGetReward = canApplyReward(user, activeCampaign);
  // const isRewarded = isRewardApplied(user, activeCampaign);
  const isRewarded = !!user.rewardsFulfilled;

  if (isRewarded) {
    return (
      <Typography
        fontSize="12px"
        lineHeight="24px"
        letterSpacing="0.02em"
        fontWeight="600"
        color="#8B89A0"
        textAlign="center"
      >
        {UsersTable.Rewarded}
      </Typography>
    );
  }

  if (!canGetReward) {
    return (
      <Box display="flex" justifyContent="center">
        <LearnMoreTooltip title={LearnMoreStrings.RewardString} />
      </Box>
    );
  }

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        trackEvent(CreatorsTableEventNames.CreatorRewardNowClick, {
          selectedUserId: user.id,
          campaignId: activeCampaign.id,
        });
        approveRewardHandler(user.id);
      }}
      display="flex"
      justifyContent="center"
    >
      <Button
        sx={{
          fontWeight: 600,
          fontSize: '12px',
          lineHeight: '24px',
          height: '24px',
          letterSpacing: '0.02em',
          borderRadius: '30px',
          border: '2px solid #5242EA',
          '&:hover': {
            backgroundColor: '#5242ea14',
            border: '2px solid #5242EA',
          },
        }}
        variant="outlined"
      >
        {UsersTable.RewardNow}
      </Button>
    </Box>
  );
};
