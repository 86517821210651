import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useStyles } from './TabsSelect.styles';
import { Box, Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import { RewardCampaignsEvents } from '../../constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { WebsiteRoutes } from '../../constants/routes';
import { useLocation } from 'react-router-dom';

export interface TabItem {
  tabName: string;
  usersCount?: string;
  tabContent: JSX.Element;
}

interface Props {
  tabsList: TabItem[];
  onTrackEvent: IEventTracker;
  onTabChange?: (i: number) => void;
  campaignVideos?: number;
}

export const TabsSelect: FC<Props> = ({ tabsList, onTrackEvent, onTabChange, campaignVideos }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes(WebsiteRoutes.RewardCampaigns) && campaignVideos === 0) {
      setTabIndex(2);
    }
  }, [campaignVideos, pathname]);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    onTabChange && onTabChange(newValue);
    onTrackEvent(
      newValue === 0
        ? RewardCampaignsEvents.RewardCampaginActiveCustomersTabClick
        : newValue === 1
        ? RewardCampaignsEvents.RewardCampaignPendingTabClick
        : newValue === 2
        ? RewardCampaignsEvents.RewardCampaginInvitedTabClick
        : RewardCampaignsEvents.RewardCampaignUnsentInvitesTabClick,
    );
  };

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.tabsWrapper}>
        <Tabs value={tabIndex} onChange={handleTabChange} className={classes.tabs}>
          {tabsList.map((tab, index) => {
            const isActive = tabIndex === index;
            return (
              <Tab
                key={index}
                style={{
                  backgroundColor: isActive ? '#FFFFFF' : '#F9FAFB',
                  borderTop: isActive ? '1px solid #5242EA' : 'none',
                  boxShadow: isActive ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  textTransform: 'none',
                }}
                label={
                  <>
                    <Typography
                      className={classes.tabName}
                      style={{
                        color: isActive ? '#5242EA' : '#667085',
                      }}
                    >
                      {tab.tabName}
                    </Typography>
                    <Typography
                      className={classes.usersCount}
                      style={{
                        color: isActive ? '#5242EA' : '#667085',
                        backgroundColor: isActive ? '#F9F5FF' : '#F2F4F7',
                      }}
                    >
                      {tab.usersCount}
                    </Typography>
                  </>
                }
                id={`${tab.tabName.split(' ').join('-')}-tab`}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box className={classes.contentWrapper}>{tabsList[tabIndex].tabContent}</Box>
    </Box>
  );
};
