import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { TableDTO } from '../storeModels';
import {
  ActivateVenueManagerRequest,
  CreateVenueManagerRequest,
  DeleteVenueManagerRequest,
  GetVenueManagerRequest,
  Manager,
  SendInviteVenueManagerRequest,
  UpdateVenueMemberRoleRequest,
  UpdateVenueManagerRequest,
  VenueManagerApiModel,
  UpdateVenueManagerSMSNotificationRequest,
} from '../../api/models/venueManagers';
import { httpClient } from '../../services/httpClient/httpClient';
import { AuthEndpoints, VenueManagerEndpoints } from '../../api/endpoints';
import { getTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import { createAlert } from './alertSlice';
interface VenueManagersInitialState extends TableDTO<VenueManagerApiModel & { itemId?: string }> {
  loggedInManager?: VenueManagerApiModel | null;
}
const initialState: VenueManagersInitialState = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
  itemId: undefined,
  loggedInManager: null,
};

export const getVenueManagerList = createAsyncThunk(
  'venus/managers',
  async (options: GetVenueManagerRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetVenueManagerRequest, VenueManagerApiModel[]>({
        url: VenueManagerEndpoints.GetVenueManagersList.replace(':id', options.venueId),
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createVenueManager = createAsyncThunk(
  'venus/managers-create',
  async (options: CreateVenueManagerRequest, { dispatch, rejectWithValue }) => {
    try {
      const req = await httpClient.post<Manager, VenueManagerApiModel>({
        url: VenueManagerEndpoints.CreateVenueManager.replace(':id', options.venueId),
        requiresToken: true,
        payload: options.item,
      });

      //caused toast alert duplication
      // dispatch(
      //   createAlert({
      //     title: 'New Member Successfully Invited',
      //     message: 'The Invitation Email Sent',
      //   }),
      // );

      return req;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteVenueManager = createAsyncThunk(
  'venus/managers-delete',
  async (options: DeleteVenueManagerRequest, { dispatch, rejectWithValue }) => {
    try {
      const url = VenueManagerEndpoints.DeleteManager.replace(':id', options.venueId).replace(
        ':managerId',
        options.managerId,
      );
      const req = await httpClient.delete<Manager | string, VenueManagerApiModel | string>({
        url: url,
        requiresToken: true,
        payload: options.managerId,
      });

      return req;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const sendInviteVenueManager = createAsyncThunk(
  'venus/managers-send-invite',
  async (options: SendInviteVenueManagerRequest, { rejectWithValue, dispatch }) => {
    try {
      const req = await httpClient.post<SendInviteVenueManagerRequest, VenueManagerApiModel>({
        url: VenueManagerEndpoints.SendInvite.replace(':id', options.venueId).replace(
          ':managerId',
          options.id,
        ),
        requiresToken: true,
      });
      dispatch(
        createAlert({
          title: 'Venue manager',
          message: 'Invite has been successfully sent',
        }),
      );
      return req;
    } catch (error) {
      dispatch(
        createAlert({
          title: 'Venue manager',
          type: 'error',
          message: 'Error sending invite',
        }),
      );
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateVenueManagerStatus = createAsyncThunk(
  'venus/managers-update-status',
  async (options: ActivateVenueManagerRequest, { rejectWithValue }) => {
    try {
      return await httpClient.post<ActivateVenueManagerRequest, VenueManagerApiModel>({
        url: VenueManagerEndpoints[options.activate ? 'ActivateManager' : 'DeactivateManager']
          .replace(':id', options.venueId)
          .replace(':managerId', options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateVenueManager = createAsyncThunk(
  'venus/managers-update',
  async (options: UpdateVenueManagerRequest, { rejectWithValue }) => {
    try {
      return await httpClient.put<{ status: string }, VenueManagerApiModel>({
        url: AuthEndpoints.PatchMe.replace(':id', options.id),
        payload: {
          status: options.status,
        },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateVenueMemberRole = createAsyncThunk(
  'venus/member-update',
  async (options: UpdateVenueMemberRoleRequest, { rejectWithValue }) => {
    try {
      return await httpClient.put<{ role: string; accessScopes?: [string] }, VenueManagerApiModel>({
        url: VenueManagerEndpoints.DeleteManager.replace(':id', options.venueId).replace(
          ':managerId',
          options.memberId,
        ),
        payload: {
          role: options.role,
          accessScopes: options.accessScopes,
        },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const enableVenueMemberSMSNotification = createAsyncThunk(
  'venus/member-enable-sms-notification',
  async (options: UpdateVenueManagerSMSNotificationRequest, { rejectWithValue }) => {
    try {
      return await httpClient.post<UpdateVenueManagerSMSNotificationRequest, VenueManagerApiModel>({
        url: VenueManagerEndpoints.EnableVideoAlerts.replace('{venueId}', options.venueId).replace(
          '{id}',
          options.id,
        ),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const disableVenueMemberSMSNotification = createAsyncThunk(
  'venus/member-disable-sms-notification',
  async (options: UpdateVenueManagerSMSNotificationRequest, { rejectWithValue }) => {
    try {
      return await httpClient.post<UpdateVenueManagerSMSNotificationRequest, VenueManagerApiModel>({
        url: VenueManagerEndpoints.DisableVideoAlerts.replace('{venueId}', options.venueId).replace(
          '{id}',
          options.id,
        ),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const venueManagerSlice = createSlice({
  name: 'venues',
  initialState,
  reducers: {
    setCurrentLoggedInManager(state, action: PayloadAction<VenueManagerApiModel | null>) {
      state.loggedInManager = action.payload;
    },
    resetError(state) {
      state.error = false;
    },
    updateSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = initialState.page;
    },
    setVenueManagerSorting(state, action: PayloadAction<string>) {
      const sorting = getTableSorting(current(state), action.payload);

      return {
        ...initialState,
        sort: sorting,
        size: state.size,
      };
    },
    addEmptyManager(state, action: PayloadAction<VenueManagerApiModel>) {
      state.items = [action.payload, ...state.items];
    },
    removeEmptyManager(state) {
      const items = [...state.items];
      items.splice(0, 1);
      state.items = [...items];
    },
    reset: () => initialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getVenueManagerList.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getVenueManagerList.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getVenueManagerList.fulfilled, (state, { payload }) => {
      return tableLoadFulFilled(state, {
        items: payload,
        totalItems: payload.length,
        totalPages: 1,
        page: 1,
        size: 1,
      });
    });
    reducersBuilder.addCase(createVenueManager.pending, (state) => {
      let items = [...state.items];
      items[0] = { ...items[0], error: false };
      state.items = [...items];
    });
    reducersBuilder.addCase(createVenueManager.fulfilled, (state, { payload }) => {
      let items = [...state.items];
      items[0] = payload;
      state.items = [...items];
    });
    reducersBuilder.addCase(createVenueManager.rejected, (state) => {
      let items = [...state.items];
      items[0] = { ...items[0], error: true };
      state.items = [...items];
    });
    reducersBuilder.addCase(deleteVenueManager.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(deleteVenueManager.fulfilled, (state, { payload }) => {
      state.isLoading = false;

      const items = [...state.items.filter((item) => item.id !== payload)];

      state.items = items;
    });
    reducersBuilder.addCase(deleteVenueManager.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = true;

      const index = state.items.findIndex((item) => item.id === payload);
      const items = [...state.items];
      items[index] = { ...items[index], error: true };
      state.items = [...items];
    });
    reducersBuilder.addCase(sendInviteVenueManager.pending, (state, action) => {
      state.itemId = action.meta.arg.id;
      state.isLoading = true;
    });
    reducersBuilder.addCase(sendInviteVenueManager.rejected, (state, action) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(sendInviteVenueManager.fulfilled, (state, { payload }) => {
      let items = [...state.items];
      const index = items.findIndex((item) => item.id === payload.id);
      items[index] = payload;
      state.items = [...items];
      state.isLoading = false;
    });
    reducersBuilder.addCase(updateVenueManager.fulfilled, (state, { payload }) => {
      let items = [...state.items];
      const index = items.findIndex((item) => item.id === payload.id);
      items[index] = payload;
      state.items = [...items];
    });
    reducersBuilder.addCase(updateVenueManagerStatus.fulfilled, (state, { payload }) => {
      let items = [...state.items];
      const index = items.findIndex((item) => item.id === payload.id);
      items[index] = payload;
      state.items = [...items];
    });
    reducersBuilder.addCase(updateVenueMemberRole.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(updateVenueMemberRole.pending, (state) => {
      state.error = false;
      state.isLoading = true;
    });
    reducersBuilder.addCase(updateVenueMemberRole.fulfilled, (state, { payload }) => {
      let items = [...state.items];
      const index = items.findIndex((item) => item.id === payload.id);
      items[index] = payload;
      state.items = [...items];
      state.isLoading = false;
    });
    reducersBuilder.addCase(enableVenueMemberSMSNotification.pending, (state) => {
      state.error = false;
      state.isLoading = false;
    });
    reducersBuilder.addCase(enableVenueMemberSMSNotification.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(enableVenueMemberSMSNotification.fulfilled, (state, { payload }) => {
      let items = [...state.items];
      const index = items.findIndex((item) => item.id === payload.id);
      items[index] = payload;
      state.items = [...items];
    });
    reducersBuilder.addCase(disableVenueMemberSMSNotification.pending, (state) => {
      state.error = false;
      state.isLoading = false;
    });
    reducersBuilder.addCase(disableVenueMemberSMSNotification.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(disableVenueMemberSMSNotification.fulfilled, (state, { payload }) => {
      let items = [...state.items];
      const index = items.findIndex((item) => item.id === payload.id);
      items[index] = payload;
      state.items = [...items];
    });
  },
});

export const {
  addEmptyManager,
  setVenueManagerSorting,
  removeEmptyManager,
  reset,
  updateSize,
  resetError,
  setCurrentLoggedInManager,
} = venueManagerSlice.actions;
export default venueManagerSlice.reducer;
