import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    display: 'flex',
    width: '130px',
  },
  progresWrapper: {
    width: '86px',
    height: '20px',
    background: '#EAECF0',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  progresBar: {
    height: '20px',
    borderRadius: '50px',
    background: '#9B8AFB',
  },
  progressVal: {
    marginLeft: '15px',
    fontSize: '12px',
    letterSpacing: '0.02em',
  },
}));
