import { FC, useRef, useState } from 'react';
import { Box, Card, CardActionArea, CardMedia } from '@mui/material';
import { useVideoComponentStyles } from './VideoComponent.style';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { ChangeThumnailIcon } from './ChangeThumnailIcon';

interface IVideoComponentProps {
  url: string;
  handleOpenThumbnailsPanel?: () => void;
  thumbnail: string;
  isPlaying?: boolean;
  isTopGolf: boolean;
  handleOpenModal: () => void;
  withoutThumbnailEdit?: boolean;
  withoutShadow?: boolean;
  width?: number;
  height?: number;
}

export const VideoComponent: FC<IVideoComponentProps> = ({
  url,
  handleOpenThumbnailsPanel,
  thumbnail,
  isTopGolf,
  handleOpenModal,
  isPlaying,
  withoutThumbnailEdit,
  withoutShadow,
  width,
  height,
}) => {
  const classes = useVideoComponentStyles();
  const [isMouseOverPlayIcon, setisMouseOverPlayIcon] = useState(false);
  const [isMouseOverThumbIcon, setisMouseOverThumbIcon] = useState(false);

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const handleOpenVideoModal = () => {
    handleOpenModal();
  };
  return (
    <Card
      sx={{
        width: width && `${width}px !important`,
        height: height && `${height}px !important`,
        boxShadow: withoutShadow ? 'none' : undefined,
      }}
      className={isTopGolf ? classes.TopGolfVideoCard : classes.VideoCard}
    >
      <CardActionArea className={classes.actionArea} id="video-detail-video-wrapper">
        <CardMedia
          component="video"
          ref={refVideo}
          poster={thumbnail}
          src={url}
          // onClick={() => playPauseSwitch()}
          onClick={handleOpenVideoModal}
          id="video-detail-play-button"
        />
        {!isPlaying && (
          <Box
            fontSize={45}
            className={[classes.icon, isMouseOverPlayIcon ? classes.MouseOverIcon : ''].join(' ')}
            onMouseOver={() => setisMouseOverPlayIcon(true)}
            onMouseLeave={() => setisMouseOverPlayIcon(false)}
          >
            <PlayArrowRoundedIcon fontSize="inherit" color="inherit" />
          </Box>
        )}
        {!withoutThumbnailEdit && (
          <Box
            className={[
              classes.ChangeThumnailIcon,
              isMouseOverThumbIcon ? classes.ChangeThumnailIconOver : '',
            ].join(' ')}
            onClick={handleOpenThumbnailsPanel}
            onMouseOver={() => setisMouseOverThumbIcon(true)}
            onMouseLeave={() => setisMouseOverThumbIcon(false)}
          >
            <ChangeThumnailIcon />
          </Box>
        )}
      </CardActionArea>
    </Card>
  );
};
