export const CreateVideoIcon = () => {
  return (
    <svg width="42" height="43" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2000_44227" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="43">
        <circle cx="20.998" cy="21.5" r="20.7558" fill="#EEECFC" />
      </mask>
      <g mask="url(#mask0_2000_44227)">
        <circle cx="20.998" cy="21.5" r="20.7558" fill="#EEECFC" />
        <path
          d="M16.5508 0.744141L16.5508 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.65625 0.744141L7.65625 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.4453 0.744141L25.4453 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.3398 0.744141L34.3398 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.4423 34.9996V12C32.4423 8.68629 29.756 6 26.4423 6H15.7695C12.4558 6 9.76953 8.6863 9.76953 12V34.9996"
          stroke="#5242EA"
        />
        <path
          d="M20.0195 7.99219H24.2377"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="18.3642" cy="7.99214" r="0.395455" fill="#8B89A0" />
        <path
          d="M27.5092 9.86719H14.7031C13.0463 9.86719 11.7031 11.2103 11.7031 12.8672V35.0002H30.5092V12.8672C30.5092 11.2103 29.1661 9.86719 27.5092 9.86719Z"
          fill="white"
        />
        <path
          d="M30.5092 35.0002V12.8672C30.5092 11.2103 29.1661 9.86719 27.5092 9.86719H14.7031C13.0463 9.86719 11.7031 11.2103 11.7031 12.8672V35.0002"
          stroke="#5242EA"
        />
        <rect x="14.293" y="27.9971" width="13.7091" height="3.69091" rx="1.84546" fill="#D4D0FA" />
        <rect x="14.293" y="13.7607" width="13.7091" height="12.6546" rx="4" fill="#D4D0FA" />
        <path
          d="M22.5742 20.0376C22.9076 20.23 22.9076 20.7112 22.5742 20.9036L20.4269 22.1433C20.0936 22.3358 19.6769 22.0952 19.6769 21.7103L19.6769 19.2309C19.6769 18.846 20.0936 18.6054 20.4269 18.7978L22.5742 20.0376Z"
          fill="#5242EA"
          stroke="#5242EA"
        />
      </g>
    </svg>
  );
};
