import { CampaignsApiModel } from '../../../api/models/campaigns';
import { TableContent, TableRow } from '../../../common/commonTypes';
import { RewardsPageStrings, RewardsStrings } from '../../../common/localization/en';
import { getDateString, getLocaleNumberValue, getStringValue } from '../../../services/utilities';
import { CampaignStatusCell } from '../../rewards/campaignsTab/TableSection/CampaignStatusCell/CampaignStatusCell';
import { Box, Button, Typography } from '@mui/material';
import { EndCampaignButton } from '../../rewards/campaignsTab/TableSection/EndButton/EndButton';
import { useStyles } from './Campaigns.styles';
import { useHistory } from 'react-router';
import { getApiUrlForId } from '../../../api/endpoints';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import ActionMenu from '../../rewards/CampaignSummary/ActionMenu/ActionMenu';
import { CopyIcon } from '../../../common/assets/newDesign/CopyIcon';
import QRCodeIconSmall from '../../../common/assets/newDesign/QRCodeIconSmall';
import { useTypedSelector } from '../../../store';
import { useToasts } from 'react-toast-notifications';
import QRCodeStyling, { Options } from 'qr-code-styling';
import { DEFAULT_BRAND_COLOR } from '../../../common/constants/defaults';
import { StarIcon } from '../../../common/assets/StarIcon';
import { StarIconGray } from '../../../common/assets/StarIconGray';
import { CustomTooltip } from '../../rewards/CampaignSummary/CampaignSummary.helper';

interface CampaignMapperProps<T> {
  items: T[];
  onOpenCampaign: () => void;
  isEditButton: boolean;
  isMobile: boolean;
  onSetPrimaryCampaign: (campaignId: string) => void;
}

interface RedeemCampaignsContent extends TableRow {
  id: string;
  img?: JSX.Element;
  name: string | JSX.Element;
  startedAt?: JSX.Element;
  endedAt: JSX.Element;
  achievements: JSX.Element;
  rewardTitle: JSX.Element;
  createdByName: JSX.Element;
  summaryButton: JSX.Element | string;
  // actions: JSX.Element;
}

type modeType = 'default' | 'branded';

export const CreateCampaignsTableContent = ({
  items,
  onSetPrimaryCampaign,
}: CampaignMapperProps<CampaignsApiModel>): TableContent<RedeemCampaignsContent> => {
  const {
    venue,
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueLogo = venueProperties?.['dashboard.QRCode.config']?.logo;
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const qrColor = venueProperties?.['dashboard.QRCode.config']?.brandColor || venueBrandColor;
  const styles = useStyles();
  const history = useHistory();
  const { trackEvent } = useTrackEvent();
  const { addToast } = useToasts();

  const qrOptions = (campaignLink: string, mode: modeType): Partial<Options> => {
    return {
      width: 430,
      height: 430,
      image: mode === 'branded' ? venueLogo : '',
      type: 'svg',
      dotsOptions: {
        color: mode === 'branded' ? qrColor || DEFAULT_BRAND_COLOR : '#000',
        type: 'extra-rounded',
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
      },
      data: campaignLink,
      margin: 20,
      imageOptions: {
        crossOrigin: 'anonymous',
        imageSize: 0.5,
      },
    };
  };

  const handleDownload = (item: CampaignsApiModel, mode: modeType) => {
    const campaignLink = `https://${venue.subdomain}.socialv.io?cid=${item.shortcode}&qrcode=true`;

    const RESOLUTION = 1024;
    new QRCodeStyling({
      ...qrOptions(campaignLink, mode),
      width: RESOLUTION,
      height: RESOLUTION,
    }).download({ name: 'qr', extension: 'png' });
    addToast(RewardsPageStrings.QRCodeDownloaded, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  const actionMenu = (item: CampaignsApiModel) => {
    const options = [
      {
        label: 'Copy Invitation Link',
        onClick: () => {
          //copy link
          const campaignLink = `https://${venue.subdomain}.socialv.io?cid=${item.shortcode}`;
          navigator.clipboard.writeText(campaignLink);
          //event tracked by menu item click
          // add toast
          addToast(RewardsPageStrings.CampaignLinkCopied, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        },
        showForEndedCampaigns: true,
        icon: (
          <Box sx={{ color: '#8B89A0' }}>
            <CopyIcon />
          </Box>
        ),
      },
      {
        icon: (
          <Box sx={{ color: '#8B89A0' }}>
            <QRCodeIconSmall />
          </Box>
        ),
        label: 'Download QR Code',
        onClick: () => {},
        showForEndedCampaigns: true,
        subItems: [
          {
            label: 'Default',
            onClick: () => {
              handleDownload(item, 'default');
            },
          },
          {
            label: 'Branded',
            onClick: () => {
              handleDownload(item, 'branded');
            },
          },
        ],
      },
    ];

    if (!(item.isPreferred || item.endedAt)) {
      options.push({
        label: 'Set as Primary Campaign',
        onClick: () => {
          onSetPrimaryCampaign(item.id);
        },
        showForEndedCampaigns: false,
        icon: <StarIconGray />,
      });
    }

    return options;
  };

  const tableContent: TableContent<RedeemCampaignsContent> = {
    header: {
      name: {
        name: RewardsPageStrings.Name,
        sortable: false,
      },
      startedAt: {
        name: RewardsPageStrings.CampaignsHeaderStartDate,
        sortable: true,
      },
      endedAt: {
        name: RewardsPageStrings.CampaignsHeaderStatus,
        sortable: true,
      },
      numAchievements: {
        name: RewardsPageStrings.AchievedUsers,
        sortable: true,
      },
      rewardTitle: {
        name: RewardsPageStrings.RewardTitle,
        sortable: true,
      },
      createdByName: {
        name: RewardsPageStrings.Owner,
        sortable: true,
      },
      downloadButton: {
        name: RewardsPageStrings.CampaignsHeaderAction,
        sortable: false,
      },
      actions: {
        name: '',
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  const goToDetails = (id: string) => {
    history.push(getApiUrlForId(WebsiteRoutes.CampaignDetails, id));
  };

  tableContent.rows = items.map((item, index) => {
    return {
      id: item.id,
      name: (
        <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px' }}>
          {item.isPreferred && (
            <CustomTooltip
              title={
                <Box padding={'13px 15px'}>
                  <Typography
                    color="inherit"
                    style={{ fontSize: '11px', fontWeight: '700', textAlign: 'left' }}
                  >
                    {RewardsStrings.PrimaryCampaignToolTipTitle}
                  </Typography>
                  <Typography
                    color="inherit"
                    style={{
                      textAlign: 'left',
                      fontSize: '11px',
                      fontWeight: '500',
                      marginTop: '8px',
                    }}
                  >
                    {RewardsStrings.PrimaryCampaignTooltip}
                  </Typography>
                </Box>
              }
              icon={
                <Box>
                  <StarIcon />
                </Box>
              }
              padding="4px 5px 0 0"
            />
          )}
          <Typography>{getStringValue(item.name)} </Typography>
        </Box>
      ),
      startedAt: <Typography className={styles.cell}>{getDateString(item.startedAt)} </Typography>,
      endedAt: (
        <Typography className={styles.cell}>
          <CampaignStatusCell
            isActive={!item.endedAt}
            hoverText={item.endedAt ? getDateString(item.startedAt) : ''}
          />
        </Typography>
      ),
      achievements: (
        <Typography className={styles.usersCell}>
          {getLocaleNumberValue(item.numAchievements)}
        </Typography>
      ),
      rewardTitle: (
        <Typography pl="15px" fontSize={'12px'}>
          {item.isNullReward ? '---' : item.rewardTitle || '---'}
        </Typography>
      ),
      createdByName: (
        <Typography pl="15px" fontSize={'12px'}>
          {item.createdByName || '---'}
        </Typography>
      ),
      summaryButton: (
        <Box display={'flex'} alignItems={'center'} justifyContent="center">
          {item.endedAt ? (
            <Button
              className={styles.btnSummary}
              onClick={() => {
                goToDetails(item.id);
              }}
              id={`summary-campaign-button-${index}`}
            >
              {RewardsPageStrings.DownloadCampaignButton}
            </Button>
          ) : (
            <EndCampaignButton
              onClick={() => {
                goToDetails(item.id);
                trackEvent(RewardCampaignsEvents.RewardCampaignsTablePreviewButtonClick, {
                  campaignId: item.id,
                });
              }}
              text={'Preview'}
              campaignName={item.name}
              id={`preview-campaign-button-${index}`}
            />
          )}
          <ActionMenu
            title={''}
            items={actionMenu(item)}
            campaignId={item.id}
            isActiveCampaign
            isCampaignsTable
          />
        </Box>
      ),
    };
  });

  return tableContent;
};
