import { useMemo } from 'react';
import _omitBy from 'lodash/omitBy';

import { SettingsPageStrings } from '../common/localization/en';
import { useTypedSelector } from '../store';

interface UseWebAppLinkProps {
  queryParams?: Record<string, string>;
  qrCode?: boolean;
}

export const useWebAppLink = ({ qrCode, queryParams }: UseWebAppLinkProps = {}) => {
  const { subdomain, isLoading } = useTypedSelector((state) => state.venue.venue);

  const webAppLink = useMemo(() => {
    const base = `https://${subdomain}${SettingsPageStrings.DomainName}`;

    const rawParams = _omitBy(
      {
        qrcode: qrCode ? 'true' : '',
        ...queryParams,
      },
      (v) => !v,
    );
    const params = new URLSearchParams(rawParams).toString();

    if (!params) return base;

    return `${base}?${params}`;
  }, [qrCode, queryParams, subdomain]);

  return {
    webAppLink,
    isLoading,
  };
};
