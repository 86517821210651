export const VideosIcon = () => {
  return (
    <svg
      width="24"
      stroke="inherit"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="4.28125"
        width="18"
        height="14.625"
        rx="3"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4891 14.3C10.8226 14.6738 10 14.192 10 13.4278V10.1126C10 9.34836 10.8226 8.86658 11.4891 9.24042L14.4448 10.898C15.1259 11.28 15.1259 12.2604 14.4448 12.6424L11.4891 14.3Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.1875 21.7188L12 18.9062L14.8125 21.7188"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
