import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { FilterOptionsType } from './filterOptions.helper';
import { SimpleAccordion } from '../../accordion/CustomAccordion';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // background: theme.palette.primary.light,
  },
  optionWrapper: {
    cursor: 'pointer',
    display: 'flex',
    height: '36px',
    padding: '5px 20px',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '5px',
    gap: '12px',
    stroke: theme.palette.primary.main,
    width: '100%',
    '&:hover': {
      backgroundColor: 'rgba(82, 66, 234, 0.2)',
    },
  },
  optionTitle: {
    textAlign: 'center',
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
  selectedOption: {
    color: theme.palette.common.white,
    stroke: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  active: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

interface FilterOptionsContainerProps {
  selectedOptions: string[];
  handleClick: (id: string) => void;
  filterOptions: FilterOptionsType[];
  title: string;
  primary?: boolean;
  expanded?: boolean;
  handleChange?: () => void;
  handleDelete?: () => void;
}

export const FilterOptionsContainer: FC<FilterOptionsContainerProps> = ({
  selectedOptions,
  handleClick,
  filterOptions,
  title,
  primary,
  expanded,
  handleChange,
  handleDelete,
}) => {
  const classes = useStyles();
  const renderOptions = (options: FilterOptionsType[], tempSelectedOptions: string[]) => {
    return options.map((item) => {
      const isSelected = tempSelectedOptions.includes(item.id);
      return (
        <div
          className={[
            classes.optionWrapper,
            isSelected ? [classes.selectedOption, classes.active].join(' ') : '',
          ].join(' ')}
          onClick={() => handleClick(item.id)}
        >
          {item.icon}
          <span
            className={[
              classes.optionTitle,
              isSelected ? [classes.selectedOption, classes.active].join(' ') : '',
            ].join(' ')}
          >
            {item.title}
          </span>
        </div>
      );
    });
  };

  return (
    <SimpleAccordion
      title={title}
      primary={primary}
      expanded={expanded}
      handleChange={handleChange}
      handleDelete={handleDelete}
    >
      <div className={classes.root}>{renderOptions(filterOptions, selectedOptions)}</div>
    </SimpleAccordion>
  );
};
