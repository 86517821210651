import {
  CampaignTransactionRecordResponse,
  CreateTransactionBatchResponse,
  GetTransactionAPIModel,
  GetTransactionBatchesRequest,
  ManualRecord,
  PostTransactionOptions,
  TransactionRecord,
  TransactionRecordRequest,
  UpdateTransactionBatchResponse,
  UpdateTransactionOptions,
} from '../../api/models/transaction';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { GetTableDataResponse } from './../../api/models/common';

import { DataSyncEndpoints, getApiUrlForId } from '../../api/endpoints';
import { defaultPagination } from '../../common/constants/constants';
import { TableDTO } from '../storeModels';
import { getTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';

interface InitialState extends TableDTO<GetTransactionAPIModel> {
  approveLoading?: boolean;
  approveError?: boolean;
}
interface InitialStateForPagination extends TableDTO<TransactionRecord> {}

interface InitialState2 {
  data: InitialState;
  details: InitialStateForPagination;
  campaignRecords: TableDTO<TransactionRecord>;
  manualRecords: ManualRecord[];
}

const initialState: InitialState2 = {
  data: {
    error: false,
    isLoading: false,
    items: [],
    page: defaultPagination.page,
    size: defaultPagination.size,
    totalItems: defaultPagination.totalItems,
    totalPages: defaultPagination.totalPages,
    sort: defaultPagination.sortByLastCreated,
    lastUpdated: new Date().toISOString(),
    selectedRow: null,
    approveLoading: false,
    approveError: false,
  },
  details: {
    error: false,
    isLoading: false,
    items: [],
    page: defaultPagination.page,
    size: defaultPagination.size,
    totalItems: defaultPagination.totalItems,
    totalPages: defaultPagination.totalPages,
    sort: defaultPagination.sortByLastCreated,
    lastUpdated: new Date().toISOString(),
    pagination: {
      items: [],
    },
  },
  campaignRecords: {
    error: false,
    isLoading: false,
    items: [],
    page: defaultPagination.page,
    size: defaultPagination.size,
    totalItems: 0,
    totalPages: defaultPagination.totalPages,
    sort: defaultPagination.sortByLastCreated,
    lastUpdated: new Date().toISOString(),
  },
  manualRecords: [],
};

export const getTransactionBatches = createAsyncThunk(
  'dataSync/GetTransactionBatches',
  async (options: GetTransactionBatchesRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<
        GetTransactionBatchesRequest,
        GetTableDataResponse<GetTransactionAPIModel>
      >({
        url: DataSyncEndpoints.GetTransactionBatches,
        requiresToken: true,
        params: {
          venueId: options.venueId,
          page: options.page,
          size: options.size,
          sort: options.sort,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getTransactionBatchRecordsById = createAsyncThunk(
  'dataSync/GetTransactionBatchRecordsById',
  async (options: { id: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetTransactionBatchesRequest, TransactionRecord[]>({
        url: getApiUrlForId(DataSyncEndpoints.GetTransactionBatchRecordsById, options.id),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export enum InvitedUserStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

interface GetRecordsByCampaignIdOptions {
  campaignId: string;
  pageable: {
    page?: number;
    size?: number;
    sort?: string[];
  };
  search?: string;
  filter?: {
    status: InvitedUserStatus;
    hasVideos?: boolean;
  };
}

export const getTransactionRecordsByCampaignId = createAsyncThunk(
  'dataSync/GetTransactionRecordsByCampaignId',
  async (options: GetRecordsByCampaignIdOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<TransactionRecordRequest, CampaignTransactionRecordResponse>({
        url: getApiUrlForId(
          DataSyncEndpoints.GetTransactionRecordsByCampaignId,
          options.campaignId,
        ),
        params: {
          page: options.pageable.page,
          size: options.pageable.size,
          sort: options.pageable.sort?.toString(),
          search: options.search || undefined,
          status: options?.filter?.status || undefined,
          hasVideos: options?.filter?.hasVideos,
        },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postTransactionBatch = createAsyncThunk(
  'dataSync/PostTransactionBatch',
  async (options: PostTransactionOptions, { rejectWithValue }) => {
    try {
      return httpClient.post<PostTransactionOptions, CreateTransactionBatchResponse>({
        url: DataSyncEndpoints.PostTransactionBatch,
        payload: {
          records: options.records,
          venueId: options.venueId,
          name: null,
          campaignId: options.campaignId,
        },
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateTransactionBatch = createAsyncThunk(
  'dataSync/updateTransactionBatch',
  async (options: UpdateTransactionOptions, { rejectWithValue }) => {
    try {
      return httpClient.put<Omit<UpdateTransactionOptions, 'id'>, UpdateTransactionBatchResponse>({
        url: getApiUrlForId(DataSyncEndpoints.UpdateTransactionBatch, options.id),
        payload: {
          name: options.name,
        },
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const putTransactionBatch = createAsyncThunk(
  'dataSync/PutTransactionBatch',
  async (options: { id: string }, { rejectWithValue }): Promise<any> => {
    try {
      return await httpClient.put<PostTransactionOptions, { success: boolean }>({
        url: getApiUrlForId(DataSyncEndpoints.PutTransactionBatch, options.id),

        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const dataSyncSlice = createSlice({
  name: 'dataSync',
  initialState,
  reducers: {
    resetError(state) {
      state.data.error = false;
    },
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.data.page = action.payload;
    },
    updateSize(state, action: PayloadAction<number>) {
      state.data.size = action.payload;
      state.data.page = initialState.data.page;
    },
    setDataSyncSorting(state, action: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.data), action.payload);

      return {
        ...initialState,
        data: { ...initialState.data, sort: sorting, size: state.data.size },
      };
    },

    reset: () => initialState,
    goToSelectedDetailsPage(state, action: PayloadAction<number>) {
      const firstItem = action.payload * state.details.size;
      const lastItem = (action.payload + 1) * state.details.size;

      state.details.pagination.items = state.details.items.slice(firstItem, lastItem);
      state.details.page = action.payload;
    },
    updateDetailsSize(state, action: PayloadAction<number>) {
      const firstItem = initialState.details.page * action.payload;
      const lastItem = (initialState.details.page + 1) * action.payload;

      state.details.pagination.items = state.details.items.slice(firstItem, lastItem);
      state.details.size = action.payload;
      state.details.page = initialState.details.page;
    },
    setDetailsSorting(state, action: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.details), action.payload);

      return {
        ...initialState,
        details: { ...initialState.details, sort: sorting, size: state.details.size },
      };
    },
    updateDetailsSearch(state, action: PayloadAction<string>) {},

    setCampaignRecordsSorting(state, action: PayloadAction<string>) {
      state.campaignRecords.sort = getTableSorting(current(state.campaignRecords), action.payload);
    },
    setCampaignRecordsPage(state, action: PayloadAction<number>) {
      state.campaignRecords.page = action.payload;
    },
    updateCampaignRecordsSize(state, action: PayloadAction<number>) {
      state.campaignRecords.size = action.payload;
    },
    updateCampaignRecordsSearch(state, action: PayloadAction<string>) {
      state.campaignRecords.search = action.payload;
    },
    resetSelectedRecord(state) {
      state.details.items = [];
      state.details.pagination.items = [];
    },
    resetManualRecords(state) {
      state.manualRecords = [];
    },
    addManualRecord(state, action: PayloadAction<ManualRecord>) {
      state.manualRecords = [...state.manualRecords, action.payload];
    },
    deleteManualRecord(state, action: PayloadAction<{ id: string }>) {
      state.manualRecords = state.manualRecords.filter((record) => record.id !== action.payload.id);
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getTransactionBatches.rejected, (state) => {
      const table = tableLoadRejected(state.data);
      state.data = table;
      return state;
    });
    reducersBuilder.addCase(getTransactionBatches.pending, (state) => {
      const table = tableLoadPending(state.data);
      state.data = table;
      return state;
    });
    reducersBuilder.addCase(getTransactionBatches.fulfilled, (state, { payload }) => {
      const table = tableLoadFulFilled(state.data, payload);
      state.data = table;
      return state;
    });
    reducersBuilder.addCase(getTransactionBatchRecordsById.pending, (state) => {
      state.details.isLoading = true;
    });
    reducersBuilder.addCase(getTransactionBatchRecordsById.rejected, (state) => {
      state.details.isLoading = false;
      state.details.error = true;
    });
    reducersBuilder.addCase(getTransactionBatchRecordsById.fulfilled, (state, { payload }) => {
      const firstItem = state.details.page * state.details.size;
      const lastItem = (state.details.page + 1) * state.details.size;

      state.details.items = payload;
      state.details.pagination.items = payload.slice(firstItem, lastItem);

      state.details.isLoading = false;
      state.details.totalItems = payload.length;
      state.details.totalPages = Math.ceil(payload.length / state.details.size);
      state.details.error = false;
    });
    reducersBuilder.addCase(getTransactionRecordsByCampaignId.pending, (state) => {
      state.campaignRecords.isLoading = true;
    });
    reducersBuilder.addCase(getTransactionRecordsByCampaignId.rejected, (state) => {
      state.campaignRecords.isLoading = false;
      state.campaignRecords.error = true;
    });
    reducersBuilder.addCase(getTransactionRecordsByCampaignId.fulfilled, (state, { payload }) => {
      state.campaignRecords.items = payload.items;
      state.campaignRecords.totalItems = payload.totalItems;
      state.campaignRecords.totalPages = payload.totalPages;
      state.campaignRecords.page = payload.page;
      state.campaignRecords.size = payload.size;

      state.campaignRecords.error = false;
      state.campaignRecords.isLoading = false;
    });
    reducersBuilder.addCase(postTransactionBatch.pending, (state) => {
      state.data.isLoading = true;
    });
    reducersBuilder.addCase(postTransactionBatch.rejected, (state) => {
      state.data.isLoading = false;
      state.data.error = true;
    });
    reducersBuilder.addCase(postTransactionBatch.fulfilled, (state, { payload }) => {
      state.data.isLoading = false;
      state.data.items = [payload, ...state.data.items];
      state.data.error = false;
    });
    reducersBuilder.addCase(putTransactionBatch.fulfilled, (state, { meta }) => {
      state.data.isLoading = false;
      state.data.error = false;
      state.data.approveLoading = false;
      state.data.items = state.data.items.map((item) => {
        if (item.id === meta.arg.id) {
          return { ...item, status: 'SUCCESSFUL' };
        }
        return item;
      });
    });
    reducersBuilder.addCase(putTransactionBatch.rejected, (state) => {
      state.data.approveLoading = false;

      state.data.approveError = true;
    });
    reducersBuilder.addCase(putTransactionBatch.pending, (state) => {
      state.data.approveLoading = true;

      state.data.approveError = true;
    });
    reducersBuilder.addCase(updateTransactionBatch.pending, (state) => {
      state.data.isLoading = true;
      state.data.error = true;
    });
    reducersBuilder.addCase(updateTransactionBatch.rejected, (state) => {
      state.data.isLoading = false;
      state.data.error = true;
    });
    reducersBuilder.addCase(updateTransactionBatch.fulfilled, (state, { meta, payload }) => {
      state.data.isLoading = false;
      state.data.error = false;
      state.data.items = state.data.items.map((item) => {
        if (item.id === payload.id) {
          return payload;
        }
        return item;
      });
    });
  },
});

export const {
  updateSize,
  goToSelectedPage,
  setDataSyncSorting,
  resetSelectedRecord,
  goToSelectedDetailsPage,
  updateDetailsSize,
  setDetailsSorting,
  updateDetailsSearch,
  addManualRecord,
  resetManualRecords,
  deleteManualRecord,
  setCampaignRecordsSorting,
  setCampaignRecordsPage,
  updateCampaignRecordsSize,
  updateCampaignRecordsSearch,
} = dataSyncSlice.actions;
export default dataSyncSlice.reducer;
