import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStylesWorkspace = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'rgba(255, 255, 255, 0.1)',
    marginLeft: '15px',
    marginRight: '15px',
    borderRadius: '10px',
    padding: '10px 20px',
    '&:hover': {
      background: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  active: {
    background: theme.palette.primary.main,
  },
  logo: {
    width: '30px',
    height: '30px',
    objectFit: 'contain',
    marginRight: '8px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.common.white,
    letterSpacing: '0.02em',
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  section: {
    paddingTop: '22px',
    borderTop: '1px solid',
    borderColor: '#292447',
    height: '1px',
    display: 'block',
  },
  containerWorkspace: {
    position: 'relative',
    minHeight: '55px',
    // paddingBottom: 18,
  },
  arrow: {
    color: 'rgba(139, 137, 160, 1)',
    fontSize: '14px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    position: 'absolute',
    top: '45%',
    right: 28,
    // padding: 5,
    minWidth: 10,
  },
}));

export const useStylesMenu = makeStyles((theme: Theme) => ({
  root: {
    margin: '15px !important',
    borderRadius: '6px !important',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      width: 310,
    },
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: '#F1F3FA',
        pointerEvents: 'all !important',
        cursor: 'pointer !important',
      },
    },
  },
  fonts: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#8F94B6',
  },
  title: {
    padding: '7px 10px',
    fontWeight: 500,
  },
  text: {
    fontWeight: 400,
  },
  menuItem: {
    padding: '10px 0px',
  },
  img: {
    width: '20px',
    height: '20px',
    margin: '0 15px',
  },
  activeManager: {
    //   cursor: 'pointer',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
}));
