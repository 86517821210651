import { Box, Button, Divider, LinearProgress, Modal, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { RecordVideoComponent } from '../../../common/components/RecordVideoComponent/RecordVideoComponent';
import { RecordedVideoPreview } from '../../../common/components/RecordVideoComponent/RecordedVideoPreview/RecordedVideoPreview';
import { useAppDispatch } from '../../../store';
import { createStorageUploadUrl, uploadVideoAsBlob } from '../../../store/slices/uploadVideoSlice';
import { Alert } from '../../../common/components/Alert/Alert';
import { MediaFilePrefix } from '../../../api/models/common';
import { setWelcomeVideoUrl } from '../../../store/slices/createCampaignSlice';
import { getUpdatedVideoUrl } from '../../../common/components/VideoUploader/VideoUplader.helpers';

interface RecordVideoModalProps {
  show: boolean;
  onClose: () => void;
}

export const RecordVideoModal: React.FC<RecordVideoModalProps> = ({ show, onClose }) => {
  const [recordedVideoBlob, setRecordedVideoBlob] = useState<string | null>(null);
  const [uploadingError, setUploadingError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState<number>(0);

  const uploadAbortController = useRef(new AbortController());

  const dispatch = useAppDispatch();

  const resetModalState = () => {
    setRecordedVideoBlob(null);
    setUploadingError(false);
    setIsUploading(false);
    setProgress(0);
  };

  const cancelUpload = () => {
    uploadAbortController.current.abort();
    resetModalState();
  };

  const handleClose = () => {
    resetModalState();
    onClose();
  };

  const onUploadProgress = (event: ProgressEvent) => {
    const percent = Math.round((event.loaded * 100) / event.total);
    setProgress(percent);
  };

  const submitVideo = async () => {
    if (!recordedVideoBlob) return;

    return new Promise(async (resolve, reject) => {
      try {
        uploadAbortController.current.signal.addEventListener('abort', (e) => {
          reject('upload aborted');
        });

        setProgress(0);
        setUploadingError(false);
        setIsUploading(true);

        const res = await createStorageUploadUrl({ ext: 'mp4', prefix: MediaFilePrefix.Welcome });
        if (!res) {
          return reject('createStorageUploadUrl failed');
        }

        const welcomeVideoUrl = res.downloadUrl;
        if (!welcomeVideoUrl) {
          return reject('no downloadUrl');
        }

        await dispatch(
          uploadVideoAsBlob({
            options: res,
            data: recordedVideoBlob,
            onUploadProgress,
          }),
        );

        resolve(welcomeVideoUrl);
      } catch (error) {
        reject(error);
      }
    })
      .then(async (welcomeVideoUrl) => {
        if (!welcomeVideoUrl || typeof welcomeVideoUrl !== 'string') return;

        const url = await getUpdatedVideoUrl(welcomeVideoUrl, undefined);
        dispatch(setWelcomeVideoUrl(url));

        handleClose();
      })
      .catch((error) => {
        if (uploadAbortController.current.signal.aborted) {
          // reset abort controller
          uploadAbortController.current = new AbortController();
        } else {
          console.error(error);
          setUploadingError(true);
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="record-video-modal-title"
      aria-describedby="record-video-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: '24px',
          borderRadius: '12px',
        }}
      >
        <Typography
          id="record-video-modal-title"
          component="h2"
          fontWeight={700}
          fontSize="18px"
          textAlign="center"
          color="black"
        >
          Record Your Welcome Video
        </Typography>
        <Typography
          fontSize="14px"
          id="record-video-modal-description"
          color="#475467"
          textAlign="center"
          mt="4px"
        >
          Guide your creators on the videos they should create when they arrive at the video app.
        </Typography>

        <Divider
          sx={{
            my: '20px',
          }}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: '24px' }}>
          {!recordedVideoBlob && (
            <Typography color="#8F8F8F" fontSize="12px" textAlign="center">
              Keep your videos short & concise. <br />
              30 seconds or less is <b>recommended</b>
            </Typography>
          )}

          {isUploading && (
            <>
              <Typography fontSize="14px" textAlign="center" mb="18px">
                <b>Uploading</b> your welcome video... <br />
                The window will auto close when upload is finished
              </Typography>

              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ borderRadius: '4px', bgcolor: '#0000001A', width: '270px' }}
              />
            </>
          )}

          {uploadingError && (
            <Alert type="error" title="Can't upload welcome video. Please try again." />
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {recordedVideoBlob ? (
            <RecordedVideoPreview videoSrc={recordedVideoBlob} />
          ) : (
            <RecordVideoComponent
              onVideoRecord={(blob) => {
                setRecordedVideoBlob(blob);
              }}
            />
          )}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: '20px', mt: '50px' }}>
          <Button
            onClick={() => setRecordedVideoBlob(null)}
            disabled={!recordedVideoBlob || isUploading}
          >
            Retake Video
          </Button>

          <Box>
            {isUploading ? (
              <Button
                onClick={cancelUpload}
                variant="outlined"
                sx={{ mr: '12px', color: '#344054', borderColor: '#D0D5DD !important' }}
              >
                Cancel Upload
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleClose}
                  variant="outlined"
                  sx={{ mr: '12px', color: '#344054', borderColor: '#D0D5DD !important' }}
                >
                  Cancel
                </Button>
                <Button disabled={!recordedVideoBlob} variant="contained" onClick={submitVideo}>
                  Submit Welcome Video
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
