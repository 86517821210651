import { FC, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { TopBar } from '../../common/components/topBar/TopBar';
import { SocialVerseStrings } from '../../common/localization/en';
import { SVButton } from './CommonComponents/CommonComponents';
import { useSocialVerseStyles } from './SocialVerses.style';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../common/constants/routes';
import { SocialVerseCard } from './SocialVerseCard/SocialVerseCard';
import { Add } from '@mui/icons-material';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  deleteSocialVerseById,
  getAllSocialVerse,
  getSocialVerseById,
  GetVideosBySocialVerseId,
} from '../../store/slices/socialVerseSlice';
import { useToasts } from 'react-toast-notifications';
import { SpinnerContainer } from '../../common/components/table/CommonTable.style';
import { Spinner } from '../../common/assets/Spinner';
import { SVModalDelete } from './SVModals/SVModalDelete';
import { DeleteIconCircle } from '../../common/assets/newDesign/SocialVerse/DeleteIconCircle';
import { CreateSocialvModal } from './SVModals/CreateSocialvModal';
import { NameSocialvModal } from './SVModals/NameSocialvModal';
import { TypeSocialVerse } from '../../common/constants/constants';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../common/constants/events/socialVerseEvents';
import { NoSocialVerses } from './NoSocialVerses';
import { getVideos } from '../../store/slices/videosSlice';
import SocialVerseLayoutSwitch, {
  LayoutType,
} from './SocialVerseLayoutSwitch/SocialVerseLayoutSwitch';
import Select from '../../common/components/Select/Select';
import { SocialVerseAllApiModel } from '../../api/models/socialVerse';
import { useClientType } from '../../services/hooks/useClientType';

export const SocialVersePage: FC = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const { trackEvent } = useTrackEvent();

  const dispatch = useAppDispatch();
  const classes = useSocialVerseStyles();
  const venueId = useTypedSelector((state) => state.venue.venue.id);
  const { socialVerseAll: socialVerseCards, isLoading } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );
  const { size, page, sort, search, totalItems } = useTypedSelector((state) => state.videos);
  const [showPopUpDelete, setShowPopUpDelete] = useState({
    id: '',
    isOpen: false,
  });
  const { isHealthCare } = useClientType();

  const [isCreateSocialvModalOpen, setIsCreateSocialvModalOpen] = useState(false);
  const [isNameSocialvModalOpen, setIsNameSocialvModalOpen] = useState(false);
  const [newSocialvViewMode, setNewSocialvViewMode] = useState(TypeSocialVerse.Cards);
  const [activeLayout, setActiveLayout] = useState(LayoutType.List);

  const [sortBy, setSortBy] = useState('updatedDesc');
  const [filterBy, setFilterBy] = useState('none');

  const handleSortByChange = (value: string) => {
    setSortBy(value);
  };

  const handleFilterByChange = (value: string) => {
    setFilterBy(value);
  };

  useEffect(() => {
    trackEvent(SocialVerseEventNames.socialVersePageLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    venueId && dispatch(getAllSocialVerse({ venueId: venueId, withVideos: true }));
  }, [venueId, dispatch]);
  useEffect(() => {
    dispatch(
      getVideos({
        venueId: venueId,
        pageable: { page: page, size: 2000, sort: sort },
        search: search,
      }),
    );
  }, [dispatch, page, size, sort, venueId, search]);

  const handleCreateNewSV = () => {
    setTimeout(() => {
      setIsCreateSocialvModalOpen(true);
    }, 0);
  };
  const handleDeleteCard = () => {
    if (showPopUpDelete.id) {
      setShowPopUpDelete((prev) => ({ ...prev, isOpen: !prev.isOpen, id: '' }));
      const deletedCard = socialVerseCards?.find((card) => {
        return card.id === showPopUpDelete.id;
      });
      dispatch(deleteSocialVerseById({ id: showPopUpDelete.id }))
        .then(() => {
          addToast(SocialVerseStrings.Deleted.replace('<Name>', deletedCard?.name || ''), {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        })
        .catch(() => {
          addToast(SocialVerseStrings.ErrorDeletingVideo, {
            appearance: 'error',
          });
        });
      toggleModal();
      trackEvent(SocialVerseEventNames.confirmDelete, { socialVerseId: showPopUpDelete.id });
    }
  };
  const canRender = () => {
    if (socialVerseCards) {
      return socialVerseCards.length > 0 ? true : false;
    }
  };
  const handleAddVideos = async (id: string) => {
    await Promise.race([
      dispatch(getSocialVerseById({ id })),
      dispatch(GetVideosBySocialVerseId({ id })),
    ]);
    history.push(`${WebsiteRoutes.SocialVerseAddVideos}/${id}`);
  };

  const onSelectSocialvViewMode = (viewMode: TypeSocialVerse) => {
    setNewSocialvViewMode(viewMode);
    setIsCreateSocialvModalOpen(false);
    setIsNameSocialvModalOpen(true);
  };

  const handleDeleteModalToggle = (id: string) => {
    setShowPopUpDelete((prev) => ({ ...prev, isOpen: !prev.isOpen, id }));
  };
  const toggleModal = () => {
    setShowPopUpDelete((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleLayoutChange = () => {
    if (activeLayout === LayoutType.List) {
      setActiveLayout(LayoutType.Grid);
    } else {
      setActiveLayout(LayoutType.List);
    }
  };

  const getSocialVerseSorting = (a: SocialVerseAllApiModel, b: SocialVerseAllApiModel) => {
    if (sortBy === 'updatedDesc') {
      return a.updatedAt > b.updatedAt ? -1 : 1;
    }

    if (sortBy === 'updatedAsc') {
      return a.updatedAt > b.updatedAt ? 1 : -1;
    }

    if (sortBy === 'createdDesc') {
      return a.createdAt > b.createdAt ? -1 : 1;
    }

    if (sortBy === 'createdAsc') {
      return a.createdAt > b.createdAt ? 1 : -1;
    }

    if (sortBy === 'videosDesc') {
      return a.numVideos > b.numVideos ? -1 : 1;
    }

    if (sortBy === 'videosAsc') {
      return a.numVideos > b.numVideos ? 1 : -1;
    }

    if (sortBy === 'nameDesc') {
      return a.name > b.name ? 1 : -1;
    }

    if (sortBy === 'nameAsc') {
      return a.name > b.name ? -1 : 1;
    }

    return 0;
  };

  const renderSocialVerseCards = () => {
    return (
      <Box
        className={classes.socialverseWrapper}
        style={{ display: activeLayout === LayoutType.List ? 'block' : 'grid' }}
      >
        {socialVerseCards &&
          socialVerseCards.length > 0 &&
          socialVerseCards
            .slice()
            .filter((sv) => {
              if (filterBy !== 'none') {
                return sv.viewMode === filterBy;
              }

              return sv;
            })
            .sort(getSocialVerseSorting)
            .map((item, index) => (
              <SocialVerseCard
                key={item.id}
                socialverse={item}
                handleAddVideo={handleAddVideos}
                handleDeleteModalToggle={handleDeleteModalToggle}
                cardIndex={index}
                isGridLayout={activeLayout === LayoutType.Grid}
              />
            ))}
      </Box>
    );
  };

  const sortByValues = [
    { value: 'updatedDesc', name: 'Date Updated (latest)' },
    { value: 'updatedAsc', name: 'Date Updated (earliest)' },
    { value: 'createdDesc', name: 'Date Created (latest)' },
    { value: 'createdAsc', name: 'Date Created (earliest)' },
    { value: 'videosDesc', name: 'Number of Videos (most)' },
    { value: 'videosAsc', name: 'Number of Videos (least)' },
    { value: 'nameDesc', name: 'Name (A to Z)' },
    { value: 'nameAsc', name: 'Name (Z to A)' },
  ];

  const filterByValues = [
    { value: 'none', name: 'None' },
    { value: 'blocks', name: 'Classic Cards' },
    { value: 'perspective', name: 'Perspective' },
    { value: 'grid', name: 'Grids' },
    { value: 'educational', name: 'Watch & Learn' },
    { value: 'spheres', name: 'Spheres' },
  ];

  return (
    <Grid container>
      <CreateSocialvModal
        isOpen={isCreateSocialvModalOpen}
        onClose={() => {
          setIsCreateSocialvModalOpen(false);
        }}
        onSelectSocialvViewMode={onSelectSocialvViewMode}
        totalItems={totalItems}
      />
      <NameSocialvModal
        isModalOpen={isNameSocialvModalOpen}
        onClose={() => {
          setIsNameSocialvModalOpen(false);
        }}
        venueId={venueId}
        viewMode={newSocialvViewMode}
        handleAddVideos={handleAddVideos}
      />
      <TopBar sectionTitle={SocialVerseStrings.SocialVerse} />
      <Grid container className={classes.contentWrapper}>
        <Typography className={classes.showcaseTitle}>
          {isHealthCare ? SocialVerseStrings.ShowcaseTitleTrublu : SocialVerseStrings.ShowcaseTitle}
        </Typography>
        <Grid className={classes.headerWrapper}>
          <Select
            width="253px"
            onChange={handleSortByChange}
            options={sortByValues}
            value={sortBy}
            margin="0 20px 0 0"
            title={SocialVerseStrings.SortBy}
          />
          <Select
            width="253px"
            onChange={handleFilterByChange}
            options={filterByValues}
            value={filterBy}
            title={SocialVerseStrings.FilterBy}
            margin="0 30px 0 0"
          />
          <SocialVerseLayoutSwitch
            activeLayout={activeLayout}
            onLayoutChange={handleLayoutChange}
          />
          <Grid container item alignItems="center" justifyContent="flex-end">
            <SVButton
              className={classes.svButtonCreate}
              startIcon={<Add />}
              variant="contained"
              handleClick={() => {
                handleCreateNewSV();
                trackEvent(SocialVerseEventNames.createNewSocialVerse);
              }}
              id="create-sv-button"
            >
              {SocialVerseStrings.CreateButtonText}
            </SVButton>
          </Grid>
        </Grid>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner color="var(--spinnerColor)" />
          </SpinnerContainer>
        ) : canRender() ? (
          renderSocialVerseCards()
        ) : (
          <NoSocialVerses />
        )}
      </Grid>
      <SVModalDelete
        nameButton={SocialVerseStrings.Delete}
        openModal={showPopUpDelete.isOpen}
        toggleModal={toggleModal}
        onClickHandler={handleDeleteCard}
      >
        <DeleteIconCircle />
      </SVModalDelete>
    </Grid>
  );
};
