import { Activity, CampaignActivitiesTypes } from '../../api/models/campaigns';
import { RewardsPageStrings } from '../localization/en';

export const DEFAULT_BRAND_COLOR = '#45A1F8';
export const DEFAULT_SV_GLOW_COLOR = '#D9D9D9';

export const DEFAULT_REWARD_TITLE = RewardsPageStrings.DEFAULT_REWARD_TITLE;
export const DEFAULT_REWARD_TITLE_TRUBLU = RewardsPageStrings.DEFAULT_REWARD_TITLE_TRUBLU;

export const DEFAULT_REWARD_SUBTITLE = RewardsPageStrings.DEFAULT_REWARD_SUBTITLE;
export const DEFAULT_REWARD_SUBTITLE_TRUBLU = RewardsPageStrings.DEFAULT_REWARD_SUBTITLE_TRUBLU;

export const DEFAULT_STORY_CTA_BUTTON = 'Visit Us!';

export const REWARD_CAMPAIGN_MAX_LENGTH = {
  name: 42,
  title: 32,
  action: 160,
};

export const activitiesForVideoCreation = [
  { activityType: CampaignActivitiesTypes.CreateVideo, points: 100, status: true },
  { activityType: CampaignActivitiesTypes.Clicks, points: 10, status: false },
  { activityType: CampaignActivitiesTypes.CallToAction, points: 50, status: false },
  { activityType: CampaignActivitiesTypes.Conversion, points: 50, status: false },
  { activityType: CampaignActivitiesTypes.BonusPoints, points: 100, status: false },
];

export const activitiesForShowCasingCustomerVideos = [
  { activityType: CampaignActivitiesTypes.CreateVideo, points: 80, status: true },
  { activityType: CampaignActivitiesTypes.Clicks, points: 20, status: true },
  { activityType: CampaignActivitiesTypes.CallToAction, points: 50, status: false },
  { activityType: CampaignActivitiesTypes.Conversion, points: 50, status: false },
  { activityType: CampaignActivitiesTypes.BonusPoints, points: 100, status: false },
];

export const activitiesForSharing = [
  { activityType: CampaignActivitiesTypes.CreateVideo, points: 70, status: true },
  { activityType: CampaignActivitiesTypes.Clicks, points: 20, status: true },
  { activityType: CampaignActivitiesTypes.CallToAction, points: 10, status: true },
  { activityType: CampaignActivitiesTypes.Conversion, points: 50, status: false },
  { activityType: CampaignActivitiesTypes.BonusPoints, points: 100, status: false },
];

export const activitiesForMarketing = [
  { activityType: CampaignActivitiesTypes.CreateVideo, points: 100, status: true },
  { activityType: CampaignActivitiesTypes.Clicks, points: 10, status: true },
  { activityType: CampaignActivitiesTypes.CallToAction, points: 10, status: true },
];
export const activitiesForWebsiteSEO = [
  { activityType: CampaignActivitiesTypes.CreateVideo, points: 80, status: true },
  { activityType: CampaignActivitiesTypes.Clicks, points: 20, status: true },
  { activityType: CampaignActivitiesTypes.CallToAction, points: 10, status: true },
];
export const activitiesForFriendsAndFamily = [
  { activityType: CampaignActivitiesTypes.CreateVideo, points: 75, status: true },
  { activityType: CampaignActivitiesTypes.Clicks, points: 15, status: true },
  { activityType: CampaignActivitiesTypes.CallToAction, points: 15, status: true },
];

export const defaultNewCampaignActivities: Activity[] = [
  {
    activityType: CampaignActivitiesTypes.CreateVideo,
    status: true,
    points: 25,
  },
  {
    activityType: CampaignActivitiesTypes.CallToAction,
    status: true,
    points: 50,
  },
  {
    activityType: CampaignActivitiesTypes.Clicks,
    status: true,
    points: 25,
  },
  {
    activityType: CampaignActivitiesTypes.Conversion,
    status: false,
    points: 100,
  },
  {
    activityType: CampaignActivitiesTypes.BonusPoints,
    status: false,
    points: 100,
  },
];
