import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { linkOnly: boolean }>(() => ({
  container: ({ linkOnly }) => ({
    width: '520px',
    margin: '0 auto',
    paddingBottom: '30px',
    display: linkOnly ? 'flex' : 'block',
    flexDirection: 'column',
    alignItems: 'center',
  }),
  matchContainer: {
    width: '540px',
    background: 'yellow',
    margin: '35px auto 0',
    paddingBottom: '145px',
  },
  dropWrapper: {
    width: '100%',
    height: '390px',
    borderRadius: '20px',
    border: '1px solid #DCDBE4',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  divider: {
    borderBottom: '1px solid #DCDBE4',
    marginTop: '23px',
    marginBottom: '47px',
  },
  helpText: ({ linkOnly }) => ({
    fontSize: linkOnly ? '14px' : '16px',
    lineHeight: linkOnly ? '20px' : '28px',
    color: linkOnly ? '#475467' : '#8B89A0',
  }),
  templateLink: ({ linkOnly }) => ({
    fontSize: '14px',
    lineHeight: '26px',
    textDecoration: 'underline',
    fontWeight: '400',
    color: linkOnly ? '#4F96FF' : '#085DFF',
  }),
}));
