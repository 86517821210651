import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    minHeight: 'fit-content',
  },
  description: {
    fontSize: '20px',
    lineHeight: '30px',
    paddingTop: '60px',
    width: '520px',
    marginLeft: '90px',
    marginBottom: '95px',
  },
  divider: {
    borderBottom: '1px solid #DCDBE4',
    marginBottom: '25px',
  },
  accordionWrapper: {
    padding: '0 80px 0 40px',
  },
}));
