import { Box, Button, IconButton, Popover, Typography } from '@mui/material';
import { FC, SyntheticEvent, useRef, useState } from 'react';
import { useStyles } from './SyncedBusiness.styles';
import { DistributorsStrings } from '../../../localization/en';
import { DisconnectModal } from '../DisconnectModal/DisconnectModal';
import { useModal } from '../../../../hooks/useModal';
import { DotsIcon } from '../../../assets/DotsIcon';
import { ConnectedAccount } from '../../../../store/slices/gbpSlice';

interface Props {
  connectedAccount: ConnectedAccount;
  onDisconnect: () => void;
}

export const SyncedBusiness: FC<Props> = ({ onDisconnect, connectedAccount }) => {
  const classes = useStyles();
  const { isOpen, open: openDisconnectModal, close: closeDisconnectModal } = useModal();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const {
    locationId,
    title: businessName,
    websiteUri,
    storefrontAddress: { addressLines, locality, regionCode, postalCode },
  } = connectedAccount;

  const location = `${addressLines.join(', ')},  ${locality}, ${regionCode} ${postalCode}`;

  const handleCloseMenu = (event: Event | SyntheticEvent) => {
    const button = menuButtonRef.current;
    if (button && button.contains(event.target as HTMLElement)) {
      return;
    }
    setIsMenuOpen(false);
  };

  return (
    <Box
      className={classes.container}
      sx={{ zoom: 'calc(1 / 0.9)' }} // revert global zoom property to fix popover position
    >
      <IconButton
        aria-describedby="popper-menu"
        className={classes.menuButton}
        ref={menuButtonRef}
        onClick={() => {
          setIsMenuOpen((prevState) => !prevState);
        }}
      >
        <DotsIcon />
      </IconButton>
      <Popover
        open={isMenuOpen}
        id="popper-menu"
        anchorEl={menuButtonRef.current}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            boxShadow:
              '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
          },
        }}
      >
        <Button
          className={classes.disconnectButton}
          onClick={() => {
            setIsMenuOpen(false);
            openDisconnectModal();
          }}
        >
          {DistributorsStrings.Disconnect}
        </Button>
      </Popover>
      <DisconnectModal isOpen={isOpen} onClose={closeDisconnectModal} onConfirm={onDisconnect} />
      <Box className={classes.detailsWrapper}>
        <Typography className={classes.status}>{DistributorsStrings.Connected}</Typography>
        <Typography className={classes.businessName}>{businessName}</Typography>
        <Typography className={classes.location}>{location}</Typography>

        <Typography className={classes.website}>{websiteUri}</Typography>
        <Typography className={classes.businessNumber}>{locationId}</Typography>
      </Box>
    </Box>
  );
};
