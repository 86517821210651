import { Box, TextField, TextFieldProps, Typography } from '@mui/material';
import { format } from 'date-fns';
import React, { FC, useState } from 'react';
import { PricingScreenStrings, SingUpPage } from '../../../common/localization/en';
import { theme } from '../../../theme';
import { DocumentIcon } from '../../../common/assets/newDesign/DocumentIcon';

interface SignatureProps {
  signature: string;
  clientName: string;
  date?: string;
  serviceAgreementLink?: string;
  showSignHereAction?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: TextFieldProps['onBlur'];
  error: string | undefined;
}

const MAIN_FONT = '"Bradley Hand"';
const FALLBACK_FONT = 'Handlee';
// const FONT_FAMILY_2 = 'Brush Script MT';
// const FONT_FAMILY_3 = 'Sacramento';

const DEFAULT_LABEL = 'Please enter your name exactly as it appears below';

export const Signature: FC<SignatureProps> = ({
  signature,
  clientName,
  onChange,
  onBlur,
  date,
  serviceAgreementLink,
  showSignHereAction = true,
  error,
}) => {
  const isError = !!error;

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: '#FBFBFB',
        p: '24px 10px',
        borderRadius: '10px',
        mt: '15px',
        position: 'relative',
      }}
    >
      {showSignHereAction && (
        <>
          <Box
            sx={{
              position: 'absolute',
              top: '30px',
              left: '-77px',
              padding: '4px 8px 4px 10px',
              backgroundColor: '#FF0000',
              color: '#FFFFFF',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '24px',
              letterSpacing: '0.02em',
            }}
          >
            {SingUpPage.SignHere}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: '30px',
              left: '-6px',
              width: 0,
              height: 0,
              borderTop: '16px solid transparent',
              borderBottom: '16px solid transparent',
              borderLeft: '16px solid #FF0000',
            }}
          ></Box>
        </>
      )}
      <TextField
        fullWidth
        name="signature"
        disabled={false}
        inputProps={{
          sx: {
            fontFamily: [MAIN_FONT, FALLBACK_FONT].join(', '),
            backgroundColor: '#fff',
            fontWeight: 800,
            fontSize: '20px',
            lineHeight: '20px',
            letterSpacing: '0.02em',
            '&.Mui-disabled': {
              color: `${theme.palette.common.black} !important`,
              textFillColor: `${theme.palette.common.black} !important`,
            },
          },
        }}
        FormHelperTextProps={{
          sx: { textAlign: isError ? 'start' : 'center' },
        }}
        onChange={onChange}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur?.(e);
        }}
        value={signature}
        error={isError}
      />
      {(isFocused || isError) && (
        <Typography
          mt="4px"
          fontSize="12px"
          color={isError ? theme.palette.error.main : theme.palette.primary.main}
        >
          {isError ? error : DEFAULT_LABEL}
        </Typography>
      )}

      <Box sx={{ my: '10px', display: 'flex', align: 'center' }}>
        <Typography
          sx={{
            mr: '10px',
            lineHeight: 2.7,
            fontWeight: 400,
            fontSize: '12px',

            letterSpacing: '0.02em',
          }}
        >
          {`${SingUpPage.Name}:`}
        </Typography>
        <TextField
          variant="standard"
          fullWidth
          disabled
          sx={{ borderBottom: '1px solid #B9B9B9' }}
          inputProps={{
            sx: {
              '&.Mui-disabled': {
                color: `${theme.palette.common.black}`,
                textFillColor: `${theme.palette.common.black}`,
              },
            },
          }}
          onChange={onChange}
          value={clientName}
        />
      </Box>
      <Box sx={{ mb: '10px', display: 'flex', align: 'center' }}>
        <Typography
          sx={{
            mr: '16px',
            lineHeight: 2.7,
            fontWeight: 400,
            fontSize: '12px',
            letterSpacing: '0.02em',
          }}
        >
          {`${SingUpPage.Date}:`}
        </Typography>
        <TextField
          variant="standard"
          fullWidth
          disabled
          sx={{ borderBottom: '1px solid #B9B9B9' }}
          inputProps={{
            sx: {
              '&.Mui-disabled': {
                color: `${theme.palette.common.black}`,
                textFillColor: `${theme.palette.common.black}`,
              },
            },
          }}
          value={date ? date : format(new Date(), 'MM/dd/yyyy')}
        />
      </Box>

      {serviceAgreementLink && (
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <DocumentIcon />
          <a
            href={serviceAgreementLink}
            rel="noreferrer"
            target="_blank"
            style={{
              fontWeight: '400',
              fontSize: '12px',
              textDecoration: 'underline',
              color: '#5242EA',
              marginLeft: '5px',
            }}
          >
            {PricingScreenStrings.ViewServiceAgreement}
          </a>
        </Box>
      )}
    </Box>
  );
};
