export const CreatorIcon = () => {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2424_70763"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="43"
        height="43"
      >
        <circle cx="21.498" cy="21.5" r="20.7558" fill="#EEECFC" />
      </mask>
      <g mask="url(#mask0_2424_70763)">
        <circle cx="21.498" cy="21.5" r="20.7558" fill="#EEECFC" />
        <path
          d="M17.0508 0.744141L17.0508 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.15625 0.744141L8.15625 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.9453 0.744141L25.9453 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.8398 0.744141L34.8398 42.2557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.9423 34.9996V12C32.9423 8.68629 30.256 6 26.9423 6H16.2695C12.9558 6 10.2695 8.6863 10.2695 12V34.9996"
          stroke="#5242EA"
        />
        <path
          d="M20.5195 7.99219H24.7377"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="18.8642" cy="7.99311" r="0.395455" fill="#8B89A0" />
        <path
          d="M28.0092 9.86694H15.2031C13.5463 9.86694 12.2031 11.2101 12.2031 12.8669V34.9999H31.0092V12.8669C31.0092 11.2101 29.6661 9.86694 28.0092 9.86694Z"
          fill="white"
        />
        <path
          d="M31.0092 34.9999V12.8669C31.0092 11.2101 29.6661 9.86694 28.0092 9.86694H15.2031C13.5463 9.86694 12.2031 11.2101 12.2031 12.8669V34.9999"
          stroke="#5242EA"
        />
        <rect x="14.793" y="27.9961" width="13.7091" height="3.69091" rx="1.84546" fill="#D4D0FA" />
        <rect x="14.793" y="13.7598" width="13.7091" height="12.6546" rx="4" fill="#D4D0FA" />
        <path
          d="M23.0742 20.0376C23.4076 20.23 23.4076 20.7112 23.0742 20.9036L20.9269 22.1433C20.5936 22.3358 20.1769 22.0952 20.1769 21.7103L20.1769 19.2309C20.1769 18.846 20.5936 18.6054 20.9269 18.7978L23.0742 20.0376Z"
          fill="#5242EA"
          stroke="#5242EA"
        />
      </g>
    </svg>
  );
};
