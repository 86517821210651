import { useEffect, useRef } from 'react';

interface UseIdleTimerOptions {
  timeoutMs: number;
  onIdle: () => void;
}

export const useIdleTimer = ({ timeoutMs, onIdle }: UseIdleTimerOptions) => {
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const eventHandler = () => {
      // clear idle timer
      clearTimeout(timerRef.current);

      // set new idle timer
      timerRef.current = setTimeout(() => {
        onIdle();
      }, timeoutMs);
    };

    window.addEventListener('mousemove', eventHandler);

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('mousemove', eventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
