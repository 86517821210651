import React, { FC, useCallback } from 'react';
import { useStyles } from './KeywordsDropdown.styles';
import { MenuItem, Select } from '@mui/material';
import { ArrowBottomIcon } from '../../assets/newDesign/ArrowBottomIcon';
import { KeywordPageStrings, KeywordsStrings } from '../../localization/en';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { updateKeywords } from '../../../store/slices/keywordsSlice';
import { useToasts } from 'react-toast-notifications';
import { AddKeywordModal } from '../AddKeywordModal/AddKeywordModal';
import { KeywordApiModel } from '../../../api/models/keywords';
import { useModal } from '../../../hooks/useModal';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';

interface Props {
  onVideoKeywordsClick?: () => void;
}

export const KeywordsDropdown: FC<Props> = ({ onVideoKeywordsClick }) => {
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const classes = useStyles();
  const { trackEvent } = useTrackEvent();

  const {
    venue: { id },
  } = useTypedSelector((state) => state.venue);
  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);

  const {
    isOpen: isKeywordModalOpen,
    open: openKeywordModal,
    close: closeKeywordModal,
  } = useModal();

  const saveKeywords = useCallback(
    (keywords: KeywordApiModel[]) => {
      trackEvent(VideosEventNames.saveKeywordButtonClicked, { keywords: keywords });
      dispatch(
        updateKeywords({
          venueId: id,
          keywords,
        }),
      ).then(() => {
        addToast(KeywordPageStrings.updateMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      });
    },
    [addToast, dispatch, id, trackEvent],
  );

  const handleRemoveKeyword = useCallback(
    (keyword: string) => {
      trackEvent(VideosEventNames.deleteKeywordButtonClicked, { keyword });
      const keywordsToSave = keywords.filter((curKeyword) => curKeyword.keyword !== keyword);
      saveKeywords(keywordsToSave);
    },
    [keywords, saveKeywords, trackEvent],
  );

  return (
    <>
      <AddKeywordModal
        isOpen={isKeywordModalOpen}
        onClose={() => {
          closeKeywordModal();
          trackEvent(VideosEventNames.buttonCloseKeywordModalClicked);
        }}
        onKeywordTyped={(keyword) => {
          trackEvent(VideosEventNames.addKeywordTyped, {
            value: keyword,
          });
        }}
        onSaveKeywords={saveKeywords}
      />
      <Select
        className={classes.select}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            className: classes.menuWrapper,
          },
        }}
        disableUnderline={true}
        IconComponent={ArrowBottomIcon}
        value={1}
        displayEmpty={false}
        onChange={() => {}}
        onClick={() => onVideoKeywordsClick?.()}
      >
        <MenuItem
          className={classes.selectItem}
          value={1}
          sx={{
            display: 'none',
          }}
        >
          {KeywordsStrings.VideoKeywords}
        </MenuItem>
        <MenuItem
          className={clsx(classes.selectItem, classes.itemAdd)}
          onClick={() => {
            openKeywordModal();
            trackEvent(VideosEventNames.createKeywordButtonClicked);
          }}
        >
          <span className={classes.plusIcon}>+ </span> {KeywordsStrings.CreateNewKeyword}
        </MenuItem>
        {keywords.map((keyword) => (
          <MenuItem className={clsx(classes.selectItem, classes.keywordItem)} key={keyword.keyword}>
            <span style={{ textOverflow: 'ellipsis', width: '220px', overflow: 'hidden' }}>
              {keyword.keyword}
            </span>
            <CloseIcon
              className={classes.iconDelete}
              onClick={() => handleRemoveKeyword(keyword.keyword)}
            />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
