import React, { useState, useEffect, useCallback } from 'react';
import { Button, Theme } from '@mui/material';

import {
  RewardsPageStrings,
  CommonStrings,
  RewardsLeaderboardDetails,
  RewardsStrings,
  RewardStrings,
} from '../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';

import { RewardsWizard } from '../rewardsWizard/RewardsWizard';

import { Activity, CampaignsApiModel } from '../../../api/models/campaigns';
import {
  postIncentiveCampaign,
  setIsCreationCampaignModal,
} from '../../../store/slices/campaignsSlice';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getProperties, patchVenueProperties } from '../../../store/slices/venueSlice';

import { EditCampaignModal } from '../../../common/components/EditCampaignModal/EditCampaignModal';
import { СreateIncentiveModal } from '../createIncentive/createIncentiveModal';
import { UpdateWebappCardValues } from '../updateIncentive/updateIncentiveModal';
import { IncevtiveCreationValues, Tabs } from '../createIncentive/createIncentiveModal.helper';
import { UpdatePointsModal } from './UpdatePointsModal';
import { VenueProperties } from '../../../api/models/venue';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { useRewardFulfillmentApiCalls } from '../../webappSettings/Incentives/useRewardFulfillmentApiCalls';
import { RewardFulfillmentMethod } from '../../../api/models/rewardFulfillment';

interface Values {
  imageUrl: string;
  name: string;
  action: string;
  title: string;
}

interface LeaderBoardButtonProps {
  isRewardPage?: boolean;
  editedCampaign: null | CampaignsApiModel;
  setEditedCampaign: (campaign: null | CampaignsApiModel) => void;
  onOpenEndCampaignModal?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  innerLeft: {
    maxWidth: '370px',
    marginRight: '50px',
  },
  createCampaignBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 25px',
    borderRadius: '100px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  image: {
    width: '100px',
  },
  icon: {
    width: '24px',
  },
  description: {
    marginTop: '25px !important',
  },
  label: {
    marginTop: '50px',
    textAlign: 'center',
    marginBottom: '10px',
  },
  styledButton: {
    width: 'inherit',
    color: theme.palette.common.white,
    borderRadius: '50px',
    fontSize: '14px',
    lineHeight: '26px',
  },
  customizeButton: {
    marginTop: '10px',
    width: 'inherit',
    color: theme.palette.primary.main,
    borderRadius: '50px',
    fontSize: '14px',
    lineHeight: '26px',
  },
  cancelButton: {
    marginTop: '10px',
    width: 'inherit',
    color: theme.palette.primary.main,
    borderRadius: '50px',
    fontSize: '14px',
    lineHeight: '26px',
  },
}));

export const DEFAULT_INCENTIVE_IMAGE_URL =
  'https://storage.googleapis.com/sv-dev-assets/14eef41f-8001-4c43-9a02-838dfecdf837/7b92f74f-2ff5-47ab-844a-838ff8550b12.jpg';

export const LeaderBoardButton: React.FC<LeaderBoardButtonProps> = ({
  editedCampaign,
  setEditedCampaign,
  isRewardPage,
  onOpenEndCampaignModal,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const venueId = useTypedSelector((state) => state.venue.venue.id);

  const { isCreationCampaignModal, activeCampaign, error } = useTypedSelector(
    (state) => state.campaigns,
  );
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const { config } = useTypedSelector((store) => store.rewardFulfillment);

  const { value: defaultIncentiveConfig } = useVenueProperty<
    VenueProperties['webapp.incentive.config']
  >({
    property: 'webapp.incentive.config',
  });

  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [isCreationModal, setIsCreationModal] = useState(false);
  const [isUpdatePointsModal, setIsUpdatePointsModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.RewardCampaign);

  const toggleCreationModal = useCallback(() => {
    setIsCreationModal(!isCreationModal);

    if (editedCampaign) {
      setEditedCampaign(null);
    }
  }, [editedCampaign, isCreationModal, setEditedCampaign]);

  const { postRewardFulfillmentConfig, getRewardFulfillmentConfig } =
    useRewardFulfillmentApiCalls();

  useEffect(() => {
    getRewardFulfillmentConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreationCampaignModal) {
      dispatch(setIsCreationCampaignModal(false));
      toggleCreationModal();
    }
  }, [dispatch, isCreationCampaignModal, toggleCreationModal]);

  useEffect(() => {
    if (!!editedCampaign) {
      toggleCreationModal();
    }
  }, [editedCampaign, toggleCreationModal]);

  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  const handleOpenNewCampaignModel = () => {
    const isAtLeastOneActivityEnabled = !!defaultIncentiveConfig?.activities?.filter(
      (item) => item.status,
    ).length;
    if (isAtLeastOneActivityEnabled) {
      toggleConfirmationModal();
    } else {
      setIsUpdatePointsModal(true);
    }
  };

  const handleCreateCampaign = (values: Values, activities: Activity[]) => {
    const data = {
      action: values.action || defaultIncentiveConfig?.action,
      title: values.title || defaultIncentiveConfig?.title,
    };

    if (
      defaultIncentiveConfig &&
      (defaultIncentiveConfig.imageUrl || DEFAULT_INCENTIVE_IMAGE_URL) &&
      defaultIncentiveConfig.points &&
      defaultIncentiveConfig.activities
    ) {
      dispatch(
        postIncentiveCampaign({
          venueId,
          ...{
            name: values.name,
            isLocked: false,
            title: values.title,
            subtitle: values.action,
            imageUrl: values.imageUrl,
            couponEnabled: values.imageUrl ? false : true,
            points: defaultIncentiveConfig.points,
            rewardTitle: values.title,
            rewardDescription: values.action,
            isNullReward: selectedTab === Tabs.NoReward,
          },
          activities: activities,
        }),
      ).then(() => {
        addToast(RewardsStrings.createCampaignMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      });
      dispatch(
        patchVenueProperties({
          id: venueId,
          update: {
            name: 'webapp.incentive.config',
            value: { ...defaultIncentiveConfig, ...values, ...data },
          },
          alert: false,
        }),
      );

      handleUpdateWebappCard({
        title: values.title,
        subtitle: values.action,
      });

      if (selectedTab === 'No Incentives') {
        postRewardFulfillmentConfig({
          ...config,
          method: RewardFulfillmentMethod.MANUAL_SMS,
        });
      }
    } else {
      history.push('/settings/incentive');
    }
  };
  // here we updating venueProperties with new action and title. so we save title,
  // subtitle in the venueProperties and defaultIncentiveConfig, but not in the active incentive campaign
  const handleUpdateWebappCard = (values: UpdateWebappCardValues) => {
    if (values.subtitle && values.title) {
      dispatch(
        patchVenueProperties({
          id: venueId,
          update: {
            name: 'webapp.cards-welcome',
            value: {
              ...venueProperties?.['webapp.cards-welcome'],
              title: values.title,
              subtitle: values.subtitle,
            },
          },
        }),
      ).then(() => {
        addToast(RewardsStrings.RewardUpdateMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      });
    }
  };

  useEffect(() => {
    if (activeCampaign && isConfirmationModal) {
      if (!error) {
        setIsConfirmationModal(false);
        addToast(RewardsPageStrings.CampaignCreated, { appearance: 'success' });
      } else {
        addToast(CommonStrings.OnError, { appearance: 'error' });
      }
    }
  }, [activeCampaign, isConfirmationModal, addToast, error]);

  useEffect(() => {
    dispatch(getProperties({ id: venueId }));
  }, [dispatch, venueId]);

  const initialParams: IncevtiveCreationValues = {
    imageUrl: '',
    name: RewardStrings.DefaultIncentiveCampaignName,
    action:
      selectedTab === Tabs.RewardCampaign
        ? RewardStrings.DefaultRewardSubtitle
        : RewardStrings.IsNullRewardDefaultDescription,
    title:
      selectedTab === Tabs.RewardCampaign
        ? RewardsPageStrings.DEFAULT_REWARD_TITLE
        : RewardStrings.IsNullRewardDefaultTitle,
    activities: [],
  };
  if (activeCampaign) {
    initialParams.activities = activeCampaign.activities;
  }
  if (!activeCampaign && defaultIncentiveConfig) {
    initialParams.activities = defaultIncentiveConfig!.activities;
  }

  const handleCancelUpdatesPointModal = () => {
    setIsUpdatePointsModal(false);
  };

  const handleConfirmUpdatePointsModal = () => {
    history.push('/settings/incentive');
  };

  return (
    <>
      <>
        {isRewardPage ? (
          <>
            <Button
              variant="contained"
              className={classes.createCampaignBtn}
              onClick={toggleConfirmationModal}
            >
              Yes, Create New Incentive Campaign
            </Button>
          </>
        ) : (
          <Button
            style={{ whiteSpace: 'nowrap', marginBottom: '20px' }}
            className={classes.createCampaignBtn}
            color="primary"
            startIcon={<img src={'/leaf.svg'} alt="" className={classes.icon} />}
            onClick={handleOpenNewCampaignModel}
            disabled={activeCampaign && activeCampaign?.id ? true : false}
            id="create-campaign-btn"
          >
            {RewardsLeaderboardDetails.CTANewLeaderboard}
          </Button>
        )}
      </>

      <UpdatePointsModal
        isOpen={isUpdatePointsModal}
        onCancel={handleCancelUpdatesPointModal}
        onConfirm={handleConfirmUpdatePointsModal}
      />

      <СreateIncentiveModal
        isOpen={isConfirmationModal}
        onSave={handleCreateCampaign}
        onCloseCreationModal={toggleConfirmationModal}
        initialParams={initialParams}
        selectedTab={selectedTab}
        setSelectedTab={(tab: Tabs) => setSelectedTab(tab)}
      />

      <EditCampaignModal
        isCreationModal={isCreationModal}
        toggleCreationModal={toggleCreationModal}
      >
        <RewardsWizard
          height="auto"
          onCloseCreationModal={toggleCreationModal}
          onOpenEndCampaignModal={onOpenEndCampaignModal}
        />
      </EditCampaignModal>
    </>
  );
};
