import { Box, Grid } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SalesDetailsModal } from '../../../common/components/NewDetailsModal/SalesDetailsModal';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { CommonTable } from '../../../common/components/table/CommonTable';

import { CampaignInvitesStrings } from '../../../common/localization/en';
import { pendingUsersTableContent } from '../../../services/helpers/tableMappers';
import { useClientType } from '../../../services/hooks/useClientType';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  CampaignCountData,
  getCampaignUsers,
  goToSelectedPageExistingCampaignUsersTable,
  setExistingCampaignUsersTableSorting,
  updateExistingCampaignUsersTableSearch,
  updateExistingCampaignUsersTableSize,
} from '../../../store/slices/campaignsSlice';

import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { useStyles } from './PendingUsersTable.style';

interface Prop {
  countData: CampaignCountData;
  handleTrackEvent: IEventTracker;
}

export const PendingUsersTable: FC<Prop> = ({ countData, handleTrackEvent }) => {
  const styles = useStyles();
  const modal = useRef(null);
  const dispatch = useAppDispatch();
  const [selectedUserId, setSelectedUserId] = useState('');
  const [isUserSlideoutOpen, setIsUserSlideoutOpen] = useState(false);

  const { isTrubluClient } = useClientType();
  const { items, totalPages, totalItems, page, size, sort, search, isLoading } = useTypedSelector(
    (state) => state.campaigns.currentCampaignUsers,
  );
  const { id: campaignId } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(
      getCampaignUsers({
        search,
        id: campaignId,
        pageable: { page, size, sort },
        hasVideos: false,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, page, size, sort, search]);

  const userDataTableContents = pendingUsersTableContent({
    items,
    isTrubluClient,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }
    dispatch(goToSelectedPageExistingCampaignUsersTable(convertedTargetPage));
  };
  const onSort = (name: string) => {
    dispatch(setExistingCampaignUsersTableSorting(name));
  };
  const onClickRow = (id: string) => {
    setSelectedUserId(id);
    setIsUserSlideoutOpen(true);
    handleTrackEvent(RewardCampaignsEvents.RewardCampaginTableRowClick, { selectedUserId: id });
  };
  const changeSize = (size: number) => {
    dispatch(updateExistingCampaignUsersTableSize(size));
  };
  const close = () => {
    setIsUserSlideoutOpen(false);
    setSelectedUserId('');
  };

  return (
    <Box>
      <>
        <Box className={styles.container}>
          <Grid container className={styles.top}>
            <Box>
              <SearchBar
                entity={'campaigns'}
                updateSearch={updateExistingCampaignUsersTableSearch}
                adaptiveWidth={true}
                disabled={isLoading}
                placeholderText={`Search ${isTrubluClient ? 'Patient' : 'Creator'}`}
                borderColor={'#F2F4F7'}
              />
            </Box>
          </Grid>
          <Grid className={styles.table}>
            <CommonTable
              content={userDataTableContents}
              selectedRowId={selectedUserId}
              page={convertApiPageToFrontEndPage(page)}
              sort={sort}
              totalItems={totalItems}
              totalPages={totalPages}
              isLoading={isLoading}
              noContent={false}
              tablePadding="0"
              goToPage={onGoToPage}
              onSortHeaderClick={onSort}
              onClickRow={onClickRow}
              size={size}
              onSizeChange={changeSize}
              withSearchBar={true}
              labelRowsPerPage={CampaignInvitesStrings.View}
              roundedNavButtons={false}
              disableTopNav={true}
            />
          </Grid>
        </Box>
      </>
      <div ref={modal}>
        <SalesDetailsModal
          isOpen={isUserSlideoutOpen}
          userId={selectedUserId}
          handleClose={close}
          campaignId={campaignId}
          entity="campaigns"
        />
      </div>
    </Box>
  );
};
