import { alpha, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideoComponentStyles = makeStyles((theme: Theme) => ({
  TopGolfVideoCard: {
    width: 305,
    height: 225,
    marginLeft: 20,
  },
  VideoCard: {
    width: 198,
    height: 352,
  },
  actionArea: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  MouseOverIcon: {
    backgroundColor: alpha(theme.palette.common.white, 0.6),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  ChangeThumnailIcon: {
    position: 'absolute',
    bottom: '3.5%',
    right: '3.5%',
    width: 56,
    height: 56,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  ChangeThumnailIconOver: {
    backgroundColor: alpha(theme.palette.common.white, 0.65),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
}));
