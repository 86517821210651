import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: 220,
    borderRadius: 12,
    padding: '2px 5px',
    height: 36,
    '& .MuiInput-root': {
      backgroundColor: 'transparent',
    },
  },
  select: {
    borderRadius: 12,
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit',
    },
    '& .MuiSelect-select': {
      paddingRight: '0 !important',
    },
  },
  menuItem: {
    width: 220,
    height: 36,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
  published: {
    color: theme.palette.common.ContentTable.published,
    borderColor: theme.palette.common.ContentTable.published,
  },
  unpublished: {
    color: theme.palette.common.ContentTable.unpublished,
  },
  moderated: {
    color: theme.palette.common.ContentTable.moderated,
  },
  safe: {
    color: theme.palette.common.ContentTable.unpublished,
  },
  pending_moderation: {
    color: theme.palette.common.ContentTable.pending_moderation,
  },
  moderatedIcon: {
    marginLeft: '12px',
    color: theme.palette.error.main,
  },
}));

export const useIndicatorStyles = makeStyles((theme: Theme) => ({
  published: {
    color: theme.palette.common.ContentTable.published,
  },
  unpublished: {
    backgroundColor: theme.palette.secondary.main,
  },
  moderated: {
    backgroundColor: '#FC4741',
  },
  safe: {
    backgroundColor: theme.palette.secondary.main,
  },
  pending_moderation: {
    backgroundColor: theme.palette.info.main,
  },
}));

export const useSelectStyles = makeStyles((theme: Theme) => ({
  published: {
    backgroundColor: alpha(theme.palette.common.ContentTable.published as string, 0.1),
  },
  unpublished: {
    backgroundColor: 'rgba(255, 181, 21, 0.1)',
  },
  moderated: {
    backgroundColor: 'rgba(252, 71, 65, 0.1);',
  },
  safe: {
    backgroundColor: 'rgba(255, 181, 21, 0.1)',
  },
  pending_moderation: {
    backgroundColor: 'rgba(82, 66, 234, 0.1)',
  },
}));
