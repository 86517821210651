import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KPIDTO, RangeFilterDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { AnalyticsEndpoints, getApiUrlForId } from '../../api/endpoints';
import {
  GeneralKPIsRequest,
  GlobalAnalyticsModel,
  GlobalAnalyticsNames,
  GlobalAnalyticsRequest,
  KPIApiModel,
  KPIApiNames,
  KPIByIdRequest,
  KPIValueApiModel,
} from '../../api/models/analytics';

const initialState: KPIDTO = {
  kpis: {
    [KPIApiNames.WidgetCloseClicks]: 0,
    [KPIApiNames.WidgetUsers]: 0,
    [KPIApiNames.WidgetCtaClicks]: 0,
    [KPIApiNames.WidgetConversionRate]: 0,
    [KPIApiNames.WidgetVideoViews]: 0,
    [KPIApiNames.WidgetAvgTimeSpent]: 0,
    [KPIApiNames.WidgetUniqueViewers]: 0,
    [KPIApiNames.WidgetBuyNowClicks]: 0,
    [KPIApiNames.WidgetPurchases]: 0,
    [KPIApiNames.Heroes]: 0,
    [KPIApiNames.Videos]: 0,
    [KPIApiNames.VideosShared]: 0,
    [KPIApiNames.VideosDownloaded]: 0,
    [KPIApiNames.VideoLinkCopied]: 0,
    [KPIApiNames.VideoViews]: 0,
    [KPIApiNames.CTAClicks]: 0,
    [KPIApiNames.HeroesValue]: 0,
    [KPIApiNames.EstimatedVideoViews]: 0,
    [KPIApiNames.VideoLinkViews]: 0,
    [KPIApiNames.Audience]: 0,
    [KPIApiNames.Referrals]: 0,
    [KPIApiNames.Purchases]: 0,
    [KPIApiNames.WidgetViews]: 0,
    [KPIApiNames.visitors]: 0,
    [KPIApiNames.users]: 0,
    [KPIApiNames.shares]: 0,
    [KPIApiNames.ctaClicks]: 0,
    [KPIApiNames.videoViews]: 0,
    [KPIApiNames.FACEBOOK]: 0,
    [KPIApiNames.INSTAGRAM]: 0,
    [KPIApiNames.LINK]: 0,
    [KPIApiNames.TWITTER]: 0,
    [KPIApiNames.DOWNLOAD]: 0,
    [KPIApiNames.SMS]: 0,
    [KPIApiNames.EMAIL]: 0,
    [KPIApiNames.LINKEDIN]: 0,
    [KPIApiNames.TIKTOK]: 0,
    [KPIApiNames.REELS]: 0,
    [KPIApiNames.REDDIT]: 0,
    [KPIApiNames.PINTEREST]: 0,
    [KPIApiNames.incentiveCtaClicks]: 0,
    [KPIApiNames.ConvertedReferrals]: 0,
  },
  globalAnalytics: {
    [GlobalAnalyticsNames.averageConversionRate]: 0,
    [GlobalAnalyticsNames.averageEngagements]: 0,
    [GlobalAnalyticsNames.averageVideos]: 0,
    [GlobalAnalyticsNames.averageVideoViews]: 0,
    [GlobalAnalyticsNames.averageUsers]: 0,
    [GlobalAnalyticsNames.ctaClicks]: 0,
    [GlobalAnalyticsNames.videos]: 0,
    [GlobalAnalyticsNames.videoViews]: 0,
    [GlobalAnalyticsNames.users]: 0,
    [GlobalAnalyticsNames.venues]: 0,
    [GlobalAnalyticsNames.socialVerses]: 0,
    [GlobalAnalyticsNames.gbpAccounts]: 0,
    [GlobalAnalyticsNames.liveCampaigns]: 0,
  },
  kpiValues: [],
  activeAnalyticsBlock: {
    mainName: '',
    main: null,
    sub: null,
  },
  isLoading: false,
  lastUpdated: new Date().toISOString(),
  rangeFilter: null,
  error: null,
  isKpisLoading: false,
};

export const getGlobalAnalytics = createAsyncThunk(
  'venue/getGlobalAnalytics',
  async (options: GlobalAnalyticsRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GlobalAnalyticsRequest, GlobalAnalyticsModel>({
        url: AnalyticsEndpoints.GetSuperAdminAnalytics,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getGeneralKPIs = createAsyncThunk(
  'venue/getGeneralKPIs',
  async (options: GeneralKPIsRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GeneralKPIsRequest, KPIApiModel>({
        url: AnalyticsEndpoints.GetGeneralKPIs,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getTopGolfGeneralKPIs = createAsyncThunk(
  'venue/getTopGolfGeneralKPIs',
  async (options: GeneralKPIsRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GeneralKPIsRequest, KPIApiModel>({
        url: AnalyticsEndpoints.GetTGSGeneralKPIs,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getShareSummary = createAsyncThunk(
  'venue/getShareSummary',
  async (options: GeneralKPIsRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GeneralKPIsRequest, KPIApiModel>({
        url: AnalyticsEndpoints.getShareSummary,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface getKPIByIdShareRequest extends KPIByIdRequest {
  platform?: string;
}

export const getKPIByIdShare = createAsyncThunk(
  'venue/getKPIByIdShare',
  async (options: getKPIByIdShareRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<getKPIByIdShareRequest, KPIValueApiModel[]>({
        url: getApiUrlForId(AnalyticsEndpoints.GetShareKPI, options.kpi),
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getKPIById = createAsyncThunk(
  'venue/getKPIById',
  async (options: KPIByIdRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<KPIByIdRequest, KPIValueApiModel[]>({
        url: getApiUrlForId(AnalyticsEndpoints.GetKPI, options.kpi),
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setKpiValues(state, action: PayloadAction<KPIValueApiModel[]>) {
      state.kpiValues = action.payload;
    },
    setActiveAnalyticsBlock(
      state,
      action: PayloadAction<{ kpiName: KPIApiNames | null; name: string }>,
    ) {
      const { kpiName, name } = action.payload;
      state.activeAnalyticsBlock = { main: kpiName, mainName: name, sub: null };
    },
    setActiveSubAnalyticsBlock(state, action: PayloadAction<{ kpiName: KPIApiNames | null }>) {
      const { kpiName } = action.payload;
      state.activeAnalyticsBlock = {
        main: state.activeAnalyticsBlock.main,
        mainName: state.activeAnalyticsBlock.mainName,
        sub: kpiName,
      };
    },
    updateAnalytics(state) {
      state.lastUpdated = new Date().toISOString();
    },
    updateFilter(state, action: PayloadAction<RangeFilterDTO | null>) {
      state.rangeFilter = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getGeneralKPIs.rejected, (state) => {
      state.isLoading = false;
      state.isKpisLoading = false;
    });
    reducersBuilder.addCase(getGeneralKPIs.pending, (state) => {
      state.isLoading = true;
      state.isKpisLoading = true;
    });
    reducersBuilder.addCase(getGeneralKPIs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isKpisLoading = false;
      state.kpis = {
        ...state.kpis,
        ...payload,
      };
    });
    reducersBuilder.addCase(getTopGolfGeneralKPIs.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getTopGolfGeneralKPIs.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getTopGolfGeneralKPIs.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.kpis = {
        ...state.kpis,
        ...payload,
      };
    });
    reducersBuilder.addCase(getShareSummary.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getShareSummary.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getShareSummary.fulfilled, (state, { payload }) => {
      state.kpis = {
        ...state.kpis,
        [KPIApiNames.FACEBOOK]: payload.FACEBOOK || 0,
        [KPIApiNames.SMS]: payload.SMS || 0,
        [KPIApiNames.DOWNLOAD]: payload.DOWNLOAD || 0,
        [KPIApiNames.TWITTER]: payload.TWITTER || 0,
        [KPIApiNames.LINK]: payload.LINK || 0,
        [KPIApiNames.INSTAGRAM]: payload.INSTAGRAM || 0,
        [KPIApiNames.EMAIL]: payload.EMAIL || 0,
        [KPIApiNames.TIKTOK]: payload.TIKTOK || 0,
        [KPIApiNames.REDDIT]: payload.REDDIT || 0,
        [KPIApiNames.REELS]: payload.REELS || 0,
        [KPIApiNames.LINKEDIN]: payload.LINKEDIN || 0,
        [KPIApiNames.PINTEREST]: payload.PINTEREST || 0,
      };
    });
    reducersBuilder.addCase(getGlobalAnalytics.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getGlobalAnalytics.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });
    reducersBuilder.addCase(getGlobalAnalytics.fulfilled, (state, { payload }) => {
      state.globalAnalytics = {
        ...state.globalAnalytics,
        [GlobalAnalyticsNames.averageConversionRate]: payload.averageConversionRate || 0,
        [GlobalAnalyticsNames.averageEngagements]: payload.averageEngagements || 0,
        [GlobalAnalyticsNames.averageVideos]: payload.averageVideos || 0,
        [GlobalAnalyticsNames.averageVideoViews]: payload.averageVideoViews || 0,
        [GlobalAnalyticsNames.averageUsers]: payload.averageUsers || 0,
        [GlobalAnalyticsNames.ctaClicks]: payload.ctaClicks || 0,
        [GlobalAnalyticsNames.videos]: payload.videos || 0,
        [GlobalAnalyticsNames.videoViews]: payload.videoViews || 0,
        [GlobalAnalyticsNames.users]: payload.users || 0,
        [GlobalAnalyticsNames.venues]: payload.venues || 0,
        [GlobalAnalyticsNames.socialVerses]: payload.socialVerses || 0,
        [GlobalAnalyticsNames.gbpAccounts]: payload.gbpAccounts || 0,
        [GlobalAnalyticsNames.liveCampaigns]: payload.liveCampaigns || 0,
      };
      state.isLoading = false;
    });
    reducersBuilder.addCase(getKPIById.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getKPIById.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getKPIById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.kpiValues = payload;
    });
    reducersBuilder.addCase(getKPIByIdShare.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.kpiValues = payload;
    });
    reducersBuilder.addCase(getKPIByIdShare.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getKPIByIdShare.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const {
  reset,
  setKpiValues,
  setActiveAnalyticsBlock,
  setActiveSubAnalyticsBlock,
  updateAnalytics,
  updateFilter,
} = analyticsSlice.actions;
export default analyticsSlice.reducer;
