import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '60px 0 0 50px',
    width: '85%',
    maxWidth: '620px',
  },
  inputsWrapper: {
    padding: '10px',
    background: 'white',
    borderRadius: '20px',
  },
  title: {
    color: '#1A1538',
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '8px',
  },
  fieldWrapper: {
    display: 'flex',
    gap: '8px',
    marginBottom: '10px',
    width: '100%',
  },
  inputWrapper: {
    width: '100%',
  },
  inputLabel: {
    fontSize: '12px !important',
    color: '#1A1538 !important',
  },
  input: {
    width: '100% !important',
  },
  phoneInput: {
    paddingLeft: '15px',
    height: '42px',
  },
  phoneLabel: {
    '& p': {
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      marginBottom: '8px',
      color: theme.palette.common.black,
    },
  },
  toggle: {
    '&.MuiSwitch-root': {
      height: '34px',
      width: '54px',
    },
    '& .MuiSwitch-thumb': {
      height: '28px !important',
      width: '28px !important',
    },
    '& .Mui-checked': {
      transform: 'translateX(18px) !important',
    },
    '& .MuiSwitch-switchBase': {
      padding: '3px 0 0 3px',
    },
  },
  btnAdd: {
    fontWeight: 400,
    width: '220px',
    height: '42px',
    marginBottom: '5px',
    fontSize: '14px',
    '&:disabled': {
      background: 'rgba(82, 66, 234, 0.3)',
      color: 'white',
    },
  },

  tableWrapper: {
    marginTop: '24px',
    maxHeight: '350px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d9d4ff',
      borderRadius: '5px',
    },
  },

  tableContainer: {
    padding: '0  !important',
    border: '1px solid #DCDBE4',
    minHeight: '96px !important',

    '& table': {
      borderSpacing: '0 !important',
    },
  },
  topSectionClassName: {
    padding: '0 !important',
  },
  rowClassName: {
    padding: '0 !important',
    height: '50px !important',
    '& td': {
      cursor: 'default !important',
    },
  },
  headerCellClassName: {
    color: '#475467 !important',
    fontSize: '14px',
    fontWeight: '400',
    paddingTop: '10px !important',
    paddingLeft: '35px !important',
    background: '#F9FAFB',
  },
  btnNextWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    marginTop: '10px',
  },
  btnSend: {
    width: '200px',
    fontWeight: 400,
    '&:disabled': {
      background: '#5242EA',
      opacity: '0.25',
      color: 'white',
    },
  },
}));
