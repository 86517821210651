import React from 'react';
import { LinkIcon } from '../../../common/assets/newDesign/LinkIcon';
import { DownloadIconDropdown } from '../../../common/assets/newDesign/DownloadIconDropdown';
import { makeStyles } from '@mui/styles';
import { PlatformType } from '../Dashboard.helper';
import { AllIcon } from '../../../common/assets/newDesign/AllIcon';
import { SmsIcon } from '../../../common/assets/newDesign/SmsIcon';
// import { EmailIcon } from '../../../common/assets/newDesign/EmailIcon';
import { FacebookIconDropdown } from '../../../common/assets/newDesign/FacebookIconDropdown';
import { TwitterIcon } from '../../../common/assets/newDesign/TwitterIcon';
import { TikTokIcon } from '../../../common/assets/shareAssets/TikTokIcon';
import { KPIApiNames } from '../../../api/models/analytics';
import { FBReelsIcon } from '../../../common/assets/shareAssets/FBReelsIcon';
import { RedditIcon } from '../../../common/assets/shareAssets/RedditIcon';
import { LinkedinIcon } from '../../../common/assets/LinkedinIcon';
import { InstagramIconDv5 } from '../../../common/assets/shareAssets/InstagramIconDv5';

export interface SharesAnalyticsBlockModel {
  icon: React.ReactNode;
  platform: PlatformType;
  label: string;
  value: number | string;
  kpiName: string;
}

export const SharesAnalyticsBlockData: SharesAnalyticsBlockModel[] = [
  {
    icon: <AllIcon />,
    platform: '',
    label: 'All',
    value: 0,
    kpiName: '',
  },
  {
    icon: <SmsIcon />,
    platform: 'SMS',
    label: 'SMS',
    value: 0,
    kpiName: 'SMS',
  },
  {
    icon: <LinkIcon />,
    platform: 'LINK',
    label: 'Link',
    value: 0,
    kpiName: 'LINK',
  },
  //email  was excluded but counted as total value
  // {
  //   icon: <EmailIcon />,
  //   platform: 'EMAIL',
  //   label: 'Email',
  //   value: 0,
  //   kpiName: 'EMAIL',
  // },
  {
    icon: <FacebookIconDropdown />,
    platform: 'FACEBOOK',
    label: 'Facebook',
    value: 0,
    kpiName: 'FACEBOOK',
  },
  {
    icon: <TwitterIcon />,
    platform: 'TWITTER',
    label: 'Twitter',
    value: 0,
    kpiName: 'TWITTER',
  },
  //linked in was missing but counted as total value
  {
    icon: <LinkedinIcon />,
    platform: 'LINKEDIN',
    label: 'LinkedIn',
    value: 0,
    kpiName: KPIApiNames.LINKEDIN,
  },
  {
    icon: <InstagramIconDv5 />,
    platform: 'INSTAGRAM',
    label: 'Instagram',
    value: 0,
    kpiName: KPIApiNames.INSTAGRAM,
  },
  {
    icon: <TikTokIcon />,
    platform: 'TIKTOK',
    label: 'TikTok',
    value: 0,
    kpiName: KPIApiNames.TIKTOK,
  },
  {
    icon: <FBReelsIcon />,
    platform: 'REELS',
    label: 'Facebook Reels',
    value: 0,
    kpiName: KPIApiNames.REELS,
  },
  {
    icon: <RedditIcon />,
    platform: 'REDDIT',
    label: 'Reddit',
    value: 0,
    kpiName: KPIApiNames.REDDIT,
  },
  {
    icon: <DownloadIconDropdown />,
    platform: 'DOWNLOAD',
    label: 'Downloads',
    value: 0,
    kpiName: 'DOWNLOAD',
  },
];
const textColorDark = '#1A1538';
const textColorGray = '#8B89A0;';
export const useStyles = makeStyles(() => ({
  root: {
    '&>.MuiOutlinedInput-notchedOutline': {
      borderColor: '#DCDBE4 !important',
      borderWidth: '1px !important',
    },
  },
  valueText: {
    textAlign: 'right',
    '&>.MuiTypography-root': {
      color: `${textColorDark} !important`,
    },
  },
  dropdownStyle: {
    marginTop: '20px',
    padding: '10px 0',
    '& .MuiListItemText-root': {
      textAlign: 'rigth !important',
    },
  },
  select: {
    width: 170,
    height: 44,
  },

  outlined: {
    padding: '2px 20px',
    fontSize: '14px',
    color: textColorGray,
    fontWeight: 400,
  },

  itemContainer: {
    width: 250,
    padding: '10px 20px',
    '&.Mui-selected': {
      background: '#F5F6F8',
      '&:hover': {
        background: '#F5F6F8',
      },
    },
  },
  button: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    '&>svg': {
      width: 24,
      height: 24,
    },
  },
  text: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
  },
  labelText: {
    '&>.MuiTypography-root': {
      color: `${textColorGray} !important`,
    },
  },
}));
