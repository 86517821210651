import React, { useState } from 'react';
import { useUploadFileToStorage } from '../../../../hooks/useUploadFileToStorage';
import { QUICKSTART_BACKGROUND_GALLERY_IMAGES } from '../../../constants/constants';
import {
  ImageUploaderWithGallery,
  ImageUploaderWithGalleryProps,
} from '../ImageUploaderWithGallery';

interface BackgroundImageUploaderProps {
  backgroundImage: string | undefined;
  onImageChange?: (image: string | undefined) => void;
  onPreviewChange?: (image: string | undefined) => void;

  imagePreviewWidth?: ImageUploaderWithGalleryProps['imagePreviewWidth'];
  imagePreviewHeight?: ImageUploaderWithGalleryProps['imagePreviewHeight'];
  title?: ImageUploaderWithGalleryProps['title'];
  subtitle?: ImageUploaderWithGalleryProps['subtitle'];
}

export const BackgroundImageUploader: React.FC<BackgroundImageUploaderProps> = ({
  backgroundImage,
  onImageChange,
  onPreviewChange,
  imagePreviewWidth = '130px',
  imagePreviewHeight = '215px',
  title = 'Background Image',
  subtitle = 'Portrait images look best when displayed on a phone.',
}) => {
  const [galleryImagePreview, setGalleryImagePreview] = useState<string>();

  const { uploadFileToStorage, loading: isFileLoading } = useUploadFileToStorage();

  const handleUpload = async (file: File | undefined) => {
    if (!file) return;
    const uploadedFile = await uploadFileToStorage(file);
    const url = uploadedFile?.downloadUrl;
    onImageChange?.(url);
  };

  return (
    <ImageUploaderWithGallery
      file={galleryImagePreview || backgroundImage}
      onUpload={handleUpload}
      accept={['png', 'jpeg']}
      title={title}
      subtitle={subtitle}
      dndDescription="PNG or JPG (Recommended Size 1080x1920 pixels)"
      imagePreviewWidth={imagePreviewWidth}
      imagePreviewHeight={imagePreviewHeight}
      loading={isFileLoading}
      images={QUICKSTART_BACKGROUND_GALLERY_IMAGES}
      alignment="center"
      onGalleryImageSelect={(image) => {
        setGalleryImagePreview(image);
        onPreviewChange?.(image);
      }}
      onGallerySaveClick={(image) => {
        onImageChange?.(image);
        onPreviewChange?.(undefined);
      }}
      onGalleryClose={() => {
        setGalleryImagePreview(undefined);
        onPreviewChange?.(undefined);
      }}
    />
  );
};
