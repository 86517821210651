import React, { useState } from 'react';
import { useStyles } from './Select.styles';
import { Box, Typography } from '@mui/material';
import ArrowRightSmall from '../../assets/ArrowRightSmall';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { CheckmarkIcon } from '../../assets/newDesign/CheckmarkIcon';

interface Option<T> {
  value: T;
  name: string | number;
}

interface Props<T> {
  options: Option<T>[];
  value: T;
  onChange: (value: T) => void;
  width?: string;
  withPlaceholder?: boolean;
  placeholder?: string | number;
  margin?: string;
  title?: string;
}

const Select = <T,>({
  options,
  value,
  onChange,
  width,
  withPlaceholder,
  placeholder,
  margin,
  title,
}: Props<T>) => {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (value: T) => {
    onChange(value);
    handleOpen();
  };

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      setIsOpen(false);
    },
  });
  return (
    <div className={styles.container} style={{ margin: margin }}>
      {title && <Typography className={styles.title}>{title}</Typography>}
      <Box
        className={styles.select}
        style={{ width: width && width }}
        onClick={handleOpen}
        ref={refDetectClickOutside}
        id="select"
      >
        {withPlaceholder && !value && <Typography>{placeholder}</Typography>}
        {!withPlaceholder && !value && <Typography>{options[0].name}</Typography>}
        {value && <Typography>{options.find((option) => option.value === value)?.name}</Typography>}
        <Box
          className={styles.arrow}
          style={{ transform: `${isOpen ? 'rotate(90deg)' : 'rotate(0)'}` }}
        >
          <ArrowRightSmall stroke="#667085" />
        </Box>
      </Box>
      {isOpen && (
        <Box className={styles.optionsWrapper}>
          {options.map((option, index) => (
            <Box
              className={`${styles.option} ${option.value === value && styles.selected}`}
              onClick={() => handleChange(option.value)}
              id={`select_option${index}`}
            >
              {option.name}
              {option.value === value && <CheckmarkIcon className={styles.checkmark} />}
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

export default Select;
