import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './LeftSideCompleteLayout.styles';
import { QuickStartCompleteStrings } from '../../../../common/localization/en';
import { RoundedButton } from '../../../../common/components/Button/RoundedButton';

interface Props {
  onConfigureVideoAppClick: () => void;
  onReviewDefaultCampaignClick: () => void;
}

const LeftSideCompleteLayout: FC<Props> = ({
  onConfigureVideoAppClick,
  onReviewDefaultCampaignClick,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Box className={styles.subsection}>
        <Typography className={styles.nextStep}>{QuickStartCompleteStrings.NextStep}</Typography>
        <Typography className={styles.title}>{QuickStartCompleteStrings.Title}</Typography>
        <Typography className={styles.subTitle}>
          Collect inspirational user generated videos to share on your <b>Website</b> and{' '}
          <b>Google Business profile.</b>
        </Typography>

        <Box display="flex" maxWidth="615px">
          <Grid container spacing="16px" columnSpacing="24px">
            <Grid item xs={6}>
              <RoundedButton
                title="Open Dashboard"
                fullWidth
                height="54px"
                onClick={onReviewDefaultCampaignClick}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Divider sx={{ mt: '103px', borderColor: '#DCDBE4' }} />
    </Box>
  );
};

export default LeftSideCompleteLayout;
