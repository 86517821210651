import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  inputsWrapper: {
    background: 'white',
  },
  content: {
    padding: '0 24px 24px 24px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: '4px',
    marginTop: '24px',
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#475467',
  },
  subTitleLink: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#4F96FF',
    textDecoration: 'underline',
  },
  divider: {
    margin: '20px 0',
  },
  importTable: {
    border: '1px solid #DCDBE4',
    borderRadius: '10px',
    marginTop: '16px',
  },
  headers: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DCDBE4',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelsHeader: {
    padding: '14px 20px 7px 20px',
    width: '100%',
  },
  values: {
    padding: '8px 20px 8px 20px',
    width: '100%',
    '&:not(:last-child)': {
      borderRight: '1px solid #DCDBE4',
    },
    minHeight: '38px',
  },
}));
