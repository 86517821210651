import { FC } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { HighlighterComponent } from './Transcript.helper';
import { useTranscriptStyles } from './Transcript.style';

interface ITranscriptProps {
  trancsriptText: string;
  keywords: string[];
}

export const Transcript: FC<ITranscriptProps> = ({ trancsriptText, keywords }) => {
  const classes = useTranscriptStyles();
  return (
    <Box className={classes.TranscriptContainer}>
      <Grid className={classes.TranscriptTitleContainer}>
        <Typography className={classes.TranscriptTitle}>
          {VideoDetailsModalStringsDv5.Transcript}
        </Typography>
      </Grid>

      {trancsriptText ? (
        <Typography className={classes.TranscriptText}>
          "
          {
            <HighlighterComponent
              textToHighlight={trancsriptText}
              searchWords={keywords}
              highlightClassName={classes.HighlightTag}
              highlightTag={'em'}
              autoEscape={true}
            />
          }
          "
        </Typography>
      ) : null}
    </Box>
  );
};
