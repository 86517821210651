import { FC, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ListItemIcon } from '@mui/material';

import { SubMenu } from '../subMenu/SubMenu';
import { IMenuItem, checkIsActiveItem, checkIsActiveSubMenuItem, useStyles } from '../Menu.helper';
import { Container, SVListItem, SubMenuWrapper } from './MenuItemCollapsed.style';

interface MenuItemCollapsedProps {
  item: IMenuItem;
}

export const MenuItemCollapsed: FC<MenuItemCollapsedProps> = ({ item }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const IsSubMenu = item.isSubMenu;

  const [isActiveItem, setIsActiveItem] = useState<boolean>(false);
  const [isSubMenuCollapsed, setIsSubMenuCollapsed] = useState<boolean>(false);

  const classes = useStyles({
    active: isActiveItem,
    isSuperAdmin: item.isSuperAdmin ?? false,
    subItems: item.subMenuItems?.length ?? 0,
    isSubMenuOpened: isSubMenuCollapsed,
    blur: false,
  });

  useEffect(() => {
    setIsActiveItem(
      checkIsActiveItem(pathname, item.route) || checkIsActiveSubMenuItem(pathname, item),
    );
  }, [item, pathname]);

  const onClickSVListItemHandler = () => {
    history.push(item.route);
  };

  const onHoverItemHandler = () => {
    if (IsSubMenu) {
      setIsSubMenuCollapsed(!isSubMenuCollapsed);
    }
  };

  return (
    <Container onMouseEnter={onHoverItemHandler} onMouseLeave={onHoverItemHandler}>
      <SVListItem
        classes={{ root: classes.root, selected: classes.selected }}
        selected={isActiveItem}
        onClick={onClickSVListItemHandler}
      >
        <ListItemIcon style={{ margin: 'auto', color: 'inherit' }}>{item.icon}</ListItemIcon>
      </SVListItem>
      {IsSubMenu && isSubMenuCollapsed && (
        <SubMenuWrapper>
          <SubMenu
            collapsed
            item={item}
            isSubMenuCollapsed={isSubMenuCollapsed}
            onClickCollapseManagerHandler={onClickSVListItemHandler}
          />
        </SubMenuWrapper>
      )}
    </Container>
  );
};
