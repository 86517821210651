import React, { FC, useEffect, useState } from 'react';
import { FSModal } from '../modal/Modal';
import { useStyles } from './AddKeywordModal.styles';
import { Box, Button, Typography } from '@mui/material';
import { KeywordPageStrings, KeywordsStrings } from '../../localization/en';
import { TextFieldComponent } from '../TextField/TextField';
import { useTypedSelector } from '../../../store';
import { KeywordApiModel } from '../../../api/models/keywords';
import { KeywordsPreview } from './KeywordsPreview';
import clsx from 'clsx';
import { RoundedButton } from '../Button/RoundedButton';
import { useClientType } from '../../../services/hooks/useClientType';
import { trimAndSquashSpaces } from '../../../services/utilities';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSaveKeywords: (keywords: KeywordApiModel[]) => void;
  onKeywordTyped?: (keyword: string) => void;
}

const KEYWORD_MAX_LENGTH = 48;

const svSuggestedKeyWords = [] as KeywordApiModel[];
const TBSuggestedKeyWords = [] as KeywordApiModel[];
// const TBSuggestedKeyWords = [
//   {
//     keyword:'Invisalign',
//     icon:''
//   },
//   {
//     keyword:'implants',
//     icon:''
//   },
//   {
//     keyword:'braces',
//     icon:''
//   },
//   {
//     keyword:'clear braces',
//     icon:''
//   },
//   {
//     keyword:'clear aligners',
//     icon:''
//   },
//   {
//     keyword:'cosmetic',
//     icon:''
//   },
//   {
//     keyword:'veneers',
//     icon:''
//   },
//   {
//     keyword:'gentle',
//     icon:''
//   },
//   {
//     keyword:'laser',
//     icon:''
//   },
//   {
//     keyword:'confident',
//     icon:''
//   },
//   {
//     keyword:'amazing',
//     icon:''
//   },
//   {
//     keyword:'professional',
//     icon:''
//   },
//   {
//     keyword:'caring',
//     icon:''
//   },
//   {
//     keyword:'smile',
//     icon:''
//   },
//   {
//     keyword:'fantastic',
//     icon:''
//   },
//   {
//     keyword:'self-confidence',
//     icon:''
//   },
//   {
//     keyword:'beautiful',
//     icon:''
//   },
// ];

export const AddKeywordModal: FC<Props> = ({ isOpen, onClose, onSaveKeywords, onKeywordTyped }) => {
  const classes = useStyles();
  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);
  const { isHealthCare } = useClientType();

  const suggestedKeyWords = isHealthCare ? TBSuggestedKeyWords : svSuggestedKeyWords;

  const [keyword, setKeyword] = useState('');
  const [keywordsToSave, setKeywordsToSave] = useState<KeywordApiModel[]>([]);

  useEffect(() => {
    setKeywordsToSave(keywords);
  }, [keywords]);

  const formatKeyword = (keyword: string) => {
    return trimAndSquashSpaces(keyword);
  };

  const isKeywordValid = (keyword: string) => {
    return (
      keyword.length > 0 &&
      keyword.length < KEYWORD_MAX_LENGTH &&
      !keywordsToSave.some((currKeyword) => currKeyword.keyword === formatKeyword(keyword))
    );
  };

  const handleAddKeyword = (item?: string) => {
    const keywordValue = item || keyword;
    if (!isKeywordValid(keywordValue)) {
      return;
    }

    setKeywordsToSave([
      ...keywordsToSave,
      {
        keyword: formatKeyword(item || keyword),
        icon: '',
      },
    ]);
    setKeyword('');
  };

  const onKeywordDelete = (keywordItem: KeywordApiModel) => {
    setKeywordsToSave(keywordsToSave.filter((keyword) => keyword.keyword !== keywordItem.keyword));
  };

  const onModalClose = () => {
    onClose();
    setKeyword('');
    setKeywordsToSave(keywords);
  };

  const onSave = () => {
    onSaveKeywords(keywordsToSave);
    onModalClose();
  };

  const handleSuggestionsClick = (item: KeywordApiModel) => {
    handleAddKeyword(item.keyword);
  };

  return (
    <FSModal
      modalIsOpen={isOpen}
      onClose={onClose}
      width="580px"
      padding="15px 30px"
      rounded="10px"
    >
      <Typography className={classes.title}>{KeywordsStrings.AddKeywords}</Typography>
      <Typography className={classes.subTitle}>{KeywordsStrings.KeywordsSubtitle}</Typography>
      <Typography className={classes.addBelow}>{KeywordsStrings.AddKeywordBelow}</Typography>
      <TextFieldComponent
        name="keyword"
        placeholder={KeywordsStrings.AddNewKeyword}
        value={keyword}
        onChange={(event) => {
          setKeyword(event.target.value);
          onKeywordTyped?.(event.target.value);
        }}
        fullWidth
        autocapitalize
        onEnterPress={handleAddKeyword}
        autoFocus
        buttonProps={{
          customComponent: (
            <Button
              disabled={!isKeywordValid(keyword)}
              className={classes.modalBtn}
              onClick={() => handleAddKeyword()}
            >
              {KeywordPageStrings.AddKeywordButtonText}
            </Button>
          ),
        }}
      />

      <KeywordsPreview
        keywords={suggestedKeyWords}
        handleClick={handleSuggestionsClick}
        borderless={true}
      />
      <Typography className={classes.categories}>{KeywordsStrings.KeywordCategories}</Typography>
      <KeywordsPreview keywords={keywordsToSave} onDelete={onKeywordDelete} />
      <Box className={classes.btnWrapper}>
        <Button className={clsx(classes.modalBtn, classes.btnSave)} onClick={onSave}>
          {KeywordsStrings.SaveUpdates}
        </Button>
        <RoundedButton title={KeywordsStrings.Cancel} outlined={true} onClick={onModalClose} />
      </Box>
    </FSModal>
  );
};
