import { FC } from 'react';

export const BellIcon: FC = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.74605 9.43538L7.42999 11.8684C7.35179 12.4704 7.13759 13.0467 6.80363 13.5536L5.49124 15.5457C5.0609 16.199 4.83154 16.964 4.83154 17.7463V18.1162C4.83154 20.3254 6.6224 22.1162 8.83154 22.1162H21.1692C23.3783 22.1162 25.1692 20.3254 25.1692 18.1162V17.803C25.1692 16.9854 24.9186 16.1875 24.4513 15.5166L23.1058 13.5851C22.7388 13.0583 22.5041 12.4508 22.4213 11.8141L22.1123 9.43538C21.6435 5.82595 18.569 3.125 14.9292 3.125C11.2894 3.125 8.21493 5.82596 7.74605 9.43538Z"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M11.8649 24.8943C12.423 26.0656 13.6177 26.875 15.0015 26.875C16.3853 26.875 17.5801 26.0656 18.1381 24.8943"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="15.4"
        y="0.4"
        width="14.2"
        height="14.2"
        rx="7.1"
        fill="#5242EA"
        stroke="white"
        strokeWidth="0.8"
      />
    </svg>
  );
};
