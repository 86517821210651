import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { setSelectedVenue } from '../../../../../store/slices/venueSlice';

import { WebsiteRoutes } from '../../../../constants/routes';
import { useStylesWorkspace } from './Workspace.style';
import { WorkspaceContextMenu } from './WorkspaceContext';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { UserRoles } from '../../../../constants/constants';
import { Box, Typography } from '@mui/material';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../../../constants/events/accountEvents';

export const WorkspaceSection = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const classes = useStylesWorkspace();
  const { name, id } = useTypedSelector((state) => state.venue.venue);

  const { role, accounts } = useTypedSelector((state) => state.me);
  const { pathname } = location;
  const isMultiAccount = accounts > 1;
  const isSocialVenueAdmin = role === UserRoles.admin;

  const accountsRoutes: string[] = [
    WebsiteRoutes.AccountsOverview,
    WebsiteRoutes.AccountsCompany,
    WebsiteRoutes.AccountsMembers,
    WebsiteRoutes.AccountsBilling,
    WebsiteRoutes.AccountsDistributors,
    WebsiteRoutes.AccountsFAQVideos,
    WebsiteRoutes.AccountsUpdates,
    WebsiteRoutes.AccountsProfile,
  ];

  const hideWorkSpaceSection =
    (isMultiAccount || isSocialVenueAdmin) &&
    (pathname === WebsiteRoutes.ProfileAccounts || pathname === WebsiteRoutes.ProfileProfile);

  const { venuesList } = useTypedSelector((state) => state.venue);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isWorkspaceRoute = accountsRoutes.includes(location.pathname);

  if (hideWorkSpaceSection) {
    return null;
  }

  if (!id) {
    return null;
  }

  // const handleOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (venueId: string) => {
    dispatch(setSelectedVenue({ id: venueId }));
    history.push(WebsiteRoutes.DashboardHeroes);
    handleClose();
  };
  return (
    <div className={classes.containerWorkspace}>
      {/* <span className={classes.section} /> */}
      <div
        onClick={() => {
          history.push(WebsiteRoutes.AccountsCompany);
          trackEvent(AccountEventNames.accountDetailsClicked);
        }}
        className={[classes.root, isWorkspaceRoute ? classes.active : ''].join(' ')}
      >
        {/* <div className={classes.titleContainer}> */}
        <Box display="flex" flexDirection="column" justifyContent="center">
          <p className={classes.title}>{name}</p>
          <Typography fontSize="12px" color="#887EE8">
            Account Details
          </Typography>
        </Box>
        <span className={classes.arrow}>
          <ArrowForwardIosRoundedIcon color="inherit" fontSize="inherit" />
        </span>
        {/* </div> */}
      </div>
      {/* {venuesList && venuesList.length ? (
        <Button
          onClick={handleOpenContextMenu}
          className={classes.iconButton}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="text"
          disableElevation
        >
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      ) : null} */}

      <WorkspaceContextMenu
        activeVenueId={id}
        venuesList={venuesList}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleClick={handleClick}
      />
    </div>
  );
};
