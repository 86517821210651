import { FC, useEffect, useState } from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import { SearchBarContainer } from './SearchBar.style';
import { useAppDispatch, useTypedSelector } from '../../../store';
import CloseIcon from '@mui/icons-material/Close';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';
import { RewardCampaignsEvents } from '../../constants/events/rewardCampaignsEvents';
import { MagnifierIcon } from '../../assets/MagnifierIcon';

type Entity = 'heroes' | 'videos' | 'campaigns' | 'OrganizationVenueSlice' | 'TopgGolfVideos';

export interface SearchBarProps {
  entity: Entity;
  updateSearch: Function;
  selfSearch?: string;
  adaptiveWidth?: boolean;
  disabled?: boolean;
  placeholderText?: string;
  callUpdateSearchAsFunc?: boolean;
  borderColor?: string;
  clearSearch?: string | number | boolean;
  onSearchChanged?: (search: string) => void;
  className?: string;
}

export const SearchBar: FC<SearchBarProps> = ({
  entity,
  updateSearch,
  selfSearch,
  adaptiveWidth,
  disabled,
  placeholderText,
  callUpdateSearchAsFunc,
  borderColor,
  clearSearch,
  onSearchChanged,
  className,
}) => {
  const dispatch = useAppDispatch();

  const { search = '' } = useTypedSelector((state) => state[entity]);

  const [searchValue, setSearchValue] = useState('');

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    setSearchValue(selfSearch || search);
  }, [search, selfSearch]);

  const callSearch = (val: string) => {
    if (callUpdateSearchAsFunc) {
      updateSearch(val);
    } else {
      dispatch(updateSearch(val));
    }
  };

  const changeSearchValue = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearchChanged?.(value);

    if (value.length === 0) {
      callSearch('');
    }

    const entitiesLiteral: Record<Entity, string> = {
      heroes: CreatorsTableEventNames.CreatorTableSearchbarTyped,
      campaigns: CreatorsTableEventNames.CampaignsTableSearchbarTyped,
      videos: CreatorsTableEventNames.VideosTableSearchbarTyped,
      OrganizationVenueSlice: CreatorsTableEventNames.VenuesTableSearchbarTyped,
      TopgGolfVideos: CreatorsTableEventNames.TopgGolfVideosTableSearchbarTyped,
    };

    trackEvent(entitiesLiteral[entity], { value });
  };

  const submitSearch = () => {
    callSearch(searchValue);

    const entitiesLiteral: Record<string, string> = {
      heroes: CreatorsTableEventNames.CreatorTableSearchbarSubmitClick,
      campaigns: RewardCampaignsEvents.RewardCampaignsTableSearchbarSubmitClick,
    };

    if (entitiesLiteral[entity]) trackEvent(entitiesLiteral[entity], { value: searchValue });
  };

  const resetSearch = () => {
    setSearchValue('');
    callSearch('');

    const entitiesLiteral: Record<string, string> = {
      heroes: CreatorsTableEventNames.CreatorTableSearchbarResetClick,
      campaigns: RewardCampaignsEvents.RewardCampaignsTableSearchbarResetClick,
    };

    if (entitiesLiteral[entity] && searchValue) trackEvent(entitiesLiteral[entity]);
  };

  return (
    <SearchBarContainer
      noValidate
      adaptiveWidth={adaptiveWidth}
      onSubmit={(event) => {
        //for Safari browser, will not be executed on Chrome
        event.preventDefault();
        submitSearch();
      }}
      disabled={disabled}
      className={className}
    >
      {/* <fieldset disabled={disabled}> */}
      <TextField
        disabled={disabled}
        value={searchValue}
        onChange={(e) => changeSearchValue(e)}
        variant="filled"
        placeholder={placeholderText || 'Search'}
        size={'small'}
        sx={{
          fontSize: '14px !important',
          fontWeight: '400 !important',
          '& ::placeholder': {
            fontSize: '14px',
            fontWeight: '400',
          },
        }}
        style={borderColor ? { border: `1px solid ${borderColor}`, borderRadius: '100px' } : {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MagnifierIcon />
            </InputAdornment>
          ),
          endAdornment: searchValue ? (
            <InputAdornment onClick={resetSearch} position="start">
              <CloseIcon style={{ fontSize: '16px', cursor: 'pointer' }} />
            </InputAdornment>
          ) : undefined,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={(e) => {
          e.preventDefault();
          submitSearch();
        }}
        disableElevation
        type="submit"
        hidden
        style={{ display: 'none' }}
        disabled={disabled}
      >
        Search
      </Button>
      {/* </fieldset> */}
    </SearchBarContainer>
  );
};
