import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { RoundedButton } from '../Button/RoundedButton';
import { ImageGallerySelectorProps, ImageGallerySelector } from './components/ImageGallerySelector';
import { ImageUploaderInfo } from './ImageUploader';
import { ImageUploaderBase, ImageUploaderBaseProps } from './components/ImageUploaderBase';
import { CommonEventNames } from '../../constants/events/common';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

export type Alignment = 'flex-start' | 'center' | 'flex-end';

export interface ImageUploaderWithGalleryProps extends ImageUploaderBaseProps {
  title: string;
  subtitle: string;

  images: ImageGallerySelectorProps['images'];
  onGalleryImageSelect?: ImageGallerySelectorProps['onSelect'];
  onGallerySaveClick?: (activeImage: string) => void;
  onGalleryClose?: () => void;
  alignment?: Alignment;
}

export const ImageUploaderWithGallery: React.FC<ImageUploaderWithGalleryProps> = ({
  file,
  onUpload,
  accept,
  loading,
  title,
  subtitle,
  images,
  onGalleryImageSelect,
  onGallerySaveClick,
  onGalleryClose,
  alignment,
  ...restProps
}) => {
  const {
    isOpen: isBackgroundSelectorOpen,
    open: openBackgroundSelector,
    close: closeBackgroundSelector,
  } = useModal();
  const { trackEvent } = useTrackEvent();
  const [galleryActiveImage, setGalleryActiveImage] = useState<string | undefined>(file);
  useEffect(() => {
    setGalleryActiveImage(file);
  }, [file]);

  const disableGallerySaveButton = useMemo(() => {
    if (!galleryActiveImage) return true;
    return !images.includes(galleryActiveImage as string);
  }, [images, galleryActiveImage]);

  return (
    <ImageUploaderBase
      file={file}
      onUpload={onUpload}
      accept={accept}
      loading={loading}
      alignment={alignment}
      renderRightSide={({ openFileSelector }) => (
        <Box display="flex" flexDirection="column" alignItems="start">
          {isBackgroundSelectorOpen ? (
            <>
              <ImageGallerySelector
                images={images}
                activeImage={galleryActiveImage}
                onSelect={(image) => {
                  setGalleryActiveImage(image);
                  onGalleryImageSelect?.(image);
                }}
              />
              <Box display="flex" mt="10px">
                <RoundedButton
                  title="Save"
                  disabled={disableGallerySaveButton}
                  onClick={() => {
                    closeBackgroundSelector();
                    onGallerySaveClick?.(galleryActiveImage as string);
                    onGalleryClose?.();
                    trackEvent(CommonEventNames.background_image_library_select_button_click);
                  }}
                  sx={{ mr: '10px' }}
                />
                <RoundedButton
                  title="Cancel"
                  textVariant
                  error
                  onClick={() => {
                    closeBackgroundSelector();
                    onGalleryClose?.();
                    trackEvent(CommonEventNames.background_image_library_cancel_button_click);
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <ImageUploaderInfo title={title} subtitle={subtitle} />
              <Box display="flex" mt="20px" gap="15px">
                <RoundedButton
                  title="Image Library"
                  onClick={() => {
                    openBackgroundSelector();
                    trackEvent(CommonEventNames.background_image_library_open_button_click);
                  }}
                  loading={loading}
                  height="40px"
                  sx={{
                    height: '34px',
                    width: '120px',
                    padding: '10px 10px',
                    '& p': {
                      fontSize: '12px',
                    },
                  }}
                />
                <RoundedButton
                  title="Upload Image"
                  onClick={() => {
                    openFileSelector();
                    trackEvent(CommonEventNames.background_image_library_upload_button_click);
                  }}
                  loading={loading}
                  outlined
                  height="40px"
                  sx={{
                    height: '34px',
                    width: '120px',
                    padding: '10px 10px',
                    '& p': {
                      fontSize: '12px',
                    },
                    border: '2px solid #5242EA',
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      )}
      {...restProps}
    />
  );
};
