import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    background: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.main,
    '&:disabled': {
      background: `${theme.palette.primary.light} !important`,
      '& path': {
        stroke: `${theme.palette.primary.main} !important`,
      },
    },
    '&:hover': {
      background: `${theme.palette.primary.contrastText} !important`,
      '& path': {
        stroke: `${theme.palette.primary.main} !important`,
      },
    },
  },
}));
