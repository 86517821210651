import { FC, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Grid, Typography, Box } from '@mui/material';
import {
  CommonStrings,
  DownloadedVideoScreenStrings,
  SidebarStrings,
  VideoEditingStrings,
  VideoSharedScreenStrings,
  WebAppSignUpConfigStrings,
} from '../../../common/localization/en';
// service & utils
import { useAppDispatch, useTypedSelector } from '../../../store';
import { patchVenueProperties } from '../../../store/slices/venueSlice';
import { getVideoFilters } from '../../../store/slices/filtersSlice';
import { createUploadUrl, uploadLogoAsFile } from '../../../services/utilities';
import { DefaultVideoDetailsEditable } from '../../../api/models/venue';
import { ClientTypes } from '../../../api/models/common';
// import components
import { IPhoneVideoPlaybackPreview } from '../../../common/components/IPhonePreview/IPhoneVideoPlaybackPreview';
import { ShareThumbnailPreview } from '../VideoEditing/ShareThumbnailPreview';
import {
  filterLogoPositions,
  MAX_LENGTH,
  uploadOverlay,
  videoBrandingPlaybackValidationSchema,
} from './VideoBrandingPlayback.helper';
import {
  InputWrapper,
  PicturedInput,
} from '../../../common/components/NewDetailsModal/DetailsModalContent/components/NewCampaign.helper';
// icon import & style
import { DownloadIcon } from '../../../common/assets/shareAssets/DownloadIcon';
import { useVideoBrandingPlaybackStyles } from './VideoBrandingPlayback.style';
import { useFTE } from '../../../hooks/useFTE';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';
import { UnsavedChangesModal } from '../../../common/components/UnsavedChangesModal/UnsavedChangesModal';
import { TextFieldComponent } from '../../../common/components/TextField/TextField';
import { useToasts } from 'react-toast-notifications';
import { TwoSidePageLayout } from '../../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { SubSectionTitle } from '../../../common/components/SubSectionTitle/SubSectionTitle';
import { URLTextField } from '../../../common/components/TextField/URLTextField';
import { useClientType } from '../../../services/hooks/useClientType';
import { TopBar } from '../../../common/components/topBar/TopBar';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { WebAppSettingsEvents } from '../../../common/constants/events/webappSettingsEvents';

import { webappTabs } from '../../webapp/tabs';
import { WebappSubnavigation } from '../../../common/components/webappSubnavigation/WebappSubnavigation';
import { SettingsEvents } from '../../../common/constants/events/settingsEvents';

export const VideoBrandingPlayback: FC = () => {
  const classes = useVideoBrandingPlaybackStyles();
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const {
    venue: { id, logo, clientType, name: venueName, subdomain },
    venueAttributes: { properties: venueProperties, isLoading },
  } = useTypedSelector((state) => state.venue);
  const [formLoading, setFormLoading] = useState(false);
  const isloading = isLoading || formLoading;
  const isTopgolf = clientType === ClientTypes.TOPGOLF;
  const defaultStoryValues = venueProperties?.['webapp.default-story-details'];
  const filterOverlayEnabled = venueProperties?.['webapp.config']['filter-overlay-enabled'];
  const brandColor = venueProperties?.['webapp.config']['primary-color'];
  const { isHealthCare } = useClientType();
  const filterOverlay =
    typeof venueProperties?.['webapp.config']['filter-overlay-url'] !== 'undefined'
      ? venueProperties?.['webapp.config']['filter-overlay-url']
      : '';
  const filterLogoEnabled =
    typeof venueProperties?.['webapp.config']['filter-logo-enabled'] !== 'undefined'
      ? venueProperties?.['webapp.config']['filter-logo-enabled']
      : true;
  const [tempFilterOverlayEnabled, setTempFilterOverlayEnabled] = useState<boolean>(
    (filterOverlayEnabled && isTopgolf) || false,
  );
  const [tempFilterLogoEnabled, setTempFilterLogoEnabled] = useState<boolean>(
    isTopgolf ? false : filterLogoEnabled,
  );
  const [tempFilterOverlayUrl, setTempFilterOverlayUrl] = useState<string>(filterOverlay || '');
  const logoPosition = filterLogoPositions.topLeft;
  const inputFile2 = useRef<HTMLInputElement | null>(null);
  const initialValue = {
    metaTitle: defaultStoryValues?.webAppSettings?.metaTitle || false,
    videoText: defaultStoryValues?.webAppSettings?.videoText || false,
    ctaBtnText: defaultStoryValues?.webAppSettings?.ctaBtnText || false,
    ctaBtnLinkUrl: defaultStoryValues?.webAppSettings?.ctaBtnLinkUrl || false,
    videoMessage: defaultStoryValues?.webAppSettings?.videoMessage || false,
  };
  const [state, setState] = useState(initialValue);
  const showFTEActionBar =
    !venueProperties?.['webapp.firstTimeOnboardingFinished.config']?.webapp?.webapp_sharing;
  useEffect(() => {
    if (id) {
      dispatch(getVideoFilters({ venueId: id }));
    }
  }, [dispatch, id]);
  useEffect(() => {
    setState({
      metaTitle: defaultStoryValues?.webAppSettings?.metaTitle || false,
      videoText: defaultStoryValues?.webAppSettings?.videoText || false,
      ctaBtnText: defaultStoryValues?.webAppSettings?.ctaBtnText || false,
      ctaBtnLinkUrl: defaultStoryValues?.webAppSettings?.ctaBtnLinkUrl || false,
      videoMessage: defaultStoryValues?.webAppSettings?.videoMessage || false,
    });
  }, [venueProperties, defaultStoryValues]);
  useEffect(() => {
    if (venueProperties) {
      setTempFilterOverlayUrl(venueProperties?.['webapp.config']['filter-overlay-url'] || '');
      setTempFilterOverlayEnabled(
        (venueProperties?.['webapp.config']['filter-overlay-enabled'] && isTopgolf) || false,
      );
      setTempFilterLogoEnabled(filterLogoEnabled && !isTopgolf);
      // setTempFilterLogo(venueProperties?.['webapp.config']['filter-logo'] || logo);
    }
  }, [venueProperties, logo, isTopgolf, filterLogoEnabled]);
  const handleUpload = async () => {
    if (logoPosition && id) {
      return await uploadOverlay(
        tempFilterOverlayEnabled ? tempFilterOverlayUrl : '',
        tempFilterLogoEnabled ? logo : '',
        logoPosition,
        id,
        isTopgolf,
      );
    } else {
      return Promise.resolve('');
    }
  };
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    resetForm,
    submitForm,
    dirty: isFormDirty,
    setFieldValue,
  } = useFormik({
    initialValues: {
      editable: defaultStoryValues?.editable ?? DefaultVideoDetailsEditable.Full,
      metaTitle: defaultStoryValues?.metaTitle ?? VideoEditingStrings.DefaultMetaTitle,
      videoText: defaultStoryValues?.videoText ?? VideoEditingStrings.DefaultVideoText,
      ctaBtnText: defaultStoryValues?.ctaBtnText ?? VideoEditingStrings.DefaultCTABtnText,
      ctaBtnLinkUrl: defaultStoryValues?.ctaBtnLinkUrl ?? VideoEditingStrings.DefaultCTABtnLink,
      videoMessage: defaultStoryValues?.videoMessage ?? '',
      tempFilterOverlayEnabled: isTopgolf ? filterOverlayEnabled : false,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    validationSchema: videoBrandingPlaybackValidationSchema,
    onSubmit: (values) => {
      setFormLoading(true);
      handleUpload()
        .then((filterUrl) => {
          dispatch(
            patchVenueProperties({
              id,
              update: {
                name: 'webapp.config',
                value: {
                  ...(venueProperties?.['webapp.config'] || {}),
                  'filter-logo-position': logoPosition,
                  'filter-logo-enabled': tempFilterLogoEnabled,
                  'filter-overlay-enabled': tempFilterOverlayEnabled,
                  'filter-overlay-url': tempFilterOverlayUrl,
                  // 'filter-logo': tempFilterLogo,
                  'filter-url': filterUrl,
                },
              },
            }),
          );
        })
        .then(() => {
          dispatch(
            patchVenueProperties({
              id,
              update: {
                name: 'webapp.default-story-details',
                value: { ...values, webAppSettings: state, ...transformedUrl },
              },
            }),
          ).then(() => {
            addToast(WebAppSignUpConfigStrings.nextStepMessage, {
              appearance: 'success',
              autoDismissTimeout: 2500,
            });
          });
        });
      setFormLoading(false);
    },
  });
  const transformedUrl = {
    ctaBtnLinkUrl: values.ctaBtnLinkUrl.includes('http')
      ? values.ctaBtnLinkUrl
      : `https://${values.ctaBtnLinkUrl}`,
  };
  const onButtonClick2 = () => {
    if (inputFile2 && inputFile2.current) {
      (inputFile2.current as any).click();
    }
  };
  const { moveToNextRoute } = useFTE();
  const onChangeTempFilterOverlay = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    createUploadUrl(id, file?.name.split('.').pop()).then((res) => {
      if (res) {
        uploadLogoAsFile({ options: res, data: file }).then(() => {
          setTempFilterOverlayUrl(res.downloadUrl);
        });
      }
    });
  };
  const handleResetLogoPosition = () => {
    setTempFilterOverlayUrl(venueProperties?.['webapp.config']['filter-overlay-url'] || '');
    const tempFilterOverlayEnabled = isTopgolf
      ? venueProperties?.['webapp.config']['filter-overlay-enabled']
        ? venueProperties?.['webapp.config']['filter-overlay-enabled']
        : false
      : false;
    setTempFilterOverlayEnabled(tempFilterOverlayEnabled);
    setTempFilterLogoEnabled(isTopgolf ? false : filterLogoEnabled);
  };
  const resetAll = () => {
    resetForm();
    setState(initialValue);
    handleResetLogoPosition();
  };
  const isError =
    errors.ctaBtnLinkUrl?.length ||
    errors.ctaBtnText?.length ||
    errors.metaTitle?.length ||
    errors.videoMessage?.length
      ? true
      : false;
  const handleActionBarFTE = async () => {
    if (!showFTEActionBar) return;
    moveToNextRoute();
  };
  const saveAndMoveNextFTE = async () => {
    await submitForm();
    resetForm();
    await handleActionBarFTE();
  };
  const onRouteClick = (route: string) => {
    const subRoute = route.split('/').reverse()[0].replaceAll('-', '_');
    const eventName = `${subRoute}_${SettingsEvents.video_app_sub_navigation_tab_click}`;
    trackEvent(eventName);
  };
  return (
    <>
      <TopBar
        sectionTitle={SidebarStrings.WebApp}
        onTabChange={(tab) => {
          trackEvent(WebAppSettingsEvents.NavigationTabChange, { value: tab.text });
        }}
      >
        <WebappSubnavigation items={webappTabs} onRouteClick={onRouteClick} />
      </TopBar>
      <UnsavedChangesModal
        isUnsavedChangesOnPage={isFormDirty || filterLogoEnabled !== tempFilterLogoEnabled}
      />
      <TwoSidePageLayout
        leftSideDescriptionClassName={classes.leftSideDescription}
        leftSideDescription={DownloadedVideoScreenStrings.SectionTitle2}
        leftSideNode={
          <Box>
            <Box mb="32px">
              {!isTopgolf ? (
                <Box>
                  <SubSectionTitle
                    mb="25px"
                    title={DownloadedVideoScreenStrings.Logo}
                    tooltip={
                      isHealthCare
                        ? DownloadedVideoScreenStrings.FirstStageTooltip.replace(
                            'customers',
                            'patient’s',
                          )
                        : DownloadedVideoScreenStrings.FirstStageTooltip
                    }
                    toggleConfig={{
                      value: tempFilterLogoEnabled,
                      onChange: () => setTempFilterLogoEnabled(!tempFilterLogoEnabled),
                      disable: !logo,
                    }}
                  />
                </Box>
              ) : (
                <Box>
                  <SubSectionTitle
                    mb="25px"
                    title={DownloadedVideoScreenStrings.SecondStepTitle2}
                    tooltip={DownloadedVideoScreenStrings.SecondStageTooltip}
                    toggleConfig={{
                      value: tempFilterOverlayEnabled,
                      onChange: () => {
                        setFieldValue('tempFilterOverlayEnabled', !tempFilterOverlayEnabled);
                        setTempFilterOverlayEnabled(!tempFilterOverlayEnabled);
                      },
                    }}
                  />
                  <Grid container>
                    <Grid item xs={12}>
                      <div className={classes.NotesWrapper}>
                        <span className={classes.NotesTitle}>
                          {DownloadedVideoScreenStrings.Notes}
                        </span>
                        <span className={classes.NotesText}>
                          {DownloadedVideoScreenStrings.NotesDescription}
                        </span>
                      </div>
                      <div className={classes.DownloadButtonWrapper}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<DownloadIcon />}
                          sx={{ borderRadius: '40px' }}
                        >
                          <a
                            className={classes.DisableColoration}
                            href={isTopgolf ? '/TGFilterTemplate.png' : '/FilterTemplate.png'}
                            download="filter template"
                          >
                            {DownloadedVideoScreenStrings.DownloadTemplate}
                          </a>
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={12} className={classes.imgChanger}>
                      <InputWrapper
                        className={
                          isTopgolf ? classes.imageWrapperForTopGolf : classes.imageWrapper
                        }
                        backgroundImage={tempFilterOverlayUrl || 'test'}
                      >
                        <PicturedInput
                          ref={inputFile2}
                          id="contained-button-file"
                          type="file"
                          accept="image/png"
                          onChange={onChangeTempFilterOverlay}
                        />
                      </InputWrapper>
                      <Box className={classes.textWrapper}>
                        <Button
                          className={classes.changeLogoButton}
                          onClick={onButtonClick2}
                          sx={{ p: '6px 16px' }}
                        >
                          {CommonStrings.ChangeImage}
                        </Button>
                        <Typography className={classes.text}>
                          {CommonStrings.DragAndDrop}
                        </Typography>
                        <Typography className={classes.text}>
                          {'(' + CommonStrings.Size}
                          {isTopgolf ? ' 1280px * 1080px' : ' 864px * 1080px'}
                          {')'}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.StageDescription}></Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
            <Box>
              <SubSectionTitle
                mb="25px"
                title={VideoSharedScreenStrings.SecondStage}
                tooltip={VideoSharedScreenStrings.FirstStageTooltip}
              />
              <Grid>
                <Box>
                  <Typography style={{ marginTop: '-8px' }} className={classes.StageTitle}>
                    {VideoSharedScreenStrings.ButtonTitle}
                  </Typography>
                  <TextFieldComponent
                    label=""
                    name="ctaBtnText"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ctaBtnText}
                    errorText={errors.ctaBtnText}
                    helperText
                    characterLimit={MAX_LENGTH}
                  />
                </Box>
                <Typography className={classes.StageTitle}>
                  {VideoSharedScreenStrings.CTADestination}
                </Typography>
                <URLTextField
                  label=""
                  name="ctaBtnLinkUrl"
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ctaBtnLinkUrl}
                  errorText={errors.ctaBtnLinkUrl}
                  helperText
                />
              </Grid>
            </Box>
          </Box>
        }
        rightSideNode={
          <>
            {false ? (
              <ShareThumbnailPreview
                logo={logo}
                venue={venueName}
                url={subdomain}
                isTopGolf={isTopgolf}
                postText={values.videoMessage}
              />
            ) : (
              <div>
                <IPhoneVideoPlaybackPreview
                  tempFilterOverlayUrl={tempFilterOverlayEnabled ? tempFilterOverlayUrl : ''}
                  logoPosition={logoPosition}
                  tempFilterLogoEnabled={tempFilterLogoEnabled}
                  tempFilterOverlayEnabled={tempFilterOverlayEnabled}
                  buttonText={values.ctaBtnText.replace('{Org name}', venueName)}
                  logo={logo}
                  isViewForTopGolf={isTopgolf}
                  brandColor={brandColor}
                />
              </div>
            )}
          </>
        }
      />
      <BottomActionBar
        loading={isloading}
        disableSaveButton={isError || !values.ctaBtnLinkUrl}
        onCancelClick={resetAll}
        onSaveClick={submitForm}
        onSaveAndNextClick={saveAndMoveNextFTE}
        onSetupLaterClick={handleActionBarFTE}
        show={isFormDirty || filterLogoEnabled !== tempFilterLogoEnabled}
        firstTimeExperience={showFTEActionBar}
      />
    </>
  );
};
