import { KeywordApiModel } from '../api/models/keywords';

declare const window: Window &
  typeof globalThis & {
    analytics: any;
    gtag: any;
  };

export interface eventProps {
  action: string;
  venueId: string;
  name?: string;
  userId?: string;
  email?: string;
  platform?: string;
  value?: string | boolean;
  location?: string;
  videoUrl?: string;
  phoneNumber?: string;
  form?: string;
  tos?: boolean;
  verificationCode?: string;
  page?: string;
  fileSize?: number;
  errorMessage?: string;
  memberId?: string;
  socialVerseId?: string | string[];
  downloadVideoType?: string;
  affiliateId?: string;
  distributorId?: string;
  selectedVenueId?: string;
  planId?: string;
  selectedUserId?: string;
  campaignId?: string;
  keyword?: string;
  keywords?: KeywordApiModel[];
  filterId?: string;
  videoId?: string;
  transactionId?: string;
}
export interface pageViewProps {
  url: string;
  venueId: string;
  userId?: string;
  email?: string;
  name?: string;
  videoId?: string;
}
// SEGMENT EVENT TRACK

// SEGMENT TRACK LINK OPTIONAL??
export const segmentTrackLink = (id: string, eventName: string, venueId: string) => {
  var link = document.getElementById(id);
  window.analytics.trackLink(link, eventName, { venueId });
};

// GA4 EVENT TRACK
const trackGAEvent: (arg0: eventProps) => void = ({ action, ...props }) => {
  window.gtag('event', action, {
    video_id: props.videoId || null,
    ...props,
  });
};
// GLOBAL TRACK PAGE VIEW
export const trackPageView: (arg0: pageViewProps) => void = ({
  url,
  videoId,
  venueId,
  userId,
  email,
  name,
}) => {
  (global as any).analytics.page(url, {
    venueId,
    videoId,
    userId,
    email,
    name,
  });
};
// GLOBAL EVENT TRACK
export const trackEvent: (arg0: eventProps) => void = ({ action, venueId, ...props }) => {
  if (process.env.REACT_APP_BUILD_ENV === 'development') {
    console.log(`%c tracked ${action}`, 'background: #222; color: yellow');
  }
  const formattedAction = action.replace('-> ', '').replace(/ /g, '_');
  trackGAEvent({ action: formattedAction, venueId, ...props });
};

export const trackAllEvents: (arg0: eventProps) => void = ({ action, ...eventProps }) => {
  window.analytics.track(action, {
    venueId: eventProps.venueId,
    videoId: eventProps.videoId,
    name: eventProps.name,
    userId: eventProps.userId,
    email: eventProps.email,
    platform: eventProps.platform,
    location: eventProps.location,
    videoUrl: eventProps.videoUrl,
    phoneNumber: eventProps.phoneNumber,
    form: eventProps.form,
    tos: eventProps.tos,
  });
};
