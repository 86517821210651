import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, Typography } from '@mui/material';
import { FC } from 'react';
import { animated, Transition } from 'react-spring';

import { theme } from '../../../theme';
import { KeywordApiModel } from '../../../api/models/keywords';

const OUTLINE_COLOR = '#DCDBE4';

interface ChipLabelProps {
  title: string;
  withIcon?: boolean;
}
const ChipLabel: FC<ChipLabelProps> = ({ title, withIcon }) => {
  return (
    <Typography
      color={theme.palette.common.deepDark}
      fontSize="12px"
      fontWeight="500"
      style={{ marginLeft: withIcon ? '-4px' : undefined }}
    >
      {title}
    </Typography>
  );
};

interface KeywordsPreviewProps {
  keywords: Array<KeywordApiModel>;
  onDelete?: (keywordItem: KeywordApiModel) => void;
  handleClick?: (item: KeywordApiModel) => void;
  borderless?: boolean;
}

export const KeywordsPreview: FC<KeywordsPreviewProps> = ({
  keywords,
  onDelete,
  borderless = false,
  handleClick,
}) => {
  return (
    <Box
      px={borderless ? 0 : '16px'}
      py={borderless ? 0 : '10px'}
      border={borderless ? 'none' : `1px solid ${OUTLINE_COLOR}`}
      borderRadius="10px"
      minHeight={borderless ? 'auto' : '64px'}
      maxHeight="250px"
      sx={{ overflow: 'auto' }}
    >
      <Transition
        native
        items={keywords}
        keys={keywords.map((item) => item.keyword)}
        from={{ opacity: 0 }}
        enter={{ opacity: 1 }}
        trail={400 / keywords.length}
      >
        {(style, { keyword, icon }) => (
          <animated.div
            style={{
              ...style,
              display: 'inline-block',
            }}
          >
            <Box display="inline-block" m="5px">
              <Chip
                variant="outlined"
                icon={<Box sx={{ w: '260px' }} />}
                label={<ChipLabel title={keyword} withIcon={!!icon} />}
                onClick={() => handleClick?.({ keyword, icon })}
                deleteIcon={
                  onDelete ? (
                    <ClearIcon style={{ color: theme.palette.common.deepDark, height: '14px' }} />
                  ) : (
                    <></>
                  )
                }
                onDelete={() => onDelete?.({ keyword, icon })}
                sx={{ pl: '6px' }}
              />
            </Box>
          </animated.div>
        )}
      </Transition>
    </Box>
  );
};
