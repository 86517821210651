import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { CampaignsApiModel } from '../../../api/models/campaigns';
import { SearchTargets } from '../../../api/models/searchFilter';
import { LayoutMode } from '../../../common/constants/constants';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getActiveIncentiveCampaign,
  getActiveIncentiveCampaignActivities,
} from '../../../store/slices/campaignsSlice';
import { getSearchFilters } from '../../../store/slices/searchFiltersSlice';
import EndCampaignModal from '../EndCampaignModal/EndCampaignModal';
import { LeaderBoardButton } from '../LeaderBoardButton/LeaderBoardButton';
import { ControlsBlock } from '../Rewards.style';
import { TableSection } from './TableSection/TableSection';

interface CampaignsTabProps {}
export const CampaignsTab: React.FC<CampaignsTabProps> = () => {
  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const activeCampaignId = useTypedSelector((state) => state.campaigns.activeCampaign?.id);

  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;
  const [editedCampaign, setEditedCampaign] = useState<null | CampaignsApiModel>(null);

  const [endCampaignModalVisible, setEndCampaignModalVisible] = useState(false);

  useEffect(() => {
    if (venueId) {
      dispatch(getActiveIncentiveCampaign(venueId));
      dispatch(getSearchFilters({ venueId, target: SearchTargets.INCENTIVE_CAMPAIGNS }));
    }
  }, [dispatch, venueId]);
  useEffect(() => {
    if (activeCampaignId) {
      dispatch(getActiveIncentiveCampaignActivities(activeCampaignId));
    }
  }, [dispatch, activeCampaignId]);

  const ExtraRightBlock = useMemo(
    () =>
      !isMobile && (
        <LeaderBoardButton
          editedCampaign={editedCampaign}
          setEditedCampaign={setEditedCampaign}
          onOpenEndCampaignModal={() => setEndCampaignModalVisible(true)}
        />
      ),
    [editedCampaign, isMobile],
  );
  const EndCampaignModalComponent = useMemo(
    () =>
      !isMobile && (
        <EndCampaignModal
          endCampaignModalVisible={endCampaignModalVisible}
          onClose={() => setEndCampaignModalVisible(false)}
        />
      ),
    [endCampaignModalVisible, isMobile],
  );

  const LeaderBoardButtonForMobile = useMemo(
    () =>
      isMobile && (
        <Box mt={4} mb={4} display="flex" justifyContent="center">
          <LeaderBoardButton
            editedCampaign={editedCampaign}
            setEditedCampaign={setEditedCampaign}
          />
        </Box>
      ),
    [editedCampaign, isMobile],
  );

  return (
    <>
      {EndCampaignModalComponent}
      <Box display="flex" justifyContent="flex-end">
        {ExtraRightBlock}
      </Box>
      <ControlsBlock noPadding>
        {LeaderBoardButtonForMobile}
        <TableSection
          onEditCampaign={setEditedCampaign}
          onEndCampaign={() => setEndCampaignModalVisible(true)}
        />
      </ControlsBlock>
    </>
  );
};
