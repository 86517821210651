import React, { FC, useState } from 'react';
import { useStyles } from './ExelInviteImport.styles';
import { Box, Typography } from '@mui/material';
import { InvitesTable } from '../InvitesTable/InvitesTable';
import { importQuickStartExelTableContent } from '../../../services/helpers/tableMappers';
import { DataSync, DataSyncSteps } from '../../../pages/webappSettings/DataSync/DataSync';
import { useTypedSelector } from '../../../store';
import { InviteStrings } from '../../localization/en';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../constants/events/quickstartEvents';

interface Props {
  onDataImported?: () => void;
  linkOnly?: boolean;
}

export const ExelInviteImport: FC<Props> = ({ onDataImported, linkOnly = false }) => {
  const classes = useStyles({ linkOnly });
  const [step, setStep] = useState<DataSyncSteps>(DataSyncSteps.IMPORT_DATA);
  const { items } = useTypedSelector((state) => state.dataSyncSlice.data);
  const { trackEvent } = useTrackEvent();

  const tableContent = importQuickStartExelTableContent({ items });

  const isImport = () => step === DataSyncSteps.IMPORT_DATA;

  return (
    <Box className={`${classes.container} ${linkOnly && classes.center}`}>
      <Typography className={classes.helpText}>{InviteStrings.NeedHelp}</Typography>
      <a
        href={`${window.location.origin}/Import_Excel_Template.xlsx`}
        download
        className={classes.templateLink}
        onClick={() => {
          trackEvent(QuickStartEventNames.quick_start_import_excel_template_link_click);
        }}
        id="download-excel-template-button"
      >
        {InviteStrings.TemplateLink}
      </a>
      {!linkOnly && (
        <>
          <Box className={classes.divider} />
          <>
            <Box className={isImport() ? classes.dropWrapper : ''}>
              <DataSync
                hideTabs={true}
                hideImportHistory={true}
                isQuickStart={true}
                autoApprove={true}
                onStepChange={setStep}
                onDataImported={onDataImported}
              />
            </Box>
            {isImport() && (
              <InvitesTable content={tableContent} totalItems={4} withRowDelete={false} />
            )}
          </>
        </>
      )}
    </Box>
  );
};
