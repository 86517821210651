import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideoStatusModalStyle = makeStyles((theme: Theme) => ({
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 434,
    backgroundColor: theme.palette.common.white,
    height: 319,
    borderWidth: 0,
    padding: 30,
    borderRadius: 15,
  },
  ModalTitle: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 14,
  },
  ModalText: {
    fontWeight: 400,
    fontSize: '13px',
    textAlign: 'center',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 14,
  },
  DescriptionTextArea: {
    border: '1px solid gray',
    borderRadius: '10px',
    width: '100%',
    height: 90,
    resize: 'none',
    fontSize: 15,
    fontWeight: 400,
    padding: 12,
    marginBottom: 14,
  },
  BtnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ModalBtn: {
    padding: '10px 36px',
    fontSize: '14px',
    borderWidth: 1,
    borderRadius: '30px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  ConfirmBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
