import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    '& svg': { transform: 'scale(1.5)' },
    minHeight: '50px',
    marginTop: '16px',
    marginBottom: '16px',
  },
  root: {
    padding: 0,
    borderRadius: 30,
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(26, 21, 56, 0.6)',
  },
  backDrop: {},
  wrapperEducationSection: {
    padding: '100px 8px',
    background: '#F5F6F8',
  },
  wrapperEducationCards: {
    background: '#F5F6F8',
    height: '100%',
    padding: '90px 0 90px 0',
  },
  wrapperMainContent: {
    background: theme.palette.common.white,
    padding: 0,
    height: '100%',
  },
  body: {
    '&>h3': {
      color: theme.palette.common.black,
      marginTop: '25px',
      fontSize: '24px',
      lineHeight: '40px',
      fontWeight: '500',
    },
    '&>p': {
      marginTop: '8px',
      marginBottom: '32px',
      fontSize: '14px',
      lineHeight: '160%',
      letterSpacing: '0.28px',
    },
  },
  textModal: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    marginTop: '20px',
    color: theme.palette.common.gray,
  },
  textfieldWrapper: {
    marginBottom: '32px',
  },
  SVInput: {
    width: '320px',
    '& input': {
      textAlign: 'center',
      borderRadius: '10px',
    },
  },
  button: {
    width: '320px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    padding: '10px 40px',
    '&>.MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  paper: {
    maxWidth: '520px',
    width: '100%',
    borderRadius: 30,
    overflow: 'visible',
  },
  close: {
    position: 'absolute',
    top: '-10px',
    right: '-7px',
    width: 50,
    height: 50,
    background: theme.palette.common.white,
    color: theme.palette.common.deepDark,
    boxShadow: '0px 9px 20px rgba(20, 21, 61, 0.1)',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  modalImage: {
    '&>img': {
      objectFit: 'contain',
      maxHeight: '450px',
      height: '100%',
    },
  },
  buttonCancel: {
    width: '320px',
    marginTop: '16px',
    borderRadius: 100,
    padding: '10px 40px',
    '& p': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));
