import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import './TransitionWrapper.css';

const Wrapper = styled.div`
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 20;
  width: 665px;
  @media (min-height: 768px) and (min-width: 768px) {
    margin: 0;
  }
  @media (min-height: 768px) and (orientation: landscape) {
    margin: 0;
  }
  @media (min-width: 768px) and (orientation: landscape) {
    margin: 0;
  }

  margin: 20px;
  height: calc(100vh - 20px);
  box-shadow: -4px 8px 48px rgba(10, 25, 107, 0.15);
  border-radius: 8px 0 0 8px;
  background-color: white;
`;

interface TransitionWrapperProps {
  show: boolean;
}
const TransitionWrapper: React.FC<TransitionWrapperProps> = ({ show, children }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return render ? (
    <Wrapper
      style={{ animation: `${show ? 'moveIn' : 'moveOut'} 0.5s ease-in-out` }}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </Wrapper>
  ) : null;
};

export default TransitionWrapper;
