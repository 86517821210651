import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    userSelect: 'none',
  },
  title: {
    position: 'absolute',
    top: '-25px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  select: {
    width: 'fit-content',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    padding: '10px 14px',
    color: '#667085',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    cursor: 'pointer',
    backgroundColor: '#FFF',
  },
  arrow: {
    marginLeft: '15px',
  },
  optionsWrapper: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    padding: '2px 6px',
    color: '#667085',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    marginTop: '5px',
    backgroundColor: '#fff',
    zIndex: 10,
  },
  option: {
    width: '100%',
    height: '44px',
    padding: '0 10px',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '0.1s',

    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  selected: {
    backgroundColor: '#F9FAFB',
  },
  checkmark: {
    width: '20px',
    height: '20px',

    '& path': {
      stroke: '#7F56D9',
    },
  },
}));
