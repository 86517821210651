import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { WebsiteRoutes } from '../../constants/routes';
import { Box, Avatar, Typography, Button } from '@mui/material';
import { useVideosDetailUserComponentStyles } from './VideosDetailUserComponent.style';
import { VideosDetailPluginComponent } from './VideosDetailPluginComponent';
import { AwardIconDv5 } from './AwardIconDv5';
import { RewardsPageStrings, VideoDetailsModalStringsDv5 } from '../../localization/en';
import { CampaignsApiModel } from '../../../api/models/campaigns';
import { addBonusPoints, resetAwardedBonusPoints } from '../../../store/slices/userDetailsSlice';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useToasts } from 'react-toast-notifications';
import {
  AddVideoToSocialVerse,
  DeleteVideoFromSocialVerse,
  getAllSocialVerse,
  GetVideosBySocialVerseId,
} from '../../../store/slices/socialVerseSlice';
import { ModalForAssignVideoToSV } from './ModalsVideoDetailuser/ModalForAssignVideoToSV';
import { AwardBonusPointsModal } from '../AwardBonusPointsModal/ModalForAwardsBonusPoints';
import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import { useControlModal } from '../../../services/hooks/useControlModal';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';
import { DEFAULT_POINTS_GOAL } from '../../constants/constants';

export interface SocialVerseAllApiModelWithCheck extends SocialVerseAllApiModel {
  isChecked: boolean;
}

export interface ICheckedList {
  id: string;
  name: string;
  isChecked: boolean;
  createdAt: Date;
}

interface IVideosDetailUserComponentProps {
  activeCampaign: CampaignsApiModel | null;
  userId: string;
  userPhotoUrl: string;
  userName: string;
  userDateCreated: string;
  isTopGolf: boolean;
  storyID: string;
  storyStatus: string;
  handleAddVideoToCard?: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
  onSocialVerseDelete?: (id: string) => void;
}

export const VideosDetailUserComponent: FC<IVideosDetailUserComponentProps> = ({
  activeCampaign,
  userId,
  userPhotoUrl,
  userName,
  userDateCreated,
  isTopGolf,
  storyID,
}) => {
  const styles = useVideosDetailUserComponentStyles();
  const CustomLink = (props: any) => <Link to={`${WebsiteRoutes.Heroes}/${userId}`} {...props} />;

  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();
  const params = useParams<{ id: string }>();
  const { isLoadingAddBonusPoints, isErrorAddBonusPoints, AwardedBonusPoints } = useTypedSelector(
    (state) => state.userDetails,
  );
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const { currUserActiveCampaign } = useTypedSelector((state) => state.campaigns);

  const { socialVerseAll, socialVerse } = useTypedSelector((state) => state.SocialVerseSlice);

  const listOfSocialVerses = useMemo(() => {
    return socialVerseAll ? socialVerseAll.filter((item) => item.venueId === venueId) : [];
  }, [socialVerseAll, venueId]);

  const existVideoInSV =
    listOfSocialVerses &&
    listOfSocialVerses.filter(
      (item) => item.topVideos && item.topVideos.some((item) => item.videoId === params.id),
    );
  const [modalAssign, toggleAssignModal] = useControlModal();
  const [modalReward, toggleRewardModal] = useControlModal();

  /*   const getSVContainingStory = listOfSocialVerses?.filter((sv) =>
    sv?.topVideos?.some((video) => video.videoId === storyID),
  ); */

  const initialCheckedList = useMemo(
    () =>
      listOfSocialVerses.map((sv) => ({
        id: sv.id,
        name: sv.name,
        isChecked: sv?.topVideos?.some((video) => video.videoId === storyID),
        createdAt: sv.createdAt,
      })),
    [storyID, listOfSocialVerses],
  );

  const [checkedList, setCheckedList] = useState<Array<ICheckedList>>(initialCheckedList ?? []);
  const [listContainigVideo, setlistContainigVideo] = useState(existVideoInSV);
  const [isDisabled, setIsDisabled] = useState(false);
  const { campaignUser } = useTypedSelector((state) => state.userDetails);

  const [userAwarded, setUserAwarded] = useState(false);
  const isButtonAwardActive =
    campaignUser && campaignUser.points < DEFAULT_POINTS_GOAL && !userAwarded;

  useEffect(() => {
    setCheckedList(initialCheckedList);
  }, [initialCheckedList]);

  useEffect(() => {
    venueId && dispatch(getAllSocialVerse({ venueId: venueId, withVideos: true }));
  }, [venueId, dispatch]);

  useEffect(() => {
    setlistContainigVideo(existVideoInSV);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, listOfSocialVerses]);

  useEffect(() => {
    if (isErrorAddBonusPoints) {
      addToast('Failed to update data', { appearance: 'error' });
    }
    if (!isLoadingAddBonusPoints && !isErrorAddBonusPoints && AwardedBonusPoints) {
      addToast(
        VideoDetailsModalStringsDv5.AssignBonusPointsModal.replace(
          '<bonus points>',
          DEFAULT_POINTS_GOAL + '',
        ),
        {
          appearance: 'success',
          autoDismissTimeout: 2500,
        },
      );
      dispatch(resetAwardedBonusPoints());
    }
  }, [isLoadingAddBonusPoints, isErrorAddBonusPoints, AwardedBonusPoints, addToast, dispatch]);

  const handleConfirm = () => {
    dispatch(
      addBonusPoints({
        id: userId,
        points: DEFAULT_POINTS_GOAL,
        campaignId: currUserActiveCampaign?.id,
      }),
    ).then(() => {
      setUserAwarded(true);
    });
  };
  const changeCheckbox = (id: string, isChecked: boolean) => {
    setCheckedList((prev) => [
      ...prev.map((sv) => (sv.id === id ? { ...sv, isChecked } : { ...sv })),
    ]);
  };
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const isChecked = event.target.checked;
    trackEvent(VideosEventNames.assignToSvCheckBoxClicked, {
      socialVerseId: id,
      value: isChecked.toString(),
    });

    changeCheckbox(id, isChecked);
  };

  const handleRemoveVideoFromSV = (id: string) => {
    trackEvent(SocialVerseEventNames.removeFromSocialVerse, {
      videoId: storyID,
      socialVerseId: id,
    });
    const newListVideo = listContainigVideo.filter((item) => item.id !== id);
    dispatch(DeleteVideoFromSocialVerse({ id, videoId: storyID }))
      .then(() => {
        addToast('Video deleted successfully', {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });

        dispatch(getAllSocialVerse({ venueId: venueId, withVideos: true })).then(() => {
          setlistContainigVideo(newListVideo);
          changeCheckbox(id, false);
        });
      })
      .catch((error) => {
        addToast('Error deleting video', {
          appearance: 'error',
          autoDismiss: true,
        });
      });
  };

  const handleConfirmAddingVideos = (data: ICheckedList[]) => {
    trackEvent(SocialVerseEventNames.addToSocialVerseConfirm, {
      videoId: storyID,
      socialVerseId: data.map((sv) => sv.id),
    });

    const getOnlyChangedSV: ICheckedList[] = checkedList.filter((sv) => {
      let res = false;
      initialCheckedList.map((el) => {
        if (el.id === sv.id && el.isChecked !== sv.isChecked) {
          res = true;
        }
        return el;
      });
      return res;
    });

    Promise.all(
      getOnlyChangedSV.map(
        (item) =>
          new Promise<void>((resolve) => {
            if (item.isChecked) {
              dispatch(AddVideoToSocialVerse({ videoId: storyID, id: item.id })).then(() =>
                resolve(),
              );
            } else {
              dispatch(DeleteVideoFromSocialVerse({ videoId: storyID, id: item.id })).then(() =>
                resolve(),
              );
            }
          }),
      ),
    ).then(() => {
      setCheckedList(data);
      dispatch(getAllSocialVerse({ venueId: venueId, withVideos: true }));
      if (params.id === socialVerse?.id) {
        dispatch(GetVideosBySocialVerseId({ id: params.id }));
      }

      setIsDisabled(false);
      toggleAssignModal();
    });
  };
  const handleAssignVideo = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    toggleAssignModal();
    /* setCheckedList(handleCreateCheckedListSV()); */

    /*  if (handleAddVideoToCard) {
      handleAddVideoToCard(event, id);
    } */
  };

  const handleCancel = () => {
    toggleAssignModal();
    setCheckedList(initialCheckedList);
    trackEvent(SocialVerseEventNames.addToSocialVerseCancel, { videoId: storyID });
  };

  if (socialVerseAll == null) {
    return null;
  }

  return (
    <div className={styles.userSectionContainer}>
      {!isTopGolf ? (
        <>
          <Box className={styles.UserBar} component={CustomLink}>
            <Avatar
              variant="circular"
              className={styles.avatar}
              alt={userName}
              src={userPhotoUrl}
            />
            <Box>
              <Typography className={styles.userName}>{userName}</Typography>
              <Typography className={styles.userDate}>
                {VideoDetailsModalStringsDv5.DateCreated}: {userDateCreated}
              </Typography>
            </Box>
          </Box>
        </>
      ) : null}
      {/* hide section for now 05-13-2022 */}
      <div className={styles.PluginSection}>
        <VideosDetailPluginComponent
          handleRemoveVideoFromSV={handleRemoveVideoFromSV}
          listSocialVerse={initialCheckedList}
          handleAddVideoToCard={handleAssignVideo}
          storyID={storyID}
        />
        {/* <ToggleVideo storyStatus={storyStatus} storyId={storyID} /> */}
      </div>

      <div className={styles.AwardSection}>
        <Button
          startIcon={<AwardIconDv5 color={!activeCampaign ? '#000000' : ''} />}
          variant="outlined"
          className={!isButtonAwardActive ? styles.AwardBtnDisabled : styles.AwardBtn}
          onClick={handleConfirm}
          disabled={!isButtonAwardActive}
        >
          {currUserActiveCampaign?.isNullReward
            ? RewardsPageStrings.EnableRecognition
            : RewardsPageStrings.EnableReward}
        </Button>
      </div>
      <AwardBonusPointsModal
        activeCampaignBonusPoints={DEFAULT_POINTS_GOAL}
        isOpenModal={modalReward}
        toggleModal={toggleRewardModal}
        handleConfirm={handleConfirm}
      />
      <ModalForAssignVideoToSV
        isDisabled={isDisabled}
        listSocialVerse={checkedList}
        isOpenModal={modalAssign}
        toggleModal={toggleAssignModal}
        handleConfirm={handleConfirmAddingVideos}
        handleChange={handleChangeCheckbox}
        handleCancel={handleCancel}
        type="checkbox"
      />
    </div>
  );
};
