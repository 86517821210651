import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { VideosEndpoints, MediaEndpoints } from '../../api/endpoints';
import { UploadUrlApiModel } from '../../api/models/upload';
import { httpClient } from '../../services/httpClient/httpClient';
import { UploadProcessDTO, UploadProcessStep } from '../storeModels';
import { VideoApiModel } from '../../api/models/videos';

export const uploadInitialState: UploadProcessDTO = {
  currentStep: UploadProcessStep.UploadVideo,
  cellPhone: '',
  email: '',
  handle: '',
  password: '',
  uploadingProgress: 0,
  supportedMimeType: 'video/mp4',
  fileSelected: false,
  thumbnailFrameurl: null,
};

export const createUploadUrl = async (userId?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${MediaEndpoints.CreateVideoUploadUrl}?userId=${userId}`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};
export const createUploadVideoUrl = async () => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${MediaEndpoints.CreateUploadUrl}?ext=mp4&prefix=welcome`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};

interface CreateStorageUploadUrlParams {
  ext: string;
  prefix: string;
  name?: string;
  contentType?: string;
}

export const createStorageUploadUrl = async (params: CreateStorageUploadUrlParams) => {
  try {
    return httpClient.post<CreateStorageUploadUrlParams, UploadUrlApiModel>({
      url: `${MediaEndpoints.CreateUploadUrl}`,
      params,
      requiresToken: true,
    });
  } catch (error) {
    return console.log(error);
  }
};

export const createUploadThumbnailUrl = async (mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${MediaEndpoints.CreateThumbnailUploadUrl}?mimeType=${mimeType}`,
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};

interface UploadVideoAsFileRequest {
  options: UploadUrlApiModel;
  data: File;
  onUploadProgress?: (event: ProgressEvent) => void;
}

export const uploadVideoAsFile = createAsyncThunk(
  'upload/uploadVideoAsFile',
  async ({ options, data, onUploadProgress }: UploadVideoAsFileRequest) => {
    if (!data) {
      return;
    }
    try {
      const res = await axios.put<typeof options, undefined>(options.url, data, {
        headers: options.headers,
        onUploadProgress,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
);

interface UploadVideoAsBlobRequest {
  options: UploadUrlApiModel;
  data: string;
  onUploadProgress?: (event: ProgressEvent) => void;
}

export const uploadVideoAsBlob = createAsyncThunk(
  'upload/uploadVideoAsBlob',
  async ({ options, data, onUploadProgress }: UploadVideoAsBlobRequest) => {
    if (!data) {
      return;
    }
    try {
      const file = await fetch(data).then((r) => r.blob());

      const res = await axios.put<typeof options, undefined>(options.url, file, {
        headers: options.headers,
        onUploadProgress,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  },
);

export interface UploadStoryDetails {
  metaTitle: string;
  metaDescription: string;
  videoText: string;
  ctaBtnText: string;
  ctaBtnLinkUrl: string | null;
  videoMessage: string;
}

export interface CreateStoryRequest {
  categoryId: string;
  url: string;
  thumbnailUrl: string;
  altThumbnailUrl: string;
  details: UploadStoryDetails;
  isPublic: boolean;
  userId: string;
  duration?: number;
}

export const CreateStory = createAsyncThunk(
  'upload/createStory',
  async (payload: CreateStoryRequest) => {
    try {
      return await httpClient.post<CreateStoryRequest, VideoApiModel>({
        url: `${VideosEndpoints.CreateVideo}?userId=${payload.userId}`,
        payload,
        requiresToken: true,
      });
    } catch (error) {
      console.log(error);
    }
  },
);

const uploadVideoSlice = createSlice({
  name: 'upload',
  initialState: uploadInitialState,
  reducers: {
    reset: () => uploadInitialState,
    setProgress(state, action: PayloadAction<{ value: number; videoUpload?: boolean }>) {
      if (!action.payload.videoUpload && action.payload.value > 95) {
        return;
      }
      state.uploadingProgress = action.payload.value;
    },
    setMimeType(state, action: PayloadAction<string>) {
      state.supportedMimeType = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(uploadVideoAsFile.pending, (state) => {
      state.currentStep = UploadProcessStep.VideoUploading;
    });
    reducersBuilder.addCase(CreateStory.fulfilled, (state) => {
      state.currentStep = UploadProcessStep.Uploaded;
      state.uploadingProgress = 100;
    });
  },
});

export const { reset, setProgress, setMimeType } = uploadVideoSlice.actions;
export default uploadVideoSlice.reducer;
