import { FC } from "react";

export const LogoutIcon: FC= () => (
<svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5589 17.5418V18.0836C12.5589 19.7405 11.2157 21.0836 9.55887 21.0836H5.74963C4.09278 21.0836 2.74963 19.7405 2.74963 18.0836V5.91626C2.74963 4.25941 4.09278 2.91626 5.74963 2.91626H9.55887C11.2157 2.91626 12.5589 4.25941 12.5589 5.91626V6.4581"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8231 7.90601L20.917 11.9874L16.8231 16.0813"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.917 11.9937H8.33472"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
 