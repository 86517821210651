import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: '430px',
    minHeight: '188px',
    width: 'fit-content',
    height: 'fit-content',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
    padding: '24px',
    position: 'relative',
  },
  detailsWrapper: {},
  status: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#5242EA',
    background: '#E4E1FF',
    borderRadius: '8px',
    minWidth: '53px',
    width: 'fit-content',
    height: '24px',
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  businessName: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#101828',
    marginTop: '4px',
  },
  website: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
    marginTop: '4px',
    color: '#475467',
  },
  location: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '24px',
    marginTop: '4px',
    color: '#475467',
  },
  businessNumber: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '24px',
    marginTop: '4px',
    color: '#475467',
  },
  divider: {
    marginTop: '24px',
  },

  btnDisconnect: {
    color: '#fff',
    background: '#5242EA',
    width: '112px',
    height: '40px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#163EC0',
    },
  },
  menuButton: {
    position: 'absolute',
    right: '8px',
    top: '15px',
  },
  disconnectButton: {
    width: '200px',
    height: '42px',
    border: '1px solid #EAECF0',
    fontSize: '12px',
    fontWeight: 400,
    color: '#344054',
  },
}));
