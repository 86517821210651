import { Box, Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ClientTypes } from '../../../api/models/common';
import { SalesDetailsModal } from '../../../common/components/NewDetailsModal/SalesDetailsModal';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { CommonTable } from '../../../common/components/table/CommonTable';
import TableFilterSelect, {
  TabItem,
} from '../../../common/components/TableFilterSelect/TableFilterSelect';
import {
  CampaignInvitesStrings,
  NotFoundPageStrings,
  RewardsPageStrings,
  VenueCreationWizardStrings,
} from '../../../common/localization/en';
import { existingCampaignUsersTableContent } from '../../../services/helpers/tableMappers';
import { useClientType } from '../../../services/hooks/useClientType';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  CampaignCountData,
  getCampaignUsers,
  goToSelectedPageExistingCampaignUsersTable,
  setExistingCampaignUsersTableSorting,
  updateExistingCampaignUsersTableSearch,
  updateExistingCampaignUsersTableSize,
} from '../../../store/slices/campaignsSlice';

import { useExistingCampaignUsersTableStyles } from './ExistingCampaignUsersTable.style';
import { approveReward } from '../../../store/slices/userDetailsSlice';
import { useToasts } from 'react-toast-notifications';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';

interface Prop {
  countData: CampaignCountData;
  handleTrackEvent: IEventTracker;
}

export const CampaignUsersTable: FC<Prop> = ({ countData, handleTrackEvent }) => {
  const styles = useExistingCampaignUsersTableStyles({});
  const { addToast } = useToasts();
  const modal = useRef(null);
  const dispatch = useAppDispatch();
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [isUserSlideoutOpen, setIsUserSlideoutOpen] = useState(false);

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { isTrubluClient } = useClientType();
  const { items, totalPages, totalItems, page, size, sort, search, isLoading } = useTypedSelector(
    (state) => state.campaigns.currentCampaignUsers,
  );
  const { currentCampaign, isCountDataLoading } = useTypedSelector((state) => state.campaigns);
  const { campaignUser } = useTypedSelector((state) => state.userDetails);
  const { id: campaignId } = useParams<{ id: string }>();
  const [updateTable, setUpdateTable] = useState(0);
  const currUserIdRef = useRef<string | null>(null);

  const tabsList: TabItem[] = useMemo(
    () => [
      {
        tabName: RewardsPageStrings.ViewAll,
        usersCount: (activeTabIndex === 0 && isCountDataLoading
          ? 0
          : countData.usersWithVideos
        ).toString(),
      },
      {
        tabName: RewardsPageStrings.GoalAchieved,
        usersCount: (activeTabIndex === 1 && isCountDataLoading
          ? 0
          : countData.fulfilledUsers
        ).toString(),
      },
    ],
    [activeTabIndex, countData, isCountDataLoading],
  );

  useEffect(() => {
    dispatch(
      getCampaignUsers({
        search,
        id: campaignId,
        pageable: { page, size, sort },
        filter: {
          fulfilled: activeTabIndex === 1 || null,
        },
        hasVideos: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, page, size, sort, search, activeTabIndex, updateTable]);

  useEffect(() => {
    /* update table if user points added or fulfilled status changed from user slideout,
    but prevent update on user slideout open */
    if (currUserIdRef.current !== campaignUser?.userId) {
      currUserIdRef.current = campaignUser?.userId || null;
      return;
    }

    dispatch(
      getCampaignUsers({
        search,
        id: campaignId,
        pageable: { page, size, sort },
        filter: {
          fulfilled: activeTabIndex === 1 || null,
        },
        hasVideos: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignUser?.points, campaignUser?.fulfilled, campaignUser?.videos]);

  const approveRewardHandler = useCallback(
    async (userId: string) => {
      const response = await dispatch(approveReward({ id: userId, campaignId }));

      if (response.payload) {
        addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        setUpdateTable((state) => state + 1);
      } else {
        addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
      }
    },
    [addToast, dispatch, campaignId],
  );
  const userDataTableContents = existingCampaignUsersTableContent({
    campaign: currentCampaign,
    items,
    venuType: clientType || ClientTypes.SALES,
    isTrubluClient,
    isInvites: true,
    approveRewardHandler,
    handleTrackEvent,
    isUserSlideoutOpen,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }
    dispatch(goToSelectedPageExistingCampaignUsersTable(convertedTargetPage));
  };
  const onSort = (name: string) => {
    dispatch(setExistingCampaignUsersTableSorting(name));
    handleTrackEvent(
      RewardCampaignsEvents.RewardCampaignsActiveCustomersTableSortClick.replace(
        'sort_by_column',
        `${tabsList[activeTabIndex].tabName}_tab_sort_by_${name}`,
      ).toLowerCase(),
    );
  };

  const onClickRow = (id: string) => {
    setSelectedUserId(id);
    setIsUserSlideoutOpen(true);
    handleTrackEvent(RewardCampaignsEvents.RewardCampaginTableRowClick, { selectedUserId: id });
  };
  const changeSize = (size: number) => {
    dispatch(updateExistingCampaignUsersTableSize(size));
  };
  const close = () => {
    setIsUserSlideoutOpen(false);
    setSelectedUserId('');
  };

  return (
    <Box>
      <>
        <Box className={styles.container}>
          <Grid container className={styles.top}>
            <TableFilterSelect
              tabsList={tabsList}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
              handleTrackEvent={handleTrackEvent}
              type="active"
            />
            <Box>
              <SearchBar
                entity={'campaigns'}
                updateSearch={updateExistingCampaignUsersTableSearch}
                adaptiveWidth={true}
                disabled={isLoading}
                placeholderText={`Search ${isTrubluClient ? 'Patient' : 'Creator'}`}
                borderColor={'#F2F4F7'}
                clearSearch={activeTabIndex}
                /* callUpdateSearchAsFunc={searchBarProps.callUpdateSearchAsFunc} */
              />
            </Box>
          </Grid>
          <Grid className={styles.table}>
            <CommonTable
              content={userDataTableContents}
              selectedRowId={selectedUserId}
              page={convertApiPageToFrontEndPage(page)}
              sort={sort}
              totalItems={totalItems}
              totalPages={totalPages}
              isLoading={isLoading}
              noContent={false}
              tablePadding="0"
              goToPage={onGoToPage}
              onSortHeaderClick={onSort}
              onClickRow={onClickRow}
              size={size}
              onSizeChange={changeSize}
              withSearchBar={true}
              labelRowsPerPage={CampaignInvitesStrings.View}
              roundedNavButtons={false}
              disableTopNav={true}
            />
          </Grid>
        </Box>
      </>
      <div ref={modal}>
        <SalesDetailsModal
          isOpen={isUserSlideoutOpen}
          userId={selectedUserId}
          handleClose={close}
          campaignId={campaignId}
          entity="campaigns"
        />
      </div>
    </Box>
  );
};
