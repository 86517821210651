import { Box, CardMedia, Typography } from '@mui/material';
import { FC, MouseEvent, useRef } from 'react';
import { IPhonePreview } from '../../../common/components/IPhonePreview/IPhonePreview';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { useTypedSelector } from '../../../store';
import backgroundImg from '../../../common/assets/newShareVideoPreview-2.png';
import TBbackgroundImg from '../../../common/assets/trublunewShareVideoPreview.jpg';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@material-ui/core';
import { FakeButton } from '../../../common/components/IPhonePreview/previewComponents/FakeButton';
import { useVideoControls } from '../../../common/components/VideoPlayer/PlayerControls.helper';
import { RewardStrings } from '../../../common/localization/en';
import { useVenue } from '../../../hooks/useVenue';

const useStyles = makeStyles<Theme>(() => ({
  previewWrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: '0',
    pointerEvents: 'all',
  },
  previewWrapNotTopGolf: {
    width: '100%',
    height: '91%',
    pointerEvents: 'all',
  },
  overLayImage: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    bottom: '2px',
    left: '2px',
    borderRadius: '10px',
  },
  logo: {
    zIndex: '2',
    objectFit: 'contain',
    objectPosition: 'left',
    height: '32px',
    position: 'absolute',
    top: '25px',
    left: '25px',
  },
  playIconWrapper: {
    position: 'absolute',
    bottom: '-16px',
    left: '-9px',
    overflow: 'hidden',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    width: '107%',
    height: '106%',
  },
  rectangle: {
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImg})`,
    border: 'none',
    margin: '0 auto',
  },
  tbrectangle: {
    backgroundSize: 'cover',
    backgroundImage: `url(${TBbackgroundImg})`,
  },

  PlayIcon: {
    zIndex: '2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffffff80',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },

  buttonWrapper: {
    zIndex: '2',
    position: 'absolute',
    height: '140px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    bottom: '0px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
  },
  uploadBtn: {
    marginTop: '12px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '26px',
    letterSpacing: '0.46000000834465027px',
    textAlign: 'center',
    textDecoration: 'underline',
    color: '#fff',
  },
}));

export const defaultVideos: Record<string, string> = {
  funny:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/v1/sv-dev-assets/welcome/57819015-981b-4fa3-8e0b-2f0e8f0f74e7.mp4',
  experiential:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/v1/sv-dev-assets/welcome/9392ebce-5e43-47f4-b749-f8454b3a9544.mp4',
  inspirational:
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/v1/sv-dev-assets/welcome/f06a1539-19af-4446-b45d-ace95d0ffc88.mp4',
  default:
    'https://res.cloudinary.com/socialvenu/video/upload/fl_attachment/ar_9:16,c_fill/v1/sv-dev-assets/welcome/667390ed-6ebf-4fb3-a4fa-c70b1716d0ad.mp4',
};

interface WelcomeVideoIPhonePreviewProps {
  videoUrl?: string;
}

const WelcomeVideoIPhonePreview: FC<WelcomeVideoIPhonePreviewProps> = ({ videoUrl }) => {
  const classes = useStyles();
  const { logo, venueProperties } = useVenue();
  const brandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const { welcomeVideoUrl } = useTypedSelector((state) => state.createCampaign);

  const mediaUrl = welcomeVideoUrl || defaultVideos.default;

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const {
    togglePlayer,
    handleOnTimeUpdate,

    playerState,
    setPlayerState,
  } = useVideoControls(refVideo);

  const togglePlay = () => {
    togglePlayer();
  };

  return (
    <IPhonePreview
      style={{
        height: '525px',
        borderBottomLeftRadius: '26px',
        borderBottomRightRadius: '26px',
        width: '100%',
      }}
    >
      {logo && <img src={logo} alt=" " className={classes.logo} />}
      <Box className={clsx(classes.previewWrap)}>
        <div className={clsx(classes.playIconWrapper)}>
          {!(playerState.isPlaying && playerState.currentTime > 0) && (
            <Box fontSize={45} className={classes.PlayIcon}>
              <PlayArrowRoundedIcon fontSize="inherit" color="inherit" />
            </Box>
          )}
        </div>
        <Box className={classes.buttonWrapper}>
          <FakeButton
            text="Record Video"
            color={brandColor || '#ED2E38'}
            minWidth="180px"
            height="45px"
            marginTop="35px"
            textAlign={'center'}
            width="fit-content"
            borderRadius="50px"
            fontSize={'14px'}
            fontWeight={'600'}
            margin={'0 auto'}
          />
          <Typography className={classes.uploadBtn}>{RewardStrings.UploadVideo}</Typography>
        </Box>
      </Box>
      <CardMedia
        component="video"
        ref={refVideo}
        onTimeUpdate={handleOnTimeUpdate}
        src={videoUrl || mediaUrl}
        onEnded={() => setPlayerState({ ...playerState, isPlaying: false })}
        onClick={(e: React.MouseEvent) => {
          togglePlay();
        }}
        onMouseUp={(e: MouseEvent) => e.stopPropagation()}
        style={{
          pointerEvents: 'all',
          position: 'absolute',
          borderBottomLeftRadius: '36px',
          borderBottomRightRadius: '36px',
        }}
      />
    </IPhonePreview>
  );
};

export default WelcomeVideoIPhonePreview;
