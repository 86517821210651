import { FC, useEffect } from 'react';
import { Box, Grid, Typography, Divider, IconButton } from '@mui/material';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { AccordionWithoutState } from './../../../../common/components/AccordionWithoutState/AccordionWithoutState';
import { CustomizationSocialVerse } from '../../../../common/localization/en';
import { CustomButton } from '../../../../common/components/customButton/customButton';
import { useStyles } from './addToWebsite.style';
import { SVModal } from '../../../../common/components/SVModal/SVModal';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getSocialVerseById } from '../../../../store/slices/socialVerseSlice';
import { useToasts } from 'react-toast-notifications';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useControlModal } from '../../../../services/hooks/useControlModal';

export const AddToWebsite: FC = () => {
  const classes = useStyles();
  const [isOpenModal, toggleModal] = useControlModal();
  const { id } = useParams<{ id: string }>();
  const { addToast } = useToasts();

  const dispatch = useAppDispatch();
  const socialverse = useTypedSelector((state) => state.SocialVerseSlice.socialVerse);
  useEffect(() => {
    if (id) {
      dispatch(getSocialVerseById({ id }));
    }
  }, [id, dispatch]);

  if (!socialverse) {
    return null;
  }
  const headerCodeScript = `<script type="module" src="https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.esm.js"></script>
  <script nomodule src="https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.js"></script>`;

  const headerCodeStringSecond = `<gidget-component widget-type='${socialverse.viewMode.replace(
    'blocks',
    'block',
  )}' venue-id='${socialverse.venueId}' heading='${
    socialverse.showTitle ? socialverse.title : ''
  }' socialverse-id='${socialverse.id}' show-block-hero-name='${socialverse.showUserInfo}'/>`;

  const handleCopyToClipboard = (data: string, header?: boolean) => {
    navigator.clipboard.writeText(data);
    const toastMessage = header
      ? CustomizationSocialVerse.HeadCopiedMessage
      : CustomizationSocialVerse.BodyCopiedMessage;
    addToast(toastMessage, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  return (
    <Box className={classes.root}>
      <SVModal openModal={isOpenModal} toggleModal={toggleModal} closeWithoutSendingConfig={true} />
      <Grid container>
        <Grid xs={5} className={classes.leftSide}>
          <Typography className={classes.title}>{CustomizationSocialVerse.CopyAndPaste}</Typography>
          <Divider />

          <AccordionWithoutState
            title={CustomizationSocialVerse.HeaderCode}
            number={1}
            subtitle={CustomizationSocialVerse.ShowOrHide}
            defaultOpen
          >
            <>
              <Typography className={classes.code}>
                {`<script type="module" src="https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.esm.js"></script>`}
                <br />
                {`<script nomodule src="https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.js"></script>`}
              </Typography>
              <Box className={classes.btnWrap}>
                <CustomButton
                  onClick={() => handleCopyToClipboard(headerCodeScript, true)}
                  title={CustomizationSocialVerse.CopyHeaderCode}
                  colorType="dark"
                  className={classes.btn}
                  startIcon={<ContentCopyRoundedIcon />}
                />
              </Box>
            </>
          </AccordionWithoutState>
          <Divider />
          <AccordionWithoutState
            number={2}
            title={CustomizationSocialVerse.BodyCode}
            subtitle={CustomizationSocialVerse.ShowHideVideoCount}
            defaultOpen
          >
            <>
              {/* <gidget-component
                widget-type={socialverse.viewMode.replace('blocks', 'block')}
                venue-id={socialverse.venueId}
                heading={socialverse.showTitle ? socialverse.title : ''}
                socialverse-id={socialverse.id}
                showBlockHeroName={socialverse.showUserInfo}
                showHeadingCounter={true}
              /> */}
              <Typography className={classes.code}>
                {`<gidget-component`}
                <br />
                {`widget-type='${socialverse.viewMode.replace('blocks', 'block')}'`}
                <br />
                {`venue-id='${socialverse.venueId}'`}
                <br />
                {`heading='${socialverse.showTitle ? socialverse.title : ''}'`}
                <br />
                {`socialverse-id='${socialverse.id}'`}
                <br />
                {`show-block-hero-name='${socialverse.showUserInfo}'`}
                <br />
                {' />'}
              </Typography>
              <Box className={classes.btnWrap}>
                <CustomButton
                  onClick={() => handleCopyToClipboard(headerCodeStringSecond)}
                  title={CustomizationSocialVerse.CopyBodyCode}
                  colorType="dark"
                  className={classes.btn}
                  startIcon={<ContentCopyRoundedIcon />}
                />
              </Box>
            </>
          </AccordionWithoutState>
        </Grid>
        <Grid xs={7} className={classes.rightSide}>
          <Box
            style={{
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              marginLeft: 'auto',
              backgroundColor: '#fff',
              padding: '10px',
              borderRadius: '50%',
            }}
          >
            <IconButton
              onClick={toggleModal}
              style={{
                backgroundColor: '#fff',
                color: '#8B89A0',
              }}
            >
              <HelpOutlineSharpIcon style={{ width: '18px', height: '18px', color: '#8B89A0' }} />
            </IconButton>
          </Box>
          <AccordionWithoutState title={CustomizationSocialVerse.BodyCode2} defaultOpen divider>
            <>
              <Box className={classes.accordionItemWrap} mt="20px">
                <Typography className={classes.numbers}>{1}</Typography>
                <Typography className={classes.itemTitle}>
                  {CustomizationSocialVerse.GoAndInsert1}
                  <strong>{CustomizationSocialVerse.GoAndInsert2}</strong>
                  {CustomizationSocialVerse.GoAndInsert3}
                </Typography>
              </Box>
              <Box className={classes.accordionItemWrap}>
                <Typography className={classes.numbers}>{2}</Typography>
                <Typography className={classes.itemTitle}>
                  {CustomizationSocialVerse.PasteCodeThenSave1}
                  <strong>{CustomizationSocialVerse.PasteCodeThenSave2}</strong>
                  {CustomizationSocialVerse.PasteCodeThenSave3}
                </Typography>
              </Box>
              <Box className={classes.html}>
                <Typography> {' <!DOCTYPE html>'}</Typography>
                <Typography> {'<htmllang="en">'}</Typography>
                <Typography> {'<head>'}</Typography>
                <Typography className={classes.padLeft}>
                  {`<script type="module" src="https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.esm.js"></script>`}
                  <br />
                  {`<script nomodule src="https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.js"></script>`}
                  <br />
                </Typography>
                <Typography> {'</head>'}</Typography>
                <Typography> {'<body>'}</Typography>
                <Typography className={classes.padLeft}>
                  {`<gidget-component`}
                  <br />
                  {`widget-type='${socialverse.viewMode.replace('blocks', 'block')}'`}
                  <br />
                  {`venue-id='${socialverse.venueId}'`}
                  <br />
                  {`heading='${socialverse.showTitle ? socialverse.title : ''}'`}
                  <br />
                  {`socialverse-id='${socialverse.id}'`}
                  <br />
                  {`show-block-hero-name='${socialverse.showUserInfo}'`}
                  <br />
                  {' />'}
                </Typography>
                <Typography> {'</body>'}</Typography>
                <Typography> {'</html>'}</Typography>
              </Box>
            </>
          </AccordionWithoutState>
        </Grid>
      </Grid>
    </Box>
  );
};
