import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch, useTypedSelector } from '../../../../store';

import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
  infinityTableScroll,
} from '../../../../services/utilities';

import { createRewardsRequestTableContent } from '../../../../services/helpers/tableMappers';

import { CommonTable } from '../../../../common/components/table/CommonTable';
import { TableSectionWrapper } from '../../../../common/components/table/CommonTable.style';

import {
  getHeroes,
  goToSelectedPage,
  reset,
  setUserDataSorting,
  updateSize,
} from '../../../../store/slices/heroesSlice';
import { updateSize as updatePageSize } from '../../../../store/slices/videosSlice';
import { useHistory } from 'react-router-dom';
import { getSearchFilters, setActiveFilter } from '../../../../store/slices/searchFiltersSlice';
import { SearchTargets } from '../../../../api/models/searchFilter';
import { CampaignActivitiesTypes } from '../../../../api/models/campaigns';
import { CreateSearchFilterComponent } from '../../../../common/components/CreateSearchFilterComponent/CreateSearchFilterComponent';
import { Box } from '@mui/material';
import { ActionBar, ActiveModal } from '../../../../common/components/ActionBar/ActionBar';
import { activeModalLiteral } from '../../../../common/components/ActionBar/ActionBarModals';
import { getActiveIncentiveCampaignActivities } from '../../../../store/slices/campaignsSlice';
import { useLeaderBoardTablesStyles } from './TableSection.style';
import { approveReward } from '../../../../store/slices/userDetailsSlice';
import {
  NotFoundPageStrings,
  VenueCreationWizardStrings,
} from '../../../../common/localization/en';
import { useToasts } from 'react-toast-notifications';

interface ITableSectionProps {
  isLeaderBoard?: boolean;
}

export const TableSection: React.FC<ITableSectionProps> = ({ isLeaderBoard }) => {
  const styles = useLeaderBoardTablesStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
    lastUpdated,
  } = useTypedSelector((state) => state.heroes);
  const { addToast } = useToasts();
  const { selectedRows } = useTypedSelector((state) => state.videos);
  const [isRedeemPointsModal, setIsRedeemPointsModal] = useState(false);
  const venueId = useTypedSelector((state) => state.venue.venue.id);
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const activities = useTypedSelector((state) => state.campaigns.activeCampaign?.activities);

  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const defaultIncentiveConfig = venueProperties?.['webapp.incentive.config'];

  useEffect(() => {
    if (venueId) {
      dispatch(
        getHeroes({
          search,
          venueId,
          pageable: { page, size, sort },
          hasInfiniteScroll: true,
        }),
      );
      dispatch(getSearchFilters({ venueId, target: SearchTargets.INCENTIVE_CAMPAIGNS }));
    }
  }, [dispatch, page, size, sort, search, venueId, lastUpdated, activeFilter, isLeaderBoard]);

  useEffect(() => {
    return () => {
      dispatch(setActiveFilter(null));
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (activeCampaign?.id) {
      dispatch(getActiveIncentiveCampaignActivities(activeCampaign?.id));
    }
  }, [dispatch, activeCampaign?.id]);

  const onRewardClick = (userId: string) => history.push(`/rewards/leaderboard/${userId}`);

  const toggleRedeemPointsModal = useCallback(async () => {
    setIsRedeemPointsModal(!isRedeemPointsModal);
  }, [isRedeemPointsModal]);

  const approveRewardHandler = useCallback(
    async (userId) => {
      const response = await dispatch(approveReward({ id: userId }));
      if (response.payload) {
        addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        dispatch(
          getHeroes({
            search,
            venueId,
            pageable: { page, size, sort },
          }),
        );
        toggleRedeemPointsModal();
        return;
      }
      addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
      dispatch(
        getHeroes({
          search,
          venueId,
          pageable: { page, size, sort },
        }),
      );
      toggleRedeemPointsModal();
    },
    [addToast, dispatch, toggleRedeemPointsModal, page, size, sort, venueId, search],
  );

  const tableContents = createRewardsRequestTableContent({
    items,
    onRewardClick: onRewardClick,
    activeCampaign: activeCampaign,
    renderCheckbox: activeFilter ? true : false,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setUserDataSorting(name));
  };

  const changeSize = (size: number) => {
    dispatch(updateSize(size));
    dispatch(updatePageSize(size));
  };

  const handleInfinityScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    infinityTableScroll(e, isLoading, onGoToPage, page);
  };

  const handleBtnClick = (name: typeof activeModal) => {
    setActiveModal(name);
  };

  const handleClose = () => {
    setActiveModal(null);
  };
  const onClickRow = (id: string) => {
    onRewardClick(id);
  };

  /*  const contentDataTableContents = createRewardsRequestTableContent({
    items: selectedRows || [],
    onRewardClick: (userId) => history.push(`/rewards/leaderboard/${userId}`),
    activeCampaign: activeCampaign,
    renderCheckbox: false,
  }); */

  const headers = [
    { label: 'Full name ', key: 'name' },
    { label: 'CampaignPoints', key: 'campaignPoints' },
    { label: 'Current points ', key: 'points' },
    { label: 'Rewards owed', key: 'rewardsFulfilled' },
  ];

  const bonusPoints = defaultIncentiveConfig?.activities.find(
    (activity) => activity.activityType === CampaignActivitiesTypes.BonusPoints,
  );

  const hasActiveCampaignBonusPoints =
    activities &&
    activities.find((activity) => activity.activityType === CampaignActivitiesTypes.BonusPoints);
  const isAwardsBonusPointsActive = activeCampaign && hasActiveCampaignBonusPoints ? true : false;
  return (
    <>
      <Box paddingBottom={'23px'}>
        {!activeFilter && (
          <CreateSearchFilterComponent
            target={SearchTargets.INCENTIVE_CAMPAIGNS}
            isSocailVerse={false}
          />
        )}
        {activeFilter && (
          <ActionBar
            setActiveModal={(name) => handleBtnClick(name)}
            selectedRowsLength={selectedRows?.length}
            isAwardsBonusPointsActive={isAwardsBonusPointsActive}
          />
        )}
        {activeModal === 'DOWNLOAD_CSV' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, headers)}
        {activeModal === 'ASSIGN_BONUS_POINTS' &&
          activeModalLiteral[activeModal](
            Boolean(activeModal),
            handleClose,
            selectedRows ?? [],
            bonusPoints?.points,
          )}
      </Box>

      <TableSectionWrapper onScroll={handleInfinityScroll}>
        <div className={styles.LeaderBoardTable}>
          <CommonTable
            content={tableContents}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
            onClickRow={onClickRow}
          />
        </div>
      </TableSectionWrapper>
    </>
  );
};
