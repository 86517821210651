import React, { FC, useEffect } from 'react';
import { useStyles } from './CustomizationAccordion.styles';
import { Box, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { Tooltip } from '../Tooltip/Tooltip';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { BoxProps } from '@material-ui/core';
import { AccordionArrow } from '../../assets/newDesign/AccordionArrow';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { NewCampaignEvents } from '../../constants/events/newCampaignEvents';

export interface AccordionItem {
  subroute: string;
  title: string;
  tooltipText?: string;
  sectionContent: React.ReactNode;
  onNextClick?: () => void;
  withToolTip?: boolean;
}

interface Props extends BoxProps {
  accordionItems: AccordionItem[];
  baseRoute: string;
  setActiveTabIndex?: (index: number) => void;
  handleTrackEvent: IEventTracker;
  isEditMode?: boolean;
  setVisitedAccordions: (acc: string) => void;
}

export const CustomizationAccordion: FC<Props> = ({
  accordionItems,
  padding,
  baseRoute,
  setActiveTabIndex,
  handleTrackEvent,
  isEditMode,
  setVisitedAccordions,
  ...boxProps
}) => {
  const classes = useStyles();
  const history = useHistory();

  const currentRoute = useLocation().pathname;

  const activeTabIndex = accordionItems.findIndex((item) => currentRoute.endsWith(item.subroute));

  useEffect(() => {
    if (activeTabIndex === -1) {
      history.push(`${currentRoute}/${accordionItems[0].subroute}`);
    }

    setActiveTabIndex?.(activeTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  return (
    <Box className={classes.container} {...boxProps}>
      <Stepper
        activeStep={activeTabIndex}
        orientation="vertical"
        className={classes.stepper}
        nonLinear={true}
      >
        {accordionItems.map((item, index) => {
          const isActiveStep = activeTabIndex === index;

          console.log('item:', item);

          return (
            <Step
              id={`${isEditMode ? 'edit_campaign_step_' : 'create_campaign_step_'}${index + 1}`}
              key={item.title}
              className={classes.step}
              onClick={() => {
                if (!isActiveStep) {
                  history.push(`${baseRoute}/${item.subroute}`);
                  handleTrackEvent(
                    NewCampaignEvents.NewRewardCampaignAccordionClick.replace(
                      'number',
                      `${index + 1}`,
                    ),
                  );
                }
                setVisitedAccordions(item.subroute);
              }}
            >
              <StepLabel
                className={classes.stepLabel}
                sx={{
                  '& .MuiSvgIcon-root': {
                    display: isEditMode ? 'none' : 'block',
                  },
                }}
              >
                <Typography
                  className={classes.labelText}
                  style={{
                    color: isActiveStep ? '#5242EA' : '#1A1538',
                  }}
                >
                  {item.title}
                  {item.tooltipText && item.withToolTip && (
                    <Tooltip
                      padding="23px 25px"
                      rounded="15px"
                      title={item.tooltipText}
                      icon={<QuestionMarkRoundedIcon className={classes.tooltip} />}
                    />
                  )}
                </Typography>

                <AccordionArrow
                  transform={isActiveStep ? 'rotate(90deg)' : 'none'}
                  className={classes.expandIcon}
                />
              </StepLabel>

              <StepContent className={classes.stepContent}>
                <Box>{item.sectionContent}</Box>
              </StepContent>
              <Box className={classes.stepDivider} />
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
