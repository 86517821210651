import React, { FC, useEffect, useRef, useState } from 'react';
import { useStyles } from './WelcomeVideoModal.styles';
import { FSModal } from '../../../../../common/components/modal/Modal';
import { CampaignsApiModel } from '../../../../../api/models/campaigns';
import { Box, Button, CardMedia, Divider, Typography } from '@mui/material';
import { WelcomeVideoModalStrings } from '../../../../../common/localization/en';
import { RoundedButton } from '../../../../../common/components/Button/RoundedButton';
import { useVideoControls } from '../../../../../common/components/VideoPlayer/PlayerControls.helper';
import { PlayArrowRounded } from '@mui/icons-material';
import { defaultVideos } from '../../../../NewCampaign/WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import EditWelcomeVideo from './EditWelcomeVideo/EditWelcomeVideo';
import { VideoStyleTypes } from '../../../../NewCampaign/VideoStyleTypesSwitch/VideoStyleTypesSwitch';

interface Props {
  currentCampaign: CampaignsApiModel;
  isOpen: boolean;
  onClose: () => void;
  isEdit?: boolean;
}

const WelcomeVideoModal: FC<Props> = ({ currentCampaign, isOpen, onClose, isEdit }) => {
  const styles = useStyles();

  const [isEditMode, setIsEditMode] = useState(isEdit);

  useEffect(() => {
    setIsEditMode(isEdit || false);
  }, [isEdit]);

  const videoStyleKey = currentCampaign.recommendedVideoStyle
    ? ((currentCampaign.recommendedVideoStyle.slice(0, 1).toUpperCase() +
        currentCampaign.recommendedVideoStyle.slice(1)) as keyof typeof VideoStyleTypes)
    : undefined;
  const [selectedStyle, setSelectedStyle] = useState(
    videoStyleKey ? VideoStyleTypes[videoStyleKey] : VideoStyleTypes.Inspirational,
  );

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setPlayerState({ ...playerState, isPlaying: false });
    }, 100);
  };

  const handleVideoStyleChange = (videoStyle: VideoStyleTypes) => {
    setSelectedStyle(videoStyle);
  };

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const { togglePlayer, handleOnTimeUpdate, playerState, setPlayerState } = useVideoControls(
    refVideo,
    false,
  );

  const getVideoUrl = () => {
    if (currentCampaign.showWelcomeVideo && currentCampaign.welcomeVideoUrl) {
      return currentCampaign.welcomeVideoUrl;
    } else {
      return defaultVideos.default;
    }
  };

  return (
    <FSModal
      onClose={handleClose}
      modalIsOpen={isOpen}
      rounded="12px"
      blur
      width="652px"
      padding="0"
    >
      <Box className={styles.container}>
        <Typography className={styles.title}>{WelcomeVideoModalStrings.Title}</Typography>
        {!currentCampaign.showWelcomeVideo && !isEditMode ? (
          <Typography className={styles.subTitle}>
            {WelcomeVideoModalStrings.NoWelcomeVideoSubTitle}
          </Typography>
        ) : (
          <Typography className={styles.subTitle}>{WelcomeVideoModalStrings.SubTitle}</Typography>
        )}
        <Divider className={styles.divider} />
        {isEditMode ? (
          <EditWelcomeVideo
            currentCampaign={currentCampaign}
            onClose={handleClose}
            onCancel={() => {
              if (isEdit) {
                handleClose();
              } else {
                setIsEditMode(false);
              }
            }}
            selectedStyle={selectedStyle}
            onSelectedStyleChange={handleVideoStyleChange}
          />
        ) : (
          <Box className={styles.videoWrapper}>
            {!playerState.isPlaying && (
              <Box fontSize={45} className={styles.playIcon}>
                <PlayArrowRounded fontSize="inherit" color="inherit" />
              </Box>
            )}
            <CardMedia
              component="video"
              ref={refVideo}
              onTimeUpdate={handleOnTimeUpdate}
              src={getVideoUrl()}
              onEnded={() => setPlayerState({ ...playerState, isPlaying: false })}
              onClick={(e: React.MouseEvent) => {
                togglePlayer();
              }}
              style={{ pointerEvents: 'all' }}
            />
          </Box>
        )}
        {!isEditMode && (
          <>
            <RoundedButton
              title={WelcomeVideoModalStrings.EditWelcomeVideo}
              borderRadius="8px"
              variant="outlined"
              textColor="#5242EA"
              fontWeight={600}
              onClick={() => {
                if (!currentCampaign.showWelcomeVideo) {
                  setSelectedStyle(VideoStyleTypes.Inspirational);
                }
                setIsEditMode(true);
              }}
              borderWidth="2px"
              sx={{ marginTop: '20px' }}
            />
            <Button className={styles.closeButton} onClick={handleClose}>
              {WelcomeVideoModalStrings.CloseWindow}
            </Button>
          </>
        )}
      </Box>
    </FSModal>
  );
};

export default WelcomeVideoModal;
