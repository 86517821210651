import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch, useParams } from 'react-router-dom';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { createUserDataTableContent } from '../../../../services/helpers/tableMappers';
import {
  getHeroes,
  getUsersBySearchFilter,
  goToSelectedPage,
  reset,
  setUserDataSorting,
  updateSearch,
  updateSize,
} from '../../../../store/slices/heroesSlice';
import { updateSize as updatePageSize } from '../../../../store/slices/videosSlice';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import { ClientTypes } from '../../../../api/models/common';
import { useToasts } from 'react-toast-notifications';
import { approveReward } from '../../../../store/slices/userDetailsSlice';
import {
  HeroesPageStrings,
  NotFoundPageStrings,
  VenueCreationWizardStrings,
} from '../../../../common/localization/en';
import { setActiveFilter } from '../../../../store/slices/searchFiltersSlice';
import { useUsersTablesStyles } from './HeroesTable.style';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../../../common/constants/events/creatorsTableEvents';
import { Box, Button, Grid } from '@mui/material';
import { SearchBar } from '../../../../common/components/searchBar/SearchBar';
import { CreateSearchFilterComponent } from '../../../../common/components/CreateSearchFilterComponent/CreateSearchFilterComponent';
import { SearchTargets } from '../../../../api/models/searchFilter';
import { AddCreatorIcon } from '../../../../common/assets/AddCreatorIcon';
import InviteCreatorModal from '../../../../common/components/InviteCreatorModal/InviteCreatorModal';
import { AddIndividualCustomerModal } from '../../../rewards/CampaignSummary/Modals/AddIndividualCustomerModal';
import { CampaignsApiModel } from '../../../../api/models/campaigns';

interface HeroesTableProps {
  showActionBar: boolean;
  hasVideos: boolean;
  activeTab: number;
  totalItems: number;
}

export const HeroesTable: FC<HeroesTableProps> = ({
  showActionBar,
  hasVideos,
  activeTab,
  totalItems,
}) => {
  const styles = useUsersTablesStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const match = useRouteMatch();
  const {
    isLoading,
    error,
    itemsSplit,
    totalPages,
    page,
    size,
    sort,
    search = '',
  } = useTypedSelector((state) => state.heroes);
  const { userDetails } = useTypedSelector((state) => state.userDetails);
  const venueId = useTypedSelector((state) => state.venue.venue.id);
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);
  const clientType = useTypedSelector((state) => state.venue.venue.clientType);
  const { activeCampaign, items } = useTypedSelector((state) => state.campaigns);
  const [isRedeemPointsModal, setIsRedeemPointsModal] = useState(false);
  const [isInviteCreatorModalOpen, setIsInviteCreatorModalOpen] = useState(false);
  const [isCreatorInfoOpen, setIsCreatorInfoOpen] = useState(false);
  const params = useParams<{ id: string }>();
  const { isHealthCare } = useClientType();
  const [isMounted, setIsMounted] = useState(false);

  const activeCampaigns = items
    .filter((campaign) => campaign.endedAt === null)
    .sort((a, b) => {
      return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
    });

  const [selectedCampaign, setSelectedCampaign] = useState(activeCampaigns[0]);
  const [activeInviteOption, setActiveInviteOption] = useState(0);

  const { trackEvent } = useTrackEvent();

  const toggleRedeemPointsModal = useCallback(async () => {
    setIsRedeemPointsModal(!isRedeemPointsModal);
  }, [isRedeemPointsModal]);

  const approveRewardHandler = useCallback(
    async (userId) => {
      const response = await dispatch(approveReward({ id: userId }));
      if (response.payload) {
        addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        dispatch(
          getHeroes({
            search,
            venueId,
            pageable: { page, size, sort },
            hasVideos: !activeTab,
          }),
        );
        toggleRedeemPointsModal();
        return;
      }
      addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
      dispatch(
        getHeroes({
          search,
          venueId,
          pageable: { page, size, sort },
          hasVideos: !activeTab,
        }),
      );
      toggleRedeemPointsModal();
    },
    [addToast, dispatch, toggleRedeemPointsModal, page, size, sort, venueId, search, activeTab],
  );

  useEffect(() => {
    if (!selectedCampaign) {
      setSelectedCampaign(activeCampaigns[0]);
    }
  }, [activeCampaigns, selectedCampaign]);

  useEffect(() => {
    return () => {
      dispatch(setActiveFilter(null));
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isMounted) {
      dispatch(reset());
      setIsMounted(true);
      return;
    }

    if (venueId) {
      if (activeFilter) {
        dispatch(
          getUsersBySearchFilter({
            venueId,
            filter: activeFilter,
            pageable: {
              page,
              size,
              sort,
            },
            search,
          }),
        );
      } else {
        dispatch(
          getHeroes({
            search,
            venueId,
            // status: `${UserStatuses.active}`,
            pageable: { page, size, sort },
            hasVideos: true,
          }),
        );
        dispatch(
          getHeroes({
            search,
            venueId,
            // status: `${UserStatuses.active}`,
            pageable: { page, size, sort },
            hasVideos: false,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, size, sort, search, venueId, userDetails, activeFilter, activeTab]);

  const userDataTableContents = createUserDataTableContent({
    items: hasVideos ? itemsSplit?.active || [] : itemsSplit?.pending || [],
    activeCampaign,
    venuType: clientType || ClientTypes.SALES,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
    renderCheckbox: activeFilter ? true : false,
    isHealthCare,
    isPending: hasVideos === false,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setUserDataSorting(name));

    trackEvent(CreatorsTableEventNames.SortTableClick.replace('name', name));
  };

  const onClickRow = (id: string) => {
    history.push(match.path.replace(':id', id));

    trackEvent(CreatorsTableEventNames.RowClick, { selectedUserId: id });
  };

  const changeSize = (size: number) => {
    dispatch(updateSize(size));
    dispatch(updatePageSize(size));
  };

  const handleSelectCampaign = (campaign: CampaignsApiModel) => {
    setSelectedCampaign(campaign);
  };

  const handleInviteCreatorNextStep = () => {
    setIsInviteCreatorModalOpen(false);
    setIsCreatorInfoOpen(true);
  };

  return (
    <>
      <AddIndividualCustomerModal
        isOpen={isCreatorInfoOpen}
        onClose={() => {
          setIsCreatorInfoOpen(false);
          setActiveInviteOption(0);
        }}
        campaignId={selectedCampaign?.id}
        withInvite={activeInviteOption === 0}
      />
      <InviteCreatorModal
        isOpen={isInviteCreatorModalOpen}
        onClose={() => {
          setIsInviteCreatorModalOpen(false);
          setActiveInviteOption(0);
        }}
        onNextStep={handleInviteCreatorNextStep}
        campaigns={activeCampaigns}
        selectedCampaign={selectedCampaign}
        onCampaignSelect={handleSelectCampaign}
        activeInviteOption={activeInviteOption}
        onInviteOptionChange={(index: number) => setActiveInviteOption(index)}
      />
      <Box className={styles.contentWrapper}>
        <Box className={styles.line} />
        {showActionBar && (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={styles.topBarWrapper}
          >
            <Box className={styles.searchWrapper}>
              <SearchBar
                entity="heroes"
                updateSearch={updateSearch}
                adaptiveWidth={true}
                placeholderText={
                  isHealthCare ? HeroesPageStrings.SearchPatients : HeroesPageStrings.SearchCreators
                }
              />
            </Box>
            <Grid className={styles.buttonsWrapper}>
              <CreateSearchFilterComponent target={SearchTargets.USERS} isSocailVerse={false} />
              <Button
                variant="outlined"
                className={styles.button}
                startIcon={<AddCreatorIcon />}
                onClick={() => setIsInviteCreatorModalOpen(true)}
              >
                {HeroesPageStrings.AddCreator}
              </Button>
            </Grid>
          </Grid>
        )}
        <Box className={styles.UsersTableStyles}>
          <CommonTable
            selectedRowId={params?.id || ''}
            content={userDataTableContents}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
            withSearchBar={false}
            disableTopNav={true}
            searchBarProps={{
              adaptiveWidth: true,
              entity: 'heroes',
              updateSearch,
            }}
          />
        </Box>
      </Box>
    </>
  );
};
