import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { createColor } from 'material-ui-color';

import { useAppDispatch, useTypedSelector } from '../../store';

import { CampaignsPageStrings } from '../../common/localization/en';
import { TableSection } from './TableSection/TableSection';
import { Preview } from './Preview/Preview';
import { TitleSection } from '../../common/components/TitleSection/TitleSection';
import { TopBar } from '../../common/components/topBar/TopBar';
import { getCampaigns } from '../../store/slices/campaignsSlice';
import { ControlsBlock, SectionDivider } from '../../common/styles/commonStyles.style';

interface CampaignsPageProps {}
export const CampaignsPage: React.FC<CampaignsPageProps> = () => {
  const {
    venue: { logo, id: venueId },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const brandColor = createColor(venueProperties?.['webapp.config']?.['primary-color'] || '#000');
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { isLoading, error, items, totalPages, totalItems, page, size, sort, lastUpdated } =
    useTypedSelector((state) => state.campaigns);

  useEffect(() => {
    dispatch(getCampaigns({ venueId, pageable: { page, size, sort } }));
  }, [dispatch, page, size, sort, venueId]);

  const selectedItem = items.find((item) => item.id === id);

  return (
    <>
      <TopBar />
      <Box height={24} />
      <Grid container>
        <Grid item xs={12} md={7}>
          <ControlsBlock>
            <TitleSection
              title={CampaignsPageStrings.Title}
              subTitle={CampaignsPageStrings.SubTitle}
            />
            <SectionDivider />

            <TableSection
              isLoading={isLoading}
              error={error}
              items={items}
              totalPages={totalPages}
              totalItems={totalItems}
              page={page}
              size={size}
              sort={sort}
              lastUpdated={lastUpdated}
              selectedItemId={selectedItem && selectedItem.id}
            />
          </ControlsBlock>
        </Grid>
        <Grid item xs={12} md={5}>
          <Preview
            brandColor={'#' + brandColor.hex}
            filterUrl={selectedItem && selectedItem.filterUrl}
            logo={logo}
          />
        </Grid>
      </Grid>
    </>
  );
};
