import { FC } from 'react';
export const UserIconPlush: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.9987 10.3333H4.9987C4.06832 10.3333 3.60313 10.3333 3.2246 10.4482C2.37233 10.7067 1.70539 11.3736 1.44686 12.2259C1.33203 12.6044 1.33203 13.0696 1.33203 14M12.6654 14V10M10.6654 12H14.6654M9.66536 5C9.66536 6.65685 8.32222 8 6.66536 8C5.00851 8 3.66536 6.65685 3.66536 5C3.66536 3.34315 5.00851 2 6.66536 2C8.32222 2 9.66536 3.34315 9.66536 5Z"
      stroke="#344054"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
