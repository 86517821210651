import { FC, useCallback } from 'react';
import { FormikProps } from 'formik';
import { FormikValues } from './SocialVerseSettingsPage';
import { useStyles } from './SettingsPreview.style';
import { Grid, Box } from '@mui/material';
import { DesktopIcon } from '../../../common/assets/newDesign/SocialVerse/DesktopIcon';
import { MobileIcon } from '../../../common/assets/newDesign/MobileIcon';
import { TypeSocialVerse } from '../../../common/constants/constants';
import { CardsPreview } from './previews/CardsPreview/CardsPreview';
import { MobilePreview } from './previews/MobilePreview/MobilePreview';
import { previewCardsMobile } from './previews/CardsPreview/CardPreview.helpers';
import {
  SpheresPreviewDeskop,
  SpheresPreviewMobile,
} from './previews/SpheresPreview/SpheresPreview';
import { previewPerspectiveMobile } from './previews/PerspectivePreview/PerspectivePreview.helpers';
import PerspectivePreview from './previews/PerspectivePreview/PerspectivePreview';
import GridPreview from './previews/GridPreview/GridPreview';
import EducationalPreview from './previews/EducationalPreview/EducationalPreview';

interface Preview {
  preview: JSX.Element;
  mobilePreview: JSX.Element;
}

interface Props {
  formik: FormikProps<FormikValues>;
  viewMode: TypeSocialVerse;
}

export const SettingsPreview: FC<Props> = ({ formik, viewMode }) => {
  const classes = useStyles();
  const USER_INFO_EXAMPLE = 'Danny J';

  const getSphereBoxShadow = useCallback(() => {
    return formik.values.withGlow
      ? `0px 0px 14px 3px ${formik.values.glowColor}`
      : '0px 4px 12px 0px rgb(0 0 0 / 50%)';
  }, [formik.values.glowColor, formik.values.withGlow]);

  const PreviewsMap: Record<TypeSocialVerse, Preview> = {
    [TypeSocialVerse.Circles]: {
      preview: (
        <SpheresPreviewDeskop
          formik={formik}
          userInfo={USER_INFO_EXAMPLE}
          getSphereBoxShadow={getSphereBoxShadow}
        />
      ),
      mobilePreview: (
        <SpheresPreviewMobile
          formik={formik}
          userInfo={USER_INFO_EXAMPLE}
          getSphereBoxShadow={getSphereBoxShadow}
        />
      ),
    },
    [TypeSocialVerse.Spheres]: {
      preview: (
        <SpheresPreviewDeskop
          formik={formik}
          userInfo={USER_INFO_EXAMPLE}
          getSphereBoxShadow={getSphereBoxShadow}
        />
      ),
      mobilePreview: (
        <SpheresPreviewMobile
          formik={formik}
          userInfo={USER_INFO_EXAMPLE}
          getSphereBoxShadow={getSphereBoxShadow}
        />
      ),
    },
    [TypeSocialVerse.Cards]: {
      preview: <CardsPreview formik={formik} />,
      mobilePreview: <MobilePreview formik={formik} previewCards={previewCardsMobile} />,
    },
    [TypeSocialVerse.Grid]: {
      preview: <GridPreview formik={formik} />,
      mobilePreview: <MobilePreview formik={formik} previewCards={previewCardsMobile} />,
    },
    [TypeSocialVerse.Perspective]: {
      preview: <PerspectivePreview formik={formik} />,
      mobilePreview: <MobilePreview formik={formik} previewCards={previewPerspectiveMobile} />,
    },
    [TypeSocialVerse.Educational]: {
      preview: <EducationalPreview formik={formik} />,
      mobilePreview: <EducationalPreview formik={formik} isMobile />,
    },
  };

  return (
    <>
      <Grid className={classes.container} style={{ paddingBottom: formik.dirty ? '80px' : '20px' }}>
        <Box className={classes.desktopWrapper}>
          <Box className={classes.sectionIcon}>
            <DesktopIcon />
          </Box>
          {PreviewsMap[viewMode].preview}
        </Box>

        <Box
          className={classes.mobileWrapper}
          style={{
            width:
              viewMode === TypeSocialVerse.Circles || viewMode === TypeSocialVerse.Spheres
                ? '347px'
                : '295px',
          }}
        >
          <Box className={classes.sectionIcon}>
            <MobileIcon />
          </Box>
          {PreviewsMap[viewMode].mobilePreview}
        </Box>
      </Grid>
    </>
  );
};
