export const QrLink = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2000_44001" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="42">
        <circle cx="20.7558" cy="21" r="20.7558" fill="#EEECFC" />
      </mask>
      <g mask="url(#mask0_2000_44001)">
        <circle cx="20.7558" cy="21" r="20.7558" fill="#EEECFC" />
        <path
          d="M16.3086 0.244141L16.3086 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.41406 0.244141L7.41406 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.2031 0.244141L25.2031 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.0977 0.244141L34.0977 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="11"
          y="10.2441"
          width="4.66198"
          height="4.66198"
          rx="1"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="29.6973"
          y="10.2441"
          width="4.66198"
          height="4.66198"
          rx="1"
          transform="rotate(90 29.6973 10.2441)"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 19.5469H13.331"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.0649 19.5469H25.0363"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3936 10.2441L20.3936 12.5751"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3936 17.2158L20.3936 19.5468"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.6973 19.5469L29.6973 21.0623"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3936 24.2783L20.3936 28.9403"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.0322 19.6162L25.0322 24.2782"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx="29.6954"
          cy="28.9396"
          r="5.3039"
          fill="white"
          stroke="#5242EA"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.4049 29.2441L29.7005 30.9486L27.9953 29.2433"
          stroke="#5242EA"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.6987 30.9492L29.6987 26.9299"
          stroke="#5242EA"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="11"
          y="24.2783"
          width="4.66198"
          height="4.66198"
          rx="1"
          stroke="#5242EA"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
