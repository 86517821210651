import { GetVideosRequestWithSearchFilterOptions } from './../../api/models/videos';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { TopGolfVideosEndpoints, getApiUrlForId } from '../../api/endpoints';
import { GetTableDataResponse } from '../../api/models/common';
import {
  GetVideosRequest,
  GetVideosRequestOptions,
  UpdateVideoRequestOptions,
  UpdateVideoRequest,
  GetVideoByIdRequestOptions,
  SharesAnalyticsData,
} from '../../api/models/videos';
import { getTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import { TableDTO } from '../storeModels';
import { TopGolfVideosApiModel } from '../../api/models/topgolfVideos';

const initialState: TableDTO<TopGolfVideosApiModel> = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
};

export const updateTopgolfVideoById = createAsyncThunk(
  'videos/updateVideo',
  async (options: UpdateVideoRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.put<UpdateVideoRequest, TopGolfVideosApiModel>({
        url: getApiUrlForId(TopGolfVideosEndpoints.UpdateVideoById, options.id),
        requiresToken: true,
        payload: options.update,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getVideosBySearchFilter = createAsyncThunk(
  'videos/getTGStoriesBySearchFilter',
  async (options: GetVideosRequestWithSearchFilterOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<GetVideosRequest, GetTableDataResponse<TopGolfVideosApiModel>>({
        url: TopGolfVideosEndpoints.getVideosBySearchFilter,
        requiresToken: true,
        payload: {
          attributes:
            'id' in options.filter ? [...options.filter.filter.attributes] : [...options.filter],
        },
        params: {
          venueId: options.venueId,
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
          search: options.search,
          withTranscript: true,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getTopGolfVideoById = createAsyncThunk(
  'videos/getVideoById',
  async (options: GetVideoByIdRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<{}, TopGolfVideosApiModel>({
        url: getApiUrlForId(TopGolfVideosEndpoints.GetVideoById, options.id),
        params: {
          withTranscript: true,
        },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getTopGolfVideoByIdForSocialVerse = createAsyncThunk(
  'videos/getTopGolfVideoByIdForSocialVerse',
  async (options: GetVideoByIdRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<{}, TopGolfVideosApiModel>({
        url: getApiUrlForId(TopGolfVideosEndpoints.GetVideoById, options.id),
        params: {
          withTranscript: true,
        },
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getTopGolfVideoSharesById = createAsyncThunk(
  'videos/getTopGolfVideoSharesById',
  async (options: GetVideoByIdRequestOptions, { rejectWithValue }) => {
    try {
      const response = await httpClient.get<{}, SharesAnalyticsData>({
        url: getApiUrlForId(TopGolfVideosEndpoints.GetVideosShareSummary, options.id),
        requiresToken: true,
      });
      const payload = {
        id: options.id,
        sharesAnalyticsData: response,
      };
      return payload;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

//rangeFilter
interface GetTopgolfVideosRequest extends GetVideosRequest {
  shotTimeFrom?: string;
  shotTimeTo?: string;
  withTranscript?: boolean;
}
interface GetTopGolfVideosRequestOptions extends GetVideosRequestOptions {
  shotTimeFrom?: string;
  shotTimeTo?: string;
}

export const getTopGolfVideos = createAsyncThunk(
  'videos/getTopGolfVideos',
  async (options: GetTopGolfVideosRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<
        GetTopgolfVideosRequest,
        GetTableDataResponse<TopGolfVideosApiModel>
      >({
        url: TopGolfVideosEndpoints.GetVideos,
        requiresToken: true,
        params: {
          venueId: options.venueId,
          userId: options.userId,
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
          status: options.pageable.status?.toUpperCase(),
          search: options.search,
          deleted: options.pageable.deleted?.toString(),
          shotTimeFrom: options.shotTimeFrom,
          shotTimeTo: options.shotTimeTo,
          withTranscript: true,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const topgGolfVideosSlice = createSlice({
  name: 'topgolfVideos',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setVideosSorting(state, action: PayloadAction<string>) {
      const sort = getTableSorting(current(state), action.payload);

      return {
        ...initialState,
        sort,
        search: state.search,
        size: state.size,
      };
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    reset: () => initialState,
    updateSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = 0;
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = initialState.page;
    },
    resetPagination(state) {
      state.page = defaultPagination.page;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getVideosBySearchFilter.fulfilled, (state, { payload }) => {
      return tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(getTopGolfVideos.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getTopGolfVideos.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getTopGolfVideos.fulfilled, (state, { payload }) => {
      let items: TopGolfVideosApiModel[];
      if (payload.page === 0) {
        items = payload.items;
      } else {
        items = [...state.items, ...payload.items];
      }

      payload.items = items;

      return tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(updateTopgolfVideoById.fulfilled, (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.id !== payload.id) {
          return item;
        } else {
          return {
            ...item,
            publishedToWebapp: payload.publishedToWebapp,
            status: payload.status,
            featured: payload.featured,
            thumbnailUrl: payload.thumbnailUrl,
          };
        }
      });
      state.lastUpdated = new Date().toISOString();
    });
    reducersBuilder.addCase(getTopGolfVideoById.fulfilled, (state, { payload }) => {
      state.items = [...state.items, payload];
      state.isLoading = false;
    });
    reducersBuilder.addCase(getTopGolfVideoById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getTopGolfVideoById.rejected, (state, { payload }) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getTopGolfVideoByIdForSocialVerse.fulfilled, (state, { payload }) => {
      const index = state.items.findIndex((item) => item.id === payload.id);
      if (index !== -1) {
        state.items[index] = payload;
      }
      state.isLoading = false;
    });
    reducersBuilder.addCase(getTopGolfVideoByIdForSocialVerse.pending, (state, { payload }) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getTopGolfVideoByIdForSocialVerse.rejected, (state, { payload }) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getTopGolfVideoSharesById.fulfilled, (state, { payload }) => {
      state.items = state.items.map((item) => {
        if (item.id !== payload.id) {
          return item;
        } else {
          const newItem = {
            ...item,
            sharesAnalyticsData: payload.sharesAnalyticsData,
          };
          return newItem;
        }
      });
    });
  },
});

export const {
  goToSelectedPage,
  updateTable,
  setVideosSorting,
  reset,
  updateSize,
  updateSearch,
  resetPagination,
} = topgGolfVideosSlice.actions;
export default topgGolfVideosSlice.reducer;
