import { FC } from 'react';
interface IYouTubeShortsIconProps {
  color?: string;
}
export const YouTubeShortsIcon: FC<IYouTubeShortsIconProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 98.94 122.88"
      enableBackground="new 0 0 98.94 122.88"
      xmlSpace="preserve"
    >
      <path
        d="M63.49 2.71c11.59-6.04 25.94-1.64 32.04 9.83 6.1 11.47 1.65 25.66-9.94 31.7l-9.53 5.01c8.21.3 16.04 4.81 20.14 12.52 6.1 11.47 1.66 25.66-9.94 31.7l-50.82 26.7c-11.59 6.04-25.94 1.64-32.04-9.83-6.1-11.47-1.65-25.66 9.94-31.7l9.53-5.01c-8.21-.3-16.04-4.81-20.14-12.52-6.1-11.47-1.65-25.66 9.94-31.7l50.82-26.7zM36.06 42.53l30.76 18.99-30.76 18.9V42.53z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color || '#8B89A0'}
      />
    </svg>
  );
};
