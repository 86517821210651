import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';

import { LoginPageStrings } from '../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  authRequest,
  getUserAccounts,
  PartnerLogin,
  ResetAuthError,
} from '../../store/slices/meSlice';
import { ILoginProps } from './Login';
import { LoginContainer, LoginForm, useLoginStyles } from './Login.style';
import {
  initialValuesLoginForm,
  LoginFormValues,
  validationSchemaLoginForm,
} from './LoginFormsHelpers';
import { LoginActionTypes, useLoginReducer } from './LoginState';
// import { WebsiteRoutes } from '../../common/constants/routes';
import { AdornmentPosition, TextFieldComponent } from '../../common/components/TextField/TextField';
import { useLocation } from 'react-router-dom';
import { setSelectedVenue } from '../../store/slices/venueSlice';
import { UnprotectedRoutes } from '../../common/constants/routes';
import { useHandleChangeWithTracking } from '../../services/hooks/useHandleChangeWithTracking';
import { SignUpEventNames } from '../../common/constants/events/signUpEventNames';
import { useTrackEvent } from '../../hooks/useTrackEvent';

export const LoginFormComponent: FC<ILoginProps> = ({ setStep }) => {
  const styles = useLoginStyles();
  const history = useHistory();

  const appDispatch = useAppDispatch();
  const query = new URLSearchParams(useLocation().search);
  const [loginData, dispatch] = useLoginReducer();

  const [showPassword, setShowPassword] = useState(false);
  const partnerToken = query.get('partner-token');
  const venueId = query.get('venueId');
  const { trackEvent } = useTrackEvent();

  const { auth, authError } = useTypedSelector((state) => ({
    auth: state.me.auth,
    authError: state.me.authError,
  }));

  useEffect(() => {
    if (partnerToken && venueId) {
      appDispatch(PartnerLogin({ token: partnerToken, venueId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth) {
      appDispatch(setSelectedVenue({ id: venueId }));
      appDispatch(getUserAccounts({}));
      // history.push(WebsiteRoutes.DashboardHeroes);
    }
  }, [auth, appDispatch, venueId]);

  const LoginFormik = useFormik({
    initialValues: initialValuesLoginForm,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchemaLoginForm,

    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const { handleSubmit, values, handleChange, errors } = LoginFormik;

  const { handleChangeWithTracking } = useHandleChangeWithTracking(handleChange);

  useEffect(() => {
    dispatch({
      type: LoginActionTypes.SET_ACTIVE_SUBMIT_BUTTON,
      payload: true,
    });
  }, [values.email, values.password, dispatch, appDispatch, authError]);

  const submitForm = (values: LoginFormValues) => {
    dispatch({
      type: LoginActionTypes.SET_ACTIVE_SUBMIT_BUTTON,
      payload: false,
    });

    appDispatch(authRequest({ username: values.email, password: values.password }));
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const resetAuthError = () => {
    if (authError) {
      appDispatch(ResetAuthError());
    }
  };

  const canSave = () => {
    const isInnitialValues = _.isEqual(values, initialValuesLoginForm);
    return loginData && !isInnitialValues;
  };

  return (
    <LoginContainer>
      <LoginForm onSubmit={handleSubmit}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography className={styles.Title}>{LoginPageStrings.SignIn}</Typography>
          </Grid>
          <Grid item sx={{ paddingTop: '12px !important' }}>
            <Typography className={styles.SubTitle}>
              {LoginPageStrings.LoginPageSubTitle}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={styles.Caption}>{LoginPageStrings.Email}</Typography>
            <TextField
              fullWidth
              error={(errors.email && true) || authError}
              name="email"
              value={values.email}
              id="email"
              className={styles.helperText}
              helperText={
                (errors.email && LoginPageStrings.EmailValidationError) ||
                (authError && LoginPageStrings.InvalidUsername)
              }
              FormHelperTextProps={{
                classes: styles.helperText,
              }}
              variant="outlined"
              placeholder={LoginPageStrings.UserNameLabel}
              onChange={handleChangeWithTracking}
            />
          </Grid>

          <Grid
            item
            style={{ position: 'relative' }}
            className={authError ? styles.pt0 : undefined}
          >
            <Typography className={styles.Caption}>{LoginPageStrings.Password}</Typography>
            <TextFieldComponent
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={(event) => {
                handleChangeWithTracking(event, true);
              }}
              placeholder={LoginPageStrings.Password}
              adornmentPosition={AdornmentPosition.End}
              helperText
              adornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
              fullWidth
              errorText={
                errors.password || authError ? LoginPageStrings.InvalidPassword : undefined
              }
              hideErrorText={!!authError}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justifyContent="start" marginBottom={'16px'}>
              <Button onClick={() => setStep('forgotPasswordRequest')}>
                {LoginPageStrings.ForgotPassword}
              </Button>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Box className={styles.SubmitBtn}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!canSave()}
                  onClick={() => {
                    resetAuthError();
                    trackEvent(SignUpEventNames.singInButtonClicked);
                  }}
                  className={styles.Button}
                >
                  {LoginPageStrings.LoginBtn}
                </Button>
              </Box>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              marginTop={'30px'}
              alignItems="center"
            >
              <Typography className={styles.SubTitle}>
                {LoginPageStrings.DoNotHaveAccount}
              </Typography>
              <Button
                onClick={() => {
                  history.push(UnprotectedRoutes.SignUp);
                  trackEvent(SignUpEventNames.singInButtonClicked);
                }}
              >
                {LoginPageStrings.SignUp}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </LoginForm>
    </LoginContainer>
  );
};
