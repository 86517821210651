import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: ' 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    // flexDirection: 'column',

    gap: '10px',
  },
  Button: {
    backgroundColor: theme.palette.common.white,
    color: '#1A1538',
    fontWeight: 400,
    fontSize: '14px',
    border: '1px solid #5242EA',

    height: '44px',
    '& svg': {
      color: theme.palette.grey[500],
    },
  },
  Badge: {
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.white,
    alignContent: 'center',
    justifyItems: 'center',
  },
  ActiveBadge: {
    backgroundColor: theme.palette.primary.main,
  },
  BadgeContent: {
    textAlign: 'center',
    fontSize: '12px',
  },
}));
