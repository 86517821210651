import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { v4 as uuidv4 } from 'uuid';
import { CreateTransactionBatchResponse, ManualRecord } from '../../../../api/models/transaction';
import { CustomTextField } from '../../../../common/components/customTextField/CustomTextField';
import { Toggle } from '../../../../common/components/toggle/CustomToggle';
import { CommonEventNames } from '../../../../common/constants/events/common';
import { SmsImportUserStrings } from '../../../../common/localization/en';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { useClientType } from '../../../../services/hooks/useClientType';
import { handleChangeWithPattern } from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { addUsersToCampaign, getCampaignCountData } from '../../../../store/slices/campaignsSlice';
import { postTransactionBatch, putTransactionBatch } from '../../../../store/slices/dataSyncSlice';
import { validationSchema } from '../../../integrations/SmsInvite/Sections/ImportUsers/AddUsersManually/AddUsersManually.helpers';
import { useStyles } from './AddIndividualCustomerModal.styles';
import {
  SMSInvitationType,
  SMSInvitationTypeSelector,
} from '../../../../common/components/SMSInvitationTypeSelector/SMSInvitationTypeSelector';
import { createUser, getHeroes } from '../../../../store/slices/heroesSlice';
import { Close } from '@mui/icons-material';

interface AddIndividualCustomerModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignId: string;
  withInvite?: boolean;
}

export const AddIndividualCustomerModal: FC<AddIndividualCustomerModalProps> = ({
  isOpen,
  onClose,
  campaignId,
  withInvite = true,
}) => {
  const classes = useStyles();
  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { page, size, sort, search = '' } = useTypedSelector((state) => state.heroes);

  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const [isLoading, setIsLoading] = useState(false);
  const [isOptInTouched, setIsOptInTouched] = useState(false);

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    optedIn: false,
    optIn: '',
    attributes: {
      additionalProp1: '',
      additionalProp2: '',
      additionalProp3: '',
    },
    refId: '',
    email: '',
    transactionDate: '',
    derivedTransactionDate: '',
    smsInvitationType: SMSInvitationType.SAVE_FOR_LATER,
  };

  const {
    values,
    handleChange,
    errors,
    isValid,
    touched,
    handleBlur,
    submitForm,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    onSubmit: () => {
      setIsLoading(true);
      const recordData = {
        ...values,
        phoneNumber: `+1${values.phoneNumber}`,
        id: uuidv4(),
      };

      handleImportSubmit(
        recordData,
        values.smsInvitationType === SMSInvitationType.SEND_IMMEDIATELY,
      );

      resetForm();
      setIsOptInTouched(false);
    },
  });

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeWithPattern({
      event,
      prevVal: values.phoneNumber,
      pattern: '(###) ###-####',
      setVal: (val: string) => {
        setFieldValue('phoneNumber', val);
      },
    });
  };

  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? (errors[field] as string) : undefined;
  };

  const handleImportSubmit = useCallback(
    async (record: ManualRecord, autoApprove: boolean) => {
      if (withInvite) {
        const batch = await dispatch(
          postTransactionBatch({
            name: `${new Date().toDateString()} import`,
            venueId,
            records: [record],
            campaignId,
          }),
        );

        if (autoApprove) {
          await dispatch(
            putTransactionBatch({ id: (batch.payload as CreateTransactionBatchResponse).id }),
          );
          addToast(
            `${record.firstName} ${record.lastName} ${SmsImportUserStrings.HasBeenInvited}`,
            {
              appearance: 'success',
              autoDismiss: true,
            },
          );
        } else {
          addToast(SmsImportUserStrings.SuccessSaveForLater, {
            appearance: 'success',
            autoDismiss: true,
          });
        }

        setIsLoading(false);
      } else {
        try {
          const user = await createUser({
            venueId,
            email: record.email || undefined,
            name: `${record.firstName} ${record.lastName}`,
            phoneNumber: record.phoneNumber || '',
          });

          dispatch(addUsersToCampaign({ campaignId, payload: { userIds: [user.id] } }));
          dispatch(
            getHeroes({
              search,
              venueId,
              // status: `${UserStatuses.active}`,
              pageable: { page, size, sort },
              hasVideos: false,
            }),
          );

          addToast(`${record.firstName} ${record.lastName} ${SmsImportUserStrings.HasBeenAdded}`, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          console.error(err);
        }

        setIsLoading(false);
      }

      onClose();
      dispatch(getCampaignCountData({ campaignId }));
    },
    [venueId, addToast, dispatch, onClose, campaignId, withInvite, page, search, size, sort],
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        resetForm();
        onClose();
      }}
      style={{
        background: 'rgba(26, 21, 56, 0.5)',
        backdropFilter: 'blur(2.5px)',
        width: '100%',
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <Box className={classes.inputsWrapper}>
        <button
          onClick={() => {
            resetForm();
            onClose();
          }}
          className={classes.closeIcon}
        >
          <Close />
        </button>
        <Typography className={classes.title}>
          {isHealthCare
            ? SmsImportUserStrings.PatientInformation
            : SmsImportUserStrings.CreatorInformation}
        </Typography>
        <Box className={classes.fieldsWrapper}>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.FirstName}
                value={values.firstName}
                name="firstName"
                handleChange={(e) => {
                  handleChange(e);
                  trackEvent(CommonEventNames.add_users_manually_first_name_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('firstName')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.FirstNamePlaceholder}
                onBlur={handleBlur}
              />
            </Box>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.LastName}
                value={values.lastName}
                name="lastName"
                handleChange={(e) => {
                  handleChange(e);
                  trackEvent(CommonEventNames.add_users_manually_last_name_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('lastName')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.LastNamePlaceholder}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.CellNumber}
                value={values.phoneNumber}
                name="phoneNumber"
                handleChange={(e) => {
                  handlePhoneNumberChange(e);
                  trackEvent(CommonEventNames.add_users_manually_cell_number_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('phoneNumber')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.PhonePlaceholder}
                onBlur={handleBlur}
              />
            </Box>
            <Box className={classes.inputWrapper}>
              {withInvite && (
                <SMSInvitationTypeSelector
                  value={values.smsInvitationType}
                  onChange={(value) => {
                    setFieldValue('smsInvitationType', value);
                  }}
                />
              )}
            </Box>
          </Box>
          <Box className={classes.optInWrapper}>
            <Typography className={classes.optInLabel}>{SmsImportUserStrings.OptIn}</Typography>
            <Toggle
              name="optedIn"
              value={values.optedIn}
              onChange={(checked) => {
                setFieldValue('optedIn', checked);
                setIsOptInTouched(true);
                trackEvent(CommonEventNames.add_users_manually_opt_in_status_changed, {
                  value: checked ? 'checked' : 'unchecked',
                });
              }}
              className={classes.toggle}
              error={(isOptInTouched && errors['optedIn']) || ''}
            />
          </Box>
        </Box>

        <LoadingButton
          loading={isLoading}
          disabled={isLoading || !isValid}
          variant="contained"
          color="primary"
          size="large"
          style={{ borderRadius: '8px', margin: '32px 24px 0 24px', width: 'calc(100% - 48px)' }}
          fullWidth
          onClick={() => {
            submitForm();
            trackEvent(CommonEventNames.add_users_manually_import_data_button_click);
          }}
          id="add-individual-customer-button"
        >
          {isHealthCare ? SmsImportUserStrings.InvitePatient : SmsImportUserStrings.InviteCustomer}
        </LoadingButton>
      </Box>
    </Modal>
  );
};
