import styled from 'styled-components';

export const SearchBarContainer = styled.form<{ adaptiveWidth?: boolean; disabled?: boolean }>`
  &:hover {
    cursor: ${(props) => (props.disabled ? 'wait' : 'auto')};
  }
  display: flex;
  align-items: center;
  width: ${(props) => (props.adaptiveWidth ? 'auto' : '100%')};

  button {
    width: 96px;
    height: 44px;
    cursor: inherit !important;
  }
  svg {
    cursor: inherit !important;
  }
  input {
    height: 24px;
    line-height: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-right-radius: 26px;
    border-top-right-radius: 26px;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
    -webkit-text-fill-color: #000;
    cursor: inherit !important;
  }

  .MuiInputBase-formControl {
    background: #fff;
    border-radius: 26px;
    width: 320px;
    cursor: inherit;

    &.Mui-focused {
      // background: #ccc;
      &:after {
        transform: scaleX(0);
      }
    }

    .MuiInputAdornment-root {
      margin-top: 2px !important;
    }
  }
`;

export const SearchIcon = styled.img`
  width: 18px;
  height: 18px;
  color: var(--grey300);
`;
