import * as React from 'react';
import { SVGProps } from 'react';
export const ClassicIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={48}
    height={40}
    viewBox="0 0 48 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1179_33194)">
      <g clipPath="url(#clip1_1179_33194)">
        <rect
          x={0.344987}
          y={4.34499}
          width={16.91}
          height={32.31}
          rx={1.83855}
          fill="#D9D4FF"
          stroke="#5242EA"
          strokeWidth={0.689974}
        />
        <path
          d="M10.5595 20.608C10.7004 20.6996 10.7805 20.867 10.7805 21.0498C10.7805 21.2326 10.7004 21.4 10.5595 21.4916L7.44227 23.5182C7.30676 23.6063 7.16004 23.5964 7.03975 23.5182C6.91679 23.4383 6.81874 23.2826 6.81874 23.0764L6.81874 19.0232C6.81874 18.817 6.91679 18.6613 7.03975 18.5814C7.16004 18.5032 7.30676 18.4933 7.44227 18.5814L10.5595 20.608Z"
          fill="#1A1538"
          stroke="#1A1538"
          strokeWidth={0.438267}
        />
        <path
          d="M3.21875 8.5835L11.9213 8.5835"
          stroke="#1A1538"
          strokeWidth={0.689974}
          strokeLinecap="round"
        />
        <path
          d="M3.21777 12.6831L8.13288 12.6831"
          stroke="#1A1538"
          strokeWidth={0.689974}
          strokeLinecap="round"
        />
        <rect
          x={22.9446}
          y={4.34499}
          width={16.7267}
          height={32.31}
          rx={1.83855}
          stroke="#1A1538"
          strokeWidth={0.689974}
        />
        <path
          d="M34.2 20.9184C34.5706 21.1324 34.5706 21.6674 34.2 21.8814L30.433 24.0562C30.0623 24.2702 29.599 24.0027 29.599 23.5747L29.599 19.225C29.599 18.797 30.0623 18.5295 30.433 18.7435L34.2 20.9184Z"
          fill="#1A1538"
          stroke="#1A1538"
          strokeWidth={0.438267}
        />
        <path
          d="M25.1357 8.33447L31.9944 8.33447"
          stroke="#1A1538"
          strokeWidth={0.689974}
          strokeLinecap="round"
        />
        <rect
          x={45.3616}
          y={4.34499}
          width={17.6434}
          height={32.31}
          rx={1.83855}
          stroke="#1A1538"
          strokeWidth={0.689974}
        />
        <path
          d="M47.9199 8.33447L54.7786 8.33447"
          stroke="#1A1538"
          strokeWidth={0.689974}
          strokeLinecap="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1179_33194">
        <rect width={48} height={40} fill="white" />
      </clipPath>
      <clipPath id="clip1_1179_33194">
        <rect width={59} height={33} fill="white" transform="translate(0 4)" />
      </clipPath>
    </defs>
  </svg>
);
