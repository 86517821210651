import { Box, Button, useTheme } from '@mui/material';
import React from 'react';

import GBPLogo from './assets/GoogleBusiness.png';
import { SocialVerseByIdApiModel } from '../../../../api/models/socialVerse';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { useModal } from '../../../../hooks/useModal';
import { GBPSyncUpdateModal } from './GBPSyncUpdateModal';
import { putEditSocialVerse } from '../../../../store/slices/socialVerseSlice';
import { GBPSyncActiveModal } from './GBPSyncActiveModal';

interface GoogleSyncStatusProps {
  socialVerse: SocialVerseByIdApiModel;
  disableClick?: boolean;
}

export const GoogleSyncStatus: React.FC<GoogleSyncStatusProps> = ({
  socialVerse,
  disableClick,
}) => {
  const theme = useTheme();

  const googleAccount = useTypedSelector((state) => state.gpbSlice.connectedAccount);

  const { isOpen: showSyncModal, open: openSyncModal, close: closeSyncModal } = useModal();
  const {
    isOpen: showSyncActiveModal,
    open: openSyncActiveModal,
    close: closeSyncActiveModal,
  } = useModal();
  const dispatch = useAppDispatch();

  const isSynced = socialVerse.gbpSyncEnabled;

  const syncSocialVerse = () => {
    return dispatch(
      putEditSocialVerse({
        id: socialVerse.id,
        data: {
          gbpSyncEnabled: true,
        },
      }),
    );
  };

  const unsyncSocialVerse = () => {
    return dispatch(
      putEditSocialVerse({
        id: socialVerse.id,
        data: {
          gbpSyncEnabled: false,
        },
      }),
    );
  };

  const isGoogleAccountConnected = !!googleAccount?.accountId;
  const isSvLive = !!socialVerse.lastSeenLocationUrl;
  if (!isGoogleAccountConnected || !isSvLive) {
    return null;
  }

  return (
    <Button
      sx={{
        height: '100%',
        minHeight: '40px',
        maxHeight: '40px',
        width: '75px',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: disableClick ? 'none' : undefined,
      }}
      onClick={() => {
        if (disableClick) return;

        if (isSynced) {
          openSyncActiveModal();
        } else {
          openSyncModal();
        }
      }}
    >
      <GBPSyncUpdateModal
        show={showSyncModal}
        onClose={closeSyncModal}
        onConfirm={() => {
          closeSyncModal();
          syncSocialVerse();
        }}
      />

      <GBPSyncActiveModal
        show={showSyncActiveModal}
        onClose={closeSyncActiveModal}
        onUnsyncClick={() => {
          closeSyncActiveModal();
          unsyncSocialVerse();
        }}
      />
      <Box
        width="8px"
        height="8px"
        borderRadius="100%"
        bgcolor={isSynced ? theme.palette.success.main : theme.palette.error.main}
        mr="5px"
      />
      <img
        src={GBPLogo}
        alt="google-business-profile"
        style={{
          height: '28px',
          width: '33px',
          objectFit: 'contain',
        }}
      />
    </Button>
  );
};
