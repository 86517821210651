import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={365} height={365} fill="none" {...props}>
    <g filter="url(#filter0_d_1_48965)">
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={55}
        d="M182.5 28A153.502 153.502 0 0 1 336 181.5 153.5 153.5 0 0 1 182.5 335a153.498 153.498 0 1 1 0-307h0Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_48965"
        width={364}
        height={364}
        x={0.5}
        y={0.5}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1_48965" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1_48965" result="shape" />
      </filter>
    </defs>
  </svg>
);
const DiagramBackground = memo(SvgComponent);
export default DiagramBackground;
