import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { AppBar, Toolbar, Grid } from '@mui/material';
// import HelpOutline from '@mui/icons-material/HelpOutline';
// import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { useLocation } from 'react-router';

// import { SearchObject } from '../../SearchAccordion/SearchAccordion.helper';
// import { SearchAccordion } from '../../SearchAccordion/SearchAccordion';
// import { setSelectedVenue } from '../../../../store/slices/venueSlice';
import { LayoutMode } from '../../../constants/constants';
import { useStyles } from './TopBarDesktop.helper';
import { TopBarBreadcrumbs } from '../topBarBreadcrumbs/topBarBreadCrumbs';
// import { CopyUrlToggle } from '../../CopyUrlToggle/CopyUrlToggle';
import { OpenWebApp } from '../../../../pages/SocialVerse/CommonComponents/CommonComponents';
import { SocialvenueManagerRoutes, WebsiteRoutes } from '../../../constants/routes';
import { SpheresPreview } from '../../SvCustomizationPreview/SpheresPreview';
import { SubNavigationTab } from '../topBarTabs/TopBarTabs.helper';
import { TopBarTabs } from '../topBarTabs/TopBarTabs';

interface TopBarDesktopProps {
  onHelpChange: () => void;
  layout: LayoutMode;
  children?: ReactNode;
  sectionTitle?: string;
  multiline?: boolean;
  tabs?: SubNavigationTab[];
  onTabChange?: (tab: SubNavigationTab) => void;
}

export const TopBarDesktop: FC<TopBarDesktopProps> = ({
  // onHelpChange,
  // layout,
  children,
  sectionTitle,
  multiline = true,
  tabs,
  onTabChange,
}) => {
  // const { venue: selectedVenue } = useTypedSelector((state) => state.venue);
  // const { items: orgManagerItems } = useTypedSelector((state) => state.OrganizationVenueSlice);
  // const { role } = useTypedSelector((state) => state.me);
  const classes = useStyles();
  // const items = getVenues(role, venuesList, orgManagerItems);

  // const chosenValue = { id: selectedVenue.id, name: selectedVenue.name };
  const location = useLocation();

  // const [value, setValue] = useState<SearchObject>(chosenValue);
  // const [searchValue, setSearchValue] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  // const selectValue = (val: SearchObject) => {
  //   dispatch(setSelectedVenue({ id: val.id }));
  //   setValue(val);
  // };

  useEffect(() => {
    setIsAdmin(location && location.pathname.includes('admin'));
  }, [location, setIsAdmin]);

  // useEffect(() => {
  //   if (selectedVenue) {
  //     setValue({ id: selectedVenue.id, name: selectedVenue.name });
  //   }
  // }, [selectedVenue]);

  // const onChangeSearch = (name: string) => {
  //   setSearchValue(name);
  // };

  const isLinkToWebapp = useMemo(() => {
    const isIncludesPath =
      location &&
      (location.pathname.includes(WebsiteRoutes.Workspace) ||
        location.pathname.includes(SocialvenueManagerRoutes.Performance) ||
        location.pathname.includes(WebsiteRoutes.ContentAll) ||
        location.pathname.includes(WebsiteRoutes.Settings) ||
        location.pathname.includes(WebsiteRoutes.Heroes) ||
        location.pathname.includes(WebsiteRoutes.Accounts) ||
        location.pathname.includes(WebsiteRoutes.Profile) ||
        location.pathname.includes(WebsiteRoutes.WebAppSettings) ||
        // location.pathname !== WebsiteRoutes.DashboardHeroes) ||
        location.pathname === WebsiteRoutes.SocialVerse ||
        location.pathname.includes(WebsiteRoutes.Rewards) ||
        location.pathname.includes(WebsiteRoutes.RewardCampaigns));
    return isIncludesPath;
  }, [location]);
  // const noPadding = useMemo(() => {
  //   const isIncludesPath =
  //     location &&
  //     (location.pathname.includes(WebsiteRoutes.IntegrationsCreate) ||
  //       location.pathname.includes(WebsiteRoutes.ConfirgurationIncentive) ||
  //       location.pathname.includes(WebsiteRoutes.ContentAll) ||
  //       location.pathname.includes(WebsiteRoutes.Profile) ||
  //       location.pathname.includes(WebsiteRoutes.Accounts) ||
  //       location.pathname.includes(WebsiteRoutes.Beacons) ||
  //       location.pathname.includes(WebsiteRoutes.Keywords) ||
  //       location.pathname.includes(WebsiteRoutes.Heroes) ||
  //       location.pathname === WebsiteRoutes.SocialVerse ||
  //       location.pathname.includes(WebsiteRoutes.Rewards));
  //   return isIncludesPath;
  // }, [location]);
  // const smallPadding = useMemo(() => {
  //   const isIncludesPath =
  //     location &&
  //     (location.pathname.includes(WebsiteRoutes.Invites) ||
  //       location.pathname.includes(WebsiteRoutes.WebAppCards));
  //   return isIncludesPath;
  // }, [location]);
  // const evenSmallerPadding = useMemo(() => {
  //   const isIncludesPath = location && location.pathname.includes(WebsiteRoutes.DashboardHeroes);
  //   return isIncludesPath;
  // }, [location]);

  const isStickyTopbar = location && location.pathname.includes(WebsiteRoutes.WebAppCards);

  return (
    <AppBar
      position="static"
      className={classes.appBar}
      sx={{
        position: isStickyTopbar ? 'sticky' : '',
        top: 0,
        zIndex: 1,
      }}
    >
      <Toolbar className={classes.toolBar}>
        <SpheresPreview />
        <Grid
          container
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
          style={{
            width: 'calc(110vw - 315px)',
          }}
        >
          <Grid item xs={!children || isLinkToWebapp ? 4 : 12}>
            <Grid
              className={multiline ? classes.Multiline : classes.Singleline}
              container
              justifyContent={'space-between'}
              style={{ position: 'relative' }}
            >
              <Grid container alignItems={'center'}>
                {sectionTitle && <div className={classes.sectionTitle}>{sectionTitle}</div>}
                {tabs && <TopBarTabs tabs={tabs} onTabChange={onTabChange} />}
              </Grid>
              <div>{children}</div>
            </Grid>
          </Grid>

          {!children
            ? isLinkToWebapp && (
                <Grid container item xs={8}>
                  {isAdmin && <TopBarBreadcrumbs />}
                  <div className={classes.grow} style={{ paddingTop: '0px' }} />
                  <div style={{ padding: '6px' }}>
                    {isLinkToWebapp ? <OpenWebApp /> : <OpenWebApp />}
                  </div>
                </Grid>
              )
            : null}
          {children
            ? isLinkToWebapp && (
                <Grid container item xs={8}>
                  {isAdmin && <TopBarBreadcrumbs />}
                  <div className={classes.grow} style={{ paddingTop: '0px' }} />
                  <div
                    style={{
                      padding: '6px',
                    }}
                  >
                    {isLinkToWebapp ? <OpenWebApp /> : <OpenWebApp />}
                  </div>
                </Grid>
              )
            : null}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
