import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid } from '@mui/material';
import { useEditThumbnailStyles } from './EditThumbnail.style';
import { useAppDispatch } from '../../../store';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { updateVideoById } from '../../../store/slices/videosSlice';
import {
  createUploadThumbnailUrl,
  googleStorageToCloudinary,
  uploadThumbnail,
} from './EditThumbnail.helper';
import { ThumbnailCustomization } from './ThumbnailCustomization';
import { updateTopgolfVideoById } from '../../../store/slices/topGolfVideosSlice';
import { useVenue } from '../../../hooks/useVenue';
import { updateVideoThumbnail } from '../../../store/slices/socialVerseSlice';
import { useLocation } from 'react-router-dom';
import { VideoApiModel } from '../../../api/models/videos';

interface IEditThumbnailProps {
  storyThumbnailUrl: string;
  storyAltThumbnailUrl: string;
  storyUrl: string;
  storyId: string;
  frames: ThumbnailFrame[];

  isOpen: boolean;
  handleClose: () => void;
  isTopGolf: boolean;
}
export type Frame = { thumbnail: HTMLImageElement; time: number };

export type ThumbnailFrame = {
  thumbnail: HTMLImageElement;
  altThumbnail: HTMLImageElement;
  time: number;
};

export const EditThumbnail: React.FC<IEditThumbnailProps> = ({
  storyThumbnailUrl,
  storyUrl,
  storyId,
  frames,
  isOpen,
  handleClose,
  isTopGolf,
}) => {
  const dispatch = useAppDispatch();

  const { venueId } = useVenue();
  const location = useLocation();

  const [selectedThumbnail, setSelectedThumbnail] = useState<ThumbnailFrame>();
  const styles = useEditThumbnailStyles();

  const handleSave = async () => {
    if (!storyUrl || !selectedThumbnail) return;

    try {
      const uploadThumbnailUrl = await createUploadThumbnailUrl('png', venueId);
      const uploadAltThumbnailUrl = await createUploadThumbnailUrl('png', venueId);
      if (!uploadThumbnailUrl || !uploadAltThumbnailUrl) return;

      const cloudinaryThumbnailUrl = googleStorageToCloudinary(selectedThumbnail.thumbnail.src);
      const cloudinaryAltThumbnailUrl = googleStorageToCloudinary(
        selectedThumbnail.altThumbnail.src,
      );

      await uploadThumbnail({ options: uploadThumbnailUrl, data: cloudinaryThumbnailUrl });
      await uploadThumbnail({ options: uploadAltThumbnailUrl, data: cloudinaryAltThumbnailUrl });

      const thumbnailUrl = uploadThumbnailUrl.downloadUrl;
      const altThumbnailUrl = uploadAltThumbnailUrl.downloadUrl;

      if (isTopGolf) {
        return dispatch(
          updateTopgolfVideoById({
            id: storyId,
            update: { thumbnailUrl, altThumbnailUrl },
          }),
        );
      }

      const data = await dispatch(
        updateVideoById({ id: storyId, update: { thumbnailUrl, altThumbnailUrl } }),
      );

      const isSocialVerse = location.pathname.includes('socialverse');
      if (!isSocialVerse) return;

      const payload = data.payload as VideoApiModel;

      return dispatch(
        updateVideoThumbnail({
          id: payload.id,
          thumbnailUrl: payload.thumbnailUrl,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const editThumbnails: string[] = [];
  for (let index = 0; index < 5; index++) {
    editThumbnails.push(storyThumbnailUrl as string);
  }

  return (
    <div
      className={[
        styles.ChangeTumbnailContainer,
        isOpen
          ? styles.ChangeTumbnailContainerDisplayed
          : styles.ChangeTumbnailContainerNotDisplayed,
      ].join(' ')}
    >
      <Accordion className={styles.ChangeTumbnailAccordion} expanded={true}>
        <AccordionSummary>
          <Box className={styles.IconEditThumbnailContainer}>
            <Typography>{VideoDetailsModalStringsDv5.ChangeThumbnail}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={styles.ChangeTumbnailAccordionDetails}>
          <Box className={styles.EditThumbnailContainer}>
            <ThumbnailCustomization onFrameSelect={setSelectedThumbnail} frames={frames} />
          </Box>
          <Grid container className={styles.EditThumbnailButtonsContainer}>
            <Button
              color="primary"
              className={[styles.Buttons, styles.ButtonsFilled, styles.ThumbnailButton].join(' ')}
              onClick={() => {
                handleSave();
                handleClose();
              }}
            >
              {VideoDetailsModalStringsDv5.SaveThumbnail}
            </Button>
            <Button
              variant="contained"
              className={[styles.Buttons, styles.ThumbnailButton].join(' ')}
              onClick={() => {
                setSelectedThumbnail(undefined);

                handleClose();
              }}
            >
              {VideoDetailsModalStringsDv5.Cancel}
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
