import { FC } from 'react';
import { theme } from '../../../theme';

interface VideosIconAnalyticsProps {
  width?: string;
  height?: string;
  isActive?: boolean;
}

export const VideosIconAnalytics: FC<VideosIconAnalyticsProps> = ({ width, height, isActive }) => (
  <svg
    width={width ?? 50}
    height={height ?? 50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={
      isActive
        ? {
            borderRadius: '50%',
            border: `1px solid ${theme.palette.primary.main}`,
          }
        : {}
    }
  >
    <mask
      id="mask0_704_20281"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={50}
      height={50}
    >
      <circle cx={25} cy={25} r={25} fill="#F1F1F1" />
    </mask>
    <g mask="url(#mask0_704_20281)">
      <circle cx={25} cy={25} r={25} fill="#EEECFD" />
      <path
        d="M5.09595 41.0681L51.0933 14.5115"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5786 50.5642L56.576 24.0076"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.0959473 32.4077L46.0933 5.8511"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-5.40405 22.8816L40.5933 -3.67502"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.56958 16.6586C9.56958 14.9542 10.9513 13.5725 12.6557 13.5725H37.3445C39.0489 13.5725 40.4306 14.9542 40.4306 16.6586V34.1465H9.56958V16.6586Z"
        fill="#D9D4FF"
        stroke="#5242EA"
      />
      <path
        d="M12.2837 17.5014C12.2837 16.565 13.0428 15.8059 13.9792 15.8059H36.021C36.9574 15.8059 37.7166 16.565 37.7166 17.5014V31.9134H12.2837V17.5014Z"
        fill="white"
      />
      <path
        d="M7.51208 34.8922C7.51208 35.7401 8.19944 36.4275 9.04733 36.4275H40.9526C41.8005 36.4275 42.4879 35.7401 42.4879 34.8922C42.4879 34.4802 42.1538 34.1462 41.7418 34.1462H8.25817C7.84612 34.1462 7.51208 34.4802 7.51208 34.8922Z"
        fill="white"
        stroke="#1A1538"
      />
      <path
        d="M26.0387 35.0891H23.693C22.9204 35.0891 22.2941 34.4628 22.2941 33.6902H27.4376C27.4376 34.4628 26.8113 35.0891 26.0387 35.0891Z"
        fill="#1A1538"
      />
      <path
        d="M28.8474 22.9992C29.5332 23.3951 29.5332 24.385 28.8474 24.7809L23.848 27.6674C23.1622 28.0633 22.3049 27.5684 22.3049 26.7765L22.3049 21.0036C22.3049 20.2117 23.1622 19.7168 23.848 20.1127L28.8474 22.9992Z"
        fill="#1A1538"
      />
    </g>
  </svg>
);
