export enum SocialVerseEventNames {
  createNewSocialVerse = 'create_new_social_verse_click',
  createNewSocialVerseCards = 'create_new_social_verse_cards_click',
  createNewSocialVerseSpheres = 'create_new_social_verse_spheres_click',
  newSocialVerseNameTyped = 'new_social_verse_name_typed',
  newSocialVerseCreate = 'new_social_verse_create_click',
  newSocialVerseCancel = 'new_social_verse_cancel_click',
  addVideosView = 'add_videos_view_click',
  configureSettingsView = 'configure_settings_view_click',
  backToSocialVerse = 'back_to_social_verse_click',
  openSocialVersePreview = 'open_social_verse_preview_click',
  changePreviewDevice = 'change_social_verse_preview_device_click',
  openVideoPlayback = 'open_video_playback_click',
  closeVideoPlayback = 'close_video_playback_click',
  addSocialVerseVideo = 'add_social_verse_video_click',
  removeSocialVerseVideo = 'remove_social_verse_video_click',
  socialVerseVideoDragged = 'social_verse_video_dragged',
  toggleSocialVerseTitle = 'toggle_social_verse_title_click',
  toggleSocialVerseSupportingText = 'toggle_social_verse_supporting_text_click',
  socialVerseTitleTyped = 'social_verse_title_typed',
  socialVerseSupportingTextTyped = 'social_verse_supporting_text_typed',
  toggleSocialVerseCreatorInfo = 'toggle_social_verse_creator_info_click',
  toggleSocialVerseVideoCounter = 'toggle_social_verse_video_counter_click',
  toggleSocialVerseHoverPreview = 'toggle_social_verse_hover_preview_click',
  toggleSocialVerseCaptionEnabled = 'toggle_social_verse_caption_enabled_click',
  toggleSocialVerseColorPicker = 'toggle_social_verse_color_picker_click',
  toggleSocialVerseGBPSync = 'toggle_social_verse_gbp_sync',
  socialVerseColorPick = 'social_verse_color_changed',
  saveChanges = 'save_changes_click',
  cancelChanges = 'cancel_changes_click',
  deleteSocialVerse = 'delete_social_verse_click',
  cancelDelete = 'cancel_delete_social_verse_click',
  confirmDelete = 'confirm_delete_social_verse_click',
  changeSocialVerseName = 'change_social_verse_name_click',
  changeSocialVerseNameTyped = 'change_social_verse_name_typed',
  saveSocialVerseName = 'save_social_verse_name',
  addVideos = 'add_videos_click',
  addToWebsite = 'open_add_to_website_click',
  closeAddToWebsite = 'close_add_to_website_click',
  copyHeaderCode = 'copy_header_code_click',
  copyBodyCode = 'copy_body_code_click',
  videoKeywords = 'video_keywords_click',
  resetResults = 'reset_results_click',
  activeFilter = 'active_filter_click',
  tableRow = 'user_video_in_table_click',
  closeVideoDetail = 'close_user_video_detail_click',
  deleteUserVideo = 'delete_user_video_click',
  deleteUserVideoConfirm = 'confirm_user_video_delete_click',
  deleteUserVideoCancel = 'cancel_user_video_delete_click',
  goToVideoLink = 'go_to_user_video_link_click',
  downloadVideo = 'download_user_video_click',
  changeDownloadVideType = 'user_video_download_type_changed',
  downloadVideoConfirm = 'confirm_download_user_video_click',
  downloadVideoCancel = 'cancel_download_user_video_click',
  addToSocialVerse = 'add_to_social_verse_click',
  addToSocialVerseConfirm = 'confirm_add_to_social_verse_click',
  addToSocialVerseCancel = 'cancel_add_to_social_verse_click',
  removeFromSocialVerse = 'remove_user_video_from_social_verse_click',
  socialVersePageLoad = 'social_verse_page',
  social_verse_tab_click = 'social_verse_tab_click',
  socialVerseNumberOfCards = 'social_verse_config_number_of_cards_changed',
  socialVerseNumberOfRows = 'social_verse_config_number_of_rows_changed',
}
