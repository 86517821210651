import * as React from 'react';
import { SVGProps } from 'react';
const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={126}
    height={126}
    viewBox="0 0 126 126"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M63 10C69.9601 10 76.852 11.3709 83.2822 14.0344C89.7125 16.6979 95.5552 20.6018 100.477 25.5233C105.398 30.4449 109.302 36.2875 111.966 42.7178C114.629 49.148 116 56.0399 116 63C116 69.9601 114.629 76.852 111.966 83.2822C109.302 89.7125 105.398 95.5552 100.477 100.477C95.5551 105.398 89.7125 109.302 83.2822 111.966C76.8519 114.629 69.96 116 63 116C56.0399 116 49.148 114.629 42.7178 111.966C36.2875 109.302 30.4448 105.398 25.5233 100.477C20.6018 95.5551 16.6979 89.7125 14.0344 83.2822C11.3709 76.8519 10 69.96 10 63C10 56.0399 11.3709 49.148 14.0344 42.7178C16.6979 36.2875 20.6019 30.4448 25.5234 25.5233C30.4449 20.6018 36.2876 16.6979 42.7178 14.0344C49.1481 11.3709 56.04 10 63 10L63 10Z"
      stroke="#F2F4F7"
      strokeWidth={20}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.726 76.7259C112.208 86.1226 107.154 94.6457 100.117 101.363C93.0801 108.079 84.331 112.731 74.8274 114.809C65.3238 116.887 55.4317 116.311 46.2336 113.143C37.0355 109.976 28.8858 104.34 22.6762 96.8514C16.4667 89.3629 12.4365 80.3106 11.0268 70.6852C9.61705 61.0597 10.882 51.232 14.6833 42.2773C18.4846 33.3225 24.6757 25.5859 32.5792 19.914C40.4827 14.242 49.7941 10.8532 59.4945 10.1185"
      stroke="#CFB8EE"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.726 76.7259C112.208 86.1226 107.154 94.6457 100.117 101.363C93.0801 108.079 84.331 112.731 74.8274 114.809C65.3238 116.887 55.4317 116.311 46.2336 113.143C37.0355 109.976 28.8858 104.34 22.6762 96.8514C16.4667 89.3629 12.4365 80.3106 11.0268 70.6852C9.61705 61.0597 10.882 51.232 14.6833 42.2773C18.4846 33.3225 24.6757 25.5859 32.5792 19.914C40.4827 14.242 49.7941 10.8532 59.4945 10.1185"
      stroke="#5242EA"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.426 36.4798C113.536 43.5982 115.936 51.5748 116.435 59.7797"
      stroke="#BAB3FE"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.3116 11.5378C84.3183 13.531 91.7494 17.365 98.014 22.7348"
      stroke="#7F56D9"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.3116 11.5378C84.3183 13.531 91.7494 17.365 98.014 22.7348"
      stroke="#372F86"
      strokeWidth={15}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask0_0_1"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={36}
      width={55}
      height={55}
    >
      <circle cx={63.5} cy={63.5} r={27.5} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask0_0_1)">
      <circle cx={63.5} cy={63.5} r={26.8} fill="#CFB8EE" stroke="#A69DF7" strokeWidth={1.4} />
      <ellipse
        cx={63.2832}
        cy={63.4355}
        rx={18.6575}
        ry={18.6575}
        transform="rotate(-0.12231 63.2832 63.4355)"
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeOpacity={0.5}
        strokeWidth={1.4}
      />
      <path
        d="M60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866L60.6212 56.3013Z"
        fill="#5242EA"
        stroke="#A69DF7"
        strokeWidth={1.4}
      />
    </g>
    <mask
      id="mask1_0_1"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={36}
      y={36}
      width={55}
      height={55}
    >
      <circle cx={63.5} cy={63.5} r={27.5} fill="#CFB8EE" />
    </mask>
    <g mask="url(#mask1_0_1)">
      <circle cx={63.5} cy={63.5} r={27.5} fill="#CFB8EE" />
      <ellipse
        cx={63.2832}
        cy={63.4355}
        rx={18.6575}
        ry={18.6575}
        transform="rotate(-0.12231 63.2832 63.4355)"
        fill="#EEECFD"
      />
      <path
        d="M60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866L60.6212 56.3013Z"
        fill="#5242EA"
        stroke="#5242EA"
        strokeWidth={1.4}
      />
    </g>
    <circle cx={63.5} cy={63.5} r={26.8} fill="#D9D4FF" stroke="#5242EA" strokeWidth={1.4} />
    <ellipse
      cx={63.2832}
      cy={63.4355}
      rx={18.6575}
      ry={18.6575}
      transform="rotate(-0.12231 63.2832 63.4355)"
      fill="#EEECFD"
      stroke="#A69DF7"
      strokeWidth={1.4}
    />
    <path
      d="M60.6212 56.3013C59.4883 55.5745 58.0003 56.3898 58.0031 57.7358L58.0313 70.9501C58.0342 72.296 59.5258 73.105 60.6555 72.3733L70.8909 65.7443C71.928 65.0726 71.9248 63.5539 70.8848 62.8866L60.6212 56.3013Z"
      fill="#5242EA"
      stroke="#A69DF7"
      strokeWidth={1.4}
    />
    <ellipse
      cx={63.2591}
      cy={63.2003}
      rx={18.3567}
      ry={18.3567}
      transform="rotate(-0.12231 63.2591 63.2003)"
      fill="#EEECFD"
      stroke="#A69DF7"
      strokeWidth={1.6}
    />
    <path
      d="M74.4851 62.4519C74.987 63.239 74.987 64.2439 74.4851 65.031C73.3968 66.7377 71.9368 68.1849 70.2071 69.2601C68.0567 70.5968 65.5753 71.3052 63.0433 71.3052C60.5114 71.3052 58.0299 70.5968 55.8796 69.2601C54.1498 68.1849 52.6898 66.7377 51.6015 65.031C51.0997 64.2439 51.0997 63.239 51.6015 62.4519C52.6898 60.7452 54.1498 59.298 55.8796 58.2228C58.0299 56.8861 60.5114 56.1777 63.0433 56.1777C65.5753 56.1777 68.0567 56.8861 70.2071 58.2228C71.9368 59.298 73.3968 60.7452 74.4851 62.4519Z"
      fill="#5242EA"
      stroke="#DCDBE4"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.045 67.3918C65.0611 67.3918 66.6955 65.7574 66.6955 63.7413C66.6955 61.7252 65.0611 60.0908 63.045 60.0908C61.0289 60.0908 59.3945 61.7252 59.3945 63.7413C59.3945 65.7574 61.0289 67.3918 63.045 67.3918Z"
      stroke="#DCDBE4"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
