export const LaptopIcon = () => (
  <svg width="82" height="53" viewBox="0 0 82 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.68359 4.0861C5.68359 2.38169 7.06529 1 8.76969 1H72.8131C74.5175 1 75.8992 2.38169 75.8992 4.0861V47.8104H5.68359V4.0861Z"
      fill="#D9D4FF"
      stroke="#5242EA"
    />
    <path
      d="M11.8613 7.77951C11.8613 6.8431 12.6204 6.08398 13.5569 6.08398H68.0312C68.9676 6.08398 69.7267 6.8431 69.7267 7.77951V42.7321H11.8613V7.77951Z"
      fill="#EEECFD"
    />
    <circle
      opacity="0.25"
      cx="23.8711"
      cy="17.2012"
      r="4.5"
      transform="rotate(-90 23.8711 17.2012)"
      fill="#5242EA"
      stroke="#5242EA"
    />
    <rect
      opacity="0.25"
      x="34.5"
      y="12.7012"
      width="26"
      height="9"
      rx="4.5"
      fill="#5242EA"
      stroke="#5242EA"
    />
    <circle
      opacity="0.25"
      r="4.5"
      transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 56 32.2012)"
      fill="#5242EA"
      stroke="#5242EA"
    />
    <rect
      opacity="0.25"
      x="-0.5"
      y="0.5"
      width="26"
      height="9"
      rx="4.5"
      transform="matrix(-1 0 0 1 44.8711 27.2012)"
      fill="#5242EA"
      stroke="#5242EA"
    />
    <path
      d="M1 49.8852C1 51.0542 1.94772 52.002 3.11679 52.002H78.4608C79.6299 52.002 80.5776 51.0542 80.5776 49.8852V47.8401C80.5776 47.272 80.1171 46.8114 79.5489 46.8114H2.0287C1.46056 46.8114 1 47.272 1 47.8401V49.8852Z"
      fill="white"
      stroke="#5242EA"
    />
    <path
      d="M43.1507 49.9543H37.8137C36.0558 49.9543 34.6309 48.5293 34.6309 46.7715H46.3335C46.3335 48.5293 44.9085 49.9543 43.1507 49.9543Z"
      fill="#5242EA"
    />
  </svg>
);
