import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '13px',
    marginTop: '-6px',
    maxWidth: '253px',
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  option: {
    cursor: 'pointer',
    position: 'relative',
    padding: '0 7px 10px 7px',
    border: '1px solid #DCDBE4',
  },
  leftOption: {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  rightOption: {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  activeOption: {
    background: '#F9F5FF',
    border: `1.5px solid ${theme.palette.primary.main}`,
    color: `${theme.palette.primary.main}`,
    '& p': {
      color: `${theme.palette.primary.main}`,
    },
  },
  checkmark: {
    position: 'absolute',
    top: '9px',
    right: '15px',
    borderRadius: '50%',
    border: `1px solid #D0D5DD`,
    width: '16px',
    height: '16px',
    paddingLeft: '2px',
  },
  checkmarkActive: {
    border: `1px solid #7F56D9`,
    background: theme.palette.primary.main,
    '& path': {
      transform: `scale(.85)`,
    },
  },
}));
