import { FC, ReactNode } from 'react';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../constants/constants';
import { SubNavigationTab } from './TopBar.helper';
import { TopBarDesktop } from './topBarDesktop/TopBarDesktop';

interface TopBarProps {
  children?: ReactNode;
  sectionTitle?: string;
  multiline?: boolean;
  tabs?: SubNavigationTab[];
  onTabChange?: (newTab: SubNavigationTab) => void;
}

export const TopBar: FC<TopBarProps> = ({
  children,
  sectionTitle,
  multiline = true,
  tabs,
  onTabChange,
}) => {
  const sidebarMode = useWindowResize();
  // const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogState = () => {
    if (window && window.$crisp) {
      window.$crisp.push(['do', 'chat:open']);
    }
  };

  // const handleDialogClose = (event: object, reason: string) => {
  //   if (reason === 'backdropClick') {
  //     setDialogOpen(false);
  //   }
  // };

  // const closeModal = () => {
  //   setDialogOpen(false);
  // };

  const renderTopBar = () => {
    switch (sidebarMode) {
      case LayoutMode.Desktop: {
        return (
          <TopBarDesktop
            onHelpChange={handleDialogState}
            layout={LayoutMode.Desktop}
            sectionTitle={sectionTitle}
            multiline={multiline}
            tabs={tabs}
            onTabChange={onTabChange}
          >
            {children}
          </TopBarDesktop>
        );
      }
      case LayoutMode.Tablet: {
        return (
          <TopBarDesktop
            tabs={tabs}
            onHelpChange={handleDialogState}
            layout={LayoutMode.Tablet}
            onTabChange={onTabChange}
          />
        );
      }
      case LayoutMode.Mobile: {
        return (
          <TopBarDesktop
            tabs={tabs}
            onHelpChange={handleDialogState}
            layout={LayoutMode.Mobile}
            onTabChange={onTabChange}
          />
        );
      }
      default: {
        return (
          <TopBarDesktop
            tabs={tabs}
            onHelpChange={handleDialogState}
            layout={LayoutMode.Desktop}
            onTabChange={onTabChange}
          />
        );
      }
    }
  };

  return <>{renderTopBar()}</>;
};
