import React, { FC, useCallback, useEffect, useState } from 'react';
import { FSModal } from '../modal/Modal';
import { useStyles } from './PreviewModal.style';
import { Box } from '@mui/material';
import { MobileDesktopSwitch } from '../SwitchIconed/MobileDesktopSwitch';
import { useTypedSelector } from '../../../store';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { isEducationalView, isGridView, isSpheresView } from '../../../services/utilities';
import { TypeSocialVerse } from '../../constants/constants';

interface ViewSize {
  width: string;
  height: string;
}

type View = Record<TypeSocialVerse, ViewSize>;

interface ModalSizes {
  desktop: View;
  mobile: View;
}

const modalSize: ModalSizes = {
  desktop: {
    perspective: {
      width: '80%',
      height: '850px',
    },
    educational: {
      width: '768px',
      height: '850px',
    },
    blocks: {
      width: '768px',
      height: '850px',
    },
    spheres: {
      width: '768px',
      height: '850px',
    },
    grid: {
      width: '90%',
      height: '850px',
    },
    circles: {
      width: '768px',
      height: '850px',
    },
  },
  mobile: {
    perspective: {
      width: '385px',
      height: '800px',
    },
    educational: {
      width: '400px',
      height: '800px',
    },
    blocks: {
      width: '373px',
      height: '800px',
    },

    spheres: {
      width: '373px',
      height: '800px',
    },
    grid: {
      width: '373px',
      height: '800px',
    },
    circles: {
      width: '373px',
      height: '800px',
    },
  },
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const PreviewModal: FC<Props> = ({ isOpen, onClose }) => {
  const [isDesktopView, setIsDesktopView] = useState(true);
  const { socialVerse } = useTypedSelector((state) => state.SocialVerseSlice);
  const { trackEvent } = useTrackEvent();
  const viewMode = socialVerse?.viewMode;

  const classes = useStyles();

  const isSmallView = isSpheresView(viewMode) || isEducationalView(viewMode);

  const getGidgetComponent = useCallback(() => {
    const component = document.createElement('section');
    component.style.width = isSmallView ? 'auto' : '100%';

    if (socialVerse) {
      const venueId = socialVerse.venueId;
      const heading = socialVerse.title;
      const socialVerseId = socialVerse.id;
      const showHeroName = socialVerse.showUserInfo;

      component.innerHTML = `<gidget-component widget-type='${viewMode}' venue-id='${venueId}'
                    heading='${heading}'
                    socialverse-id='${socialVerseId}' show-block-hero-name='${showHeroName}' is-mobile-prop='${!isDesktopView}'
                   ></gidget-component>`;
    }

    return component;
  }, [isSmallView, socialVerse, viewMode, isDesktopView]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(async () => {
        const containerId = isDesktopView ? 'desktop-view' : 'mobile-view';
        const container = document.getElementById(containerId);
        if (container && container.hasChildNodes()) {
          container.innerHTML = '';
        }
        container?.prepend(getGidgetComponent());
      }, 0);
    }
  }, [isOpen, isDesktopView, getGidgetComponent]);

  return (
    <>
      <FSModal
        modalIsOpen={isOpen}
        onClose={onClose}
        width={
          viewMode
            ? isDesktopView
              ? modalSize.desktop[viewMode].width
              : modalSize.mobile[viewMode].width
            : isDesktopView
            ? '90%'
            : '385px'
        }
        rounded="5px"
        padding="0"
        styles={{ overflow: 'initial', overflowY: 'visible' }}
      >
        {isOpen && (
          <Box className={classes.switchWrapper}>
            <MobileDesktopSwitch
              onChange={() => {
                setIsDesktopView(!isDesktopView);
                trackEvent(SocialVerseEventNames.changePreviewDevice, {
                  socialVerseId: socialVerse?.id,
                });
              }}
              isChecked={!isDesktopView}
            />
          </Box>
        )}
        <Box
          className={classes.container}
          style={{
            padding: isDesktopView
              ? isGridView(viewMode)
                ? (socialVerse?.details?.numberOfGridRows || 1) > 2
                  ? '20px'
                  : '0px 20px 0px'
                : '0 51px 0px'
              : '0 22px 0px',
            height: viewMode
              ? isDesktopView
                ? modalSize.desktop[viewMode].height
                : modalSize.mobile[viewMode].height
              : 'auto',
            overflowY: isGridView(viewMode) ? 'scroll' : 'auto',
          }}
        >
          {isDesktopView ? (
            <>
              {/* <Box className={classes.topSectionDeskTop}>
                <Box className={clsx(classes.previewEmptyBlock, classes.topSectionLeft)} />
                <Box className={clsx(classes.previewEmptyBlock, classes.topSectionRight)} />
              </Box> */}
              {/*    <Box className={clsx(classes.previewEmptyBlock, classes.middleBlockDesktop)} /> */}
              <Box
                className={classes.pluginDesktopWrapper}
                id="desktop-view"
                sx={{
                  display:
                    isGridView(viewMode) && (socialVerse?.details?.numberOfGridRows || 1) > 1
                      ? 'block !important'
                      : 'flex',
                  left: isSpheresView(viewMode) ? '-8%' : 'auto',
                  top: isSpheresView(viewMode) ? '-28px' : 'auto',
                  position: isSmallView ? 'relative' : 'static',
                }}
              ></Box>
              {/*  <Box className={clsx(classes.previewEmptyBlock, classes.bottomBlockDesktop)} /> */}
            </>
          ) : (
            <>
              {/* <Box className={clsx(classes.previewEmptyBlock, classes.topSectionMobile)} />
              <Box className={clsx(classes.previewEmptyBlock, classes.middleSectionMobile)} /> */}
              <Box
                className={classes.pluginMobileWrapper}
                id="mobile-view"
                sx={{
                  left: isSpheresView(viewMode) ? '120px' : 'auto',
                  top: isSpheresView(viewMode) ? '0px' : 0,
                  height: isSpheresView(viewMode) ? '280px' : '100%',
                  position: isSmallView ? 'relative' : 'static',
                  zIndex: 100,
                  width: isEducationalView(viewMode) ? 'fit-content' : '100%',
                }}
              ></Box>
              {/* <Box
                className={clsx(classes.previewEmptyBlock, classes.bottomBlockMobile)}
                style={{
                  marginTop: isSmallView ? '120px' : '20px',
                }}
              /> */}
            </>
          )}
        </Box>
      </FSModal>
    </>
  );
};
