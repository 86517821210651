import React, { FC } from 'react';
import { Grid, Typography, Stack, Box, Tabs, Tab } from '@mui/material';
import { SocialVerseStrings } from '../../common/localization/en';
import { useClientType } from '../../services/hooks/useClientType';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../common/constants/events/socialVerseEvents';
import poster_classic from './SVPreviews/sv_classic_cards.png';
import poster_perspective from './SVPreviews/sv_perspective.png';
import poster_grids from './SVPreviews/sv_grids.png';
import poster_watch_learn from './SVPreviews/sv_watch_learn.png';
import poster_spheres from './SVPreviews/sv_spheres.png';
import { useNoSocialVersesStyles } from './NoSocialVerses.style';
import Carousel, { CarouselItem } from '../../common/components/Carousel/Carousel';

interface NoSocialVersesProps {
  handleSVTypeChange?: (type: string) => void;
}

export const NoSocialVerses: FC<NoSocialVersesProps> = ({ handleSVTypeChange }) => {
  const classes = useNoSocialVersesStyles();

  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const tabName = SVTabs[newValue].text;
    const eventName = `${tabName}_${SocialVerseEventNames.social_verse_tab_click}`;
    trackEvent(eventName);
  };
  return (
    <>
      <Grid container className={classes.containerNewSV}>
        <Stack className={classes.wrapperNewSV}>
          <Typography className={classes.titleCreateNewSV}>
            {SocialVerseStrings.CreateSocialVerse}
          </Typography>
          <Typography variant="body1" className={classes.textContentNewSV}>
            {SocialVerseStrings.CreateNewSocialVerseText}
          </Typography>
          <Grid container className={classes.imageContainer}>
            <Box>
              <Box></Box>
              <Box className={classes.CarouselTab}>
                <Typography className={classes.tabTitle}>{SVTabs[value].text}</Typography>
                <Typography className={classes.textContentCustomers}>
                  {isHealthCare
                    ? SocialVerseStrings.SeeWhatCustomersSay.replace('customers', 'patients')
                    : SocialVerseStrings.SeeWhatCustomersSay}
                </Typography>
                <Box className={classes.CarouselWrapper}>
                  <Carousel
                    tabs={SVTabs}
                    activeTabIndex={value}
                    onActiveTabUpdate={(newTab) => {
                      setValue(newTab);
                    }}
                    onAutoScroll={() => {}}
                    handleTabClick={(newTab) => {}}
                  >
                    {SVTabs.map((tab) => (
                      <CarouselItem key={tab.text}>{tab.content}</CarouselItem>
                    ))}
                  </Carousel>
                </Box>
              </Box>

              <Box>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    '.MuiTabs-indicator': {
                      top: '0px',
                      height: '4px',
                    },
                  }}
                >
                  {SVTabs.map((item, index) => {
                    return <Tab label={item.text} className={classes.SVTab} />;
                  })}
                </Tabs>
              </Box>
            </Box>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
};

const SVTabs = [
  {
    poster: poster_classic,
    text: SocialVerseStrings.Classic,
    content: (
      <Box sx={{ padding: '2px' }}>
        <img
          src={poster_classic}
          alt={`SocialV Create ${SocialVerseStrings.Classic}`}
          width={'765px'}
          height={'300px'}
          loading="lazy"
        />
      </Box>
    ),
  },
  {
    poster: poster_perspective,
    text: SocialVerseStrings.Perspective,
    content: (
      <img
        src={poster_perspective}
        alt={`SocialV Create ${SocialVerseStrings.Perspective}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
  {
    poster: poster_grids,
    text: SocialVerseStrings.Grids,
    content: (
      <img
        src={poster_grids}
        alt={`SocialV Create ${SocialVerseStrings.Grids}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
  {
    poster: poster_watch_learn,
    text: SocialVerseStrings.WatchLearn,
    content: (
      <img
        src={poster_watch_learn}
        alt={`SocialV Create ${SocialVerseStrings.WatchLearn}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
  {
    poster: poster_spheres,
    text: SocialVerseStrings.Spheres,
    content: (
      <img
        src={poster_spheres}
        alt={`SocialV Create ${SocialVerseStrings.Spheres}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
];
