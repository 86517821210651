import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useInviteTeamMemberStyles } from './InviteTeamMember.style';
import { Profile2userIcon } from '../../assets/Profile2userIcon';
import { TeamSettingsStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';

interface IDeleteModalProps {
  openDeleteModal: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

export const DeleteModal: FC<IDeleteModalProps> = ({
  openDeleteModal,
  handleClose,
  handleDelete,
}) => {
  const style = useInviteTeamMemberStyles();
  return (
    <Modal open={openDeleteModal} onClose={handleClose}>
      <Box className={style.DeleteModal}>
        <Box className={style.Icon}>
          <Profile2userIcon />
        </Box>
        <Typography className={style.ModalTitle} style={{ marginTop: 20, marginBottom: 12 }}>
          {TeamSettingsStrings.DeleteQuestion}
        </Typography>

        <Typography className={style.ModalDescription}>
          {TeamSettingsStrings.DeleteDescription}
        </Typography>
        <Box display="flex" gap="16px">
          <RoundedButton
            error
            onClick={() => {
              handleDelete();
              handleClose();
            }}
            title={TeamSettingsStrings.Delete}
          />
          <RoundedButton title={TeamSettingsStrings.CancelDelete} onClick={handleClose} />
        </Box>
      </Box>
    </Modal>
  );
};
