import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { GlobalAnalyticsNames, KPIApiNames } from '../../../../api/models/analytics';
import { Tooltip } from '../../../../common/components/table/Tooltip';
import { CommonStrings, DashboardStrings } from '../../../../common/localization/en';
import { useIsTBAccount } from '../../../../hooks/common';
import { numberWithCommas } from '../../../../services/utilities';
import { KPIDTOActiveAnalyticsBlock } from '../../../../store/storeModels';
import { AnalyticsBlockModel } from './../../../admin/performance/analyticsBlock/AnalyticsBlock.helper';
import { StatisticsBlock, useStylesStatisctic } from './StatisticBlock.style';
import { PerfomanceEvents } from '../../../../common/constants/events/perfomanceEvents';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { useTypedSelector } from '../../../../store';
import { useDistributors } from '../../../../hooks/useDistributors';
import { useAffiliates } from '../../../../hooks/useAffiliates';
import { UserRoles } from '../../../../common/constants/constants';

const StatisticsBlocksLiteral: { [key: string]: PerfomanceEvents } = {
  users: PerfomanceEvents.Perfomance_Creators_Statistic_Block_Click,
  videos: PerfomanceEvents.Perfomance_Videos_Statistic_Block_Click,
  shares: PerfomanceEvents.Perfomance_Shares_Statistic_Block_Click,
  videoViews: PerfomanceEvents.Perfomance_Views_Statistic_Block_Click,
  ctaClicks: PerfomanceEvents.Perfomance_Clicks_Statistic_Block_Click,
  venues: PerfomanceEvents.Perfomance_Venues_Statistic_Block_Click,
  convertedReferrals: PerfomanceEvents.Perfomance_Venues_Statistic_Block_Click,
  liveCampaigns: PerfomanceEvents.Perfomance_Venues_Statistic_Block_Click,
  socialVerses: PerfomanceEvents.Perfomance_Socialverses_Statistic_Block_Click,
  gbpAccounts: PerfomanceEvents.Perfomance_Synced_GBP_Statistic_Block_Click,
};

interface StatisticsBlockProps {
  items: AnalyticsBlockModel[];
  activeAnalyticsBlock?: KPIDTOActiveAnalyticsBlock;
  onClick: (kpiName: KPIApiNames, name: string) => void;
  isTopgolf?: boolean;
  isHealthCare?: boolean;
  accountsPage?: boolean;
}
export const StatisticsBlocks: FC<StatisticsBlockProps> = ({
  items,
  activeAnalyticsBlock,
  onClick,
  isTopgolf,
  isHealthCare,
  accountsPage,
}) => {
  const classes = useStylesStatisctic();
  const isTBAccount = useIsTBAccount();
  const { trackEvent } = useTrackEvent();

  const { role } = useTypedSelector((state) => state.me);
  const { distributors } = useDistributors();
  const { affiliates } = useAffiliates();

  const renderStatisticBlock = (item: AnalyticsBlockModel) => {
    const handleClick = () => {
      onClick(item.kpiName as KPIApiNames, item.name);
      trackEvent(StatisticsBlocksLiteral[item.kpiName as any]);
    };

    const isActiveBlock = activeAnalyticsBlock ? item.kpiName === activeAnalyticsBlock.main : false;
    return (
      <Grid className={classes.root}>
        <StatisticsBlock onClick={handleClick} isActive={isActiveBlock}>
          <Grid display="flex" flexDirection="row">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.icon}
            >
              {item.icon}
            </Box>
            <Box className={classes.textToltipRationWrapper}>
              <Grid display="flex" flexDirection="row" alignItems="center">
                {isHealthCare && item.kpiName === 'users' ? (
                  <Typography className={classes.text}>
                    {isTBAccount ? CommonStrings.Patients : CommonStrings.Creators}
                  </Typography>
                ) : isTopgolf && item.kpiName === 'ctaClicks' ? (
                  <Typography className={classes.text}>{DashboardStrings.CTA}</Typography>
                ) : (
                  <Typography className={classes.text}>
                    {item.kpiName === 'users'
                      ? isTBAccount
                        ? CommonStrings.Patients
                        : CommonStrings.Creators
                      : item.name}
                  </Typography>
                )}
                <Tooltip
                  title={
                    isHealthCare && item.kpiName
                      ? item.tooltipText.replace('creators', 'patients')
                      : item.tooltipText
                  }
                  padding="12px"
                  rounded="12px"
                  icon={
                    <Box className={classes.circle}>
                      <span>?</span>
                    </Box>
                  }
                />
              </Grid>

              <Box className={classes.value}>{numberWithCommas(item.value)}</Box>
            </Box>
          </Grid>

          {!accountsPage && (
            <Box className={classes.ratioWrapper}>
              <span>{!!item.ratioLable && item.ratioLable}</span>
              <span>{!!item.ratioValue && numberWithCommas(item.ratioValue)}</span>
            </Box>
          )}
        </StatisticsBlock>
      </Grid>
    );
  };

  // hide conversion due to: https://socialvenu.atlassian.net/browse/SV-558
  // const isConversionsHidden = kpis.convertedReferrals === 0;
  const isConversionsHidden = true;

  const isSuperAdmin =
    ([UserRoles.admin_role] as string[]).includes(role) ||
    !!distributors.length ||
    !!affiliates.length;

  return (
    <Grid spacing={2} container justifyContent="center" alignItems="center" width="100%">
      {items
        .filter((item) => {
          if (item.kpiName === GlobalAnalyticsNames.gbpAccounts && !isSuperAdmin) {
            return false;
          }
          return !(isConversionsHidden && item.name === 'Conversions');
        })
        .map((item) => (
          <Grid
            item
            key={item.kpiName}
            flexGrow={1}
            style={{
              flexShrink: isConversionsHidden ? '1' : '0',
            }}
            id={`statistic-block-${item.name}`}
          >
            {renderStatisticBlock(item)}
          </Grid>
        ))}
    </Grid>
  );
};
