import { Box, Divider, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

interface FakeTextFieldProps extends BoxProps {
  title: string;
  value?: string;
  color?: string;
  required?: boolean;
  asterisk?: boolean;
}

export const FakeTextField: React.FC<FakeTextFieldProps> = ({
  title,
  value,
  color = 'white',
  required = false,
  asterisk,
  ...boxProps
}) => {
  const titleWithAsterisk = `${title}${required && asterisk ? '*' : ''}`;

  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      <Typography fontSize="13px" color={color}>
        {titleWithAsterisk}
      </Typography>
      <Typography fontSize="11px" color={color} my="4px">
        {value}
      </Typography>
      {required && (
        <Typography fontSize="13px" color="#94A5AB" my="4px" style={{ minHeight: '16px' }}>
          Required
        </Typography>
      )}
      <Divider style={{ borderColor: color }} />
    </Box>
  );
};
