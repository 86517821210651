import { useEffect, useState } from 'react';

export const useCircularTimer = (maxLength: number) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((progress) => (progress >= maxLength ? maxLength : progress + maxLength / 10));
    }, 100);
    if (progress === maxLength) clearInterval(timer);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return progress;
};
