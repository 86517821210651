import React, { FC } from 'react';
import { useStyles } from './ThemeSelect.styles';
import { Box, MenuItem, Select } from '@mui/material';
import { VideoAppTheme } from '../../../api/models/venue';
import { SelectOption } from '../TextField/SelectTextField';
import { SelectIcon } from './SelectIcon';

interface Props {
  onChange: (val: VideoAppTheme) => void;
  value: VideoAppTheme;
}

export const ThemeSelect: FC<Props> = ({ onChange, value }) => {
  const styles = useStyles();

  const selectOptions: SelectOption[] = [
    {
      value: 'dark',
      content: 'Dark',
    },
    {
      value: 'light',
      content: 'Light',
    },
  ];

  return (
    <Select
      value={value}
      fullWidth
      className={styles.select}
      onChange={(event) => {
        onChange(event.target.value as VideoAppTheme);
      }}
      renderValue={(value) => {
        return <>{selectOptions.find((opt) => opt.value === value)?.content}</>;
      }}
      IconComponent={() => (
        <Box className={styles.iconWrapper}>
          <SelectIcon />
        </Box>
      )}
      MenuProps={{
        style: {
          zoom: '0.9',
        },
      }}
    >
      {selectOptions.map((option) => {
        return (
          <MenuItem value={option.value} key={option.value} className={styles.selectItem}>
            {option.content}
          </MenuItem>
        );
      })}
    </Select>
  );
};
