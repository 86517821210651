import { Box, ListItem as MuiListItem, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
// import formatDate from 'date-fns/format';
import React, { useEffect } from 'react';

import { ExternalLink } from '../../common/components/Link/ExternalLink';
import {
  SubSectionTitle,
  SubSectionTitleProps,
} from '../../common/components/SubSectionTitle/SubSectionTitle';
import { useServiceAgreementLink } from '../../hooks/useServiceAgreementLink';
import { useVenue } from '../../hooks/useVenue';
import { redirectToPage } from '../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  getBillingSubscriptionDetailsSimplified,
  getCustomerPortalSession,
} from '../../store/slices/BillingSlice';
import { getServiceAgreement } from '../../store/slices/venueSlice';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { AccountEventNames } from '../../common/constants/events/accountEvents';
import { PaymentIcon } from '../../common/assets/newDesign/WebAppIcons/PaymentIcon';
import { RoundedButton } from '../../common/components/Button/RoundedButton';
import { BillingStrings } from '../../common/localization/en';

const SectionTitle: React.FC<SubSectionTitleProps> = ({ title }) => {
  const theme = useTheme();

  return (
    <SubSectionTitle
      title={title}
      titleProps={{
        fontSize: '24px',
        fontWeight: 700,
      }}
      bgcolor={theme.palette.common.pageBackground}
      py="6px"
      px="14px"
      borderRadius="9px"
      mb="16px"
    />
  );
};

const ListItem: React.FC = ({ children }) => {
  return (
    <MuiListItem sx={{ display: 'list-item', fontWeight: '400', p: 0, my: '4px', ml: '20px' }}>
      {children}
    </MuiListItem>
  );
};

export const AccountOverview: React.FC = () => {
  const venue = useVenue();
  const serviceAgreementLink = useServiceAgreementLink();
  const { subscriptionDetails, pricingPlan, isLoading } = useTypedSelector(
    (state) => state.BillingSlice,
  );
  const { id } = useTypedSelector((state) => state.venue.venue);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (id) {
      dispatch(getServiceAgreement({ id }));
      dispatch(getBillingSubscriptionDetailsSimplified({ venueId: id }));
    }
  }, [id, dispatch]);

  const { serviceAgreement } = useTypedSelector((state) => state.venue);

  const handleOpenAgreement = () => {
    const url =
      !serviceAgreement.error && serviceAgreement.value
        ? window.URL.createObjectURL(serviceAgreement.value)
        : serviceAgreementLink;

    redirectToPage(url, true);
    trackEvent(AccountEventNames.viewAgreement);
  };

  const planName = 'Premium';
  const subscriptionPrice = pricingPlan?.subscriptionFee;

  // FIXME: use real values in nextPaymentDate and planUpdateLink
  const nextPaymentDate = subscriptionDetails?.currentPeriodEnd
    ? format(new Date(subscriptionDetails?.currentPeriodEnd), 'MMM d, yyyy')
    : null;
  const billingPeriod = pricingPlan?.billingPeriod === 'monthly' ? 'monthly' : 'yearly';
  // const planUpdateLink = '#';

  const redirectToManagePaymentPage = async () => {
    const data = await dispatch(
      getCustomerPortalSession({
        payload: {
          venueId: venue.venueId,
          successUrl: window.location.origin,
        },
      }),
    );

    const redirectUrl = data?.payload.redirectUrl;
    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    }
  };

  const openMail = () => {
    window.location.href = 'mailto:feedback@socialvenu.com?subject=Plan%20Information';
  };

  return (
    <Box width="100%" bgcolor="white" borderRadius="15px" p="30px">
      <Box mb="46px">
        <SectionTitle title="Your Plan" />

        {pricingPlan && (
          <ListItem>
            Your account is on the{' '}
            <Typography component="span" color="black" fontWeight={700}>
              {planName} plan
            </Typography>{' '}
            (<ExternalLink title="View Agreement" onClick={handleOpenAgreement} />
            ), paying {billingPeriod}.
          </ListItem>
        )}
        {subscriptionPrice && pricingPlan ? (
          <ListItem>
            Your{' '}
            <Typography component="span" color="black" fontWeight={400}>
              {billingPeriod}
            </Typography>{' '}
            subscription will be billed on{' '}
            <Typography component="span" color="black" fontWeight={700}>
              {nextPaymentDate},
            </Typography>{' '}
            for{' '}
            <Typography component="span" color="black" fontWeight={700}>
              {subscriptionDetails?.subscriptionFee}
            </Typography>{' '}
            + tax.
            {nextPaymentDate && (
              <Typography component="span" color="black" fontWeight={700}></Typography>
            )}
          </ListItem>
        ) : (
          <ListItem>
            Contact SocialVenu for information about your plan{' '}
            <ExternalLink title="Contact" onClick={openMail} />
          </ListItem>
        )}
      </Box>
      <Box mb="46px">
        <SectionTitle title="Billing Actions" />

        {pricingPlan ? (
          <>
            <ListItem>Future charges will be billed to the card we have in our system.</ListItem>
            <ListItem>Credit Card information and prior invoices provided by Stripe:</ListItem>
            <RoundedButton
              title={BillingStrings.AccessPaymentInfo}
              outlined={true}
              startIcon={<PaymentIcon />}
              onClick={redirectToManagePaymentPage}
              loading={isLoading}
              sx={{
                width: '325px !important',
                height: '45px',
                whiteSpace: 'nowrap',
                marginTop: '15px',
              }}
            />{' '}
          </>
        ) : (
          <ListItem>
            Contact SocialVenu for information about your plan{' '}
            <ExternalLink title="Contact" onClick={openMail} />
          </ListItem>
        )}
      </Box>
    </Box>
  );
};
