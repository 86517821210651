import { Button, Link, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { FSModal } from '../../../../common/components/modal/Modal';
import { theme } from '../../../../theme';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { Link as ReactLink } from 'react-router-dom';
import { WebsiteRoutes } from '../../../../common/constants/routes';

interface UnlockLastCampaignModalProps {
  isOpen: boolean;
  campaign?: CampaignsApiModel;
  onClose: () => void;
  onConfirm: () => void;
}

export const UnlockLastCampaignModal: FC<UnlockLastCampaignModalProps> = ({
  isOpen,
  campaign,
  onClose,
  onConfirm,
}) => {
  const campaignLink = WebsiteRoutes.CampaignDetails.replace(':id', campaign?.id || '');

  return (
    <FSModal onClose={onClose} modalIsOpen={isOpen} width="400px" rounded="15px" closeBtn>
      <Typography
        style={{
          fontWeight: '500',
          fontSize: '18px',
          lineHeight: '150%',
          textAlign: 'center',
          letterSpacing: '0.02em',
        }}
      >
        Your last campaign is closed to new users
      </Typography>
      <Typography
        style={{
          fontWeight: '400',
          fontSize: '13px',
          lineHeight: '160%',
          textAlign: 'center',
          letterSpacing: '0.02em',
          color: theme.palette.common.gray,
          marginTop: '12px',
        }}
      >
        Ending this campaign will result in making campaign{' '}
        <Link component={ReactLink} to={campaignLink}>
          {campaign?.name}
        </Link>{' '}
        open for new users, do you wish to continue?
      </Typography>
      <Stack mt={'23px'} justifyContent="center" direction="row" spacing={2}>
        <Button
          variant="outlined"
          size="large"
          style={{ borderRadius: '100px', padding: '8px 36px' }}
          onClick={onClose}
        >
          No thanks
        </Button>
        <Button
          variant="contained"
          size="large"
          style={{ borderRadius: '100px', padding: '8px 36px' }}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </Stack>
    </FSModal>
  );
};
