export interface Attribute {
  name: string;
  operation: SearchOperationsType;
  value: any;
}
export interface SearchFilterApiModel {
  id: string;
  venueId: string;
  name: string;
  target: SearchTargetsType;
  filter: {
    attributes: Attribute[];
  };
  searchText: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  lastModifiedBy: string;
}
// start properties / db column name export
export const creationDate = 'createdAt';

export const status = 'status';

export enum statItemsIdEnum {
  views = 'views',
  shares = 'shares',
  ctaClicks = 'ctaClicks',
  conversions = 'conversions',
  rewardsFulfilled = 'rewardsFulfilled',
  totalPoints = 'totalPoints',
  videoCtaClicks = 'videoCtaClicks',
  campaignPoints = 'campaignPoints',
  points = 'points',
  // may be we do not have something like this on backend
  //keywordScore = 'keywordScore'
}

// end column name export

export interface SearchFilterAttributes {
  name: string; //property object of the targeted table / api model
  operation: SearchOperationsType;
  value: any;
}

export interface PostSearchFiltersRequest {
  venueId: string;
  name: string;
  target: SearchTargetsType;
  filter: {
    attributes: SearchFilterAttributes[];
  };
  searchText: string;
}

export interface GetSearchFiltersRequest {
  venueId: string;
  target: SearchTargetsType;
}

export type SearchTargetsType = 'USERS' | 'VIDEOS' | 'VENUES' | 'INCENTIVE_CAMPAIGNS';
export enum SearchTargets {
  USERS = 'USERS',
  VIDEOS = 'VIDEOS',
  VENUES = 'VENUES',
  INCENTIVE_CAMPAIGNS = 'INCENTIVE_CAMPAIGNS',
}

export type SearchOperationsType =
  | 'EQUALS'
  | 'IN'
  | 'GREATER_THAN'
  | 'LESS_THAN'
  | 'IN_RANGE'
  | 'EXISTS';
export enum SearchOperations {
  EQUALS = 'EQUALS',
  IN = 'IN',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  IN_RANGE = 'IN_RANGE',
  EXISTS = 'EXISTS',
}
