import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="82"
    height="82"
    viewBox="0 0 82 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_1398_24863)">
      <path
        d="M41 39.6774C51.9566 39.6774 60.8387 30.7953 60.8387 19.8387C60.8387 8.88209 51.9566 0 41 0C30.0434 0 21.1613 8.88209 21.1613 19.8387C21.1613 30.7953 30.0434 39.6774 41 39.6774Z"
        fill="#7545FF"
      />
      <path
        d="M41 37.0323C50.4957 37.0323 58.1935 29.3345 58.1935 19.8387C58.1935 10.343 50.4957 2.64517 41 2.64517C31.5043 2.64517 23.8064 10.343 23.8064 19.8387C23.8064 29.3345 31.5043 37.0323 41 37.0323Z"
        fill="#5C35CB"
      />
      <path
        d="M41 35.7097C49.7653 35.7097 56.871 28.604 56.871 19.8387C56.871 11.0734 49.7653 3.96774 41 3.96774C32.2347 3.96774 25.129 11.0734 25.129 19.8387C25.129 28.604 32.2347 35.7097 41 35.7097Z"
        fill="#9E96ED"
      />
      <path
        d="M42.9839 18.5161H39.0161C37.191 18.5161 35.7097 17.0348 35.7097 15.2097C35.7097 13.3845 37.191 11.9032 39.0161 11.9032H44.9677C45.6952 11.9032 46.2903 11.3081 46.2903 10.5806C46.2903 9.85322 45.6952 9.25806 44.9677 9.25806H42.3226V7.93548C42.3226 7.20806 41.7274 6.6129 41 6.6129C40.2726 6.6129 39.6774 7.20806 39.6774 7.93548V9.25806H39.0161C35.7361 9.25806 33.0645 11.9297 33.0645 15.2097C33.0645 18.4897 35.7361 21.1613 39.0161 21.1613H42.9839C44.809 21.1613 46.2903 22.6426 46.2903 24.4677C46.2903 26.2929 44.809 27.7742 42.9839 27.7742H37.0323C36.3048 27.7742 35.7097 28.3694 35.7097 29.0968C35.7097 29.8242 36.3048 30.4193 37.0323 30.4193H39.6774V31.7419C39.6774 32.4694 40.2726 33.0645 41 33.0645C41.7274 33.0645 42.3226 32.4694 42.3226 31.7419V30.4193H42.9839C46.2639 30.4193 48.9355 27.7477 48.9355 24.4677C48.9355 21.1877 46.2639 18.5161 42.9839 18.5161Z"
        fill="white"
      />
      <path d="M82 56.871V82H66.129L82 56.871Z" fill="#9E96ED" />
      <path
        d="M70.9961 57.7703L74.0645 52.9032L82 56.871L66.129 82L58.1935 78.0323L60.7593 73.9852L70.9961 57.7703Z"
        fill="#EDF4FA"
      />
      <path
        d="M16.6513 37.8126C17.1274 37.3232 17.7887 37.0323 18.5161 37.0323C20.2487 37.0323 21.9416 37.5216 23.3964 38.4474L35.7097 46.2903H29.7581C28.7793 46.2903 27.8668 46.5945 27.1261 47.1235L16.0958 39.6774C16.0297 39.6245 15.9503 39.5848 15.8842 39.5319C15.9106 38.8574 16.2016 38.2623 16.6513 37.8126Z"
        fill="#D9D4FF"
      />
      <path
        d="M27.7742 55.5484C27.7742 55.5484 15.9371 46.0787 10.541 41.7671C10.131 41.4365 9.70773 41.1587 9.25806 40.8942V40.6429C9.25806 39.3732 10.3558 38.3548 11.7181 38.3548H11.9032C13.3845 38.3548 14.6806 38.7648 15.8842 39.5319C15.9503 39.5848 16.0297 39.6245 16.0958 39.6774L27.1261 47.1236C26.9013 47.2823 26.6897 47.4542 26.4913 47.6526C25.6448 48.4858 25.129 49.6497 25.129 50.9194C25.129 53.4719 27.2055 55.5484 29.7581 55.5484H27.7742Z"
        fill="#D9D4FF"
      />
      <path
        d="M10.541 41.7671C15.9371 46.0787 27.7742 55.5484 27.7742 55.5484H29.7581C27.2055 55.5484 25.129 53.4719 25.129 50.9193C25.129 49.6497 25.6448 48.4858 26.4913 47.6526C26.6897 47.4542 26.9013 47.2823 27.1261 47.1235C27.8668 46.5945 28.7794 46.2903 29.7581 46.2903H54.2258C55.9187 46.2903 57.5852 46.6342 59.1194 47.269C60.6536 47.9039 62.0555 48.8297 63.259 50.0332L70.9961 57.7703L60.7594 73.9852L59.5161 72.7419H36.6223C32.6148 72.7419 28.7529 71.3003 25.7374 68.6816L1.49452 47.6526C0.542258 46.7797 0 45.5497 0 44.2668V44.1477C0 41.6745 1.9971 39.6774 4.47032 39.6774H4.5629C6.22936 39.6774 7.8429 40.1006 9.25807 40.8942C9.70774 41.1587 10.131 41.4364 10.541 41.7671Z"
        fill="#EEECFD"
      />
      <path
        d="M50.2581 51.5806H30.4194C28.3694 51.5806 26.6103 50.4035 25.7242 48.6842C25.3539 49.3455 25.129 50.0993 25.129 50.9193C25.129 53.4719 27.2055 55.5484 29.7581 55.5484H54.2258C54.2258 53.3529 52.4535 51.5806 50.2581 51.5806Z"
        fill="#9E96ED"
      />
      <path
        d="M67.7426 54.5168L64.1848 60.0981C61.2752 64.6742 56.2229 67.4516 50.8003 67.4516H34.5194C32.7206 67.4516 30.9748 66.8432 29.5729 65.719L4.82742 45.9861C2.89645 44.5181 2.28806 42.0316 3.08161 39.9155C1.29613 40.4974 0 42.1639 0 44.1477V44.2668C0 45.5497 0.542258 46.7797 1.49452 47.6526L25.7374 68.6816C28.7529 71.3003 32.6148 72.7419 36.6223 72.7419H59.5161L60.7594 73.9852L70.9961 57.7703L67.7426 54.5168Z"
        fill="#9E96ED"
      />
      <path
        d="M25.129 50.9193C25.129 49.6497 25.6448 48.4858 26.4913 47.6526C26.6897 47.4542 26.9013 47.2823 27.1261 47.1235L26.1606 46.4755C24.4281 47.7319 22.0739 47.8377 20.2223 46.6606L10.6468 40.5635C10.0516 40.18 9.90612 39.4261 10.2235 38.831C9.64161 39.2542 9.25806 39.9023 9.25806 40.6429V40.8942C9.70773 41.1587 10.131 41.4364 10.541 41.7671C15.9371 46.0787 27.7742 55.5484 27.7742 55.5484H29.7581C27.2055 55.5484 25.129 53.4719 25.129 50.9193Z"
        fill="#5242EA"
      />
      <path
        d="M29.9035 44.9677C28.5148 44.9677 27.1393 44.5974 25.9358 43.9097L19.151 39.9948C18.04 39.4394 17.5506 38.2623 17.749 37.1513C17.3258 37.2835 16.9555 37.4952 16.6513 37.7994C16.2016 38.249 15.9106 38.8442 15.8842 39.5187C15.9503 39.5716 16.0297 39.6113 16.0958 39.6642L27.1261 47.1103C27.8668 46.5813 28.7793 46.2771 29.7581 46.2771H35.7097L33.6332 44.9545H29.9035V44.9677Z"
        fill="#5242EA"
      />
      <path
        d="M77.6274 75.3862C75.5906 75.3862 74.321 73.1907 75.3261 71.4317L81 61.4991V56.87L65.129 81.9991H81V75.3862H77.6274Z"
        fill="#5242EA"
      />
      <path
        d="M78.0587 54.9003L67.2268 71.6442C65.8777 73.7339 63.2458 74.5803 60.9313 73.7074L60.7593 73.9852L58.1935 78.0323L66.129 82L82 56.871L78.0587 54.9003Z"
        fill="#D6E0EB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1398_24863">
        <rect width="82" height="82" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SaleAmountIconBlue = memo(SvgComponent);
export default SaleAmountIconBlue;
