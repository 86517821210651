import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useActionBarStyles = makeStyles<Theme>((theme: Theme) => ({
  topbarWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 112,
    bottom: -20,
    right: 0,
    position: 'fixed',
    backgroundColor: '#fff',
    padding: '24px 100px 36px 58px',
    zIndex: 100,
    background: theme.palette.common.white,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  styledButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '14px',
    padding: '10px 40px',
  },
  cancelButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
}));
