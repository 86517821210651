import { theme } from './../../../theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '840px',
    height: 'auto',
    margin: '26px auto 0',
    borderRadius: '25px',
    padding: '40px 40px',
    border: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.common.white,
  },
  titleText: {
    fontSize: '18px',
    fontWeight: '600',
    textAlign: 'center',
    color: '#000',
  },
  descrWrapper: {
    margin: '0 auto',
    width: '478px',
    textAlign: 'center',
  },
  videoAppText: {
    fontSize: '14px',
    fontWeight: '500',
  },
  descrText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '10px',
  },
  linksWrapper: {
    display: 'flex',
    marginTop: '30px',
    width: 'auto',
    margin: '0 auto',
    justifyContent: 'scenter',
    alignItems: 'center',
  },
  linkWrapper: {
    width: '51px',
    height: '51px',
    border: '1px solid  #EAECF0',
    display: 'grid',
    placeItems: 'center',
    borderRadius: '50%',
  },
  invitationsLink: {
    borderRadius: '22px',
    border: '1px solid  #EAECF0',
    background: '#fff',
    height: '130px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '200px',
  },
  invitationsLinkText: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '38px',
    color: '#101828',
  },
  invitationsLinkTextDescription: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '17px',
    width: '232px',
    color: '#8F8F8F',
    background: '#F5F6F8',
    position: 'absolute',
    top: '260px',
    zIndex: '2',
    padding: '10px',
  },
  fastPass: {
    borderRadius: '50px',
    background: '#fff',
    color: '#5242EA',
    border: '1px solid #E7E8EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 28px',
    fill: 'red',
    position: 'absolute',
    zIndex: '2',
    top: '180px',
    '& p': {
      color: '#101828',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '28px',
    },
  },
  orText: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    color: '#5242EA',
    background: 'rgba(82, 66, 234, 0.2)',
    padding: '3px 7px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 8px',
  },
  contactIconsWrapper: {
    marginLeft: '346px',
  },
  videoIconsWrapper: {
    margin: '15px auto 0',
    width: '260px',
    height: 'auto',
    borderRadius: '22px',
    background: '#EEECFC',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '14px 0',
    transform: 'translateX(-56px)',
  },
  videoTitle: {
    marginTop: '6px',
    marginBottom: '6px',
    fontSize: '12px',
    fontWeight: '600',
    color: '#5242EA',
    letterSpacing: '0.02em',
  },
  videoIcon: {
    width: '236px',
    height: '120px',
    border: '2px solid rgba(82, 66, 234, 0.99)',
    borderRadius: '22px',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconWrapper: {
    width: '52px',
    height: '52px',
    border: '2px solid rgba(82, 66, 234, 0.99)',
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
    marginTop: '12px',
  },
  videoIconText: {
    fontWeight: 500,
    lineHeight: '38px',
    fontSize: '20px',
    color: '#5242EA',
  },
  arrowWrapper: {
    marginRight: '112px',
    marginTop: '-2px',
    height: '24px',
  },
  directionArrowWrapper: {
    position: 'absolute',
    top: '129px',
    left: '90px',
  },
  directionArrowLeftWrapper: {
    position: 'absolute',
    top: '129px',
    left: '82px',
  },
}));
