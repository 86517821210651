import { FC } from 'react';

export const DataSyncIcon: FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.30834 7.92531C1.90834 8.16697 1.91667 13.092 5.30834 13.3336H13.3583C14.3333 13.342 15.275 12.9753 16 12.317C18.3833 10.2337 17.1083 6.06696 13.9667 5.66696C12.8417 -1.11638 3.01668 1.45864 5.34168 7.92531"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.334V15.834"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99999 19.1673C10.9205 19.1673 11.6667 18.4211 11.6667 17.5007C11.6667 16.5802 10.9205 15.834 9.99999 15.834C9.07952 15.834 8.33333 16.5802 8.33333 17.5007C8.33333 18.4211 9.07952 19.1673 9.99999 19.1673Z"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 17.5H11.6667"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33333 17.5H5"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
