import { Box, Divider } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ITab } from './Carousel.helper';
import { Tabs } from '../Tabs/Tabs';
import { useClasses } from './Carousel.helper';
import { RewardsSubroutes } from '../../constants/routes';
import { useInterval } from '../../../hooks/useInterval';

const CAROUSEL_AUTOSCROLL_TIMEOUT = 3000;

// eslint-disable-next-line no-lone-blocks

/*
  USAGE
  const [selectedAccordion, setSelectedAccordion] = useState(0);//index of accordion
  const displayCorrespondingCarouselTabs = () => {
    const [personalize,reward] = QuickStartSections;
    return personalize.accordion.find((acc, i) => i === selectedAccordion)?.tabs;
  };
              <Carousel tabs={displayCorrespondingCarouselTabs() || []}>
                <CarouselItem>{SettingsSubnavigationStrings.BrandingStyle}</CarouselItem>
                <CarouselItem>{SettingsSubnavigationStrings.QRCode}</CarouselItem>
                <CarouselItem>{SettingsSubnavigationStrings.Coupon}</CarouselItem>
                <CarouselItem>{WebAppPageStrings.SignUpFields}</CarouselItem>
              </Carousel>
            */

interface CarouselItemProps {
  width?: string;
  subroute?: RewardsSubroutes;
  height?: string;
}
export const CarouselItem: FC<CarouselItemProps> = ({ height, children }) => {
  const classes = useClasses();
  return (
    <Box
      className={classes.carouselItem}
      style={{ width: '100%', height: height ? height : '', minHeight: 'calc(100vh - 275px)' }}
    >
      {children}
    </Box>
  );
};

interface CarouselProps {
  tabs: ITab[];
  activeTabIndex: number;
  onActiveTabUpdate?: (tabIndex: number) => void;
  onAutoScroll?: () => void;
  hideDivider?: boolean;
  pX?: string;
  handleTabClick?: (tab: ITab) => void;
}

const Carousel: FC<CarouselProps> = ({
  tabs,
  activeTabIndex,
  onActiveTabUpdate,
  onAutoScroll,
  hideDivider,
  children,
  pX,
  handleTabClick,
}) => {
  const classes = useClasses();

  const [isHovered, setIsHovered] = useState(false);

  const { resetInterval: resetAutoscrollInterval } = useInterval(
    () => onAutoScroll?.(),
    isHovered ? null : CAROUSEL_AUTOSCROLL_TIMEOUT,
  );

  // reset autoscroll timeout on tab change
  useEffect(() => {
    resetAutoscrollInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  return (
    <Box
      className={classes.carousel}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box className={classes.indicators} mb="30px" px={pX || '0'}>
        <Tabs
          items={tabs || []}
          onTabChange={(index) => {
            onActiveTabUpdate?.(index);
            if (handleTabClick) {
              handleTabClick(tabs[index]);
            }
          }}
          activeTabIndex={activeTabIndex}
        />
      </Box>
      {!hideDivider && (
        <Box mb="20px">
          <Divider
            color="#DCDBE4"
            style={{
              height: '2px',
            }}
          />
        </Box>
      )}
      <Box
        className={classes.inner}
        style={{
          transform: `translateX(-${activeTabIndex * 100}%)`,
          // FIXME: didn't find a way to do it without grid
          display: 'grid',
          gridTemplateColumns: `repeat(${tabs.length}, 100%)`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default Carousel;
