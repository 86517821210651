import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: '0 24px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  rightSideWrapper: {
    width: '289px',
    marginLeft: '36px',
  },
  videoWrapper: {
    position: 'relative',
    borderRadius: '14px',
    transform: 'scale(0.8)',
    marginTop: '-20px',
    maxHeight: '500px',
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffffff80',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    borderColor: '#D0D5DD',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  button: {
    padding: '10px 23px',
    height: '44px',
    fontWeight: 600,
    borderRadius: '8px',

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  cancelButton: {
    color: '#344054',
    border: '2px solid #D0D5DD',

    '&:hover': {
      borderColor: '#344454',
    },
  },
  confirmButton: {
    color: '#5242EA',
    border: '2px solid #D0D5DD',

    '&:hover': {
      borderColor: '#5242EA',
    },
  },
  leftSideWrapper: {
    width: '100%',
    paddingTop: '32px',
    position: 'relative',
  },
  toggleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleLabel: {
    fontWeight: 500,
    color: '#344054',
  },
  hint: {
    fontSize: '14px',
    color: '#475467',
    marginTop: '15px',
  },
  recordButtonsWrapper: {
    display: 'flex',
    gap: '10px',
    marginTop: '10px',
  },
  downloadButton: {
    width: '170px',
    marginTop: '50px',
    marginLeft: 'calc(100% - 170px)',
  },
  progressWrapper: {
    marginTop: '10px',
    marginBottom: '-20px',
  },
  progress: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#EAECF0',
  },
  uploadingError: {
    marginTop: '10px',
    color: '#ED2E38',
    fontSize: '12px',
  },
  hidden: {
    opacity: 0,
  },
}));
