import { Draft } from 'immer';
import { TableDTO } from '../storeModels';
import { GetTableDataResponse } from '../../api/models/common';
import {
  getSortDirectionFromSortString,
  getSortFieldFromSortString,
} from '../../services/utilities';
import { OrderDirection } from '../../common/constants/constants';

export function tableLoadRejected<T>(state: TableDTO<T>): TableDTO<T> {
  state.isLoading = false;
  state.error = true;
  return state;
}

export function tableLoadPending<T>(state: TableDTO<T>): TableDTO<T> {
  state.isLoading = true;
  state.isLoaded = false;
  state.error = false;
  return state;
}

export function tableLoadFulFilled<T>(
  state: TableDTO<T>,
  payload: GetTableDataResponse<T>,
  itemsSplit?: 'active' | 'pending',
): TableDTO<T> {
  if (!payload.items) {
    state.isLoading = false;
    return state;
  }

  if (itemsSplit) {
    state.itemsSplit![itemsSplit] = payload.items;
  } else {
    state.items = payload.items;
  }

  state.error = false;
  state.isLoading = false;
  state.isLoaded = true;
  state.totalItems = payload.totalItems;
  state.totalPages = payload.totalPages;
  state.page = payload.page;
  return state;
}

export const getVideoThumbnail = (uri: string) =>
  uri.replace('s3://', 'https://s3.amazonaws.com/') + '_t.jpg';

export function setTableSorting<T>(state: Draft<TableDTO<T>>, payload: string): Draft<TableDTO<T>> {
  const sortDirection = getSortDirectionFromSortString(state.sort[0]);

  state.sort[0] = `${payload},${
    OrderDirection[sortDirection === OrderDirection.ASC ? 'DESC' : 'ASC']
  }`;

  return state;
}

export function getTableSorting<T>(state: Draft<TableDTO<T>>, payload: string): string[] {
  const columnName = payload === 'monthsActive' ? 'createdAt' : payload;

  let sorting = [] as string[];
  if (!state.sort) {
    sorting = [];
  }
  const sortField = getSortFieldFromSortString(state.sort[0]);
  const sortDirection = getSortDirectionFromSortString(state.sort[0]);

  if (sortField && sortField === columnName && sortDirection === OrderDirection.DESC) {
    sorting[0] = `${columnName},${OrderDirection.ASC}`;
  } else if (getSortDirectionFromSortString(payload)) {
    sorting[0] = columnName;
  } else {
    sorting[0] = `${columnName},${OrderDirection.DESC}`;
  }

  return sorting;
}

export function updateSliceLastUpdatedValue<T>(state: Draft<TableDTO<T>>): Draft<TableDTO<T>> {
  state.lastUpdated = new Date().toISOString();
  return state;
}

export const asyncCopyText = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    return false;
  }
};

export const cleanCopyText = (str: string, items: string[]) => {
  let out = str;
  items.forEach((item) => {
    out = out.replace(item, '');
  });
  return out;
};
