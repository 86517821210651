import { Theme, Typography, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import * as Yup from 'yup';
import { TableContent, TableRow as TableRowType } from '../../../../commonTypes';
import { CampaignsPageStrings, CommonStrings } from '../../../../localization/en';
import { theme } from '../../../../../theme';
import { REWARD_CAMPAIGN_MAX_LENGTH } from '../../../../constants/defaults';

interface Activities {
  status: boolean;
  title: string;
  points: number;
}

export interface ActivitiesContent extends TableRowType {
  status: JSX.Element;
  title: JSX.Element;
  points: JSX.Element;
}

export interface InitialValues {
  imageUrl: string;
  name: string;
  value: string;
}

export interface TableRowProps {
  selected: boolean;
  hasShadow?: boolean;
}

export interface TopBarProps {
  onCreateCampaign: any;
}

export interface IActivities {
  CreateVideo: {
    title: string;
    status: boolean;
    points: number;
  };
  CallToAction: {
    title: string;
    status: boolean;
    points: number;
  };
  Shares: {
    title: string;
    status: boolean;
    points: number;
  };
  Conversion: {
    title: string;
    status: boolean;
    points: number;
  };
  BonusPoints: {
    title: string;
    status: boolean;
    points: number;
  };
}

export const TableRowStyled = styled(TableRow)<TableRowProps>`
  color: var(--tableTextColor);
  height: 60px;
  border-collapse: collapse;
  td {
    position: relative;
    padding-left: 10px;
    vertical-align: middle;
    border-top: 1px solid #e1e5ee !important;
    background-color: ${theme.palette.text.secondary};
  }

  td:first-child {
    padding-left: 20px;
  }
`;

export const Container = styled.div`
  // padding: 30px;
  // background: var(--pageBackgroundColor);
`;
export const BodyContentContainer = styled.div`
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  // border-radius: 15px;
  background: ${theme.palette.common.white};
`;
export const ContentWrapper = styled.div`
  min-height: 470px;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${theme.palette.common.black};
  border-radius: 24px;
  overflow: hidden;
`;
interface Props {
  padding?: string;
}
export const PreviewContainer = styled.div<Props>`
  --iphoneXWidth: 258px;
  width: 100%;
  padding: ${(props) => (props.padding ? props.padding : '50px 73px')};
  background: var(--pageBackgroundColor);
  border-radius: 15px;
  @media (max-width: 900px) {
    padding: 5px 10px;
  }
`;

export const initialValues: IActivities = {
  CreateVideo: {
    title: 'CreateVideo',
    status: true,
    points: 25,
  },
  Shares: {
    title: 'Shares',
    status: true,
    points: 10,
  },
  CallToAction: {
    title: 'CallToAction',
    status: true,
    points: 50,
  },
  Conversion: {
    title: 'Conversion',
    status: false,
    points: 100,
  },
  BonusPoints: {
    title: 'BonusPoints',
    status: false,
    points: 100,
  },
};

export const createCampaignsActivitiesContent = ({
  items,
  renderSwitch,
  renderTitle,
  renderTextField,
}: {
  items: Activities[];
  renderSwitch: (activity: keyof IActivities) => JSX.Element;
  renderTitle: (activity: string) => JSX.Element;
  renderTextField: (activity: keyof IActivities) => JSX.Element;
}): TableContent<ActivitiesContent> => {
  const tableContent: TableContent<ActivitiesContent> = {
    header: {
      status: {
        name: CampaignsPageStrings.CampaignsActivitiesStatus,
        sortable: false,
      },
      title: {
        name: CampaignsPageStrings.CampaignsActivitiesConsumer,
        sortable: false,
      },
      points: {
        name: CampaignsPageStrings.CampaignsActivitiesPoints,
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => ({
    status: renderSwitch(item.title as keyof IActivities),
    title: renderTitle(item.title),
    points: renderTextField(item.title as keyof IActivities),
  }));
  return tableContent;
};

export const validationSchema = () =>
  Yup.object().shape({
    points: Yup.string().min(1, 'Points required').required('Required'),
    name: Yup.string()
      .required('Required')
      .min(3)
      .max(REWARD_CAMPAIGN_MAX_LENGTH.name, CommonStrings.ExceededCharLimit),
    action: Yup.string()
      .required('Required')
      .min(3)
      .max(REWARD_CAMPAIGN_MAX_LENGTH.action, CommonStrings.ExceededCharLimit),
    title: Yup.string()
      .required('Required')
      .min(3)
      .max(REWARD_CAMPAIGN_MAX_LENGTH.title, CommonStrings.ExceededCharLimit),
    imageUrl: Yup.string().min(3, 'Image required').required('Required'),
  });

interface InputWrapperProps {
  backgroundImage: string;
  isCampaignCard?: boolean;
}
export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  max-width: 176px;
  height: 120px;
  border: 1px dashed;
  border-color: ${theme.palette.primary.light};
  border-radius: 15px;
  background-color: #f5f6f8;
  background-image: ${(props) =>
    props.backgroundImage
      ? `url(${props.backgroundImage})`
      : props.isCampaignCard
      ? 'url(/default-incentive-preview.png)'
      : 'url(/uploadImage.png)'};
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  object-fit: cover;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    transform: scale(0.7);
    margin: -20px 0 -20px -20px;
  }
`;

export const SelectImageText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  position: absolute;
  bottom: 54px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
`;

export const PicturedInput = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  opacity: 0;
  cursor: pointer;
`;

export const SelectImageIconWrapper = styled.div`
  background: ${theme.palette.common.white};
  padding: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: 36px;
  width: 36px;
  border-radius: 50%;
`;

interface Props {
  status?: boolean;
  couponEnabled?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  FirstStageTitle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  FirstStageTitleText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: theme.palette.grey[500],
  },
  FirstStageInstruction: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    marginTop: '25px',
    paddingLeft: '16px',
  },
  PointsInput: {
    width: 'min-content',
    minWidth: '150px',
    '& input': {
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '48px',
      letterSpacing: '0.02em',
      height: '58px',
      color: '#484848',
    },
  },
  textInput: {
    width: '100%',
    minWidth: '128px',
    height: '42px',
    '& input': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
    },
  },
  topbarWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff',
    padding: '24px 58px',
    paddingLeft: '390px',
    zIndex: 100,
    background: theme.palette.common.white,
  },
  sectiontitle: {
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
  },
  styledButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '30px',
    padding: '10px 40px',
  },
  cancelButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
  incentiveSetupTitle: {
    fontWeight: 500,
    paddingTop: '20px',
    paddingLeft: '30px',
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
  },
  incentiveSetup: {
    marginTop: '20px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    '& p': {
      fontSize: '12px',
      lineHeight: '24px',
    },
    '& p:nth-child(2)': {
      marginLeft: '25px',
    },
    '& p:nth-child(3)': {
      flex: 1,
      display: 'flex',
      justifyContent: 'right',
      marginRight: '30px',
    },
  },
  row: {
    borderTop: '1px solid #dcdbe4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',

    '& .MuiFormHelperText-root': {
      marginLeft: '-20px',
      width: '108px',
      textAlign: 'center',
    },
  },
  switch: {
    width: '40px',
    marginRight: '30px',
  },
  activityTitle: (props) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: props.status ? theme.palette.common.black : '#8b89a0',
  }),
  activityPoints: {
    padding: '4px 12px',
    background: theme.palette.common.white,
    borderRadius: '6px',
    fontSize: '12px',
    lineHeight: '26px',
  },
  formLabel: {
    marginBottom: '10px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    color: '#8b89a0',
  },
  selectedImg: {
    position: 'absolute',
    top: '116px',
    borderRadius: '3px',
    width: '214px',
    height: '71px',
  },
  incentiveName: {
    position: 'absolute',
    top: '126px',
    left: '10px',
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '10px',
    zIndex: 2,
  },
  incentivePoints: {
    position: 'absolute',
    top: '121px',
    right: '10px',
    background: theme.palette.error.main,
    color: theme.palette.common.white,
    padding: '5px',
    fontWeight: 500,
    fontSize: '10px',
    lineHeight: '10px',
    zIndex: 2,
    borderTopLeftRadius: '80px 80px',
    borderBottomLeftRadius: '80px 80px',
    borderBottomRightRadius: '80px 80px',
  },
  tooltip: {
    cursor: 'pointer',
    padding: '12px',
    borderRadius: '50%',
    background: theme.palette.common.white,
    width: '40px',
    height: '40px',
  },
  tooltipIcon: {
    paddingLeft: '4px',
    lineHeight: '15px',
    fontSize: '12px',
    border: '1px solid #8B89A0',
    borderRadius: '50%',
    color: '#8B89A0',
    height: '16px',
    width: '16px',
  },

  EditIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    zIndex: 10,
    padding: '5px',
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  EditPointsIcon: {
    position: 'absolute',
    top: '20%',
    right: '0px',
  },
  EditImageIcon: {
    position: 'absolute',
    top: '25%',
    left: '-20px',
  },
  imageWrapper: {
    height: '260px !important',
    width: '100% !important',
    border: 'none !important',
    borderBottomLeftRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    maxWidth: '100% !important',
  },
  couponImageInput: {
    position: 'absolute',
    top: '0',
    height: '320px !important',
    width: '100% !important',
    border: 'none !important',
    borderBottomLeftRadius: '0 !important',
    borderBottomRightRadius: '0 !important',
    maxWidth: '100% !important',
  },
  selectImageIconWrapper: (props) => ({
    background: theme.palette.common.white,
    padding: '6px',
    position: 'absolute',
    top: ' 86%',
    left: props.couponEnabled ? '5%' : '8%',
    transform: 'translateY(-50%) translateX(-50%)',
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    pointerEvents: 'none',
    backgroundColor: '#F5F6F8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  EditActivitiesIcon: {
    position: 'absolute',
    top: '50%',
    right: '-20px',
  },
  EditIconActive: { background: theme.palette.primary.main },
  EditIconInactive: { background: '#ffffffcc' },
  new: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      backgroundColor: 'black',
      height: '100vh',
      width: '2px',
    },
  },
  editImageIcon: {
    position: 'absolute',
    top: '25%',
    left: '-20px',
  },
  editTextIcon: {
    position: 'absolute',
    bottom: '15%',
    right: '-20px',
  },
  DisableAccordion: {
    '& svg': {
      cursor: 'pointer',
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      cursor: 'default !important',
    },
  },
  ChangeImage: {
    borderRadius: '40px',
    padding: '5px 15px',
    background: 'rgba(82, 66, 234, 0.1)',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      outline: '1px solid',
    },
    marginBottom: '8px',
  },
  preview: {
    margin: '24px 16px 0 20px',
    borderRadius: '15px',
    background: theme.palette.common.white,
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    position: 'relative',
  },
  preview__off: {
    marginTop: '16px',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    textAlign: 'center',
  },
  preview__off_left: {
    textAlign: 'left',
  },
  preview__card: {
    padding: '0px 30px 50px',
  },
  preview__label: {
    marginBottom: '10px',
    color: theme.palette.common.gray,
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '26px',
    marginTop: '20px',
    fontFamily: 'Poppins',
  },
  preview__spaceTop: {
    marginTop: '4px',
  },
}));
