import { useEffect } from 'react';
import { GlobalAnalyticsNames, KPIApiNames } from '../../../../api/models/analytics';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getGlobalAnalytics } from '../../../../store/slices/analyticsSlice';
import { venuesInitialAnalyticsData } from './SuperAdminAnalyticsBlock.helper';
import { AnalyticsBlocks } from '../../../dashboard/AnalyticsSection/AnalyticsBlocks/AnalyticsBlocks';
import { useDistributors } from '../../../../hooks/useDistributors';
import { useAffiliates } from '../../../../hooks/useAffiliates';
import { useClientType } from '../../../../services/hooks/useClientType';

export const SuperAdminAnalyticsBlock = () => {
  const onClick = (kpiName: KPIApiNames, name: string) => {};
  const dispatch = useAppDispatch();
  const { activeDistributor } = useDistributors();
  const { activeAffiliate } = useAffiliates();
  const { isHealthCare, isTopGolfClient } = useClientType();

  useEffect(() => {
    const groupId = activeDistributor?.groupId || activeAffiliate?.groupId;
    dispatch(getGlobalAnalytics({ groupId }));
  }, [dispatch, activeAffiliate, activeDistributor]);

  const { globalAnalytics } = useTypedSelector((state) => state.analytics);

  const roundComasToNearestTwoDigitsDicimal = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const venueAnalyticsDataValues = [
    globalAnalytics[GlobalAnalyticsNames.venues],
    globalAnalytics[GlobalAnalyticsNames.liveCampaigns],
    globalAnalytics[GlobalAnalyticsNames.videos],
    globalAnalytics[GlobalAnalyticsNames.socialVerses],
    globalAnalytics[GlobalAnalyticsNames.gbpAccounts],
    globalAnalytics[GlobalAnalyticsNames.ctaClicks],
  ];
  const venueAnalyticsData = venuesInitialAnalyticsData.map((item, index) => {
    return {
      ...item,
      value: roundComasToNearestTwoDigitsDicimal(venueAnalyticsDataValues[index]),
    };
  });

  return (
    <AnalyticsBlocks
      onClick={onClick}
      items={venueAnalyticsData}
      isHealthCare={isHealthCare}
      isTopgolf={isTopGolfClient}
      accountsPage={true}
    />
  );
};
