import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderTop: '1px solid #DCDBE4',
    paddingTop: '147px',
    marginTop: '125px',
  },
  subsection: {
    padding: '0 0 55px 112px',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '40px',
    color: '#101828',
    marginBottom: '20px',
  },
  subTitle: {
    fontSize: '16px',
    color: '#475467',
    maxWidth: '510px',
    marginBottom: '40px',
  },
  secondaryTitle: {
    color: '#000',
    fontSize: '24px',
    fontWeight: 500,
  },
  nextStep: {
    padding: '5px 6px',
    fontSize: '38px',
    color: '#5242EA',
    fontWeight: 600,
    backgroundColor: '#EBE9FF',
    width: 'fit-content',
    borderRadius: '8px',
  },
  info: {
    maxWidth: '577px',
    color: '#475467',
    margin: '15px 0 47px 0',
  },
}));
