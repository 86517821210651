import { FC } from 'react';

export const PlayStropesIcon: FC = () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1184_1367"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="51"
      height="50"
    >
      <circle cx="25.25" cy="25" r="25" fill="#F1F1F1" />
    </mask>
    <g mask="url(#mask0_1184_1367)">
      <circle cx="25.25" cy="25" r="25" fill="#EEECFD" />
      <path
        d="M45.7401 7.3764L-0.257346 33.933"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2574 -2.11984L-5.74001 24.4368"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.7401 16.0367L4.74265 42.5933"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.2401 25.5629L10.2427 52.1196"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.25 12C10.25 10.8954 11.1454 10 12.25 10H38.25C39.3546 10 40.25 10.8954 40.25 12V30H10.25V12Z"
        fill="#D9D4FF"
        stroke="#5242EA"
      />
      <path
        d="M10.25 30H40.25V38C40.25 39.1046 39.3546 40 38.25 40H12.25C11.1454 40 10.25 39.1046 10.25 38V30Z"
        fill="white"
        stroke="#1A1538"
      />
      <path d="M15.25 34.7388H25.25" stroke="#1A1538" strokeLinecap="round" />
      <path d="M32.25 34.7388H35.25" stroke="#1A1538" strokeLinecap="round" />
      <path d="M13.25 13H37.25V27H13.25V13Z" fill="white" />
      <path
        d="M27.891 19.3176C28.4163 19.6209 28.4163 20.3791 27.891 20.6824L24.0617 22.8933C23.5364 23.1965 22.8798 22.8174 22.8798 22.2109L22.8798 17.7891C22.8798 17.1826 23.5364 16.8035 24.0617 17.1067L27.891 19.3176Z"
        fill="#1A1538"
      />
    </g>
  </svg>
);
