import { SidebarStrings } from '../localization/en';

export enum WebsiteRoutes {
  Mobile = '/mobile',
  Dashboard = '/dashboard',
  DashboardHeroes = '/dashboard/creators',
  DashboardWidget = '/dashboard/widget',
  Campaigns = '/campaigns',
  Heroes = '/creators',
  HeroesDetail = '/creators/:id',
  IncentiveAd = '/incentive-ad',
  Rewards = '/rewards',
  RewardsWizard = '/rewards/rewards-wizard',
  RewardsCampaigns = '/rewards/campaigns',
  RewardsLeaderboard = '/rewards/leaderboard',
  RewardsLeaderboardDetails = '/rewards/leaderboard/:id',
  RewardsList = '/rewards/rewards-list',
  RewardsActivities = '/rewards/rewards-activities',
  RedeemRequests = '/rewards/redeem-requests',
  Settings = '/settings',
  GeneralSettings = '/settings/general',
  TeamSettings = '/settings/team',
  SettingsManagers = '/settings/managers',
  TeamSettingsDetail = '/settings/team/:id',
  BrandingStyle = '/settings/webapp/branding-style',
  CategoriesFilters = '/settings/categories-filters',
  EducationCards = '/settings/education-cards',
  WebApp = '/settings/webapp/',
  WebAppSignUpConfig = '/settings/webapp/signup-config',
  WebAppCards = '/settings/webapp/webapp-cards',
  VideoEditing = '/settings/webapp/video-editing',
  Tips = '/settings/tips',
  Content = '/content',
  ContentAll = '/content/all',
  ContentDetail = '/content/all/:id',
  ContentPublished = '/content/published',
  ContentModerated = '/content/moderated',
  ContentUnpublished = '/content/unpublished',
  ContentArchived = '/content/archived',
  Integrations = '/integrations',
  socialverseRoutes = '/settings/socialverse/',
  IntegrationsWebWidget = '/settings/socialverse/widget',
  IntegrationsCreate = '/settings/socialverse/create',
  IntegrationsWebWidgetWithId = '/settings/socialverse/widget/:id',
  IntegrationsHistory = '/settings/socialverse/history',
  IntegrationsAddToWebsiteWithId = '/settings/socialverse/addToYourWebsite/:id',
  IntegrationsAddToWebsite = '/settings/socialverse/addToYourWebsite',
  Invites = '/settings/invites',
  IntegrationsSmsInvite = '/settings/invites/sms',
  IntegrationsQRCode = '/settings/invites/qr-code',
  Keywords = '/settings/keywords',
  SyncData = '/settings/sync/data',
  HistorySync = '/settings/sync/history',

  Help = '/help',
  HelpGuide = '/help/guide',
  HelpFaq = '/help/faq',
  HelpChat = '/help/chat',
  HelpSendEmail = '/help/send-email',

  Profile = '/profile',
  ProfileProfile = '/profile/profile',
  ProfileAccounts = '/profile/accounts',

  Accounts = '/accounts',
  AccountsOverview = '/accounts/overview',
  AccountsCompany = '/accounts/company',
  AccountsMembers = '/accounts/members',
  AccountsBilling = '/accounts/billing',
  AccountsDistributors = '/accounts/distributions',
  AccountsFAQVideos = '/accounts/faq-videos',
  AccountsUpdates = '/accounts/updates',
  AccountsProfile = '/accounts/profile',

  VideoSharing = '/settings/webapp/video-sharing',
  DownloadedVideo = '/settings/webapp/downloaded-video',
  SocialVerse = '/socialverse',
  SocialVerseAddVideos = '/socialverse/add-videos',
  SocialVerseSettings = '/socialverse/settings',
  Workspace = '/workspace',
  WorkspaceCompany = '/workspace/company',
  WorkspaceProfile = '/workspace/profile',
  WorkspaceMembers = '/workspace/members',
  WorkspaceMembersInvite = '/members/invite',
  WorkspaceMembersEdit = '/members/invite/:id',
  WorkspaceBilling = '/workspace/billing',
  ConfirgurationIncentive = '/settings/incentive',
  Beacons = '/settings/beacons',
  API = '/settings/api',
  DataSync = '/settings/sync/data',
  Filters = '/filters',
  UsersFilter = '/filters/results/creators',
  UsersFilterDetails = '/filters/results/creators/:id',
  VideosFilter = '/filters/results/videos',
  VideosFilterDetails = '/filters/results/videos/:id',
  IncentivesFilter = '/filters/results/incentives',

  PricingPlans = '/plans/',
  QuickStart = '/quickstart',
  QuickStartWelcome = '/quickstart/welcome',
  QuickStartPersonalize = '/quickstart/personalize',
  QuickStartRewards = '/quickstart/rewards',
  QuickStartInvite = '/quickstart/invite',
  QuickStartComplete = '/quickstart/complete',

  NewCampaign = '/new-campaign',
  EditCampaign = '/edit-campaign',
  RewardCampaigns = '/reward-campaigns',
  CampaignDetails = '/reward-campaigns/:id',

  WebAppSettings = '/webapp',
  BrandingStyleTab = '/webapp/branding-style',
  VideoPlaybackTab = '/webapp/video-playback',
  QRCode = '/webapp/qr-code',
  TrackingPixelTab = '/webapp/tracking-pixel',
  SignUpTab = '/webapp/signup-config',
}

export enum SocialvenueManagerRoutes {
  AdminMarketManagement = '/admin/market',
  AdminOrgManagement = '/admin/org',
  AddNewVenue = '/add-new-venue',
  Performance = '/admin/performance',
}

interface WebsiteRoutesName {
  route: WebsiteRoutes;
  name: SidebarStrings;
  isChild?: boolean;
  parent?: WebsiteRoutesName;
}

export const websiteRouteNameConnections: WebsiteRoutesName[] = [
  {
    route: WebsiteRoutes.DashboardHeroes,
    name: SidebarStrings.Dashboard,
  },
  {
    route: WebsiteRoutes.DashboardWidget,
    name: SidebarStrings.Dashboard,
  },
  {
    route: WebsiteRoutes.Campaigns,
    name: SidebarStrings.Campaigns,
  },
  {
    route: WebsiteRoutes.ContentAll,
    name: SidebarStrings.Content,
  },
  {
    route: WebsiteRoutes.ContentPublished,
    name: SidebarStrings.Published,
    isChild: true,
    parent: {
      route: WebsiteRoutes.ContentAll,
      name: SidebarStrings.Content,
    },
  },
  {
    route: WebsiteRoutes.ContentModerated,
    name: SidebarStrings.Moderated,
    isChild: true,
    parent: {
      route: WebsiteRoutes.ContentAll,
      name: SidebarStrings.Content,
    },
  },
  {
    route: WebsiteRoutes.ContentUnpublished,
    name: SidebarStrings.Unpublished,
    isChild: true,
    parent: {
      route: WebsiteRoutes.ContentAll,
      name: SidebarStrings.Content,
    },
  },
  {
    route: WebsiteRoutes.Heroes,
    name: SidebarStrings.Heroes,
  },
  {
    route: WebsiteRoutes.Integrations,
    name: SidebarStrings.Integrations,
  },
  {
    route: WebsiteRoutes.IntegrationsHistory,
    name: SidebarStrings.IntegrationsHistory,
    isChild: true,
    parent: {
      route: WebsiteRoutes.Integrations,
      name: SidebarStrings.Integrations,
    },
  },
  {
    route: WebsiteRoutes.RewardsCampaigns,
    name: SidebarStrings.Campaigns,
  },
  {
    route: WebsiteRoutes.RewardsWizard,
    name: SidebarStrings.RewardsWizard,
  },
  {
    route: WebsiteRoutes.GeneralSettings,
    name: SidebarStrings.General,
    isChild: true,
    parent: {
      route: WebsiteRoutes.Settings,
      name: SidebarStrings.Settings,
    },
  },
  {
    route: WebsiteRoutes.CategoriesFilters,
    name: SidebarStrings.Filters,
    isChild: true,
    parent: {
      route: WebsiteRoutes.Settings,
      name: SidebarStrings.Settings,
    },
  },
  {
    route: WebsiteRoutes.EducationCards,
    name: SidebarStrings.General,
    isChild: true,
    parent: {
      route: WebsiteRoutes.Settings,
      name: SidebarStrings.Settings,
    },
  },
  {
    route: WebsiteRoutes.Tips,
    name: SidebarStrings.General,
    isChild: true,
    parent: {
      route: WebsiteRoutes.Settings,
      name: SidebarStrings.Settings,
    },
  },
];

export enum UnprotectedRoutes {
  Login = '/login',
  CreateVenue = '/create-venue',
  UnAuthorized = '/unauthorized',
  ResetPassword = '/reset-password',
  Logout = '/logout',
  SignUp = '/signup-form',
  PricePlans = '/signup/plans',
  SignupSuccess = '/signup/success',
  QuickSignup = '/quick-signup',
  QuickSignupSuccess = '/quick-signup/success',
  CompleteProfile = '/complete-profile',
}

export enum WizardCreationRoute {
  SetupWizard = '/setup-wizard',
  WizardSuccess = '/wizard-success',
  WizardFailure = '/wizard-failure',
}

export enum SuperAdminRoutes {
  OrgManagement = '/admin/org',
  OrgManagements = '/admin/org/org-managers/:id',
  OrgManagementTeams = '/admin/org/teams/:id',
  OrgManagementDetail = '/admin/org/:tabName/:id',
  AddNewVenue = '/add-new-venue',
  Performance = '/admin/performance',
}

export enum DevRoutes {
  ResetFTE = '/dev/reset-fte',
  DevIcons = '/dev/icons',
}

export enum PersonalizationSubroutes {
  UploadLogo = 'upload-logo',
  UploadBackgroundImage = 'upload-background-image',
  SelectBrandColor = 'select-brand-color',
}

export enum RewardsSubroutes {
  Type = 'type',
  Behaviors = 'behaviors',
  Fulfillment = 'fulfillment',
}

export enum InviteSubroutes {
  ExcelImport = 'excel-import',
  ManualImport = 'manual-import',
}

export enum NewCampaignSubroutes {
  CampaignObjectives = 'campaign-objectives',
  VideoStyle = 'video-style',
  WhoIsInvited = 'who-invited',
  RewardCreators = 'reward-creators',
  CustomizeCard = 'customize-card',
  InviteSms = 'invite-sms',
  AcknowledgementSms = 'acknowledgement-sms',
  FulfillRewards = 'fulfil-rewards',
}

export const getRouteById = (route: string, id: string): string => {
  return route.replace(':id', id);
};
