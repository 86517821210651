import React from 'react';

const WebAppIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.8752 22.302H9.12516C4.89891 22.302 3.61475 21.0179 3.61475 16.7916V7.20829C3.61475 2.98204 4.89891 1.69788 9.12516 1.69788H14.8752C19.1014 1.69788 20.3856 2.98204 20.3856 7.20829V16.7916C20.3856 21.0179 19.1014 22.302 14.8752 22.302ZM9.12516 3.13538C5.69433 3.13538 5.05225 3.78704 5.05225 7.20829V16.7916C5.05225 20.2129 5.69433 20.8645 9.12516 20.8645H14.8752C18.306 20.8645 18.9481 20.2129 18.9481 16.7916V7.20829C18.9481 3.78704 18.306 3.13538 14.8752 3.13538H9.12516Z"
        fill="#FFFFFF"
      />
      <path
        d="M13.9168 6.48962H10.0835C9.69058 6.48962 9.36475 6.16379 9.36475 5.77087C9.36475 5.37796 9.69058 5.05212 10.0835 5.05212H13.9168C14.3097 5.05212 14.6356 5.37796 14.6356 5.77087C14.6356 6.16379 14.3097 6.48962 13.9168 6.48962Z"
        fill="#FFFFFF"
      />
      <path
        d="M12.0001 19.5325C10.783 19.5325 9.7959 18.5454 9.7959 17.3283C9.7959 16.1112 10.783 15.1241 12.0001 15.1241C13.2171 15.1241 14.2042 16.1112 14.2042 17.3283C14.2042 18.5454 13.2171 19.5325 12.0001 19.5325ZM12.0001 16.5521C11.5784 16.5521 11.2334 16.8971 11.2334 17.3187C11.2334 17.7404 11.5784 18.0854 12.0001 18.0854C12.4217 18.0854 12.7667 17.7404 12.7667 17.3187C12.7667 16.8971 12.4217 16.5521 12.0001 16.5521Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export default WebAppIcon;
