export enum CommonEventNames {
  color_change = 'color_change',
  carousel_tab_click = 'carousel_tab_click',

  image_uploader_area_click = 'image_uploader_area_click',
  background_image_library_open_button_click = 'background_image_library_open_button_click',
  background_image_library_selector_click = 'background_image_library_selector_click',
  background_image_library_select_button_click = 'background_image_library_select_button_click',
  background_image_library_cancel_button_click = 'background_image_library_cancel_button_click',
  background_image_library_upload_button_click = 'background_image_library_upload_button_click',

  company_logo_upload_button_click = 'company_logo_upload_button_click',

  data_sync_drop_zone_click = 'data_sync_drop_zone_click',
  data_sync_file_uploaded = 'data_sync_file_uploaded',
  data_sync_import_data_click = 'data_sync_import_data_click',
  data_sync_prev_step_button_click = 'data_sync_prev_step_button_click',
  data_sync_agreement_checkbox_click = 'data_sync_agreement_checkbox_click',
  data_sync_imported_property_first_name_changed = 'data_sync_imported_property_first_name_changed',
  data_sync_imported_property_last_name_changed = 'data_sync_imported_property_last_name_changed',
  data_sync_imported_property_cell_phone_changed = 'data_sync_imported_property_cell_phone_changed',
  data_sync_imported_property_email_changed = 'data_sync_imported_property_email_changed',
  data_sync_imported_property_transaction_date_changed = 'data_sync_imported_property_transaction_date_changed',
  data_sync_imported_property_opt_in_status_changed = 'data_sync_imported_property_opt_in_status_changed',
  data_sync_imported_property_id_changed = 'data_sync_imported_property_id_changed',

  approved_data_modal_agreement_checked = 'approved_data_modal_agreement_checked',
  approved_data_modal_agreement_button_click = 'approved_data_modal_agreement_button_click',
  approved_data_modal_agreement_close = 'approved_data_modal_agreement_close',

  add_users_manually_first_name_input_typed = 'add_users_manually_first_name_input_typed',
  add_users_manually_last_name_input_typed = 'add_users_manually_last_name_input_typed',
  add_users_manually_cell_number_input_typed = 'add_users_manually_cell_number_input_typed',
  add_users_manually_opt_in_status_changed = 'add_users_manually_opt_in_status_changed',
  add_users_manually_email_input_typed = 'add_users_manually_email_input_typed',
  add_users_manually_transaction_date_input_typed = 'add_users_manually_transaction_date_input_typed',
  add_users_manually_add_user_button_click = 'add_users_manually_add_user_button_click',
  add_users_manually_import_data_button_click = 'add_users_manually_import_data_button_click',
  add_users_manually_agreement_checkbox_click = 'add_users_manually_agreement_checkbox_click',
  delete_input_user_row_click = 'delete_input_user_row_click',
  delete_added_user_row_click = 'delete_added_user_row_click',

  unsaved_changes_modal_close = 'unsaved_changes_modal_close',
  confirm_primary_modal_close = 'confirm_primary_modal_close',
  unsaved_changes_modal_leave_button_click = 'unsaved_changes_modal_leave_button_click',
  unsaved_changes_modal_stay_on_page_button_click = 'unsaved_changes_modal_stay_on_page_button_click',
  confirm_primary_modal_cancel_button_click = 'confirm_primary_modal_cancel_button_click',
  confirm_primary_modal_confirm_button_click = 'confirm_primary_modal_confirm_button_click',
}
