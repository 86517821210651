import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCampaignSummaryStyles = makeStyles((theme: Theme) => ({
  RewardName: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '6px',
    width: 'fit-content',
    marginRight: '10px',
    marginBottom: '2px',
  },
  PrimaryCampaign: {
    marginRight: '8px',
    padding: '2px 10px',
    borderRadius: '6px',
    color: '#E0991F',
    backgroundColor: '#FFFAEB',
  },
  CampaignStatusActive: {
    color: '#027A48',
    backgroundColor: '#ECFDF3',
  },
  CampaignStatusInactive: {
    color: theme.palette.common.gray,
    backgroundColor: 'rgb(242, 244, 247)',
  },
  Container: {
    minHeight: '400px',
    height: '100%',
    '&.MuiPaper-root': {
      borderRadius: '13px',
      border: '1px solid #D0D5DD',
    },
  },
  SummaryInfo: {
    marginBottom: '12px',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '20px',
    color: '#475467',
  },
  TotalVideos: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '14px',
  },
  Total: {
    color: theme.palette.common.black,
  },
  CampaignName: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px',
    color: '#101828',
  },
  RewardTypeRewardNameContainer: {
    width: 'max-content',
    padding: '4px 8px',
    background: '#F9FAFB',
    borderRadius: '6px',
  },
  RewardTypeRewardName: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#344054',
  },
  activitiesList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '10px',
    gap: '10px',
  },
  campaignNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '-15px 0 -10px 0',
  },
  editButton: {
    padding: 0,
    marginTop: 10,
    width: 50,
    height: 50,
    marginLeft: 0,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  nameEditInput: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#000000',
    padding: '3px 14px',
    background: '#FAFAFA',
    border: '1px solid #DDD',
    borderRadius: '8px',
    width: '353px',
    margin: '20px 0 15px 0',
  },
}));
