import React, { FC } from 'react';
import { useStyles } from './SpheresView.styles';
import { Box, CardMedia } from '@mui/material';
import { PlayIcon } from '../../../common/assets/newDesign/VideoControlsIcons/PlayIcon';
import { SocialVerseAllApiModel, SocialVerseVideos } from '../../../api/models/socialVerse';
import { PictureIcon } from '../../../common/assets/newDesign/PictureIcon';
import { getVideoPoster } from '../../../services/utilities';

interface Props {
  socialverse: SocialVerseAllApiModel;
}

export const SpheresView: FC<Props> = ({ socialverse }) => {
  const classes = useStyles();
  const videosList = socialverse.topVideos.slice(0, 3);

  return (
    <Box className={classes.container}>
      {videosList.length ? (
        videosList.map((video, index) => <Sphere spheresPreviewVideo={video} index={index} />)
      ) : (
        <Sphere index={0} isEmpty />
      )}
    </Box>
  );
};

interface SphereProps {
  spheresPreviewVideo?: SocialVerseVideos;
  index: number;
  isEmpty?: boolean;
}

const Sphere: FC<SphereProps> = ({ spheresPreviewVideo, index, isEmpty }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.sphereWrapper}
      sx={{
        transform: `scale(${1 - index / 10})`,
        zIndex: -index,
        left: `${index * 15}px`,
        bottom: `${index * 15}px`,
      }}
    >
      <CardMedia
        className={classes.spherePreview}
        image={
          spheresPreviewVideo?.url
            ? getVideoPoster(spheresPreviewVideo.url)
            : spheresPreviewVideo?.thumbnailUrl || spheresPreviewVideo?.url
        }
      >
        {index === 0 && (
          <Box fontSize={45} className={classes.spheresPlayIcon}>
            <PlayIcon />
          </Box>
        )}
        {isEmpty && index === 0 && (
          <Box fontSize={45} className={classes.spheresPictureIcon}>
            <PictureIcon />
          </Box>
        )}
      </CardMedia>
    </Box>
  );
};
