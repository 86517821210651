import { FC, useState } from 'react';
import { Grid, Box } from '@mui/material';

import { ContentContainer } from './TableSection.style';
import { SearchBar } from '../../../../common/components/searchBar/SearchBar';
import { TeamTable } from './TeamTable/TeamTable';
import { AddUsersButton } from '../AddUsersButton/AddUsersButton';
import { getHeroes, updateSearch } from '../../../../store/slices/heroesSlice';
import { DropDownStatusFilter } from '../../../../common/components/DropDownStatusFilter/DropDownStatusFilter';
import { FilterStatuses } from '../../../../common/constants/constants';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { InviteAllButton } from '../InviteAllButton/InviteAllButton';
import { ClientTypes } from '../../../../api/models/common';

export const TableSection: FC = () => {
  const { page, size, sort, search = '' } = useTypedSelector((state) => state.heroes);
  const allStatuses = ['ALL', 'ACTIVE', 'INACTIVE', 'ARCHIVED'] as Array<FilterStatuses>;
  const { id: venueId, clientType } = useTypedSelector((state) => state.venue.venue);
  const [status, setStatus] = useState<string>(allStatuses[0]);
  const dispatch = useAppDispatch();

  const handleStatusUpdate = (value: string) => {
    if (value === FilterStatuses.all) {
      setStatus('');
      dispatch(getHeroes({ status: '', search, venueId, pageable: { page, size, sort } }));
    } else {
      setStatus(value);
      dispatch(getHeroes({ status: value, search, venueId, pageable: { page: 0, size, sort } }));
    }
  };

  return (
    <ContentContainer>
      <Grid container justifyContent="center" alignItems="baseline">
        <Grid item xs={clientType === ClientTypes.ETS ? 12 : 3}>
          <SearchBar entity="heroes" updateSearch={updateSearch} />
        </Grid>
        {clientType === ClientTypes.ETS ? (
          <></>
        ) : (
          <>
            <Grid item xs={3}>
              <Box ml="16px">
                <DropDownStatusFilter
                  handleStatusUpdate={handleStatusUpdate}
                  status={status}
                  statuses={allStatuses}
                />
              </Box>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <InviteAllButton />
            </Grid>
            <Grid item xs={2}>
              <AddUsersButton />
            </Grid>
          </>
        )}
      </Grid>
      <TeamTable status={status} />
    </ContentContainer>
  );
};
