import React, { FC, useRef, useState } from 'react';
import { useStyles } from './EditWelcomeVideo.styles';
import { CampaignsApiModel } from '../../../../../../api/models/campaigns';
import { VideoStyleTypes } from '../../../../../NewCampaign/VideoStyleTypesSwitch/VideoStyleTypesSwitch';
import { Box, Button, Typography } from '@mui/material';
import { Toggle } from '../../../../../../common/components/toggle/CustomToggle';
import { WelcomeVideoModalStrings } from '../../../../../../common/localization/en';
import WelcomeVideoIPhonePreview, {
  defaultVideos,
} from '../../../../../NewCampaign/WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import AddVideo from '../AddVideo/AddVideo';
import RecordVideo from '../RecordVideo/RecordVideo';
import {
  createStorageUploadUrl,
  uploadVideoAsBlob,
} from '../../../../../../store/slices/uploadVideoSlice';
import { MediaFilePrefix } from '../../../../../../api/models/common';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { getUpdatedVideoUrl } from '../../../../../../common/components/VideoUploader/VideoUplader.helpers';
import { LinearProgressWithLabel } from '../../../../../../common/components/LinearProgressBar/LinearProgress.helper';
import { downloadMedia, getProperCloudinaryUrl } from '../../../../../../services/utilities';
import { LoadingButton } from '@mui/lab';
import {
  getCampaignById,
  patchIncentiveCampaign,
} from '../../../../../../store/slices/campaignsSlice';
import CreateVideoPreview from '../../../../../../common/components/previews/CreateVideoPreview/CreateVideoPreview';
import { useVenue } from '../../../../../../hooks/useVenue';
import { useBackgroundImage } from '../../../../../../hooks/webApp/useBackgroundImage';
import { useThemeLayout } from '../../../../../../hooks/useThemeLayout';

export const checkIsWelcomeVideoDefault = (videoUrL: string | null) => {
  if (!videoUrL) return false;

  let isDefault = false;
  for (const video of Object.values(defaultVideos)) {
    if (video === videoUrL) {
      isDefault = true;
    }
  }

  return isDefault;
};

interface Props {
  currentCampaign: CampaignsApiModel;
  onCancel: () => void;
  onClose: () => void;
  selectedStyle: VideoStyleTypes;
  onSelectedStyleChange: (selectedStyle: VideoStyleTypes) => void;
}

const EditWelcomeVideo: FC<Props> = ({
  currentCampaign,
  onCancel,
  onClose,
  selectedStyle,
  onSelectedStyleChange,
}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const [isDisplayWelcomeVideo, setIsDisplayWelcomeVideo] = useState(
    currentCampaign.showWelcomeVideo,
  );
  const [welcomeVideoUrl, setWelcomeVideoUrl] = useState<string | null>(
    currentCampaign.welcomeVideoUrl || defaultVideos.default,
  );
  const [isRecordMode, setIsRecordMode] = useState(false);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState<string | null>(null);
  const [uploadingError, setUploadingError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { backgroundImage } = useBackgroundImage();

  const { theme } = useThemeLayout();

  const { venueProperties, logo } = useVenue();
  const brandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const handleToggleChange = () => {
    setIsDisplayWelcomeVideo(!isDisplayWelcomeVideo);
  };

  const handleVideoUrlChange = (url: string) => {
    setWelcomeVideoUrl(url);
  };

  const handleRemoveVideo = () => {
    setWelcomeVideoUrl(defaultVideos.default);
  };

  const handleRecordMode = () => {
    setIsRecordMode(!isRecordMode);
  };

  const handleCancel = () => {
    if (isRecordMode) {
      setIsRecordMode(false);
      setRecordedVideoBlob(null);
    } else {
      onCancel();
    }
  };

  const handleVideoRecorded = (blob: string) => {
    setRecordedVideoBlob(blob);

    submitVideo(blob);
  };

  const uploadAbortController = useRef(new AbortController());

  const onUploadProgress = (event: ProgressEvent) => {
    const percent = Math.round((event.loaded * 100) / event.total);
    setProgress(percent);
  };

  const submitVideo = async (recordedVideoBlob: string) => {
    if (!recordedVideoBlob) return;

    return new Promise(async (resolve, reject) => {
      try {
        uploadAbortController.current.signal.addEventListener('abort', (e) => {
          reject('upload aborted');
        });

        setProgress(0);
        setUploadingError(false);
        setIsUploading(true);

        const res = await createStorageUploadUrl({ ext: 'mp4', prefix: MediaFilePrefix.Welcome });
        if (!res) {
          return reject('createStorageUploadUrl failed');
        }

        const welcomeVideoUrl = res.downloadUrl;
        if (!welcomeVideoUrl) {
          return reject('no downloadUrl');
        }

        await dispatch(
          uploadVideoAsBlob({
            options: res,
            data: recordedVideoBlob,
            onUploadProgress,
          }),
        );

        resolve(welcomeVideoUrl);
      } catch (error) {
        reject(error);
      }
    })
      .then(async (welcomeVideoUrl) => {
        if (!welcomeVideoUrl || typeof welcomeVideoUrl !== 'string') return;

        const url = await getUpdatedVideoUrl(welcomeVideoUrl);
        setWelcomeVideoUrl(url);
        setIsRecordMode(false);
        setRecordedVideoBlob(null);
      })
      .catch((error) => {
        if (uploadAbortController.current.signal.aborted) {
          // reset abort controller
          uploadAbortController.current = new AbortController();
        } else {
          console.error(error);
          setUploadingError(true);
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const assets =
    process.env.REACT_APP_BUILD_ENV === 'production' ? ',l_sv-prod-assets' : ',l_sv-dev-assets';
  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);

  const downloadFile = () => {
    if (!welcomeVideoUrl) return;

    if (welcomeVideoUrl.includes('ar_9:16')) {
      downloadMedia(welcomeVideoUrl);
      setIsDownloading(true);
      setTimeout(() => {
        setIsDownloading(false);
      }, 1000);
    } else {
      setIsDownloading(true);
      downloadMedia(getProperCloudinaryUrl(welcomeVideoUrl, venueId, assets)).then(() => {
        setIsDownloading(false);
      });
    }
  };

  const isConfirmButtonDisabled = () => {
    const isMatchingShowWelcomeVideo = currentCampaign.showWelcomeVideo === isDisplayWelcomeVideo;

    return (
      (isMatchingShowWelcomeVideo && !currentCampaign.showWelcomeVideo) ||
      (isMatchingShowWelcomeVideo &&
        welcomeVideoUrl === currentCampaign.welcomeVideoUrl &&
        selectedStyle === currentCampaign.recommendedVideoStyle)
    );
  };

  const handleConfirm = () => {
    let urlToSave;
    if (!welcomeVideoUrl || !isDisplayWelcomeVideo || checkIsWelcomeVideoDefault(welcomeVideoUrl)) {
      urlToSave = defaultVideos.default;
    } else {
      urlToSave = welcomeVideoUrl;
    }

    setIsConfirmLoading(true);
    dispatch(
      patchIncentiveCampaign({
        campaignId: currentCampaign.id,
        values: {
          showWelcomeVideo: isDisplayWelcomeVideo,
          welcomeVideoUrl: urlToSave,
          recommendedVideoStyle: selectedStyle,
        },
      }),
    )
      .then(() => dispatch(getCampaignById(currentCampaign.id)))
      .then(() => {
        setIsConfirmLoading(false);
        onClose();
        if (!isDisplayWelcomeVideo) {
          onSelectedStyleChange(VideoStyleTypes.Inspirational);
        }
      });
  };

  const getVideoUrl = () => {
    if (welcomeVideoUrl && !checkIsWelcomeVideoDefault(welcomeVideoUrl)) {
      return welcomeVideoUrl!;
    } else {
      return defaultVideos.default;
    }
  };

  const videoUrl = getVideoUrl();

  return (
    <Box className={styles.container}>
      <Box className={styles.leftSideWrapper}>
        <Box className={styles.toggleWrapper}>
          <Toggle onChange={handleToggleChange} value={isDisplayWelcomeVideo} />
          <Typography className={styles.toggleLabel}>
            {WelcomeVideoModalStrings.DisplayWelcomeVideo}
          </Typography>
        </Box>
        <Typography className={styles.hint}>{WelcomeVideoModalStrings.Hint}</Typography>
        {isUploading && (
          <Box className={styles.progressWrapper}>
            <LinearProgressWithLabel value={progress} className={styles.progress} />
          </Box>
        )}
        <AddVideo
          welcomeVideoUrl={welcomeVideoUrl}
          onWelcomeVideoUploadComplete={handleVideoUrlChange}
          onRemove={handleRemoveVideo}
          isDisplayWelcomeVideo={isDisplayWelcomeVideo}
          onRecordClick={handleRecordMode}
          isRecordMode={isRecordMode}
        />
        {uploadingError && (
          <Typography className={styles.uploadingError}>
            {WelcomeVideoModalStrings.UploadingError}
          </Typography>
        )}

        <Box className={styles.buttonsWrapper}>
          <Button className={`${styles.button} ${styles.cancelButton}`} onClick={handleCancel}>
            {WelcomeVideoModalStrings.Cancel}
          </Button>
          <LoadingButton
            className={`${styles.button} ${styles.confirmButton}`}
            disabled={isConfirmButtonDisabled()}
            onClick={handleConfirm}
            loading={isConfirmLoading}
          >
            {WelcomeVideoModalStrings.ConfirmChanges}
          </LoadingButton>
        </Box>
      </Box>
      <Box className={styles.rightSideWrapper}>
        {isRecordMode ? (
          <RecordVideo onVideoRecorded={handleVideoRecorded} recordedVideo={recordedVideoBlob} />
        ) : (
          <Box className={styles.videoWrapper}>
            {isDisplayWelcomeVideo ? (
              <WelcomeVideoIPhonePreview videoUrl={videoUrl} />
            ) : (
              <Box>
                <CreateVideoPreview
                  brandColor={brandColor}
                  image={backgroundImage || ''}
                  logo={logo}
                  theme={theme}
                  isDisplayWelcomeVideo={isDisplayWelcomeVideo}
                />
              </Box>
            )}
          </Box>
        )}
        <LoadingButton
          className={`${styles.downloadButton} ${styles.button} ${styles.cancelButton} ${
            checkIsWelcomeVideoDefault(welcomeVideoUrl) && styles.hidden
          }`}
          disabled={!welcomeVideoUrl || checkIsWelcomeVideoDefault(welcomeVideoUrl)}
          onClick={downloadFile}
          loading={isDownloading}
        >
          {WelcomeVideoModalStrings.DownloadVideo}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default EditWelcomeVideo;
