import { FC } from 'react';

export const MenuIconSettingsActive: FC = () => (
  <svg width="56" height="47" viewBox="0 0 56 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50 0.5H6C2.96243 0.5 0.5 2.96243 0.5 6V41C0.5 44.0376 2.96243 46.5 6 46.5H50C53.0376 46.5 55.5 44.0376 55.5 41V6C55.5 2.96243 53.0376 0.5 50 0.5Z"
      fill="#5242EA"
      stroke="#5242EA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.9472 11.9453C32.765 12.1867 33.5467 12.5118 34.2824 12.9107L34.199 14.5356C34.1097 16.279 35.4548 17.7646 37.2036 17.8536C37.3113 17.8591 37.4193 17.8591 37.527 17.8536L39.1568 17.7705C39.5568 18.5039 39.8829 19.2833 40.125 20.0987L38.9128 21.1893C37.6132 22.3592 37.5108 24.358 38.6841 25.6538C38.7564 25.7336 38.8328 25.8097 38.9128 25.8818L40.125 26.9725C39.8829 27.7878 39.5568 28.5672 39.1568 29.3007L37.527 29.2175C35.7783 29.1285 34.2883 30.4697 34.199 32.2131C34.1935 32.3206 34.1935 32.4282 34.199 32.5356L34.2824 34.1605C33.5467 34.5594 32.765 34.8844 31.9472 35.1259L30.8533 33.9173C29.6799 32.6215 27.6751 32.5194 26.3754 33.6893C26.2954 33.7614 26.219 33.8375 26.1467 33.9173L25.0528 35.1259C24.235 34.8844 23.4533 34.5594 22.7176 34.1605L22.801 32.5356C22.8903 30.7921 21.5452 29.3066 19.7965 29.2175C19.6887 29.212 19.5808 29.212 19.473 29.2175L17.8432 29.3007C17.4432 28.5672 17.1171 27.7878 16.875 26.9725L18.0872 25.8818C19.3868 24.712 19.4892 22.7132 18.3159 21.4174C18.2436 21.3375 18.1672 21.2614 18.0872 21.1893L16.875 20.0987C17.1171 19.2833 17.4432 18.5039 17.8432 17.7705L19.473 17.8536C21.2217 17.9427 22.7117 16.6015 22.801 14.858C22.8065 14.7506 22.8065 14.643 22.801 14.5356L22.7176 12.9107C23.4533 12.5118 24.235 12.1867 25.0528 11.9453L26.1467 13.1539C27.3201 14.4497 29.3249 14.5517 30.6246 13.3819C30.7046 13.3098 30.781 13.2337 30.8533 13.1539L31.9472 11.9453Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5002 27.7495C30.8348 27.7495 32.7274 25.8626 32.7274 23.5349C32.7274 21.2072 30.8348 19.3203 28.5002 19.3203C26.1655 19.3203 24.2729 21.2072 24.2729 23.5349C24.2729 25.8626 26.1655 27.7495 28.5002 27.7495Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
