import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
    <mask
      id="mask0_2083_97109"
      width={60}
      height={60}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <circle cx={30} cy={30} r={30} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask0_2083_97109)">
      <circle
        cx={30}
        cy={30}
        r={29.2}
        fill="#F3F2FD"
        stroke="#D9D4FF"
        strokeWidth={1.6}
        opacity={0.8}
      />
      <circle
        cx={29.765}
        cy={29.929}
        r={20.354}
        fill="#8F81FF"
        fillOpacity={0.3}
        stroke="#A69DF7"
        strokeWidth={1.6}
        opacity={0.7}
        transform="rotate(-.122 29.765 29.93)"
      />
      <path
        fill="#fff"
        stroke="#8B89A0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="m22.306 29.764 3.343 2.35V18.668a1.922 1.922 0 1 1 3.843 0v6.04a1.88 1.88 0 1 1 3.759 0v.541a1.831 1.831 0 1 1 3.662 0v.846a1.703 1.703 0 0 1 3.405 0v8.709a8.927 8.927 0 0 1-16.6 4.56l-4.18-7.036a1.93 1.93 0 0 1 2.768-2.564Z"
      />
    </g>
  </svg>
);
const VideoTypeFriendsAndFamilyIcon = memo(SvgComponent);
export default VideoTypeFriendsAndFamilyIcon;
