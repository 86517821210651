import { FC, useMemo } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { getRoundedValue, insertAt } from '../../../services/utilities';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { createVideoData } from './VideosDetailVideoData.helper';
import { useVideosDetailVideoDataStyles } from './VideosDetailVideoData.style';
import { useTypedSelector } from '../../../store';
import { ClientTypes } from '../../../api/models/common';
interface IVideosDetailVideoDataProps {
  views: number;
  ctaClicks: number;
  convertedReferrals: number;
}

export const VideosDetailVideoData: FC<IVideosDetailVideoDataProps> = ({
  views,
  ctaClicks,
  convertedReferrals,
}) => {
  const conversionRate = useMemo(
    () => insertAt(getRoundedValue(!views ? 0 : (ctaClicks / views) * 100) || 0, '%', 'end'),
    [views, ctaClicks],
  );
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const isTopgolf = clientType === ClientTypes.TOPGOLF;
  const videoData = createVideoData(views, ctaClicks, conversionRate, convertedReferrals);
  const classes = useVideosDetailVideoDataStyles();

  return (
    <Box className={classes.VideoDataContainer}>
      <Typography className={classes.VideoDataTitle}>
        {VideoDetailsModalStringsDv5.VideoData}
      </Typography>
      <Grid container spacing={2} sx={{ mb: '20px', ml: '0px', mt: '0px' }}>
        {videoData.map((share) => {
          if (isTopgolf && share.label === VideoDetailsModalStringsDv5.Conversions) {
            return null;
          }
          return (
            <Box key={share.label} className={classes.ShareIconContainer}>
              <Box className={classes.IconVideoDataContainer}>{share.icon}</Box>
              <Box className={classes.ShareInfoContainer}>
                <Typography className={classes.ShareInfoText}>{share.label}</Typography>
                <Typography className={classes.ShareInfoValue}>{share.value}</Typography>
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Box>
  );
};
