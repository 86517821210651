export const InvitesDirectionDownArrow = () => {
  return (
    <svg
      width="21"
      height="229"
      viewBox="0 0 21 229"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 218.418L10.5 226.418L2.5 218.418"
        stroke="#BDBDBD"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 224C8.5 225.105 9.39543 226 10.5 226C11.6046 226 12.5 225.105 12.5 224H8.5ZM12.5 2C12.5 0.89543 11.6046 0 10.5 0C9.39543 0 8.5 0.89543 8.5 2H12.5ZM12.5 224V2H8.5V224H12.5Z"
        fill="#BDBDBD"
      />
    </svg>
  );
};
