import { ChangeEvent as ReactChangeEvent, FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './VideoDownloadDialog.helper';
import { CommonStrings, VideoDetailsModalStringsDv5 } from '../../../../localization/en';
import { VideoApiModel } from '../../../../../api/models/videos';
import { downloadMedia, getProperCloudinaryUrl } from '../../../../../services/utilities';
import { VideoDownloadCard } from '../components/VideoDownloadCard/VideoDownloadCard';
import { TopGolfVideosApiModel } from '../../../../../api/models/topgolfVideos';
import { useTypedSelector } from '../../../../../store';
import { ClientTypes } from '../../../../../api/models/common';
import { VideoDownloadSpinner } from '../../../../assets/VideoDownloadSpinner';
import { useToasts } from 'react-toast-notifications';
import { SocialVerseEventNames } from '../../../../constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';

interface VideoDownloadDialogProps {
  video: VideoApiModel | TopGolfVideosApiModel;
  open: boolean;
  onClose: () => void;
}

enum VideoTypes {
  WithFilter = 'withFilter',
  WithoutFilter = 'withoutFilter',
}

export const VideoDownloadDialog: FC<VideoDownloadDialogProps> = ({ onClose, open, video }) => {
  const classes = useStyles();
  const [type, setType] = useState<VideoTypes>(VideoTypes.WithFilter);
  const { trackEvent } = useTrackEvent();

  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const { addToast } = useToasts();
  const [noFilterVideoUrl, setNoFilterVideoUrl] = useState<string>('');
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const isTopgolf = clientType === ClientTypes.TOPGOLF;
  const assets =
    process.env.REACT_APP_BUILD_ENV === 'production' ? ',l_sv-prod-assets' : ',l_sv-dev-assets';

  useEffect(() => {
    const parts = video.url.split(assets);
    const startPart = parts[0];
    const endPart = parts[1]?.substring(parts[1].indexOf('/v1/')) || '';
    setNoFilterVideoUrl(startPart + endPart);
  }, [video.url, assets]);

  // useEffect(() => {
  //   if (story.url) {
  //     const [first, second] = story.url.split('/v1/');
  //     setVideoUrl(`${first},eo_0.01/v1/${second}`);
  //   }
  // }, [story.url, setVideoUrl]);
  const handleClose = () => {
    onClose();
    trackEvent(SocialVerseEventNames.downloadVideoCancel, { videoId: video.id });
  };

  const handleVideoTypeChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    setType(event.target.value as VideoTypes);
    trackEvent(SocialVerseEventNames.changeDownloadVideType, {
      videoId: video.id,
      downloadVideoType: event.target.value,
    });
  };

  const downloadFile = () => {
    if (video.url && noFilterVideoUrl) {
      trackEvent(SocialVerseEventNames.downloadVideoConfirm, {
        videoId: video.id,
        downloadVideoType: type,
      });

      if (video.url.includes('ar_9:16')) {
        downloadMedia(type === VideoTypes.WithFilter ? video.url : noFilterVideoUrl);
        setShowSpinner(true);
        setTimeout(() => {
          setShowSpinner(false);
          onClose();
          addToast(VideoDetailsModalStringsDv5.DownloadVideo, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        }, 2000);
      } else {
        // const filterId = venueProperties?.['webapp.config']['filter-url']
        //   ? venueProperties?.['webapp.config']['filter-url'].split('/').reverse()[0].split('.')[0]
        //   : '';
        downloadMedia(
          type === VideoTypes.WithFilter
            ? video.url
            : getProperCloudinaryUrl(video.url, venueId, assets),
        ).then(() => {
          onClose();
          addToast(VideoDetailsModalStringsDv5.DownloadVideo, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        });
      }
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle className={classes.title} id="scroll-dialog-title">
        <Box className={classes.titleText}>Want to download video?</Box>
        <IconButton
          className={classes.close}
          type="button"
          aria-label="Close modal"
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <RadioGroup
          className={classes.group}
          onChange={handleVideoTypeChange}
          role="group"
          aria-labelledby="video-types-group"
        >
          <Box className={classes.label}>
            <VideoDownloadCard url={video.url} />

            <FormControlLabel
              name="video-type"
              value={VideoTypes.WithFilter}
              checked={type === VideoTypes.WithFilter}
              control={<Radio />}
              label={!isTopgolf ? 'With Branding' : 'Processed video'}
            />
          </Box>
          {!isTopgolf && (
            <Box className={classes.label}>
              <VideoDownloadCard url={noFilterVideoUrl} />

              <FormControlLabel
                name="video-type"
                control={<Radio />}
                value={VideoTypes.WithoutFilter}
                checked={type === VideoTypes.WithoutFilter}
                label="Without Branding"
              />
            </Box>
          )}
        </RadioGroup>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary">
          {CommonStrings.Cancel}
        </Button>
        <Button onClick={downloadFile} color="primary" variant="contained">
          {showSpinner ? (
            <VideoDownloadSpinner width="25px" height="25px" viewBoxHeight="70" viewBoxWidth="25" />
          ) : (
            'Download'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
