import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideosDetailHeaderToolbarStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'sticky',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '30px',
    paddingLeft: '24px',
    paddingRight: '30px',
    backgroundColor: theme.palette.common.white,
    zIndex: 1205,
  },
  containerCloseIcon: {
    color: theme.palette.common.black,
  },
  Title: {
    fontSize: '20px',
    fontWeight: 500,
    marginRight: 'auto',
    letterSpacing: '0.02em',
  },
}));
