import { FC, MouseEvent, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { ClientTypes } from '../../api/models/common';
import { VideoApiModel } from '../../api/models/videos';
import { NewDetailsModal } from '../../common/components/NewDetailsModal/NewDetailsModal';
import { TopbarSelectedFilter } from '../../common/components/TopbarSelectedFilter';
import { VideoStatus } from '../../common/constants/constants';
import { WebsiteRoutes } from '../../common/constants/routes';
import {
  CommonStrings,
  ContentSubnavigationStrings,
  SidebarStrings,
} from '../../common/localization/en';
import { useSegmentPageCall } from '../../hooks/useSegment';
import { useAppDispatch, useTypedSelector } from '../../store';
import { setActiveFilter } from '../../store/slices/searchFiltersSlice';
import { updateVideoById } from '../../store/slices/videosSlice';
import { updateTopgolfVideoById } from '../../store/slices/topGolfVideosSlice';
import { ConfirmationModal } from './ConfirmationModel/confirmationModel';
import { ContentContainer } from './Content.style';
import { DeleteModal } from './DeleteModal/DeleteModal';
import { TableSection } from './tableSection/TableSection';
import { WidgetTagCreationModal } from './WidgetTagCreationModal/WidgetTagCreationModal';
import { VideosEventNames } from '../../common/constants/events/videosEvents';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { topGolfEvents } from '../../common/constants/events/topGolfEvents';

export const Content: FC = () => {
  const { pathname } = useLocation();
  const match = useRouteMatch();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);

  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
  const dispatch = useAppDispatch();
  const stories = useTypedSelector((state) => state.videos.items);
  const topgolfVideos = useTypedSelector((state) => state.TopgGolfVideos.items);
  const [story] = isNotTopgolf
    ? stories.filter((item) => item.id === params.id)
    : topgolfVideos.filter((item) => item.id === params.id);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAddNewTagModalOpen, setisAddNewTagModalOpen] = useState(false);
  const [storyId, setStoryId] = useState('');
  const [archived, setArchived] = useState(false);
  const [storyUpdate] = isNotTopgolf
    ? stories.filter((item) => item.id === storyId)
    : topgolfVideos.filter((item) => item.id === storyId);
  const { trackEvent } = useTrackEvent();

  const isDetailsModalOpen = useMemo(() => {
    return (
      !!params.id &&
      !VideoStatus.includes(params.id) &&
      params.id !== ContentSubnavigationStrings.Archived.toLowerCase()
    );
  }, [params]);

  const [id, setId] = useState('');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const detailsModal = useRef(null);

  useEffect(() => {
    trackEvent(VideosEventNames.videosPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSegmentPageCall();

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    if (!isNotTopgolf)
      trackEvent(topGolfEvents.top_golf_video_slide_out_archive_modal_close, { videoId: story.id });
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    if (!isNotTopgolf)
      trackEvent(topGolfEvents.top_golf_video_slide_out_feature_modal_close, { videoId: story.id });
  };
  const handleCloseAddnewTagModal = () => {
    setisAddNewTagModalOpen(false);
  };

  const close = () => {
    history.push(match.path.replace('/:id', ''));
  };

  const handleOpenDeleteModal = (id: string, deleted: boolean) => {
    setIsDeleteModalOpen(true);
    setStoryId(id);
    setArchived(deleted);
  };

  const handleOpenConfirmationModal = (id: string) => {
    setIsConfirmationModalOpen(true);
    setStoryId(id);
    if (!isNotTopgolf)
      trackEvent(topGolfEvents.top_golf_video_slide_out_feature_modal_open, { videoId: story.id });
  };

  const handleOpenAddNewTagModal = (id: string) => {
    setisAddNewTagModalOpen(true);
    setStoryId(id);
  };

  const handleConfirm = () => {
    if (storyId) {
      if (isNotTopgolf) {
        dispatch(updateVideoById({ id: storyId, update: { featured: !storyUpdate.featured } }));
      } else {
        dispatch(
          updateTopgolfVideoById({ id: storyId, update: { featured: !storyUpdate.featured } }),
        );
        trackEvent(topGolfEvents.top_golf_video_slide_out_feature_video, { videoId: story.id });
      }
      handleCloseConfirmationModal();
    }
  };

  const handleDelete = () => {
    if (storyId) {
      if (isNotTopgolf) {
        dispatch(updateVideoById({ id: storyId, update: { deleted: !archived } }));
      } else {
        dispatch(
          updateTopgolfVideoById({
            id: storyId,
            update: { status: storyUpdate.status === 'ARCHIVED' ? 'PUBLISHED' : 'ARCHIVED' },
          }),
        );
        trackEvent(topGolfEvents.top_golf_video_slide_out_archive_video_status_changed, {
          videoId: story.id,
          value: storyUpdate.status === 'ARCHIVED' ? 'PUBLISHED' : 'ARCHIVED',
        });
      }

      handleCloseDeleteModal();
    }
    if (isDetailsModalOpen) {
      close();
    }
  };

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setId(id !== event.currentTarget?.id ? event.currentTarget.id : '');
  };

  const handleClearFilter = () => {
    trackEvent(VideosEventNames.clearFilter);
    dispatch(setActiveFilter(null));
  };

  const getCurrentStatus = () => VideoStatus.find((status) => pathname.split('/')[2] === status);
  const archivedFilter = pathname.includes(ContentSubnavigationStrings.Archived.toLowerCase());
  const statusFilter = getCurrentStatus();
  const isAddVideoPage = history.location.pathname.includes(WebsiteRoutes.SocialVerse);

  return (
    <ContentContainer>
      <WidgetTagCreationModal
        handleCloseModal={handleCloseAddnewTagModal}
        onConfirm={() => {
          // tags were removed
        }}
        isModalOpen={isAddNewTagModalOpen}
      />
      <ConfirmationModal
        handleCloseModal={handleCloseConfirmationModal}
        onConfirm={handleConfirm}
        isModalOpen={isConfirmationModalOpen}
        message={
          storyUpdate?.featured
            ? CommonStrings.RemoveFromWidgetConfirmation
            : CommonStrings.AddToWidgetConfirmation
        }
      />
      <DeleteModal
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDelete={handleDelete}
        isDeleteModalOpen={isDeleteModalOpen}
        archived={archived}
      />

      <TopbarSelectedFilter
        anchorEl={anchorEl}
        handleSetAnchorEl={() => setAnchorEl(null)}
        handleSetId={() => setId('')}
        handleClick={handleClick}
        handleClearFilter={handleClearFilter}
        sectionTitle={SidebarStrings.Content}
        multiline={false}
      />

      <TableSection
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        handleOpenAddNewTagModal={handleOpenAddNewTagModal}
        statusFilter={statusFilter}
        archivedFilter={archivedFilter}
        showActionBar={activeFilter ? true : false}
        isSocialVerseAddVideo={isAddVideoPage}
        onCreateFilterClick={() => {
          trackEvent(VideosEventNames.createFilterButtonClicked);
        }}
        onSavedFiltersClick={() => {
          trackEvent(VideosEventNames.savedFiltersButtonClicked);
        }}
      />
      <div ref={detailsModal}>
        <NewDetailsModal
          userId={isNotTopgolf ? (story as VideoApiModel)?.userId || '' : ''}
          isOpen={isDetailsModalOpen}
          videoId={params.id}
          handleClose={close}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
        />
      </div>
    </ContentContainer>
  );
};
