import React, { FC, useEffect, useMemo, useState } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';

import { WebsiteRoutes } from '../../common/constants/routes';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  getGeneralKPIs,
  getKPIById,
  setActiveAnalyticsBlock,
  setActiveSubAnalyticsBlock,
  setKpiValues,
  reset,
  getTopGolfGeneralKPIs,
  getKPIByIdShare,
  getShareSummary,
} from '../../store/slices/analyticsSlice';
import { KPIApiNames } from '../../api/models/analytics';
import { TopBar } from '../../common/components/topBar/TopBar';
import { AnalyticsSection } from './AnalyticsSection/AnalyticsSection';
import {
  getAnalyticsData,
  webInitialAnalyticsData,
  webInitialAnalyticsSalesData,
  webInitialAnalyticsTopGolfData,
  widgetInitialAnalyticsData,
} from './AnalyticsSection/AnalyticsSection.helper';
import { ChartSection } from './ChartSection/ChartSection';
import { PlatformType, subnavigationItems, topGolfsubnavigationItems } from './Dashboard.helper';
// import { BodyContentContainer } from '../../common/styles/commonStyles.style';
import { ConversionLabel, DashboardContainer } from './Dashboard.style';
import { useSegmentPageCall } from '../../hooks/useSegment';
import { segmentActions } from '../../common/constants/constants';
import { segmentTrackCall } from '../../utils/segment';
import { ClientTypes } from '../../api/models/common';
import { DashboardStrings } from '../../common/localization/en';
import { SharesSubAnalyticsNavigation } from './SharesSubAnalyticsNavigation/SharesSubAnalyticsNavigation';
import { NumberWithOneDecimal, stringToNumber } from '../../services/utilities';
import {
  SharesAnalyticsBlockData,
  SharesAnalyticsBlockModel,
} from './SharesSubAnalyticsNavigation/SharesAnalytics.helper';
import { TopbarGraph } from '../../common/components/TopbarGraph/TopbarGraph';
import { useStyles } from './Dashboard.helper';
import { WebappSubnavigation } from '../../common/components/webappSubnavigation/WebappSubnavigation';
import { NoResultsContent } from './ChartSection/NoResultsContent';
import { useVenueProperty } from '../../hooks/useVenueProperty';
import { VenueProperties } from '../../api/models/venue';

import { useIsFteFinished } from '../../hooks/useIsFteFinished';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { PerfomanceEvents } from '../../common/constants/events/perfomanceEvents';
import { useCreateDefaultCampaign } from '../signUp/useCreateDefaultCampaign';

export const Dashboard: FC = () => {
  const { pathname } = useLocation();
  const isWidget = Boolean(useRouteMatch(WebsiteRoutes.DashboardWidget));
  const isHeroes = Boolean(useRouteMatch(WebsiteRoutes.DashboardHeroes));
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { lastUpdated, kpis, activeAnalyticsBlock, kpiValues, isLoading, rangeFilter } =
    useTypedSelector((state) => state.analytics);
  const { id: venueId, clientType } = useTypedSelector((state) => state.venue.venue);
  const [isChart, setIsChart] = useState(false);
  const [platform, setPlatform] = useState<PlatformType>('');
  useSegmentPageCall();
  const isTopgolfVenue = clientType === ClientTypes.TOPGOLF;
  const widgetAnalyticsData = getAnalyticsData(kpis, widgetInitialAnalyticsData);
  const webAnalyticsData = getAnalyticsData(kpis, webInitialAnalyticsData);
  const TGAnalyticsData = getAnalyticsData(kpis, webInitialAnalyticsTopGolfData);
  const TGSharesAnalyticsData = getAnalyticsData(
    kpis,
    SharesAnalyticsBlockData,
  ) as SharesAnalyticsBlockModel[];

  const { trackEvent } = useTrackEvent();

  const { value: FTEValues, isLoading: isFTELoading } = useVenueProperty<
    VenueProperties['webapp.firstTimeOnboardingFinished.config']
  >({
    property: 'webapp.firstTimeOnboardingFinished.config',
  });

  const { value: wasCongratsModalSeen, isLoading: wasCongratsModalSeenLoading } = useVenueProperty<
    VenueProperties['dashboard.wasCongratsModalSeen']
  >({
    property: 'dashboard.wasCongratsModalSeen',
  });

  const { value: quickstartConfig, isLoading: isQuickstartConfigLoading } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  const showFTE = FTEValues ? !FTEValues?.webapp_modal?.modal : !isFTELoading;

  const { createDefaultCampaign } = useCreateDefaultCampaign();

  useEffect(() => {
    if (!venueId || isQuickstartConfigLoading) return;

    if ((quickstartConfig === undefined && showFTE) || quickstartConfig?.isQuickStartInProgress) {
      createDefaultCampaign(venueId).then(() => {
        history.push(WebsiteRoutes.QuickStart);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, showFTE, quickstartConfig, venueId, isQuickstartConfigLoading]);

  useEffect(() => {
    if (activeAnalyticsBlock.main !== KPIApiNames.shares) {
      setPlatform('');
    }
  }, [activeAnalyticsBlock]);

  const getWidgetConversionRateValue = () => {
    const ctaClicksName = isHeroes ? 'CTAClicks' : 'WidgetCtaClicks';
    const totalViewsName = isHeroes ? 'VideoViews' : 'WidgetViews';
    const analyticsData = isHeroes ? webAnalyticsData : widgetAnalyticsData;
    const ctaClicksValue = Number(
      analyticsData.find((item) => item.kpiName === KPIApiNames[ctaClicksName])?.value,
    );
    const viewsValue = Number(
      analyticsData.find((item) => item.kpiName === KPIApiNames[totalViewsName])?.value,
    );
    if (!ctaClicksValue || !viewsValue) {
      return 0;
    }
    return `${Math.round((ctaClicksValue / viewsValue) * 100)}%`;
  };
  const renderConversionRate = () => {
    return (
      <ConversionLabel>
        {DashboardStrings.ConversionRate} <b>{getWidgetConversionRateValue()}</b>
      </ConversionLabel>
    );
  };
  const renderTopGolfConversionRate = useMemo(() => {
    let value;
    let label;
    const visitors = stringToNumber(
      TGAnalyticsData.find((item) => item.kpiName === KPIApiNames.visitors)?.value,
    );
    const users = stringToNumber(
      TGAnalyticsData.find((item) => item.kpiName === KPIApiNames.users)?.value,
    );
    const shares = stringToNumber(
      TGAnalyticsData.find((item) => item.kpiName === KPIApiNames.shares)?.value,
    );
    const views = stringToNumber(
      TGAnalyticsData.find((item) => item.kpiName === KPIApiNames.videoViews)?.value,
    );
    const ctaClicks = stringToNumber(
      TGAnalyticsData.find((item) => item.kpiName === KPIApiNames.ctaClicks)?.value,
    );
    const platformConversionRate = {
      FACEBOOK: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.FACEBOOK)?.value,
      ),
      TWITTER: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.TWITTER)?.value,
      ),
      DOWNLOAD: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.DOWNLOAD)?.value,
      ),
      LINK: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.LINK)?.value,
      ),
      SMS: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.SMS)?.value,
      ),
      EMAIL: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.EMAIL)?.value,
      ),
      TIKTOK: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.TIKTOK)?.value,
      ),
      REELS: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.REELS)?.value,
      ),
      REDDIT: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.REDDIT)?.value,
      ),
      LINKEDIN: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.LINKEDIN)?.value,
      ),
      INSTAGRAM: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.INSTAGRAM)?.value,
      ),
      PINTEREST: stringToNumber(
        TGSharesAnalyticsData.find((item) => item.kpiName === KPIApiNames.PINTEREST)?.value,
      ),
    };

    if (!platform) {
      if (activeAnalyticsBlock.main === KPIApiNames.users) {
        label = DashboardStrings.ConversionRate;
        const tempValue = users / visitors;
        value = visitors ? `${NumberWithOneDecimal(tempValue * 100)}%` : '0%';
      }
      if (activeAnalyticsBlock.main === KPIApiNames.shares) {
        label = DashboardStrings.SharesPerUser;
        const tempValue = shares / users;
        value = users ? `${NumberWithOneDecimal(tempValue * 100)}%` : '0%';
      }
      if (activeAnalyticsBlock.main === KPIApiNames.videoViews) {
        label = DashboardStrings.ViewsPerShareAvg;
        const tempValue = views / shares;
        value = shares ? `${NumberWithOneDecimal(tempValue)}` : '0';
      }
      if (activeAnalyticsBlock.main === KPIApiNames.ctaClicks) {
        label = DashboardStrings.ConversionRate;
        const tempValue = ctaClicks / views;
        value = views ? `${NumberWithOneDecimal(tempValue * 100)}%` : '0%';
      }
    } else {
      label = DashboardStrings.SharePercentage;
      value =
        shares && platformConversionRate[platform]
          ? `${NumberWithOneDecimal((platformConversionRate[platform] / shares) * 100)}%`
          : '0%';
    }

    if (!label) {
      return null;
    }
    return (
      <ConversionLabel>
        {label} <b>{value}</b>
      </ConversionLabel>
    );
  }, [platform, TGAnalyticsData, TGSharesAnalyticsData, activeAnalyticsBlock]);

  useEffect(() => {
    if (pathname) {
      const [defaultBlock] = isWidget
        ? widgetInitialAnalyticsData
        : clientType === ClientTypes.SALES
        ? webInitialAnalyticsSalesData
        : isTopgolfVenue
        ? webInitialAnalyticsTopGolfData
        : webInitialAnalyticsData;

      dispatch(setKpiValues([]));
      dispatch(
        setActiveAnalyticsBlock({
          kpiName: defaultBlock?.kpiName as KPIApiNames,
          name: defaultBlock?.name ?? '',
        }),
      );
    }
  }, [dispatch, isWidget, pathname, clientType, isTopgolfVenue]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (venueId) {
      const requestParams = rangeFilter
        ? { venueId, from: rangeFilter.from, to: rangeFilter.to }
        : { venueId };
      if (isTopgolfVenue) {
        dispatch(getTopGolfGeneralKPIs(requestParams));
        dispatch(getShareSummary(requestParams));
      } else {
        dispatch(getGeneralKPIs(requestParams));
        dispatch(getShareSummary(requestParams));
      }
    }
  }, [dispatch, lastUpdated, venueId, rangeFilter, isTopgolfVenue]);

  useEffect(() => {
    const kpi = activeAnalyticsBlock.sub || activeAnalyticsBlock.main;

    if (venueId && kpi) {
      const requestParams = rangeFilter
        ? { venueId, kpi, from: rangeFilter.from, to: rangeFilter.to }
        : { venueId, kpi };
      if (kpi === KPIApiNames.shares) {
        dispatch(getKPIByIdShare(platform ? { ...requestParams, platform } : requestParams));
      } else {
        dispatch(getKPIById(requestParams));
      }
    }
  }, [
    activeAnalyticsBlock.main,
    activeAnalyticsBlock.sub,
    dispatch,
    venueId,
    rangeFilter,
    platform,
  ]);

  const onClickAnalyticsBlockHandler = (kpiName: KPIApiNames, name: string) => {
    segmentTrackCall(`${segmentActions.Analytics}_${kpiName.toLowerCase()}`, venueId);
    dispatch(setActiveAnalyticsBlock({ kpiName, name }));
    setIsChart(true);
  };

  const onClickChartAnalyticsBlockHandler = (kpiName: KPIApiNames) => {
    let activeKPIName = null;
    if (kpiName !== activeAnalyticsBlock.sub) {
      activeKPIName = kpiName;
    }
    segmentTrackCall(`${segmentActions.Analytics}_${kpiName.toLowerCase()}`, venueId);
    dispatch(setActiveSubAnalyticsBlock({ kpiName: activeKPIName }));
    setIsChart(true);
  };

  const onCloseChartModalHandler = () => {
    setIsChart(false);
  };
  const navigationItems = isTopgolfVenue ? topGolfsubnavigationItems : subnavigationItems;

  const isChartData = () => {
    const iskpiValues = !!kpiValues.length;
    const isNoValuseData = kpiValues && kpiValues.every((kpi) => kpi.value === 0);
    const result = !isLoading && iskpiValues && !isNoValuseData;
    return result;
  };

  const FTEIsFinished = useIsFteFinished();
  const finishedFTEAndSelectedAdvancedSetup =
    FTEIsFinished || (!!quickstartConfig?.visitedRoutes && !!quickstartConfig.visitedRoutes.length);

  const isCongradsModalOpen = useMemo(() => {
    return (
      !wasCongratsModalSeen && !wasCongratsModalSeenLoading && finishedFTEAndSelectedAdvancedSetup
    );
  }, [finishedFTEAndSelectedAdvancedSetup, wasCongratsModalSeen, wasCongratsModalSeenLoading]);

  const [, setInvitesModalDisabled] = useState(false);

  useEffect(() => {
    if (isCongradsModalOpen) {
      setInvitesModalDisabled(true);
    }
  }, [isCongradsModalOpen]);

  useEffect(() => {
    trackEvent(PerfomanceEvents.Perfomance_Page_Load);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardContainer>
      <TopBar>
        <WebappSubnavigation items={navigationItems} />
      </TopBar>

      <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '30px' }}>
        <AnalyticsSection
          isWidget={isWidget}
          isHeroes={isHeroes}
          onClick={onClickAnalyticsBlockHandler}
          kpis={kpis}
          activeAnalyticsBlock={activeAnalyticsBlock}
        />
      </div>

      <div className={classes.chartWrapper}>
        <TopbarGraph
          activeAnalyticsBlock={activeAnalyticsBlock}
          dropdownComponent={
            <SharesSubAnalyticsNavigation setPlatform={setPlatform} platform={platform} />
          }
        />

        <ChartSection
          kpis={kpis}
          chartData={kpiValues}
          isChart={isChart}
          isLoading={isLoading}
          onClose={onCloseChartModalHandler}
          onClickChartAnalyticsBlock={onClickChartAnalyticsBlockHandler}
          activeAnalyticsBlock={activeAnalyticsBlock}
        >
          {isChartData() ? null : <NoResultsContent />}
          {isTopgolfVenue ? renderTopGolfConversionRate : renderConversionRate()}
          {/* {isTopgolfVenue ? renderTopGolfConversionRate : renderTopbarGraphic()} */}
        </ChartSection>
      </div>
    </DashboardContainer>
  );
};
