import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './Distributors.style';
import { DistributorsStrings } from '../../localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  clearGbpAuthUrl,
  confirmLocation,
  disconnectAccount,
  GbpAccount,
  GbpLocation,
  getConnectedAccount,
  getGbpAccounts,
  getGbpAuthorizeUrl,
  getGbpLocations,
  googleOauth2,
} from '../../../store/slices/gbpSlice';
import { getSimilarityScore, redirectToPage } from '../../../services/utilities';
import { useQuery } from '../../../hooks/useQuery';
import { Link, useHistory } from 'react-router-dom';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../api/models/venue';
import { SyncedBusiness } from './SyncedBusiness/SyncedBusiness';
import { WebsiteRoutes } from '../../constants/routes';
import { LinkIcon } from '../../assets/LinkIcon';
import { getAllSocialVerse, putEditSocialVerse } from '../../../store/slices/socialVerseSlice';
import { useModal } from '../../../hooks/useModal';
import { ConfirmSyncModal } from './ConfirmSyncModal/ConfirmSyncModal';
import { ConsentModal } from './ConsentModal/ConsentModal';
import { GoogleIcon } from '../../assets/GoogleIcon';
import { setSelectedVenue } from '../../../store/slices/venueSlice';

const CONSENT_TIMEOUT = 2500;

export const Distributors: FC = () => {
  const classes = useStyles();

  const { value: companyAddress } = useVenueProperty<VenueProperties['company-address']>({
    property: 'company-address',
  });

  const history = useHistory();
  const query = useQuery();
  const state = query.get('state');
  const code = query.get('code');

  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { authUrl, isLoading, isConfirmLocationError, connectedAccount } = useTypedSelector(
    (state) => state.gpbSlice,
  );

  useEffect(() => {
    if (state && state !== venueId) {
      dispatch(setSelectedVenue({ id: state }));
    }
  }, [state, venueId, dispatch]);

  const { socialVerseAll } = useTypedSelector((state) => state.SocialVerseSlice);

  const liveSocialVerses = useMemo(() => {
    return socialVerseAll?.filter((sv) => sv.lastSeenLocationUrl && sv.numVideos > 0) || [];
  }, [socialVerseAll]);

  const liveVideosCount = useMemo(() => {
    return liveSocialVerses.reduce((acc, curr) => {
      return acc + curr.numVideos;
    }, 0);
  }, [liveSocialVerses]);

  const consentTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [isSignInError, setIsSignInError] = useState(false);
  const [isGoogleBtnActive, setIsGoogleBtnActive] = useState(false);
  const {
    isOpen: isConfirmSyncModalOpen,
    open: openConfirmSyncModal,
    close: closeConfirmSyncMoldal,
  } = useModal();

  const {
    isOpen: isConsentModalOpen,
    open: openConsentModal,
    close: closeConsentModal,
  } = useModal();

  const handleSignIn = () => {
    localStorage.setItem('isGbpSyncStarted', String(true));
    dispatch(getGbpAuthorizeUrl({ venueId }));
  };

  useEffect(() => {
    if (authUrl) {
      redirectToPage(authUrl);
      dispatch(clearGbpAuthUrl());
    }
  }, [authUrl, dispatch]);

  useEffect(() => {
    if (venueId) {
      dispatch(getAllSocialVerse({ venueId: venueId, withVideos: true }));
    }
  }, [dispatch, venueId]);

  const getGbpLocation = (locations: GbpLocation[], zip: string, address: string) => {
    const MIN_SIMILARITY_SCORE = 65; //percents

    return locations.find((loc) => {
      return (
        loc.storefrontAddress.postalCode === zip &&
        loc.storefrontAddress.addressLines.find((line) => {
          const lineDigits = line.replace(/\D/g, '');
          const addressDigits = address.replace(/\D/g, '');
          const similarityScore = getSimilarityScore(line.toLowerCase(), address.toLowerCase());
          return lineDigits === addressDigits && similarityScore > MIN_SIMILARITY_SCORE;
        }) != null
      );
    });
  };

  const signInGbp = useCallback(
    async (state: string, code: string) => {
      let signInError = true;

      await dispatch(
        googleOauth2({
          code,
          state,
        }),
      );

      const gbpAccounts = (
        await dispatch(
          getGbpAccounts({
            venueId,
          }),
        )
      ).payload as Array<GbpAccount>;

      const accountsId = gbpAccounts.map((account) => account.name.split('/')[1]);

      const locations = (
        await dispatch(
          getGbpLocations({
            accountsId,
            venueId,
          }),
        )
      ).payload as Array<GbpLocation>;

      if (locations.length && companyAddress) {
        const { zip, address } = companyAddress;
        const companyLocation = getGbpLocation(locations, zip, address);
        if (companyLocation) {
          signInError = false;
          const { name, accountId } = companyLocation;
          dispatch(
            confirmLocation({
              accountId,
              locationId: name.split('/')[1],
              venueId,
            }),
          ).then((res) => {
            if (res.payload) {
              dispatch(
                getConnectedAccount({
                  venueId,
                }),
              );
            }
          });
        }
      }

      setIsSignInError(signInError);
    },
    [dispatch, venueId, companyAddress],
  );

  useEffect(() => {
    if (
      connectedAccount &&
      localStorage.getItem('isGbpSyncStarted') === String(true) &&
      liveSocialVerses.length > 0
    ) {
      liveSocialVerses.forEach((socialverse) => {
        dispatch(
          putEditSocialVerse({
            id: socialverse.id,
            data: {
              ...socialverse,
              gbpSyncEnabled: true,
            },
          }),
        );
      });
      localStorage.setItem('isGbpSyncStarted', String(false));
    }
  }, [connectedAccount, liveSocialVerses, dispatch]);

  const clearQueryParams = useCallback(() => {
    query.delete('code');
    query.delete('scope');
    query.delete('state');
    history.replace({
      search: query.toString(),
    });
  }, [history, query]);

  useEffect(() => {
    if (state && code && venueId && companyAddress) {
      signInGbp(state, code);
      clearQueryParams();
    }
  }, [state, code, dispatch, signInGbp, clearQueryParams, venueId, companyAddress]);

  return (
    <Box className={classes.container}>
      <ConfirmSyncModal
        isOpen={isConfirmSyncModalOpen}
        onClose={closeConfirmSyncMoldal}
        onConfirm={() => {
          closeConfirmSyncMoldal();
          openConsentModal();
          consentTimerRef.current = setTimeout(() => {
            closeConsentModal();
            handleSignIn();
          }, CONSENT_TIMEOUT);
        }}
        liveVideosCount={liveVideosCount}
      />
      <ConsentModal
        isOpen={isConsentModalOpen}
        onClose={() => {
          closeConsentModal();
          handleSignIn();
          if (consentTimerRef.current) {
            clearTimeout(consentTimerRef.current);
          }
        }}
      />

      <Box className={classes.topWrapper}>
        <Typography className={classes.title}>{DistributorsStrings.Title}</Typography>
        <Typography className={classes.description}>{DistributorsStrings.Descr}</Typography>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.bottomWrapper}>
        <Box className={classes.googleProfileWrapper}>
          <img src="/GoogleProfile.png" className={classes.googleProfileIcon} alt="google icon" />
          <Box ml="10px">
            <Typography className={classes.googleProfileTitle}>
              {DistributorsStrings.GoogleProfileTitle}
            </Typography>
            <Typography className={classes.googleProfileDescr}>
              {DistributorsStrings.GoogleProfileDescr}
            </Typography>
          </Box>
        </Box>
        {!connectedAccount && (
          <Box className={classes.signInWrapper}>
            {isSignInError && (
              <Box>
                <Typography className={classes.errorTitle}>
                  {DistributorsStrings.ErrorTitle}
                </Typography>
                <Typography className={classes.ensureText}>
                  {DistributorsStrings.EnsureText}
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>
                    Your{' '}
                    <Link
                      href={WebsiteRoutes.AccountsCompany}
                      to={WebsiteRoutes.AccountsCompany}
                      className={classes.profileLink}
                    >
                      Company Profile
                    </Link>
                    <LinkIcon className={classes.linkIcon} />
                    {DistributorsStrings.MatchesGoogleProfile}
                  </li>
                  <li className={classes.listItem}>{DistributorsStrings.GoogleAccountIsLinked}</li>
                </ul>
              </Box>
            )}
            {isConfirmLocationError && (
              <Box>
                <Typography className={classes.errorTitle}>
                  {DistributorsStrings.ErrorTitleConnected}
                </Typography>
                <Typography className={classes.ensureText}>
                  {DistributorsStrings.TryText}
                </Typography>
                <ul className={classes.list}>
                  <li className={classes.listItem}>{DistributorsStrings.DisconnectYour}</li>
                  <li className={classes.listItem}>{DistributorsStrings.ConnectTo}</li>
                </ul>
              </Box>
            )}
            <Box
              className={classes.googleBtnWrapper}
              sx={{
                background: isGoogleBtnActive ? 'white !important' : '',
              }}
            >
              <Button
                className={classes.signInButton}
                onClick={openConfirmSyncModal}
                onMouseDown={() => {
                  setIsGoogleBtnActive(true);
                }}
                onMouseLeave={() => {
                  setIsGoogleBtnActive(false);
                }}
                onMouseUp={() => {
                  setIsGoogleBtnActive(false);
                }}
                startIcon={
                  isLoading ? (
                    <CircularProgress className={classes.progress} size={22} />
                  ) : (
                    <GoogleIcon className={classes.googleIcon} />
                  )
                }
              >
                {DistributorsStrings.Connect}
              </Button>
            </Box>
            {isSignInError && (
              <Box className={classes.contactWrapper}>
                <Typography display="inline" className={classes.contactText} color="#646E8A">
                  {DistributorsStrings.ContactStart}
                </Typography>
                <a
                  href="mailto:support@socialvenu.com"
                  className={classes.contactText}
                  color="#1769FF"
                  style={{
                    textDecoration: 'underline',
                  }}
                >
                  {DistributorsStrings.CustomerCare}
                </a>
                <Typography display="inline" className={classes.contactText} color="#646E8A">
                  {DistributorsStrings.ContactEnd}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {connectedAccount && (
          <Box className={classes.syncedBusinessWrapper}>
            <SyncedBusiness
              connectedAccount={connectedAccount}
              onDisconnect={() => {
                dispatch(
                  disconnectAccount({
                    venueId,
                  }),
                );
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
