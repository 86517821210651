import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSignUpFormStyles = makeStyles((theme: Theme) => ({
  SubmitBtn: {
    '& >button:disabled': {
      backgroundColor: '#DCD9FB !important',
      color: '#B3ACF6 !important',
      '& svg': {
        color: '#B3ACF6 !important',
      },
    },
  },
  captchaBlock: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainSide: {
    padding: '60px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  mobileMainSide: {
    scale: '1.4',
    padding: '100px 0px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    width: '100%',
    maxWidth: '440px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginTop: {
    marginTop: '15px',
    '& label, p': {
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      color: theme.palette.common.black,
    },
  },
  title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    marginBottom: '15px',
  },
  plan: {
    display: 'inline-block',
    backgroundColor: '#F5F6F8',
    padding: '14px',
    borderRadius: '12px',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '34px',
    margin: '14px 0',
  },
  pairInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subTitle: {
    color: '#8B89A0',
    fontSize: '14px',
  },
  planName: {},
  month: {
    fontSize: '14px',
    color: '#8B89A0',
  },
  termsBlock: {
    whiteSpace: 'nowrap',
    marginTop: '5px',
    '& p': {
      fontWeight: 600,
      fontSize: '11px',
      lineHeight: '12px',
      letterSpacing: '0.01em',
    },
    '& .MuiCheckbox-root': {
      transform: 'scale(1.1)',
    },
  },
  numberInput: {
    width: '70px',
  },
  full: {
    height: '42px',
    width: '100%',
    marginTop: '10px',
  },
  termLinksBlock: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    marginTop: '10px',
  },
  termLink: {
    fontWeight: '400',
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5242EA',
  },
  linkDivider: {
    height: '20px',
  },
  inputSubdomain: {
    '& input': {
      textAlign: 'right',
      color: '#5242EA',
      fontSize: '14px',
      fontWeight: '500',
      paddingRight: 0,
    },
  },
  planSuccessWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'absolute',
    bottom: '-30px',
  },
  planSuccess: {
    fontSize: '12px',
    color: '#3F9700',
  },
  agreementLink: {
    color: '#5242EA',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  btnDisabled: {
    backgroundColor: '#000 !important',
    color: '#fff !important',
  },
  inputSuccess: {
    color: '#8B89A0 !important',
  },
  BackgroundSideIcon: {
    position: 'absolute',
    right: 0,
    bottom: '106px',
  },
}));
