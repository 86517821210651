import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { ConversionsIconDv5 } from './ConversionsIconDv5';
import { PointerIconDv5 } from './PointerIconDv5';
import { RateIconDv5 } from './RateIconDv5';
import { ViewIconDv5 } from './ViewIconDv5';

export interface IVideoData {
  icon: React.ReactNode;
  label: string;
  value: number | string;
}

export const createVideoData = (
  views: number,
  ctaClicks: number,
  conversionRate: string,
  convertedReferrals: number,
): IVideoData[] => {
  return [
    {
      icon: <ViewIconDv5 />,
      label: VideoDetailsModalStringsDv5.Views,
      value: views,
    },
    {
      icon: <PointerIconDv5 />,
      label: VideoDetailsModalStringsDv5.CTA,
      value: ctaClicks,
    },
    {
      icon: <ConversionsIconDv5 />,
      label: VideoDetailsModalStringsDv5.Conversions,
      value: convertedReferrals,
    },
    {
      icon: <RateIconDv5 />,
      label: VideoDetailsModalStringsDv5.ConversionRate,
      value: conversionRate,
    },
  ];
};
