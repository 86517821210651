// import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { BoxProps } from '@material-ui/core';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useMemo, useRef } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useToasts } from 'react-toast-notifications';

import { CommonStrings } from '../../../localization/en';
import { UploadIcon } from '../../../assets/UploadIcon';
import { Alignment } from '../ImageUploaderWithGallery';
import { CommonEventNames } from '../../../constants/events/common';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';

export type ImageUploaderSupportedFileTypes = 'jpeg' | 'png' | 'svg';

const acceptOptions: Record<ImageUploaderSupportedFileTypes, Accept> = {
  jpeg: {
    'image/jpeg': ['.jpeg', '.jpg'],
  },
  png: {
    'image/png': ['.png'],
  },
  svg: {
    'image/svg+xml': ['.svg'],
  },
};

export interface ImageUploaderBaseProps extends BoxProps {
  file: string | undefined;
  onUpload: (file: File | undefined) => void;
  accept?: ImageUploaderSupportedFileTypes[];
  loading?: boolean;
  renderRightSide?: (props: { openFileSelector: () => void }) => React.ReactNode;
  renderBottomBlock?: (props: { openFileSelector: () => void }) => React.ReactNode;
  onRemoveClick?: () => void;
  dndDescription?: string;
  dndTitle?: string;
  dndWidth?: string;
  dndHeight?: string;
  imagePreviewWidth?: string;
  imagePreviewHeight?: string;
  alignment?: Alignment;
  imagePadding?: number;
  /**
   * "cover" -> image will take full size of container and trimmed - useful for logos
   * "contain" -> display full image with background as borders - useful for background images
   */
  imagePreviewFit?: 'cover' | 'contain';
  isCreateNewCampaignPreview?: boolean;
  dndIcon?: JSX.Element;
}

export const ImageUploaderBase: FC<ImageUploaderBaseProps> = ({
  file,
  onUpload,
  accept,
  loading,
  renderRightSide,
  renderBottomBlock,
  onRemoveClick,
  dndDescription,
  dndTitle,
  imagePreviewWidth = '180px',
  imagePreviewHeight = '180px',
  dndWidth = imagePreviewWidth,
  dndHeight = imagePreviewHeight,
  imagePreviewFit = 'cover',
  alignment,
  imagePadding,
  isCreateNewCampaignPreview,
  dndIcon,
  ...boxProps
}) => {
  const theme = useTheme();
  const { trackEvent } = useTrackEvent();
  const acceptDropzoneSettings = useMemo<Accept | undefined>(() => {
    return (accept || []).reduce((resultAccept: Accept, fileType) => {
      return { ...resultAccept, ...acceptOptions[fileType] };
    }, {});
  }, [accept]);

  const { addToast } = useToasts();

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
    isDragActive,
  } = useDropzone({
    disabled: loading,
    multiple: false,
    onDrop: (files) => onUpload(files[0]),
    accept: acceptDropzoneSettings,
    onDropRejected: () => {
      addToast(CommonStrings.OnError, { appearance: 'error' });
    },
  });

  const renderImagePreview = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={imagePreviewWidth}
        height={imagePreviewHeight}
        position="relative"
        onClick={() => {
          trackEvent(CommonEventNames.image_uploader_area_click);
        }}
        padding={imagePadding || 0}
        border={isCreateNewCampaignPreview ? 'none' : '1px solid #5242EA'}
        borderRadius="12px"
      >
        <img
          src={file}
          alt="selected-file"
          style={{
            borderRadius: '12px',
            width: '100%',
            height: '100%',
            objectFit: imagePreviewFit,
          }}
        />
        {onRemoveClick && (
          <IconButton
            aria-label="remove"
            size="small"
            sx={{
              position: 'absolute',
              top: isCreateNewCampaignPreview ? '-15px' : '0px',
              right: isCreateNewCampaignPreview ? '-20px' : '0px',
              bgcolor: isCreateNewCampaignPreview ? '#AFAFAF' : '#00000020',
              width: isCreateNewCampaignPreview ? '32px' : '20px',
              height: isCreateNewCampaignPreview ? '32px' : '20px',
              m: '4px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveClick();
            }}
          >
            <CloseIcon
              sx={{
                color: 'white',
                width: '16px',
                height: '16px',
              }}
            />
          </IconButton>
        )}
      </Box>
    );
  };

  const renderDND = () => {
    return (
      <Box
        border="1px solid #EAECF0"
        borderRadius="12px"
        height={dndHeight}
        width={dndWidth}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
      >
        {dndIcon ? dndIcon : <UploadIcon />}
        <Box>
          <Typography fontSize="11px" color="#475467" textAlign="center" mb="4px" fontWeight="500">
            {dndTitle || 'Drag and drop'}
          </Typography>
          {dndDescription && (
            <Typography maxWidth="120px" fontSize="12px" color="#475467" textAlign="center">
              {dndDescription}
            </Typography>
          )}
        </Box>
        <input {...getDropzoneInputProps()} />
      </Box>
    );
  };

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const handleHiddenInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    onUpload(fileUploaded);
  };

  const acceptHiddenInputSettings = Object.keys(acceptDropzoneSettings || {}).join(', ');

  const openFileSelector = () => {
    hiddenFileInput.current?.click();
  };

  return (
    <>
      <Box display="flex" alignItems={alignment || 'flex-end'} {...boxProps}>
        <Box
          {...getDropzoneRootProps()}
          mr="26px"
          border={`1px dashed ${isDragActive ? theme.palette.primary.main : '#EAECF0'}`}
          bgcolor="#F2F4F7"
          borderRadius="12px"
          mt={dndIcon ? '6px' : '0'}
        >
          {file ? renderImagePreview() : renderDND()}
        </Box>
        <Box>{renderRightSide?.({ openFileSelector })}</Box>
      </Box>
      <input
        type="file"
        accept={acceptHiddenInputSettings}
        ref={hiddenFileInput}
        onChange={handleHiddenInputChange}
        style={{ display: 'none' }}
      />
      <Box>{renderBottomBlock?.({ openFileSelector })}</Box>
    </>
  );
};
