import { FC } from 'react';

export const DocumentIcon: FC = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 5.16634V11.833C14 13.833 13 15.1663 10.6667 15.1663H5.33333C3 15.1663 2 13.833 2 11.833V5.16634C2 3.16634 3 1.83301 5.33333 1.83301H10.6667C13 1.83301 14 3.16634 14 5.16634Z"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 5.16634V11.833C14 13.833 13 15.1663 10.6667 15.1663H5.33333C3 15.1663 2 13.833 2 11.833V5.16634C2 3.16634 3 1.83301 5.33333 1.83301H10.6667C13 1.83301 14 3.16634 14 5.16634Z"
      stroke="#5242EA"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66669 3.5V4.83333C9.66669 5.56667 10.2667 6.16667 11 6.16667H12.3334"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.66669 3.5V4.83333C9.66669 5.56667 10.2667 6.16667 11 6.16667H12.3334"
      stroke="#5242EA"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33331 9.16699H7.99998"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33331 9.16699H7.99998"
      stroke="#5242EA"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33331 11.833H10.6666"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33331 11.833H10.6666"
      stroke="#5242EA"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
