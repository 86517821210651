import { Box, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { CampaignActivitiesTypes } from '../../../api/models/campaigns';
// import { ContentSubNavigation } from '../ContentSubNavigation/ContentSubNavigation';
import { ClientTypes } from '../../../api/models/common';
import { SearchTargets } from '../../../api/models/searchFilter';
/* import { StoryApiModel } from '../../../api/models/videos'; */
import { ActionBar, ActiveModal } from '../../../common/components/ActionBar/ActionBar';
import { activeModalLiteral } from '../../../common/components/ActionBar/ActionBarModals';
import { CreateSearchFilterComponent } from '../../../common/components/CreateSearchFilterComponent/CreateSearchFilterComponent';
import {
  DateRangeFilter,
  ReturnedDate,
} from '../../../common/components/datePicker/DateRangeFilter';
/* import { createContentDataTableContent } from '../../../services/helpers/tableMappers'; */
/* import { getItemsFromPageAndSize } from '../../../services/utilities'; */
import { useAppDispatch, useTypedSelector } from '../../../store';
import { reset } from '../../../store/slices/analyticsSlice';
import { getActiveIncentiveCampaignActivities } from '../../../store/slices/campaignsSlice';
import { getSearchFilters } from '../../../store/slices/searchFiltersSlice';
import { DateFilterMobileContainer } from '../../dashboard/Dashboard.style';
import { ContentTable } from './contentTable/ContentTable';
import { ContentContainer, useVideosTableStyles } from './TableSection.style';
import { TopgolfContentTable } from './topgolfContentTable/topGolfContentTable';
import { KeywordsDropdown } from '../../../common/components/KeywordsDropdown/KeywordsDropdown';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../../common/constants/events/videosEvents';

interface TabeSectionProps {
  handleOpenDeleteModal: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal: (id: string) => void;
  handleOpenAddNewTagModal: (id: string) => void;
  handleAddVideoToCard?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  statusFilter?: string;
  archivedFilter: boolean;
  showActionBar: boolean;
  isSocialVerseAddVideo?: boolean;
  onCreateFilterClick?: () => void;
  onSavedFiltersClick?: () => void;
  onVideoKeywordsClick?: () => void;
  handleVideoReplace?: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
}

export const TableSection: FC<TabeSectionProps> = ({
  handleOpenDeleteModal,
  handleOpenConfirmationModal,
  handleOpenAddNewTagModal,
  handleAddVideoToCard,
  statusFilter,
  archivedFilter,
  showActionBar,
  isSocialVerseAddVideo,
  onCreateFilterClick,
  onSavedFiltersClick,
  onVideoKeywordsClick,
  handleVideoReplace,
}) => {
  const styles = useVideosTableStyles();
  const dispatch = useAppDispatch();
  const { clientType, id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { selectedRows } = useTypedSelector((state) => state.videos);
  const { totalItems } = useTypedSelector((state) => state.videos);
  const { totalItems: totalTopGolfItems } = useTypedSelector((state) => state.TopgGolfVideos);
  const activeCampaign = useTypedSelector((state) => state.campaigns.activeCampaign);
  const activities = useTypedSelector((state) => state.campaigns.activeCampaign?.activities);
  const { trackEvent } = useTrackEvent();

  const [date, setDate] = useState<ReturnedDate | null>(null);
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
  const updateFilters = (date: ReturnedDate | null) => {
    setDate(date);
  };

  useEffect(() => {
    if (venueId) {
      dispatch(getSearchFilters({ venueId, target: SearchTargets.VIDEOS }));
    }
  }, [venueId, dispatch]);

  useEffect(() => {
    if (activeCampaign?.id) {
      dispatch(getActiveIncentiveCampaignActivities(activeCampaign?.id));
    }
  }, [dispatch, activeCampaign?.id]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBtnClick = (name: typeof activeModal) => {
    setActiveModal(name);
  };

  const handleClose = () => {
    setActiveModal(null);
  };

  const headers = [
    { label: 'User Name', key: 'userName' },
    { label: 'Date Created', key: 'createdAt' },
    { label: 'Clicks', key: 'ctaClicks' },
    { label: 'Views', key: 'views' },
    { label: 'Conversions', key: 'conversion' },
    { label: 'Keyword Score', key: 'keywordScore' },
    { label: 'Keywords', key: 'keywordsCount' },
    { label: 'Video Status', key: 'status' },
    { label: 'Add Video', key: 'addVideo' },
  ];

  const hasActiveCampaignBonusPoints =
    activities &&
    activities.find((activity) => activity.activityType === CampaignActivitiesTypes.BonusPoints);
  const isAwardsBonusPointsActive = activeCampaign && hasActiveCampaignBonusPoints ? true : false;

  return (
    <>
      {/* <ContentSubNavigation /> */}
      <Box
        display="flex"
        alignItems="center"
        paddingLeft="40px"
        paddingRight="38px"
        paddingTop="18px"
      >
        {showActionBar && (
          <ActionBar
            setActiveModal={(name) => handleBtnClick(name)}
            selectedRowsLength={selectedRows?.length}
            isAwardsBonusPointsActive={isAwardsBonusPointsActive}
          />
        )}
        {!showActionBar && (
          <Box width="100%" display="flex" justifyContent="space-between">
            <KeywordsDropdown onVideoKeywordsClick={onVideoKeywordsClick} />
            <CreateSearchFilterComponent
              target={SearchTargets.VIDEOS}
              isSocailVerse={!!isSocialVerseAddVideo}
              videosAmount={isNotTopgolf ? totalItems : totalTopGolfItems}
              onCreateFilterClick={onCreateFilterClick}
              onSavedFiltersClick={onSavedFiltersClick}
            />
          </Box>
        )}
        {/* could be refactored in the end ↓ */}
        {activeModal === 'DOWNLOAD_CSV' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, headers)}
        {activeModal === 'ASSIGN_BONUS_POINTS' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, selectedRows ?? [])}
        {activeModal === 'DOWNLOAD_CSV_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_TO_PLUGIN_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_BONUS_POINTS_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
      </Box>
      <Box paddingLeft="24px" paddingRight="24px">
        <ContentContainer>
          {isNotTopgolf ? (
            <div className={styles.SVVideosTableStyles}>
              <ContentTable
                handleOpenDeleteModal={handleOpenDeleteModal}
                statusFilter={statusFilter}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
                handleOpenAddNewTagModal={handleOpenAddNewTagModal}
                archivedFilter={archivedFilter}
                isSocialVersePage={isSocialVerseAddVideo}
                handleAddVideoToCard={handleAddVideoToCard ? handleAddVideoToCard : () => {}}
                onRowClick={(videoId) => {
                  trackEvent(VideosEventNames.openVideoDetailsClicked, { videoId: videoId });
                }}
                handleVideoReplace={handleVideoReplace}
              />
            </div>
          ) : (
            <div className={styles.SVTopGolfVideosTableStyles}>
              <Grid container justifyContent="flex-end" alignItems="center">
                <Grid item xs={4} style={{ display: 'none' }}>
                  <DateFilterMobileContainer>
                    <DateRangeFilter updateRange={updateFilters} width="100%" noFormatDate={true} />
                  </DateFilterMobileContainer>
                </Grid>

                <Grid item xs={12}>
                  <TopgolfContentTable
                    handleOpenDeleteModal={handleOpenDeleteModal}
                    statusFilter={archivedFilter ? 'ARCHIVED' : statusFilter}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                    handleOpenAddNewTagModal={handleOpenAddNewTagModal}
                    archivedFilter={archivedFilter}
                    date={date}
                    isSocialVersePage={isSocialVerseAddVideo}
                    handleAddVideoToCard={handleAddVideoToCard ? handleAddVideoToCard : () => {}}
                    handleVideoReplace={handleVideoReplace}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </ContentContainer>
      </Box>
    </>
  );
};
