import { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { createTeamDataTableContent } from '../../../../../services/helpers/tableMappers';
import {
  getHeroes,
  goToSelectedPage,
  setUserDataSorting,
  updateSize,
} from '../../../../../store/slices/heroesSlice';
import { CommonTable } from '../../../../../common/components/table/CommonTable';
import { WebsiteRoutes } from '../../../../../common/constants/routes';
import { FilterStatuses } from '../../../../../common/constants/constants';

interface TeamTableProps {
  status: string;
}
export const TeamTable: FC<TeamTableProps> = ({ status }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
    lastUpdated,
  } = useTypedSelector((state) => state.heroes);
  const venueId = useTypedSelector((state) => state.venue.venue.id);

  useEffect(() => {
    if (venueId) {
      if (!(status === FilterStatuses.all)) {
        dispatch(getHeroes({ search, status, venueId, pageable: { page, size, sort } }));
      } else {
        dispatch(getHeroes({ search, venueId, pageable: { page, size, sort } }));
      }
    }
  }, [dispatch, page, size, sort, search, venueId, lastUpdated, status]);

  const userDataTableContents = createTeamDataTableContent({
    items,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setUserDataSorting(name));
  };

  const onClickRow = (id: string) => {
    history.push(`${WebsiteRoutes.TeamSettings}/${id}`);
  };

  const changeSize = (size: number) => {
    dispatch(updateSize(size));
  };

  return (
    <CommonTable
      content={userDataTableContents}
      page={convertApiPageToFrontEndPage(page)}
      sort={sort}
      totalItems={totalItems}
      totalPages={totalPages}
      isLoading={isLoading}
      noContent={error}
      tablePadding="0"
      goToPage={onGoToPage}
      onSortHeaderClick={onSort}
      onClickRow={onClickRow}
      size={size}
      onSizeChange={changeSize}
    />
  );
};
