import { Box, Grid } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { VideoApiModel } from '../../../../api/models/videos';
import { TopGolfVideosApiModel } from '../../../../api/models/topgolfVideos';
import { UserApiModel } from '../../../../api/models/users';
import { getVideoSharesById } from '../../../../store/slices/videosSlice';
import { getTopGolfVideoSharesById } from '../../../../store/slices/topGolfVideosSlice';
import { SettingsPageStrings, VideoDetailsModalStringsDv5 } from '../../../localization/en';
import { BottomToolbar } from '../../BottomToolbar/BottomToolbar';
import { EditThumbnail, ThumbnailFrame } from '../../EditThumbnail/EditThumbnail';
import { SharedVideo } from '../../SharedVideo/SharedVideo';
import { TopGolfVideoInfo } from '../../TopGolfVideoInfo/TopGolfVideoInfo';
import { Transcript } from '../../Transcript/Transcript';
import { VideoComponent } from '../../VideoComponent/VideoComponent';
import { VideosDetailUserComponent } from '../../VideosDetailUserComponent/VideosDetailUserComponent';
import { VideosDetailVideoData } from '../../VideosDetailVideoData/VideosDetailVideoData';
import { VideoPlaybackModal } from './VideoPlaybackModal';

import { useTypedSelector } from '../../../../store';
import { VideoKeywords } from './VideoKeywords';
import { useVideoTableIdContext } from '../../../../pages/SocialVerse/SocialVerse.helper';

interface ModalInfoProps {
  userDateCreated: string;
  activeCampaign: CampaignsApiModel | null;
  isTopGolf: boolean;
  subdomain: string;
  userData?: UserApiModel;
  video: VideoApiModel | TopGolfVideosApiModel;
  thumbnailFrames: ThumbnailFrame[];
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
  handleOpenDownloadVideoDialog: () => void;
  convertedRefferals: number;
  handleDeleteModalOpen: () => void;
  handleAddVideoToCard?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
}

export const ContentModalInfo: FC<ModalInfoProps> = ({
  userDateCreated,
  activeCampaign,
  video,
  handleOpenDownloadVideoDialog,
  convertedRefferals: convertedReferrals,
  isTopGolf,
  subdomain,
  handleDeleteModalOpen,
  handleAddVideoToCard,
  thumbnailFrames,
}) => {
  const thumbnailRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);

  const storyLink = `https://${subdomain}${SettingsPageStrings.DomainName}/s?id=${video.shortcode}`;
  const [isPlaying, setIsPlaying] = useState(false);

  const { withEditThumbnailOpen } = useVideoTableIdContext();
  const [openEditThumbnail, setopenEditThumbnail] = useState(withEditThumbnailOpen || false);

  useEffect(() => {
    !withEditThumbnailOpen && setopenEditThumbnail(false);
  }, [video, withEditThumbnailOpen]);

  useEffect(() => {
    if (!video.sharesAnalyticsData) {
      if (video.id) {
        if (!isTopGolf) {
          dispatch(getVideoSharesById({ id: video.id }));
        } else if (isTopGolf) {
          dispatch(getTopGolfVideoSharesById({ id: video.id }));
        }
      }
    }
  }, [video.sharesAnalyticsData, dispatch, isTopGolf, video.id]);

  const handleOpenEditThumbnail = () => {
    setopenEditThumbnail(!openEditThumbnail);
    if (thumbnailRef.current && !openEditThumbnail)
      thumbnailRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenPlayVideoModal = () => {
    setIsOpen(!isOpen);
    setIsPlaying(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsPlaying(false);
  };
  const handleAddVideoToCardClick = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    if (handleAddVideoToCard) {
      handleAddVideoToCard(e, id, false);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justifyContent="center"
      style={{ position: 'relative' }}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <VideoPlaybackModal
        isOpen={isOpen}
        isTopGolf={isTopGolf}
        videoUrl={video.url}
        onClose={handleClose}
        thumbnailUrl={video.thumbnailUrl}
      />
      <Box margin="20px">
        <Grid
          item
          style={{ marginLeft: !isTopGolf ? '20px' : '0px' }}
          container
          direction="row"
          wrap="nowrap"
        >
          <Grid item>
            <VideoComponent
              url={video.url}
              handleOpenThumbnailsPanel={handleOpenEditThumbnail}
              thumbnail={video.thumbnailUrl}
              isTopGolf={isTopGolf}
              handleOpenModal={handleOpenPlayVideoModal}
              isPlaying={isPlaying}
            />
          </Grid>
          {!isTopGolf ? (
            <Grid item style={{ marginLeft: '20px' }}>
              <VideosDetailUserComponent
                activeCampaign={activeCampaign}
                userDateCreated={userDateCreated}
                userId={isTopGolf ? '' : (video as VideoApiModel).userId}
                userPhotoUrl={isTopGolf ? '' : (video as VideoApiModel).userPhotoUrl}
                userName={isTopGolf ? '' : (video as VideoApiModel).userName}
                isTopGolf={isTopGolf}
                storyID={video.id}
                storyStatus={video.status}
                handleAddVideoToCard={handleAddVideoToCardClick}
              />
            </Grid>
          ) : (
            <TopGolfVideoInfo
              shotTime={(video as TopGolfVideosApiModel).shotTime}
              teeTime={(video as TopGolfVideosApiModel).teeTime}
              shortcode={(video as TopGolfVideosApiModel).shortcode}
            />
          )}
        </Grid>
        <Grid item ref={thumbnailRef}>
          <EditThumbnail
            isOpen={openEditThumbnail}
            handleClose={handleOpenEditThumbnail}
            storyThumbnailUrl={video.thumbnailUrl}
            storyAltThumbnailUrl={video.altThumbnailUrl}
            storyUrl={video.url}
            storyId={video.id}
            isTopGolf={isTopGolf}
            frames={thumbnailFrames}
          />
        </Grid>
        {/* hide section for now 05-13-2022 */}
        {!isTopGolf ? (
          <Grid item>
            <VideoKeywords
              keywordsTitle={VideoDetailsModalStringsDv5.Keywords}
              keywords={keywords || []}
              transcriptText={video.transcript}
            />
          </Grid>
        ) : null}
        {!isTopGolf ? (
          <Grid item>
            <Transcript
              trancsriptText={video.transcript || ''}
              keywords={keywords ? keywords.map((item) => item.keyword) : []}
            />
          </Grid>
        ) : null}
        <Grid item>
          <VideosDetailVideoData
            views={video.views}
            ctaClicks={video.ctaClicks}
            convertedReferrals={convertedReferrals}
          />
        </Grid>
        <Grid item>
          <SharedVideo videoAnalyticsData={video.sharesAnalyticsData} />
        </Grid>
      </Box>
      <BottomToolbar
        storyPageUrl={isTopGolf ? storyLink : (video as VideoApiModel).videoPageUrl}
        handleOpenDownloadVideoDialog={handleOpenDownloadVideoDialog}
        handleDeleteModalOpen={handleDeleteModalOpen}
        isTopGolf={isTopGolf}
      />
    </Grid>
  );
};
