import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useStyles } from './VideoUplaoder.styles';
import { DNDIcon } from '../../assets/newDesign/DNDIcon';
import { VideoUploaderStrings } from '../../localization/en';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  CreateStory,
  createUploadUrl,
  reset,
  uploadVideoAsFile,
} from '../../../store/slices/uploadVideoSlice';
import { useOnUploadProgress } from '../../../services/hooks/useOnUploadProgress';
import {
  getDefaultStoryDetails,
  getFileSizeInMb,
  getUpdatedVideoUrl,
  getUserStoryDetails,
  getVideoPosterWithCloudinary,
} from './VideoUplader.helpers';
import { updateTable } from '../../../store/slices/videosSlice';
import {
  useAttributesFilterUrl,
  useLogoFilterEnabled,
} from '../../../services/hooks/useAttributesFilterUrl';
import { DEFAULT_VIDEO_DETAILS } from '../../../api/constants';
import { useClientType } from '../../../services/hooks/useClientType';
import { LinearProgressWithLabel } from '../LinearProgressBar/LinearProgress.helper';
import { CompletedIcon } from '../../assets/CompletedIcon';
import { CancelIcon } from '../../assets/CancelIcon';
import { FileIcon } from '../../assets/FileIcon';
import { fetchUserDetails, getCampaignUser } from '../../../store/slices/userDetailsSlice';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';
import { getCampaignVideos } from '../../../store/slices/campaignsSlice';
import { getVideoDuration } from '../../../services/utilities';

interface Props {
  userId: string;
  onFileSelect?: (value: boolean) => void;
  isCampaignUser?: boolean;
  campaignId?: string;
  disabled: boolean;
}

const VideoUploader: FC<Props> = ({
  userId,
  onFileSelect,
  isCampaignUser,
  campaignId,
  disabled,
}) => {
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [userFile, setUserFile] = useState<File | null>(null);
  const [isUploadError, setIsUploadError] = useState(false);
  const isCancelUpload = useRef(false);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const dispatch = useAppDispatch();

  const logoEnabled = useLogoFilterEnabled();
  const attributesFilterUrl = useAttributesFilterUrl();

  const { isAthleticSolutionsClient } = useClientType();

  const onUploadProgress = useOnUploadProgress({});

  const { name } = useTypedSelector((state) => state.me);
  const { userDetails } = useTypedSelector((state) => state.userDetails);
  const { venue, venueAttributes } = useTypedSelector((state) => state.venue);
  const { uploadingProgress } = useTypedSelector((state) => state.uploadVideo);

  const { trackEvent } = useTrackEvent();

  const storyDetails = useMemo(() => {
    const properties = getDefaultStoryDetails(
      userDetails?.defaultVideoDetails || null,
      venueAttributes.properties?.['webapp.default-story-details'],
      DEFAULT_VIDEO_DETAILS(),
      userDetails?.returnUrl || undefined,
    );
    const userName = userDetails?.displayName || name;

    return getUserStoryDetails(properties, userName, venue.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, venue, venueAttributes]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsLoading(true);
      const file = acceptedFiles[0];

      if (!file) {
        setIsLoading(false);
        return;
      }
      const duration = await getVideoDuration(file);

      dispatch(reset());
      setUserFile(file);

      trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileDrop, { selectedUserId: userId });

      createUploadUrl(userId)
        .then((res) => {
          if (res) {
            trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploading, {
              selectedUserId: userId,
            });
            dispatch(
              uploadVideoAsFile({
                options: res,
                data: file,
                onUploadProgress,
              }),
            ).then(async () => {
              const url = await getUpdatedVideoUrl(
                res.downloadUrl,
                logoEnabled ? attributesFilterUrl : undefined,
              );

              const urlWitoutFilter = await getUpdatedVideoUrl(res.downloadUrl, undefined);

              if (isCancelUpload.current) {
                isCancelUpload.current = false;
                return;
              }

              dispatch(
                CreateStory({
                  categoryId: '',
                  url: url,
                  isPublic: true,
                  duration,
                  thumbnailUrl: getVideoPosterWithCloudinary(url, true),
                  altThumbnailUrl: getVideoPosterWithCloudinary(urlWitoutFilter, true),
                  details: {
                    ...storyDetails,
                    ctaBtnLinkUrl: isAthleticSolutionsClient
                      ? userDetails?.returnUrl || null
                      : storyDetails.ctaBtnLinkUrl || null,
                  },
                  userId: userId,
                }),
              ).then((action: any) => {
                if (action.payload.id) {
                  trackEvent(CreatorsTableEventNames.CreatorDetailsModalStoryCreated, {
                    selectedUserId: userId,
                    videoId: action.payload.id,
                  });
                }

                if (isCampaignUser) {
                  if (campaignId) {
                    dispatch(getCampaignUser({ campaignId, userId }));
                    dispatch(
                      getCampaignVideos({
                        id: campaignId,
                        pageable: {
                          page: 0,
                          size: 100000,
                          sort: ['asc'],
                        },
                      }),
                    );
                  }
                } else {
                  dispatch(fetchUserDetails(userId));
                }

                const timer = setTimeout(() => {
                  dispatch(updateTable());
                  setIsLoading(false);
                }, 2000);
                timerRef.current = timer;
              });

              if (onFileSelect) {
                onFileSelect(false);
              }
            });
          }
        })
        .catch(() => {
          setIsUploadError(true);
          trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploadingError, {
            selectedUserId: userId,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      userId,
      onUploadProgress,
      logoEnabled,
      attributesFilterUrl,
      isAthleticSolutionsClient,
      storyDetails,
      userDetails?.returnUrl,
      onFileSelect,
      isCancelUpload,
      isCampaignUser,
      campaignId,
    ],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/ogg': ['.ogg'],
      'video/x-matroska': ['.mkv'],
      'video/quicktime': ['.mov'],
    },
    multiple: false,
    noDragEventsBubbling: true,
    noClick: isLoading,
    noDrag: isLoading,
    disabled: disabled,
  });

  const tryAgainHandler = () => {
    setIsLoading(false);
    setIsUploadError(false);
    setUserFile(null);

    trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploadingTryAgainClick, {
      selectedUserId: userId,
    });
  };

  const cancelUploadingHandler = () => {
    isCancelUpload.current = true;
    setIsLoading(false);
    setIsUploadError(false);
    setUserFile(null);

    trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploadingCancelClick, {
      selectedUserId: userId,
    });

    if (onFileSelect) {
      onFileSelect(false);
    }
  };

  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <div onClick={() => onFileSelect && onFileSelect(true)}>
      <Box
        className={styles.container}
        {...getRootProps()}
        sx={{ cursor: disabled ? 'not-allowed !important' : '' }}
      >
        {!isLoading && (
          <>
            <input {...getInputProps()} />
            <DNDIcon color="#D9D9D9" />
            <Box marginLeft="24px">
              <Typography className={styles.title}>{VideoUploaderStrings.Title}</Typography>
              <Typography className={styles.subtitle}>{VideoUploaderStrings.Subtitle}</Typography>
            </Box>
            {isDragActive && (
              <Box className={styles.dragOver}>
                <FileIcon />
              </Box>
            )}
          </>
        )}
        {isLoading && userFile && (
          <Box className={styles.loadingWrapper}>
            <Typography className={styles.fileName}>{userFile.name}</Typography>
            <Typography className={styles.fileSize}>{getFileSizeInMb(userFile.size)} MB</Typography>
            {!isUploadError && (
              <div className={styles.progressWrapper}>
                <LinearProgressWithLabel value={uploadingProgress} className={styles.progress} />
              </div>
            )}
            {isUploadError && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  tryAgainHandler();
                }}
                className={styles.tryAgainButton}
              >
                Try again
              </Button>
            )}
            {uploadingProgress >= 100 && (
              <Box className={styles.completedIcon}>
                <CompletedIcon />
              </Box>
            )}
            {uploadingProgress < 100 && (
              <IconButton
                className={styles.deleteIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  cancelUploadingHandler();
                }}
              >
                <CancelIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Box>
    </div>
  );
};

export default VideoUploader;
