import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    whiteSpace: 'nowrap',
    padding: '10px 25px',
    margin: '16px 0',
    borderRadius: '100px',
  },
  buttonPrimary: {
    backgroundColor: '#5242EA',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4334D2',
    },
    '&:disabled': {
      backgroundColor: '#5242EA',
      opacity: '20%',
      color: '#fff',
    },
    '& svg': {
      color: '#fff',
    },
  },
  buttonSecond: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid #5242EA',
    color: '#5242EA',
    '&:hover': {
      backgroundColor: 'rgba(82, 66, 234, 0.04)',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '& svg': {
      color: '#5242EA',
    },
  },
  buttonError: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid red',
    color: 'red',
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '& svg': {
      color: 'red',
    },
  },
  buttonDark: {
    backgroundColor: '#1A1538',
    color: '#fff',

    '&:hover': {
      backgroundColor: '#1a1538cf',
      boxShadow: 'none',
    },

    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    '& svg': {
      color: '#fff',
    },
  },
}));
