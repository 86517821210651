import { FC } from 'react';

export const FolderIcon: FC = () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1184_1294"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="51"
      height="50"
    >
      <circle cx="25.75" cy="25" r="25" fill="#F1F1F1" />
    </mask>
    <g mask="url(#mask0_1184_1294)">
      <circle cx="25.75" cy="25" r="25" fill="#EEECFD" />
      <path
        d="M0.828613 17.0669L46.826 43.6235"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-4.65405 26.5632L41.3433 53.1198"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.82861 8.40665L51.826 34.9633"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3286 -1.11963L57.326 25.437"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 18.7614V38.4062C12.75 39.2347 13.4216 39.9062 14.25 39.9062H17.3828C18.2113 39.9062 18.8828 39.2347 18.8828 38.4062V18.7614C18.8828 17.933 18.2113 17.2614 17.3828 17.2614H14.25C13.4216 17.2614 12.75 17.933 12.75 18.7614Z"
        fill="#D9D4FF"
        stroke="#5242EA"
      />
      <path
        d="M15.8363 11.0938V35.5988H37.5604V15.2495L32.4116 10.0938H16.8363C16.284 10.0938 15.8363 10.5415 15.8363 11.0938Z"
        fill="white"
        stroke="#1A1538"
        strokeLinejoin="round"
      />
      <path
        d="M32.388 10.0938V15.2662H37.5604L32.388 10.0938Z"
        fill="#1A1538"
        stroke="#1A1538"
        strokeLinejoin="round"
      />
      <path d="M26.1811 15.2662H21.0087" stroke="#1A1538" strokeLinecap="round" />
      <path d="M32.388 19.9213H21.0087" stroke="#1A1538" strokeLinecap="round" />
      <path
        d="M17.1737 25.8107C17.37 25.1854 17.9495 24.7599 18.6049 24.7599H41.1482C41.9488 24.7599 42.5248 25.5288 42.2999 26.2971L38.6308 38.8273C38.4436 39.4666 37.8573 39.9058 37.1912 39.9058H14.3843C13.5741 39.9058 12.9968 39.1195 13.2394 38.3465L17.1737 25.8107Z"
        fill="#D9D4FF"
        stroke="#5242EA"
      />
      <path d="M35.5086 35.7679H30.3362" stroke="#5242EA" strokeLinecap="round" />
    </g>
  </svg>
);
