import { Box, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { BillingStrings } from '../../../../common/localization/en';
import { PricingPlanModel } from '../../../../api/models/pricingPlans';
import { useStyles } from './PricingPlanPreview.style';
import { SocialVenuLogo } from '../../../../common/assets/newDesign/Sidebar/SocialVenuLogo';
import { TrubluIcon } from '../../../../common/assets/companyLogo/TrubluIcon';
import { SubsectionIcon } from '../../../../common/assets/newDesign/SubsectionIcon';
import { BellIcon } from '../../../../common/assets/newDesign/BellIcon';

import { getShortPlanPeriod, getThousandsSeparatedVal } from '../../../../services/utilities';

interface PricingPlanPreviewProps {
  plan: PricingPlanModel;
  referralPlan: PricingPlanModel | null;
  isTrubluPlan: boolean;
  isEnterPrisePlan: boolean;
  isQuickSignup?: boolean;
  isCompleteProfile?: boolean;
}

const REFERRAL_PRICE_COLOR = '#3F9700';

export const PricingPlanPreview: React.FC<PricingPlanPreviewProps> = ({
  plan,
  referralPlan,
  isTrubluPlan,
  isEnterPrisePlan,
  isQuickSignup = false,
  isCompleteProfile = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const QuickSignupItems = [
    {
      title: BillingStrings.QuickSignupText,
      subtitle: 'Our Support team will reach out to follow up on completing your account profile.',
    },
  ];

  const SignupItems = [
    {
      title: BillingStrings.QuickSetup,
      subtitle: BillingStrings.MinutesToBeReady,
    },
    {
      title: BillingStrings.StartCollecting,
      subtitle: BillingStrings.SendSms,
    },
    {
      title: BillingStrings.ShowcaseContent,
      subtitle: BillingStrings.CurateTheBest,
    },
  ];

  const descriptionItems = isQuickSignup ? QuickSignupItems : SignupItems;

  const setupFeeOffset = useMemo(() => {
    return getShortPlanPeriod(plan) === BillingStrings.PerYear ? '44px' : '38px';
  }, [plan]);

  return (
    <Box className={classes.container}>
      {isTrubluPlan ? (
        <>
          <TrubluIcon width={250} height={205} />
        </>
      ) : (
        <SocialVenuLogo />
      )}
      <Typography className={classes.prefPricingText} display={isEnterPrisePlan ? 'none' : 'block'}>
        {/* {BillingStrings.PreferredPricing} */}
      </Typography>
      {!isCompleteProfile && (
        <Box
          py="40px"
          px="25px"
          borderRadius="15px"
          bgcolor={theme.palette.common.white}
          display={isEnterPrisePlan ? 'none' : 'flex'}
          flexDirection="column"
          width="532px"
        >
          <Typography
            color={theme.palette.common.deepDark}
            fontSize="28px"
            fontWeight="500"
            mb="26px"
            textAlign="center"
          >
            {BillingStrings.PricingPlan}
          </Typography>

          {isQuickSignup && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="flex-start"
              marginTop="-15px"
              marginBottom="15px"
            >
              <div
                style={{
                  borderRadius: '16px',
                  background: '#EFEEFC',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '4px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  height: '30px',
                }}
              >
                <Typography
                  color={theme.palette.primary.main}
                  fontSize="14px"
                  lineHeight="20px"
                  fontWeight="600"
                  mb="26px"
                  textAlign="center"
                >
                  {plan.setupFee === '$0' ? '50%' : '30%'} Percent Discount Applied
                </Typography>
              </div>
            </Box>
          )}

          <Box
            bgcolor={theme.palette.common.pageBackground}
            borderRadius="12px"
            py="14px"
            px="20px"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              width="100%"
              pr={setupFeeOffset}
            >
              <Box>
                <Typography
                  color={theme.palette.common.deepDark}
                  fontSize="20px"
                  fontWeight="500"
                  width="100%"
                >
                  {BillingStrings.SetupFee}
                </Typography>
                <Typography className={classes.planDescription}>
                  {BillingStrings.OneTimeFee}
                </Typography>
              </Box>
              <Typography
                fontSize="32px"
                fontWeight="500"
                sx={{
                  textDecorationLine: referralPlan ? 'line-through' : undefined,
                  color: theme.palette.common.deepDark,
                }}
              >
                {isQuickSignup && plan.setupFee === '$0'
                  ? 'Waived'
                  : getThousandsSeparatedVal(plan.setupFee)}
              </Typography>
            </Box>
            {referralPlan && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pr={setupFeeOffset}
              >
                <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="20px" mr="5px">
                  {BillingStrings.AfterPromoCode}
                </Typography>
                <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="32px">
                  {getThousandsSeparatedVal(referralPlan.setupFee)}
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              marginTop="10px"
            >
              <Box>
                <Typography
                  color={theme.palette.common.deepDark}
                  fontSize="20px"
                  fontWeight="500"
                  width="100%"
                >
                  {BillingStrings.SubscriptionFee}
                </Typography>
                <Typography className={classes.planDescription}>
                  {BillingStrings.OneYearCommitment}
                </Typography>
              </Box>
              <Box display="flex" alignItems="flex-end">
                <Typography
                  color={theme.palette.common.deepDark}
                  fontSize="32px"
                  fontWeight="500"
                  sx={{
                    textDecorationLine: referralPlan ? 'line-through' : undefined,
                  }}
                >
                  {getThousandsSeparatedVal(plan.subscriptionFee) || '$0'}
                </Typography>
                <Typography
                  component="span"
                  fontSize="20px"
                  color={theme.palette.common.gray}
                  sx={{
                    textDecorationLine: referralPlan ? 'line-through' : undefined,
                  }}
                >
                  {getShortPlanPeriod(plan)}
                </Typography>
              </Box>
            </Box>
            {referralPlan && (
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="20px" mr="5px">
                  {BillingStrings.AfterPromoCode}
                </Typography>
                <Box display="flex" alignItems="flex-end">
                  <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="32px">
                    {getThousandsSeparatedVal(referralPlan.subscriptionFee) || '$0'}
                  </Typography>
                  <Typography component="span" fontSize="20px" color={theme.palette.common.gray}>
                    {getShortPlanPeriod(referralPlan)}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box width="265px" marginTop="50px">
        {descriptionItems.map((item) => (
          <Box className={classes.sectionItem}>
            <Box className={classes.sectionTitleWrapper}>
              {isQuickSignup ? <BellIcon /> : <SubsectionIcon />}
              <Typography className={classes.sectionTitle}>{item.title}</Typography>
            </Box>
            <Typography className={classes.sectionDescription}>{item.subtitle}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
