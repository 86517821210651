import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';

import { RangeFilter } from '../../../store/storeModels';
import {
  createLabelFromDateRange,
  dateRangeFilterDefaultValues,
  DefaultWidth,
  predefinedFilterLabels,
  PredefinedFilters,
  useStyles,
} from './DateRangeFilter.helper';
import { DateRangeFilterStrings } from '../../localization/en';
import { backEndDateFormat, segmentActions } from '../../constants/constants';
import { segmentTrackCall } from '../../../utils/segment';
import { useTypedSelector } from '../../../store';
import { endOfDay, endOfYesterday, startOfDay, startOfYesterday } from 'date-fns';

export interface ReturnedDate {
  from: string;
  to: string;
}
interface DateRangeFilterProps {
  updateRange: (dateRange: ReturnedDate | null) => void;
  width?: string;
  noFormatDate?: boolean;
  initialValue?: null | any;
}

export const DateRangeFilter: FC<DateRangeFilterProps> = ({
  updateRange,
  width,
  noFormatDate,
  initialValue,
}) => {
  const now = new Date();
  const classes = useStyles({ initialValue });
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const [dateRange, setDateRange] = useState<RangeFilter>(dateRangeFilterDefaultValues.dateRange);
  const [chosenPredefinedFilter, setChosenPredefinedFilter] = useState<PredefinedFilters | null>(
    dateRangeFilterDefaultValues.chosenPredefinedFilter,
  );
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorElement);

  const resetFilter = () => {
    setDateRange(dateRangeFilterDefaultValues.dateRange);
    setChosenPredefinedFilter(dateRangeFilterDefaultValues.chosenPredefinedFilter);
  };
  useEffect(() => {
    if (initialValue === undefined) return;
    if (initialValue === null) resetFilter();
  }, [initialValue]);
  const applyRangeFilterChanges = () => {
    if (chosenPredefinedFilter === PredefinedFilters.SinceBeginning) {
      triggerUpdate();
    }
    if (!dateRange.from && !dateRange.to) {
      setDateRange({});
    }

    if (dateRange.from && dateRange.to) {
      const label = createLabelFromDateRange(dateRange.from, dateRange.to);
      setDateRange({ ...dateRange, label });
      triggerUpdate();
    }

    if (chosenPredefinedFilter === null && !dateRange.to) {
      resetFilter();
    }

    return handleClose();
  };

  const triggerUpdate = (reset?: boolean) => {
    if (chosenPredefinedFilter === PredefinedFilters.SinceBeginning || reset) {
      return updateRange(null);
    }
    if (!dateRange.from || !dateRange.to) {
      return;
    }
    const finalRange: ReturnedDate = {
      from: format(dateRange.from, backEndDateFormat),
      to: format(dateRange.to, backEndDateFormat),
    };
    if (noFormatDate) {
      updateRange({ from: dateRange.from.toString(), to: dateRange.to.toString() });
    } else {
      updateRange(finalRange);
    }
  };

  const setDateValue = (value: Array<Date | null | undefined>) => {
    segmentTrackCall(segmentActions.DateRange, venueId);
    setChosenPredefinedFilter(PredefinedFilters.Custom);
    return setDateRange({ from: value[0], to: value[1] || value[0] });
  };

  const getTextFieldLabel = (): string => {
    if (chosenPredefinedFilter !== null && chosenPredefinedFilter !== PredefinedFilters.Custom) {
      return predefinedFilterLabels[chosenPredefinedFilter];
    }
    if (dateRange.label) {
      return dateRange.label;
    }
    return '';
  };

  const choosePredefinedFilter = (value: PredefinedFilters) => {
    setChosenPredefinedFilter(value);
    switch (value) {
      case PredefinedFilters.SinceBeginning: {
        segmentTrackCall(segmentActions.SinceBeginning, venueId);
        setDateRange({});
        break;
      }
      case PredefinedFilters.Today: {
        segmentTrackCall(segmentActions.Today, venueId);
        const startOfToday = startOfDay(now);
        const endOfToday = endOfDay(now);

        setDateRange({
          from: startOfToday,
          to: endOfToday,
          label: createLabelFromDateRange(startOfToday, endOfToday),
        });
        break;
      }
      case PredefinedFilters.Yesterday: {
        segmentTrackCall(segmentActions.Yesterday, venueId);
        const startOfDayBeforeToday = startOfYesterday();
        const endOfDayBeforeToday = endOfYesterday();

        setDateRange({
          from: startOfDayBeforeToday,
          to: endOfDayBeforeToday,
          label: createLabelFromDateRange(startOfDayBeforeToday, endOfDayBeforeToday),
        });
        break;
      }
      case PredefinedFilters.LastWeek: {
        segmentTrackCall(segmentActions.LastWeek, venueId);
        const endOfLastWeek = endOfWeek(subWeeks(now, 1));
        const startOfLastWeek = startOfWeek(endOfLastWeek);
        setDateRange({
          from: startOfLastWeek,
          to: endOfLastWeek,
          label: createLabelFromDateRange(startOfLastWeek, endOfLastWeek),
        });
        break;
      }
      case PredefinedFilters.ThisMonth: {
        segmentTrackCall(segmentActions.ThisMonth, venueId);
        const endOfThisMonth = endOfMonth(now);
        const startOfThisMonth = startOfMonth(endOfThisMonth);
        setDateRange({
          from: startOfThisMonth,
          to: endOfThisMonth,
          label: createLabelFromDateRange(startOfThisMonth, endOfThisMonth),
        });
        break;
      }
      case PredefinedFilters.LastMonth: {
        segmentTrackCall(segmentActions.LastMonth, venueId);
        const endOfLastMonth = endOfMonth(subMonths(now, 1));
        const startOfLastMonth = startOfMonth(endOfLastMonth);
        setDateRange({
          from: startOfLastMonth,
          to: endOfLastMonth,
          label: createLabelFromDateRange(startOfLastMonth, endOfLastMonth),
        });
        break;
      }
    }
  };

  const handleResetFilterButton = () => {
    resetFilter();
    triggerUpdate(true);
  };

  const triggerPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    segmentTrackCall(segmentActions.DatePickerOpen, venueId);
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    segmentTrackCall(segmentActions.DatePickerClose, venueId);
    setAnchorElement(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className={classes.root} style={{ width: width || DefaultWidth }}>
        <TextField
          variant="outlined"
          className={classes.input}
          value={getTextFieldLabel()}
          onChange={() => {}}
          onClick={triggerPopup}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CalendarTodayIcon fontSize="small" color="inherit" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={(e) =>
                  chosenPredefinedFilter !== PredefinedFilters.SinceBeginning && e.stopPropagation()
                }
              >
                {chosenPredefinedFilter !== PredefinedFilters.SinceBeginning ? (
                  <CloseIcon
                    fontSize="small"
                    color="error"
                    onClick={handleResetFilterButton}
                    className={classes.resetButton}
                  />
                ) : (
                  <ExpandMoreIcon fontSize="small" color="inherit" />
                )}
              </InputAdornment>
            ),
          }}
          size="small"
          fullWidth
          disabled
        />
        <Popover
          open={open}
          anchorEl={anchorElement}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          classes={{ paper: classes.paperRoot }}
        >
          <Grid spacing={0} container direction="row" height="100%">
            <Grid item /* xs={12} md={3} */>
              <Box className={classes.filterContainer} padding={2}>
                <List className={classes.listContainer}>
                  {Object.keys(predefinedFilterLabels).map((item) => (
                    <ListItem
                      button
                      className={clsx({
                        [classes.activeListItem]: chosenPredefinedFilter === item,
                        [classes.listItem]: true,
                      })}
                      key={item as PredefinedFilters}
                      onClick={() => choosePredefinedFilter(item as PredefinedFilters)}
                      id={`${item}-date-range-button`}
                    >
                      <ListItemText
                        sx={{ fontSize: '14px', lineHeight: '26px' }}
                        primary={predefinedFilterLabels[item as PredefinedFilters]}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
            <Grid item /* md={1} */>
              <Divider className={classes.divider} orientation="vertical" flexItem />
            </Grid>
            <Grid item /* xs={12} md={8} */>
              <Grid container direction="column" className={classes.buttonsCalendarBlock}>
                <Grid item>
                  <Box>
                    <DesktopDateRangePicker
                      calendars={1}
                      open={open}
                      reduceAnimations
                      value={[dateRange.from, dateRange.to]}
                      onChange={(newValue) => setDateValue(newValue)}
                      renderInput={() => <></>}
                      PaperProps={{ elevation: 0 }}
                      showDaysOutsideCurrentMonth
                      PopperProps={{
                        className: 'desktop-date-range-picker',
                        style: { position: 'static' },
                        disablePortal: true,
                        anchorEl: this,
                        placement: undefined,
                        popperOptions: { strategy: 'absolute' },
                      }}
                      maxDate={new Date()}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box padding={2} className={classes.buttonsContainer}>
                    <Button
                      variant="text"
                      color="primary"
                      disableElevation
                      onClick={resetFilter}
                      id="date-reset-filter-button"
                    >
                      {DateRangeFilterStrings.Reset}
                    </Button>
                    <Button
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={applyRangeFilterChanges}
                      id="date-apply-filter-button"
                    >
                      {DateRangeFilterStrings.Apply}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
      </div>
    </LocalizationProvider>
  );
};
