import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={19}
    height={10}
    viewBox="0 0 19 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.585 1.807H4.713c-1.718 0-3.111 1.43-3.111 3.193 0 1.763 1.393 3.193 3.111 3.193h2.872v1.473H4.713C2.202 9.666.166 7.577.166 5 .166 2.422 2.202.333 4.713.333h2.872v1.474Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M7.585 1.807H4.713c-1.718 0-3.111 1.43-3.111 3.193 0 1.763 1.393 3.193 3.111 3.193h2.872v1.473H4.713C2.202 9.666.166 7.577.166 5 .166 2.422 2.202.333 4.713.333h2.872v1.474Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M14.286 1.807h-2.872V.333h2.872c2.51 0 4.547 2.09 4.547 4.667s-2.036 4.666-4.547 4.666h-2.872V8.193h2.872c1.718 0 3.11-1.43 3.11-3.193 0-1.764-1.392-3.193-3.11-3.193Z"
      fill={props.color || '#fff'}
    />
    <path
      d="M14.286 1.807h-2.872V.333h2.872c2.51 0 4.547 2.09 4.547 4.667s-2.036 4.666-4.547 4.666h-2.872V8.193h2.872c1.718 0 3.11-1.43 3.11-3.193 0-1.764-1.392-3.193-3.11-3.193Z"
      fill={props.color || '#fff'}
    />
    <path d="M13.328 5.737H5.67V4.263h7.658v1.474Z" fill={props.color || '#fff'} />
    <path d="M13.328 5.737H5.67V4.263h7.658v1.474Z" fill={props.color || '#fff'} />
  </svg>
);

export default SvgComponent;
