import { Box, Grid, Typography } from '@mui/material';
import { useSharedVideoStyles } from './SharedVideo.style';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { createSharesAnalyticsBlockData, SharesAnalyticsBlockModel } from './SharedVideo.helper';
import { FC, useEffect, useState } from 'react';
import _ from 'lodash';

type kpiNames =
  | 'SMS'
  | 'EMAIL'
  | 'FACEBOOK'
  | 'TWITTER'
  | 'LINK'
  | 'DOWNLOAD'
  | 'INSTAGRAM'
  | 'TIKTOK'
  | 'REELS'
  | 'REDDIT'
  | 'PINTEREST'
  | 'YOUTUBESHORTS';

export type SharesAnalyticsData = {
  [key in kpiNames]: number;
};

interface ISharedVideo {
  videoAnalyticsData?: SharesAnalyticsData;
}

export const SharedVideo: FC<ISharedVideo> = ({ videoAnalyticsData }) => {
  const [shares, setShares] = useState<SharesAnalyticsData | undefined>(undefined);
  useEffect(() => {
    setShares(videoAnalyticsData);
  }, [videoAnalyticsData]);
  const classes = useSharedVideoStyles();
  const isShared = shares && !_.isEmpty(shares) ? true : false;
  const sharesAnalyticsBlockData = isShared
    ? createSharesAnalyticsBlockData(shares as SharesAnalyticsData)
    : false;
  return (
    <Box className={classes.TotalShareContainer}>
      <Typography className={classes.TotalShareScore}>
        {sharesAnalyticsBlockData
          ? VideoDetailsModalStringsDv5.ShareTitle
          : 'This Video Was Not Shared Yet'}
      </Typography>
      <Grid container spacing={2} alignItems="baseline">
        {sharesAnalyticsBlockData &&
          (sharesAnalyticsBlockData as SharesAnalyticsBlockModel[]).map((share) => {
            if (!share.value || Number(share.value) === 0) return null;
            return (
              <Grid key={share.kpiName} item xs={2} className={classes.ShareIconContainer}>
                <Box className={classes.IconInfoContainer}>{share.icon}</Box>
                <Box className={classes.ShareInfoContainer}>
                  <Typography className={classes.ShareInfoText}>{share.label}</Typography>
                  {/* <Typography className={classes.ShareInfoValue}>{share.value}</Typography> */}
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
