import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useClasses = makeStyles<Theme>((theme) => ({
  root: {
    padding: '6px 13px',
    cursor: 'pointer',
    background: theme.palette.common.white,
    borderRadius: '10px',
    border: '1px solid #DCDBE4',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  active: {
    background:
      'linear-gradient(180deg, rgba(82, 66, 234, 0) 33.85%, rgba(82, 66, 234, 0.1) 100%), linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 89.06%)',
    border: '1px solid #5242EA',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabWrapper: {
    flex: 1,
    marginRight: '17px',
    '&:last-child': {
      marginRight: 0,
    },
    height: '74px',
  },
  tabText: {
    marginTop: '8px',
    paddingBottom: '4px',
    fontWeight: '400',
    fontSize: '9px',
    lineHeight: '1',
    textAlign: 'center',
    color: theme.palette.common.gray,
  },
  tabIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
