import { Box, Container, Divider, Grid, Link } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './Billing.style';
import { BillingStrings, CommonStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import { PaymentIcon } from '../../assets/newDesign/WebAppIcons/PaymentIcon';
import { AgreementsModal } from '../AgreementsModal/AgreementsModal';
import { getCustomerPortalSession } from '../../../store/slices/BillingSlice';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getShortPlanPeriod, redirectToPage } from '../../../services/utilities';
import { getServiceAgreement } from '../../../store/slices/venueSlice';
import { useClientType } from '../../../services/hooks/useClientType';

export const Billing: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isAgreementOpen, setIsAgreementOpen] = useState(false);
  const {
    venue: { id: venueId },
    serviceAgreement,
  } = useTypedSelector((state) => state.venue);

  const { isLoading, pricingPlan } = useTypedSelector((state) => state.BillingSlice);
  const { isHealthCare } = useClientType();

  const monthlyFee = useMemo(() => {
    return pricingPlan
      ? `${pricingPlan.subscriptionFee} ${getShortPlanPeriod(pricingPlan)}`
      : BillingStrings.PlanNotAvailable;
  }, [pricingPlan]);

  useEffect(() => {
    if (venueId) {
      dispatch(getServiceAgreement({ id: venueId }));
    }
  }, [venueId, dispatch]);

  const handleOpenAgreement = useCallback(() => {
    if (isHealthCare) {
      const url = window.URL.createObjectURL(serviceAgreement.value);
      redirectToPage(url, true);
    } else {
      setIsAgreementOpen(true);
    }
  }, [serviceAgreement.value, isHealthCare]);

  console.log('//todo', monthlyFee || handleOpenAgreement);

  const handleOpenPaymentInfo = async () => {
    dispatch(
      getCustomerPortalSession({
        payload: {
          venueId,
          successUrl: window.location.origin,
        },
      }),
    ).then((result) => {
      const url = result.payload.redirectUrl;
      if (url) {
        redirectToPage(url);
      }
    });
  };

  return (
    <Container className={classes.container}>
      <h2 className={classes.subheading}>{BillingStrings.Title}</h2>

      <AgreementsModal isOpen={isAgreementOpen} onClose={() => setIsAgreementOpen(false)} />
      <Box>
        {/* <Grid xs={6} className={classes.sectionWrapper}>
          <Box>
            <Typography className={classes.sectionTitle}>{BillingStrings.PlanOverview}</Typography>
            <Typography className={classes.sectionSubTitle}>{monthlyFee}</Typography>
          </Box>
          <RoundedButton
            title={BillingStrings.OpenAgreement}
            outlined={true}
            className={classes.btnOpen}
            onClick={handleOpenAgreement}
          />
        </Grid> */}
        {/* <Divider
          orientation="vertical"
          sx={{
            height: '192px',
          }}
        /> */}
        <Grid xs={6} className={classes.sectionWrapper}>
          {/* <Typography className={classes.sectionTitle}>
            {BillingStrings.PaymentInformation}
          </Typography> */}
          <RoundedButton
            title={BillingStrings.AccessPaymentInfo}
            outlined={true}
            className={classes.btnPayment}
            startIcon={<PaymentIcon />}
            onClick={handleOpenPaymentInfo}
            loading={isLoading}
          />
        </Grid>
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.footerPanel}>
          <Link href="#" className={classes.footerLink} underline="hover">
            {CommonStrings.AllRightsReserved}
          </Link>
          <Divider orientation="vertical" />
          <Link href="#" className={classes.footerLink} underline="hover">
            {CommonStrings.TermsOfUse}
          </Link>
          <Divider orientation="vertical" />
          <Link href="#" className={classes.footerLink} underline="hover">
            {CommonStrings.PrivacyPolicy}
          </Link>
        </Box>
      </Box>
    </Container>
  );
};
