import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import * as Yup from 'yup';
import { CommonStrings, ErrorsStrings } from '../../../../../../common/localization/en';
export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  modalRoot: {
    width: '499px',
    padding: '40px 36px',
  },
  input: {
    background: theme.palette.common.white,
    borderRadius: '5px !important',
    '& >div': {
      borderRadius: '5px !important',
    },
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '-28px',
    color: theme.palette.error.main,
    fontSize: '12px',
    lineHeight: '26px',
  },
  modalTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
  },
  modalLabel: {
    marginTop: '8px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
  },
  modalAgreement: {
    marginLeft: '20px',
    '& p': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0.02em',
      color: theme.palette.common.gray,
    },
  },
  modalBtn: {
    borderRadius: '8px',
    marginTop: '24px',
    width: '60%',
    '&:disabled': {
      background: 'rgba(82, 66, 234, 0.3)',
    },
  },
  infoWrapper: {
    margin: '0 auto',
    width: 'fit-content',
    height: '46px',
    background: '#F0FFF0',
    marginTop: '23px',
    borderRadius: '8px',
    border: '1px solid #2EB380',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    marginTop: '8px',
  },

  usersCount: {
    fontWeight: 700,
    fontSize: '20px',
    color: '#027A48',
    margin: '0 3px 0 10px',
  },
  usersText: {
    fontWeight: 400,
    fontSize: '20px',
    color: '#027A48',
  },
}));

export const MAX_LENGTH = 50;

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(4, 'Name must be at least 4 characters length')
      .max(MAX_LENGTH, CommonStrings.ExceededCharLimit)
      .required(ErrorsStrings.FieldIsRequired),
    agreement: Yup.boolean().oneOf([true]).required(),
  });
