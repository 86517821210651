import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSharedVideoStyles = makeStyles((theme: Theme) => ({
  IconInfoContainer: {
    flexShrink: 0,
    width: 36,
    height: 36,
    marginBottom: 4,
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  ShareIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ShareInfoContainer: {
    // paddingLeft: 12,
  },
  ShareInfoText: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
    textAlign: 'center',
  },
  ShareInfoValue: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  TotalShareContainer: {
    background: '#F5F6F8',
    borderRadius: '12px',
    padding: 20,
    marginBottom: 26,
    marginLeft: '20px',
    marginRight: '20px',
  },

  TotalShareScore: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 16,
  },
  TotalShareScoreValue: {
    marginLeft: 16,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
}));
