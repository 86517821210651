import { Box, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { VenueManagerApiModel } from '../../../api/models/venueManagers';
import { useAppDispatch } from '../../../store';
import {
  disableVenueMemberSMSNotification,
  enableVenueMemberSMSNotification,
} from '../../../store/slices/venueManagerSlice';
import { AdminVenueManagementStrings } from '../../localization/en';
import { ActivateNotificationSwitch } from '../SwitchIconed/ActivateNotificationSwitch';

import { ConfirmationModal } from './ConfirmationModal';
import { OneOnlyNotifiedModal } from './OneOnlyNotifiedModal';
import { PhoneAlertModal } from './PhoneAlertModal';
import { useVideoNotificationsStyles } from './VideoNotifications.styles';
import { AccountEventNames } from '../../constants/events/accountEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

interface VideoNotificationsProps {
  activeManager: VenueManagerApiModel;
  managers?: VenueManagerApiModel[];
  venueId: string;
  isSuperAdmin: boolean;
  isMyId: boolean;
}

export const VideoNotifications: FC<VideoNotificationsProps> = ({
  activeManager,
  managers,
  venueId,
  isSuperAdmin,
  isMyId,
}) => {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const { addToast } = useToasts();
  const styles = useVideoNotificationsStyles();
  const videoAlerts = activeManager.videoAlerts;

  const [onlyWithPhoneNumbers, setOnlyWithPhoneNumber] = useState(false);

  const isOneNotified = managers?.find((manager) => manager.videoAlerts);

  const [onlyOneNotified, setOnlyOneOwnerNotified] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);

  let isDisabled = true;
  if (isSuperAdmin) {
    isDisabled = false;
  } else if (isMyId) {
    isDisabled = false;
  }

  const enableNotifications = async () => {
    trackEvent(AccountEventNames.memberNotificationEnabled, {
      memberId: activeManager.id,
    });
    await dispatch(enableVenueMemberSMSNotification({ venueId, id: activeManager.id }))
      .then((data) => {
        if (data.meta.requestStatus !== 'rejected') {
          addToast(AdminVenueManagementStrings.NotificationToast, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        }
      })
      .finally(() => {
        setConfirmationModal(false);
      });
  };
  const disableNotifications = async () => {
    trackEvent(AccountEventNames.memberNotificationDisabled, {
      memberId: activeManager.id,
    });
    await dispatch(disableVenueMemberSMSNotification({ venueId, id: activeManager.id }))
      .then((data) => {
        if (data.meta.requestStatus !== 'rejected') {
          addToast(AdminVenueManagementStrings.CancelNotificationToast, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        }
      })
      .finally(() => {
        setConfirmationModal(false);
      });
  };

  const handleChangeVideoNotifications = (checked: boolean) => {
    trackEvent(AccountEventNames.notificationAlertChangeClicked, {
      value: checked.toString(),
    });
    if (!activeManager.phoneNumber) {
      setOnlyWithPhoneNumber(true);
    } else if (isOneNotified && checked) {
      setOnlyOneOwnerNotified(true);
    } else {
      if (checked) {
        setConfirmationModal(true);
      } else {
        disableNotifications();
      }
    }
  };

  const handleClose = () => {
    setConfirmationModal(false);
  };

  return (
    <>
      <Box display="flex">
        <Box sx={{ pr: '8px', display: 'flex', alignItems: 'center' }}>
          <Typography className={styles.Text}>
            {AdminVenueManagementStrings.VideoCreationAlert}
          </Typography>
        </Box>
        <Box>
          <ActivateNotificationSwitch
            isChecked={videoAlerts ? videoAlerts : false}
            onChange={handleChangeVideoNotifications}
            disabled={isDisabled}
          />
        </Box>
      </Box>
      <ConfirmationModal
        isOpenModal={confirmationModal}
        closeModal={handleClose}
        phone={activeManager.phoneNumber}
        enableNotifications={enableNotifications}
        name={activeManager.name}
      />
      <PhoneAlertModal
        isOpenModal={onlyWithPhoneNumbers}
        closeModal={() => {
          setOnlyWithPhoneNumber(false);
        }}
      />
      <OneOnlyNotifiedModal
        isOpenModal={onlyOneNotified}
        closeModal={() => {
          setOnlyOneOwnerNotified(false);
        }}
      />
    </>
  );
};
