import { FC, useRef, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './InvitesBlock.styles';
import { InviteBlockStrings } from '../../localization/en';
import { InvitesBlockCopyUrl } from '../InviteBlokCopyUrl/InvitesBlockCopyUrl';
import { QRCode } from '../QrCode/QRCode';
import { useWebAppLink } from '../../../hooks/useWebAppLink';
import { QrCodeType } from '../../../api/models/venue';
import { useTypedSelector } from '../../../store';
import { DropDownIcon } from '../../assets/DropDownIcon';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { RoundedButton } from '../Button/RoundedButton';
import { CustomTooltip } from '../../../pages/rewards/CampaignSummary/CampaignSummary.helper';

interface Props {
  campaignId: string;
}

export const InvitesBlock: FC<Props> = ({ campaignId }) => {
  const styles = useStyles();
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);

  const { webAppLink } = useWebAppLink({ queryParams: { cid: campaignId } });

  const [qrCodeTypeSelected, setQrCodeTypeSelected] = useState(QrCodeType.Branded);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [downloadTrigger, setDownloadTrigger] = useState(0);

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      setIsSelectOpen(false);
    },
  });

  const {
    venue: { logo },
  } = useTypedSelector((state) => state.venue);
  const selectRef = useRef<HTMLDivElement>(null);

  const venueLogo = venueProperties?.['dashboard.QRCode.config']?.logo || logo;
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const isBranded = qrCodeTypeSelected === QrCodeType.Branded;
  const brandColor = isBranded ? venueBrandColor : '#000';

  const imageUrl = isBranded ? venueLogo : '';

  const qrTypeMap = {
    [QrCodeType.Branded]: InviteBlockStrings.Branded,
    [QrCodeType.Default]: InviteBlockStrings.Default,
  };

  return (
    <Box className={styles.container} ref={selectRef}>
      <Typography className={styles.titleText}>{InviteBlockStrings.Title}</Typography>
      <InvitesBlockCopyUrl webAppLink={webAppLink} />
      <Box className={styles.divider} />
      <Typography className={styles.downloadQrText}>{InviteBlockStrings.DownloadQrCode}</Typography>
      <Typography className={styles.scanCodeText}>{InviteBlockStrings.ScanningCode}</Typography>
      <Box className={styles.qrWrapper}>
        <Box className={styles.qrCode}>
          <QRCode
            content={`${webAppLink}&qrcode=true`}
            color={brandColor}
            logo={imageUrl}
            withDownloadButton={false}
            size={118}
            padding="0"
            qrMargin={0.1}
            logoBorderWidth="10px"
            downloadTrigger={downloadTrigger}
          />
        </Box>
        <Box className={styles.selectSection}>
          <Typography className={styles.selectStyleText}>
            {InviteBlockStrings.SelectStyle}
          </Typography>
          <Box className={styles.selectWrapper} ref={refDetectClickOutside}>
            <Button
              className={styles.selectButton}
              variant="outlined"
              endIcon={<DropDownIcon />}
              onClick={() => {
                setIsSelectOpen((prevState) => !prevState);
              }}
            >
              {qrTypeMap[qrCodeTypeSelected]}
            </Button>
            <Box
              className={styles.selectItemsWrapper}
              style={{
                display: isSelectOpen ? 'flex' : 'none',
              }}
            >
              {[QrCodeType.Branded, QrCodeType.Default].map((type) => (
                <Button
                  key={type}
                  className={styles.selectItem}
                  onClick={() => {
                    setQrCodeTypeSelected(type);
                    setIsSelectOpen(false);
                  }}
                >
                  {qrTypeMap[type]}
                </Button>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={styles.downloadBtnWrapper}>
        <CustomTooltip
          title={
            <Box padding={'13px 15px'}>
              <Typography
                color="inherit"
                style={{ fontSize: '11px', fontWeight: '700', textAlign: 'left' }}
              >
                {InviteBlockStrings.InvitaionContactTooltipTitle}
              </Typography>
              <Typography
                color="inherit"
                style={{
                  textAlign: 'left',
                  fontSize: '11px',
                  fontWeight: '500',
                  marginTop: '4px',
                }}
              >
                {InviteBlockStrings.InvitaionContactTooltipSubTitle}
                <span
                  color="inherit"
                  style={{
                    textAlign: 'left',
                    fontSize: '11px',
                    fontWeight: '400',
                    textDecoration: 'underline',
                  }}
                >
                  {InviteBlockStrings.InvitaionContactTooltipSubTitlePhone}
                </span>
              </Typography>
            </Box>
          }
          icon={
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#475467E5',
              }}
            >
              {InviteBlockStrings.InvitaionContact}
            </Typography>
          }
        />

        <RoundedButton
          title={InviteBlockStrings.Download}
          outlined={true}
          onClick={() => {
            setDownloadTrigger((prev) => prev + 1);
          }}
          className={styles.btnDownload}
        />
      </Box>
    </Box>
  );
};
