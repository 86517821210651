import { FC } from 'react';

interface Props {
  color?: string;
}

export const CrispChatIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.629 24.8836L37.6385 24.9402L38.2914 25.031C39.5446 25.2053 40.7086 24.3778 40.8914 23.1829L41.9574 16.2113C42.1401 15.0164 41.2724 13.9065 40.0192 13.7323L38.5682 13.5305C36.854 5.85423 29.8127 0.103668 21.3867 0.103668C12.959 0.103668 5.91647 5.85659 4.20417 13.5351L2.49351 13.7147C1.23471 13.8468 0.32658 14.927 0.465137 16.1272L1.2736 23.1303C1.41216 24.3305 2.54496 25.1965 3.80377 25.0643L5.45024 24.8916L5.45002 24.8836L7.66444 24.4825C6.42375 22.3114 5.71754 19.8144 5.71754 17.1581C5.71754 8.78576 12.7328 1.99861 21.3867 1.99861C30.0405 1.99861 37.0557 8.78576 37.0557 17.1581C37.0557 19.794 36.3604 22.2727 35.1375 24.4321L37.629 24.8836ZM34.457 16.6479C34.457 18.9352 33.8109 21.0786 32.683 22.9212C29.3351 24.0501 24.7498 24.4522 24.0113 24.5113C23.8112 24.1272 23.3956 23.8632 22.9153 23.8632H19.8579C19.1825 23.8632 18.635 24.3854 18.635 25.0294V25.1022C18.635 25.7463 19.1825 26.2684 19.8579 26.2684H22.9153C23.4893 26.2684 23.9709 25.8914 24.1026 25.3829C24.9343 25.3323 28.3178 25.0833 31.7721 24.2159C29.3836 27.1915 25.6201 29.1107 21.3867 29.1107C20.9248 29.1107 20.4686 29.0879 20.019 29.0433L14.8646 31.4634L14.5748 27.2864C10.8211 25.0965 8.31632 21.1512 8.31632 16.6479C8.31632 9.7649 14.1681 4.18507 21.3867 4.18507C28.6052 4.18507 34.457 9.7649 34.457 16.6479Z"
        fill={color || 'white'}
      />
      <path
        d="M14.8656 13.2387C15.5222 13.2387 16.0678 13.4021 16.5021 13.7289C16.9365 14.0557 17.2295 14.4989 17.381 15.0584H16.4643C16.338 14.7415 16.1359 14.4915 15.8581 14.3083C15.5854 14.1201 15.2495 14.026 14.8505 14.026C14.5323 14.026 14.2469 14.1028 13.9943 14.2563C13.7468 14.4098 13.5498 14.6301 13.4034 14.9173C13.2619 15.1995 13.1912 15.5313 13.1912 15.9126C13.1912 16.2889 13.2619 16.6206 13.4034 16.9078C13.5498 17.1901 13.7468 17.4079 13.9943 17.5614C14.2469 17.7149 14.5323 17.7917 14.8505 17.7917C15.2495 17.7917 15.5854 17.7001 15.8581 17.5169C16.1359 17.3287 16.338 17.0786 16.4643 16.7667H17.381C17.2295 17.3262 16.9365 17.7694 16.5021 18.0962C16.0678 18.418 15.5222 18.579 14.8656 18.579C14.3605 18.579 13.9135 18.4676 13.5246 18.2447C13.1356 18.017 12.8351 17.7025 12.623 17.3015C12.4108 16.8954 12.3048 16.4325 12.3048 15.9126C12.3048 15.3926 12.4108 14.9322 12.623 14.5311C12.8351 14.1251 13.1356 13.8082 13.5246 13.5804C13.9135 13.3526 14.3605 13.2387 14.8656 13.2387Z"
        fill="black"
      />
      <path
        d="M20.4619 14.3751C20.9468 14.3751 21.3357 14.5286 21.6286 14.8356C21.9216 15.1426 22.0681 15.5808 22.0681 16.1502V18.5344H21.2044V16.2394C21.2044 15.8729 21.1084 15.5907 20.9165 15.3926C20.7245 15.1896 20.4619 15.0881 20.1285 15.0881C19.785 15.0881 19.5097 15.1971 19.3027 15.4149C19.0956 15.6278 18.992 15.9423 18.992 16.3582V18.5344H18.1283V13.0382H18.992V15.3407C19.1031 15.0337 19.29 14.796 19.5527 14.6276C19.8153 14.4593 20.1184 14.3751 20.4619 14.3751Z"
        fill="black"
      />
      <path
        d="M24.6381 14.39C25.0169 14.39 25.3402 14.4816 25.6079 14.6648C25.8756 14.848 26.065 15.0906 26.1761 15.3926V14.442H27.0399V18.5344H26.1761V17.5837C26.065 17.8857 25.8756 18.1284 25.6079 18.3116C25.3402 18.4948 25.0169 18.5864 24.6381 18.5864C24.2744 18.5864 23.9486 18.5022 23.6607 18.3339C23.3779 18.1655 23.1556 17.9229 22.994 17.606C22.8324 17.2891 22.7516 16.9152 22.7516 16.4845C22.7516 16.0586 22.8324 15.6873 22.994 15.3704C23.1556 15.0535 23.3779 14.8108 23.6607 14.6425C23.9486 14.4741 24.2744 14.39 24.6381 14.39ZM24.9033 15.1327C24.5194 15.1327 24.2113 15.254 23.9789 15.4966C23.7517 15.7343 23.638 16.0636 23.638 16.4845C23.638 16.9053 23.7517 17.2371 23.9789 17.4797C24.2113 17.7174 24.5194 17.8362 24.9033 17.8362C25.1457 17.8362 25.3629 17.7818 25.5549 17.6728C25.7468 17.5589 25.8983 17.4005 26.0095 17.1975C26.1206 16.9945 26.1761 16.7568 26.1761 16.4845C26.1761 16.2171 26.1206 15.9819 26.0095 15.7789C25.8983 15.5709 25.7468 15.4125 25.5549 15.3035C25.3629 15.1896 25.1457 15.1327 24.9033 15.1327Z"
        fill="black"
      />
      <path
        d="M30.0606 17.7991V18.5344H29.5302C29.0857 18.5344 28.7473 18.4304 28.515 18.2225C28.2877 18.0095 28.174 17.658 28.174 17.1678V15.1624H27.5755V14.442H28.174V13.4244H29.0453V14.442H30.053V15.1624H29.0453V17.1826C29.0453 17.4154 29.0882 17.5763 29.1741 17.6654C29.265 17.7545 29.4216 17.7991 29.6439 17.7991H30.0606Z"
        fill="black"
      />
    </svg>
  );
};
