import { FC, MouseEvent } from 'react';
import { Dialog, DialogContent, IconButton, Card, CardActionArea } from '@mui/material';
import { usePlaybackVideoModal } from './components/VideoPlaybackModal.style';
import { CloseIcomModal } from '../../../assets/newDesign/CloseIconModal';
import { PlayerControls } from '../../VideoPlayer/PlayerControls';

interface VideoPlaybackModalProps {
  isOpen: boolean;
  videoUrl: string;
  isTopGolf: boolean;
  onClose: () => void;
  videoId?: string;
  entity?: 'heroes' | 'campaigns';
  thumbnailUrl?: string;
}
export const VideoPlaybackModal: FC<VideoPlaybackModalProps> = ({
  isOpen,
  videoUrl,
  isTopGolf,
  onClose,
  videoId,
  entity,
  thumbnailUrl,
}) => {
  const classes = usePlaybackVideoModal();
  const handleCloseModal = () => {
    onClose();
  };
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleCloseModal}
      open={isOpen}
      onMouseUp={(e: MouseEvent) => e.stopPropagation()}
    >
      <IconButton
        className={classes.closeModal}
        type="button"
        aria-label="Close modal"
        onClick={handleCloseModal}
        size="large"
      >
        <CloseIcomModal />
      </IconButton>
      <DialogContent classes={{ root: classes.dialogText }}>
        <Card className={isTopGolf ? classes.TopGolfVideoCard : classes.VideoCard}>
          <CardActionArea className={classes.actionArea}>
            <PlayerControls
              isTopgolf={isTopGolf}
              videoUrl={videoUrl}
              videoId={videoId}
              entity={entity}
              thumbnailUrl={thumbnailUrl}
            />
          </CardActionArea>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
