import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './RewardCardPreview.styles';
import RewardCardPreviewBackground from '../../assets/RewardCardPreviewBackground';
import { useTypedSelector } from '../../../store';
import { HowToRewardCreatorsStrings } from '../../localization/en';
import {
  ContactMethod,
  colorPalette,
} from '../../../pages/NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import { getRewardCardFontSize } from './RewardCardPreview.helpers';
import { format } from 'date-fns';

interface Props {
  cardColor?: string;
  contact: string | null;
  rewardTitle: string;
  rewardHeader: string;
  logo: string;
  contactMethod?: string;
}

const RewardCardPreview: FC<Props> = ({
  rewardHeader,
  rewardTitle,
  cardColor,
  logo,
  contact,
  contactMethod,
}) => {
  const styles = useStyles();

  const {
    venue: { name },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const defaultCardColor =
    colorPalette.flat().find((color) => color === venueBrandColor) || '#D1D1D1';

  const { name: clientName } = useTypedSelector((state) => state.me);

  return (
    <Box className={styles.container}>
      <RewardCardPreviewBackground fill={cardColor || defaultCardColor} />
      <Box className={styles.contentWrapper}>
        {logo ? (
          <img src={logo} alt="company logo" className={styles.companyLogo} />
        ) : (
          <Box sx={{ marginTop: '80px' }} />
        )}
        <Typography
          className={styles.headerText}
          sx={{ fontSize: getRewardCardFontSize(rewardHeader) }}
        >
          {rewardHeader}
        </Typography>
        <Typography className={styles.customerName} sx={{ marginTop: '-8px' }}>
          {clientName.split(' ')[0]}!
        </Typography>
        <Typography
          className={styles.rewardTitle}
          sx={{ fontSize: getRewardCardFontSize(rewardTitle) }}
        >
          {rewardTitle}
        </Typography>
        <Box className={styles.infoWrapper}>
          <Typography className={styles.companyName}>{name}</Typography>
          <Typography className={styles.infoText}>
            {(contactMethod && contactMethod !== ContactMethod.None) || contact ? (
              <Box className={styles.contactWrapper}>
                <Typography className={styles.contactHeader}>
                  {HowToRewardCreatorsStrings.RedeemText}
                </Typography>
                <Typography className={styles.contact}>{contact}</Typography>
              </Box>
            ) : (
              <div style={{ height: '48px' }}></div>
            )}
          </Typography>
          <Typography className={styles.infoText}>{format(new Date(), 'P')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RewardCardPreview;
