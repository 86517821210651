import { FC } from 'react';

export const VideoCardIcons: FC = () => (
  <svg width="17" height="46" viewBox="0 0 17 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <g opacity="0.8" filter="url(#filter0_b_2073_45099)">
        <path
          d="M14.6187 0.0876465H2.17426C1.19204 0.0876465 0.396484 0.83348 0.396484 1.75431V11.7543C0.396484 12.6751 1.19204 13.421 2.17426 13.421H14.6187C15.6009 13.421 16.3965 12.6751 16.3965 11.7543V1.75431C16.3965 0.83348 15.6009 0.0876465 14.6187 0.0876465ZM7.5076 5.92098H6.17426V5.50431H4.39648V8.00431H6.17426V7.58765H7.5076V8.42098C7.5076 8.64199 7.41394 8.85396 7.24725 9.01024C7.08055 9.16652 6.85445 9.25431 6.61871 9.25431H3.95204C3.71629 9.25431 3.4902 9.16652 3.3235 9.01024C3.1568 8.85396 3.06315 8.64199 3.06315 8.42098V5.08765C3.06315 4.86663 3.1568 4.65467 3.3235 4.49839C3.4902 4.34211 3.71629 4.25431 3.95204 4.25431H6.61871C6.85445 4.25431 7.08055 4.34211 7.24725 4.49839C7.41394 4.65467 7.5076 4.86663 7.5076 5.08765V5.92098ZM13.7298 5.92098H12.3965V5.50431H10.6187V8.00431H12.3965V7.58765H13.7298V8.42098C13.7298 8.64199 13.6362 8.85396 13.4695 9.01024C13.3028 9.16652 13.0767 9.25431 12.8409 9.25431H10.1743C9.93851 9.25431 9.71242 9.16652 9.54572 9.01024C9.37902 8.85396 9.28537 8.64199 9.28537 8.42098V5.08765C9.28537 4.86663 9.37902 4.65467 9.54572 4.49839C9.71242 4.34211 9.93851 4.25431 10.1743 4.25431H12.8409C13.0767 4.25431 13.3028 4.34211 13.4695 4.49839C13.6362 4.65467 13.7298 4.86663 13.7298 5.08765V5.92098Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_b_2073_45099"
        x="-15.6035"
        y="-16"
        width="48"
        height="45.4209"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2073_45099" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2073_45099"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
