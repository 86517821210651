import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_3_87"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="70"
      height="71"
    >
      <circle cx="35" cy="35.5872" r="35" fill="#EEECFC" />
    </mask>
    <g mask="url(#mask0_3_87)">
      <circle cx="35" cy="35.5872" r="35" fill="#EEECFC" />
      <path
        d="M0 43.0872L69.9999 43.0872"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 58.0872L69.9999 58.0872"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 28.0872L69.9999 28.0872"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 13.0872L69.9999 13.0872"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="34.5" cy="36.5" r="20.5" fill="white" stroke="#D9D4FF" strokeWidth="4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.007 28.4995H40.9922V31.5119V34.4754C40.9922 37.7744 38.3025 40.4489 34.9996 40.4489C31.69 40.4489 29.007 37.7828 29.007 34.4678V31.4819V28.4995Z"
        stroke="#5242EA"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M34.9996 42.1195V46.1145" stroke="#5242EA" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M31.0045 46.1145H38.9947" stroke="#5242EA" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M40.9922 30.4911C40.9922 30.4911 43.4891 30.4911 43.9885 30.4911C44.4879 30.4911 44.9873 30.989 44.9873 31.4869C44.9873 31.9847 45.4696 36.4657 40.4758 36.4657"
        stroke="#5242EA"
        strokeWidth="1.5"
      />
      <path
        d="M29.007 30.4911C29.007 30.4911 26.5101 30.4911 26.0107 30.4911C25.5113 30.4911 25.0119 30.989 25.0119 31.4869C25.0119 31.9847 24.5387 36.4657 29.5325 36.4657"
        stroke="#5242EA"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);
export default SvgComponent;
