import { FC } from 'react';
import { Box, CardMedia } from '@mui/material';
import { useStyles } from './VideoDownloadCard.helper';

interface VideoDownloadCardProps {
  url: string;
}

export const VideoDownloadCard: FC<VideoDownloadCardProps> = ({ url }) => {
  const classes = useStyles();

  return (
    <Box position="relative" marginBottom={3}>
      <CardMedia className={classes.video} component="video" src={url} controls />
    </Box>
  );
};
