import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import me from './slices/meSlice';
import heroes from './slices/heroesSlice';
import campaigns from './slices/campaignsSlice';
import campaignsStoryVideos from './slices/campaignsStoryVideosSlice';
import videos from './slices/videosSlice';
import venue from './slices/venueSlice';
import ui from './slices/uiSlice';
import userDetails from './slices/userDetailsSlice';
import analytics from './slices/analyticsSlice';
import rewards from './slices/rewardsSlice';
import venueCreation from './slices/venueCreationSlice';
import filtersSlice from './slices/filtersSlice';
import VenueManagerSlice from './slices/venueManagerSlice';
import OrganizationVenueSlice from './slices/organizationVenueSlice';
import AlertSlice from './slices/alertSlice';
import TopgGolfVideos from './slices/topGolfVideosSlice';
import SearchFilterSlice from './slices/searchFiltersSlice';
import VenueNewManagerInputSlice from './slices/VenueNewManagerInputSlice';
import BillingSlice from './slices/BillingSlice';
import SocialVerseSlice from './slices/socialVerseSlice';
import selectedRows from './slices/selectedRowsSlice';
import KeywordsSlice from './slices/keywordsSlice';
import smsInvitesSlice from './slices/smsInvitesSlice';
import dataSyncSlice from './slices/dataSyncSlice';
import rewardFulfillment from './slices/rewardFulfillmentSlice';
import affiliates from './slices/affiliatesSlice';
import distributors from './slices/distributorsSlice';
import pricingPlans from './slices/pricingPlansSlice';
import createCampaign from './slices/createCampaignSlice';
import uploadVideo from './slices/uploadVideoSlice';
import gpbSlice from './slices/gbpSlice';

const store = configureStore({
  reducer: {
    me,
    heroes,
    campaigns,
    campaignsStoryVideos,
    videos,
    venue,
    ui,
    userDetails,
    analytics,
    rewards,
    venueCreation,
    filtersSlice,
    VenueManagerSlice,
    OrganizationVenueSlice,
    AlertSlice,
    TopgGolfVideos,
    SearchFilterSlice,
    VenueNewManagerInputSlice,
    BillingSlice,
    SocialVerseSlice,
    selectedRows,
    KeywordsSlice,
    smsInvitesSlice,
    dataSyncSlice,
    rewardFulfillment,
    affiliates,
    distributors,
    pricingPlans,
    createCampaign,
    uploadVideo,
    gpbSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ['venue.serviceAgreement.value'],
        ignoredActions: ['venue/getServiceAgreement/fulfilled'],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
