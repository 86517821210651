import { FC } from 'react';
import { FSModal } from '../../modal/Modal';
import { useStyles } from './ConcentModal.styles';
import { Box, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../../assets/CloseIcon';
import { ConfirmIcon } from '../../../assets/ConfirmIcon';
import { DistributorsStrings } from '../../../localization/en';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ConsentModal: FC<Props> = ({ isOpen, onClose }) => {
  const styles = useStyles();

  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur={true}
      width="497px"
      padding="0 5px"
    >
      <Box className={styles.iconWrapper}>
        <IconButton onClick={onClose} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={styles.container}>
        <ConfirmIcon />
        <Typography className={styles.title}>{DistributorsStrings.ThankForConsent}</Typography>
      </Box>
    </FSModal>
  );
};
