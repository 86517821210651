import { ReactNode } from 'react';
import { RewardStrings } from '../../../common/localization/en';
import VideoStyleListInspirationalIcon from '../../../common/assets/QuickStartIcons/VideoStyleListInspirationalIcon';
import VideoStyleListExperientialIcon from '../../../common/assets/QuickStartIcons/VideoStyleListExperientialIcon';
import VideoStyleListFunnyIcon from '../../../common/assets/QuickStartIcons/VideoStyleListFunnyIcon';

import VideoTypeMarketingIconActive from '../../../common/assets/QuickStartIcons/VideoTypeMarketingIconActive';
import VideoTypeWebsiteSEOIconActive from '../../../common/assets/QuickStartIcons/VideoTypeWebsiteSEOIconActive';
import VideoTypeFriendsAndFamilyIconActive from '../../../common/assets/QuickStartIcons/VideoTypeFriendsAndFamilyIconActive';
import { AnimatedDiagramVideoContent } from './AnimatedDiagramVideoContentIcon';
import { AnimatedDiagramWebsiteSEO } from './AnimatedDiagramWebsiteSEO';
import { AnimatedDiagramFamilyFriends } from './AnimatedDiagramFamilyFriends';
import DiagramFamilyFriendsIcon from '../../../common/assets/QuickStartIcons/DiagramFamilyFriendsIcon';
import DiagramWebsiteSEOIcon from '../../../common/assets/QuickStartIcons/DiagramWebsiteSEOIcon';
import DiagramVideoContentIcon from '../../../common/assets/QuickStartIcons/DiagramVideoContentIcon';
import { CustomerActivitiesType } from '../../../api/models/campaigns';

export type descriptionListType = { title: string; description: string; icon: ReactNode }[];

export type previewContentType = {
  title: string;
  subTitle: string;
  diagramLegendTitle: string;
  description: string;
  descriptionList: descriptionListType;
  diagramIcon: ReactNode;
};

export const videoStyleContent: previewContentType = {
  title: RewardStrings.WelcomeVideoExplained,
  subTitle: RewardStrings.VideoStylesSubTitle,
  diagramIcon: <></>,
  diagramLegendTitle: '',
  description: '',
  descriptionList: [
    {
      title: RewardStrings.Inspirational,
      description: RewardStrings.InspirationalDescription,
      icon: <VideoStyleListInspirationalIcon />,
    },
    {
      title: RewardStrings.Experiential,
      description: RewardStrings.ExperientialDescription,
      icon: <VideoStyleListExperientialIcon />,
    },
    {
      title: RewardStrings.Funny,
      description: RewardStrings.FunnyDescription,
      icon: <VideoStyleListFunnyIcon />,
    },
  ],
};

export const activityTypesContent: Record<string, previewContentType> = {
  [CustomerActivitiesType.videoContent]: {
    title: RewardStrings.BusinessTypeMarketingTitle,
    subTitle: RewardStrings.BusinessTypeMarketingSubTitle,
    diagramIcon: <AnimatedDiagramVideoContent />,
    diagramLegendTitle: RewardStrings.BusinessTypeMarketingDiagramLegendTitle,
    description: RewardStrings.BusinessTypeMarketingDescription,
    descriptionList: [],
  },
  [CustomerActivitiesType.showcase]: {
    title: RewardStrings.WebsiteSEOTypeMarketingTitle,
    subTitle: RewardStrings.WebsiteSEOTypeMarketingSubTitle,
    diagramIcon: <AnimatedDiagramWebsiteSEO />,
    diagramLegendTitle: RewardStrings.WebsiteSEOTypeDiagramLegendTitle,
    description: RewardStrings.WebsiteSEOTypeTypeDescription,
    descriptionList: [],
  },
  [CustomerActivitiesType.sharing]: {
    title: RewardStrings.FriendsFamilyTypeMarketingTitle,
    subTitle: RewardStrings.FriendsFamilyTypeMarketingSubTitle,
    diagramIcon: <AnimatedDiagramFamilyFriends />,
    diagramLegendTitle: RewardStrings.FriendsFamilyTypeDiagramLegendTitle,
    description: RewardStrings.FriendsFamilyTypeDescription,
    descriptionList: [],
  },
};

export const diagramData: Record<string, any> = {
  [CustomerActivitiesType.videoContent]: {
    staticDiagram: <DiagramVideoContentIcon />,
    icon: <VideoTypeMarketingIconActive />,
    videos: 1,
    views: '-',
    clickthrouhs: '-',
  },
  [CustomerActivitiesType.showcase]: {
    staticDiagram: <DiagramWebsiteSEOIcon />,
    icon: <VideoTypeWebsiteSEOIconActive />,
    videos: 1,
    views: 1,
    clickthrouhs: '-',
  },
  [CustomerActivitiesType.sharing]: {
    staticDiagram: <DiagramFamilyFriendsIcon />,
    icon: <VideoTypeFriendsAndFamilyIconActive />,
    videos: 1,
    views: 1,
    clickthrouhs: 1,
  },
};
