import { makeStyles } from '@mui/styles';

export const useStatusStyles = makeStyles(() => ({
  bottomControllers: {
    // height: 68,
    width: 'max-content',
    display: 'flex',
  },
  switchContainer: {
    height: '34px',
    width: '140px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0 !important',
  },
  actionButton: {
    flexShrink: 0,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.02em',
    lineHeight: '26px',
    padding: '2px 15px',
    border: '1px solid #5242EA',
    borderRadius: '100px',
    marginLeft: 20,
    '&:nth-child(1)': {
      marginLeft: 0,
    },
    '&.MuiButton-root+.Mui-disabled': {
      borderColor: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: '#EBEBEB',
      color: '#8B89A0',
    },
  },
  reactSwitchCheckbox: {
    height: 0,
    width: 0,
    position: 'absolute',
    visibility: 'hidden',

    '&:checked + $reactSwitchButton': {
      left: 'calc(100% - 0px)',
      transform: 'translateX(-100%)',
    },
    '&:active + $reactSwitchButton': {
      width: '60px',
    },
  },
  reactSwitch: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    background: '#fff',
    position: 'relative',
    transition: 'background-color 0.2s',
    borderRadius: '35px',
  },

  reactSwitchButton: {
    position: 'absolute',
    zIndex: '1',
    top: '-1px',
    left: '0px',
    width: '70px',
    height: '34px',
    transition: '0.2s',
    background: '#8B89A0',
    borderRadius: '30px',
  },
  reactSwitchLabels: {
    display: 'relative',
    zIndex: '0',
    height: '100%',
    '& span': {
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0.02em',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
      height: '100%',
      zIndex: '1',
    },
    '& span:first-child': {
      left: 0,
    },
    '& span:last-child': {
      right: 0,
    },
  },
}));
