import { ErrorsStrings } from '../../../common/localization/en';
import { DiscountType } from '../../QuickStart/Rewards/RewardTypeCustomizationSection/RewardTypeCustomizationSection.helper';

export const HEADER_MAX_CHAR_LIMIT = 20;
export const NAME_MAX_CHAR_LIMIT = 32;
export const DEFAULT_REWARD_HEADER = 'Congratulations';
export const DEFAULT_REWARD_NAME = '$25 OFF';

export interface HowToRewardCreators {
  cardColor: string;
  rewardHeader: string;
  rewardName: string;
  contact: string;
  contactMethod: string;
  discountType: DiscountType;
  value: string;
  dataForPreview: {
    discountType: DiscountType;
    value: string;
  };
}

export const colorPalette = [
  ['#D1D1D1', '#737373', '#333333', '#000000'],
  ['#72C0F9', '#4F96FF', '#3075B5', '#1D4C7C'],
  ['#97F9EA', '#6BE3CF', '#4CA88F', '#2D6A65'],
  ['#A5F66A', '#81D553', '#54AD32', '#306F1D'],
  ['#FDF071', '#F9DA58', '#F3B03E', '#E3782E'],
  ['#F19B91', '#ED6D57', '#DB3B26', '#A62A17'],
  ['#F19BC8', '#EC549F', '#C23275', '#8A2152'],
];

export const formatUsPhoneNumber = (phoneNumber: string) => {
  const number = phoneNumber.toString().replace(/\D/g, '').replace(/^1/, '');
  const match = number.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return '(' + match[1] + ') ' + (match[2] ? match[2] + '-' : '') + match[3];
  }
  return number;
};

export enum ContactMethod {
  Phone = 'PHONE',
  Email = 'EMAIL',
  Website = 'WEBSITE',
  None = 'NONE',
}

export interface ContactData {
  name: string;
  element: JSX.Element;
}

export const getRegExpForContactMethod = (method: ContactMethod) => {
  const regExpMap = {
    PHONE: /^\(\d{3}\)\s\d{3}-\d{4}/,
    EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    WEBSITE:
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
    NONE: /(.*?)/,
  };

  return regExpMap[method];
};

export const getErrorMessageForContactMethod = (method: ContactMethod) => {
  const errorMessageMap = {
    PHONE: ErrorsStrings.PhoneIsNotValid,
    EMAIL: ErrorsStrings.EmailAddressIsNotValid,
    WEBSITE: ErrorsStrings.WebsiteUrlIsNotValid,
    NONE: '',
  };

  return errorMessageMap[method];
};
