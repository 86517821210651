import { FC, BaseSyntheticEvent, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { HelpersStrings } from '../../localization/en';

import { SearchBarContainer } from './../searchBar/SearchBar.style';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  onChange: (e: BaseSyntheticEvent) => void;
  onReset: () => void;
}

export const SearchOnClient: FC<Props> = ({ onChange, onReset }) => {
  const [value, setValue] = useState('');

  const handleChange = (e: BaseSyntheticEvent) => {
    setValue(e.target.value);
    onChange(e);
  };

  const handleReset = () => {
    setValue('');
    onReset();
  };

  return (
    <SearchBarContainer>
      <TextField
        style={{ border: '1px solid #DCDBE4', borderRadius: '100px' }}
        id="input-with-icon-textfield"
        placeholder={HelpersStrings.Search}
        onChange={handleChange}
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon style={{ marginLeft: '10px' }} />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment onClick={handleReset} position="start">
              <CloseIcon style={{ fontSize: '16px', cursor: 'pointer', marginRight: '8px' }} />
            </InputAdornment>
          ) : undefined,
        }}
        variant="standard"
      />
    </SearchBarContainer>
  );
};
