import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  image: {
    position: 'absolute',
  },
  logo: {
    position: 'relative',
    height: '40px',
    width: 'auto',
    objectFit: 'contain',
    marginBottom: '50px',
    marginTop: '35px',
    padding: '0 16px',
  },
  buttonsWrapper: {
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gradient: {
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.68) 100%)',

    width: '265px',
    height: '118px',
    paddingTop: '18px',
  },
  button: {
    height: '42px',
    width: '211px',
    display: 'grid',
    placeItems: 'center',
    borderRadius: '50px',
    fontSize: '12px',
    fontWeight: 600,
  },
  recordButton: {
    boxShadow: '0px 4px 7px -2px rgba(44, 44, 44, 0.15)',
  },
  uploadButton: {
    textDecoration: 'underline',
    color: 'white',
  },
  glassContainer: {
    width: '255px',
    minHeight: '176px',
    height: 'min-content',
    borderRadius: '8px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 35.94%, rgba(0, 0, 0, 0.45) 100%)',
    backdropFilter: 'blur(3.5px)',
    textAlign: 'center',
    margin: '20px auto 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadText: {
    lineHeight: '111%',
    letterSpacing: '0.35px',
    fontSize: '28px',
    width: '170px',
    fontWeight: 600,
    margin: '0 auto',
    color: 'white',
    textAlign: 'center',
  },
  companyName: {
    position: 'relative',
    padding: '0 16px',
    width: 'min-content',
    fontSize: '16px',
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    marginTop: '35px',
    marginBottom: '50px',
  },
}));
