import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  tableWrapper: {
    marginTop: '24px',
    maxHeight: '350px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d9d4ff',
      borderRadius: '5px',
    },
  },
  tableContainer: {
    padding: '0  !important',
    border: '1px solid #DCDBE4',
    minHeight: '96px !important',

    '& table': {
      borderSpacing: '0 !important',
    },
  },
  topSectionClassName: {
    padding: '0 !important',
  },
  headerCellClassName: {
    color: '#475467 !important',
    fontSize: '14px',
    fontWeight: '400',
    paddingTop: '10px !important',
    paddingLeft: '0px !important',
    background: '#F9FAFB',
    justifyContent: 'center !important',
  },
  rowClassName: {
    padding: '0 !important',
    height: '50px !important',
    '& td': {
      cursor: 'default !important',
    },
  },
}));
