import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { alpha } from '@mui/material';

export const useStoryStatusStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    display: 'flex',
    padding: '5px 14px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0px solid black',
    borderRadius: 30,
    width: '105px',
    height: '20px',
  },
  statusText: {
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: '0.02em',
  },
  published: {
    color: theme.palette.common.ContentTable.published,
    borderColor: theme.palette.common.ContentTable.published,
    backgroundColor: alpha(theme.palette.common.ContentTable.published as string, 0.1),
  },
  unpublished: {
    color: theme.palette.common.ContentTable.unpublished,
    borderColor: theme.palette.common.ContentTable.unpublished,
    backgroundColor: alpha(theme.palette.common.ContentTable.unpublished as string, 0.1),
  },
  moderated: {
    color: theme.palette.common.ContentTable.moderated,
    borderColor: theme.palette.common.ContentTable.moderated,
    backgroundColor: alpha(theme.palette.common.ContentTable.moderated as string, 0.1),
  },
  pending_moderation: {
    color: theme.palette.common.ContentTable.pending_moderation,
    borderColor: theme.palette.common.ContentTable.pending_moderation,
    backgroundColor: alpha(theme.palette.common.ContentTable.pending_moderation as string, 0.1),
    width: '150px',
  },
}));
