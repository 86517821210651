import { Box, Checkbox, Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { CommonTable } from '../../../common/components/table/CommonTable';

import { CampaignInvitesStrings } from '../../../common/localization/en';
import { unsentInvitesTableContent } from '../../../services/helpers/tableMappers';
import { convertApiPageToFrontEndPage } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';

import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { useStyles } from './UnsentInvitesTable.styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  getTransactionBatchRecordsById,
  putTransactionBatch,
} from '../../../store/slices/dataSyncSlice';
import { FSModal } from '../../../common/components/modal/Modal';
import { WebsiteRoutes } from '../../../common/constants/routes';
import ImportDetailsTable from '../../../common/components/DataSync/ImportDetailsTable/ImportDetailsTable';
import CheckboxIcon from '../../../common/assets/CheckboxIcon';
import CheckedCheckboxIcon from '../../../common/assets/CheckedCheckboxIcon';
import {
  getCampaignCountData,
  getCampaignInvitationBatches,
  setInvitationBatchesSorting,
  updateInvitationBatchesSize,
} from '../../../store/slices/campaignsSlice';
import { useClientType } from '../../../services/hooks/useClientType';
import { LoadingButton } from '@mui/lab';
import { useToasts } from 'react-toast-notifications';

interface Props {
  handleTrackEvent: IEventTracker;
}

export const UnsentInvitesTable: FC<Props> = ({ handleTrackEvent }) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const [isDeselectModeActive, setIsDeselectModeActive] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const { isHealthCare } = useClientType();
  const { addToast } = useToasts();

  const { items, page, size, sort, totalItems, totalPages, isLoading } = useTypedSelector(
    (state) => state.campaigns.invitationBatches,
  );
  const { unsentInvitations } = useTypedSelector((state) => state.campaigns.countData);

  const { id: campaignId } = useParams<{ id: string }>();

  const unapprovedItems = items.filter((item) => item.status !== 'APPROVED');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const rowId = event.target.dataset.rowId;
    if (!checked) {
      setSelectedRows((prevSelectedRows) => prevSelectedRows.filter((id) => id !== rowId));
      handleTrackEvent(RewardCampaignsEvents.RewardCampaignSummaryTransactionDeselect, {
        transactionId: rowId,
      });
      return;
    }

    setSelectedRows((prevSelectedRows) =>
      rowId ? [...prevSelectedRows, rowId] : prevSelectedRows,
    );
    handleTrackEvent(RewardCampaignsEvents.RewardCampaignSummaryTransactionSelect, {
      transactionId: rowId,
    });
  };

  const unsentInvitesContent = unsentInvitesTableContent({
    items: unapprovedItems,
    selectedRows,
    handleChange,
    isHealthCare,
    isLoading: isProcessing,
  });

  useEffect(() => {
    if (!campaignId) return;
    dispatch(getCampaignInvitationBatches({ id: campaignId, page, size, sort }));
  }, [campaignId, page, size, sort, dispatch, unsentInvitations]);

  const onSort = (name: string) => {
    dispatch(setInvitationBatchesSorting(name));
  };
  const onClickRow = (id: string) => {
    if (isProcessing) return;

    handleTrackEvent(RewardCampaignsEvents.RewardCampaginTableRowClick, { selectedUserId: id });
    history.push(`${WebsiteRoutes.RewardCampaigns}/${campaignId}?id=${id}`);
  };
  const changeSize = (size: number) => {
    dispatch(updateInvitationBatchesSize(size));
  };
  const close = () => {
    history.replace(`${WebsiteRoutes.RewardCampaigns}/${campaignId}`);
  };

  const selectedImportRow = items.find((row) => row.id === location.search.split('id=')[1]);

  const selectedRowId = location.search.split('id=')[1];

  useEffect(() => {
    if (selectedRowId) dispatch(getTransactionBatchRecordsById({ id: selectedRowId }));
  }, [dispatch, selectedRowId]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDeselectModeActive(event.target.checked);
    if (isDeselectModeActive) {
      handleTrackEvent(RewardCampaignsEvents.RewardCampaignSummaryUnsentInvitesDeselectAll);
      setSelectedRows([]);
    } else {
      handleTrackEvent(RewardCampaignsEvents.RewardCampaignSummaryUnsentInvitesSelectAll);
      setSelectedRows(unapprovedItems.map((item) => item.id));
    }
  };

  const handleInviteSelected = () => {
    handleTrackEvent(RewardCampaignsEvents.RewardCampaignSummaryInviteSelectedClick);
    setIsProcessing(true);
    Promise.all(selectedRows.map((id) => dispatch(putTransactionBatch({ id })))).then(() => {
      dispatch(getCampaignInvitationBatches({ id: campaignId, page, size, sort }));
      dispatch(getCampaignCountData({ campaignId: campaignId }));
      setIsProcessing(false);
      addToast(CampaignInvitesStrings.InvitationsSentSuccessfully, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
    });
    setSelectedRows([]);
    setIsDeselectModeActive(false);
  };

  return (
    <Box>
      <FSModal modalIsOpen={!!location.search} onClose={close} width="90%" padding="13px 0 0 0">
        {selectedImportRow ? <ImportDetailsTable onClose={close} /> : <></>}
      </FSModal>
      <>
        <Box className={styles.container}>
          <Grid container className={styles.top}>
            <LoadingButton
              variant="contained"
              className={styles.inviteButton}
              onClick={handleInviteSelected}
              disabled={!(selectedRows.length > 0)}
              loading={isProcessing}
            >
              {CampaignInvitesStrings.InviteSelected}
            </LoadingButton>
          </Grid>
          <Grid className={styles.table}>
            <Checkbox
              className={styles.checkbox}
              checked={isDeselectModeActive}
              onChange={handleCheckboxChange}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedCheckboxIcon />}
              id={`${isDeselectModeActive ? 'deselect' : 'select'}-all-users-button`}
              disabled={isProcessing}
            />
            <CommonTable
              content={unsentInvitesContent}
              page={convertApiPageToFrontEndPage(page)}
              sort={sort}
              totalItems={totalItems}
              totalPages={totalPages}
              isLoading={isLoading || isProcessing}
              noContent={false}
              tablePadding="0"
              onSortHeaderClick={onSort}
              onClickRow={onClickRow}
              size={size}
              onSizeChange={changeSize}
              withSearchBar={true}
              labelRowsPerPage={CampaignInvitesStrings.View}
              roundedNavButtons={false}
              disableTopNav={true}
            />
          </Grid>
        </Box>
      </>
    </Box>
  );
};
