import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_233_75)">
      <mask
        id="mask0_233_75"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="70"
        height="70"
      >
        <path
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill="#EEECFC"
        />
      </mask>
      <g mask="url(#mask0_233_75)">
        <path
          d="M35 70C54.33 70 70 54.33 70 35C70 15.67 54.33 0 35 0C15.67 0 0 15.67 0 35C0 54.33 15.67 70 35 70Z"
          fill="#EEECFC"
        />
        <path d="M0 42.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0 57.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0 27.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M0 12.5H69.9999" stroke="#D9D4FF" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <g clip-path="url(#clip1_233_75)">
        <path
          d="M34.5 13C22.1025 13 12 23.1025 12 35.5C12 47.8975 22.1025 58 34.5 58C46.8975 58 57 47.8975 57 35.5C57 23.1025 46.8975 13 34.5 13ZM45.255 30.325L32.4975 43.0825C32.1825 43.3975 31.755 43.5775 31.305 43.5775C30.855 43.5775 30.4275 43.3975 30.1125 43.0825L23.745 36.715C23.0925 36.0625 23.0925 34.9825 23.745 34.33C24.3975 33.6775 25.4775 33.6775 26.13 34.33L31.305 39.505L42.87 27.94C43.5225 27.2875 44.6025 27.2875 45.255 27.94C45.9075 28.5925 45.9075 29.65 45.255 30.325Z"
          fill="#67CE67"
        />
      </g>
    </g>
  </svg>
);

const CompletedIcon = memo(SvgComponent);
export default CompletedIcon;
