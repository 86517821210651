import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { UnprotectedRoutes } from '../../constants/routes';
import { useLogout } from '../../../services/hooks/useLogout';

export const Logout = () => {
  const history = useHistory();
  const logout = useLogout();

  useEffect(() => {
    history.push(UnprotectedRoutes.Login);
    return logout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
