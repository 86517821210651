const VIDEO_CONTENT_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name] & inspiring others with your videos!`;

const SHOWCASE_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name] & inspiring others with your widely viewed videos!`;

const SHARING_inspirational = `Hey [Customer Name], thank you for being a valued customer of [Company Name] & creating your inspiring & popular videos!`;

const VIDEO_CONTENT_experiential = `Hey [Customer Name], 

Thank you for being a valued customer of [Company Name] & creating all of your engaging videos!`;

const SHOWCASE_experiential = `Hey [Customer Name], 

Thank you for being a valued customer of [Company Name] & creating your engaging, widely viewed videos!`;

const SHARING_experiential = `Hey [Customer Name], thank you for being a valued customer of [Company Name] & creating your engaging, popular videos!`;

const VIDEO_CONTENT_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name] & inspiring others with your funny videos!`;

const SHOWCASE_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name] & creating your funny, widely viewed videos!`;

const SHARING_funny = `Hey [Customer Name], thank you for being a valued customer of [Company Name] & creating your funny, popular videos!`;

const defaultInvite = `Hi [Customer Name], it's [Company Name]. Thank you for inspiring others with your video and for being a valued part of our family.

Keep inspiring!`;

export const awareness_default_sms_reward: Record<string, string> = {
  VIDEO_CONTENT_inspirational,
  SHOWCASE_inspirational,
  SHARING_inspirational,

  VIDEO_CONTENT_experiential,
  SHOWCASE_experiential,
  SHARING_experiential,

  VIDEO_CONTENT_funny,
  SHOWCASE_funny,
  SHARING_funny,

  defaultInvite,
};

export const awareness_default_sms_reward_TruBlu: Record<string, string> = {
  VIDEO_CONTENT_inspirational: VIDEO_CONTENT_inspirational.replaceAll(
    '[Customer Name]',
    '[Patient Name]',
  )
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_inspirational: SHOWCASE_inspirational.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_inspirational: SHARING_inspirational.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  VIDEO_CONTENT_experiential: VIDEO_CONTENT_experiential.replaceAll(
    '[Customer Name]',
    '[Patient Name]',
  )
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_experiential: SHOWCASE_experiential.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_experiential: SHARING_experiential.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  VIDEO_CONTENT_funny: VIDEO_CONTENT_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHOWCASE_funny: SHOWCASE_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
  SHARING_funny: SHARING_funny.replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),

  defaultInvite: defaultInvite
    .replaceAll('[Customer Name]', '[Patient Name]')
    .replaceAll('[Company Name]', '[Practice Name]')
    .replaceAll('customer', 'patient'),
};
