import { Box, Divider, Stack, Typography } from '@mui/material';
import { OnboardingWelcomeStrings } from '../../../common/localization/en';
import { getStepsMethod1 } from './RightSideWelcomeLayout.helper';
import { useRightSideWelcomeLayoutStyles } from './RightSideWelcomeLayout.style';

export const RightSideWelcomeLayout = () => {
  const styles = useRightSideWelcomeLayoutStyles();

  const steps = getStepsMethod1();
  return (
    <Box sx={{ p: '67px 70px 0 57px', pt: '100px' }} width="100%">
      <Typography className={styles.description}>
        {OnboardingWelcomeStrings.RightSideDescription}
      </Typography>
      <Box sx={{ mb: '100px', mt: '35px' }}>
        <Divider sx={{ borderColor: '#DCDBE4', maxWidth: '495px' }} />
      </Box>
      <Stack spacing={3}>
        {steps.map((step) => {
          return (
            <Box
              sx={{
                borderRadius: '8px',
                backgroundColor: '#fff',
                display: 'flex',
                maxWidth: '495px',
              }}
            >
              <Box className={styles.iconBadge}>
                <Box className={styles.icon}>{step.icon}</Box>
              </Box>
              <Box sx={{ pl: '40px', pt: '16px', pr: '20px' }}>
                <Typography className={styles.stepTitle}>{step.title}</Typography>
                <Box sx={{ mb: '14px', width: '40px' }}>
                  <Divider sx={{ borderColor: '#F7B409' }} />
                </Box>
                <Typography className={styles.stepDescription}>{step.description}</Typography>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
