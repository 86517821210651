import { Box, IconButton, Typography } from '@mui/material';
import { FC } from 'react';
import { useTypedSelector } from '../../../store';
import { useStyles } from './WelcomeVideoStylesPreview.styles';

import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { useClientType } from '../../../services/hooks/useClientType';
import { subtitle } from './WelcomeVideoStylesPreview.helper';
import { NewCampaignStrings } from '../../../common/localization/en';
import WelcomeVideoIPhonePreview from './WelcomeVideoIPhonePreview';
import VideoStyleListFunnyIcon from '../../../common/assets/QuickStartIcons/VideoStyleListFunnyIcon';
import { VideoStyleTypes } from '../VideoStyleTypesSwitch/VideoStyleTypesSwitch';
import VideoStyleListInspirationalIcon from '../../../common/assets/QuickStartIcons/VideoStyleListInspirationalIcon';
import VideoStyleListExperientialIcon from '../../../common/assets/QuickStartIcons/VideoStyleListExperientialIcon';
import { UploadProcessStep } from '../../../store/storeModels';

interface WelcomeVideoStylesPreviewProps {}

const WelcomeVideoStylesPreview: FC<WelcomeVideoStylesPreviewProps> = () => {
  const styles = useStyles();
  const { isHealthCare } = useClientType();
  const { recommendedVideoStyle } = useTypedSelector((state) => state.createCampaign);
  const { currentStep } = useTypedSelector((state) => state.uploadVideo);

  const text = subtitle;

  return (
    <Box className={styles.Container}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', height: '42px' }}>
        <IconButton className={styles.QuestionButton}>
          <QuestionMarkRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>

      <Box sx={{ width: '100%', textAlign: 'center' }}>
        {recommendedVideoStyle === VideoStyleTypes.Inspirational && (
          <VideoStyleListInspirationalIcon color={'#5242EA'} />
        )}
        {recommendedVideoStyle === VideoStyleTypes.Experiential && (
          <VideoStyleListExperientialIcon color={'#5242EA'} />
        )}
        {recommendedVideoStyle === VideoStyleTypes.Funny && (
          <VideoStyleListFunnyIcon color={'#5242EA'} />
        )}
      </Box>
      <Typography className={styles.Title}>
        {currentStep !== UploadProcessStep.UploadVideo
          ? NewCampaignStrings.CustomWelcomeVideoPreviewTitle
          : text.title}
      </Typography>
      <Typography className={styles.SubTitle}>
        {currentStep !== UploadProcessStep.UploadVideo
          ? isHealthCare
            ? NewCampaignStrings.CustomWelcomeVideoPreviewSubTitle.replace('Creators', 'Patients')
            : NewCampaignStrings.CustomWelcomeVideoPreviewSubTitle
          : isHealthCare
          ? text.subtitle.replace(`creator`, `patient`)
          : text.subtitle}
      </Typography>
      <Typography className={styles.FooterLabel}>
        {isHealthCare
          ? NewCampaignStrings.PreviewFooterLabel.replace(`creator`, `patient`)
          : NewCampaignStrings.PreviewFooterLabel}
      </Typography>
      <Box className={styles.iphonePreview}>
        <WelcomeVideoIPhonePreview />
      </Box>
    </Box>
  );
};

export default WelcomeVideoStylesPreview;
