import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useContentModalInfoStyles = makeStyles((theme: Theme) => ({
  KeywordsContainer: {
    padding: '20px 20px',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  KeywordsTitle: {
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  KeywordScoreContainer: {
    textAlign: 'center',
  },
  KeywordScore: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
    width: 'max-content',
  },
  KeywordScoreValue: {
    fontWeight: 600,
    fontSize: '42px',
    lineHeight: '63px',
    letterSpacing: '0.02em',
    color: theme.palette.primary.main,
  },
  KeywordsBubblesContainer: {
    marginTop: '20px',
  },
  KeywordsBubble: {
    display: 'inline-block',
    padding: '3px 16px',
    background: 'rgba(139, 137, 160, 0.1)',
    borderRadius: '30px',
    marginRight: '12px',
    marginBottom: '12px',
    width: 'fit-content',
  },
  Keyword: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
    lineHeight: '24px',
  },
}));
