import React, { FC, useEffect } from 'react';
import { useStyles } from './QuickStartAccordion.styles';
import {
  Box,
  /* Button, */ Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '../Tooltip/Tooltip';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { BoxProps } from '@material-ui/core';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../constants/events/quickstartEvents';
import { PersonalizationSubroutes } from '../../constants/routes';

export interface AccordionItem {
  subroute: string;
  title: string;
  tooltipText?: string;
  sectionContent: React.ReactNode;
  onNextClick?: () => void;
  withToolTip?: boolean;
}

interface Props extends BoxProps {
  accordionItems: AccordionItem[];
  baseRoute: string;
  colorError?: boolean;
}

export const QuickStartAccordion: FC<Props> = ({
  accordionItems,
  padding,
  baseRoute,
  colorError,
  ...boxProps
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { trackEvent } = useTrackEvent();
  const currentRoute = useLocation().pathname;
  const activeTabIndex = accordionItems.findIndex((item) => currentRoute.endsWith(item.subroute));

  useEffect(() => {
    if (activeTabIndex === -1) {
      history.push(`${currentRoute}/${accordionItems[0].subroute}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);
  const handleAccordionClick = (item: AccordionItem) => {
    const tabName = item.title.toLowerCase().replaceAll(' ', '_').replaceAll('?', '');
    const eventName = `${tabName}_${QuickStartEventNames.accordion_click}`;
    trackEvent(eventName);
  };

  return (
    <Box className={classes.container} {...boxProps}>
      <Stepper activeStep={activeTabIndex} orientation="vertical" className={classes.stepper}>
        {accordionItems.map((item, index) => {
          const isActiveStep = activeTabIndex === index;
          /* const isLastStep = index === accordionItems.length - 1; */

          return (
            <Step
              key={item.title}
              className={classes.step}
              onClick={() => {
                if (colorError) {
                  history.push(`${baseRoute}/${PersonalizationSubroutes.SelectBrandColor}`);
                  return;
                }
                if (isActiveStep) return;
                history.push(`${baseRoute}/${item.subroute}`);
              }}
            >
              <StepLabel
                className={classes.stepLabel}
                onClick={() => {
                  handleAccordionClick(item);
                }}
              >
                <Typography
                  className={classes.labelText}
                  style={{
                    color: isActiveStep ? '#5242EA' : '#1A1538',
                  }}
                >
                  {item.title}
                  {item.tooltipText && item.withToolTip && (
                    <Tooltip
                      padding="23px 25px"
                      rounded="15px"
                      title={item.tooltipText}
                      icon={<QuestionMarkRoundedIcon className={classes.tooltip} />}
                    />
                  )}
                </Typography>
                <ExpandMoreIcon
                  className={classes.expandIcon}
                  style={{
                    transform: isActiveStep ? 'none' : 'rotate(270deg)',
                  }}
                />
              </StepLabel>

              <StepContent>
                <Box sx={{ margin: '20px 0 32px 30px' }}>
                  {item.sectionContent}
                  {/*  {!isLastStep && (
                    <Box display="flex" justifyContent="flex-end" mt="-38px">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() => {
                          history.push(`${baseRoute}/${accordionItems[index + 1].subroute}`);
                          item.onNextClick?.();
                        }}
                      >
                        Next
                      </Button>
                    </Box>
                  )} */}
                </Box>
              </StepContent>
              <Box
                className={classes.stepDivider}
                style={{
                  display: index === accordionItems.length - 1 ? 'none' : 'block',
                }}
              />
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
