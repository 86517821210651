import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minWidth: '778px',
    maxWidth: 'fit-content',
    background: '#fff',
    minHeight: '348px',
    borderRadius: '10px',
  },
  topWrapper: {
    paddingLeft: '28px',
  },
  title: {
    marginTop: '30px',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.48px',
    color: '#000',
  },
  description: {
    marginTop: '6px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.28px',
    color: '#484848',
  },
  divider: {
    margin: '26px 0',
  },
  bottomWrapper: {
    paddingLeft: '28px',
  },
  googleProfileWrapper: {
    display: 'flex',
  },
  googleProfileIcon: {
    width: '27px',
    height: '24px',
  },
  googleProfileTitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#101828',
  },
  googleProfileDescr: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: '3px',
    color: '#475467',
  },
  signInWrapper: {
    marginTop: '24px',
    minWidth: '194px',
    minHeight: '92px',
    height: 'fit-content',
    width: 'fit-content',
    borderRadius: '12px',
    border: '1px solid #EAECF0',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    marginBottom: '24px',
  },
  googleBtnWrapper: {
    width: '256px',
    height: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover, &:focus': {
      background: 'rgba(66, 133, 244, 0.30)',
    },
  },
  googleIcon: {
    marginRight: '16px',
  },

  signInButton: {
    color: 'rgba(0, 0, 0, 0.54)',
    borderRadius: '2px',
    fontSize: '15px',
    letterSpacing: '0.375px',
    fontWeight: 400,
    background: 'white',
    fontFamily: 'Roboto',
    boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.24)',
    width: '248px',
    height: '40px',
    justifyContent: 'start',
    paddingLeft: '14px',
    paddingTop: '7px',
    transition: 'unset',
    '&:hover, &:focus': {
      background: 'white',
    },
    '&:active': {
      background: 'linear-gradient(0deg, #EEE 0%, #EEE 100%), #FFF',
    },
  },
  progress: {
    width: '5px',
    height: '5px',
    marginRight: '12px',
    color: '#5242eab3',
  },
  errorTitle: {
    color: '#DB3B26',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.28px',
  },
  ensureText: {
    marginTop: '3px',
  },
  list: {
    margin: '5px 0 16px 25px',
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    color: '#484848',
    fontWeight: 400,
    lineHeight: '26px',
  },
  linkIcon: {
    transform: 'translateY(2px)',
  },
  profileLink: {
    color: '#5242EA',
  },
  errorDescr: {
    color: '#484848',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.28px',
    width: '534px',
    marginBottom: '16px',
  },
  contactWrapper: {
    marginTop: '16px',
  },
  contactText: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 400,
  },
  syncedBusinessWrapper: {
    margin: '24px 0 24px 0',
  },
}));
