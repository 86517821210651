import { Box, Typography } from '@mui/material';
import React from 'react';
import { SelectOption, SelectTextField } from '../TextField/SelectTextField';
import { BoxProps } from '@mui/system';

export enum SMSInvitationType {
  SEND_IMMEDIATELY = 'SEND_IMMEDIATELY',
  SAVE_FOR_LATER = 'SAVE_FOR_LATER',
}

const smsInvitationOptions: Array<SelectOption> = [
  {
    content: 'Send Immediately',
    value: SMSInvitationType.SEND_IMMEDIATELY,
  },
  {
    content: 'Save For Later',
    value: SMSInvitationType.SAVE_FOR_LATER,
  },
];

interface SMSInvitationTypeSelectorProps extends Omit<BoxProps, 'onChange'> {
  title?: string;
  value: string;
  onChange?: (value: SMSInvitationType) => void;
}

export const SMSInvitationTypeSelector: React.FC<SMSInvitationTypeSelectorProps> = ({
  title = 'When should the SMS invitation be sent?',
  value,
  onChange,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      <Typography color="#344054" fontSize="13px" mb="6px" fontWeight={500}>
        {title}
      </Typography>
      <SelectTextField
        sx={{ height: '44px' }}
        fullWidth
        name="state"
        placeholder="State"
        value={value
          .split('_')
          .map((word) => word.slice(0, 1).concat(word.slice(1).toLowerCase()))
          .join(' ')}
        onChange={(event) => {
          onChange?.(event.target.value as SMSInvitationType);
        }}
        options={smsInvitationOptions}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      />
    </Box>
  );
};
