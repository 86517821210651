import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { VenueProperties } from '../../../api/models/venue';
import { QuickStartTopBar } from '../../../common/components/QuickStartTopBar/QuickStartTopBar';
import { DEFAULT_BRAND_COLOR } from '../../../common/constants/defaults';
import { TwoSidePageLayout } from '../../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { CommonStrings } from '../../../common/localization/en';
import { useVenue } from '../../../hooks/useVenue';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { useBackgroundImage } from '../../../hooks/webApp/useBackgroundImage';
import { useAppDispatch } from '../../../store';
import { patchVenue } from '../../../store/slices/venueSlice';
import {
  filterLogoPositions,
  uploadOverlay,
} from '../../webappSettings/VideoBrandingPlayback/VideoBrandingPlayback.helper';
import { useStyles } from '../QuickStart.styles';
import { CustomizationSection } from './CustomizationSection/CustomizationSection';
import { PreviewSection } from './PreviewSection/PreviewSection';
import { useThemeLayout } from '../../../hooks/useThemeLayout';

export const Personalize: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const [logo, setLogo] = useState<string>();
  const [backgroundImage, setBackgroundImage] = useState<string>();
  const [backgroundImagePreview, setBackgroundImagePreview] = useState<string>();
  const [color, setColor] = useState(DEFAULT_BRAND_COLOR);
  const [colorError, setColorError] = useState(false);

  const [accordionSubroutes, setAccordionSubroutes] = useState<Array<string>>([]);

  const backgroundImageConfig = useBackgroundImage();

  const webAppConfig = useVenueProperty<VenueProperties['webapp.config']>({
    property: 'webapp.config',
  });

  const qrConfig = useVenueProperty<VenueProperties['webapp.config']>({
    property: 'dashboard.QRCode.config',
  });

  const { theme } = useThemeLayout();

  const { venueId, logo: venueLogo } = useVenue({
    onVenueLoad: () => {
      const color = webAppConfig?.value?.['primary-color'] || DEFAULT_BRAND_COLOR;
      const background = backgroundImageConfig.backgroundImage ?? undefined;

      setLogo(venueLogo);
      setBackgroundImage(background);
      setColor(color);
    },
  });

  const disableNextButton = !backgroundImage || colorError;

  const saveLogoImage = async () => {
    qrConfig.update(
      {
        logo,
      },
      { patchObjectValue: true },
    );
    return dispatch(patchVenue({ id: venueId, update: { logo } }));
  };

  const saveBackgroundImage = async () => {
    if (!backgroundImage) return;
    return backgroundImageConfig.updateBackgroundImage(backgroundImage);
  };

  const saveBrandColor = async () => {
    if (!colorError) {
      qrConfig.update(
        {
          brandColor: color,
        },
        { patchObjectValue: true },
      );

      return webAppConfig.update(
        {
          'primary-color': color,
        },
        { patchObjectValue: true },
      );
    } else return;
  };

  const handleUpload = async () => {
    if (venueId) {
      return await uploadOverlay(
        webAppConfig.value?.['filter-overlay-url'] || '',
        logo ?? venueLogo,
        filterLogoPositions.topLeft,
        venueId,
        false,
      );
    } else {
      return Promise.resolve('');
    }
  };

  const onLogoNextClick = async () => {
    await handleUpload().then((filterUrl) => {
      webAppConfig.update(
        {
          'filter-logo-position': filterLogoPositions.topLeft,
          'filter-logo-enabled': !!logo,
          'filter-overlay-enabled': false,
          'filter-overlay-url': '',
          'filter-url': filterUrl,
          'primary-color': color,
        },
        { patchObjectValue: true },
      );
    });
  };

  const saveDataToVenueProperties = async () => {
    await Promise.all([
      onLogoNextClick(),
      saveLogoImage(),
      saveBackgroundImage(),
      saveBrandColor(),
    ]);
    addToast(CommonStrings.OnSuccess, { appearance: 'success' });
  };

  return (
    <>
      <QuickStartTopBar
        disableNextButton={disableNextButton}
        onNextClick={saveDataToVenueProperties}
        accordionSubroutes={accordionSubroutes}
      />
      <Box className={classes.container} style={{ overflowY: 'auto' }}>
        <TwoSidePageLayout
          leftSideNode={
            <CustomizationSection
              logo={logo}
              onLogoChange={(logo) => (logo ? setLogo(logo) : {})}
              background={backgroundImage}
              onBackgroundChange={setBackgroundImage}
              onBackgroundPreviewChange={setBackgroundImagePreview}
              color={color}
              onColorChange={setColor}
              setColorError={setColorError}
              colorError={colorError}
              onColorNextClick={saveBrandColor}
              onBackgroundNextClick={saveBackgroundImage}
              onSetAccordionSubroutes={setAccordionSubroutes}
            />
          }
          rightSideNode={
            <PreviewSection
              logoImage={logo}
              brandColor={color}
              backgroundImage={backgroundImagePreview || backgroundImage}
              theme={theme}
            />
          }
          rightSideTop="0"
          leftSideColumnWidths={7}
          pxLeftSide={0}
        />
      </Box>
    </>
  );
};
