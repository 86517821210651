import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTranscriptStyles = makeStyles((theme: Theme) => ({
  TranscriptContainer: {
    border: '1px solid #DCDBE4',
    borderRadius: '12px',
    padding: '14px 20px',
    margin: '20px',
    marginBottom: 30,
  },
  TranscriptTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  TranscriptTitle: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  TranscriptScore: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
  },
  TranscriptScoreValue: {
    marginLeft: 6,
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  TranscriptText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  HighlightTag: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: '500',
    fontStyle: 'inherit',
  },
}));
