export enum RewardFulfillmentMethod {
  MANUAL_SMS = 'MANUAL_SMS',
  AUTOMATIC_SMS = 'AUTOMATIC_SMS',
  WEBHOOK = 'WEBHOOK',
}

export enum RewardSMSMessageVariables {
  COMPANY_NAME = 'COMPANY_NAME',
  CREATOR_NAME = 'CREATOR_NAME',
  INCENTIVE_NAME = 'INCENTIVE_NAME',
  REWARD_NAME = 'INCENTIVE_NAME',
  LINK = 'LINK',
}

export interface RewardFulfillmentSMSConfig {
  mediaUrl?: string;
  body: string;
  parameters: Record<string, string>;
}

export interface RewardFulfillmentWebhookConfig {
  webhookUrl: string;
  validationKey: string;
}

export interface RewardFulfillmentConfig {
  method: RewardFulfillmentMethod;
  sms: RewardFulfillmentSMSConfig;
  webhook: RewardFulfillmentWebhookConfig;
}

export type GetRewardFulfillmentConfigResponse = RewardFulfillmentConfig;

export interface PostRewardFulfillmentConfigRequest {
  venueId: string;
  payload: RewardFulfillmentConfig;
}

export type PostRewardFulfillmentConfigResponse = RewardFulfillmentConfig;

export interface PostRewardFulfillmentTestWebhookRequest {
  venueId: string;
  payload: {
    url: string;
    key: string;
  };
}

interface RewardFulfillmentTestSMSPayload extends RewardFulfillmentSMSConfig {
  phoneNumber: string;
}

export interface PostRewardFulfillmentTestSMSRequest {
  venueId: string;
  payload: RewardFulfillmentTestSMSPayload;
}
