export const InvitationsLink = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2000_43963" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="42">
        <circle cx="20.998" cy="21" r="20.7558" fill="#EEECFC" />
      </mask>
      <g mask="url(#mask0_2000_43963)">
        <circle cx="20.998" cy="21" r="20.7558" fill="#EEECFC" />
        <path
          d="M16.5508 0.244141L16.5508 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.65625 0.244141L7.65625 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.4453 0.244141L25.4453 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.3398 0.244141L34.3398 41.7557"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M21.2076 27.8644L19.7933 29.2786C17.8407 31.2313 14.6749 31.2313 12.7223 29.2786C10.7697 27.326 10.7697 24.1602 12.7223 22.2076L14.1365 20.7933M26.8644 22.2076L28.2786 20.7933C30.2312 18.8407 30.2312 15.6749 28.2786 13.7223C26.326 11.7697 23.1602 11.7697 21.2076 13.7223L19.7933 15.1365M17.0005 25.0004L24.0005 18.0004"
        stroke="#5242EA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
