export const LaptopWithPuzleIcon = () => (
  <svg width="82" height="53" viewBox="0 0 82 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.68359 4.0861C5.68359 2.38169 7.06529 1 8.76969 1H72.8131C74.5175 1 75.8992 2.38169 75.8992 4.0861V47.8104H5.68359V4.0861Z"
      fill="#D9D4FF"
      stroke="#5242EA"
    />
    <path
      d="M11.8608 7.77951C11.8608 6.8431 12.62 6.08398 13.5564 6.08398H68.0307C68.9671 6.08398 69.7262 6.8431 69.7262 7.77951V42.7321H11.8608V7.77951Z"
      fill="#EEECFD"
    />
    <path
      d="M1 49.8852C1 51.0542 1.94772 52.002 3.11679 52.002H78.4608C79.6299 52.002 80.5776 51.0542 80.5776 49.8852V47.8401C80.5776 47.272 80.1171 46.8114 79.5489 46.8114H2.0287C1.46056 46.8114 1 47.272 1 47.8401V49.8852Z"
      fill="white"
      stroke="#5242EA"
    />
    <path
      d="M43.1502 49.9543H37.8132C36.0554 49.9543 34.6304 48.5293 34.6304 46.7715H46.333C46.333 48.5293 44.908 49.9543 43.1502 49.9543Z"
      fill="#5242EA"
    />
    <path
      d="M49.2692 28.266V31.3078C49.2692 32.4124 48.3738 33.3078 47.2692 33.3078H36.5874C35.4828 33.3078 34.5874 32.4124 34.5874 31.3078V28.2963M49.2692 23.8359V20.626C49.2692 19.5214 48.3738 18.626 47.2692 18.626H44.1161M34.5874 23.9065V20.626C34.5874 19.5214 35.4828 18.626 36.5874 18.626H39.6863"
      stroke="#5242EA"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M49.2698 24.5576C49.6841 24.5576 50.0198 24.2218 50.0198 23.8076C50.0198 23.3934 49.6841 23.0576 49.2698 23.0576V24.5576ZM49.2698 28.8758C49.6841 28.8758 50.0198 28.54 50.0198 28.1258C50.0198 27.7116 49.6841 27.3758 49.2698 27.3758V28.8758ZM47.1108 24.5576H49.2698V23.0576H47.1108V24.5576ZM49.2698 27.3758H47.1108V28.8758H49.2698V27.3758ZM47.1108 27.3758C46.3325 27.3758 45.7017 26.7449 45.7017 25.9667H44.2017C44.2017 27.5734 45.5041 28.8758 47.1108 28.8758V27.3758ZM47.1108 23.0576C45.5041 23.0576 44.2017 24.3601 44.2017 25.9667H45.7017C45.7017 25.1885 46.3325 24.5576 47.1108 24.5576V23.0576Z"
      fill="#5242EA"
    />
    <path
      d="M43.3377 18.6258C43.3377 19.04 43.6735 19.3758 44.0877 19.3758C44.5019 19.3758 44.8377 19.04 44.8377 18.6258H43.3377ZM39.0195 18.6258C39.0195 19.04 39.3553 19.3758 39.7695 19.3758C40.1837 19.3758 40.5195 19.04 40.5195 18.6258H39.0195ZM43.3377 16.4667V18.6258H44.8377V16.4667H43.3377ZM40.5195 18.6258V16.4667H39.0195V18.6258H40.5195ZM41.9286 15.0576C42.7068 15.0576 43.3377 15.6885 43.3377 16.4667H44.8377C44.8377 14.8601 43.5353 13.5576 41.9286 13.5576V15.0576ZM41.9286 13.5576C40.322 13.5576 39.0195 14.8601 39.0195 16.4667H40.5195C40.5195 15.6885 41.1504 15.0576 41.9286 15.0576V13.5576Z"
      fill="#5242EA"
    />
    <path
      d="M34.5877 24.5576C35.0019 24.5576 35.3377 24.2218 35.3377 23.8076C35.3377 23.3934 35.0019 23.0576 34.5877 23.0576V24.5576ZM34.5877 28.8758C35.0019 28.8758 35.3377 28.54 35.3377 28.1258C35.3377 27.7116 35.0019 27.3758 34.5877 27.3758V28.8758ZM32.4286 24.5576H34.5877V23.0576H32.4286V24.5576ZM34.5877 27.3758H32.4286V28.8758H34.5877V27.3758ZM32.4286 27.3758C31.6504 27.3758 31.0195 26.7449 31.0195 25.9667H29.5195C29.5195 27.5734 30.822 28.8758 32.4286 28.8758V27.3758ZM32.4286 23.0576C30.822 23.0576 29.5195 24.3601 29.5195 25.9667H31.0195C31.0195 25.1885 31.6504 24.5576 32.4286 24.5576V23.0576Z"
      fill="#5242EA"
    />
  </svg>
);
