export const ArrowGreen = () => {
  return (
    <svg width="27" height="30" viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.9142 16.4142C26.6953 15.6332 26.6953 14.3668 25.9142 13.5858L13.1863 0.857865C12.4052 0.0768166 11.1389 0.0768165 10.3579 0.857865C9.57682 1.63891 9.57682 2.90524 10.3579 3.68629L21.6716 15L10.3579 26.3137C9.57681 27.0948 9.57681 28.3611 10.3579 29.1421C11.1389 29.9232 12.4052 29.9232 13.1863 29.1421L25.9142 16.4142ZM0.5 17L24.5 17L24.5 13L0.5 13L0.5 17Z"
        fill="#54AD32"
      />
    </svg>
  );
};
