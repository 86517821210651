import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Color, createColor } from 'material-ui-color';

import { SocialVerseStrings } from '../../localization/en';
import { ColorPalette } from './components/ColorPalette';

interface ICustomColorPickerProps {
  color: string;
  onColorChange: (color: string) => void;
  palette?: string[][];
  title?: string;
  warningMessage?: string;
}

export type ColorFormatType = 'hex' | 'rgb';

export const defaultColorPalette = [
  ['#D1D1D1', '#737373', '#333333', '#000000'],
  ['#72C0F9', '#45A1F8', '#3075B5', '#1D4C7C'],
  ['#97F9EA', '#6BE3CF', '#4CA88F', '#2D6A65'],
  ['#A5F66A', '#81D553', '#54AD32', '#306F1D'],
  ['#FDF071', '#F9DA58', '#F3B03E', '#E3782E'],
  ['#F19B91', '#ED6D57', '#DB3B26', '#A62A17'],
  ['#F19BC8', '#EC549F', '#C23275', '#8A2152'],
];

export const CustomColorPicker: FC<ICustomColorPickerProps> = ({
  color,
  onColorChange,
  palette = defaultColorPalette,
  title = SocialVerseStrings.ColorPalette,
  warningMessage,
}) => {
  const [activeColor, setActiveColor] = useState<Color>(createColor(color));

  // removed SV-236 Remove the custom HEX/RGB/Color picker component from the brand color UI
  // const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   let color = event.target.value;
  //   if (colorFormat === 'hex' && !color.startsWith('#')) {
  //     color = `#${color}`;
  //   }
  //   onColorChange(color);
  // };

  useEffect(() => {
    setActiveColor(createColor(color));
  }, [color]);

  return (
    <Box>
      <Box border="1px solid #E1E5EE" borderRadius="10px" p="16px">
        <Typography fontSize="12px" marginBottom="8px">
          {title}
        </Typography>

        {warningMessage && (
          <Typography fontSize="12px" marginBottom="8px" color="#8B89A0">
            {warningMessage}
          </Typography>
        )}

        <ColorPalette
          palette={palette}
          activeColor={activeColor.raw as string}
          onSelect={onColorChange}
        />
      </Box>
      {/* removed SV-236 Remove the custom HEX/RGB/Color picker component from the brand color UI */}
      {/* <Grid mt="25px">
        <ColorInput
          color={color}
          colorFormat={colorFormat}
          onInputChange={handleChangeInput}
          onFormatChange={setColorFormat}
          onColorPick={handleColorUpdate}
        />
      </Grid> */}
    </Box>
  );
};
