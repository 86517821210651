import { FC } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { CommonStrings, VideoDetailsModalStringsDv5 } from '../../localization/en';
import { useModalsStyles } from '../VideosDetailUserComponent/ModalsVideoDetailuser/Modals.style';
interface ModalAwardProps {
  isOpenModal: boolean;
  activeCampaignBonusPoints: number | '';
  toggleModal: () => void;
  handleConfirm: () => void;
  onClickOutsideDisable?: (value: boolean) => void;
  trackClose?: () => void;
}
export const AwardBonusPointsModal: FC<ModalAwardProps> = ({
  isOpenModal,
  activeCampaignBonusPoints,
  toggleModal,
  handleConfirm,
  onClickOutsideDisable,
  trackClose,
}) => {
  const styles = useModalsStyles();

  const handleConfirmAward = () => {
    toggleModal();
    handleConfirm();
    onClickOutsideDisable?.(false);
  };

  const handleCancel = () => {
    onClose();
    onClickOutsideDisable?.(false);
  };
  const onClose = () => {
    toggleModal();
    trackClose && trackClose();
  };
  return (
    <Modal open={isOpenModal} onClose={onClose}>
      <Box className={styles.AwardsModal}>
        <Typography className={styles.ModalTitle}>
          {VideoDetailsModalStringsDv5.ModalTitle}
        </Typography>
        <Typography className={styles.ModalText}>
          {VideoDetailsModalStringsDv5.ModalText.replace(
            '{bonus points}',
            activeCampaignBonusPoints + '',
          )}
        </Typography>
        <div style={{ textAlign: 'end', marginTop: 30 }}>
          <Button
            variant="outlined"
            className={[styles.ModalBtn, styles.CancelBtn].join(' ')}
            onClick={handleCancel}
          >
            {CommonStrings.Cancel}
          </Button>
          <Button variant="outlined" className={styles.ModalBtn} onClick={handleConfirmAward}>
            {VideoDetailsModalStringsDv5.ModalBtn}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
