import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useStyles } from './InitialPreview.styles';
import { InvitationsLink } from '../../../common/assets/InvitationsLink';
import { QrLink } from '../../../common/assets/QrLink';
import { InvitationArrow } from '../../../common/assets/InvitationArrow';
import { CreateVideoIcon } from '../../../common/assets/CreateVideoIcon';
import { InvitesDirectionArrow } from '../../../common/assets/InvitesDirectionArrow';
import { CampaignInvitesStrings } from '../../../common/localization/en';
import { ContactIcons } from '../../../common/assets/ContactIcons';
import { useTypedSelector } from '../../../store';
import { CreatorIcon } from '../../../common/assets/CreatorIcon';
import { InvitesDirectionDownArrow } from '../../../common/assets/InvitesDirectionDownArrow';
import { LinkIcon } from '../../../common/assets/newDesign/LinkIcon';

export const InitialPreview: FC = () => {
  const classes = useStyles();
  const { emailRequired } = useTypedSelector((state) => state.createCampaign);

  return (
    <Box className={classes.container}>
      <Typography className={classes.titleText}>
        {CampaignInvitesStrings.HowInvitationsWork}
      </Typography>
      <Box className={classes.descrWrapper}>
        <Typography className={classes.descrText} display="inline">
          {CampaignInvitesStrings.InvitationsDescr}
        </Typography>
        <Typography className={classes.videoAppText} display="inline">
          {CampaignInvitesStrings.VideoAppText}
        </Typography>
      </Box>

      <Box className={classes.linksWrapper}>
        <Box className={classes.invitationsLink} width="250px">
          <Box className={classes.linkWrapper}>
            <InvitationsLink />
          </Box>
          <Box className={classes.fastPass}>
            <LinkIcon />
            <Typography className={classes.invitationsLinkText}>FastPass Link</Typography>
          </Box>

          <Typography className={classes.invitationsLinkText}>
            {CampaignInvitesStrings.SocialVLink}
          </Typography>
          <Typography className={classes.invitationsLinkTextDescription}>
            {CampaignInvitesStrings.SocialVLinkDescription}
          </Typography>
          <Box className={classes.directionArrowLeftWrapper}>
            <InvitesDirectionArrow />
          </Box>
        </Box>
        <Typography className={classes.orText}>OR</Typography>
        <Box className={classes.invitationsLink} width="200px">
          <Box className={classes.linkWrapper}>
            <QrLink />
          </Box>
          <Typography className={classes.invitationsLinkText}>
            {CampaignInvitesStrings.QrCode}
          </Typography>
          <Box className={classes.directionArrowWrapper}>
            <InvitesDirectionDownArrow />
          </Box>
        </Box>
        <Typography className={classes.orText}>OR</Typography>
        <Box className={classes.invitationsLink} width="250px">
          <Box className={classes.linkWrapper}>
            <InvitationsLink />
          </Box>
          <Typography className={classes.invitationsLinkText}>
            {CampaignInvitesStrings.InvitationLink}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.contactIconsWrapper}>
        <ContactIcons />
      </Box>
      <Box className={classes.videoIconsWrapper}>
        <Box className={classes.videoIcon}>
          <Box className={classes.iconWrapper}>
            <CreateVideoIcon />
          </Box>
          <Typography className={classes.videoIconText}>
            {CampaignInvitesStrings.CreateVideo}
          </Typography>
        </Box>
        {emailRequired && (
          <Box className={classes.arrowWrapper}>
            <InvitationArrow />
          </Box>
        )}
        {emailRequired && (
          <Box className={classes.videoIcon}>
            <Box className={classes.iconWrapper}>
              <CreatorIcon />
            </Box>
            <Typography className={classes.videoIconText}>
              {CampaignInvitesStrings.CaptureEmail}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
