import { FC } from 'react';
interface IRateIconDv55Props {
  color?: string;
}
export const RateIconDv5: FC<IRateIconDv55Props> = ({ color }) => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 1.5L1 15"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <circle cx="11" cy="13" r="2.35" stroke={color || '#8B89A0'} strokeWidth="1.3" />
      <circle cx="3" cy="4" r="2.35" stroke={color || '#8B89A0'} strokeWidth="1.3" />
    </svg>
  );
};
